/**
* CLASS Capacitaciones
*
* Contiene el contenedor principal de las capacitaciones
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import Window from '../window/Window';
import globalState from '../configuration/GlobalState';
import {validarPermiso} from '../configuration/GlobalFunctions';

class Capacitaciones extends Component {         	  
  	colFuncion(idRow){        
        globalState.dispatch({
                type   : "windowUsuariosCapacitaciones",
                params : {
                              visible : true,
                              params  : {
                                            idRow  : idRow,
                                            idWin  : "windowUsuariosCapacitaciones",//identificador de la ventana,
                                            width  : '350px', 
                                            height : '70%'
                                        }
                         }
            }); 
    }
    colFuncion2(idRow){        
        globalState.dispatch({
                type   : "windowAdjuntosCapacitaciones",
                params : {
                              visible : true,
                              params  : {
                                            idRow   : idRow, 
                                            table   : 'capacitaciones',
                                            maxSize : 10245760,
                                            allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],                                           
                                            idWin   : "windowAdjuntosCapacitaciones",
                                            width   : '300px', 
                                            height  : '250px'//identificador de la ventana
                                        }
                         }
            }); 
    }
    render() { 
        let sqlParams = { 
                            sqlCols : [
                                "*",
                                "DATE_FORMAT(T1.fecha_inicio,'%Y-%m-%d') AS fecha_inicio", 
                                "DATE_FORMAT(T1.fecha_final,'%Y-%m-%d') AS fecha_final",                                                                   
                                "DATE_FORMAT(CONCAT(T1.fecha_inicio,' ',T1.hora_inicio),'%Y-%m-%d %H:%i:%s') AS fecha_inicial", 
                                "DATE_FORMAT(CONCAT(T1.fecha_final,' ',T1.hora_final),'%Y-%m-%d %H:%i:%s') AS fecha_finalizacion",
                            ],
                            fieldSearch : [
                                'nombre',                                
                            ],                                                       
                            fieldFechas : "T1.fecha_inicio",
                            fecha_completa : "true",                
                        };
        //validar permiso para configurar capacitaciones
        let botonConfigurar = '';        
        if(validarPermiso(74)){
            botonConfigurar = {
                                  type  : 'manual',
                                  label : '',
                                  icon  : 'settings',
                                  colFuncion : this.colFuncion.bind(this)
                              };
        }                    
        return (//carga el componente que contiene la grilla de datos 
            <div>
                <DataGrid titulo='Capacitaciones' 
                    funcionClick={this.props.funcionClick}   
                    parametro={this.props.parametro} 
                    colsData={[ 
                                  {
                                      type  : 'bd',
                                      label : 'Nombre',
                                      field : 'nombre'
                                  },
                                  {
                                      type  : 'bd',
                                      label : 'Instructor',
                                      field : 'instructor'
                                  },
                                  {
                                      type  : 'bd',
                                      label : 'Horas',
                                      field : 'intensidad'
                                  },
                                  {
                                      type  : 'bd',
                                      label : 'Lugar',
                                      field : 'lugar'
                                  },
                                  {
                                      type  : 'bd',
                                      label : 'Fecha Inicio',
                                      field : 'fecha_inicial'
                                  },
                                  {
                                      type  : 'bd',
                                      label : 'Fecha Final',
                                      field : 'fecha_finalizacion'
                                  },                                  
                                  botonConfigurar,
                                  {
                                      type  : 'manual',
                                      label : '',
                                      icon  : 'attach_file',
                                      colFuncion : this.colFuncion2.bind(this)
                                  },
                              ]}
                    sqlParams = { sqlParams }                       
                    automatica="true"
                    botonNuevo="true"
                    filtroFechas="true"
                    formFields={[
                                  {
                                      label : 'Nombre *',
                                      field : 'nombre',
                                      type  : 'text',
                                      validation : 'texto_mayusculas',
                                      required : 'true',
                                      longitud : 70,
                                      tooltip : 'Ingrese el nombre de la capacitacion'
                                  },
                                  {
                                      label : 'Instructor *',
                                      field : 'instructor',
                                      type  : 'text',
                                      validation : 'texto_mayusculas',
                                      required : 'true',
                                      longitud : 60,
                                      tooltip : 'Ingrese el nombre del capacitador'
                                  },
                                  {
                                      label : 'Fecha Inicio *',
                                      field : 'fecha_inicio',
                                      type  : 'date',
                                      validation : '',
                                      required : 'true',
                                      tooltip : 'Ingrese la fecha inicial de la capacitacion'                                        
                                  },
                                  {
                                      label : 'Hora Inicio *',
                                      field : 'hora_inicio',
                                      type  : 'time',
                                      validation : '',
                                      required : 'true',
                                      tooltip : 'Ingrese la hora inicial de la capacitacion'                                        
                                  },
                                  {
                                      label : 'Fecha Final *',
                                      field : 'fecha_final',
                                      type  : 'date',
                                      validation : '',
                                      required : 'true',
                                      tooltip : 'Ingrese la fecha final de la capacitacion'                                        
                                  },
                                  {
                                      label : 'Hora Final *',
                                      field : 'hora_final',
                                      type  : 'time',
                                      validation : '',
                                      required : 'true',
                                      tooltip : 'Ingrese la hora final de la capacitacion'                                        
                                  },
                                  {
                                      label : 'Intensidad(Horas) *',
                                      field : 'intensidad',
                                      type  : 'text',
                                      validation : 'entero',
                                      required : 'true',
                                      tooltip : 'Ingrese la intensidad horaria'                                        
                                  },
                                  {
                                      label : 'Lugar *',
                                      field : 'lugar',
                                      type  : 'text',
                                      validation : 'texto_mayusculas',
                                      required : 'true',
                                      longitud : 35,
                                      tooltip : 'Ingrese el lugar de la capacitacion'
                                  }, 
                                  {
                                      label : 'Observaciones *',
                                      field : 'observaciones',
                                      type  : 'textarea',
                                      rows  : 4,
                                      validation : '',
                                      required : 'true',
                                      tooltip : 'Ingrese las observaciones'
                                  }, 
                                  {
                                      label  : 'Adjunto',                                                    
                                      field  : 'nombre_archivo',                                                                                                        
                                      type   : 'multi_file',
                                      accept : ".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png",
                                      allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],
                                      maxSize : 10245760,
                                      validation : 'file',
                                      required : '',
                                      tooltip : 'Seleccione los archivos a adjuntar en la capacitacion'
                                  },                               
                              ]} 
                    permisoInsertUpdate="73"                    
                    permisoDelete="73"                    
                    apiField={'capacitaciones'}
                    mainContainer='Capacitaciones'/>
                <Window 
                    id = "windowAdjuntosCapacitaciones"                     
                    title='Ver Documentos'                   
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                /> 
                <Window 
                    id = "windowUsuariosCapacitaciones"                     
                    title='Funcionarios'                   
                    tbar="false"
                    componente="WindowUsuariosCapacitaciones"
                    params="" 
                />
            </div>                                 
        )
    } 
}

export default Capacitaciones