/**
* CLASS ReportContainer
*
* Contiene el contenedor con los reportes
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,validarPermisoRol} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import ReportBotonExcel from './ReportBotonExcel';
import Table from 'react-bootstrap/Table';
import alertify from 'alertifyjs';
import './dashboard.css';

class ReportContainer extends Component {
    constructor(props){
        super(props);         
        this.state={   
            indicadores : {
                indicadorIncidenciasPuntB : [],         
                indicadorProblemasPuntB : [],         
                indicadorServiciosPuntB : [],
                indicadorIncidenciasNoCumple : [],         
                indicadorProblemasNoCumple : [],         
                indicadorServiciosNoCumple : [],
                indicadorIncidenciasSinA : [],         
                indicadorProblemasSinA : [],         
                indicadorServiciosSinA : [],
                indicadorIncidenciasPendientes : [],         
                indicadorProblemasPendientes : [],         
                indicadorServiciosPendientes : [], 
            },     
            permisos : {
                displayPromI : 'none',
                displayPromP : 'none',
                displayPromS : 'none',            
                displayIncidencias : 'none',
                displayProblemas : 'none',
                displayServicios : 'none',
                whereIncidencia : '',
                whereProblema : '',
                whereServicio : ''
            },
            database : globalState.getState().companyData[0].bd           
        };
    }
    componentWillMount(){
        this.cargarIndicadores(this.props.params.date1,this.props.params.date2,this.props.params.usuario,this.props.params.rol_usuario,this.props.params.filtroArea,this.props.params.permisos);         
    } 
    componentDidUpdate(prevProps){   
        if (this.props !== prevProps) {           
           this.cargarIndicadores(this.props.params.date1,this.props.params.date2,this.props.params.usuario,this.props.params.rol_usuario,this.props.params.filtroArea,this.props.params.permisos); 
        }
    }
    async cargarIndicadores(date1,date2,idUser,rolUser,filtroArea,permisos){  //cargar los indicadores                             
        try{    
            var verOtros = "true";        
            if(idUser === 0){
                idUser = globalState.getState().userData[0].id;
                verOtros = "false";
            } 
            let arrayPermisos = {};                    
            if(permisos.permisoVerTecnicos === true && verOtros==="false"){
                //visualizaciones 
                arrayPermisos = { 
                    displayIncidencias : 'block',
                    displayProblemas   : 'block',
                    displayServicios   : 'block',
                    displayPromI : 'block',
                    displayPromP : 'block',
                    displayPromS : 'block',
                    whereIncidencia : '',
                    whereProblema : '',                 
                    whereServicio : '', 
                };
            } 
            else if(permisos.permisoVerTecnicos === false || verOtros==="true"){           
                let res = await validarPermisoRol(rolUser,46);
                let response = res.data;
                let displayPromI = 'none';
                let displayIncidencias = 'none';
                let whereIncidencia = '';
                if((response.msg === true && verOtros === "true") || (verOtros==="false" && permisos.indicadoresIncidencias === true)){
                    if(permisos.permisoVerTecnicos === true){
                        displayPromI = 'block';                     
                    }
                    displayIncidencias = 'block';
                    whereIncidencia    = ' AND id_tecnico_incidencia = '+idUser;                
                }            
                
                res = await validarPermisoRol(rolUser,47);
                response = res.data;  
                let displayPromP = 'none'; 
                let displayProblemas = 'none';
                let whereProblema = '';                                             
                if((response.msg === true && verOtros === "true") || (verOtros==="false" && permisos.indicadoresProblemas === true)){
                    if(permisos.permisoVerTecnicos === true){
                        displayPromP = 'block';                    
                    }
                    displayProblemas = 'block';
                    whereProblema = ' AND id_tecnico_problema = '+idUser;                    
                }
                        
                res = await validarPermisoRol(rolUser,48);
                response = res.data;
                let displayPromS = 'none'; 
                let displayServicios = 'none';  
                let whereServicio = '';        
                if((response.msg === true && verOtros === "true") || (verOtros==="false" && permisos.indicadoresServicios === true)){
                    if(permisos.permisoVerTecnicos === true){
                        displayPromS = 'block';                   
                    }
                    displayServicios = 'block';
                    whereServicio = ' AND id_tecnico_servicio = '+idUser;                   
                }                                  
                arrayPermisos = { 
                    displayIncidencias : displayIncidencias,
                    displayProblemas   : displayProblemas,
                    displayServicios   : displayServicios,
                    displayPromI : displayPromI,
                    displayPromP : displayPromP,
                    displayPromS : displayPromS,
                    whereIncidencia : whereIncidencia,
                    whereProblema : whereProblema,                 
                    whereServicio : whereServicio,                 
                };  

            }         
            this.setState({permisos : arrayPermisos},()=>{
                this.ejecutarQuerysDashboard(date1,date2,filtroArea,verOtros);
            });
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message);
        }
    }    
    async ejecutarQuerysDashboard(date1,date2,filtroArea,verOtros){
        try{
            let permisos = this.state.permisos; 
            //formato de la fecha
            date1 = new Date(date1.getTime() - (date1.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            date2 = new Date(date2.getTime() - (date2.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            //cargar los indicadores
             //indicador 1 de incidencias con incumplimiento 
            let sqlParams = '';
            let indicadorIncidenciasPuntB =  [];         
            let indicadorProblemasPuntB =  [];         
            let indicadorServiciosPuntB =  [];
            let indicadorIncidenciasNoCumple =  [];         
            let indicadorProblemasNoCumple =  [];         
            let indicadorServiciosNoCumple =  [];
            let indicadorIncidenciasSinA =  [];         
            let indicadorProblemasSinA =  [];         
            let indicadorServiciosSinA =  [];
            let indicadorIncidenciasPendientes =  [];         
            let indicadorProblemasPendientes =  [];         
            let indicadorServiciosPendientes =  [];

            var whereArea1 = '';        
            if(filtroArea > 0 && verOtros==='false'){            
                whereArea1 = ' AND T1.id_area='+filtroArea;
            }

            if(permisos.displayIncidencias === 'block'){   
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                    'SP.html AS prioridad',
                                    "DATE_FORMAT(T1.fecha_proceso_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_proceso",
                                    "DATE_FORMAT(T1.fecha_vencimiento_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_vencimiento",
                                    "DATE_FORMAT(T1.fecha_solucion_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_solucion",
                                    "TIMEDIFF(T1.fecha_solucion_incidencia,fecha_proceso_incidencia) AS diferencia"
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                            'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_incidencia)', 
                                            'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                          ], 
                                sqlWhere : [ " AND problema='false' AND incidencia_cumplimiento='false' "+permisos.whereIncidencia+whereArea1 ],    
                                fieldFechas : "T1.fecha_solucion_incidencia",
                                fecha_completa : "true"                                                      
                            }
                let res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorIncidenciasNoCumple = response;
            }
            if(permisos.displayProblemas === 'block'){
                //indicador 1 de problemas con incumplimiento   
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                    'SP.html AS prioridad',
                                    "DATE_FORMAT(T1.fecha_proceso_problema,'%Y-%m-%d %H:%i:%s') AS fecha_proceso",
                                    "DATE_FORMAT(T1.fecha_solucion_problema,'%Y-%m-%d %H:%i:%s') AS fecha_solucion",
                                    "DATE_FORMAT(T1.fecha_vencimiento_problema,'%Y-%m-%d %H:%i:%s') AS fecha_vencimiento",
                                    "TIMEDIFF(T1.fecha_solucion_problema,fecha_proceso_problema) AS diferencia"
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)', 
                                            'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_problema)',                                   
                                            'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                          ], 
                                sqlWhere : [ " AND problema='true' AND problema_cumplimiento='false' "+permisos.whereProblema+whereArea1 ],    
                                fieldFechas : "T1.fecha_solucion_problema",
                                fecha_completa : "true"                                                      
                            }
                let res = await cargarFilas('incidencias','',100000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorProblemasNoCumple = response;
            }
            //indicador 1 de servicios con incumplimiento 
            if(permisos.displayServicios === 'block'){  
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                    'SP.html AS prioridad',
                                    "DATE_FORMAT(T1.fecha_proceso_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_proceso",
                                    "DATE_FORMAT(T1.fecha_solucion_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_solucion",
                                    "DATE_FORMAT(T1.fecha_vencimiento_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_vencimiento",
                                    "TIMEDIFF(T1.fecha_solucion_servicio,fecha_proceso_servicio) AS diferencia"
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                            'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_servicio)',
                                            'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                          ], 
                                sqlWhere : [ " AND servicio_cumplimiento='false' "+permisos.whereServicio+whereArea1 ],    
                                fieldFechas : "T1.fecha_solucion_servicio",
                                fecha_completa : "true"                                                      
                            }
                let res = await cargarFilas('servicios','',100000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorServiciosNoCumple = response;
            }       
             //indicador 1 de incidencias pendientes por finalizar
            if(permisos.displayIncidencias === 'block'){   
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                    'SP.html AS prioridad',
                                    "DATE_FORMAT(T1.fecha_asignacion_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_asignacion",
                                    "DATE_FORMAT(T1.fecha_proceso_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_proceso",
                                    "DATE_FORMAT(T1.fecha_vencimiento_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_vencimiento",
                                    "TIMEDIFF(fecha_vencimiento_incidencia,NOW()) AS diferencia"
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',   
                                            'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_incidencia)',                                 
                                            'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                          ], 
                                sqlWhere : [ " AND T1.problema='false' AND T1.estado > 0 AND T1.estado < 3 AND T1.fecha_asignacion_incidencia IS NOT NULL "+permisos.whereIncidencia+whereArea1 ],    
                                sqlOrderBy : "diferencia"                                                      
                            }
                let res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorIncidenciasPendientes = response;
            }
            //indicador 1 de problemas pendientes por finalizar 
            if(permisos.displayProblemas === 'block'){  
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                    'SP.html AS prioridad',
                                    "DATE_FORMAT(T1.fecha_asignacion_problema,'%Y-%m-%d %H:%i:%s') AS fecha_asignacion",
                                    "DATE_FORMAT(T1.fecha_proceso_problema,'%Y-%m-%d %H:%i:%s') AS fecha_proceso",
                                    "DATE_FORMAT(T1.fecha_vencimiento_problema,'%Y-%m-%d %H:%i:%s') AS fecha_vencimiento",
                                    "TIMEDIFF(fecha_vencimiento_problema,NOW()) AS diferencia"
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)', 
                                            'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_problema)',
                                            'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                          ], 
                                sqlWhere : [ " AND problema='true' AND T1.estado > 0 AND T1.estado < 3 AND T1.fecha_asignacion_problema IS NOT NULL "+permisos.whereProblema+whereArea1 ],    
                                sqlOrderBy : "diferencia"                                                      
                            }
                let res = await cargarFilas('incidencias','',100000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorProblemasPendientes = response;
            } 
            //indicador 1 de servicios pendientes por finalizar 
            if(permisos.displayServicios === 'block'){ 
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                    'SP.html AS prioridad',
                                    "DATE_FORMAT(T1.fecha_asignacion_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_asignacion",
                                    "DATE_FORMAT(T1.fecha_proceso_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_proceso",
                                    "DATE_FORMAT(T1.fecha_vencimiento_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_vencimiento",
                                    "TIMEDIFF(fecha_vencimiento_servicio,NOW()) AS diferencia"
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)', 
                                            'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_servicio)',
                                            'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                          ], 
                                sqlWhere : [ " AND T1.estado > 0 AND T1.estado < 3 AND T1.fecha_asignacion_servicio IS NOT NULL "+permisos.whereServicio+whereArea1 ],    
                                sqlOrderBy : "diferencia"                                                      
                            }
                let res = await cargarFilas('servicios','',100000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorServiciosPendientes = response; 
            }
             //indicador 1 de incidencias con puntaje bajo  
            if(permisos.displayPromI === 'block'){  
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                    "ROUND(T1.puntaje_encuesta) AS puntaje_encuesta",
                                    "E.comentarios"
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',   
                                            'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_incidencia)',                                 
                                            "INNER JOIN encuesta_satisfaccion_respuestas AS E ON (E.id_solicitud = T1.id AND E.tabla = 'incidencias' AND E.id_pregunta = 0)",
                                          ], 
                                sqlWhere : [ " AND T1.problema='false' AND T1.puntaje_encuesta > 0 AND T1.puntaje_encuesta <= 3 "+permisos.whereIncidencia+whereArea1 ],    
                                fieldFechas : "T1.fecha_finalizacion",                        
                                fecha_completa : "true",                                                                              
                            }
                let res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorIncidenciasPuntB = response;
            }
            //indicador 1 de problemas con puntaje bajo 
            if(permisos.displayPromP === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                    "ROUND(T1.puntaje_encuesta) AS puntaje_encuesta",
                                    "E.comentarios"
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)', 
                                            'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_problema)',
                                            "INNER JOIN encuesta_satisfaccion_respuestas AS E ON (E.id_solicitud = T1.id AND E.tabla = 'incidencias' AND E.id_pregunta = 0)",
                                          ], 
                                sqlWhere : [ " AND problema='true' AND T1.puntaje_encuesta > 0 AND T1.puntaje_encuesta <= 3 "+permisos.whereProblema+whereArea1 ],    
                                fieldFechas : "T1.fecha_finalizacion",
                                fecha_completa : "true"                                                      
                            }
                let res = await cargarFilas('incidencias','',100000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorProblemasPuntB = response;
            }
            //indicador 1 de servicios con puntaje bajo
            if(permisos.displayPromS === 'block'){ 
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                    "ROUND(T1.puntaje_encuesta) AS puntaje_encuesta",
                                    "E.comentarios"
                                ],
                                sqlJoin : [                                    
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)', 
                                            'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_servicio)',
                                            "INNER JOIN encuesta_satisfaccion_respuestas AS E ON (E.id_solicitud = T1.id AND E.tabla = 'servicios' AND E.id_pregunta = 0)",
                                          ], 
                                sqlWhere : [ " AND T1.estado > 0 AND T1.puntaje_encuesta > 0 AND T1.puntaje_encuesta <= 3 "+permisos.whereServicio+whereArea1 ],    
                                fieldFechas : "T1.fecha_finalizacion",
                                fecha_completa : "true"                                                      
                            }
                let res = await cargarFilas('servicios','',100000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorServiciosPuntB = response;
            }
            //indicador 1 de incidencias sin asignar 
            if(permisos.displayPromI === 'block'){ 
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",                            
                                    'SP.html AS prioridad',
                                    "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',   
                                            'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                          ], 
                                sqlWhere : [ " AND T1.problema='false' AND T1.estado = 0 "+permisos.whereIncidencia+whereArea1 ],   
                            }
                let res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorIncidenciasSinA = response;
            }
            //indicador 1 de problemas sin asignar
            if(permisos.displayPromP === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",                            
                                    'SP.html AS prioridad',
                                    "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",
                                ],
                                sqlJoin : [
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)', 
                                            'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                          ], 
                                sqlWhere : [ " AND problema='true' AND T1.estado = 0 "+permisos.whereProblema+whereArea1 ],
                            }
                let res = await cargarFilas('incidencias','',100000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorProblemasSinA = response;
            }
            //indicador 1 de servicios sin asignar
            if(permisos.displayPromS === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'T1.id',
                                    "T1.asunto",
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",                            
                                    'SP.html AS prioridad',
                                    "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",
                                ],
                                sqlJoin : [                                    
                                            'LEFT JOIN users AS U ON (U.id = T1.id_usuario)', 
                                            'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                          ], 
                                sqlWhere : [ " AND T1.estado = 0 "+permisos.whereServicio+whereArea1 ],
                            }
                let res = await cargarFilas('servicios','',100000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                indicadorServiciosSinA = response;
            }  
            let arrayIndicadores = {
                indicadorIncidenciasPuntB : indicadorIncidenciasPuntB,         
                indicadorProblemasPuntB : indicadorProblemasPuntB,         
                indicadorServiciosPuntB : indicadorServiciosPuntB,
                indicadorIncidenciasNoCumple : indicadorIncidenciasNoCumple,         
                indicadorProblemasNoCumple : indicadorProblemasNoCumple,         
                indicadorServiciosNoCumple : indicadorServiciosNoCumple,
                indicadorIncidenciasSinA : indicadorIncidenciasSinA,         
                indicadorProblemasSinA : indicadorProblemasSinA,         
                indicadorServiciosSinA : indicadorServiciosSinA,
                indicadorIncidenciasPendientes : indicadorIncidenciasPendientes,         
                indicadorProblemasPendientes : indicadorProblemasPendientes,         
                indicadorServiciosPendientes : indicadorServiciosPendientes, 
            }
            this.setState({indicadores : arrayIndicadores});
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message);
        }
    }
  	render() {
        let permisos = this.state.permisos;
        let indicadores = this.state.indicadores;   
        //LOS TIPOS DE GRAFICO SON PieChart y ColumnChart                    
        return ( 
            <div id="DashboardContenedor">
                    <div>
                        <div id="IndicadorAmplio" style={{display:permisos.displayIncidencias}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Incidencias pendientes por Solucionar</div>
                                <ReportBotonExcel idTabla="indicadorIncidenciasPendientes"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorIncidenciasPendientes">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>
                                            <th style={{fontSize:'12px'}}>Tecnico</th>
                                            <th style={{fontSize:'12px'}}>Prioridad</th>
                                            <th style={{fontSize:'12px'}}>Fecha Asignado</th>  
                                            <th style={{fontSize:'12px'}}>Fecha Proceso</th>                                 
                                            <th style={{fontSize:'12px'}}>Vencimiento</th>
                                            <th style={{fontSize:'12px'}}>Restante</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorIncidenciasPendientes.map((listado,i) => {
                                            if(listado.diferencia !== null && listado.diferencia.length > 0 && listado.diferencia.indexOf('-') !== -1){
                                                listado.diferencia = <span style={{color:'red',fontSize:'11px'}}>{listado.diferencia}</span>
                                            }                                        
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>
                                                        <td data-title="Tecnico" style={{fontSize:'10px'}}>{listado.tecnico}&nbsp;</td>
                                                        <td data-title="Prioridad" style={{fontSize:'10px'}}><div dangerouslySetInnerHTML={{__html: listado.prioridad}}></div></td>
                                                        <td data-title="Fecha Asignado" style={{fontSize:'10px'}}>{listado.fecha_asignacion}&nbsp;</td>
                                                        <td data-title="Fecha Proceso" style={{fontSize:'10px'}}>{listado.fecha_proceso}&nbsp;</td>
                                                        <td data-title="Fecha Solucion" style={{fontSize:'10px'}}>{listado.fecha_vencimiento}&nbsp;</td>                                                                           
                                                        <td data-title="Tiempo" style={{fontSize:'10px'}}>{listado.diferencia}&nbsp;</td>                                                                           

                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                        
                        <div id="IndicadorAmplio" style={{display:permisos.displayProblemas}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Problemas pendientes por Solucionar</div>
                                <ReportBotonExcel idTabla="indicadorProblemasPendientes"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorProblemasPendientes">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>
                                            <th style={{fontSize:'12px'}}>Tecnico</th>
                                            <th style={{fontSize:'12px'}}>Prioridad</th>
                                            <th style={{fontSize:'12px'}}>Fecha Asignado</th>  
                                            <th style={{fontSize:'12px'}}>Fecha Proceso</th>                                 
                                            <th style={{fontSize:'12px'}}>Vencimiento</th>
                                            <th style={{fontSize:'12px'}}>Restante</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorProblemasPendientes.map((listado,i) => {
                                            if(listado.diferencia !== null && listado.diferencia.length > 0 && listado.diferencia.indexOf('-') !== -1){
                                                listado.diferencia = <span style={{color:'red',fontSize:'11px'}}>{listado.diferencia}</span>
                                            }                                        
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>
                                                        <td data-title="Tecnico" style={{fontSize:'10px'}}>{listado.tecnico}&nbsp;</td>
                                                        <td data-title="Prioridad" style={{fontSize:'10px'}}><div dangerouslySetInnerHTML={{__html: listado.prioridad}}></div></td>
                                                        <td data-title="Fecha Asignado" style={{fontSize:'10px'}}>{listado.fecha_asignacion}&nbsp;</td>
                                                        <td data-title="Fecha Proceso" style={{fontSize:'10px'}}>{listado.fecha_proceso}&nbsp;</td>
                                                        <td data-title="Fecha Solucion" style={{fontSize:'10px'}}>{listado.fecha_vencimiento}&nbsp;</td>                                                                           
                                                        <td data-title="Tiempo" style={{fontSize:'10px'}}>{listado.diferencia}&nbsp;</td>                                                                           

                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                        
                        <div id="IndicadorAmplio" style={{display:permisos.displayServicios}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Servicios pendientes por Solucionar</div>
                                <ReportBotonExcel idTabla="indicadorServiciosPendientes"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorServiciosPendientes">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>
                                            <th style={{fontSize:'12px'}}>Tecnico</th>
                                            <th style={{fontSize:'12px'}}>Prioridad</th>
                                            <th style={{fontSize:'12px'}}>Fecha Asignado</th> 
                                            <th style={{fontSize:'12px'}}>Fecha Proceso</th>                                 
                                            <th style={{fontSize:'12px'}}>Vencimiento</th>
                                            <th style={{fontSize:'12px'}}>Restante</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorServiciosPendientes.map((listado,i) => { 
                                            if(listado.diferencia !== null && listado.diferencia.length > 0 && listado.diferencia.indexOf('-') !== -1){
                                                listado.diferencia = <span style={{color:'red',fontSize:'11px'}}>{listado.diferencia}</span>
                                            }                                       
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>
                                                        <td data-title="Tecnico" style={{fontSize:'10px'}}>{listado.tecnico}&nbsp;</td>
                                                        <td data-title="Prioridad" style={{fontSize:'10px'}}><div dangerouslySetInnerHTML={{__html: listado.prioridad}}></div></td>
                                                        <td data-title="Fecha Asignado" style={{fontSize:'10px'}}>{listado.fecha_asignacion}&nbsp;</td>
                                                        <td data-title="Fecha Proceso" style={{fontSize:'10px'}}>{listado.fecha_proceso}&nbsp;</td>
                                                        <td data-title="Fecha Solucion" style={{fontSize:'10px'}}>{listado.fecha_vencimiento}&nbsp;</td>                                                                           
                                                        <td data-title="Tiempo" style={{fontSize:'10px'}}>{listado.diferencia}&nbsp;</td>                                                                           

                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                        
                        <div id="IndicadorAmplio" style={{display:permisos.displayIncidencias}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Incidencias con Incumplimiento de SLAs</div>
                                <ReportBotonExcel idTabla="indicadorIncidenciasNoCumple"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorIncidenciasNoCumple">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>
                                            <th style={{fontSize:'12px'}}>Tecnico</th>
                                            <th style={{fontSize:'12px'}}>Prioridad</th>
                                            <th style={{fontSize:'12px'}}>Fecha Proceso</th>  
                                            <th style={{fontSize:'12px'}}>Fecha Vencimiento</th>                                
                                            <th style={{fontSize:'12px'}}>Fecha Solucion</th>
                                            <th style={{fontSize:'12px'}}>Tiempo</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorIncidenciasNoCumple.map((listado,i) => {                                        
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>
                                                        <td data-title="Tecnico" style={{fontSize:'10px'}}>{listado.tecnico}&nbsp;</td>
                                                        <td data-title="Prioridad" style={{fontSize:'10px'}}><div dangerouslySetInnerHTML={{__html: listado.prioridad}}></div></td>
                                                        <td data-title="Fecha Proceso" style={{fontSize:'10px'}}>{listado.fecha_proceso}&nbsp;</td>
                                                        <td data-title="Fecha Vencimiento" style={{fontSize:'10px'}}>{listado.fecha_vencimiento}&nbsp;</td>  
                                                        <td data-title="Fecha Solucion" style={{fontSize:'10px'}}>{listado.fecha_solucion}&nbsp;</td>                                                                           
                                                        <td data-title="Tiempo" style={{fontSize:'10px'}}>{listado.diferencia}&nbsp;</td>                                                                           

                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                        
                        <div id="IndicadorAmplio" style={{display:permisos.displayProblemas}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Problemas con Incumplimiento de SLAs</div>
                                <ReportBotonExcel idTabla="indicadorProblemasNoCumple"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">                            
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorProblemasNoCumple">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>
                                            <th style={{fontSize:'12px'}}>Tecnico</th>                                            
                                            <th style={{fontSize:'12px'}}>Prioridad</th>
                                            <th style={{fontSize:'12px'}}>Fecha Proceso</th> 
                                            <th style={{fontSize:'12px'}}>Fecha Vencimiento</th>                                  
                                            <th style={{fontSize:'12px'}}>Fecha Solucion</th>
                                            <th style={{fontSize:'12px'}}>Tiempo</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorProblemasNoCumple.map((listado,i) => {                                        
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>
                                                        <td data-title="Tecnico" style={{fontSize:'10px'}}>{listado.tecnico}&nbsp;</td>
                                                        <td data-title="Prioridad" style={{fontSize:'10px'}}><div dangerouslySetInnerHTML={{__html: listado.prioridad}}></div></td>
                                                        <td data-title="Fecha Proceso" style={{fontSize:'10px'}}>{listado.fecha_proceso}&nbsp;</td>
                                                        <td data-title="Fecha Vencimiento" style={{fontSize:'10px'}}>{listado.fecha_vencimiento}&nbsp;</td>  
                                                        <td data-title="Fecha Solucion" style={{fontSize:'10px'}}>{listado.fecha_solucion}&nbsp;</td>                                                                           
                                                        <td data-title="Tiempo" style={{fontSize:'10px'}}>{listado.diferencia}&nbsp;</td>                                                                           

                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                        
                        <div id="IndicadorAmplio" style={{display:permisos.displayServicios}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Servicios con Incumplimiento de SLAs</div>
                                <ReportBotonExcel idTabla="indicadorServiciosNoCumple"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">                               
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorServiciosNoCumple">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>
                                            <th style={{fontSize:'12px'}}>Tecnico</th>
                                            <th style={{fontSize:'12px'}}>Prioridad</th>
                                            <th style={{fontSize:'12px'}}>Fecha Proceso</th>  
                                            <th style={{fontSize:'12px'}}>Fecha Vencimiento</th>                                
                                            <th style={{fontSize:'12px'}}>Fecha Solucion</th>
                                            <th style={{fontSize:'12px'}}>Tiempo</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorServiciosNoCumple.map((listado,i) => {                                        
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>
                                                        <td data-title="Tecnico" style={{fontSize:'10px'}}>{listado.tecnico}&nbsp;</td>
                                                        <td data-title="Prioridad" style={{fontSize:'10px'}}><div dangerouslySetInnerHTML={{__html: listado.prioridad}}></div></td>
                                                        <td data-title="Fecha Proceso" style={{fontSize:'10px'}}>{listado.fecha_proceso}&nbsp;</td>
                                                        <td data-title="Fecha Vencimiento" style={{fontSize:'10px'}}>{listado.fecha_vencimiento}&nbsp;</td>  
                                                        <td data-title="Fecha Solucion" style={{fontSize:'10px'}}>{listado.fecha_solucion}&nbsp;</td>                                                                           
                                                        <td data-title="Tiempo" style={{fontSize:'10px'}}>{listado.diferencia}&nbsp;</td>                                                                           

                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                                                
                        <div id="IndicadorAmplio" style={{display:permisos.displayPromI}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Incidencias con Puntaje Bajo</div>
                                <ReportBotonExcel idTabla="indicadorIncidenciasPuntB"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorIncidenciasPuntB">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>
                                            <th style={{fontSize:'12px'}}>Tecnico</th>
                                            <th style={{fontSize:'12px'}}>Puntaje</th>
                                            <th style={{fontSize:'12px'}}>Comentarios</th> 
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorIncidenciasPuntB.map((listado,i) => {                                                                                    
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>
                                                        <td data-title="Tecnico" style={{fontSize:'10px'}}>{listado.tecnico}&nbsp;</td>
                                                        <td data-title="Puntaje" style={{fontSize:'10px'}}>{listado.puntaje_encuesta}&nbsp;</td>
                                                        <td data-title="Comentarios" style={{fontSize:'10px'}}>{listado.comentarios}&nbsp;</td>
                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                        
                        <div id="IndicadorAmplio" style={{display:permisos.displayPromP}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Problemas con Puntaje Bajo</div>
                                <ReportBotonExcel idTabla="indicadorProblemasPuntB"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorProblemasPuntB">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>
                                            <th style={{fontSize:'12px'}}>Tecnico</th>
                                            <th style={{fontSize:'12px'}}>Puntaje</th>
                                            <th style={{fontSize:'12px'}}>Comentarios</th> 
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorProblemasPuntB.map((listado,i) => {                                                                                 
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>
                                                        <td data-title="Tecnico" style={{fontSize:'10px'}}>{listado.tecnico}&nbsp;</td>
                                                        <td data-title="Puntaje" style={{fontSize:'10px'}}>{listado.puntaje_encuesta}&nbsp;</td>
                                                        <td data-title="Comentarios" style={{fontSize:'10px'}}>{listado.comentarios}&nbsp;</td>                                                                       
                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                        
                        <div id="IndicadorAmplio" style={{display:permisos.displayPromS}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Servicios con Puntaje Bajo</div>
                                <ReportBotonExcel idTabla="indicadorServiciosPuntB"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorServiciosPuntB">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>
                                            <th style={{fontSize:'12px'}}>Tecnico</th>
                                            <th style={{fontSize:'12px'}}>Puntaje</th>
                                            <th style={{fontSize:'12px'}}>Comentarios</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorServiciosPuntB.map((listado,i) => {                                                                                  
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>
                                                        <td data-title="Tecnico" style={{fontSize:'10px'}}>{listado.tecnico}&nbsp;</td>
                                                        <td data-title="Puntaje" style={{fontSize:'10px'}}>{listado.puntaje_encuesta}&nbsp;</td>
                                                        <td data-title="Comentarios" style={{fontSize:'10px'}}>{listado.comentarios}&nbsp;</td>                                                                           
                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div> 
                        <div id="IndicadorAmplio" style={{display:permisos.displayPromI}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Incidencias pendientes por Asignar</div>
                                <ReportBotonExcel idTabla="indicadorIncidenciasSinA"/>
                            </div>
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorIncidenciasSinA">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>                                            
                                            <th style={{fontSize:'12px'}}>Prioridad</th>
                                            <th style={{fontSize:'12px'}}>Fecha</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorIncidenciasSinA.map((listado,i) => {                                                                                   
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>                                                       
                                                        <td data-title="Prioridad" style={{fontSize:'10px'}}><div dangerouslySetInnerHTML={{__html: listado.prioridad}}></div></td>
                                                        <td data-title="Fecha" style={{fontSize:'10px'}}>{listado.fecha}&nbsp;</td>                                                                                         
                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                        
                        <div id="IndicadorAmplio" style={{display:permisos.displayPromP}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Problemas pendientes por Asignar</div>
                                <ReportBotonExcel idTabla="indicadorProblemasSinA"/>
                            </div>                            
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorProblemasSinA">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>                                            
                                            <th style={{fontSize:'12px'}}>Prioridad</th>
                                            <th style={{fontSize:'12px'}}>Fecha</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorProblemasSinA.map((listado,i) => {                                                                                   
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>                                                       
                                                        <td data-title="Prioridad" style={{fontSize:'10px'}}><div dangerouslySetInnerHTML={{__html: listado.prioridad}}></div></td>
                                                        <td data-title="Fecha" style={{fontSize:'10px'}}>{listado.fecha}&nbsp;</td> 
                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                        
                        <div id="IndicadorAmplio" style={{display:permisos.displayPromS}}>
                            <div className="TituloIndicadorReportes">
                                <div style={{float:"left"}}>Servicios pendientes por Asignar</div>
                                <ReportBotonExcel idTabla="indicadorServiciosSinA"/>
                            </div>                            
                            <div id="no-more-tables" className="tablasIncumplimiento">
                                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}} id="indicadorServiciosSinA">
                                     <thead className="cf">
                                        <tr>
                                            <th style={{fontSize:'12px'}}>No.</th>
                                            <th style={{fontSize:'12px'}}>Asunto</th>
                                            <th style={{fontSize:'12px'}}>Usuario</th>                                            
                                            <th style={{fontSize:'12px'}}>Prioridad</th>
                                            <th style={{fontSize:'12px'}}>Fecha</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                    {
                                        indicadores.indicadorServiciosSinA.map((listado,i) => {                                                                                    
                                            return <tr key={ i } >
                                                        <td data-title="No." style={{fontSize:'10px'}}>{listado.id}</td> 
                                                        <td data-title="Asunto" style={{fontSize:'10px'}}>{listado.asunto}&nbsp;</td>
                                                        <td data-title="Usuario" style={{fontSize:'10px'}}>{listado.usuario}&nbsp;</td>                                                       
                                                        <td data-title="Prioridad" style={{fontSize:'10px'}}><div dangerouslySetInnerHTML={{__html: listado.prioridad}}></div></td>
                                                        <td data-title="Fecha" style={{fontSize:'10px'}}>{listado.fecha}&nbsp;</td> 
                                                   </tr>                                                                            
                                        })
                                    }
                                    </tbody>                                      
                                </Table>
                            </div>
                        </div>                                                
                    </div>
                </div>
        );
    } 
}

export default ReportContainer
