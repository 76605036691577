/**
* CLASS Notificaciones
*
* Contiene el contenedor principal de las notificaciones
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import FormDataGrid from '../data_grid/FormDataGrid';
import TBar from '../tbar/TBar';
import globalState from '../configuration/GlobalState';
import {cargarFilas} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import '../../css/alertify.css';

class Notificaciones extends Component {
    constructor(props){
        super(props)
        this.state={
            idRow : 0, 
            database : globalState.getState().companyData[0].bd           
        }; 
        let sqlParams = {
                            sqlCols : [
                                'id',
                                'tiempo_incidencia',
                                'tiempo_problema',
                                'tiempo_servicio',                                                                          
                            ],
                            mode : 'rows',                                                                           
                        }

        cargarFilas('solicitudes_notificaciones','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{
                let idRow = 0;                
                if(response.length > 0){
                    idRow = response[0];                    
                }
                this.setState({ idRow: idRow });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        }); 
    }
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }
  	render() {           
        return (//carga el componente que contiene la grilla de datos 
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>            
                    <FormDataGrid 
                        funcionClick = {this.props.funcionClick} 
                        parametro = {
                                        {
                                            idRow : this.state.idRow,
                                            mainContainer : 'PanelMenu',
                                            formulario : true,
                                            titulo : 'Notificaciones',
                                            apiField : 'solicitudes_notificaciones', 
                                            enableBtnEdit : true,                                                                         
                                            formFields :  [
                                                            {
                                                                label : 'Tiempo de anticipacion para Notificaciones(Horas)',
                                                                field : '',
                                                                type  : 'separator',
                                                                validation : '',
                                                                required : ''                                        
                                                            },
                                                            {
                                                                label : 'Incidencias *',
                                                                field : 'tiempo_incidencia',
                                                                type  : 'text',
                                                                validation : 'entero',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el tiempo de anticipacion con que se notificara el vencimiento de las incidencias'                                          
                                                            },                                    
                                                            {
                                                                label : 'Problemas *',
                                                                field : 'tiempo_problema',
                                                                type  : 'text',
                                                                validation : 'entero',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el tiempo de anticipacion con que se notificara el vencimiento de los problemas'                                         
                                                            },
                                                            {
                                                                label : 'Servicios *',
                                                                field : 'tiempo_servicio',
                                                                type  : 'text',
                                                                validation : 'entero',
                                                                required : 'true', 
                                                                tooltip : 'Ingrese el tiempo de anticipacion con que se notificara el vencimiento de los servicios'                                        
                                                            }] 
                                        }
                                    } 
                                
                    />  
                </div>
            </div>             
        )
    } 
}

export default Notificaciones