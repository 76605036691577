/**
* CLASS AreasSla
*
* Contiene el contenedor principal de los acuerdos de nivel de servicio
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import FormDataGrid from '../../data_grid/FormDataGrid';
import Window from '../../window/Window';
import TBar from '../../tbar/TBar';
import {cargarFilas} from '../../api_calls/ApiCalls';
import globalState from '../../configuration/GlobalState';
import alertify from 'alertifyjs';

class AreasSla extends Component {
    constructor(props){
        super(props)
        this.state={
            idRow : 0,
            btnHoras : 'none',
            incidencias : 'false',
            problemas   : 'false',
            servicios   : 'false',
            database : globalState.getState().companyData[0].bd            
        };         
        let sqlParams = { 
                            sqlWhere : [
                                " AND id_area="+this.props.parametro.idArea
                            ],                                                                                                       
                        }

        cargarFilas('areas_sla','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{
                let idRow = 0;                
                if(response.length > 0){
                    idRow = response[0]; 
                    this.setState({ btnHoras: 'block' });                   
                }
                this.setState({ idRow: idRow });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        }); 
    }
    componentWillMount(){
        let sqlParams = {
                            sqlCols : [
                                "atencion_incidencias",
                                "atencion_problemas",
                                "atencion_servicios",
                            ],                        
                            sqlWhere : [ " AND id = '"+this.props.parametro.idArea+"'" ],                                
                        }
        cargarFilas('areas_servicio','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {                    
            var response = res.data;                       
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{
                this.setState({incidencias:response[0].atencion_incidencias,
                               problemas:response[0].atencion_problemas,
                               servicios:response[0].atencion_servicios});
            }
        })
        .catch(err => {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                                      
        });     
    }
    retrocederPanel(){
        this.props.funcionClick('AreasServicio');
    }
    ventanaHorasOperativas(){
        globalState.dispatch({
            type   : "windowHorasOperativas",
            params : {
                          visible : true,
                          params  : {                                       
                                        idRow : this.state.idRow.id,                                        
                                        idWin : "windowHorasOperativas",//identificador de la ventana
                                        width : '400px', 
                                        height: '400px'                                       
                                    }
                     }
        }); 
    }
    btnVisorLog(){
        globalState.dispatch({
            type   : "windowVisorLog",
            params : {
                          visible : true,
                          params  : {
                                        idRow : this.state.idRow.id,
                                        table : 'areas_sla',
                                        idWin : "windowVisorLog",
                                        width : '400px', 
                                        height: '300px'//identificador de la ventana
                                    }
                     }
        });
    }
  	render() {
        let fieldIncidencias  = [null,null,null,null,null];
        if(this.state.incidencias === 'true'){            
            fieldIncidencias[0]  =  {
                                        label : 'Tiempo de Atencion para Incidencias(Horas)',
                                        field : '',
                                        type  : 'separator',
                                        validation : '',
                                        required : ''                                        
                                    };
            fieldIncidencias[1]  =  {
                                        label : 'Prioridad Crítica *',
                                        field : 'incidencia_prioridad_4',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad crítica en incidencias(Horas)'                                        
                                    };
            fieldIncidencias[2]  =  {
                                        label : 'Prioridad Alta *',
                                        field : 'incidencia_prioridad_3',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad alta en incidencias(Horas)'                                        
                                    };                                    
            fieldIncidencias[3]  =  {
                                        label : 'Prioridad Media *',
                                        field : 'incidencia_prioridad_2',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad media en incidencias(Horas)'                                                                                
                                    };
            fieldIncidencias[4]  =  {
                                        label : 'Prioridad Baja *',
                                        field : 'incidencia_prioridad_1',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad baja en incidencias(Horas)'                                                                                
                                    };
        } 
        let fieldProblemas  = [null,null,null,null,null];
        if(this.state.problemas === 'true'){  
            fieldProblemas[0]   =   {
                                        label : 'Tiempo de Atencion para Problemas(Horas)',
                                        field : '',
                                        type  : 'separator',
                                        validation : '',
                                        required : ''                                        
                                    };
            fieldProblemas[1]   =   {
                                        label : 'Prioridad Crítica *',
                                        field : 'problema_prioridad_4',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad crítica en problemas(Horas)'                                                                                
                                    }; 
            fieldProblemas[2]   =   {
                                        label : 'Prioridad Alta *',
                                        field : 'problema_prioridad_3',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad alta en problemas(Horas)'                                                                                
                                    };                                    
            fieldProblemas[3]   =   {
                                        label : 'Prioridad Media *',
                                        field : 'problema_prioridad_2',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad media en problemas(Horas)'                                                                                
                                    };
            fieldProblemas[4]   =   {
                                        label : 'Prioridad Baja *',
                                        field : 'problema_prioridad_1',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad baja en problemas(Horas)'                                                                                
                                    };  
        }
        let fieldServicios  = [null,null,null,null,null];
        if(this.state.servicios === 'true'){
            fieldServicios[0]   =   {
                                        label : 'Tiempo de Atencion para Servicios(Horas)',
                                        field : '',
                                        type  : 'separator',
                                        validation : '',
                                        required : ''                                        
                                    };
            fieldServicios[1]   =   {
                                        label : 'Prioridad Crítica *',
                                        field : 'servicio_prioridad_4',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad crítica en servicios(Horas)'                                                                                
                                    };
            fieldServicios[2]   =   {
                                        label : 'Prioridad Alta *',
                                        field : 'servicio_prioridad_3',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad alta en servicios(Horas)'                                                                                
                                    };                                    
            fieldServicios[3]   =   {
                                        label : 'Prioridad Media *',
                                        field : 'servicio_prioridad_2',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad media en servicios(Horas)'                                                                                
                                    };
            fieldServicios[4]   =   {
                                        label : 'Prioridad Baja *',
                                        field : 'servicio_prioridad_1',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese el tiempo para prioridad baja en servicios(Horas)'                                                                                
                                    }; 
        }

        return (//carga el componente que contiene la grilla de datos 
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '80px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                              {
                                  type : 'boton',
                                  icon : 'update',
                                  width : '80px',
                                  height : '60px',
                                  title : 'Horarios',
                                  display : this.state.btnHoras,
                                  function : this.ventanaHorasOperativas.bind(this)
                              },
                              {
                                  type : 'boton',
                                  icon : 'description',
                                  width : '80px',
                                  height : '60px',
                                  title : 'Ver Log',
                                  display : this.state.btnHoras,
                                  function : this.btnVisorLog.bind(this)                                       
                              }
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>            
                    <FormDataGrid 
                        funcionClick = {this.props.funcionClick} 
                        parametro = {
                                        {
                                            idRow : this.state.idRow,
                                            mainContainer : 'AreasServicio',
                                            formulario : true,
                                            titulo : 'Configuracion SLA',
                                            apiField : 'areas_sla', 
                                            enableBtnEdit : true,  
                                            dataLog:{
                                                incidencia_prioridad_4 : "Prioridad Critica Incidencia",
                                                incidencia_prioridad_3 : "Prioridad Alta Incidencia",
                                                incidencia_prioridad_2 : "Prioridad Media Incidencia",
                                                incidencia_prioridad_1 : "Prioridad Baja Incidencia",
                                                problema_prioridad_4 : "Prioridad Critica Problema",
                                                problema_prioridad_3 : "Prioridad Alta Problema",
                                                problema_prioridad_2 : "Prioridad Media Problema",
                                                problema_prioridad_1 : "Prioridad Baja Problema",
                                                servicio_prioridad_4 : "Prioridad Critica Servicio",
                                                servicio_prioridad_3 : "Prioridad Alta Servicio",
                                                servicio_prioridad_2 : "Prioridad Media Servicio",
                                                servicio_prioridad_1 : "Prioridad Baja Servicio",
                                                tiempo_finalizacion : "Tiempo Finalizacion",                       
                                            },                                                                                          
                                            formFields :  [
                                                            fieldIncidencias[0],
                                                            fieldIncidencias[1],
                                                            fieldIncidencias[2],
                                                            fieldIncidencias[3],
                                                            fieldIncidencias[4],
                                                            fieldProblemas[0],
                                                            fieldProblemas[1],
                                                            fieldProblemas[2],
                                                            fieldProblemas[3],
                                                            fieldProblemas[4],
                                                            fieldServicios[0],
                                                            fieldServicios[1],
                                                            fieldServicios[2],
                                                            fieldServicios[3],
                                                            fieldServicios[4],                                        
                                                            {
                                                                label : 'Parametrizaciones Adicionales',
                                                                field : '',
                                                                type  : 'separator',
                                                                validation : '',
                                                                required : ''                                        
                                                            },                                                            
                                                            {
                                                                label : 'Tiempo de Finalizacion de Encuesta Satisfaccion(Dias) *',
                                                                field : 'tiempo_finalizacion',
                                                                type  : 'text',
                                                                validation : 'entero',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el tiempo para finalizacion de encuestas de satisfaccion(dias)'                                                                                
                                                            },
                                                            {
                                                                label : '',
                                                                field : 'id_area',
                                                                type  : 'hidden',
                                                                validation : '',
                                                                value : this.props.parametro.idArea,
                                                                required : 'true'                                        
                                                            },
                                                        ] 
                                        }
                                    } 
                                
                    />  
                </div>
                <Window 
                    id = "windowHorasOperativas"                      
                    title='Horas Operativas'                    
                    tbar="false"
                    componente="WindowHorasOperativas"
                    params="" 
                />
                <Window 
                    id = "windowVisorLog"                      
                    title='Ver Log'                                                
                    tbar="false"
                    componente="WindowVisorLog"
                    params="" 
                />  
            </div>             
        )
    } 
}

export default AreasSla