/**
* CLASS WindowVerCambiosLog
*
* Contiene el componente que visualiza los cambios realizados en los cambios
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import alertify from 'alertifyjs';

class WindowVerCambiosLog extends Component {
    constructor(props) {        
        super(props);           
        this.state = {
            objectDiffs : [], 
            database : globalState.getState().companyData[0].bd
        };      
    }
    async componentWillMount(prevProps,prevState){  
        try{
            // Obtener la información actual
            let datos = {};
            let datos2 = {};
            let sqlParams = { 
                                sqlCols : [
                                    "T1.datos",
                                    "tabla",
                                    "id_tabla"                                              
                                ],                                                          
                                sqlWhere : [ " AND T1.id = "+this.props.params.parametro.idRow.id ],                                
                            }
            let res = await cargarFilas('log','',100,0,'','',sqlParams,'rows',this.state.database);
            let response = res.data; 

            // Comparar la información actual con la anterior
            if(response.length > 0 && typeof response[0].datos === 'string'){

                datos = response[0];

                sqlParams = { 
                                sqlCols : [
                                    "T1.datos",                                              
                                ],                                                          
                                sqlWhere : [ " AND T1.id < "+this.props.params.parametro.idRow.id,
                                             " AND tabla = '"+datos.tabla+"' AND id_tabla = "+datos.id_tabla ],
                                sqlOrderBy : "T1.id DESC"                                
                            }
                res = await cargarFilas('log','',1,0,'','',sqlParams,'rows',this.state.database);
                response = res.data; 
                if(response.length > 0 && typeof response[0].datos === 'string'){
                    datos2 = response[0].datos;

                    //
                    datos = datos.datos;
                    datos = datos.split('{.}');
                    datos2 = datos2.split('{.}');
                    let objectDatos1 = {};
                    for(let key in datos){
                        let row = datos[key].split('=');
                        objectDatos1[row[0]] = row[1];
                    }

                    let objectDatos2 = {};
                    for(let key in datos2){
                        let row = datos2[key].split('=');
                        objectDatos2[row[0]] = row[1];
                    }

                    let objectDiffs = [];
                    for(let key in objectDatos1){
                        if(objectDatos1[key] !== objectDatos2[key] && objectDatos2[key] !== undefined){
                            objectDiffs.push(key+': "'+objectDatos2[key]+'" => "'+objectDatos1[key]+'"');
                        }
                    }

                    this.setState({objectDiffs : objectDiffs});                 
                }
            }  
        }
        catch(error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        }           
    }
    handleBotonAtras(){
        this.props.params.parametro.funcionClick('WindowVerDatosLog',{funcionClick:this.props.params.parametro.funcionClick,idRow:this.props.params.parametro.idRow});
    }
  	render() {       
  	  	return (<div id="contenedorTrazabilidad" style={{paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'270px',overflowY : 'auto' }}>   
                    <Button style={{margin: '5px 0'}} className="float-left mr-3" variant="secondary" onClick={this.handleBotonAtras.bind(this)}>
                        ATRAS
                    </Button>
                    <div>
                        <Table responsive>
                            <thead className="cf">
                                <tr>
                                    <th style={{fontSize:'12px'}}>Cambios Realizados</th>
                                </tr>
                            </thead>    
                            <tbody>
                            {
                                this.state.objectDiffs.map((content,i) => {
                                    let contentx = content.split(':') 
                                    return  <tr key={ i } >
                                                <td data-title="Campo" style={{fontSize:'11px'}}><span style={{fontWeight:'bold',fontSize:'11px'}}>{contentx[0]}:</span>{contentx[1]}</td> 
                                            </tr>            
                                })
                            }
                            </tbody>
                        </Table>
                    </div>                                   
                </div>);
  	}
}

export default WindowVerCambiosLog