/**
* CLASS WindowAgregarUsuariosCapacitacion
*
* Contiene el componente que agrega los usuarios a la capacitacion
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,insertarActualizarFila} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import {modalLoading} from '../configuration/GlobalFunctions';
import TBar from '../tbar/TBar';
import Form from 'react-bootstrap/Form';
import alertify from 'alertifyjs';

class WindowEmpresasModulos extends Component {
    constructor(props) {        
        super(props);   
        this.state = {
            objUsuarios : '',
            idDepartamento : 0,
            arrayDepartamentos : [],
            arrayUsuarios : [],
            database : globalState.getState().companyData[0].bd
        };        
    } 
    componentWillMount(){        
        let sqlParams = {
                            sqlCols : [
                                'id',
                                'nombre'                                
                            ],                                                                                                                
                        };                                                                
        cargarFilas('departamentos','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{
                this.setState({arrayDepartamentos : response});                
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })
    }
    handleChangeDepartamento(e){
        let idDepartamento = e.target.value;        
        this.setState({idDepartamento : idDepartamento},()=>{
            this.cargarUsuarios();
        });
    }
    cargarUsuarios(){
        let whereDepartamento = " AND id_departamento="+this.state.idDepartamento;
        if(this.state.idDepartamento === 'todos'){
            whereDepartamento = '';
        }
        let sqlParams = { 
                            sqlCols : [ 
                                "id",
                                "CONCAT(primer_nombre,' ',primer_apellido) AS nombre",
                            ],                                                     
                            sqlWhere : [
                                whereDepartamento,
                                " AND id NOT IN(SELECT id_usuario FROM capacitaciones_usuarios WHERE id_capacitacion = "+this.props.params.idRow+")"
                            ]
                        }
        cargarFilas('users','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => { 
            response = response.data;            
            if(response.msg === 'error'){//aqui no me dejara continuar si la empresa noe xiste
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail); 
            }
            else{
                response.map((content,i) => { 
                    this.setState({ ['usuario_'+content.id]: false });
                    return null;
                });
                this.setState({arrayUsuarios:response});
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    }    
    handleCheckBox(e){//control del check de los checkbox
        var checkBox = e.target.name;
        var checked  = e.target.checked; 
        if(checked === true){
            let id_usuario = checkBox.split('_');
            let objectUsuario = {
                id_usuario : id_usuario[1],
                id_capacitacion : this.props.params.idRow
            };
            this.setState({ [checkBox]: true });
            modalLoading(true);
            insertarActualizarFila('post','capacitaciones_usuarios',objectUsuario,'false',undefined,this.state.database).then(response => {               
                response = response.data;   
                if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                    modalLoading(false);
                }
                else{
                    this.cargarUsuarios();
                    modalLoading(false);               
                }     
            })
            .catch(function (error) {
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            });
        }
    }    
    btnRetroceder(){
        this.props.funcionClick('WindowUsuariosCapacitaciones');
    }    
  	render() {             
  	  	return (
                <div style={{height:'calc(100% - 30px)'}}>
                    <TBar
                        items={[
                                {
                                    type : 'boton',
                                    icon : 'arrow_back',
                                    width : '100px',
                                    height : '60px',
                                    title : 'Atras',
                                    display : true,
                                    function : this.btnRetroceder.bind(this)
                                },                                                              
                              ]}
                        length = '1'
                    />
                    <div style={{position:"relative",top:'60px',paddingRight: '7px',paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'calc(100% - 60px)'}}>   
                        <Form>
                            <Form.Group controlId="formFieldArea" style={{}}>
                                <Form.Label>Departamento *</Form.Label>                       
                                <Form.Control as="select" name = "id_area" onChange={this.handleChangeDepartamento.bind(this)} value={this.state.idDepartamento}>
                                    <option value="">Seleccione...</option>
                                    <option value="todos">TODOS</option>
                                    {
                                        this.state.arrayDepartamentos.map((content,i) => { 
                                            return <option key={i} value={content.id} >{content.nombre}</option>
                                        })
                                    }                            
                                </Form.Control>  
                            </Form.Group>
                        </Form>
                        <div id="contenedorUsuarios" style={{position:"relative",paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'calc(100% - 60px)',overflowY : 'auto' }}>  
                            {                                
                                this.state.arrayUsuarios.map((objUsuarios,i) => {
                                    var padding = 5;
                                    var fontWeight = 'bold';                                                                                    
                                    return <div key={i} style={{width:'100%',height:'24px'}}>
                                                <div style={{float:'left'}}>
                                                    <input name={'usuario_'+objUsuarios.id} type="checkbox" onChange={this.handleCheckBox.bind(this)} checked={this.state['usuario_'+objUsuarios.id] || false } />
                                                </div>
                                                <div style={{paddingLeft:padding+'px',float:'left',fontWeight:fontWeight,fontSize:'11px' }}>
                                                    {objUsuarios.nombre} 
                                                </div>
                                            </div>
                                })                                
                            }                                                      
                        </div>
                          
                    </div> 
                </div> 				
			  );
  	}
}

export default WindowEmpresasModulos