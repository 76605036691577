/**
* CLASS WindowUploadArchivo
*
* Contiene el componente que sube los archivos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import img_upload from './../../images/upload_file.jpg?v1.0';
import {ValidateExtension} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import {uploaderFile,obtenerConsumoAlmacenamiento} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import configJson from '../configuration/configuration.json';
import './adjuntos.css';

class WindowUploadArchivo extends Component {
    constructor(props){
        super(props);
        this.state = {
            espacioOcupado : 0,
            espacioTotal : globalState.getState().companyData[0].cuota_almacenamiento,
            database : globalState.getState().companyData[0].bd
        };
    }
    componentWillMount(){
        obtenerConsumoAlmacenamiento(this.state.database)
        .then(res => {
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando al sistema de archivos!<br />Codigo de Error: '+res.detail);
            } 
            else{                                          
                this.setState({espacioOcupado : res});                                                   
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }    
    handleArchivoAdjunto(e){
        //subir la imagen del usuario
        var file   = e.target.files[0];
        var folder = globalState.getState().companyData[0].bd;     
        var allowedFiles = [".pdf",".jpg", ".jpeg", ".png"];
        if(this.props.params.allowedExt !== undefined){
            allowedFiles = this.props.params.allowedExt;
        }
        if(!ValidateExtension(allowedFiles,file.name)){
            alertify.alert('Error!', 'Solo se permiten imagenes con extension pdf, jpeg, jpg o png!');
        }
        else{   
            let maxSize = configJson.maxDocumentsSize;     
            if(this.props.params.maxSize > 0){
                maxSize = this.props.params.maxSize;
            }    
            if(file.size > maxSize){
                let size = '';
                if(maxSize/1048576 >= 1){
                    size = maxSize/1048576;
                    size = size.toFixed(1)+'M';
                }
                else{
                    size = maxSize/1024;
                    size = size.toFixed(1)+'K';
                }
                alertify.alert('Error!', 'Solo se permiten imagenes de maximo '+size+'!');
            }
            else{
                //verificar si hay espacio libre
                let espacioOcupado = (this.state.espacioOcupado*1048576)+file.size;
                let espacioTotal = this.state.espacioTotal*1048576;
                let espacioLibre = espacioTotal-espacioOcupado;
                if(espacioLibre < 0){
                    espacioLibre = 0;
                }
                if(espacioOcupado > espacioTotal){
                    alertify.alert('Error!', 'Se ha superado la cuota de almacenamiento!<br>Espacio Total:'+this.state.espacioTotal+' MB<br>Espacio Libre:'+espacioLibre+' MB');
                    return;
                }
                uploaderFile(file,this.props.params.table+'_adjuntos','nombre_archivo',this.props.params.idRow+'_'+globalState.getState().userData[0].id,folder+'/'+this.props.params.table+'_adjuntos/',folder).then(res => {
                    var response = res.data; 
                    if (response.msg === "error") {
                        alertify.alert('Error!', 'Ha ocurrido un error subiendo el archivo!<br />Codigo de Error: '+response.detail);
                    } else { 
                        globalState.dispatch({
                            type   : "windowUploadArchivo",
                            params : {
                                          visible : false, 
                                     }
                        });  
                        globalState.dispatch({//refrescar el listado
                            type   : "updateUploadArchivo",
                            params : { 
                                fileName : response.detail  
                            }
                        });                             
                    }
                })
                .catch( err => {            
                    alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
                }); 
            }
        } 
    }
  	render() {             
  	  	return (
             <div id="uploaderImageUser">
                  <div className="divImageUploader">
                      <img id="ImageUploader" alt="imagen_usuario" src={img_upload} />
                      <input id="inputUploader" type="file" name="inputUploader" onChange={this.handleArchivoAdjunto.bind(this)}/>
                  </div>
              </div>
        )
  	}
}

export default WindowUploadArchivo