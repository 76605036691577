/**
* CLASS Departamentos
*
* Contiene el contenedor principal de los departamentos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../../data_grid/DataGrid';
import Window from '../../window/Window';
import {cargarFilas} from '../../api_calls/ApiCalls';
import globalState from '../../configuration/GlobalState';
import TBar from '../../tbar/TBar';

class Departamentos extends Component {
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    } 
    colFuncion(idDepartamento){        
        globalState.dispatch({
                type   : "windowUbicaciones",
                params : {
                              visible : true,
                              params  : {
                                            idDepartamento : idDepartamento,
                                            idWin  : "windowUbicaciones",//identificador de la ventana,
                                            width  : '500px', 
                                            height : '300px'
                                        }
                         }
            }); 
    }
    async validacionDeleteDepartamento(id){
        try{
            // Validar que no hayan usuarios en el departamento
            let database = globalState.getState().companyData[0].bd;
            let sqlParams = {
                                sqlCols : [
                                    "COUNT(id) AS total"                                             
                                ],                            
                                sqlWhere : [
                                    " AND id_departamento = "+id//no cuenta superusuarios
                                ],                              
                            }; 
            let res = await cargarFilas('users','',1000,0,'','',sqlParams,'rows',database);
            let response = res.data; 
            if(response.length > 0 && response[0].total > 0){
                return {  status : false, message : "El departamento no se puede eliminar, tiene usuarios activos vinculados!" }; 
            }
            
            return {  status : true, message : "" }; 
               
        }           
        catch(err){
            return {  status : false, message : 'No se ha logrado la conexion con el servidor!<br />'+err.message }; 
        }     
    }

  	render() { 
        let sqlParams = { 
                            fieldSearch : [
                                'nombre',                                
                            ],                                                       
                            filtroFechas : "false",                                                                             
                        };                        
        return (//carga el componente que contiene la grilla de datos 
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>  
                    <DataGrid titulo='Departamentos' 
                              funcionClick={this.props.funcionClick}   
                              parametro={this.props.parametro} 
                              funcionBeforeDelete={this.validacionDeleteDepartamento.bind(this)}
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Nombre',
                                                field : 'nombre'
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'settings',
                                                colFuncion : this.colFuncion.bind(this)
                                            },
                                        ]}
                              sqlParams = { sqlParams }                       
                              automatica="true"
                              botonNuevo="true"
                              formFields={[
                                            {
                                                label : 'Nombre *',
                                                field : 'nombre',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                required : 'true',
                                                longitud : 35,
                                                tooltip : 'Ingrese el nombre del departamento'
                                            },                               
                                        ]}                     
                              apiField={'departamentos'}
                              mainContainer='Departamentos'/> 
                </div>
                <Window 
                    id = "windowUbicaciones"                      
                    title='Ubicaciones'                        
                    tbar="false"
                    componente="WindowUbicaciones"
                    params="" 
                />
            </div>            
        )
    } 
}

export default Departamentos