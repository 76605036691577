/**
* CLASS WindowAgregarIcono
*
* Contiene el componente que lista los permisos del rol
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import globalState from '../../configuration/GlobalState';
import {insertarActualizarFila} from '../../api_calls/ApiCalls';
import MaterialUiIconPicker from 'react-material-ui-icon-picker';
import alertify from 'alertifyjs';

class WindowAgregarIcono extends Component {
    showPickedIcon(icono){
        let database = globalState.getState().companyData[0].bd;
        let objectData = {
            id : this.props.params.idTipo,
            icono : icono.name,                        
        } 
        insertarActualizarFila('put','activos_tipos',objectData,'false',undefined,database).then(response => {        
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error                
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{
                alertify.alert('Aviso!', 'Se ha cargado la imagen con exito!');
                this.props.params.funcionClick('WelcomePage');
                this.props.params.funcionClick('ActivosTipos');
                globalState.dispatch({
                    type   : "windowAgregarIcono",
                    params : {
                                  visible : false,                                  
                             }
                }); 
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });        
    }
  	render() {             
  	  	return (
                <div id="contenedorPermisos" style={{textAlign:'center',paddingTop: '22px',height:'60px',overflowY : 'auto' }}>   
                    <MaterialUiIconPicker 
                        onPick={this.showPickedIcon.bind(this)} 
                        label="Seleccionar Icono" 
                        pickLabel="Cargar"
                        cancelLabel="Cancelar"
                        modalTitle="Seleccionar Icono"
                    />
                </div>  				
			  );
  	}
}

export default WindowAgregarIcono