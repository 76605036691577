/**
* CLASS BotonNotificaciones
*
* Contiene el contenedor del boton de notificaciones
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import globalState from '../configuration/GlobalState';
import Window from '../window/Window';
import notifications from '../../images/notifications.png?v1.0';
import configJson from '../configuration/configuration.json';
import {divMouseOver,divMouseOut} from '../configuration/GlobalFunctions';
import notifications_active from '../../images/notifications_active.png?v1.0';
import './desktop.css';

let componentList = {
      notifications  : notifications,
      notifications_active : notifications_active,
}

class BotonNotificaciones extends Component {	          
    //abrir la modal
    handlePendientes(){//boton de abrir modal        
        globalState.dispatch({
                type   : "windowVerPendientes",
                params : {
                            visible : true,
                            params  : {
                                width  : '300px', 
                                height : '240px'
                            }
                         }
            });
    }
    render() {  
    	  	return (//carga el menu de opciones del usuario  	  	
                  <div>
                      <div id="ContenidoBotonNotificaciones" style={{cursor:'pointer'}} onClick={this.handlePendientes.bind(this)} onMouseOut={divMouseOut.bind(this,'ContenidoBotonNotificaciones',configJson.fondoMenu)} onMouseOver={divMouseOver.bind(this,'ContenidoBotonNotificaciones',configJson.fondoMenu)}>  
                          <img src={componentList[this.props.icon]} alt={this.props.icon} />                        
                      </div>                        
                      <Window 
                          id = "windowVerPendientes"                      
                          title='Ver Pendientes'                                               
                          tbar='false'
                          componente="WindowVerPendientes"
                          params="" 
                      />                  
      			      </div>	              	    		
	     );
    }
}

export default BotonNotificaciones