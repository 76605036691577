/**
* CLASS Container
*
* Contiene el contenedor que permite renderizar lo que se requiera en la interfaz
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Dashboard from '../dashboard/Dashboard';
import Proveedores from '../terceros/Proveedores';
import Clientes from '../terceros/Clientes';
import Activos from '../activos/Activos';
import Incidencias from '../incidencias/Incidencias';
import Problemas from '../problemas/Problemas';
import Servicios from '../servicios/Servicios';
import Conocimiento from '../conocimiento/Conocimiento';
import SeleccionConocimiento from '../conocimiento/SeleccionConocimiento';
import PanelMenu from '../control_panel/PanelMenu';
import DocumentTypes from '../control_panel/DocumentTypes';
import ActivosTipos from '../control_panel/activos_tipos/ActivosTipos';
import Departamentos from '../control_panel/departamentos/Departamentos';
import AreasServicio from '../control_panel/areas_servicio/AreasServicio';
import EncuestaSatisfaccion from '../control_panel/EncuestaSatisfaccion';
import EncuestaProveedores from '../control_panel/EncuestaProveedores';
import EncuestaClientes from '../control_panel/EncuestaClientes';
import PoliticasSeguridad from '../control_panel/PoliticasSeguridad';
import Notificaciones from '../control_panel/Notificaciones';
import Companies from '../control_panel/companies/Companies';
import AreasSla from '../control_panel/areas_servicio/AreasSla';
import Roles from '../control_panel/roles/Roles';
import Users from '../control_panel/users/Users';
import Smtp from '../control_panel/Smtp';
import Imap from '../control_panel/imap/Imap';
import ImapReglas from '../control_panel/imap/ImapReglas';
import FormDataGrid from '../data_grid/FormDataGrid';
import DashboardActivos from '../activos/DashboardActivos';
import Capacitaciones from '../capacitaciones/Capacitaciones';
import Reports from '../reports/Reports';
import WelcomePage from './WelcomePage';
import TercerosContratos from '../terceros/TercerosContratos';
import ImprimirFichaTecnica from '../activos/ImprimirFichaTecnica';
import TercerosContratosPagos from '../terceros/TercerosContratosPagos';
import ActivosTiposCamposFicha from '../control_panel/activos_tipos/ActivosTiposCamposFicha';
import Moneda from '../control_panel/Moneda';
import Calendario from '../calendario/Calendario';
import './desktop.css';

class Container extends Component {
	// listado de componentes 		
  	render() {
  		let componentList = {
			WelcomePage    : WelcomePage,
			Dashboard      : Dashboard,
			Proveedores    : Proveedores,
			Clientes       : Clientes,			
			Activos        : Activos,
			Incidencias    : Incidencias,
			Problemas      : Problemas,
			Servicios      : Servicios,
			Conocimiento   : Conocimiento,
			SeleccionConocimiento   : SeleccionConocimiento,
			PanelMenu      : PanelMenu,			
			DocumentTypes  : DocumentTypes,							
			Companies      : Companies,
			Roles          : Roles,
			Users 		   : Users,
			ActivosTipos   : ActivosTipos,	
			DashboardActivos : DashboardActivos,
			Departamentos  : Departamentos,
			FormDataGrid   : FormDataGrid,			
			Reports  	   : Reports,
			Smtp           : Smtp,	
			Imap           : Imap,	
			AreasServicio  : AreasServicio,
			EncuestaSatisfaccion : EncuestaSatisfaccion,
			EncuestaProveedores : EncuestaProveedores,
			Notificaciones : Notificaciones,
			PoliticasSeguridad : PoliticasSeguridad,
			AreasSla : AreasSla,   
			ActivosTiposCamposFicha : ActivosTiposCamposFicha,
			TercerosContratos : TercerosContratos,
			TercerosContratosPagos : TercerosContratosPagos,
			ImprimirFichaTecnica : ImprimirFichaTecnica,
			Moneda : Moneda,
			ImapReglas : ImapReglas,			
			Calendario : Calendario,
			EncuestaClientes : EncuestaClientes,
			Capacitaciones : Capacitaciones 								  
		} 
		
  		let ChildComponent = componentList[this.props.componente];		
  	  	return (  		  
  	  		<ChildComponent funcionClick={this.props.funcionClick} parametro={this.props.parametro} />  	  		    
	    );
  	}
}

export default Container