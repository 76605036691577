/**
* CLASS ReportBotonExcel
*
* Contiene el contenedor con la impresion en excel de los reportes
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import icono_xls from '../../images/icon_excel.png?v1.0';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class ReportBotonExcel extends Component {

	render() {
		return ( 
			<div style={{float:'right'}}>
			    <img src={ icono_xls } alt="Excel" />                                        
			    <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table={this.props.idTabla}
                    filename={this.props.idTabla}
                    sheet="tablexls"
                    buttonText="Excel"
                />
			</div>
		);
	}
}

export default ReportBotonExcel