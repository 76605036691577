/**
* CLASS DashboardContainer
*
* Contiene el contenedor con los indicadores
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DashboardOption from './DashboardOption';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import { diccionario } from '../configuration/configuration.json';
import alertify from 'alertifyjs';
import '../../css/alertify.css';
import './dashboard.css';

const { LabelsGeneralContainer } = diccionario;

class DashboardContainer extends Component {
    constructor(props){
        super(props);          
        this.state={
            indicadores : {
                indicadorPuntClientes : 0,
                indicadorPuntProveedores : 0,
                indicadorCapacitaciones : 0,  
                graficoTortaPuntClientes : [],
                graficoTortaPuntProveedores : [],
                graficoTortaIntCapacitaciones : [],
                graficoApiladoCapacitaciones : [],
                stateYear1 : '',
            },
            permisos : {
                displayCliente : 'none',
                displayProveedor : 'none',
                displayCapacitaciones : 'none',                                
            },
            idioma : globalState.getState().idioma,
            database : globalState.getState().companyData[0].bd           
        };        
    }
    componentWillMount(){
        this.cargarIndicadores(this.props.params.date1,this.props.params.date2,this.props.params.usuario,this.props.params.rol_usuario,this.props.params.filtroArea,this.props.params.permisos);         
    } 
    componentDidUpdate(prevProps){
        if (this.props !== prevProps) {              
            this.cargarIndicadores(this.props.params.date1,this.props.params.date2,this.props.params.usuario,this.props.params.rol_usuario,this.props.params.filtroArea,this.props.params.permisos); 
        }
    }
    async cargarIndicadores(date1,date2,idUser,rolUser,filtroArea,permisos){  //cargar los indicadores                             
        try{
            var verOtros = "true";        
            if(idUser === 0){
                idUser = globalState.getState().userData[0].id;
                verOtros = "false";
            }
            let arrayPermisos = {}; 
            //ver los indicadores de clientes,proveedores y capacitaciones 
            let displayCliente = 'none';
            let displayProveedor = 'none';
            let displayCapacitaciones = 'none';
            //validar los permisos
            if(permisos.indicadoresClientes === true){                
                displayCliente = 'block';                
            }  
            if(permisos.indicadoresProveedores === true){
                displayProveedor = 'block';                
            } 
            if(permisos.indicadoresCapacitaciones === true){
                displayCapacitaciones = 'block';                
            } 
            //indicadores de servicio        
            arrayPermisos = { 
                displayCliente : displayCliente,
                displayProveedor : displayProveedor,
                displayCapacitaciones : displayCapacitaciones,                                    
            }; 
                   
            this.setState({permisos : arrayPermisos},()=>{
                this.ejecutarQuerysDashboard(date1,date2,filtroArea,verOtros);
            });
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message);
        }
    }

    async ejecutarQuerysDashboard(date1,date2,filtroArea,verOtros){
        try{ 
            let permisos = this.state.permisos;                     
            //formato de la fecha
            date1 = new Date(date1.getTime() - (date1.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            date2 = new Date(date2.getTime() - (date2.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            //cargar los indicadores
            //indicador 1 de incidencias 
            var year1 = date1.substring(0,4);  
          
            let indicadorPuntClientes = '';
            let indicadorPuntProveedores = '';
            let indicadorCapacitaciones = 0;  
            let graficoTortaPuntClientes = [];    
            let graficoTortaPuntProveedores = []; 
            let graficoTortaIntCapacitaciones = [];
            let graficoApiladoCapacitaciones = [];
            let stateYear1 = year1;
            let i = 0;            

            //indicador de satisfaccion de clientes
            let sqlParams = '';
            if(permisos.displayCliente === 'block'){                            
                //obtener el promedio
                sqlParams = {
                                sqlCols : [
                                    'ROUND(AVG(T1.promedio)) AS promedio'
                                ],
                                sqlJoin : [                                    
                                    "INNER JOIN terceros AS T ON(T.id = T1.id_tercero AND T.activo = 1 AND T.cliente = 'true')"                                                              
                                ],
                                sqlWhere : [ " AND tipo='cliente'" ],                                
                                fieldFechas : "T1.fecha",
                                fecha_completa : "true"                                                     
                            }
                let res = await cargarFilas('encuesta_terceros','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                if(response.length > 0){
                    indicadorPuntClientes = response[0].promedio;
                }
                if(!indicadorPuntClientes>0){
                    indicadorPuntClientes = 0;
                } 
            }
            if(permisos.displayProveedor === 'block'){            
                //obtener el promedio
                sqlParams = {
                                sqlCols : [
                                    'ROUND(AVG(T1.promedio)) AS promedio'
                                ],
                                sqlJoin : [                                    
                                    "INNER JOIN terceros AS T ON(T.id = T1.id_tercero AND T.activo = 1 AND T.proveedor = 'true')"                                                              
                                ],
                                sqlWhere : [ " AND tipo='proveedor'" ],                                
                                fieldFechas : "T1.fecha",
                                fecha_completa : "true"                                                     
                            }
                let res = await cargarFilas('encuesta_terceros','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                if(response.length > 0){
                    indicadorPuntProveedores = response[0].promedio; 
                } 
                if(!indicadorPuntProveedores>0){
                    indicadorPuntProveedores = 0;
                }                             
            }
            if(permisos.displayCapacitaciones === 'block'){            
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total'
                                ],
                                sqlJoin : [
                                    "INNER JOIN capacitaciones AS C ON (C.activo = 1 AND C.id = T1.id_capacitacion)",
                                    "INNER JOIN users AS U ON (U.activo = 1 AND U.id = T1.id_usuario)"                                                           
                                ],
                                fieldFechas : "C.fecha_inicio",                                                                                
                            }
                let res = await cargarFilas('capacitaciones_usuarios','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                let total = 0;
                if(response.length > 0){
                    total = response[0].total;
                } 
                //contar la asistencia
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total'
                                ],
                                sqlJoin : [
                                    "INNER JOIN capacitaciones AS C ON (C.activo = 1 AND C.id = T1.id_capacitacion)",
                                    "INNER JOIN users AS U ON (U.activo = 1 AND U.id = T1.id_usuario)"                                                           
                                ],
                                sqlWhere : [
                                    " AND T1.asistencia = 'true'"
                                ],                                                               
                                fieldFechas : "C.fecha_inicio",                                                                                
                            }
                res = await cargarFilas('capacitaciones_usuarios','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                response = res.data;
                let asistentes = 0;
                if(response.length > 0){
                    asistentes = response[0].total;
                }
                let asistencia = 0;
                if(total > 0){
                    asistencia = asistentes/total;
                }
                indicadorCapacitaciones = (Math.round(asistencia*100))+'%';              
            }
            //indicador grafico de incidencias por puntaje
            if(permisos.displayCliente === 'block'){ 
                sqlParams = {
                                sqlCols : [                                
                                    'ROUND(T1.promedio) AS puntaje',
                                    'COUNT(T1.id) AS total'                                
                                ],                        
                                sqlJoin : [                                    
                                    "INNER JOIN terceros AS T ON(T.id = T1.id_tercero AND T.activo = 1 AND T.cliente = 'true')"                                                              
                                ],                                
                                sqlWhere : [ " AND T1.tipo='cliente'" ],                                
                                fieldFechas : "T1.fecha",
                                sqlGroupBy : "puntaje",
                                sqlOrderBy : "puntaje",
                                fecha_completa : "true"                                            
                            }
                let res = await cargarFilas('encuesta_terceros','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;                                
                let arrayPuntClientes = [['Puntaje', 'Total']];                
                for(i in response){
                    arrayPuntClientes.push(['PUNTAJE '+response[i].puntaje,response[i].total]);                    
                }                         
                graficoTortaPuntClientes = arrayPuntClientes;                
            } 
            //indicador grafico de incidencias por puntaje
            if(permisos.displayProveedor === 'block'){ 
                sqlParams = {
                                sqlCols : [                                
                                    'ROUND(T1.promedio) AS puntaje',
                                    'COUNT(T1.id) AS total'                                
                                ],                        
                                sqlJoin : [                                    
                                    "INNER JOIN terceros AS T ON(T.id = T1.id_tercero AND T.activo = 1 AND T.proveedor = 'true')"                                                              
                                ],                                
                                sqlWhere : [ " AND T1.tipo='proveedor'" ],                                
                                fieldFechas : "T1.fecha",
                                sqlGroupBy : "puntaje",
                                sqlOrderBy : "puntaje",
                                fecha_completa : "true"                                            
                            }
                let res = await cargarFilas('encuesta_terceros','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;                                
                let arrayPuntProveedores = [['Puntaje', 'Total']];                
                for(i in response){
                    arrayPuntProveedores.push(['PUNTAJE '+response[i].puntaje,response[i].total]);                    
                }                         
                graficoTortaPuntProveedores = arrayPuntProveedores; 
            }
            //indicador grafico de incidencias por puntaje
            if(permisos.displayCapacitaciones === 'block'){ 
                sqlParams = {
                                sqlCols : [                                
                                    'nombre',
                                    'intensidad',                                                                  
                                ],
                                fieldFechas : "fecha_inicio",                                           
                            }
                let res = await cargarFilas('capacitaciones','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;                                
                let arrayPuntIntCapacitaciones = [['Nombre', 'Intensidad']];                
                for(i in response){
                    arrayPuntIntCapacitaciones.push([response[i].nombre,response[i].intensidad]);                    
                }                         
                graficoTortaIntCapacitaciones = arrayPuntIntCapacitaciones;                
            }
            //indicador grafico de incidencias apilado 
            if(permisos.displayCapacitaciones === 'block'){       
                //total de capacitaciones
                sqlParams = {
                                sqlCols : [
                                    'T1.id_capacitacion',
                                    'C.nombre AS capacitacion',
                                    "SUM(IF(T1.asistencia='true',1,0)) AS asistencia",
                                    "SUM(IF(T1.asistencia='false',1,0)) AS inasistencia",                                                                  
                                ], 
                                sqlJoin : [
                                    "INNER JOIN capacitaciones AS C ON (C.activo = 1 AND C.id = T1.id_capacitacion)",
                                    "INNER JOIN users AS U ON (U.activo = 1 AND U.id = T1.id_usuario)"                                                           
                                ],                                
                                sqlGroupBy  : "T1.id_capacitacion",
                                sqlOrderBy  : "T1.id_capacitacion",                                                                                               
                                fieldFechas : "C.fecha_inicio",                                            
                            }

                let res = await cargarFilas('capacitaciones_usuarios','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                let response = res.data;                  

                let arrayApiladoCapacitaciones = [['ESTADO', 'ASISTENCIA','INASISTENCIA']];

                let x = 0;
                for(i in response){
                    x++;
                    arrayApiladoCapacitaciones.push([response[i].capacitacion,response[i].asistencia*1,response[i].inasistencia*1]);                    
                } 
                if(x===0){
                    arrayApiladoCapacitaciones.push(['',0,0]);                    
                }                                           
                graficoApiladoCapacitaciones = arrayApiladoCapacitaciones;                        
            }          

            let arrayIndicadores = {
                indicadorPuntClientes : indicadorPuntClientes,
                indicadorPuntProveedores : indicadorPuntProveedores,
                indicadorCapacitaciones : indicadorCapacitaciones,
                graficoTortaPuntClientes : graficoTortaPuntClientes, 
                graficoTortaPuntProveedores : graficoTortaPuntProveedores,
                graficoTortaIntCapacitaciones : graficoTortaIntCapacitaciones,
                graficoApiladoCapacitaciones : graficoApiladoCapacitaciones,               
                stateYear1  : stateYear1,
            }            
            this.setState({indicadores : arrayIndicadores});
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message);
        }
    }     
  	render() {        
        let permisos = this.state.permisos;
        let indicadores = this.state.indicadores; 
        //LOS TIPOS DE GRAFICO SON PieChart y ColumnChart                    
        return ( 
            <div id="DashboardContenedor">
                    <ul>
                        <div style={{display:permisos.displayCliente}}>
                            <DashboardOption                              
                                titulo={LabelsGeneralContainer.Ind1GeneralContainer[this.state.idioma]}                            
                                tipo="cifra" 
                                icono="star"
                                colorIcono="orange"
                                valor={indicadores.indicadorPuntClientes}
                            />
                        </div>
                        <div style={{display:permisos.displayProveedor}}> 
                            <DashboardOption                              
                                titulo={LabelsGeneralContainer.Ind2GeneralContainer[this.state.idioma]}                            
                                tipo="cifra" 
                                icono="star"
                                colorIcono="red"
                                valor={indicadores.indicadorPuntProveedores}
                            /> 
                        </div>
                        <div style={{display:permisos.displayCapacitaciones}}>
                            <DashboardOption                              
                                titulo={LabelsGeneralContainer.Ind3GeneralContainer[this.state.idioma]}                            
                                tipo="cifra"
                                icono="star"
                                colorIcono="blue" 
                                valor={indicadores.indicadorCapacitaciones}
                            /> 
                        </div> 
                        <div style={{display:permisos.displayCliente}}>                     
                            <DashboardOption                             
                                titulo={LabelsGeneralContainer.Ind4GeneralContainer[this.state.idioma]}                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaPuntClientes,
                                                    options    : {
                                                                     title: LabelsGeneralContainer.Ind4GeneralContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right:5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <div style={{display:permisos.displayProveedor}}>                     
                            <DashboardOption                             
                                titulo={LabelsGeneralContainer.Ind5GeneralContainer[this.state.idioma]}                           
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaPuntProveedores,
                                                    options    : {
                                                                     title: LabelsGeneralContainer.Ind5GeneralContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right:5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <div style={{display:permisos.displayCapacitaciones}}>                     
                            <DashboardOption                             
                                titulo='Capacitaciones por Intensidad Horaria'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaIntCapacitaciones,
                                                    options    : {
                                                                     title: LabelsGeneralContainer.Ind6GeneralContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right:5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },                                                                     
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <div style={{display:permisos.displayCapacitaciones}}>
                            <DashboardOption                             
                                titulo='Asistencia'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoCapacitaciones,
                                                    options    : {
                                                                     title: LabelsGeneralContainer.Ind7GeneralContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            /> 
                        </div>                       
                        <br />
                        <br />                                                               
                    </ul>
                </div>
        );
    } 
}

export default DashboardContainer
