/**
* CLASS WindowHorasOperativas
*
* Contiene el componente que lista los conocimientos por publicar
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,insertarActualizarFila} from '../../api_calls/ApiCalls';
import globalState from '../../configuration/GlobalState';
import Col from 'react-bootstrap/Col';
import TBar from '../../tbar/TBar';
import Form from 'react-bootstrap/Form';
import alertify from 'alertifyjs';
import {modalLoading} from '../../configuration/GlobalFunctions';
import '../../data_grid/dataGrid.css'; 

class WindowHorasOperativas extends Component {
    constructor(props) {        
        super(props);   
        this.state = {             
            hora_inicio : '',
            hora_fin : '',
            tiempoCompleto : true,
            intervalo : false,
            lunes : true,
            martes : true,
            miercoles : true,
            jueves : true,
            viernes : true,
            sabado : true,
            domingo : true,
            database : globalState.getState().companyData[0].bd             
        };
    }
    componentWillMount(){
        let sqlParams = {
                            sqlCols  : [
                                "hora_inicio_operativa",
                                "hora_fin_operativa",
                                "tiempo_completo",
                                "lunes",
                                "martes",
                                "miercoles",
                                "jueves",
                                "viernes",
                                "sabado",
                                "domingo",
                            ],                           
                            sqlWhere : [ " AND T1.id = "+this.props.params.idRow ],                                
                        }
        cargarFilas('areas_sla','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{          
                this.setState({                    
                    hora_inicio : response[0].hora_inicio_operativa,
                    hora_fin : response[0].hora_fin_operativa,
                    tiempoCompleto : JSON.parse(response[0].tiempo_completo),
                    intervalo : !JSON.parse(response[0].tiempo_completo),
                    lunes : JSON.parse(response[0].lunes),
                    martes : JSON.parse(response[0].martes),
                    miercoles : JSON.parse(response[0].miercoles),
                    jueves : JSON.parse(response[0].jueves),
                    viernes : JSON.parse(response[0].viernes),
                    sabado : JSON.parse(response[0].sabado),
                    domingo : JSON.parse(response[0].domingo), 
                });                        
            }     
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })   
    }
    handleHoraInicio(e){
        var hora_inicio = e.target.value;
        if(hora_inicio === null || hora_inicio === '' || hora_inicio === undefined){
            hora_inicio = '';
        }
        this.setState({hora_inicio:hora_inicio});
    }
    handleHoraFinal(e){
        var hora_fin = e.target.value;
        if(hora_fin === null || hora_fin === '' || hora_fin === undefined){
            hora_fin = '';
        }
        this.setState({hora_fin:hora_fin});        
    }
    handleTiempoCompleto(e){
        //var radioButton = e.target.name;
        var checked  = e.target.checked; 
        if(checked === true){
            this.setState({ intervalo : false });
        }       
        this.setState({ tiempoCompleto : checked });
    }
    handleIntervalo(e){
        var checked  = e.target.checked; 
        if(checked === true){
            this.setState({ tiempoCompleto : false });
        }       
        this.setState({ intervalo : checked });
    } 
    handleDiasHabiles(e){
        var checkBox = e.target.name;
        var checked  = e.target.checked;        
        this.setState({ [checkBox]: checked });
    }
    handleGuardarHorario(){
        let objectHorario = {
            id : this.props.params.idRow,
            hora_inicio_operativa : this.state.hora_inicio, 
            hora_fin_operativa : this.state.hora_fin,
            tiempo_completo : Boolean(this.state.tiempoCompleto).toString(),
            lunes : Boolean(this.state.lunes).toString(),
            martes : Boolean(this.state.martes).toString(),
            miercoles : Boolean(this.state.miercoles).toString(),
            jueves : Boolean(this.state.jueves).toString(),
            viernes : Boolean(this.state.viernes).toString(),
            sabado : Boolean(this.state.sabado).toString(),
            domingo : Boolean(this.state.domingo).toString(),
        } 

        let dataLog = {
            hora_inicio_operativa : "Hora Inicio",
            hora_fin_operativa : "Hora Final", 
            tiempo_completo : "Tiempo Completo",
            lunes : "Lunes",
            martes : "Martes",
            miercoles : "Miercoles",
            jueves : "Jueves",
            viernes : "Viernes",
            sabado : "Sabado",
            domingo : "Domingo",           
        }            

        if(this.state.intervalo === true && (objectHorario.hora_inicio_operativa === '' || objectHorario.hora_inicio_operativa === null)){
            alertify.alert('Error!', 'Ingrese la hora de inicio!');
            return;
        }
        if(this.state.intervalo === true && (objectHorario.hora_fin_operativa === '' || objectHorario.hora_fin_operativa === null)){
            alertify.alert('Error!', 'Ingrese la hora final!');
            return;
        }
        if(objectHorario.lunes === 'false' && objectHorario.martes === 'false' && objectHorario.miercoles === 'false'  && objectHorario.jueves === 'false'  && objectHorario.viernes === 'false'){
            alertify.alert('Error!', 'Seleccione al menos 1 dia!');
            return;
        }       
        modalLoading(true);
        insertarActualizarFila('put','areas_sla',objectHorario,dataLog,undefined,this.state.database).then(response => {
            modalLoading(false);
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{
                alertify.alert('Aviso!', 'Se ha guardado la informacion con exito!');                
                globalState.dispatch({
                    type   : "windowHorasOperativas",
                    params : {
                        visible : false,
                    } 
                });               
            }     
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })        

    }     
  	render() {                    
  	  	return (
                <div>
                    <TBar
                        items={[
                                  {
                                      type : 'boton',
                                      icon : 'save',
                                      width : '100px',
                                      height : '60px',
                                      title : 'Guardar',
                                      display : true,
                                      function : this.handleGuardarHorario.bind(this)
                                  },                              
                              ]}
                        length = '1'
                    />
                    <div className="container" style={{position:"relative",top:'65px'}}>
                        <div className="content"> 
                            <div className="table-responsive mb-3">
                                <Form>
                                    <Form.Group controlId="form24Horas" style={{marginBottom:'4px'}}>
                                        <Form.Check type="radio" name="tipo_intervalo" label="24 Horas" checked={this.state.tiempoCompleto} onChange={this.handleTiempoCompleto.bind(this)}/>
                                    </Form.Group>
                                    <Form.Group controlId="formIntervalo" style={{marginBottom:'4px'}}>
                                        <Form.Check type="radio" name="tipo_intervalo" label="Intervalo Establecido" checked={this.state.intervalo} onChange={this.handleIntervalo.bind(this)}/>
                                    </Form.Group>
                                    {
                                        this.state.intervalo ?
                                            <Form.Row style={{width:'99%'}}>
                                                <Form.Group  as={Col} controlId="formHoraInicio">
                                                     <Form.Label title="Ingrese la hora de inicio">Hora Inicio *</Form.Label>
                                                     <Form.Control name = "hora_inicio" type="time" value={this.state.hora_inicio} onChange={this.handleHoraInicio.bind(this)}/>                               
                                                </Form.Group>
                                                <Form.Group  as={Col} controlId="formHoraFinal">     
                                                     <Form.Label title="Ingrese la hora final">Hora Final *</Form.Label>
                                                     <Form.Control name = "hora_fin" type="time" value={this.state.hora_fin} onChange={this.handleHoraFinal.bind(this)}/>                               
                                                </Form.Group>
                                            </Form.Row>
                                        : ''
                                    }                                    
                                    <Form.Group controlId="formLunes" style={{marginBottom:'4px'}}>
                                        <Form.Check type="checkbox" label="Lunes" name="lunes" checked={this.state.lunes} onChange={this.handleDiasHabiles.bind(this)} />
                                    </Form.Group>
                                     <Form.Group controlId="formMartes" style={{marginBottom:'4px'}}>
                                        <Form.Check type="checkbox" label="Martes" name="martes" checked={this.state.martes} onChange={this.handleDiasHabiles.bind(this)} />
                                    </Form.Group>
                                    <Form.Group controlId="formMiercoles" style={{marginBottom:'4px'}}>
                                        <Form.Check type="checkbox" label="Miercoles" name="miercoles" checked={this.state.miercoles} onChange={this.handleDiasHabiles.bind(this)} />
                                    </Form.Group>
                                    <Form.Group controlId="formJueves" style={{marginBottom:'4px'}}>
                                        <Form.Check type="checkbox" label="Jueves" name="jueves" checked={this.state.jueves} onChange={this.handleDiasHabiles.bind(this)} />
                                    </Form.Group>
                                    <Form.Group controlId="formViernes" style={{marginBottom:'4px'}}>
                                        <Form.Check type="checkbox" label="Viernes" name="viernes" checked={this.state.viernes} onChange={this.handleDiasHabiles.bind(this)} />
                                    </Form.Group>
                                    <Form.Group controlId="formSabado" style={{marginBottom:'4px'}}>
                                        <Form.Check type="checkbox" label="Sabado" name="sabado" checked={this.state.sabado} onChange={this.handleDiasHabiles.bind(this)} />
                                    </Form.Group>
                                    <Form.Group controlId="formDomingo" style={{marginBottom:'4px'}}>
                                        <Form.Check type="checkbox" label="Domingo" name="domingo" checked={this.state.domingo} onChange={this.handleDiasHabiles.bind(this)} />
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </div> 
                </div> 				
			  );
  	}
}

export default WindowHorasOperativas