/**
* CLASS TabPanelOption
*
* Contiene el contenedor principal del tabpanel
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import globalState from '../configuration/GlobalState';
import './tabpanel.css';

class TabPanelOption extends Component {	
	handleTabClick(tab){		
		globalState.dispatch({
                                type   : this.props.tabPanelId,
                                params : {
                                	tabActive  : this.props.params.id,
                                	componente : this.props.params.componente,
                                	params     : this.props.params.params,
                                }
                             });
	}
	render(){
		let tabSelected = 'false';
		if(this.props.activeTab === this.props.params.id){
			tabSelected = 'true';
		}
		return <div id="win-tab-2" className="optionTab" data-selected={tabSelected} onClick={this.handleTabClick.bind(this,this.props.params.id)}>
					<div style={{float:'left', margin:'0 3px 0 0'}}>
						<i className="material-icons" style={{fontSize:'16px', top:'0px'}}>{this.props.params.icon}</i>
					</div>
					<div style={{float:'left', margin:'0'}}>{this.props.params.title}</div>
				</div>
	}
}

export default TabPanelOption
