/**
* CLASS WindowNovedadesActivos
*
* Contiene el contenedor principal de las categorias de areas de servicio
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import {cargarFilas} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import globalState from '../configuration/GlobalState';
import {fechaMysql} from '../configuration/GlobalFunctions';

class WindowNovedadesActivos extends Component {
    constructor(props){
        super(props);
        this.state = {
            titulo   : '',
            database : globalState.getState().companyData[0].bd 
        }
    }
    async componentWillMount(){
        try{
            let sqlParams = {
                                sqlCols : [
                                    'nombre',
                                ],
                                sqlWhere : [ " AND id="+this.props.params.idRow ],                   
                            }
            let res = await cargarFilas('activos','',1,0,'','',sqlParams,'rows',this.state.database);
            let response = res.data;
            this.setState({titulo : response[0].nombre});                        
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message); 
        }
    }
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }
  	render() {
        let sqlParams = {
                            sqlCols : [
                                "T1.novedad",
                                "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i') AS fecha", 
                                "T1.id_usuario",
                                "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                            ],    
                            sqlJoin : [ "INNER JOIN users AS U ON(U.id = T1.id_usuario)" ],                    
                            sqlWhere : [ " AND id_activo = '"+this.props.params.idRow+"'" ]
                        }
        return (//carga el componente que contiene la grilla de datos
            <div style={{marginTop:'-15px'}}>
                <DataGrid titulo={'Novedades '+this.state.titulo} 
                          funcionClick={this.props.funcionClick}   
                          parametro={this.props.parametro} 
                          colsData={[ 
                                        {
                                            type  : 'bd',
                                            label : 'Fecha',
                                            field : 'fecha'
                                        },
                                        {
                                            type  : 'bd',
                                            label : 'Usuario',
                                            field : 'usuario'
                                        },{
                                            type  : 'bd',
                                            label : 'Novedad',
                                            field : 'novedad'
                                        },                                        
                                    ]}
                          sqlParams = { sqlParams }                       
                          automatica="true"
                          botonNuevo="true"                          
                          formFields={[
                                        {
                                            label : 'Novedad *',
                                            field : 'novedad',
                                            type  : 'textarea',
                                            rows  : 3,
                                            validation : '',
                                            required : 'true',
                                            tooltip : 'Ingrese la novedad'
                                        },                                       
                                        {
                                            label : '',
                                            field : 'id_activo',
                                            type  : 'hidden',
                                            validation : '',
                                            value : this.props.params.idRow                         
                                        }, 
                                        {
                                            label : '',
                                            field : 'id_usuario',
                                            type  : 'hidden',
                                            validation : '',
                                            value : globalState.getState().userData[0].id,
                                            required : 'true'                                        
                                        }, 
                                        {
                                            label : '',
                                            field : 'fecha',
                                            type  : 'hidden',
                                            validation : '',
                                            value : fechaMysql(),
                                            required : 'true'                                        
                                        },                                                                              
                                    ]}                     
                          apiField={'activos_novedades'}
                          permisoBtnUpdate="19"
                          permisoDelete="19"
                          mainContainer='WindowNovedadesActivos'/>
            </div>            
        )
    } 
}

export default WindowNovedadesActivos