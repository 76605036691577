/**
* CLASS ComboBoxFichaTecnica
*
* Contiene el contenedor del combobox para el formulario del data grid
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';
import '../../css/alertify.css';

class ComboBoxFichaTecnica extends Component {
    constructor(props) {
        let  arrayContent = [];
        super(props);
        this.state = {  
                        content  : arrayContent,
                        database : globalState.getState().companyData[0].bd
                     }
    }    
    componentWillMount() {        
        //llenado dinamico del combobox
        let sqlParams = {
                            sqlCols : [
                                "T1.id",
                                "T1.nombre",
                                 "F.valor",                                                               
                            ],
                            sqlJoin : [
                                "INNER JOIN activos_tipos_campos_ficha AS ATC ON (ATC.id = T1.id_campo)",
                                "LEFT JOIN activos_ficha AS F ON (F.id_campo = ATC.id AND F.id_activo="+this.props.idActivo+")",
                                "INNER JOIN activos AS A ON(A.id_tipo = ATC.id_tipo AND A.id="+this.props.idActivo+")"
                            ],                                                                                                    
                            sqlWhere : [
                                " AND T1.id_campo = "+this.props.idCampo
                            ]
                        };        
        cargarFilas('activos_tipos_campos_ficha_valores','',50,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } else {
                this.setState({ content: response })
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
        });         
    }    
  	render() {   
        var valueText = this.props.value;    
        if(valueText > 0 || (typeof valueText === 'string' && valueText.length > 0)){
            //...
        }
        else{
            valueText = "";
        }       
        return (
            <Form.Control as="select" name = {this.props.name} onChange={this.props.functionChange} value={valueText}>
                <option value="" >Seleccione...</option>
                {
                    this.state.content.map((content,i) => {                       
                        return <option key={ i } value={content.id} >{content.nombre}</option>

                    })                                        
                }                
            </Form.Control>                         
        )
    } 
}

export default ComboBoxFichaTecnica