/**
/**
* CLASS WindowEncuestasProveedor
*
* Contiene el contenedor principal de las encuestas de terceros
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import TBar from '../tbar/TBar';
import {cargarFilas,linkEncuestaCliente} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import {validarPermiso,modalLoading} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';

class WindowEncuestasProveedor extends Component { 
    constructor(props){
        super(props);
        this.state = {
            titulo   : '',
            database : globalState.getState().companyData[0].bd
        }
    }
    async componentWillMount(){
        try{
            let sqlParams = {
                                sqlCols : [
                                    'razon_social',
                                ],
                                sqlWhere : [ " AND id="+this.props.params.idRow ],                   
                            }
            let res = await cargarFilas('terceros','',1,0,'','',sqlParams,'rows',this.state.database);
            let response = res.data;
            this.setState({titulo : response[0].razon_social});            
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message); 
        } 
    }
    handleNuevaEncuesta(){        
        this.props.funcionClick('FormEncuestaProveedor',{funcionClick:this.props.funcionClick});
    } 
    verEncuestaProveedor(idRow){        
        this.props.funcionClick('WindowVerEncuestaTerceros',{funcionClick:this.props.funcionClick,idRow:idRow});
    } 
    btnRetroceder(){
        globalState.dispatch({
            type   : this.props.params.idWin,
            params : {
                          visible : false,
                     }
        });
    }
    linkEncuestaCliente(){
        modalLoading(true);
        linkEncuestaCliente(this.props.params.idRow,this.state.database)
        .then(res => {
            modalLoading(false);
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
            } 
            else if(res.msg === 'mail_error'){                
                alertify.alert('Error!', 'Ha ocurrido un error enviando el correo, verifique la conexion a internet y configuracion SMTP en la aplicacion!<br /><br />Detalles del Error: '+JSON.stringify(res.detail, null, 4));
            }  
            else{
                alertify.alert('Aviso!', 'Se ha enviado un correo electronico con la encuesta al cliente!');                               
            }
        })
        .catch( err => {  
            modalLoading(false);          
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    } 
    render() { 
        let idUser = globalState.getState().userData[0].id;  
        let whereUser = ' AND T1.id_usuario='+idUser;  
        if(validarPermiso(54)){
            whereUser = '';
        }  
        let botonNuevo = 'true';
        let botonLink  = 'none';
        if(this.props.params.opcion === 'cliente'){
            botonNuevo = 'false';
            botonLink  = 'block';
        }
        let sqlParams = { 
                            sqlCols : [
                                "T1.id",
                                "ROUND(T1.promedio) AS promedio",
                                "IF(T1.tipo='cliente',T.nombre_contacto,CONCAT(U.primer_nombre,' ',U.primer_apellido)) AS usuario",
                                "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",  
                            ],
                            fieldSearch : [
                                "U.nombre",
                                "T1.fecha"                                
                            ], 
                            sqlJoin : [ 
                                "LEFT JOIN users AS U ON (U.id = T1.id_usuario)",
                                "INNER JOIN terceros AS T ON (T.id = T1.id_tercero)"
                            ],
                            sqlWhere : [
                                " AND T1.id_tercero="+this.props.params.idRow+whereUser,
                                " AND T1.tipo='"+this.props.params.opcion+"'" 
                            ],
                            sqlOrderBy : "T1.fecha DESC",                                                     
                            filtroFechas : "false",                                                 
                        };                 
        return (//carga el componente que contiene la grilla de datos                            
            <div style={{height: 'calc(100% - 31px)'}}> 
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.btnRetroceder.bind(this)
                              },
                              {
                                  type : 'boton',
                                  icon : 'mail_outline',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Enviar Link',
                                  display : botonLink,
                                  function : this.linkEncuestaCliente.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative",height:'calc(100% - 60px)',overflow:'auto'}}>
                    <div className="titulo" style={{paddingLeft:'15px'}}>{'Encuestas '+this.props.params.opcion+' '+this.state.titulo}</div>         
                    <DataGrid titulo='Encuestas' 
                              funcionClick={this.props.funcionClick} 
                              parametro={this.props.parametro}
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Usuario',
                                                field : 'usuario'
                                            },                                        
                                            {
                                                type  : 'bd',
                                                label : 'Fecha',
                                                field : 'fecha'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Prom.',
                                                field : 'promedio'
                                            },
                                        ]} 
                              sqlParams = { sqlParams }
                              automatica="false"
                              funcionNew={this.handleNuevaEncuesta.bind(this)}
                              botonNuevo={botonNuevo}
                              funcionEdit={this.verEncuestaProveedor.bind(this)}                                               
                              apiField={'encuesta_terceros'}
                              mainContainer='WindowEncuestasTerceros'/> 
                </div> 
            </div>                       
        )
    } 
}

export default WindowEncuestasProveedor