/**
* CLASS EncuestaSatisfaccion
*
* Contiene el componente que despliega la encuesta de satisfaccion de los servicios
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import configJson from '../configuration/configuration.json';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import LogoCorporativo from '../LogoCorporativo/LogoCorporativo';
import Modal from 'react-modal';
import loadingImg from '../../images/loading.gif?v1.0';
import Button from 'react-bootstrap/Button';
import star_inactive from '../../images/star_inactive.png?v1.0';
import star_active from '../../images/star_active.png?v1.0';
import {cargarEncuesta,guardarEncuesta} from '../api_calls/ApiCalls';
import {divMouseOver,divMouseOut,modalLoadingRstPwd,vacio} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';

var base64 = require('base-64');

const stylesLoading = {
  content : {
    top         : '50%',
    left        : '50%',
    width       : '202px',
    height      : '202px',
    right       : 'auto',
    bottom      : 'auto',
    marginRight : '-50%',
    padding     : '0px',
    transform   : 'translate(-50%, -50%)',    
  }
};

const componentList = {
      star_inactive  : star_inactive,
      star_active : star_active,
}

class EncuestaSatisfaccion extends Component {
	constructor(props){
		super(props);
		this.state = {
			resultPreguntas  : { "msg" : []},
			mostrarEncuesta  : true,
			estadoSolicitud  : '',
			updateEncuesta   : 0,
			showLoading : false,
			objRespuestas    : [],
			campoComentarios : '',
            idSolicitud : base64.decode(this.props.match.params.idSolicitud),
            tabla : base64.decode(this.props.match.params.tabla),
            database : base64.decode(this.props.match.params.bd), 
		}		
	}
	cargaEncuesta(){
		cargarEncuesta(this.state.idSolicitud,this.state.tabla,this.state.database).then(response => {             
            response = response.data;                   
            this.setState({resultPreguntas : response });            
            if(Array.isArray(response.msg)){
            	response.msg.map((listado,i) => {            		
            		this.setState({[listado.id] : ''});
            		this.setState({[listado.id+'_star1'] : 'star_inactive'});
            		this.setState({[listado.id+'_star2'] : 'star_inactive'});
            		this.setState({[listado.id+'_star3'] : 'star_inactive'});
            		this.setState({[listado.id+'_star4'] : 'star_inactive'});
            		this.setState({[listado.id+'_star5'] : 'star_inactive'});
            		return 0;
            	})
            }                              
        })
        .catch(function (error) {
             alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });	
	}
	componentWillMount(){		
		this.cargaEncuesta();	
	}	
    componentDidUpdate(prevProps,prevState){
        if (this.state.updateEncuesta !== prevState.updateEncuesta) {           
           this.cargaEncuesta();            
        }       
    }
	componentDidMount() {//cada que se monte el escritorio debe alistar la ventana del loading      
        //... 
        this.unsubscribe1 = globalState.subscribe( ()=>{ 
            if(globalState.getState().type==="modalLoadingEncuesta"){                 
                this.setState({showLoading  : globalState.getState().modalLoadingEncuesta});                                           
            }
        });          
    } 
    componentWillUnmount(){ 
        this.unsubscribe1();
    }	
	handleEnviarEncuesta(){
		//validar que todas esten diligenciadas
		var jsonRespuestas = {};
		var errors = 0;
		this.state.resultPreguntas.msg.map((listado,i) => {    	
			if(this.state[listado.id] === ''){
    			alertify.error('Califique la pregunta: '+listado.nombre+'!'); 
    			errors++;    			
			}
			else{
				jsonRespuestas[listado.id] = this.state[listado.id];
			}
            return 0;
    	})
    	if(vacio(this.state.campoComentarios)  === false){
    		alertify.error('Sus comentarios son muy importantes para nuestra mejora!');
    		errors++; 
    	}
    	else{
    		jsonRespuestas[0] = this.state.campoComentarios;
    	}
    	if(errors > 0){
    		return;
    	}
    	//generar el JSON
		jsonRespuestas = JSON.stringify(jsonRespuestas);
		modalLoadingRstPwd(true,"modalLoadingEncuesta");
		guardarEncuesta(jsonRespuestas,this.state.idSolicitud,this.state.tabla,this.state.database).then(response => {             
            response = response.data;  
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
            	modalLoadingRstPwd(false,"modalLoadingEncuesta");
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else if(response.msg === 'no_mail'){ 
            	modalLoadingRstPwd(false,"modalLoadingEncuesta");
            	alertify.alert('Error!', 'No hay destinatarios para enviar el correo!');            	
        	}
            else if(response.msg === 'mail_error'){ 
            	modalLoadingRstPwd(false,"modalLoadingEncuesta");
                alertify.alert('Error!', 'Ha ocurrido un error enviando el correo!<br />Codigo de Error: '+response.detail);
            }
            else if(response.msg === 'success'){
            	modalLoadingRstPwd(false,"modalLoadingEncuesta");
            }
            this.setState({updateEncuesta: this.state.updateEncuesta+1});
        })
        .catch(function (error) {
        	modalLoadingRstPwd(false,"modalLoadingEncuesta");
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });		
	}	
	handleComentarios(e){
        let value=e.target.value;
        value = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\n,:.@\-+¡!?¿ ]/g,''); 
		this.setState({campoComentarios : value});
	}
	handlePuntaje(id,num,e){
        //activacion/desactivacion estrellas
        for(var i=num;i>0;i--){            
            this.setState({[id+'_star'+i] : 'star_active'});
        }
        for(i=num;i<5;i++){            
            this.setState({[id+'_star'+(i+1)] : 'star_inactive'});
        }
		this.setState({[id] : num});
    } 
	render() {
		return <div style={{background:'#efefef',width:'100%',height:'100%',overflow:'hidden'}}>
                    <div className="adM">
                    </div>
                    <LogoCorporativo database={this.state.database}/>
                    <div style={{width:'calc(100% - 60px)',maxWidth:'600px',height:'auto',padding:'20px',margin:'20px auto',backgroundColor:'#fff',borderRadius:'5px'}}>
	                    <div>
	                   		<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>Encuesta de Satisfaccion</h1>
	                        <hr style={{margin:'0 0 20px 0'}} />        	
		                    {
		                   		(this.state.resultPreguntas.msg === 'error') ?
		                   			<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>Ha ocurrido un error generando la encuesta. <br />{this.state.resultPreguntas.detail}.</h1> 
		                   		:(this.state.resultPreguntas.msg === 'inhabilitada') ?
		                   			<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>La encuesta no se encuentra habilitada!.<br />Estado de Solicitud: {this.state.resultPreguntas.detail}</h1> 
		                   		:(this.state.resultPreguntas.msg === 'no_preguntas') ?
		                   			<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>No se han creado las preguntas para la encuesta.<br />Por favor comunicarse con el Administrador del sistema.</h1> 
		               			:(this.state.resultPreguntas.msg === 'diligenciada') ?
                                    <h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>La encuesta ya fue diligenciada!</h1> 
                                :(this.state.resultPreguntas.msg === 'sistema') ?
                                    <h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>No se puede diligenciar la encuesta, la solicitud fue finalizada automaticamente por el sistema!</h1> 
                                : <div> 
			               			  <div style={{fontFamily:'Roboto,sans-serif',fontSize:'14px',color:'#666'}}>Por favor evalue el nivel de satisfaccion de las siguientes preguntas relacionadas con la atencion prestada a su solicitud.<br /></div>
                        			  <div style={{marginTop:'20px'}}> 
				               			  <Form>
				               			       {
				               			  	      this.state.resultPreguntas.msg.map((listado,i) => {
					               					  return <Form.Group key={i} controlId={"encuestaPregunta_"+i}>
					                                             <Form.Label>{listado.nombre} *</Form.Label>
					                                             <Col sm={10} style={{paddingLeft:'0px'}}>															        
															        <div style={{textAlign:'center',height:'50px',width:'250px',marginLeft:'0px'}}>
										                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,1)}>
										                                    <img src={componentList[this.state[listado.id+'_star1']]} alt={this.state[listado.id+'_star1']} />                                 
										                                </div>
										                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,2)}>
										                                    <img src={componentList[this.state[listado.id+'_star2']]} alt={this.state[listado.id+'_star2']} />                                 
										                                </div>
										                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,3)}>
										                                    <img src={componentList[this.state[listado.id+'_star3']]} alt={this.state[listado.id+'_star3']} />                                 
										                                </div>
										                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,4)}>
										                                    <img src={componentList[this.state[listado.id+'_star4']]} alt={this.state[listado.id+'_star4']} />                                 
										                                </div>
										                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,5)}>
										                                    <img src={componentList[this.state[listado.id+'_star5']]} alt={this.state[listado.id+'_star5']} />                                 
										                                </div>                            
										                            </div>
															     </Col>					                                             
					                                         </Form.Group>					               					  
					               			      })
				               			        }
				               			       	<Form.Row style={{width:'99%'}}>
                                                    <Form.Group as={Col} controlId="encuestaComentarios">
                                                        <Form.Label>Escriba sus Comentarios *</Form.Label>                                                        
                                                        <Form.Control name = "encuestaComentarios" as="textarea" rows="3" onChange={this.handleComentarios.bind(this)} value={this.state.campoComentarios}/>
                                                    </Form.Group>
                                                </Form.Row>
				               			  		<Form.Group controlId="formBasicResetPwd" style={{height:'50px',marginTop:'20px',marginBottom:'0px'}}>
			                                        <Button id="formGridBtnSave" style={{padding:'10px 20px',margin: '5px 0',backgroundColor:configJson.fondoBotonGrilla}} className="float-left mr-3" variant="primary" onClick={this.handleEnviarEncuesta.bind(this)} onMouseOut={divMouseOut.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)}>
		    				  	      					ENVIAR
		    				  	    			    </Button>
			    				  	    		</Form.Group>  
				               			   </Form> 
				               		  </div>
				               	 </div>
		               			  	
		                    }			                			                 
			            </div>       		                              
                        <div className="yj6qo"></div>
                        <div className="adL"></div>
                    </div>
                    <div className="adL"></div>
                    <Modal //la ventana del loading
	                   isOpen={this.state.showLoading}
	                   contentLabel="Minimal Modal Example"
	                   style={stylesLoading}
	                > 
	                    <img src={loadingImg}  alt="Loading"/>
	                </Modal>
           	    </div> 
    }
}   

export default EncuestaSatisfaccion              