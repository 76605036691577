/**
* CLASS WindowRecategorizarIncidencia
*
* Contiene el componente que guarda la observacion de la recategorizacion a problema
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,recategorizacionIncidencia} from '../api_calls/ApiCalls';
import {modalLoading} from '../configuration/GlobalFunctions';
import TBar from '../tbar/TBar';
import globalState from '../configuration/GlobalState';
import { diccionario } from '../configuration/configuration.json';
import alertify from 'alertifyjs';
import Form from 'react-bootstrap/Form';

const { LabelsWindowRecategorizarIncidencia } = diccionario;

class WindowRecategorizarIncidencia extends Component {
    constructor(props) {        
        super(props);   
        this.state = {
            textareaObs        : '',
            idArea             : '',
            arrayAreas         : [],
            arrayCategorias    : [],
            arraySubcategorias : [],
            idCategoria        : '',
            idSubcategoria     : '',
            idioma   : globalState.getState().idioma,
            database : globalState.getState().companyData[0].bd
        };  
        //valida que aun este habilitado para recategorizarse
        let sqlParams = { 
                            sqlCols : [
                                "T1.id_tecnico_incidencia AS id_tecnico",
                                "T1.id_area",
                                "T1.estado",
                                "SE.nombre AS nom_estado",
                                "T1.estado+1 AS estado1",
                                "SE1.nombre AS nom_estado1"              
                            ], 
                            sqlJoin : [
                                "INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)",
                                "LEFT JOIN solicitudes_estado AS SE1 ON (SE1.valor = T1.estado+1 AND T1.estado < 5)"  
                            ],                      
                            sqlWhere : [ " AND T1.id = "+this.props.params.idRow ],                                
                        }
        cargarFilas('incidencias','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert1[this.state.idioma]+': '+response.detail);
            }
            else{                
                response = response[0];
                let idUser  = globalState.getState().userData[0].id;
                let idRol = globalState.getState().idRol;
                if(idUser !== response.id_tecnico && idRol > 1){
                    alertify.alert(LabelsWindowRecategorizarIncidencia.aviso[this.state.idioma], LabelsWindowRecategorizarIncidencia.error_alert2[this.state.idioma]);
                    globalState.dispatch({
                    type   : "windowRecategorizarIncidencia",
                        params : {
                                      visible : false,                              
                                 }
                    });
                    return;
                }
                //cargar el combo del estado siguiente 
                let estadoActual = response.estado; 
                let nomEstadoActual = response.nom_estado;  
                if(estadoActual > 1){
                    alertify.alert(LabelsWindowRecategorizarIncidencia.aviso[this.state.idioma], LabelsWindowRecategorizarIncidencia.alert2[this.state.idioma]+nomEstadoActual+'!');
                    globalState.dispatch({
                    type   : "windowRecategorizarIncidencia",
                        params : {
                                      visible : false,                              
                                 }
                    });     
                }
                else{
                    this.setState({idArea : response.idArea},()=>{
                        let sqlParams = {
                                            sqlCols : [
                                                'id',
                                                'nombre'                                
                                            ],
                                            sqlWhere : [ " AND atencion_problemas='true'" ],                                                                                                                                                                                                            
                                        };                                                                
                        cargarFilas('areas_servicio','',100,0,'','',sqlParams,'rows',this.state.database)
                        .then(response => {
                            response = response.data;
                            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert3[this.state.idioma]+': '+response.detail);
                            }
                            else{
                                this.setState({arrayAreas : response});
                            }
                        })
                        .catch(function (error) {
                            alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert3[this.state.idioma]+': '+error);
                        }) 
                    });
                }
            }     
        })
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert1[this.state.idioma]+': '+error);
        })
    }         
    obsRecategorizar(e){
        let value=e.target.value;         
        value = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\n,:.@\-+¡!?¿ ]/g,'');       
        this.setState({textareaObs:value});
    }
    handleChangeArea(e){
        let idArea = e.target.value;
        let sqlParams = {
                            sqlCols : [
                                'id',
                                'nombre'                                
                            ],
                            sqlWhere : [ " AND id_area='"+idArea+"' AND atencion_problemas='true'" ],                                                                                    
                        };                                                                
        cargarFilas('areas_servicio_categorias','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert4[this.state.idioma]+': '+response.detail);
            }
            else{
                this.setState({idArea : idArea});
                this.setState({arrayCategorias : response});
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert4[this.state.idioma]+': '+error);
        })
    }
    handleChangeCategoria(e){
        let idCategoria = e.target.value;
        let sqlParams = {
                            sqlCols : [
                                'id',
                                'nombre'                                
                            ],
                            sqlWhere : [ " AND id_categoria='"+idCategoria+"'" ],                                                                                                         
                        };                                                                
        cargarFilas('areas_servicio_subcategorias','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert5[this.state.idioma]+': '+response.detail);
            }
            else{
                this.setState({idCategoria : idCategoria});
                this.setState({arraySubcategorias : response});
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert5[this.state.idioma]+': '+error);
        })
    }
    handleChangeSubcategoria(e){    
        let idSubcategoria = e.target.value;
        this.setState({idSubcategoria : idSubcategoria});
    } 
    handleBtnRecategorizar(e){         
        var observacion    = this.state.textareaObs; 
        var idArea         = this.state.idArea;      
        var idCategoria    = this.state.idCategoria;      
        var idSubcategoria = this.state.idSubcategoria; 

        if(idArea.length === undefined || idArea.length < 1){
            alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.prompt_alert1[this.state.idioma]);
            return;
        }  
        if(idCategoria.length === undefined || idCategoria.length < 1){
            alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.prompt_alert2[this.state.idioma]);
            return;
        } 
        if(idSubcategoria.length === undefined || idSubcategoria.length < 1){
            alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.prompt_alert3[this.state.idioma]);
            return;
        } 
        if(observacion.length === undefined || observacion.length < 1){
            alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.prompt_alert4[this.state.idioma]);
            return;
        } 

        alertify.confirm(LabelsWindowRecategorizarIncidencia.confirmacion[this.state.idioma],LabelsWindowRecategorizarIncidencia.prompt_alert5[this.state.idioma],this.recategorizarIncidencia.bind(this,observacion,idArea,idCategoria,idSubcategoria), function(){});    
    } 
    //======================================Recategorizacion del problema==============================================//
    recategorizarIncidencia(obs,idArea,idCategoria,idSubcategoria){ 
        var id_usuario = globalState.getState().userData[0].id; 
        modalLoading(true);
        recategorizacionIncidencia(obs,idArea,idCategoria,idSubcategoria,this.props.params.idRow,id_usuario,this.state.database).then(response => {             
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert6[this.state.idioma]+': '+response.detail);
            }
            else if(response.msg === 'no_capacidad'){
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert7[this.state.idioma]);
            }
            else if(response.msg === 'no_mail'){ 
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert8[this.state.idioma]);                
            }
            else if(response.msg === 'mail_error'){ 
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert9[this.state.idioma]+': '+response.detail);
            }
            else if(response.msg === 'no_sla'){ 
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert10[this.state.idioma]);
            }
            else if(response.msg === 'no_roles'){                
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert11[this.state.idioma]);
            }            
            else if(response.msg === 'jefe_no_encontrado'){
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert12[this.state.idioma], this.showAlertSuccess2());
            }
            else if(response.msg === 'success'){
                alertify.alert(LabelsWindowRecategorizarIncidencia.aviso[this.state.idioma], LabelsWindowRecategorizarIncidencia.success[this.state.idioma]);
                modalLoading(false);
            }    
            this.props.params.funcionClick("Incidencias");
            globalState.dispatch({
                type   : "windowRecategorizarIncidencia",
                params : {
                              visible : false,                              
                         }
            });          
        })
        .catch(function (error) {
            modalLoading(false);
            alertify.alert('Error!', LabelsWindowRecategorizarIncidencia.error_alert6[this.state.idioma]+': '+error);
        });
    }
    showAlertSuccess2(){//alert con el ticket       
        alert(LabelsWindowRecategorizarIncidencia.aviso[this.state.idioma]);
    }  
  	render() {             
  	  	return (
                <div>
                    <TBar
                        items={[
                                  {
                                      type : 'boton',
                                      icon : 'save',
                                      width : '80px',
                                      height : '60px',
                                      title : LabelsWindowRecategorizarIncidencia.guardar[this.state.idioma],
                                      function : this.handleBtnRecategorizar.bind(this)
                                  },                              
                              ]}
                        length = '1'
                    />
                    <div id="contenedorPermisos" style={{position:"relative",top:'70px'}}>
                        <Form>
                            <Form.Group controlId="formFieldArea">
                                <Form.Label>{LabelsWindowRecategorizarIncidencia.area[this.state.idioma]} *</Form.Label>                       
                                <Form.Control as="select" name = "id_area" onChange={this.handleChangeArea.bind(this)} value={this.state.idArea}>
                                    <option value="">{LabelsWindowRecategorizarIncidencia.seleccione[this.state.idioma]}</option>
                                    {
                                        this.state.arrayAreas.map((content,i) => { 
                                            return <option key={i} value={content.id} >{content.nombre}</option>
                                        })
                                    }                            
                                </Form.Control>  
                            </Form.Group>
                            <Form.Group controlId="formFieldCategoria">
                                <Form.Label>{LabelsWindowRecategorizarIncidencia.categoria[this.state.idioma]} *</Form.Label>                       
                                <Form.Control as="select" name = "id_categoria" onChange={this.handleChangeCategoria.bind(this)} value={this.state.idCategoria}>
                                    <option value="">{LabelsWindowRecategorizarIncidencia.seleccione[this.state.idioma]}</option>
                                    {
                                        this.state.arrayCategorias.map((content,i) => { 
                                            return <option key={i} value={content.id} >{content.nombre}</option>
                                        })
                                    }                            
                                </Form.Control>  
                            </Form.Group>
                            <Form.Group controlId="formFieldSubcategoria">
                                <Form.Label>{LabelsWindowRecategorizarIncidencia.subcategoria[this.state.idioma]} *</Form.Label>                       
                                <Form.Control as="select" name = "id_subcategoria" onChange={this.handleChangeSubcategoria.bind(this)} value={this.state.idSubcategoria}>
                                    <option value="">{LabelsWindowRecategorizarIncidencia.seleccione[this.state.idioma]}</option>
                                    {
                                        this.state.arraySubcategorias.map((content,i) => { 
                                            return <option key={i} value={content.id} >{content.nombre}</option>
                                        })
                                    }                            
                                </Form.Control>  
                            </Form.Group> 
                            <Form.Group controlId="formFieldGestion3">
                                <Form.Label>{LabelsWindowRecategorizarIncidencia.observaciones[this.state.idioma]} *</Form.Label>                                                        
                                <Form.Control name = "observaciones" as="textarea" style={{resize:'none',overflow:'hidden'}} rows="3" onChange={this.obsRecategorizar.bind(this)} value={this.state.textareaObs}/>
                            </Form.Group>                    
                        </Form>
                    </div>
                </div>  				
			  );
  	}
}

export default WindowRecategorizarIncidencia