/**
* CLASS DashboardActivos
*
* Contiene el contenedor principal del panel de control
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas} from '../api_calls/ApiCalls';
//import { diccionario } from '../configuration/configuration.json';
import {validarPermiso} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import DashboardActivosOption from  './DashboardActivosOption';
import alertify from 'alertifyjs';

//const { LabelsDashboardActivos } = diccionario;

class DashboardActivos extends Component { 
    constructor(props){
        super(props); 
        this.state = {
            resultIT : [],
            resultNOIT : [],
            database : globalState.getState().companyData[0].bd            
        } 
    }
    componentWillMount(){
        let sqlParams = {
                            sqlOrderBy : "categoria ASC"                                                                                                                                                          
                        } 
        cargarFilas('activos_tipos','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
            } 
            else{
                let arrayIT = [];
                let arrayNOIT = [];
                for(let i in res){
                    if(res[i].categoria === 'it'){
                        arrayIT.push(res[i]);
                    }
                    else{
                        arrayNOIT.push(res[i]);
                    }
                } 
                this.setState({resultIT   : arrayIT});  
                this.setState({resultNOIT : arrayNOIT});
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }     	
  	render() {
  	  	return ( 
            <div id="ContentDashboardActivos" className="EstiloIconosDashboardActivos">
                {
                    validarPermiso(60) === true ?
                        <div>
                            <div className="titulo">Activos IT</div>
                            <ul> 
                            {
                                this.state.resultIT.map((optionMenu,i) => {
                                    return <DashboardActivosOption 
                                                key={i} 
                                                tab={'ActivosITTab'+i} 
                                                titulo={optionMenu.nombre} 
                                                descripcion={optionMenu.descripcion} 
                                                icono={optionMenu.icono} 
                                                funcionClick={this.props.funcionClick} 
                                                idTipo={optionMenu.id} 
                                            />
                                })
                            }
                            </ul>
                        </div>
                    : ''
                }
                {
                    validarPermiso(61) === true ?
                        <div>
                            <div className="titulo">Activos no IT</div>
                            <ul>
                            {
                                this.state.resultNOIT.map((optionMenu,i) => {
                                    return <DashboardActivosOption 
                                                key={i} 
                                                tab={'ActivosNOITTab'+i} 
                                                titulo={optionMenu.nombre} 
                                                descripcion={optionMenu.descripcion}                                      
                                                icono={optionMenu.icono} 
                                                funcionClick={this.props.funcionClick} 
                                                idTipo={optionMenu.id} 
                                            />
                                })
                            } 
                            </ul>
                        </div>
                    : ''
                }
            </div>    	
  	  	);
  	}
}

export default DashboardActivos