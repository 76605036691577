/**
* CLASS WindowCategorias
*
* Contiene el contenedor principal de las categorias de areas de servicio
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../../data_grid/DataGrid';
import Window from '../../window/Window';
import globalState from '../../configuration/GlobalState';
import {cargarFilas} from '../../api_calls/ApiCalls';
import alertify from 'alertifyjs';

class WindowCategorias extends Component {
    constructor(props){
        super(props);
        this.state={
            incidencias : 'false',
            problemas   : 'false',
            servicios   : 'false',
            database : globalState.getState().companyData[0].bd 
        }    
    }
    componentWillMount(){
        let sqlParams = {
                            sqlCols : [
                                "atencion_incidencias",
                                "atencion_problemas",
                                "atencion_servicios",
                            ],                        
                            sqlWhere : [ " AND id = '"+this.props.params.idArea+"'" ],                                
                        }
        cargarFilas('areas_servicio','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {                    
            var response = res.data;                       
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{
                this.setState({incidencias:response[0].atencion_incidencias,
                                problemas:response[0].atencion_problemas,
                                servicios:response[0].atencion_servicios});
            }
        })
        .catch(err => {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                                      
        });     
    }
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }
    colFuncion(idCategoria){        
        globalState.dispatch({
                type   : "windowSubcategorias",
                params : {
                              visible : true,
                              params  : {
                                            idCat : idCategoria,
                                            idWin : "windowSubcategorias",
                                            width  : '400px', 
                                            height : '250px'//identificador de la ventana
                                        }
                         }
            }); 
    }      	  
  	render() { 
        let sqlParams = { 
                            sqlCols : [
                                '*',
                                "IF(atencion_incidencias='true','Si','No') AS incidencias",
                                "IF(atencion_problemas='true','Si','No') AS problemas",
                                "IF(atencion_servicios='true','Si','No') AS servicios",                                                                                                         
                            ],
                            fieldSearch : [
                                'nombre',                                
                            ], 
                            sqlWhere : [
                                ' AND T1.id_area = '+this.props.params.idArea
                            ],                                                       
                            filtroFechas : "false",                                                                            
                        }; 
        
        let columnIncidencias = '';
        let fieldIncidencias  = null;
        if(this.state.incidencias === 'true'){
            columnIncidencias = {
                                    type  : 'bd',
                                    label : 'Incidencias',
                                    field : 'incidencias'
                                };
            fieldIncidencias =  {
                                    label : 'Incidencias *',
                                    field : 'atencion_incidencias',
                                    type  : 'select',
                                    validation : '',
                                    required : 'true',
                                    dinamic : 'false',
                                    valueName : 'nombre',
                                    options :  [{
                                                    id: 'true',
                                                    nombre: 'Si',
                                                },
                                                {
                                                    id: 'false',
                                                    nombre: 'No', 
                                                }],
                                    tooltip : 'Seleccione si se vincula a incidencias'                                        
                                };
        }
        let columnProblemas  = ''; 
        let fieldProblemas = null;        
        if(this.state.problemas === 'true'){
            columnProblemas  =  {
                                    type  : 'bd',
                                    label : 'Problemas',
                                    field : 'problemas'
                                };
            fieldProblemas =    {
                                    label : 'Problemas *',
                                    field : 'atencion_problemas',
                                    type  : 'select',
                                    validation : '',
                                    required : 'true',
                                    dinamic : 'false',
                                    valueName : 'nombre',
                                    options :  [{
                                                    id: 'true',
                                                    nombre: 'Si',
                                                },
                                                {
                                                    id: 'false',
                                                    nombre: 'No', 
                                                }],
                                    tooltip : 'Seleccione si se vincula a problemas'                                        
                                };
        } 
        let columnServicios = '';
        let fieldServicios  = null;
        if(this.state.servicios === 'true'){
            columnServicios  =  {
                                    type  : 'bd',
                                    label : 'Servicios',
                                    field : 'servicios'
                                };
            fieldServicios = {
                                label : 'Servicios *',
                                field : 'atencion_servicios',
                                type  : 'select',
                                validation : '',
                                required : 'true',
                                dinamic : 'false',
                                valueName : 'nombre',
                                options :  [{
                                                id: 'true',
                                                nombre: 'Si',
                                            },
                                            {
                                                id: 'false',
                                                nombre: 'No', 
                                            }],
                                tooltip : 'Seleccione si se vincula a servicios'                                        
                              };

        }

        return (//carga el componente que contiene la grilla de datos
            <div style={{height: 'calc(100% - 30px)'}}>                
                <div style={{position:"relative",height:"100%",overflow:"auto"}}> 
                    <DataGrid titulo='Categorias' 
                              funcionClick={this.props.funcionClick}   
                              parametro={this.props.parametro} 
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Nombre',
                                                field : 'nombre'
                                            },
                                            columnIncidencias, 
                                            columnProblemas, 
                                            columnServicios, 
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'settings',
                                                colFuncion : this.colFuncion.bind(this)
                                            }
                                        ]}
                              sqlParams = { sqlParams }                       
                              automatica="true"
                              botonNuevo="true"
                              formFields={[
                                            {
                                                label : 'Nombre *',
                                                field : 'nombre',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                required : 'true',
                                                longitud : 45,
                                                tooltip : 'Ingrese el nombre de la categoria'
                                            },
                                            fieldIncidencias,
                                            fieldProblemas,
                                            fieldServicios,
                                            {
                                                label : '',
                                                field : 'id_area',
                                                type  : 'hidden',
                                                validation : '',
                                                value : this.props.params.idArea,
                                                required : 'true'                                        
                                            },                                                                             
                                        ]}                     
                              apiField={'areas_servicio_categorias'}
                              mainContainer='WindowCategorias'/>                
                    <Window 
                          id = "windowSubcategorias"                      
                          title='Subcategorias'                      
                          tbar="false"
                          componente="WindowSubcategorias"
                          params="" 
                      />  
                </div>
            </div>            
        )
    } 
}

export default WindowCategorias