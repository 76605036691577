/**
* CLASS PanelMenu
*
* Contiene el menu de opciones del panel
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import configJson,{ diccionario } from '../configuration/configuration.json';
import globalState from '../configuration/GlobalState';
import {validarModulo} from '../configuration/GlobalFunctions';
import Option from './Option';
import './controlpanel.css';

const { LabelsPanelMenu } = diccionario;

class PanelMenu extends Component {
    
  	render() {
        let idioma = globalState.getState().idioma;
  	  	return (  	  		  
  	  		<div id="ContentPaneldeControl" className="EstiloIconosMenuDashboard">
              <div className="titulo">{LabelsPanelMenu.Title[idioma]}</div>
              <div style={{width:'100%'}}>
                  <ul>
                  {
                      configJson.control_panel.map((optionMenu,i) => {//recorre el objeto con los modulos  
                          if(optionMenu.componente === 'ActivosTipos' && !validarModulo(6)){
                              return null;
                          } 
                          else{
                              return (<Option key={i} 
                                          tab={optionMenu.tab} 
                                          titulo={LabelsPanelMenu[optionMenu.titulo][idioma]} 
                                          subtitulo={optionMenu.subtitulo} 
                                          icono={optionMenu.icono} 
                                          funcionClick={this.props.funcionClick} 
                                          componente={optionMenu.componente} 
                                          permiso={optionMenu.permiso}/>)  
                          }
                      })
                  } 	
    			        </ul>
              </div>
          </div>   	
		    );
  	}
}

export default PanelMenu
