/**
* CLASS DataGrid
*
* Contiene el contenedor principal del data grid
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGridContainer from './DataGridContainer';
import ComboBoxFilter from './ComboBoxFilter';
import Button from 'react-bootstrap/Button';
import {cargarFilas} from '../api_calls/ApiCalls';
import Window from '../window/Window';
import configJson from '../configuration/configuration.json';
import {divMouseOver,divMouseOut,exportCSVFile,validarPermiso} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import icono_pdf from '../../images/icon_pdf.png?v1.0';
import icono_xls from '../../images/icon_excel.png?v1.0';
import ReactToPdf from 'react-to-pdf';
import MaterialIcon from 'material-icons-react';
import alertify from 'alertifyjs';
import '../../css/alertify.css';
import './dataGrid.css';

class DataGrid extends Component {
    constructor(props, context) {
        super(props, context);

        let date1 = '';
        let date2 = '';        
        if(this.props.filtroFechas === 'true'){
            date1 = new Date();
            date2 = new Date();
            date1.setDate(date1.getDate() - 30);     

            let day = date1.getDate()
            let month = date1.getMonth() + 1
            let year = date1.getFullYear()

            if(month < 10){
                month = '0'+month;
            }
            if(day < 10){
                day = '0'+day;
            }

            date1 = year+'-'+month+'-'+day;

            day = date2.getDate()
            month = date2.getMonth() + 1
            year = date2.getFullYear()

            if(month < 10){
                month = '0'+month;
            }
            if(day < 10){
                day = '0'+day;
            }

            date2 = year+'-'+month+'-'+day;
        }
        //validacion del permiso de acceso 
        let enableBtnNew = true;
        let enableBtnUpd = true;
        let enableBtnDel = true;
        if(this.props.permisoInsertUpdate>0){ 
            enableBtnNew = validarPermiso(this.props.permisoInsertUpdate);
            if(this.props.permisoBtnUpdate === undefined){                    
                enableBtnUpd = validarPermiso(this.props.permisoInsertUpdate); 
            } 
        } 
        if(this.props.permisoDelete>0){
            enableBtnDel =  validarPermiso(this.props.permisoDelete);                
        }  
        if(this.props.permisoBtnUpdate){
            enableBtnUpd = validarPermiso(this.props.permisoBtnUpdate);                
        }

        this.state = {//las opciones de filtrado
            date1 : date1,
            date2 : date2,
            dateH : date2,
            showRecords  : 15,
            searchWord   : '',
            offsetRecord : 0,
            resultRows   : 0,
            enableBtnNew : enableBtnNew,
            enableBtnDel : enableBtnDel,
            enableBtnUpd : enableBtnUpd,
            sqlParams    : this.props.sqlParams,
            arrayFilter  : [],
            windowDataSelectId : '',
            database : globalState.getState().companyData[0].bd
        }
        this.funcionEditDataSelect = this.funcionEditDataSelect.bind(this); 
    }        
  	handleNewButton(){//Boton nuevo registro
        if(this.props.automatica === 'true'){
            this.props.funcionClick('FormDataGrid',{ idRow:0,mainContainer:this.props.mainContainer,titulo:this.props.titulo,apiField:this.props.apiField,apiRoute:this.props.apiRoute,formFields:this.props.formFields,funcionAfterInsert:this.props.funcionAfterInsert,dataLog:this.props.dataLog});
        } 
        else{            
            this.props.funcionNew(this.props.funcionNewParams);            
        }
    }  
    handleSearchField(event){//Boton de Busqueda        
        let key = event.keyCode;
        if(key === 13){  
            let searchWord = event.target.value;  
            this.setState({ searchWord: searchWord,offsetRecord: 0 }, () => {                        
                this.props.funcionClick(this.props.mainContainer,{
                                                                    dataFilter : this.state.arrayFilter, 
                                                                    searchWord: this.state.searchWord, 
                                                                    showRecords: this.state.showRecords, 
                                                                    offsetRecord: this.state.offsetRecord,                                                                    
                                                                 }); 
            });
        }        
    } 
    handleComboShow(event){//Combo de registros por pagina       
        let showRecords = event.target.value;       
        this.setState({ showRecords: showRecords }, () => {            
            this.props.funcionClick(this.props.mainContainer,{
                                                                dataFilter : this.state.arrayFilter, 
                                                                searchWord: this.state.searchWord, 
                                                                showRecords: this.state.showRecords, 
                                                                offsetRecord: this.state.offsetRecord,                                                                
                                                             });
        });      
    }
    handlePrevButton(event){//Retroceder
        let prevOffsetRecord = this.state.offsetRecord;    
        let offsetRecord = prevOffsetRecord-this.state.showRecords;  
        if(offsetRecord < 0){//tope inferior
            offsetRecord = 0;
        }      
        this.setState({ offsetRecord: offsetRecord }, () => {            
            this.props.funcionClick(this.props.mainContainer,{
                                                                dataFilter : this.state.arrayFilter, 
                                                                searchWord: this.state.searchWord, 
                                                                showRecords: this.state.showRecords, 
                                                                offsetRecord: this.state.offsetRecord,                                                                
                                                             });
        });      
    }
    handleNextButton(event){ //Avanzar 
        let prevOffsetRecord = this.state.offsetRecord;  
        let offsetRecord = prevOffsetRecord+this.state.showRecords; 
        if(offsetRecord > this.state.resultRows){//tope superior
            offsetRecord = prevOffsetRecord;
        }    
        this.setState({ offsetRecord: offsetRecord }, () => {            
            this.props.funcionClick(this.props.mainContainer,{
                                                                dataFilter : this.state.arrayFilter, 
                                                                searchWord: this.state.searchWord, 
                                                                showRecords: this.state.showRecords, 
                                                                offsetRecord: this.state.offsetRecord,                                                                 
                                                             });
        });      
    } 
    consultaFilas(){//Cuenta Filas
        //cargar info del dataFilter
        let sqlParam   = JSON.parse(JSON.stringify(this.state.sqlParams));
        let dataFilter = this.state.arrayFilter;
        if(Object.keys(dataFilter).length > 0){            
            for(var i in dataFilter){                
                if(dataFilter[i] !== ""){
                    sqlParam.sqlWhere.push(" AND "+i+" = '"+dataFilter[i]+"'");
                }
            }            
        }        
        cargarFilas(this.props.apiField,this.state.searchWord,1,0,this.state.date1,this.state.date2,sqlParam,'total',this.state.database,this.props.apiRoute)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } else {                
                this.setState({ resultRows: response[0].total })
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
        });
    }
    componentWillMount() {
        this.consultaFilas();   
        //inicializar el array del DataFIlter
        if(this.props.dataFilter !== undefined){      
            var arrayFilter = [...this.state.arrayFilter];      
            this.props.dataFilter.map((listado,i) => {                 
                arrayFilter[listado.field] = '';
                this.setState({arrayFilter: arrayFilter}); 
                return 0;                 
            }); 
        }     
    }
    handleDataFilter(field,e){  
        //actualizar el filtro      
        let arrayFilter = this.state.arrayFilter;
        let filter = {...arrayFilter[field]};    
        filter = e.target.value;   
        arrayFilter[field] = filter;   
        this.setState({arrayFilter},()=>{
            this.props.funcionClick(this.props.mainContainer,{
                                                                dataFilter : this.state.arrayFilter,
                                                                searchWord: this.state.searchWord, 
                                                                showRecords: this.state.showRecords, 
                                                                offsetRecord: this.state.offsetRecord,                                                                    
                                                             });
        });        
    }    
    componentDidUpdate(prevProps){           
        if (this.props.parametro !== prevProps.parametro) {           
           this.consultaFilas(); 
        }       
    } 
    handleDate1(e){ 
        if(e.target.value <= this.state.date2){       
            this.setState({ date1 : e.target.value },() => {                        
                this.props.funcionClick(this.props.mainContainer,{
                                                                      dataFilter : this.state.arrayFilter, 
                                                                      searchWord: this.state.searchWord, 
                                                                      showRecords: this.state.showRecords, 
                                                                      offsetRecord: this.state.offsetRecord,                                                                  
                                                                 }); 
            });
        }

    }
    handleDate2(e){        
        this.setState({ date2 : e.target.value },() => {                        
            this.props.funcionClick(this.props.mainContainer,{
                                                                  dataFilter : this.state.arrayFilter, 
                                                                  searchWord: this.state.searchWord, 
                                                                  showRecords: this.state.showRecords, 
                                                                  offsetRecord: this.state.offsetRecord,                                                                   
                                                             }); 
        });        
    }    
    convertirAExcel(){
        var jsonData = globalState.getState()['data_'+this.props.mainContainer];
        var itemsFormatted = [];
        var fileTitle = this.props.mainContainer; // or 'my-unique-title'
        var colsData = this.props.colsData;
        //cabecera
        var headers = {};
        colsData.map((colsData,i) => {
            if(colsData.type === 'bd'){
                let label = colsData['label'].replace(/,/g,'');
                headers[colsData.field] = label.replace( /(<([^>]+)>)/ig, '');               
            }
            return 0;
        });        
        // format the data
        jsonData.forEach((item) => {
            let itemJson = {};                    
            colsData.map((colsData,i) => {  
                if(colsData.type === 'bd'){              
                    var field = item[colsData.field];                
                    if(field === null || field === undefined){
                        field = '';
                    }
                    else{
                        field = field.toString();
                    }
                    field = field.replace(/,/g,'');
                    itemJson[colsData.field] = field.replace( /(<([^>]+)>)/ig, '');
                }
                return 0;
            });
            itemsFormatted.push(itemJson);
        });        
        exportCSVFile(headers, itemsFormatted, fileTitle);
    }
    refreshDatagrid(){
        this.props.funcionClick(this.props.mainContainer,{
                                                            dataFilter : this.state.arrayFilter, 
                                                            searchWord: this.state.searchWord, 
                                                            showRecords: this.state.showRecords, 
                                                            offsetRecord: this.state.offsetRecord,                                                                 
                                                         });
    }
    handleDataSelect(dataParams,field){//al dar clic en el campo de texto
        dataParams['funcionEdit'] = this.funcionEditDataSelect;      
        this.setState({windowDataSelectId : "windowDataGridSelect_"+field }, () => {
            globalState.dispatch({
                type   : "windowDataGridSelect_"+field,
                params : {
                    visible : true,
                    params  : dataParams,
                    width   : '400px', 
                    height  : '300px'
                }
            })
        });        
    }
    funcionEditDataSelect(data,params){//la funcion que carga los datos del DataSelect 
        let arrayFilter = this.state.arrayFilter;
        let filter = {...arrayFilter[params.idField]};    
        filter = data.id; 
        arrayFilter[params.idField] = filter;   
        this.setState({arrayFilter},()=>{
            this.props.funcionClick(this.props.mainContainer,{
                                                                dataFilter : this.state.arrayFilter,
                                                                searchWord: this.state.searchWord, 
                                                                showRecords: this.state.showRecords, 
                                                                offsetRecord: this.state.offsetRecord,                                                                    
                                                             });
            this.setState({[params.valueField] : data[params.fieldFetch]});
            this.setState({windowDataSelectId : "windowReportDataSelect_"+params.idField }, () => {
                globalState.dispatch({
                    type   : "windowReportDataSelect_"+params.idField,
                    params : {
                        visible : false,                    
                    }
                })
            }); 
        });
    } 
    resetSelectFilter(field,nom_field){ 
        let arrayFilter = this.state.arrayFilter;       
        delete arrayFilter[field];          
        this.setState({arrayFilter},()=>{
            this.props.funcionClick(this.props.mainContainer,{
                                                                dataFilter : this.state.arrayFilter,
                                                                searchWord: this.state.searchWord, 
                                                                showRecords: this.state.showRecords, 
                                                                offsetRecord: this.state.offsetRecord,                                                                    
                                                             });
            this.setState({[nom_field] :''});             
        });
    }                                                
  	render() {
        const divPDF = React.createRef(); 
        //los topes
        var lastRecord = this.state.offsetRecord+this.state.showRecords;
        if(lastRecord > this.state.resultRows || this.state.showRecords === 'todos'){
            lastRecord = this.state.resultRows;
        }                
        return (//El cuerpo del datagrid
            <div className="container ContenedorDataGrid">
                <div className="content">
                    {
                        this.props.automatica === 'true' ?
                            <div>
                                <div className="table-responsive mt-4">
                                    <div className="titulo">{this.props.titulo}</div>
                                </div>
                                <hr />
                            </div>
                        : ''
                    }
                    <div className="table-responsive mb-3">
                    {  
                        this.props.botonNuevo === 'true' && this.state.enableBtnNew === true ?
                                <Button id="dataGridBtnNew" variant="primary" onClick={this.handleNewButton.bind(this)} style={{backgroundColor:configJson.fondoBotonGrilla}} onMouseOut={divMouseOut.bind(this,'dataGridBtnNew',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'dataGridBtnNew',configJson.fondoBotonGrilla)}>AGREGAR NUEVO</Button>
                        : '' 
                    }
                    {
                        this.props.botonesExportar === 'true' ?
                            <div style={{textAlign:'right',float:'right'}}>
                            {
                                this.props.botonExportarXls === 'true' ?
                                    <div style={{textAlign:'right',float:'left'}}>
                                        <img src={ icono_xls } alt="Excel" />                                        
                                        <button onClick={this.convertirAExcel.bind(this)} type="button">Excel</button>
                                    </div>

                                : ''
                            }
                                    <div style={{textAlign:'right',float:'left'}}>

                                    </div>
                            {
                                this.props.botonExportarPDF === 'true' ?
                                    <div style={{textAlign:'right',float:'left'}}>
                                        <img src={ icono_pdf } alt="PDF" />
                                        <ReactToPdf targetRef={divPDF} filename={"informe_"+this.props.titulo+".pdf"} options={this.props.optionsPDF}>
                                            {
                                                ({toPdf}) => (
                                                    <button onClick={toPdf} className="save">PDF</button>
                                                )
                                            }
                                        </ReactToPdf>
                                    </div>
                                : ''
                            }
                            </div>                    
                        : ''
                        
                    }
                    </div>
                    <div className="table-responsive mb-3">                        
                        {
                            this.props.automatica === 'true' ?
                                <div style={{float:'left'}} className="filtrosDatagrid">
                                    <div style={{float:'left',width:'100px'}} className="labelSearch">Mostrar:</div> 
                                    <div style={{float:'left'}}>
                                        <select style={{border:'1px solid #ced3d9',width:'140px',height:'25px'}} defaultValue="15" onChange={this.handleComboShow.bind(this)}>                               
                                            <option value="15">15 Registros</option>
                                            <option value="20">20 Registros</option>
                                            <option value="30">30 Registros</option>
                                            <option value="50">50 Registros</option>
                                            <option value="todos">Todos los Registros</option>
                                        </select>                            
                                    </div>                                    
                                </div>
                            : ''
                        }
                        {
                            this.props.filtroFechas === 'true' ?
                                <div style={{float:'left'}} className="filtrosDatagrid">  
                                    <div style={{float:'left',width:'100px'}} className="labelSearch">Fecha Inicio:</div>
                                    <div style={{float:'left'}}>                      
                                        <input style={{border:'1px solid #ced3d9',width:'140px'}} type="date" id="start" name="fechaI" value={this.state.date1} onChange={this.handleDate1.bind(this)} max={this.state.date2}/>
                                    </div>
                                </div>
                            : ''
                        }
                        {
                            this.props.filtroFechas === 'true' ?
                                 <div style={{float:'left'}} className="filtrosDatagrid">  
                                    <div style={{float:'left',width:'100px'}} className="labelSearch">Fecha Final:</div>
                                    <div style={{float:'left'}}>                    
                                        <input style={{border:'1px solid #ced3d9',width:'140px'}} type="date" id="start" name="fechaF" value={this.state.date2} onChange={this.handleDate2.bind(this)} min={this.state.date1} max={this.state.dateH}/>
                                    </div>
                                </div>
                            : ''
                        }
                        {
                            this.props.dataFilter !== undefined ?
                                this.props.dataFilter.map((listado,i) => {   
                                    return <div key= {i} style={{float:'left'}} className="filtrosDatagrid">
                                                <div style={{float:'left',width:'100px'}} className="labelSearch">{listado.label}:&nbsp;</div> 
                                                <div style={{float:'left'}}>
                                                {
                                                    listado.dinamic === 'data_select' ?                                              
                                                        <div style={{position:'relative'}}>
                                                            <input type="text"  style={{float:'left',border:'1px solid #ced3d9',width:'140px',height:'25px'}} onClick={this.handleDataSelect.bind(this,listado.dataParams,listado.field)} value={this.state[listado.dataParams.fetchData.valueField] || 'Seleccione...'} readOnly/> 
                                                            <div style={{float:'left',position:'absolute',cursor:'pointer',right:'0px',top:'2px'}} onClick={this.resetSelectFilter.bind(this,listado.field,listado.dataParams.fetchData.valueField)} title="Eliminar seleccion">    
                                                                <MaterialIcon id="iconColumna" color="red" size={20} icon="remove_circle"/>
                                                            </div>
                                                        </div>
                                                    : <ComboBoxFilter style={{border:'1px solid #ced3d9',width:'140px',height:'25px'}} listado={listado} functionChange={this.handleDataFilter.bind(this,listado.field)} />  
                                                }   
                                                </div>                                               
                                            </div>

                                })                                 
                            : ''
                        }
                        <div style={{float:'left'}} className="filtrosDatagrid">
                            <div style={{float:'left',width:'100px'}} className="labelSearch">Buscar:</div> 
                            <div style={{float:'left'}}>
                                <input type="text" style={{border:'1px solid #ced3d9',width:'140px'}} onKeyUp={this.handleSearchField.bind(this)}/>
                            </div>
                            <div style={{float:'left',cursor:'pointer',paddingTop:'0px'}} onClick={this.refreshDatagrid.bind(this)} title="Actualizar Dashboard">
                                <MaterialIcon id="iconColumna" size={24} icon="refresh" color={configJson.fondoBotonGrilla}/>
                            </div> 
                        </div>
                    </div>                    
                    <div className="table-responsive" style={{height:'calc(100% - 170px)'}}>
                        <DataGridContainer funcionClick={this.props.funcionClick} 
                                           titulo={this.props.titulo}                                            
                                           colsData={this.props.colsData}
                                           apiField={this.props.apiField}
                                           apiRoute={this.props.apiRoute}
                                           formFields={this.props.formFields}
                                           mainContainer={this.props.mainContainer}
                                           parametro={this.props.parametro}
                                           automatica={this.props.automatica}
                                           enableRowClick={this.props.enableRowClick}                                           
                                           funcionEdit = {this.props.funcionEdit}                                           
                                           tbarEdit = {this.props.tbarEdit}
                                           tbarItems = {this.props.tbarItems}
                                           tbarLength = {this.props.tbarLength}
                                           funcionAfterUpdate = {this.props.funcionAfterUpdate}
                                           funcionBeforeDelete = {this.props.funcionBeforeDelete}
                                           divPDF = {divPDF}
                                           date1 = {this.state.date1}
                                           date2 = {this.state.date2}
                                           funcionEditParams = {this.props.funcionEditParams}
                                           permisoBtnUpdate = {this.props.permisoBtnUpdate}
                                           enableBtnEdit = {this.state.enableBtnUpd}
                                           enableBtnDel = {this.state.enableBtnDel}
                                           sqlParams = {this.state.sqlParams}
                                           dataFilter = {this.state.arrayFilter}
                                           dataLog = {this.props.dataLog}
                                           />
                    </div> 
                    <div className="table-responsive pie_grilla">
                        <div style={{float:'left',paddingTop:'8px'}} >
                            <div style={{fontSize:'12px',fontWeight:'bold',float:'left',width:'90px'}}>Mostrando</div> 
                            <div style={{fontSize:'12px',fontWeight:'bold',float:'left',width:'20px'}}>{(this.state.offsetRecord*1)+1}</div> 
                            <div style={{fontSize:'12px',fontWeight:'bold',float:'left',width:'20px',paddingLeft:'5px'}}>a</div> 
                            <div style={{fontSize:'12px',fontWeight:'bold',float:'left',width:'25px',paddingLeft:'5px'}}>{lastRecord*1}</div>
                            <div style={{fontSize:'12px',fontWeight:'bold',float:'left',width:'25px',paddingLeft:'5px'}}>de</div>
                            <div style={{fontSize:'12px',fontWeight:'bold',float:'left',width:'35px',paddingLeft:'5px'}}>{this.state.resultRows}</div>
                        </div>
                        <div style={{float:'right'}} >
                            <div style={{float:'left',cursor:'pointer'}} onClick={this.handlePrevButton.bind(this)}>
                                <MaterialIcon size={24} icon="keyboard_arrow_left" color={configJson.fondoBotonGrilla}/>
                            </div>
                            <div style={{float:'left',width:'20px'}} >
                                &nbsp;
                            </div>
                            <div style={{float:'left',cursor:'pointer'}} onClick={this.handleNextButton.bind(this)}>
                                <MaterialIcon size={24} icon="keyboard_arrow_right" color={configJson.fondoBotonGrilla}/>
                            </div>                            
                        </div>
                    </div>                   
                </div>
                <Window   //ventana para el data select
                    id = {this.state.windowDataSelectId}                    
                    title='Seleccione ...'                                                     
                    tbar="false"
                    componente="DataGridSelect"
                    params="" 
                 />
            </div>             
        )
    } 
}

export default DataGrid