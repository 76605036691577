/**
* CLASS PoliticasSeguridad
*
* Contiene el contenedor principal de las politicas de seguridad
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import FormDataGrid from '../data_grid/FormDataGrid';
import TBar from '../tbar/TBar';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';
import '../../css/alertify.css';

class PoliticasSeguridad extends Component {
    constructor(props){
        super(props);
        this.state={
            idRow : 0,
            database : globalState.getState().companyData[0].bd                 
        };    
        let sqlParams = {  
                            mode : 'rows'                                                 
                        }

        cargarFilas('politicas_seguridad','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{
                let idRow = 0;                
                if(response.length > 0){
                    idRow = response[0];                    
                }
                this.setState({ idRow: idRow });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });     
    }
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }    
    render() {          
        return (//carga el componente que contiene el formulario
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },                              
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>             
                    <FormDataGrid 
                        funcionClick = {this.props.funcionClick} 
                        parametro = {
                                        {
                                            idRow : this.state.idRow,
                                            mainContainer : 'PanelMenu',
                                            formulario : true,
                                            titulo : 'Politicas de Seguridad',
                                            apiField : 'politicas_seguridad', 
                                            enableBtnEdit : true,                                                                         
                                            formFields :  [{
                                                                label : 'Longitud Password *',
                                                                field : 'password_longitud',
                                                                type  : 'text',
                                                                validation : 'entero',
                                                                required : 'true',
                                                                tooltip : 'Ingrese la longitud del password'                                         
                                                            },                                                            
                                                            {
                                                                label : 'Mayusculas *',
                                                                field : 'password_mayuscula',
                                                                type  : 'select',
                                                                validation : '',
                                                                required : 'true',
                                                                dinamic : 'false',
                                                                valueName : 'nombre',
                                                                options :  [{
                                                                                id: 'true',
                                                                                nombre: 'Si',
                                                                            },
                                                                            {
                                                                                id: 'false',
                                                                                nombre: 'No', 
                                                                            }],
                                                                tooltip : 'Seleccione si se exige mayusculas'                                        
                                                            },
                                                            {
                                                                label : 'Minusculas *',
                                                                field : 'password_minuscula',
                                                                type  : 'select',
                                                                validation : '',
                                                                required : 'true',
                                                                dinamic : 'false',
                                                                valueName : 'nombre',
                                                                options :  [{
                                                                                id: 'true',
                                                                                nombre: 'Si',
                                                                            },
                                                                            {
                                                                                id: 'false',
                                                                                nombre: 'No', 
                                                                            }],
                                                                tooltip : 'Seleccione si se exige minusculas'                                        
                                                            },
                                                            {
                                                                label : 'Numeros *',
                                                                field : 'password_numero',
                                                                type  : 'select',
                                                                validation : '',
                                                                required : 'true',
                                                                dinamic : 'false',
                                                                valueName : 'nombre',
                                                                options :  [{
                                                                                id: 'true',
                                                                                nombre: 'Si',
                                                                            },
                                                                            {
                                                                                id: 'false',
                                                                                nombre: 'No', 
                                                                            }],
                                                                tooltip : 'Seleccione si se exige numeros'                                        
                                                            },
                                                            {
                                                                label : 'Simbolos *',
                                                                field : 'password_simbolo',
                                                                type  : 'select',
                                                                validation : '',
                                                                required : 'true',
                                                                dinamic : 'false',
                                                                valueName : 'nombre',
                                                                options :  [{
                                                                                id: 'true',
                                                                                nombre: 'Si',
                                                                            },
                                                                            {
                                                                                id: 'false',
                                                                                nombre: 'No', 
                                                                            }],
                                                                tooltip : 'Seleccione si se exige simbolos'                                        
                                                            },
                                                            {
                                                                label : 'Renovacion de Contraseña *',
                                                                field : 'password_vencimiento',
                                                                type  : 'select',
                                                                validation : '',
                                                                required : 'true',
                                                                dinamic : 'false',
                                                                valueName : 'nombre',
                                                                options :  [{
                                                                                id: '30',
                                                                                nombre: '30 dias',
                                                                            },
                                                                            {
                                                                                id: '60',
                                                                                nombre: '60 dias', 
                                                                            },
                                                                            {
                                                                                id: '90',
                                                                                nombre: '90 dias',
                                                                            },
                                                                            {
                                                                                id: '120',
                                                                                nombre: '120 dias',
                                                                            },
                                                                            {
                                                                                id: '0',
                                                                                nombre: 'No Aplica',
                                                                            },],
                                                                tooltip : 'Seleccione el tiempo de renovacion de contraseña'                                        
                                                            },
                                                            {
                                                                label : 'Tiempo de Sesion(Minutos) *',
                                                                field : 'tiempo_sesion',
                                                                type  : 'text',
                                                                validation : 'entero',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el tiempo de cierre de sesion por inactividad(Minutos)'                                        
                                                            }, 
                                                            {
                                                                label : 'Vigencia de Emails(Minutos) *',
                                                                field : 'tiempo_token',
                                                                type  : 'text',
                                                                validation : 'entero',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el tiempo de vigencia de emails(Minutos)'                                        
                                                            },  
                                                            {
                                                                label : 'Intentos Permitidos de Login *',
                                                                field : 'intentos_login',
                                                                type  : 'text',
                                                                validation : 'entero',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el numero de intentos permitidos de login'                                          
                                                            }] 
                                        }
                                    } 
                                
                    /> 
                </div> 
            </div>              
        )
    } 
}

export default PoliticasSeguridad