/**
* CLASS LogoCorporativo
*
* Contiene el componente para el logo corporativo
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import configJson from '../configuration/configuration.json';
import alertify from 'alertifyjs';
import ImagenUsuario from '../desktop/ImagenUsuario';
import {cargarFilas} from '../api_calls/ApiCalls';

class LogoCorporativo extends Component {
	constructor(props){
		super(props);
		this.state = {
			logoEmpresa : '',
		}
	}
	componentWillMount(){
		//cargar la informacion de la empresa
        let sqlParams = {
                        sqlCols : [
                            "id_local"                                    
                        ],                        
                        sqlWhere : [ " AND bd = '"+this.props.database+"'" ],                                
                    };                
        cargarFilas('ikarosof_management_acceso.companies','',1,0,'','',sqlParams,'rows','ikarosof_management_acceso',configJson.cloudRoute).then(res => {
	        var response1 = res.data;                       
	        if(response1.msg === 'error'){//aqui no me dejara continuar si hay un error
	            alertify.alert('Error!', 'Ha ocurrido un error accesando a la informacion de la empresa!<br />Codigo de Error: '+response1.detail);                
	        }
	        else{
	        	//cargar el logo de la empresa
	            sqlParams = {   
	                            sqlCols  : [ "nombre_archivo" ], 
	                            sqlWhere : [ " AND id_maestro="+response1[0].id_local ],
	                            sqlOrderBy : 'id DESC',
	                            mode : 'rows',                                                                                                
	                        }
	            cargarFilas('ikarosof_management_acceso.companies_adjuntos','',100,0,'','',sqlParams,'rows','ikarosof_management_acceso').then(result => {
		            result = result.data; 
		            if (result.msg === "error") {
		                alertify.alert('Error!', 'Ha ocurrido un error consultando el logo corporativo!<br />Codigo de Error: '+result.detail);	                 
		            } 
		            else{
		                if(result.length > 0){
		                    this.setState({logoEmpresa : result[0].nombre_archivo});
		                }
		            }
		        });
	        }   
	    });
	}

	render(){
		// Cargar el logo de la empresa
		var date = new Date();
        var randomDate = date.getTime(); 
        var path =  window.location.protocol+"//"+window.location.hostname+configJson.filesRoute; 
        var folder = this.props.database+'/ikarosof_management_acceso.companies_adjuntos'; 
		let objImage = {maxHeight:'60px',height:'60px'};
        if(this.state.logoEmpresa){
            objImage = {backgroundImage:'none'};
        }

        return <div style={{width:'calc(100% - 40px)',maxWidth:'600px',padding:'20px',paddingLeft:'0px',paddingBottom:'0px',margin:'0 auto'}}>
                    <div className="adM">
                    </div>
                    <div className="LogoCabecera LogoCabecera2" style={objImage}>
                    {
                        this.state.logoEmpresa !== '' ? 
                            <ImagenUsuario src={path+folder+'/'+this.state.logoEmpresa+'?'+randomDate} alt="Logo" />
                        : ''
                    }                        
                    </div>                        
                </div>
	}
}

export default LogoCorporativo 