/**
* CLASS ComboBoxFormDataGrid
*
* Contiene el contenedor del combobox para el formulario del data grid
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';
import '../../css/alertify.css';

class ComboBoxFormDataGrid extends Component {
    constructor(props) {
        let  arrayContent = [];
        super(props);
        this.state = {  
                        content  : arrayContent,
                        database : globalState.getState().companyData[0].bd
                     }
    }
    consultarFilas(){
        //agregar el filtro para actualizar el combo
        let queryParams = '';
        queryParams = JSON.parse(JSON.stringify(this.props.sqlParams));        
        if(this.props.fieldUpdate !== undefined){  
            if(this.props.sqlParams.sqlWhere === undefined){
                this.props.sqlParams.sqlWhere = '';
            }
            //actualizar el combo
            queryParams.sqlWhere = [ this.props.sqlParams.sqlWhere+' AND '+this.props.fieldUpdate+' = \''+this.props.valueUpdate+'\'' ];                      
        }        
        cargarFilas(this.props.apiField,'',50,0,'','',queryParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } else {
                this.setState({ content: response })
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
        });        
    }
    componentWillMount() {        
        //llenado dinamico del combobox
        if(this.props.dinamic === 'true'){
            this.consultarFilas();
        }
        else{
            this.setState({ content: this.props.options });
        }
        if(typeof this.props.onChange === 'function'){
            this.props.onChange(this.props.value);
        }        
    }
    componentDidUpdate(prevProps,prevState){  
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {   
            if(this.props.dinamic === 'true'){
                this.consultarFilas();
            } 
        }       
    }
  	render() {   
        var valueText = this.props.value;    
        if(valueText > 0 || (typeof valueText === 'string' && valueText.length > 0)){
            //...
        }
        else{
            valueText = "";
        }       
        return (
            <Form.Control disabled={this.props.readOnly} as="select" name = {this.props.name} onChange={this.props.functionChange} value={valueText}>
                <option value="" >Seleccione...</option>
                {
                    this.state.content.map((content,i) => {                       
                        return <option key={ i } value={content.id} >{content[this.props.valueName]}</option>

                    })                                        
                }                
            </Form.Control>                         
        )
    } 
}

export default ComboBoxFormDataGrid