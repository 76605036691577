/**
* CLASS Incidencias
*
* Contiene el contenedor principal de las incidencias
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import Window from '../window/Window';
import globalState from '../configuration/GlobalState';
import { diccionario } from '../configuration/configuration.json';
import alertify from 'alertifyjs';
import {asignarSolicitud} from '../api_calls/ApiCalls';
import {validarPermiso,fechaMysql,validarModulo} from '../configuration/GlobalFunctions';

const { LabelsIncidencias } = diccionario;

class Incidencias extends Component {   
    constructor(props){
        super(props);        
        let permisoGestion = 'none';
        if(validarPermiso(24)){ 
            permisoGestion = 'block';
        }
        this.state = {
            btnRecategoriza : permisoGestion,
            showLoading : false,
            btnGestionar    : permisoGestion,
            idioma : globalState.getState().idioma,
            database : globalState.getState().companyData[0].bd
        } 
    }     
    btnRecategorizar(idRow){
        globalState.dispatch({
            type   : "windowRecategorizarIncidencia",
            params : {
                          visible : true,
                          params  : {
                                        idRow  : idRow,
                                        funcionClick : this.props.funcionClick,
                                        idWin  : "windowRecategorizarIncidencia",
                                        width  : '300px', 
                                        height : '410px'//identificador de la ventana
                                    }
                     }
        });            
    } 
    btnGestionIncidencia(idRow){
        globalState.dispatch({
            type   : "windowGestionIncidencia",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,
                                        funcionClick : this.props.funcionClick,
                                        table : 'incidencias',
                                        tipo  : 'incidencia',
                                        componente : 'Incidencias',
                                        idWin : "windowGestionIncidencia",
                                        width : '340px', 
                                        height: '390px'//identificador de la ventana
                                    }
                     }
        });            
    }
    btnTrazabilidadIncidencia(idRow){
        globalState.dispatch({
            type   : "windowTrazabilidadIncidencia",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,
                                        table : 'incidencias',
                                        idWin : "windowTrazabilidadIncidencia",
                                        width : '320px', 
                                        height: '400px'//identificador de la ventana
                                    }
                     }
        });            
    }
    colFuncion(idRow){        
        globalState.dispatch({
                type   : "windowTrazabilidadIncidencia1",
                params : {
                              visible : true,
                              params  : {
                                            idRow : idRow,
                                            table : 'incidencias',
                                            idWin : "windowTrazabilidadIncidencia1",
                                            width : '320px', 
                                            height: '400px'//identificador de la ventana
                                        }
                         }
            }); 
    } 
    colFuncion2(idRow){        
        globalState.dispatch({
                type   : "windowArchivosAdjuntosIncidencias",
                params : {
                              visible : true,
                              params  : {
                                            idRow  : idRow,
                                            table  : 'incidencias',
                                            idWin  : "windowArchivosAdjuntosIncidencias",
                                            width  : '300px', 
                                            height : '250px'//identificador de la ventana
                                        }
                         }
            }); 
    }    
    //una vez se inserta el incidente se procede a asignar el tecnico
    asignarIncidencia(id){  
        let idioma = this.state.idioma;
        asignarSolicitud(id,'incidencia',this.state.database).then(response => {   
            response = response.data;         
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsIncidencias.error_alert1[idioma]+': '+response.detail);
            }
            else if(response.msg === 'no_capacidad'){
                alertify.alert('Error!', LabelsIncidencias.error_alert2[idioma], this.showAlertSuccess(response.consec));
            }
            else if(response.msg === 'no_sla'){                
                alertify.alert('Error!', LabelsIncidencias.error_alert3[idioma], this.showAlertSuccess(response.consec));
            }
            else if(response.msg === 'no_roles'){                
                alertify.alert('Error!', LabelsIncidencias.error_alert4[idioma], this.showAlertSuccess(response.consec));
            }
            else if(response.msg === 'jefe_no_encontrado'){
                alertify.alert('Error!', LabelsIncidencias.error_alert5[idioma], this.showAlertSuccess(response.consec));
            }
            else if(response.msg === 'mail_error'){                
                alertify.alert('Error!', LabelsIncidencias.error_alert6[idioma]+': '+JSON.stringify(response.detail, null, 4), this.showAlertSuccess(response.consec));
            } 
            else{
                alertify.alert('Aviso!', LabelsIncidencias.success_alert[idioma]+' Ticket No. '+response.consec+'!')
            }
            this.props.funcionClick('Incidencias');            
        })
        .catch(function (error) {            
            alertify.alert('Error!', LabelsIncidencias.error_alert1[idioma]+': '+error);
        });
    }
    showAlertSuccess(consec,e){//alert con el ticket       
        alert(LabelsIncidencias.success_alert[this.state.idioma]+' Ticket No. '+consec+'!');
    } 
  	render() {
        //privacidad de la visualizacion         
        let idUser  = globalState.getState().userData[0].id;        
        let externo = globalState.getState().userData[0].externo;
        let idioma  = this.state.idioma;
        let whereUser = '';
        if(validarPermiso(49)){
            whereUser = '';
        } 
        else if(validarPermiso(37)){
            whereUser = ' AND (id_usuario = '+idUser+' OR id_tecnico_incidencia = '+idUser+')';
        }
        else{
            whereUser = ' AND id_usuario = '+idUser;
        }
        //validar las areas de servicio habilitadas
        let whereArea = " AND (tipo='todas' OR tipo='interna')";        
        if(externo === 'true'){
            whereArea = " AND (tipo='todas' OR tipo='externa')";
        }
        //si tiene el permiso podrá ver todas las areas de servicio        
        if(validarPermiso(34)){
            whereArea = "";
        }

        // Id del area, si está vinculado a un area de servicio, solo podrá ver lo de su area
        let idArea = globalState.getState().userData[0].id_area;        
        let whereArea2 = '';
        if(idArea > 0){
            whereArea2 += " AND id = "+idArea;
        }

        let sqlParams = {
                            sqlCols : [
                                'T1.id',                                
                                'T1.id_activo',
                                'A.nombre AS nombre_activo',
                                'T1.id_activo',                                
                                'T1.impacto',
                                'T1.id_categoria',
                                'T1.id_subcategoria',
                                'T1.urgencia',
                                'T1.descripcion',                                
                                'SP.html AS nom_prioridad',
                                'AR.nombre AS nombre_area',
                                'T1.id_area',  
                                'T1.prioridad',
                                'T1.asunto', 
                                "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i') AS fecha", 
                                "SE.nombre AS estado", 
                                "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario", 
                                "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                "DATE_FORMAT(T1.fecha_asignacion_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_asignacion", 
                                "DATE_FORMAT(T1.fecha_solucion_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_solucion",
                            ],
                            sqlJoin : [
                                'INNER JOIN users AS U ON (U.id = T1.id_usuario)',
                                'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_incidencia)',
                                'INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)',
                                'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                'INNER JOIN areas_servicio AS AR ON (AR.id = T1.id_area)', 
                                'LEFT JOIN activos AS A ON (A.id = T1.id_activo)'
                            ],
                            fieldSearch : [
                                "T1.asunto",
                                "T1.id",
                                'AR.nombre'                                
                            ],
                            sqlWhere : [
                                ' AND T1.problema = "false"'+whereUser
                            ],                                                        
                            fieldFechas : "T1.fecha",
                            fecha_completa : "true",
                            sqlOrderBy : "T1.id DESC"                                             
                        };   

        let windowRecategorizarIncidencia = <Window 
                                                id = "windowRecategorizarIncidencia"                      
                                                title={LabelsIncidencias.window1_title[idioma]}                                                
                                                tbar="false"
                                                componente="WindowRecategorizarIncidencia"
                                                params="" 
                                            /> 

         let windowGestionIncidencia = <Window 
                                            id = "windowGestionIncidencia"                      
                                            title={LabelsIncidencias.window2_title[idioma]}                                            
                                            tbar="false"
                                            componente="WindowProcesoGestion"
                                            params= ""
                                        /> 
          

        let windowTrazabilidadIncidencia =  <Window 
                                                id = "windowTrazabilidadIncidencia"                      
                                                title={LabelsIncidencias.window3_title[idioma]}                                               
                                                tbar="false"
                                                componente="WindowTrazabilidad"
                                                params="" 
                                            /> 


        let fieldActivos = null;        
        if(validarModulo(6)){            
            fieldActivos   =    {
                                    label : LabelsIncidencias.form_field9[idioma],                                        
                                    type  : 'data_select',
                                    field : 'id_activo',
                                    dataParams : { 
                                                     fetchData : {
                                                          fieldFetch  : 'nombre',
                                                          idField     : 'id_activo',
                                                          valueField  : 'nombre_activo',
                                                     },
                                                     apiField    : 'activos',
                                                     colsData    : [ 
                                                                        {
                                                                            type  : 'bd',
                                                                            label : 'Codigo',
                                                                            field : 'codigo'
                                                                        },
                                                                        {
                                                                            type  : 'bd',
                                                                            label : 'Nombre',
                                                                            field : 'nombre'
                                                                        },
                                                                    ],
                                                     sqlParams : {
                                                                    sqlCols : [ 
                                                                        'id',                                 
                                                                        'codigo',
                                                                        'nombre'                                
                                                                    ],                            
                                                                    fieldSearch : [                                                                            
                                                                        'codigo',
                                                                        'nombre',                                                                            
                                                                    ],  
                                                                    sqlWhere : [ " AND estado < 7" ]                                         
                                                                 }                                                                                           
                                                 },
                                    validation : '',
                                    required : 'false',
                                    tooltip : LabelsIncidencias.form_field9_tooltip[idioma],                                                                                            
                                };
        }                                             
                                            
        return (
                <div>
                    <div>
                        <DataGrid titulo={LabelsIncidencias.Titulo[idioma]} 
                                  funcionClick={this.props.funcionClick}  
                                  parametro={this.props.parametro} 
                                  tbarEdit="true"
                                  dataFilter={[
                                        {
                                            label   : LabelsIncidencias.dataFilter1[idioma],
                                            dinamic : 'false',
                                            field   : 'T1.prioridad',
                                            options :  [{
                                                            id: '4',
                                                            nombre: LabelsIncidencias.dataFilter1_op1[idioma],
                                                        },
                                                        {
                                                            id: '3',
                                                            nombre: LabelsIncidencias.dataFilter1_op2[idioma],
                                                        },
                                                        {
                                                            id: '2',
                                                            nombre: LabelsIncidencias.dataFilter1_op3[idioma], 
                                                        },
                                                        {
                                                            id: '1',
                                                            nombre: LabelsIncidencias.dataFilter1_op4[idioma], 
                                                        }]
                                        },
                                        {
                                            label   : LabelsIncidencias.dataFilter2[idioma],
                                            dinamic : 'false',
                                            field   : 'T1.estado',
                                            options :  [{
                                                            id: '0',
                                                            nombre: LabelsIncidencias.dataFilter2_op1[idioma],
                                                        },
                                                        {
                                                            id: '1',
                                                            nombre: LabelsIncidencias.dataFilter2_op2[idioma], 
                                                        },
                                                        {
                                                            id: '2',
                                                            nombre: LabelsIncidencias.dataFilter2_op3[idioma], 
                                                        },
                                                        {
                                                            id: '3',
                                                            nombre: LabelsIncidencias.dataFilter2_op4[idioma], 
                                                        },
                                                        {
                                                            id: '4',
                                                            nombre: LabelsIncidencias.dataFilter2_op5[idioma], 
                                                        },
                                                        {
                                                            id: '5',
                                                            nombre: LabelsIncidencias.dataFilter2_op6[idioma], 
                                                        },
                                                        {
                                                            id: '6',
                                                            nombre: LabelsIncidencias.dataFilter2_op7[idioma], 
                                                        }]
                                        },
                                        {
                                            label : LabelsIncidencias.dataFilter3[idioma],
                                            dinamic : 'true',
                                            field : 'T1.id_area',
                                            sqlParams : {
                                                            sqlCols : [
                                                                'id',
                                                                'nombre'                                
                                                            ],                                                            
                                                            sqlWhere : [ " AND atencion_incidencias='true'"+whereArea+whereArea2 ],                                                                                                                                                                                                                            
                                                        },
                                            apiField : 'areas_servicio',                                            
                                            valueName : 'nombre',
                                        }                                                 
                                  ]}
                                  tbarItems={[
                                                {
                                                      type : 'boton',
                                                      icon : 'done',
                                                      width : '80px',
                                                      height : '70px',
                                                      title : LabelsIncidencias.window1_title[idioma],
                                                      display : this.state.btnRecategoriza, 
                                                      windowComponent : windowRecategorizarIncidencia,
                                                      function : this.btnRecategorizar.bind(this)                                       
                                                },
                                                {
                                                      type : 'boton',
                                                      icon : 'assignment_turned_in',
                                                      width : '80px',
                                                      height : '70px',
                                                      title : LabelsIncidencias.edit_btn2[idioma],
                                                      display : this.state.btnGestionar, 
                                                      windowComponent : windowGestionIncidencia,
                                                      function : this.btnGestionIncidencia.bind(this)                                       
                                                },                                                                                               
                                                {
                                                      type : 'boton',
                                                      icon : 'description',
                                                      width : '80px',
                                                      height : '70px',
                                                      title : LabelsIncidencias.edit_btn3[idioma],
                                                      display : true, 
                                                      windowComponent : windowTrazabilidadIncidencia,
                                                      function : this.btnTrazabilidadIncidencia.bind(this)                                       
                                                },                                                
                                            ]}                              
                                  colsData={[
                                                {
                                                    type  : 'bd',
                                                    label : LabelsIncidencias.ColLabel1[idioma],
                                                    field : 'id'
                                                },
                                                {
                                                    type  : 'bd_html',
                                                    label : LabelsIncidencias.ColLabel2[idioma],
                                                    field : 'nom_prioridad'
                                                },
                                                {
                                                    type    : 'bd',
                                                    label   : LabelsIncidencias.ColLabel2[idioma],
                                                    field   : 'prioridad',
                                                    onlyXLS : 'true'
                                                },
                                                {
                                                    type    : 'bd',
                                                    label   : LabelsIncidencias.ColLabel2_1[idioma],
                                                    field   : 'impacto',
                                                    onlyXLS : 'true'
                                                },
                                                {
                                                    type    : 'bd',
                                                    label   : LabelsIncidencias.ColLabel2_2[idioma],
                                                    field   : 'urgencia',
                                                    onlyXLS : 'true'
                                                },
                                                {
                                                    type  : 'bd',
                                                    label : LabelsIncidencias.ColLabel3[idioma],
                                                    field : 'estado'
                                                },
                                                {
                                                    type  : 'bd',
                                                    label : LabelsIncidencias.ColLabel4[idioma],
                                                    field : 'fecha'
                                                },
                                                {
                                                    type  : 'bd',
                                                    label : LabelsIncidencias.ColLabel5[idioma],
                                                    field : 'nombre_area'
                                                },
                                                {
                                                    type  : 'bd',
                                                    label : LabelsIncidencias.ColLabel6[idioma],
                                                    field : 'asunto'
                                                }, 
                                                {
                                                    type    : 'bd',
                                                    label   : LabelsIncidencias.ColLabel8[idioma],
                                                    field   : 'descripcion',
                                                    onlyXLS : 'true'
                                                },
                                                {
                                                    type  : 'bd',
                                                    label : LabelsIncidencias.ColLabel7[idioma],
                                                    field : 'usuario'
                                                }, 
                                                {
                                                    type    : 'bd',
                                                    label   : LabelsIncidencias.ColLabel9[idioma],
                                                    field   : 'tecnico',
                                                    onlyXLS : 'true'
                                                },       
                                                {
                                                    type    : 'bd',
                                                    label   : LabelsIncidencias.ColLabel10[idioma],
                                                    field   : 'fecha_asignacion',
                                                    onlyXLS : 'true'
                                                },  
                                                {
                                                    type    : 'bd',
                                                    label   : LabelsIncidencias.ColLabel11[idioma],
                                                    field   : 'fecha_solucion',
                                                    onlyXLS : 'true'
                                                },                                      
                                                {
                                                    type  : 'manual',
                                                    label : '',
                                                    icon  : 'description',
                                                    colFuncion : this.colFuncion.bind(this)
                                                },                                                
                                                {
                                                    type  : 'manual',
                                                    label : '',
                                                    icon  : 'attach_file',
                                                    colFuncion : this.colFuncion2.bind(this)
                                                }                                                                        
                                            ]}
                                  sqlParams = { sqlParams }
                                  automatica="true"
                                  botonNuevo="true"
                                  funcionAfterInsert={this.asignarIncidencia.bind(this)}
                                  botonesExportar="true"
                                  botonExportarXls="true"
                                  botonExportarPDF="false"                                  
                                  filtroFechas="true"  
                                  formFields={[                                                
                                                {
                                                    label : LabelsIncidencias.ColLabel2_2[idioma]+' *',
                                                    field : 'urgencia',
                                                    type  : 'select',
                                                    validation : '',
                                                    required : 'true',
                                                    dinamic : 'false',
                                                    valueName : 'nombre',
                                                    options :  [{
                                                                    id: '4',
                                                                    nombre: LabelsIncidencias.dataFilter1_op1[idioma],
                                                                },
                                                                {
                                                                    id: '3',
                                                                    nombre: LabelsIncidencias.dataFilter1_op2[idioma],
                                                                },
                                                                {
                                                                    id: '2',
                                                                    nombre: LabelsIncidencias.dataFilter1_op3[idioma], 
                                                                },
                                                                {
                                                                    id: '1',
                                                                    nombre: LabelsIncidencias.dataFilter1_op4[idioma], 
                                                                }],
                                                    tooltip : LabelsIncidencias.ColLabel2_2_tooltip[idioma],                                                                                            
                                                },
                                                {
                                                    label : LabelsIncidencias.ColLabel2_1[idioma]+' *',
                                                    field : 'impacto',
                                                    type  : 'select',
                                                    validation : '',
                                                    required : 'true',
                                                    dinamic : 'false',
                                                    valueName : 'nombre',
                                                    options :  [{
                                                                    id: '4',
                                                                    nombre: LabelsIncidencias.ColLabel2_1_op1[idioma],
                                                                },
                                                                {
                                                                    id: '3',
                                                                    nombre: LabelsIncidencias.ColLabel2_1_op2[idioma],
                                                                },
                                                                {
                                                                    id: '2',
                                                                    nombre: LabelsIncidencias.ColLabel2_1_op3[idioma], 
                                                                },
                                                                {
                                                                    id: '1',
                                                                    nombre: LabelsIncidencias.ColLabel2_1_op4[idioma], 
                                                                }],
                                                    tooltip : LabelsIncidencias.ColLabel2_1_tooltip[idioma],                                                                                            
                                                }, 
                                                {
                                                    label : LabelsIncidencias.form_field3[idioma]+' *',
                                                    field : 'id_area',
                                                    type  : 'select',
                                                    validation : '',
                                                    required : 'true',
                                                    dinamic : 'true',
                                                    apiField : 'areas_servicio',
                                                    valueName : 'nombre',
                                                    updateTo : 'id_categoria',
                                                    sqlParams : {
                                                                    sqlCols : [
                                                                        'id',
                                                                        'nombre'                                
                                                                    ],
                                                                    sqlWhere : [ " AND atencion_incidencias='true'"+whereArea ],                                                                                                                                                                                                                                    
                                                                },
                                                    tooltip : LabelsIncidencias.form_field3_tooltip[idioma],                                                                                                
                                                },
                                                {
                                                    label : LabelsIncidencias.form_field4[idioma]+' *',
                                                    field : 'id_categoria',
                                                    type  : 'select',
                                                    validation : '',
                                                    required : 'true',
                                                    dinamic : 'true',
                                                    apiField : 'areas_servicio_categorias',
                                                    valueName : 'nombre',                                       
                                                    updateTo : 'id_subcategoria',
                                                    fieldUpdate : 'id_area', 
                                                    sqlParams : {
                                                                    sqlCols : [
                                                                        'id',
                                                                        'nombre'                                
                                                                    ],
                                                                    sqlWhere : [ " AND atencion_incidencias='true'" ],                                                   
                                                                },
                                                    tooltip : LabelsIncidencias.form_field4_tooltip[idioma] 
                                                }, 
                                                {
                                                    label : LabelsIncidencias.form_field5[idioma]+' *',
                                                    field : 'id_subcategoria',
                                                    type  : 'select',
                                                    validation : '',
                                                    required : 'true',
                                                    dinamic : 'true',
                                                    apiField : 'areas_servicio_subcategorias',
                                                    valueName : 'nombre',
                                                    fieldUpdate : 'id_categoria',                                        
                                                    sqlParams : {
                                                                    sqlCols : [
                                                                        'id',
                                                                        'nombre'                                
                                                                    ],                                                                                                                                                         
                                                                },
                                                    tooltip : LabelsIncidencias.form_field5_tooltip[idioma] 
                                                },                                                  
                                                fieldActivos,
                                                {
                                                    label : LabelsIncidencias.form_field6[idioma]+' *',
                                                    field : 'asunto',
                                                    type  : 'text',
                                                    validation : '',
                                                    required : 'true',
                                                    longitud : 60,
                                                    tooltip : LabelsIncidencias.form_field6_tooltip[idioma],                                                    
                                                },                                                                         
                                                {
                                                    label : LabelsIncidencias.form_field7[idioma]+' *',
                                                    field : 'descripcion',
                                                    type  : 'textarea-rich',
                                                    rows  : 3,
                                                    validation : '',
                                                    required : 'true',
                                                    tooltip : LabelsIncidencias.form_field7_tooltip[idioma],                                                    
                                                }, 
                                                {
                                                    label  : LabelsIncidencias.form_field8[idioma],                                                    
                                                    field  : 'nombre_archivo',                                                                                                        
                                                    type   : 'multi_file',
                                                    accept : ".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png",
                                                    allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],
                                                    validation : 'file',
                                                    required : '',
                                                    tooltip : LabelsIncidencias.form_field8_tooltip[idioma]
                                                },
                                                {
                                                    label : '',
                                                    field : 'id_usuario',
                                                    type  : 'hidden',
                                                    validation : '',
                                                    value : globalState.getState().userData[0].id,
                                                    required : 'true'                                        
                                                }, 
                                                {
                                                    label : '',
                                                    field : 'fecha',
                                                    type  : 'hidden',
                                                    validation : '',
                                                    value : fechaMysql(),
                                                    required : 'true'                                        
                                                },  
                                            ]}                     
                                  apiField = {'incidencias'}
                                  permisoInsertUpdate="3"
                                  permisoBtnUpdate="19"
                                  permisoDelete="19"
                                  mainContainer='Incidencias'/> 
                </div> 
                <Window 
                    id = "windowTrazabilidadIncidencia1"                      
                    title={LabelsIncidencias.window3_title[idioma]}                    
                    tbar="false"
                    componente="WindowTrazabilidad"
                    params="" 
                />
                <Window 
                    id = "windowArchivosAdjuntosIncidencias"                     
                    title={LabelsIncidencias.window4_title[idioma]}                    
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                />                                                               
            </div>
        )
    } 
}

export default Incidencias