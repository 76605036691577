/**
* CLASS WindowEditarFichaTecnica
*
* Contiene el contenedor principal de la ficha tecnica
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,guardarCamposFicha} from '../api_calls/ApiCalls';
import ComboBoxFichaTecnica from './ComboBoxFichaTecnica';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import globalState from '../configuration/GlobalState';
import TBar from '../tbar/TBar';
import alertify from 'alertifyjs';

class WindowEditarFichaTecnica extends Component {
    constructor(props){
        super(props);
        this.state = {
            listadoCampos : [],
            database : globalState.getState().companyData[0].bd
        };
    }
    componentWillMount(){
        let sqlParams = {
                            sqlCols : [
                                "T1.id",
                                "T1.nombre",
                                "T1.tipo",
                                "T1.validacion",
                                "T1.longitud", 
                                "F.valor",                               
                            ],
                            sqlJoin : [
                                "LEFT JOIN activos_ficha AS F ON (F.id_campo = T1.id AND F.id_activo="+this.props.params.idRow+")",
                                "INNER JOIN activos AS A ON(A.id_tipo = T1.id_tipo AND A.id="+this.props.params.idRow+")"
                            ],                                                                         
                            sqlWhere : [ ]
                        };
        cargarFilas('activos_tipos_campos_ficha','',1000,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{
                let campo = '';
                response.map((formFields,i) => {
                    campo = 'campo_'+formFields.id;  
                    if(formFields.valor === null){
                        formFields.valor = '';
                    }                  
                    this.setState({[campo]:formFields.valor});
                    return null;
                });
                this.setState({ listadoCampos: response });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    guardarFichaTecnica(){        
        let count=0;
        let arrayCampos = {}; 
        this.state.listadoCampos.map((formFields,i) => {            
            if(this.state['campo_'+formFields.id] === '' || this.state['campo_'+formFields.id] === undefined){
                alertify.alert('Aviso!', 'Ingrese el campo '+formFields.nombre);
                count++;   
            }
            else{
                arrayCampos['campo_'+formFields.id] = this.state['campo_'+formFields.id];
            }
            return null;
        });
        if(count === 0){
            guardarCamposFicha(arrayCampos,this.props.params.idRow,this.state.database)
            .then(res => {
                var response = res.data; 
                if (response.msg === "error") {
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                } 
                else{
                    alertify.alert('Aviso!', 'Se ha guardado la informacion con exito!');
                    globalState.dispatch({
                        type   : "windowEditarFichaTecnica",
                        params : {
                                      visible : false,                                      
                                 }
                    });
                }
            })
            .catch( err => {            
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
            });
        }
    }
    handleStateChange(validation,longitud,e){
        let ingresado = e.target.value;
        ingresado = ingresado.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\n,:.@\-+#?¿_ ]/g,'');
        if(validation === 'mayusculas'){
            ingresado = ingresado.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ?¿ ]/g,'');
            ingresado = ingresado.toUpperCase();
        }
        if(validation === 'texto_mayusculas'){
            ingresado = ingresado.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9?¿ ]/g,'');
            ingresado = ingresado.toUpperCase();
        }
        if(validation === 'entero'){
            ingresado = ingresado.replace(/[^\d]/g,'');
        }
        if(validation === 'numero_real'){
            ingresado = ingresado.replace(/[^\d.]/g,'');
        }
        if(longitud!== undefined && longitud > 0){
            if(ingresado.length <= longitud){
                this.setState({ [e.target.name]: ingresado });                    
            }
        }
        else{
            this.setState({ [e.target.name]: ingresado });
        }
    }
  	render() {   
        var field = '';
        var field1 = '';
        var count = 1;                
        return (//carga el componente que contiene la grilla de datos
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'save',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Guardar',
                                  display : true,
                                  function : this.guardarFichaTecnica.bind(this)
                              },
                          ]}
                    length = '1'
                />           
                <div className="container" style={{position:"relative",top:'65px'}}>
                    <div className="content">
                        <div className="table-responsive mb-3"> 
                            <Form>
                            {
                                this.state.listadoCampos.map((formFields,i) => {
                                    if(formFields.tipo === 'text' || formFields.tipo === 'date'){
                                        field = <>{field}<Form.Group as={Col}  controlId={"formField_"+formFields.id}>
                                                    <Form.Label>
                                                        {formFields.nombre}                                                  
                                                    </Form.Label>
                                                    <Form.Control name = {'campo_'+formFields.id} type={formFields.tipo} onChange={this.handleStateChange.bind(this,formFields.validacion,formFields.longitud)} value={this.state['campo_'+formFields.id]}/>                               
                                                </Form.Group></>
                                    }
                                    if(formFields.tipo === 'select'){
                                        field = <>{field}<Form.Group as={Col}  controlId={"formField_"+formFields.id}>
                                                    <Form.Label>
                                                        {formFields.nombre}                                                  
                                                    </Form.Label>
                                                    <ComboBoxFichaTecnica                                              
                                                        name = {'campo_'+formFields.id}
                                                        idActivo = {this.props.params.idRow}
                                                        idCampo = {formFields.id}
                                                        functionChange={this.handleStateChange.bind(this,'',0)} 
                                                        value={this.state['campo_'+formFields.id]}                                                            
                                                    />                            
                                                </Form.Group></>
                                    }
                                    else if(formFields.tipo === 'textarea'){
                                        if(count%2===0){
                                            count+=3;
                                            field1 = field;
                                            field = '';                                                                                                                                                                           
                                        } 
                                        else{
                                            field1 = '';                                                
                                        } 
                                        return <div key= {i}> 
                                                    <Form.Row style={{width:'99%'}}>
                                                        {field1}
                                                    </Form.Row>
                                                    <Form.Row style={{width:'99%'}}>
                                                        <Form.Group as={Col} key= {i} controlId={"formField_"+formFields.id}>
                                                            <Form.Label>
                                                                {formFields.nombre}                                                            
                                                            </Form.Label>                                                        
                                                            <Form.Control name = {'campo_'+formFields.id} as="textarea" rows="3" onChange={this.handleStateChange.bind(this,formFields.validacion,formFields.longitud)} value={this.state['campo_'+formFields.id]}/>
                                                        </Form.Group>
                                                    </Form.Row>
                                               </div>
                                    } 
                                    //organizar las columnas en filas
                                    if(count%2===0){
                                        count++;
                                        field1 = field;
                                        field = '';                                       
                                        return <Form.Row style={{width:'99%'}} key= {i}>
                                                    {field1}
                                                </Form.Row>;   

                                    }                                    
                                    else{
                                        count++;                                                                                
                                    } 
                                    return null;
                                })
                            }                        
                            {
                                (count%2===0)?<Form.Row style={{width:'99%'}}>{field}</Form.Row>:''                                 
                            }                    
                            </Form>
                        </div>
                    </div>
                </div>  
            </div>          
        )
    } 
}

export default WindowEditarFichaTecnica