/**
* CLASS WindowVisorLog
*
* Contiene el contenedor principal del visor del log de sucesos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';

class WindowVisorLog extends Component {      
    verInformacionLog(idRow){
        this.props.funcionClick('WindowVerDatosLog',{funcionClick:this.props.funcionClick,idRow:idRow});
    }
  	render() {        
        let sqlParams = {
                            sqlCols : [
                                "T1.id",   
                                "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i') AS fecha",                                                                                                
                                'T1.accion',                                 
                                "IF(U1.id IS NULL,'SISTEMA',CONCAT(U1.primer_nombre,' ',U1.primer_apellido)) AS nombre_funcionario", 
                            ],
                            sqlJoin : [
                                'LEFT JOIN users AS U1 ON (U1.id = T1.id_usuario)',                                                                                                                          
                            ],
                            fieldSearch : [
                                'T1.accion',
                                "CONCAT(U1.primer_nombre,' ',U1.primer_apellido)", 
                            ],                                                
                            sqlWhere : [
                                " AND tabla='"+this.props.params.table+"' AND id_tabla = "+this.props.params.idRow
                            ],
                            sqlOrderBy : 'T1.fecha DESC',                                                                                               
                        }                
        return (//carga el componente que contiene la grilla de datos
            <div style={{height:'calc(100% - 30px)',overflowY:'auto'}}>
                <DataGrid titulo='Log de Usuarios' 
                          funcionClick={this.props.funcionClick}   
                          parametro={this.props.parametro} 
                          colsData={[ 
                                        {
                                            type  : 'bd',
                                            label : 'Fecha',
                                            field : 'fecha'
                                        },
                                        {
                                            type  : 'bd',
                                            label : 'Accion',
                                            field : 'accion'
                                        },
                                        {
                                            type  : 'bd',
                                            label : 'Usuario',
                                            field : 'nombre_funcionario'
                                        },                                      
                                    ]}
                          sqlParams = { sqlParams }                       
                          automatica="false"
                          botonNuevo="false"
                          apiField={'log'}
                          funcionEdit={this.verInformacionLog.bind(this)}
                          permisoInsertUpdate="19"
                          permisoBtnUpdate="19"
                          permisoDelete="19"
                          mainContainer='WindowVisorLog'/>
            </div>            
        )
    } 
}

export default WindowVisorLog