/**
* CLASS WindowConocimientoPorPublicar
*
* Contiene el componente que lista los conocimientos por publicar
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,eliminarFilas,insertarActualizarFila} from '../api_calls/ApiCalls';
import Table from 'react-bootstrap/Table';
import MaterialIcon from 'material-icons-react';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';
import {validarPermiso} from '../configuration/GlobalFunctions';
import '../data_grid/dataGrid.css'; 

class WindowConocimientoPorPublicar extends Component {
    constructor(props) {        
        super(props);   
        this.state = {           
            listadoPendientes : [],
            updateListado : 0,
            database : globalState.getState().companyData[0].bd
        };
    }
    componentWillMount(){       
        this.cargarConocimiento();   
    }   
    componentDidUpdate(prevProps,prevState){
        if (this.state.updateListado !== prevState.updateListado) {           
           this.cargarConocimiento();            
        }       
    } 
    handleViewObservation(obs){
        alertify.alert('Ver Detalles',obs);
    }
    cargarConocimiento(){        
        var idUsuario = this.props.params.idUsuario;        
        let where = '';
        if(!validarPermiso(49)){
            where = ' AND T1.id_tecnico = '+idUsuario;
        }        
        let sqlParams = { 
                            sqlCols : [
                                "T1.id",
                                "A.nombre AS area",
                                "IC.nombre AS categoria",
                                "ISC.nombre AS subcategoria",
                                "T1.tema",
                                "T1.solucion"              
                            ], 
                            sqlJoin : [
                                "INNER JOIN areas_servicio AS A ON (A.id = T1.id_area)",
                                "INNER JOIN areas_servicio_categorias AS IC ON (IC.id = T1.id_categoria)",
                                "INNER JOIN areas_servicio_subcategorias AS ISC ON (ISC.id = T1.id_subcategoria)"  
                            ],                      
                            sqlWhere : [ " AND T1.aprobacion = 'false' "+where ],                                
                        }
        cargarFilas('conocimiento','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{                
                this.setState({listadoPendientes : response});                
            }     
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })
    }
    handleDeletePublicacion(id,opc){//boton eliminar
        alertify.confirm('Confirmacion', 'Esta seguro(a) de eliminar esta publicacion?', this.handleGestionPublicacion.bind(this,id,opc), function(){});
    } 
    handleNoPrivPublicacion(id,opc){//boton eliminar
        alertify.confirm('Confirmacion', 'Esta seguro(a) de publicar esta publicacion accesible a todos los usuarios?', this.handleGestionPublicacion.bind(this,id,opc), function(){});
    } 
    handlePrivPublicacion(id,opc){//boton eliminar
        alertify.confirm('Confirmacion', 'Esta seguro(a) de publicar esta publicacion privada?', this.handleGestionPublicacion.bind(this,id,opc), function(){});
    }
    handleGestionPublicacion(id,opc){
        let objectData = {};         
        if(opc === 'delete'){
            let where = [ " id = '"+id+"'" ];
            eliminarFilas('conocimiento',0,'false','delete',where,this.state.database).then(response => {
                response = response.data;   
                if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                }
                else{                
                    this.setState({updateListado: this.state.updateListado+1});                
                }     
            })
            .catch(function (error) {
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            }); 
        } 
        else if(opc === 'no_priv'){
            objectData = {
                id : id,
                aprobacion : 'true',
                privado : 'false'
            }
            insertarActualizarFila('put','conocimiento',objectData,null,undefined,this.state.database).then(response => {
                response = response.data;   
                if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                }
                else{                
                    this.setState({updateListado: this.state.updateListado+1});                
                }     
            })
            .catch(function (error) {
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            });
        } 
        else if(opc === 'priv'){
            objectData = {
                id : id,
                aprobacion : 'true',
                privado : 'true'
            }
            insertarActualizarFila('put','conocimiento',objectData,null,this.state.database).then(response => {
                response = response.data;   
                if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                }
                else{                
                    this.setState({updateListado: this.state.updateListado+1});                
                }     
            })
            .catch(function (error) {
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            });                     
        }                 
    }      
  	render() {             
  	  	return (
                <div id="no-more-tables">
                    <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}}>
                         <thead className="cf">
                            <tr>
                                <th style={{fontSize:'12px'}}>No</th>
                                <th style={{fontSize:'12px'}}>Area</th>
                                <th style={{fontSize:'12px'}}>Categoria</th>
                                <th style={{fontSize:'12px'}}>Subcategoria</th>
                                <th style={{fontSize:'12px'}}>Tema</th>                                  
                                <th style={{fontSize:'12px'}}>&nbsp;</th>
                                <th style={{fontSize:'12px'}}>&nbsp;</th>  
                                <th style={{fontSize:'12px'}}>&nbsp;</th> 
                            </tr>
                        </thead>    
                        <tbody>
                        {
                            this.state.listadoPendientes.map((listado,i) => {
                                return <tr key={ i } >
                                            <td style={{fontSize:'10px'}}>{i+1}</td> 
                                            <td data-title="Area" style={{fontSize:'10px'}}>{listado.area}&nbsp;</td>
                                            <td data-title="Categoria" style={{fontSize:'10px'}}>{listado.categoria}&nbsp;</td>
                                            <td data-title="Subcategoria" style={{fontSize:'10px'}}>{listado.subcategoria}&nbsp;</td>
                                            <td data-title="Tema" style={{fontSize:'10px',cursor:'pointer'}} onClick={this.handleViewObservation.bind(this,listado.solucion)}><div style={{fontSize:'10px'}} title={listado.solucion}>{listado.tema}&nbsp;</div></td>
                                            <td title="Publicacion Accesible a todos los usuarios" data-title="" style={{cursor:'pointer'}} onClick={this.handleNoPrivPublicacion.bind(this,listado.id,'no_priv')}><MaterialIcon id="iconColumna" size={20} icon="lock_open" /></td>
                                            <td title="Publicacion Privada" data-title="" style={{cursor:'pointer'}} onClick={this.handlePrivPublicacion.bind(this,listado.id,'priv')}><MaterialIcon id="iconColumna" size={20} icon="lock" /></td>
                                            <td title="Eliminar" data-title="" style={{cursor:'pointer'}} onClick={this.handleDeletePublicacion.bind(this,listado.id,'delete')}><MaterialIcon id="iconColumna" size={20} icon="clear" /></td>                                            
                                       </tr>
                            })
                        }
                        </tbody>                                      
                    </Table>
                </div>  				
			  );
  	}
}

export default WindowConocimientoPorPublicar