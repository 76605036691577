/**
* CLASS WindowSeleccionFuncionario
*
* Contiene el contenedor para seleccionar funcionario en la dashboard
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import globalState from '../configuration/GlobalState';

class WindowSeleccionFuncionario extends Component {
    handleSelectUser(data){        
        globalState.dispatch({//cargamos los datos de los permisos
            type   : this.props.params.idField,
            params : {  id_usuario:data.id,nombre_usuario:data.usuario,rol_usuario:data.id_rol  }
        });
        globalState.dispatch({
            type   : "windowSeleccionFuncionario",
            params : {
                          visible : false,                          
                     }
        });
    } 
  	render() {     
        let sqlParams = {
                            sqlCols : [
                                "id",
                                "CONCAT(primer_nombre,' ',primer_apellido) AS usuario", 
                                "documento",
                                "id_rol"                                 
                            ], 
                            fieldSearch : [
                                "CONCAT(primer_nombre,' ',primer_apellido)",  
                                "documento"                                 
                            ],
                            sqlWhere : [
                                this.props.params.where,
                            ],                                                                                                           
                            filtroFechas : "false",                                                 
                        };  
                     
        return (//carga el componente que contiene la grilla de datos 
            <div style={{height: 'calc(100% - 30px)'}}>                
                <div style={{position:"relative",height:"100%",overflow:"auto"}}>            
                    <DataGrid titulo='' 
                              funcionClick={this.props.funcionClick} 
                              parametro={this.props.parametro}
                              colsData={[
                                            {
                                                type  : 'bd',
                                                label : 'Documento',
                                                field : 'documento'
                                            },                                             
                                            {
                                                type  : 'bd',
                                                label : 'Nombre',
                                                field : 'usuario'
                                            },
                                        ]} 
                              sqlParams = { sqlParams }
                              automatica="false"
                              funcionEdit={this.handleSelectUser.bind(this)}                              
                              botonNuevo="false"                                                 
                              apiField={'users'}
                              mainContainer='WindowSeleccionFuncionario'/> 
                </div>
            </div>             
        )
    } 
}

export default WindowSeleccionFuncionario