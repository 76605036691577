/**
* CLASS Calendario
*
* Contiene la pantalla del calendario de eventos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/


import React from 'react';
import FullCalendar from '@fullcalendar/react';
import globalState from '../configuration/GlobalState';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {validarPermiso} from '../configuration/GlobalFunctions';
import {cargarFilas} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import './calendario.css';

export default class DemoApp extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            listadoEventos : [],
            database : globalState.getState().companyData[0].bd
        }
    }
    componentWillMount(){
        this.cargarDatos();        
    } 
    async cargarDatos(){ 
        try{  
            let arrayEventos = []; 
            let idUser = globalState.getState().userData[0].id;   
            //recordatorios de cumpleaños 
            let sqlParams = {
                                sqlCols : [
                                    "CONCAT(YEAR(NOW()),SUBSTRING(fecha_nacimiento,5,10)) AS fecha_nacimiento",
                                    'nombre',                                                                                                                                   
                                ],                            
                                sqlWhere : [
                                    " AND id_rol > 1 AND fecha_nacimiento IS NOT NULL"//no cuenta superusuarios
                                ]                                                                                                                                                         
                            } 
            let res = await cargarFilas('users','',1000,0,'','',sqlParams,'rows',this.state.database)
            let response = res.data; 
            for(let i in response){
                arrayEventos.push({
                    title : 'Cumpleaños '+response[i].nombre,
                    date : response[i].fecha_nacimiento,
                    allDay : true,
                    color: 'steelblue'
                }); 
            } 
            //vencimientos de incidencias y problemas
            let andIncidencia =  " AND id_tecnico_incidencia="+idUser;
            let andProblema =  " AND id_tecnico_problema="+idUser;
            let andServicio =  " AND id_tecnico_servicio="+idUser;
            if(validarPermiso(49)){
                let idArea = globalState.getState().userData[0].id_area;
                let andArea = "";
                if(idArea > 0){
                    andArea = " AND id_area="+idArea;
                }
                andIncidencia = andArea;
                andProblema = andArea;
                andServicio = andArea;
            }
            sqlParams = {
                                sqlCols : [
                                    "id",
                                    "problema",
                                    "IF(problema='false',fecha_vencimiento_incidencia,fecha_vencimiento_problema) AS fecha_vencimiento",                                                                                                                                   
                                ],                             
                                sqlWhere : [
                                    " AND activo = 1 AND estado < 3 AND ((problema = 'false' "+andIncidencia+") OR (problema = 'true' "+andProblema+"))"//no cuenta superusuarios
                                ]                                                                                                                                                         
                            } 
            res = await cargarFilas('incidencias','',1000,0,'','',sqlParams,'rows',this.state.database)
            response = res.data; 
            for(let i in response){
                let titulo = 'Incidencia';
                if(response[i].problema === 'true'){
                    titulo = 'Problema';
                }
                arrayEventos.push({
                    title : 'Vencimiento '+titulo+' #'+response[i].id,
                    date : response[i].fecha_vencimiento,
                    allDay : false,
                    color: 'red'
                }); 
            }
            //vencimientos de servicios
            sqlParams = {
                                sqlCols : [
                                    "id",
                                    "fecha_vencimiento_servicio",                                                                                                                                   
                                ],                             
                                sqlWhere : [
                                    " AND activo = 1 AND estado < 3 "+andServicio,//no cuenta superusuarios
                                ]                                                                                                                                                         
                            } 
            res = await cargarFilas('servicios','',1000,0,'','',sqlParams,'rows',this.state.database)
            response = res.data; 
            for(let i in response){
                arrayEventos.push({
                    title : 'Vencimiento Servicio #'+response[i].id,
                    date : response[i].fecha_vencimiento_servicio,
                    allDay : false,
                    color: 'red'
                }); 
            } 
            //vencimientos de contratos
            sqlParams = {
                                sqlCols : [
                                    "T1.id",
                                    "T1.tipo",
                                    "P.razon_social",
                                    "T1.fecha_vencimiento",                                                                                                                                   
                                ],                            
                                sqlJoin : [
                                    'INNER JOIN terceros AS P ON (P.activo = 1 AND P.id = T1.id_tercero)'
                                ], 
                                sqlWhere : [
                                    " AND T1.activo = 1 AND T1.estado = 1 AND (T1.id_responsable_ejecucion="+idUser+" OR T1.id_responsable_pago="+idUser+")",//no cuenta superusuarios
                                ]                                                                                                                                                         
                            } 
            res = await cargarFilas('terceros_contratos','',1000,0,'','',sqlParams,'rows',this.state.database)
            response = res.data; 
            for(let i in response){
                arrayEventos.push({
                    title : 'Vencimiento Contrato #'+response[i].id+' '+response[i].tipo+' '+response[i].razon_social,
                    date : response[i].fecha_vencimiento,
                    allDay : true,
                    color: 'red'
                }); 
            } 
            //capacitaciones agendadas
            sqlParams = {
                                sqlCols : [
                                    "CONCAT(T1.fecha_inicio,' ',T1.hora_inicio) AS fecha_inicio",                                    
                                    "T1.nombre",
                                    "T1.lugar",                                                                                                                                   
                                ],                            
                                sqlJoin : [
                                    "INNER JOIN capacitaciones_usuarios AS CP ON (CP.id_capacitacion = T1.id AND CP.id_usuario = "+idUser+")"
                                ],                                                                                                                                                   
                            } 
            res = await cargarFilas('capacitaciones','',1000,0,'','',sqlParams,'rows',this.state.database)
            response = res.data; 
            for(let i in response){
                arrayEventos.push({
                    title : 'Capacitacion '+response[i].nombre+' Lugar: '+response[i].lugar,
                    date : response[i].fecha_inicio,
                    allDay : false,
                    color: 'green',                    
                }); 
            }        
            this.setState({listadoEventos : arrayEventos});
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message);
        }
    }
    reloadCalendario(){
        this.cargarDatos();
    }   
    render() {        
        let w = window.innerWidth;
        let initialView = '';
        let headerToolbar = {
            left: 'prev,next today',
            center: 'title',
            right: 'refresh dayGridMonth,dayGridWeek,dayGridDay',
        };
        let dayEvents = 3;
        if(w <= 570){
            initialView = 'dayGridDay';
            headerToolbar = {
                right: 'refresh today prev,next',
                //right : 'refresh'
            };
            dayEvents = '';
        }
        else{
            initialView = 'dayGridMonth';
        }
        return (
          <FullCalendar            
            dayMaxEvents  = {dayEvents}
            headerToolbar={headerToolbar}
            plugins={[ dayGridPlugin, interactionPlugin ]}
            initialView={initialView}            
            events={this.state.listadoEventos}
            customButtons = {{
                refresh:{
                    text: 'reload',
                    click: function() {
                        this.reloadCalendario();
                    }.bind(this)
                }
            }}
            eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',                
                meridiem: 'short'
            }}
          />
        )
    }
}