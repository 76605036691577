/**
* ApiCalls
*
* Contiene todas las llamadas a la API
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import axios from 'axios';
import configJson from '../configuration/configuration.json';
import globalState from '../configuration/GlobalState';

const path = window.location.protocol+"//"+window.location.hostname+configJson.apiRoute;

export function login(username,password,bd){
	//consulta si el usuario existe con los datos ingresados
    return axios.get(path+'login/'+username+'/'+password+'/'+bd, {withCredentials: true});    
}

export function checkUsername(username,bd){
    //consulta si la empresa existe
    return axios.get(path+'checkUsername/'+username+'/'+bd, {withCredentials: true});
}

export function checkSession(){
	//consulta si la sesion esta activa
	return axios.get(path+'checkSession', {withCredentials: true});
}

export function logout(){
	//cierra la sesion
	return axios.get(path+'logout', {withCredentials: true});
}

export function cargarFilas(apiField,searchWord,showRecords,offsetRecord,date1,date2,sqlParams,modo,bd,apiRoute=path){	
	//trae las filas filas de la grilla   
	return axios.get(apiRoute+'dataGrid', {
				withCredentials: true, 
				params: { 
					searchWord   : searchWord,
					showRecords  : showRecords,
					offsetRecord : offsetRecord,					
					date1        : date1,
					date2 		 : date2,
					sqlParams    : sqlParams,
					tabla        : apiField,
					mode         : modo,
                    bd : bd					
				} 
			});
}

export function eliminarFilas(apiField,id,dataLog,actionDelete,whereDelete,bd,apiRoute=path){
	//elimina una fila de la grilla
	return axios.delete(path+'dataGrid',{            
        	    data: {
        	    	id    : id,
        	    	tabla : apiField,
                    dataLog : dataLog,
                    actionDelete : actionDelete,
                    whereDelete : whereDelete,
                    idUser  : globalState.getState().userData[0].id,
                    bd : bd
        	    },
        	    withCredentials: true
        	});
}

export function insertarActualizarFila(method,apiField,arrayData,dataLog,whereUpdate=null,bd,apiRoute=path){
	//inserta actualiza una fila en la grilla
    let idUser = 0;
    if(globalState.getState().userData !== undefined){
        idUser = globalState.getState().userData[0].id;
    }
	return axios({
        	    method: method,
        	    url: path+'dataGrid',
        	    data: {
					arrayData : arrayData,
					tabla 	  : apiField,
                    dataLog   : dataLog,
                    whereUpdate : whereUpdate,
                    idUser    : idUser,
                    bd : bd
        	    },
        	    withCredentials: true
        	});	
}

export function sendEmailPassword(email,opcion,bd){	
	//envia correo para cambiar password
	return axios({
        	    method: 'post',
        	    url: path+'emailPassword',
        	    data: { 
                    'bd' : bd,
                    'email' : email,
                    'opcion': opcion 
                },
        	    withCredentials: true
        	});	
}

export function updatePassword(email,password,passwordActual,opcion,bd){	
	//actualiza el password
	return axios({
        	    method: 'post',
        	    url: path+'updatePassword',
        	    data: { 
                        'email': email,
                        'password': password,
                         passwordActual : passwordActual,
                         opcion : opcion,
                         bd : bd
                      },
        	    withCredentials: true
        	});	
}

export function uploaderFile(file,table,field,id,folder,bd){	
	var formData = new FormData();
	formData.append("file", file);
	formData.append("table", table);
	formData.append("field", field);
	formData.append("id", id);
	formData.append("folder", folder);
    formData.append("bd", bd);
	//enviar el archivo
	return axios.post(path+'uploaderFile',
				formData,
				{
	            	withCredentials: true,	            
	           		headers: {
            			'content-type': 'multipart/form-data'
        			} 
	        	}
	        );
}

export function guardaPermisos(idRol,arrayPermisos,bd){	
	//actualiza los permisos
	return axios({
        	    method: 'post',
        	    url: path+'guardaPermisos',
        	    data: { 'idRol': idRol,'arrayPermisos': arrayPermisos,'bd':bd },
        	    withCredentials: true
        	});	
}

export function validarPermisoRol(idRol,idPermiso){
	//consulta el numero de filas de la grilla
    let sqlParams = {                        
                        sqlCols : [ "COUNT(id) AS total" ],
                        sqlWhere : [ " AND id_rol = '"+idRol+"' AND id_permiso = "+idPermiso ],                                
                    }

    let database = globalState.getState().companyData[0].bd;
           
	return  cargarFilas('roles_permisos','',1,0,'','',sqlParams,'rows',database)    
            .then(res => {
                if(res.data.msg === 'error'){
                    return res;
                }
                else if(res.data[0].total > 0 || idRol === 1){
                    res.data.msg = true;
                    return res;
                }                
                else{
                    res.data.msg = false;
                    return res;                    
                }
            })
            .catch(err => {            
                console.log('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
            });
}

export function checkSMTP(email,bd){
	//envia un correo electronico para verificar la conexion SMTP
	return axios({
        	    method: 'post',
        	    url: path+'checkSMTP',
        	    data: { 'email': email,'bd': bd },
        	    withCredentials: true
        	}); 
}

export function recategorizacionIncidencia(observacion,idArea,idCategoria,idSubcategoria,idRow,idUser,bd){
    return axios({
                method: 'post',
                url: path+'recategorizacionIncidencia',
                data: { 
                    'idRow': idRow,
                    'observacion': observacion,
                    'id_area' : idArea, 
                    'id_categoria' : idCategoria,
                    'id_subcategoria' : idSubcategoria,                    
                    'idUser':idUser,
                    'bd':bd 
                },
                withCredentials: true
            }); 
}

export function procesoGestion(observacion,estadoActual,estado,name_estado,idRow,idUser,idArea,idCategoria,idSubcategoria,idAsignado,nomAsignado,gestionCambio,tabla,opcion,causaProblema='',bd){
    return axios({
                method: 'post',
                url: path+'procesoGestion',
                data: { 
                    'idRow': idRow,
                    'observacion': observacion, 
                    'estado' : estado,
                    'estadoActual' : estadoActual,
                    'name_estado' : name_estado,
                    'idUser':idUser,
                    'tabla':tabla,
                    'id_area' : idArea, 
                    'id_categoria' : idCategoria,
                    'id_subcategoria' : idSubcategoria, 
                    'idAsignado':idAsignado,
                    'nomAsignado': nomAsignado,
                    'opcion':opcion,
                    'causaProblema':causaProblema,
                    'gestionCambio':gestionCambio,
                    'bd':bd 
                },
                withCredentials: true
            }); 
}

export function asignarSolicitud(idRow,tipo,bd){
    return axios({
                method: 'post',
                url: path+'asignarSolicitud',
                data: { 
                    'idRow': idRow,
                    'tipo' : tipo, 
                    'bd' : bd                   
                },
                withCredentials: true
            }); 
}

export function deleteFile(file,table,id,bd){   
    //eliminar el archivo
    return axios({
                method: 'post',
                url: path+'deleteFile',
                data: { 
                    'file'  : file,
                    'table' : table,
                    'id'    : id,
                    'bd'    : bd                            
                },
                withCredentials: true
            });
}

export function cargarEncuesta(idSolicitud,tabla,bd){   
    //cargar la encuesta
    return axios.get(path+'cargarEncuesta', {
                withCredentials: true, 
                params: {  
                    idSolicitud : idSolicitud,  
                    tabla : tabla,
                    bd : bd                      
                } 
            }); 
}

export function guardarEncuesta(jsonRespuestas,idSolicitud,tabla,bd){   
    //almacenar la encuesta
    return axios({
                method: 'post',
                url: path+'guardarEncuesta',
                data: { 
                    'jsonRespuestas' : jsonRespuestas,
                    'idSolicitud'    : idSolicitud,
                    'tabla'    : tabla,
                    'bd' : bd                            
                },
                withCredentials: true
            });    
}

export function guardarReputacionConocimiento(puntaje,idRow,idUsuario,bd){
    return axios({
                method: 'post',
                url: path+'guardarReputacionConocimiento',
                data: { 
                    puntaje   : puntaje,
                    idRow     : idRow,
                    idUsuario : idUsuario,
                    bd : bd                           
                },
                withCredentials: true
            }); 
}

export function obtenerArchivoServidor(url){
    return axios.get(url,{ 
                responseType: 'arraybuffer',
                withCredentials: true, 
           });        
}

export function visualizarArchivoServidor(url){
    return axios.get(url,{ 
                responseType: 'blob',
                withCredentials: true, 
           });
}

export function enviarMailActivacion(id,bd){
    return axios({
                method: 'post',
                url: path+'enviarMailActivacion',
                data: { 
                    idUser : id,
                    bd : bd                                               
                },
                withCredentials: true
            });     
}

export function acumularIntentosLogin(user,bd){
    return axios({
                method: 'post',
                url: path+'acumularIntentosLogin',
                data: { 
                    user : user,  
                    bd : bd                                                                
                },
                withCredentials: true
            });     
}

export function rechazarSolucion(value,idRow,tabla,usuario,bd){
    return axios({
                method: 'post',
                url: path+'rechazarSolucion',
                data: { 
                    observacion  : value,
                    idRow  : idRow,
                    tabla  : tabla,
                    idUser : usuario,
                    bd : bd
                },
                withCredentials: true
            });
}

export function guardaModulos(idRow,arrayModulos,bd){    
    //actualiza los permisos
    return axios({
                method: 'post',
                url: configJson.cloudRoute+'guardaModulos',
                data: { 'idRow': idRow,'arrayModulos': arrayModulos,'bd':bd },
                withCredentials: true
            }); 
}

export function guardarEncuestaTercero(jsonRespuestas,idTercero,idUser,opcion,bd){   
    //almacenar la encuesta
    return axios({
                method: 'post',
                url: path+'guardarEncuestaTercero',
                data: { 
                    'jsonRespuestas' : jsonRespuestas,
                    'idTercero'    : idTercero,
                    'idUser' : idUser,
                    'opcion' : opcion,
                    'bd' : bd                                               
                },
                withCredentials: true
            });    
}

export function sincronizarEmpresa(documento,data){    
    //sincronizar informacion de la nube con entorno local
    return axios({
                    method: 'post',
                    url: path+'sincronizarEmpresa',
                    data: { 
                        'documento' : documento, 
                        'data' : data                   
                    },
                    withCredentials: true
                }); 
}

export function crearDirectorios(id){    
    //creacion de los directorios donde estaran alojados los documentos
    return axios({
                    method: 'post',
                    url: path+'crearDirectorios',
                    data: { 
                        'idEmpresa' : id,                                           
                    },
                    withCredentials: true
                }); 
}

export function guardarCamposFicha(arrayCampos,id,bd){    
    //creacion de los directorios donde estaran alojados los documentos
    return axios({
                    method: 'post',
                    url: path+'guardarCamposFicha',
                    data: { 
                        'arrayCampos' : arrayCampos,
                        'idActivo'    : id,
                        'bd' : bd                                           
                    },
                    withCredentials: true
                }); 
}

export function generarCodigoActivo(idActivo,bd){
    //generacion del codigo de los activos
    return axios({
                    method: 'post',
                    url: path+'generarCodigoActivo',
                    data: { 
                        'idActivo' : idActivo, 
                        'bd' : bd                                                              
                    },
                    withCredentials: true
                }); 
}

export function obtenerConsumoAlmacenamiento(bd){
    //obtencion del espacio libre
    return axios.get(path+'obtenerConsumoAlmacenamiento', {
                withCredentials: true, 
                params: {  
                    bd : bd,             
                } 
            }); 
}

export function linkEncuestaCliente(idCliente,bd){
    //generacion del codigo de los activos
    return axios({
                    method: 'post',
                    url: path+'linkEncuestaCliente',
                    data: { 
                        'idCliente' : idCliente, 
                        'bd' : bd                                                              
                    },
                    withCredentials: true
                }); 
}

export function notificarAsistentesCapacitacion(idRow,bd){
    //generacion del codigo de los activos
    return axios({
                    method: 'post',
                    url: path+'notificarAsistentesCapacitacion',
                    data: { 
                        'idRow' : idRow, 
                        'bd' : bd                                                              
                    },
                    withCredentials: true
                });
}