/**
* CLASS ImprimirFichaTecnica
*
* Contiene el contenedor de la visualizacion para la ficha tecnica
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas} from '../api_calls/ApiCalls';
import Table from 'react-bootstrap/Table';
import {divMouseOver,divMouseOut} from '../configuration/GlobalFunctions';
import ReactToPdf from 'react-to-pdf';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import globalState from '../configuration/GlobalState';
import configJson from '../configuration/configuration.json';
import MaterialIcon from 'material-icons-react';
import alertify from 'alertifyjs';

class ImprimirFichaTecnica extends Component {
    constructor(props){
        super(props);
        this.state = {
            camposFicha  : [], 
            codigoActivo : '',           
            nombreActivo : '',
            marcaActivo  : '',
            precioCompra : '',
            fechaCompra  : '',
            database : globalState.getState().companyData[0].bd
        };
    }
    componentWillMount(){
        let sqlParams = {  
                            sqlCols : [
                                "*",
                                "DATE_FORMAT(fecha_compra,'%Y-%m-%d') AS fecha_compra",
                            ],                                                                        
                            sqlWhere : [ " AND id="+this.props.parametro.idActivo ]
                        };
        cargarFilas('activos','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{                
                this.setState({
                                  codigoActivo : response[0].codigo, 
                                  nombreActivo : response[0].nombre,
                                  marcaActivo  : response[0].marca,
                                  precioCompra : response[0].precio_compra,
                                  fechaCompra  : response[0].fecha_compra,
                              });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });


        sqlParams = {
                        sqlCols : [
                            "T1.id",
                            "T1.nombre",
                            "T1.tipo", 
                            "IF(T1.tipo='select',FV.nombre,F.valor) AS valor",                               
                        ],
                        sqlJoin : [
                            "LEFT JOIN activos_ficha AS F ON (F.id_campo = T1.id AND F.id_activo="+this.props.parametro.idActivo+")",
                            "LEFT JOIN activos_tipos_campos_ficha_valores AS FV ON(FV.id = F.valor)",
                            "INNER JOIN activos AS A ON(A.id_tipo = T1.id_tipo AND A.id="+this.props.parametro.idActivo+")"
                        ],                                                                         
                        sqlWhere : [ ]
                    };
        cargarFilas('activos_tipos_campos_ficha','',1000,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{                
                this.setState({ camposFicha: response });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    retrocederPanel(){
        this.props.funcionClick('Activos');
    }    
  	render() { 
        const divPDF = React.createRef();     
        var field = null;
        var field1 = null;
        var count = 1;        
        return (//carga el componente que contiene la grilla de datos
            <div>
                <div style={{backgroundColor : configJson.windowColor }} className="tbarContainer">                    
                    <div id="reportButton0" className="tbarButton" style={{float:'left',width:'65px'}} onClick={this.retrocederPanel.bind(this)} onMouseOut={divMouseOut.bind(this,"reportButton0",configJson.windowColor)} onMouseOver={divMouseOver.bind(this,"reportButton0",configJson.windowColor)}>                                  
                        <div style={{textAlign:'center'}}>
                            <MaterialIcon size={24} icon="arrow_back" invert/>
                        </div>
                        <button style={{color:'white'}} className="save">Atras</button>
                    </div>
                    <div id="reportButtonXLS" className="tbarButton" style={{float:'left',width:'65px'}} onMouseOut={divMouseOut.bind(this,"reportButtonXLS",configJson.windowColor)} onMouseOver={divMouseOver.bind(this,"reportButtonXLS",configJson.windowColor)}>                                 
                        <div style={{textAlign:'center'}}>
                            <MaterialIcon size={24} icon="library_books" invert/>
                        </div>
                        <ReactHTMLTableToExcel
                            id="fichaTableXlsButton"
                            className="download-table-xls-button"
                            table="ficha-to-xls"
                            filename={"FichaTecnica_"+this.state.codigoActivo}
                            sheet="tablexls"
                            buttonText="Generar Excel"
                        />
                    </div>
                    <div id="reportButtonPDF" className="tbarButton" style={{float:'left',width:'65px'}} onMouseOut={divMouseOut.bind(this,"reportButtonPDF",configJson.windowColor)} onMouseOver={divMouseOver.bind(this,"reportButtonPDF",configJson.windowColor)}>                                 
                        <div style={{textAlign:'center'}}>
                            <MaterialIcon size={24} icon="picture_as_pdf" invert/>
                        </div>
                        <ReactToPdf targetRef={divPDF} filename={"FichaTecnica_"+this.state.codigoActivo+".pdf"}>
                            {
                                ({toPdf}) => (
                                    <button onClick={toPdf} className="save" os="windows">Generar PDF</button>
                                )
                            }
                        </ReactToPdf>
                    </div>
                </div>             
                <div style={{position:"relative",top:'70px'}} ref = {divPDF}>
                    <div className="content">
                        <div style={{paddingBottom:'15px',paddingLeft:'15px'}}>
                            <div className="table-responsive mt-4">
                                <div className="titulo">Ficha Tecnica {this.state.nombreActivo}</div>
                            </div>                            
                        </div>
                        <div className="table-responsive mb-3">
                            <Table responsive id="ficha-to-xls">
                                <tbody>
                                    <tr>
                                        <td>
                                            <Table responsive style={{marginBottom: '0px'}}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{fontSize:'10px',fontWeight:'bold'}}>DATOS GENERALES</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Table responsive>
                                                <tbody>
                                                    <tr style={{width:'99%'}}>
                                                        <td style={{fontSize:'10px',fontWeight:'bold'}}>
                                                            NOMBRE
                                                        </td>
                                                        <td style={{fontSize:'10px'}}>
                                                            {this.state.nombreActivo}
                                                        </td>
                                                        <td style={{fontSize:'10px',fontWeight:'bold'}}>
                                                            MARCA
                                                        </td>
                                                        <td style={{fontSize:'10px'}}>
                                                            {this.state.marcaActivo}
                                                        </td>
                                                    </tr>
                                                    <tr style={{width:'99%'}}>
                                                        <td style={{fontSize:'10px',fontWeight:'bold'}}>
                                                            PRECIO COMPRA
                                                        </td>
                                                        <td style={{fontSize:'10px'}}>
                                                            {this.state.precioCompra}
                                                        </td>
                                                        <td style={{fontSize:'10px',fontWeight:'bold'}}>
                                                            FECHA COMPRA
                                                        </td>
                                                        <td style={{fontSize:'10px'}}>
                                                            {this.state.fechaCompra}
                                                        </td>
                                                    </tr>
                                                </tbody>    
                                            </Table> 
                                            <Table responsive style={{marginBottom: '0px'}}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{fontSize:'10px',fontWeight:'bold'}}>DATOS ESPECIFICOS</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                            <Table responsive>
                                                <tbody>
                                                {
                                                    this.state.camposFicha.map((formFields,i) => {
                                                        field = <>
                                                                    {field}
                                                                    <td style={{fontSize:'10px',fontWeight:'bold'}}>
                                                                        {formFields.nombre}                                                  
                                                                    </td>
                                                                    <td style={{fontSize:'10px'}}>
                                                                        {formFields.valor}
                                                                    </td>
                                                                </>
                                                        //organizar las columnas en filas
                                                        if(count%2===0){
                                                            count++;
                                                            field1 = field;
                                                            field = null;                                       
                                                            return <tr style={{width:'99%'}} key= {i}>
                                                                        {field1}
                                                                    </tr>;
                                                        }                                    
                                                        else{
                                                            count++;                                                                                
                                                        } 
                                                        return null;
                                                    })
                                                }                        
                                                {
                                                    (count%2===0)?<tr style={{width:'99%'}}>{field}</tr>:<></>                                 
                                                }
                                                </tbody>                    
                                            </Table>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>  
            </div>          
        )
    } 
}

export default ImprimirFichaTecnica