/**
* CLASS DashboardOption
*
* Contiene el contenedor de cada uno de los indicadores
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Chart from 'react-google-charts';
import MaterialIcon from 'material-icons-react';
import './dashboard.css';

class DashboardOption extends Component {	
  	render() { 
  		var id = "Indicador";
  		if(this.props.tipo === 'grafico_amplio'){
  			id = "IndicadorAmplio";
  		}  		
        return (
			<div id={id}>
				{
					this.props.tipo === 'cifra' ?
						<div style={{width:'100%'}}>
							<div style={{float:'left',width:'35px',paddingTop:'18px'}} className="LiIcon"><MaterialIcon size={35} icon={this.props.icono} color={this.props.colorIcono}/></div>
					 		<div style={{float:'left',width:'calc(100% - 35px)'}}>
						 		<div className="Cifra">{this.props.valor}</div>					 		
						 		<div className="titulo1" style={{borderRight:'0px'}}>{this.props.titulo}</div>
						 	</div>
				 		</div>
				 	: this.props.tipo === 'cifra_html' ?
						<div style={{width:'100%'}}>
							<div style={{float:'left',width:'35px',paddingTop:'18px'}} className="LiIcon"><MaterialIcon size={35} icon={this.props.icono} color={this.props.colorIcono}/></div>
					 		<div style={{float:'left',width:'calc(100% - 35px)'}}>
						 		<div className="Cifra" dangerouslySetInnerHTML={{__html: this.props.valor}} style={{paddingBottom:'10px'}}></div>					 		
						 		<div className="titulo1" style={{borderRight:'0px',marginBottom:'10px'}}>{this.props.titulo}</div>
						 	</div>
				 		</div>
				 	: <div className="grafico">				 					 		
				 		<Chart
						  	width={this.props.chartProps[0].width}
						  	height={this.props.chartProps[0].height}
						  	chartType={this.props.chartProps[0].chartType}
						  	loader={this.props.chartProps[0].loader}
						  	data={this.props.chartProps[0].data}
						  	options={this.props.chartProps[0].options}
						  	rootProps={this.props.chartProps[0].rootProps}
						/>
			 		  </div>
				}
				
								
			</div>
		);
    } 
}

export default DashboardOption