/**
/**
* CLASS WindowNotificacionesContratos
*
* Contiene el contenedor principal de las notificaciones del contrato
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import FormDataGrid from '../data_grid/FormDataGrid';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';

class WindowNotificacionesContratos extends Component { 
    constructor(props){
        super(props);
        this.state = {
            idRow : 0,
            renovacion : 'false',
            database : globalState.getState().companyData[0].bd  
        };
    } 
    componentWillMount(){
        //CONOCER SI IMPLICA RENOVACION AUTOMATICA
        let sqlParams = {
                            sqlCols : [
                                "renovacion_automatica"
                            ], 
                            sqlWhere : [
                                " AND id="+this.props.params.idRow
                            ],                                                     
                            mode : 'rows'                                                 
                        }

        cargarFilas('terceros_contratos','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{                
                this.setState({ renovacion : response[0].renovacion_automatica });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
        //CARGAR LOS DATOS DEL FORMULARIO
        sqlParams = { 
                        sqlWhere : [
                            " AND id_contrato="+this.props.params.idRow
                        ],                                                     
                        mode : 'rows'                                                 
                    }

        cargarFilas('terceros_contratos_notificaciones','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{
                let idRow = 0;               
                if(response.length > 0){
                    idRow = response[0];                    
                }
                this.setState({ idRow: idRow });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        }); 
    }
    cerrarVentana(){
        globalState.dispatch({
                type   : "windowNotificacionesContratos",
                params : {
                              visible : false,
                         }
        });
    } 
    render() { 
        let fieldRenovacion  = [null,null,null,null];
        if(this.state.renovacion === 'true'){
            fieldRenovacion[0]   =   {
                                        label : 'Renovacion Automatica(Dias)',
                                        field : '',
                                        type  : 'separator',
                                        validation : '',
                                        required : ''                                        
                                    };
            fieldRenovacion[1]   =   {
                                        label : '1era Notificacion *',
                                        field : 'primera_notificacion_renovacion',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese los dias de la 1era Notificacion de Renovacion'                                        
                                    };
            fieldRenovacion[2]   =   {
                                        label : '2da Notificacion *',
                                        field : 'segunda_notificacion_renovacion',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese los dias de la 2da Notificacion de Renovacion'                                        
                                    };
            fieldRenovacion[3]   =   {
                                        label : '3ra Notificacion *',
                                        field : 'tercera_notificacion_renovacion',
                                        type  : 'text',
                                        validation : 'entero',
                                        required : 'true',
                                        tooltip : 'Ingrese los dias de la 3era Notificacion de Renovacion'                                        
                                    };           
        } 
        //validar si es cliente o proveedor 
        let simboloObligatorio = '*';
        let obligatorio = 'true';        
        if(this.props.params.opcion === 'cliente'){
            simboloObligatorio = '';
            obligatorio = 'false';
        }       
        return (//carga el componente que contiene la grilla de datos                            
            <div style={{top:'0px',position:'relative',height: 'calc(100% - 33px)',overflowY:'auto'}}>             
                <FormDataGrid 
                    funcionClick = {this.props.funcionClick} 
                    parametro = {
                                    {
                                        idRow : this.state.idRow,
                                        mainContainer : 'WindowNotificacionesContratos',
                                        formulario : true,
                                        titulo : 'Notificaciones',
                                        apiField : 'terceros_contratos_notificaciones', 
                                        enableBtnEdit : true,
                                        functionCancel : this.cerrarVentana.bind(this),
                                        funcionAfterInsert : this.cerrarVentana.bind(this),
                                        funcionAfterUpdate : this.cerrarVentana.bind(this),                                                                        
                                        formFields :  [
                                                        {
                                                            label : 'Vencimiento de Contrato(Dias)',
                                                            field : '',
                                                            type  : 'separator',
                                                            validation : '',
                                                            required : ''                                        
                                                        },
                                                        {
                                                            label : '1era Notificacion *',
                                                            field : 'primera_notificacion_vencimiento',
                                                            type  : 'text',
                                                            validation : 'entero',
                                                            required : 'true',
                                                            tooltip : 'Ingrese los dias de la 1era Notificacion de Vencimiento'                                        
                                                        },
                                                        {
                                                            label : '2da Notificacion *',
                                                            field : 'segunda_notificacion_vencimiento',
                                                            type  : 'text',
                                                            validation : 'entero',
                                                            required : 'true',
                                                            tooltip : 'Ingrese los dias de la 2da Notificacion de Vencimiento'                                        
                                                        },
                                                        {
                                                            label : '3ra Notificacion *',
                                                            field : 'tercera_notificacion_vencimiento',
                                                            type  : 'text',
                                                            validation : 'entero',
                                                            required : 'true',
                                                            tooltip : 'Ingrese los dias de la 3era Notificacion de Vencimiento'                                        
                                                        }, 
                                                        fieldRenovacion[0],
                                                        fieldRenovacion[1],
                                                        fieldRenovacion[2],
                                                        fieldRenovacion[3],
                                                        {
                                                            label : 'Realizacion de Pagos(Dias)',
                                                            field : '',
                                                            type  : 'separator',
                                                            validation : '',
                                                            required : ''                                        
                                                        },
                                                        {
                                                            label : '1era Notificacion '+simboloObligatorio,
                                                            field : 'primera_notificacion_pagos',
                                                            type  : 'text',
                                                            validation : 'entero',
                                                            required : obligatorio,
                                                            tooltip : 'Ingrese los dias de la 1era Notificacion de Pagos'                                        
                                                        },
                                                        {
                                                            label : '2da Notificacion '+simboloObligatorio,
                                                            field : 'segunda_notificacion_pagos',
                                                            type  : 'text',
                                                            validation : 'entero',
                                                            required : obligatorio,
                                                            tooltip : 'Ingrese los dias de la 2da Notificacion de Pagos'                                        
                                                        },
                                                        {
                                                            label : '3ra Notificacion '+simboloObligatorio,
                                                            field : 'tercera_notificacion_pagos',
                                                            type  : 'text',
                                                            validation : 'entero',
                                                            required : obligatorio,
                                                            tooltip : 'Ingrese los dias de la 3era Notificacion de Pagos'                                        
                                                        },                                                                                                                
                                                        {
                                                            label : '',
                                                            field : 'id_contrato',
                                                            type  : 'hidden',
                                                            validation : '',
                                                            value : this.props.params.idRow,
                                                            required : 'true'                                        
                                                        },
                                                       ] 
                                    }
                                } 
                            
                /> 
            </div>                         
        )
    } 
}

export default WindowNotificacionesContratos