/**
* CLASS RechazoSolucion
*
* Contiene el componente que rechaza la solucion dada por el tecnico
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import configJson from '../configuration/configuration.json';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-modal';
import loadingImg from '../../images/loading.gif?v1.0';
import Button from 'react-bootstrap/Button';
import {cargarFilas,rechazarSolucion} from '../api_calls/ApiCalls';
import {divMouseOver,divMouseOut,modalLoadingRstPwd,vacio} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';
import LogoCorporativo from '../LogoCorporativo/LogoCorporativo';

var base64 = require('base-64');

const stylesLoading = {
    content : {
        top         : '50%',
        left        : '50%',
        width       : '202px',
        height      : '202px',
        right       : 'auto',
        bottom      : 'auto',
        marginRight : '-50%',
        padding     : '0px',
        transform   : 'translate(-50%, -50%)' 
    }
};

class RechazoSolucion extends Component {
	constructor(props){
		super(props);
		this.state = {
			estadoSolicitud  : '',
			updateEncuesta   : 0,
			showLoading : false,			
			campoComentarios : '',            
            idSolicitud : base64.decode(this.props.match.params.idSolicitud),
            tabla : base64.decode(this.props.match.params.tabla),
            database : base64.decode(this.props.match.params.bd),
            idUsuario : base64.decode(this.props.match.params.idUsuario) 
		}		
	}
	verificarEstado(){
        let sqlParams = {
                            sqlCols : [
                                'T1.estado', 
                                'SE.nombre AS nombre_estado',                                                                                                                                        
                            ], 
                            sqlJoin :[
                                "INNER JOIN solicitudes_estado AS SE ON(SE.valor = T1.estado)",
                            ],
                            sqlWhere : [
                                " AND T1.id="+this.state.idSolicitud
                            ],                                                                                                                                                                                                              
                        } 
        cargarFilas(this.state.tabla,'',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {		             
            res = res.data; 
            if (res.msg === "error") {                
                this.setState({estadoSolicitud : 'error'});
            } 
            else{                  
                this.setState({estadoSolicitud : res[0] });
            }                           
        })
        .catch(function (error) {
             alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });	
	}
	componentWillMount(){		
		this.verificarEstado();	
	}	
    componentDidUpdate(prevProps,prevState){
        if (this.state.updateEncuesta !== prevState.updateEncuesta) {           
           this.verificarEstado();            
        }       
    }
	componentDidMount() {//cada que se monte el escritorio debe alistar la ventana del loading      
        //... 
        this.unsubscribe1 = globalState.subscribe( ()=>{ 
            if(globalState.getState().type==="modalLoadingEncuesta"){                 
                this.setState({showLoading  : globalState.getState().modalLoadingEncuesta});                                           
            }
        });          
    } 
    componentWillUnmount(){ 
        this.unsubscribe1();
    }	
	handleEnviarEncuesta(){
		//rechazar la solucion	
        if(vacio(this.state.campoComentarios) === false){
            alertify.error('Ingrese el motivo!'); 
            return;
        }	
		modalLoadingRstPwd(true,"modalLoadingEncuesta");
		rechazarSolucion(this.state.campoComentarios,this.state.idSolicitud,this.state.tabla,this.state.idUsuario,this.state.database)
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                modalLoadingRstPwd(false,"modalLoadingEncuesta");
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else if(response.msg === 'no_mail'){ 
                modalLoadingRstPwd(false,"modalLoadingEncuesta");
                alertify.alert('Error!', 'No hay destinatarios para enviar el correo!');                
            }
            else if(response.msg === 'mail_error'){ 
                modalLoadingRstPwd(false,"modalLoadingEncuesta");
                alertify.alert('Error!', 'Ha ocurrido un error enviando el correo!<br />Codigo de Error: '+response.detail);
            }
            else{               
                modalLoadingRstPwd(false,"modalLoadingEncuesta");
                alertify.alert('Aviso!', 'Se ha guardado la informacion por exito!');
            }
            this.setState({ updateEncuesta : this.state.updateEncuesta+1 });
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        }); 	
	}	
	handleComentarios(e){
        let value=e.target.value;
        value = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\n,:.@\-+¡!?¿ ]/g,''); 
		this.setState({campoComentarios : value});
	}	
	render() {
		return <div style={{background:'#efefef',width:'100%',height:'100%',overflow:'hidden'}}>
                    <div className="adM">
                    </div>
                    <LogoCorporativo database={this.state.database}/>
                    <div style={{width:'calc(100% - 60px)',maxWidth:'600px',height:'auto',padding:'20px',margin:'20px auto',backgroundColor:'#fff',borderRadius:'5px'}}>
	                    <div>
	                   		<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>Rechazo de Solucion</h1>
	                        <hr style={{margin:'0 0 20px 0'}} />        	
		                    {
		                   		(this.state.estadoSolicitud === 'error') ?
		                   			<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>Ha ocurrido un error accediendo a la base de datos!</h1> 
		                   		:(this.state.estadoSolicitud.estado !== 3) ?
		                   			<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>Estado de Solicitud: {this.state.estadoSolicitud.nombre_estado}</h1> 
		                   		: <div> 			               			  
                        			  <div style={{marginTop:'20px'}}> 
				               			  <Form>				               			       
				               			       	<Form.Row style={{width:'99%'}}>
                                                    <Form.Group as={Col} controlId="encuestaComentarios">
                                                        <Form.Label>Escriba sus Comentarios *</Form.Label>                                                        
                                                        <Form.Control name = "encuestaComentarios" as="textarea" rows="3" onChange={this.handleComentarios.bind(this)} value={this.state.campoComentarios}/>
                                                    </Form.Group>
                                                </Form.Row>
				               			  		<Form.Group controlId="formBasicResetPwd" style={{height:'50px',marginTop:'20px',marginBottom:'0px'}}>
			                                        <Button id="formGridBtnSave" style={{padding:'10px 20px',margin: '5px 0',backgroundColor:configJson.fondoBotonGrilla}} className="float-left mr-3" variant="primary" onClick={this.handleEnviarEncuesta.bind(this)} onMouseOut={divMouseOut.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)}>
		    				  	      					ENVIAR
		    				  	    			    </Button>
			    				  	    		</Form.Group>  
				               			   </Form> 
				               		  </div>
				               	 </div>
		               			  	
		                    }			                			                 
			            </div>       		                              
                        <div className="yj6qo"></div>
                        <div className="adL"></div>
                    </div>
                    <div className="adL"></div>
                    <Modal //la ventana del loading
	                   isOpen={this.state.showLoading}
	                   contentLabel="Minimal Modal Example"
	                   style={stylesLoading}
	                > 
	                    <img src={loadingImg}  alt="Loading"/>
	                </Modal>
           	    </div> 
    }
}   

export default RechazoSolucion              