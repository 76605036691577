/**
* CLASS DataReportContainer
*
* Contiene la tabla que arroja la informacion recibida de la API para generar el informe
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import ImagenUsuario from '../desktop/ImagenUsuario';
import configJson from '../configuration/configuration.json';
import globalState from '../configuration/GlobalState';
import './reports.css';

class DataReportContainer extends Component {	   
    render() {     	
    	//generador de la tabla con el reporte
    	var dataRow = this.props.dataRow; 
        var date = new Date();
        var randomDate = date.getTime(); 
        var path = window.location.protocol+"//"+window.location.hostname+configJson.filesRoute; 
        var file = globalState.getState().logoEmpresa;        
        var folder = globalState.getState().companyData[0].bd+'/ikarosof_management_acceso.companies_adjuntos/'+file+'?'+randomDate;    	
        return (        	
        	dataRow !== '' ?
        	<div ref = {this.props.divPDF}>                  			
        		<Table striped bordered hover >
            	        <thead>
                            <tr style={{fontWeight:'bold'}}>                                
                                <td>                                    
                                    <div style={{float:'left'}}>{this.props.title}</div>
                                    <div style={{width:'250px',height:'60px',float:'right'}}>
                                    {
                                        file !== undefined && file !== '' ?
                                            <ImagenUsuario src={path+folder} alt="Logo"/>
                                        : ''
                                    }                                        
                                    </div>
                                </td>
                            </tr>            	            
            	        </thead>
            	        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <Table id="table-to-xls">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    {
                                                        this.props.colsHeaders.map((colsHeaders,i) => {
                                                            if(colsHeaders !== null){
                                                                return <th key={ i }>{colsHeaders}</th>
                                                            }
                                                            else{
                                                                return null;
                                                            }
                                                        })
                                                    } 
                                                </tr>
                                            </thead>
                                            <tbody>
                                	        {
                                	        	dataRow.map((dataRow,i) => {
                                	        		return <tr key={ i }>      
                                							    <td>{i+1}</td> 
                    		    	        				    {
                    		    	            				    this.props.colsData.map((colsData,i) => {
                    		    	            				        if(colsData !== null){
                                                                            return <td key={ i }>{dataRow[colsData]}</td>
                    		    	            				        }
                                                                        else{
                                                                            return null;
                                                                        }
                                                                    })
                    		    	            				}    
                                							</tr>
                                	        	})                    	
                                	        } 
                                            </tbody>
                                        </Table>
                                    </div>
                                </td> 
                            </tr>          			
            			</tbody>  
            	</Table>             	
        	</div>
            : ''              
        );
    }
}

export default DataReportContainer