/**
* CLASS TabPanelContainer
*
* Contiene el contenedor que renderiza el contenido
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DashboardContainer from '../dashboard/DashboardContainer';
import ReportContainer from '../dashboard/ReportContainer';
import GeneralContainer from '../dashboard/GeneralContainer';
import TrendContainer from '../dashboard/TrendContainer';

class TabPanelContainer extends Component {

	render() {		
		let componentList = {
            DashboardContainer : DashboardContainer, 
            GeneralContainer : GeneralContainer,
            ReportContainer : ReportContainer,   
            TrendContainer : TrendContainer,         
        }
        let ChildComponent = componentList[this.props.componente];
        return (        
            <ChildComponent params={this.props.params}/>              
        );
	}

}

export default TabPanelContainer