/**
* CLASS Imap
*
* Contiene el contenedor principal de la configuracion IMAP
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import FormDataGrid from '../../data_grid/FormDataGrid';
import TBar from '../../tbar/TBar';
import {cargarFilas} from '../../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import globalState from '../../configuration/GlobalState';

class Imap extends Component {
    constructor(props){
        super(props);
        this.state={
            idRow : 0,
            btnCheck : 'none',
            database : globalState.getState().companyData[0].bd
        };    
        let sqlParams = {                                                    
                            mode : 'rows'                                                 
                        }

        cargarFilas('imap','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{
                let idRow = 0;
                let display = 'none';
                if(response.length > 0){
                    idRow = response[0];
                    display = 'block';
                }
                this.setState({ idRow: idRow,btnCheck: display });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });     
    }
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }
    configurarReglas(idRow){        
        globalState.dispatch({
            type   : "idImap",
            params : this.state.idRow.id
        });        
        this.props.funcionClick('ImapReglas'); 
    }
  	render() {          
        return (//carga el componente que contiene el formulario
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                              {
                                  type : 'boton',
                                  icon : 'settings',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Reglas',
                                  display : this.state.btnCheck,
                                  function : this.configurarReglas.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>             
                    <FormDataGrid 
                        funcionClick = {this.props.funcionClick} 
                        parametro = {
                                        {
                                            idRow : this.state.idRow,
                                            mainContainer : 'PanelMenu',
                                            formulario : true,
                                            titulo : 'Configuracion IMAP',
                                            apiField : 'imap', 
                                            enableBtnEdit : true,                                                                         
                                            formFields :  [{
                                                                label : 'Servidor IMAP *',
                                                                field : 'servidor',
                                                                type  : 'text',
                                                                validation : '',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el nombre del servidor SMTP'                                        
                                                            },
                                                            {
                                                                label : 'Usuario *',
                                                                field : 'correo',
                                                                type  : 'text',
                                                                validation : 'email',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el Usuario SMTP'                                        
                                                            },
                                                            {
                                                                label : 'Password *',
                                                                field : 'password',
                                                                type  : 'text',
                                                                validation : '',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el password del usuario SMTP'                                        
                                                            },
                                                            {
                                                                label : 'Puerto *',
                                                                field : 'puerto',
                                                                type  : 'text',
                                                                validation : 'entero',
                                                                required : 'true',
                                                                tooltip : 'Ingrese el puerto SMTP'                                        
                                                            },                                    
                                                            {
                                                                label : 'TLS *',
                                                                field : 'tls',
                                                                type  : 'select',
                                                                validation : '',
                                                                required : 'true',
                                                                dinamic : 'false',
                                                                valueName : 'nombre',
                                                                options :  [{
                                                                                id: 'true',
                                                                                nombre: 'Si',
                                                                            },
                                                                            {
                                                                                id: 'false',
                                                                                nombre: 'No', 
                                                                            }],
                                                                tooltip : 'Ingrese el tipo de seguridad SMTP'                                        
                                                            }] 
                                        }
                                    } 
                                
                    /> 
                </div>                
            </div>              
        )
    } 
}

export default Imap