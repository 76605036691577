/**
* CLASS SeleccionConocimiento
*
* Contiene el contenedor principal de la seleccion de conocimiento
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import {divMouseOver,divMouseOut,validarPermiso} from '../configuration/GlobalFunctions';
import { diccionario } from '../configuration/configuration.json';
import globalState from '../configuration/GlobalState';

const { LabelsSeleccionConocimiento } = diccionario;

class SeleccionConocimiento extends Component { 
    constructor(props){
        super(props); 
        this.state = {
            usuario : false
        } 
    }
    componentWillMount(){
        if(validarPermiso(28) !== true){//no esta habilitado para ingresar conocimiento
            this.setState({usuario : true},()=>{
                globalState.dispatch({
                    type   : "opcionConocimiento",
                    params : {                
                        opcion  : 'usuario',
                        permisoInsertUpdate : 19,
                        permisoDelete : 19, 
                    }
                })
                this.props.funcionClick('Conocimiento',{ opcion : 'usuario' });
            });            
        }
    }
    handleOption(opc){
        let permiso = '';
        if(opc === 'incidencia'){
            permiso = 44;
        }
        else if(opc === 'servicio'){
            permiso = 45;
        }                       
        if(validarPermiso(permiso) !== true){//no esta habilitado para ingresar conocimiento 
            globalState.dispatch({
                type   : "opcionConocimiento",
                params : {                
                    opcion  : opc,
                    permisoInsertUpdate : 19,
                    permisoDelete : 19, 
                }
            })
            this.props.funcionClick('Conocimiento',{ opcion : opc });
        } 
        else{                    
            globalState.dispatch({
                type   : "opcionConocimiento",
                params : {                
                    opcion  : opc,
                    permisoInsertUpdate : 28,
                    permisoDelete : 29, 
                }
            }); 
            this.props.funcionClick('Conocimiento',{ opcion : opc });               
        }
    } 	
  	render() {
        let idioma = globalState.getState().idioma;        
  	  	return (  
            !this.state.usuario  ?           	  		  
      	  		<div id="ContentPaneldeControl" className="EstiloIconosMenuDashboard" ostype="desktop">
                    <div className="titulo">{LabelsSeleccionConocimiento.Title[idioma]}</div>
                    <ul> 
                         <li id="option_0" onClick={this.handleOption.bind(this,'incidencia')} onMouseOut={divMouseOut.bind(this,"option_0",'#FFFFFF')} onMouseOver={divMouseOver.bind(this,"option_0",'#c6c6c6')} >
                            <div className="icono"><MaterialIcon size={50} icon="contact_support" /></div>
                            <div className="data">Incidencia</div>
                        </li>
                        <li id="option_1" onClick={this.handleOption.bind(this,'servicio')} onMouseOut={divMouseOut.bind(this,"option_1",'#FFFFFF')} onMouseOver={divMouseOver.bind(this,"option_1",'#c6c6c6')} >
                            <div className="icono"><MaterialIcon size={50} icon="build" /></div>
                            <div className="data">Servicio</div>
                        </li>
                    </ul>
                </div>  
            : ''           	
  	  	);
  	}
}

export default SeleccionConocimiento