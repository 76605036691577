/**
/**
* CLASS WindowUsuariosCapacitaciones
*
* Contiene el contenedor principal de los usuarios de capacitaciones
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import TBar from '../tbar/TBar';
import {cargarFilas,notificarAsistentesCapacitacion,insertarActualizarFila,eliminarFilas} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import {modalLoading} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';

class WindowUsuariosCapacitaciones extends Component { 
    constructor(props){
        super(props);
        this.state = {
            titulo   : '',
            botonNotificar : 'none',
            inicioCapacitacion: '',
            database : globalState.getState().companyData[0].bd
        }
    }
    async componentWillMount(){
        try{
            let sqlParams = {
                                sqlCols : [
                                    'nombre',
                                    "IF(CONCAT(fecha_final,' ',hora_final) <= NOW(),'vencida','vigente') AS vigencia",
                                    "IF(CONCAT(fecha_inicio,' ',hora_inicio) <= NOW(),'iniciado','no_iniciado') AS inicio"
                                ],                                
                                sqlWhere : [ " AND id="+this.props.params.idRow ],                   
                            }
            let res = await cargarFilas('capacitaciones','',1,0,'','',sqlParams,'rows',this.state.database);
            let response = res.data;
            if(response.length > 0){
                let vigencia = 'none';
                if(response[0].vigencia === 'vigente'){
                    vigencia = 'block';
                }
                // Con este estado validaré si ya inició la capacitación
                let iniciado = response[0].inicio;
                // Actualizar los estados
                this.setState({titulo : response[0].nombre, botonNotificar : vigencia, inicioCapacitacion : iniciado});
            }
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message); 
        } 
    }
    handleAgregarUsuarios(){        
        this.props.funcionClick('WindowAgregarUsuariosCapacitacion',{funcionClick:this.props.funcionClick});
    } 
    handleEdit(){        
        //...
    } 
    btnRetroceder(){
        globalState.dispatch({
            type   : this.props.params.idWin,
            params : {
                          visible : false,
                     }
        });
    }
    notificarAsistentesCapacitacion(){
        modalLoading(true);
        notificarAsistentesCapacitacion(this.props.params.idRow,this.state.database)
        .then(res => {
            modalLoading(false);
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
            } 
            else if(res.msg === 'mail_error'){                
                alertify.alert('Error!', 'Ha ocurrido un error enviando el correo, verifique la conexion a internet y configuracion SMTP en la aplicacion!<br /><br />Detalles del Error: '+JSON.stringify(res.detail, null, 4));
            }  
            else{
                alertify.alert('Aviso!', 'Se ha enviado un correo electronico a los funcionarios vinculados!');                               
            }
        })
        .catch( err => {  
            modalLoading(false);          
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    } 
    async colFuncion(idRow){
        try{
            modalLoading(true);
            //obtener la asistencia actual
            let sqlParams = {
                                sqlCols : [
                                    'asistencia',
                                ],
                                sqlWhere : [ " AND id="+idRow ],                   
                            }
            let res = await cargarFilas('capacitaciones_usuarios','',1,0,'','',sqlParams,'rows',this.state.database);
            let response = res.data;
            //actualizar la asistencia            
            let asistencia = response[0].asistencia;            
            if(asistencia === 'false'){
                asistencia = 'true';
            }
            else{
              asistencia = 'false';
            }
            let objectUsuario = {                
                asistencia : asistencia,
                id : idRow
            };
            insertarActualizarFila('put','capacitaciones_usuarios',objectUsuario,'false',undefined,this.state.database).then(response => {               
                response = response.data;   
                if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                    modalLoading(false);
                }
                else{
                    this.props.funcionClick('WelcomePage');
                    this.props.funcionClick('WindowUsuariosCapacitaciones');
                    modalLoading(false);               
                }     
            })
            .catch(function (error) {
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            });
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
        }
    }
    colFuncion2(idRow){
        //eliminar al usuario
        let where = [ " id = '"+idRow+"'" ];
        modalLoading(true);
        eliminarFilas('capacitaciones_usuarios',0,'false','delete',where,this.state.database)
        .then(response => {            
            response = response.data;
            if(response.msg === 'error'){
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail); 
                modalLoading(false);                    
            }
            else {
                this.props.funcionClick('WelcomePage');
                this.props.funcionClick('WindowUsuariosCapacitaciones');
                modalLoading(false); 
            }                
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    }
    render() {        
        let sqlParams = { 
                            sqlCols : [
                                "T1.id",
                                "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS nombre", 
                                "IF(T1.asistencia='true','how_to_reg','playlist_add_check') AS icono"                               
                            ],
                            fieldSearch : [
                                "CONCAT(U.primer_nombre,' ',U.primer_apellido)",                                                               
                            ], 
                            sqlJoin : [ 
                                "INNER JOIN users AS U ON (U.id = T1.id_usuario)",                                
                            ],
                            sqlWhere : [
                                " AND T1.id_capacitacion="+this.props.params.idRow,                                
                            ]                                           
                        };    

        // Habilitar columna con botón de asistencia
        let colAsistencia = '';

        if(this.state.inicioCapacitacion === 'iniciado'){
            colAsistencia = {
                                type  : 'manual',
                                label : '',
                                icon  : '',
                                field : 'icono',
                                colFuncion : this.colFuncion.bind(this)
                            };
        }

        return (//carga el componente que contiene la grilla de datos                            
            <div style={{height: 'calc(100% - 31px)'}}> 
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.btnRetroceder.bind(this)
                              },
                              {
                                  type : 'boton',
                                  icon : 'mail_outline',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Notificar',
                                  display : this.state.botonNotificar,
                                  function : this.notificarAsistentesCapacitacion.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative",height:'calc(100% - 60px)',overflow:'auto'}}>
                    <div className="titulo" style={{paddingLeft:'15px'}}>{'Asistentes '+this.state.titulo}</div>         
                    <DataGrid titulo='Usuarios' 
                              funcionClick={this.props.funcionClick} 
                              parametro={this.props.parametro}
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Usuario',
                                                field : 'nombre'
                                            },
                                            colAsistencia,
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'delete',
                                                colFuncion : this.colFuncion2.bind(this)
                                            }
                                        ]} 
                              sqlParams = { sqlParams }
                              automatica="false"
                              funcionNew={this.handleAgregarUsuarios.bind(this)}
                              botonNuevo="true"
                              funcionEdit={this.handleEdit.bind(this)}                                               
                              apiField={'capacitaciones_usuarios'}
                              mainContainer='WindowUsuariosCapacitaciones'/> 
                </div> 
            </div>                       
        )
    } 
}

export default WindowUsuariosCapacitaciones