/**
* CLASS WindowVerDatosLog
*
* Contiene el componente que visualiza el detalle del log
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import alertify from 'alertifyjs';

class WindowVerDatosLog extends Component {
    constructor(props) {        
        super(props);           
        this.state = {
            datosLog : [], 
            database : globalState.getState().companyData[0].bd
        };      
    }
    componentWillMount(prevProps,prevState){  //refrescar el listado        
        let sqlParams = { 
                            sqlCols : [
                                "T1.datos",                                              
                            ],                                                          
                            sqlWhere : [ " AND T1.id = "+this.props.params.parametro.idRow.id ],                                
                        }
        cargarFilas('log','',100,0,'','',sqlParams,'rows',this.state.database)      
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else if(response.length > 0){
                var stringDatos = response[0].datos;
                if(stringDatos !== null){
                    stringDatos = stringDatos.split('{.}');                             
                    this.setState({datosLog : stringDatos}); 
                }                                              
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })     
    }
    handleBotonAtras(){
        this.props.params.parametro.funcionClick('WindowVisorLog'); 
    }
    handleVerCambios(){
        this.props.params.parametro.funcionClick('WindowVerCambiosLog'); 
    }
  	render() {       
  	  	return (<div id="contenedorTrazabilidad" style={{paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'270px',overflowY : 'auto' }}>   
                    <Button style={{margin: '5px 0'}} className="float-left mr-3" variant="secondary" onClick={this.handleBotonAtras.bind(this)}>
                        ATRAS
                    </Button>
                    <Button style={{margin: '5px 0'}} className="float-left mr-3" variant="primary" onClick={this.handleVerCambios.bind(this)}>
                        VER CAMBIOS
                    </Button>
                    <div>
                        <Table responsive>
                            <thead className="cf">
                                <tr>
                                    <th style={{fontSize:'12px'}}>Campo</th>
                                    <th style={{fontSize:'12px'}}>Valor</th>                                     
                                </tr>
                            </thead>    
                            <tbody>
                            {
                                this.state.datosLog.map((content,i) => { 
                                    content = content.split('=');   
                                    return  <tr key={ i } >
                                                <td data-title="Campo" style={{fontSize:'11px'}}>{content[0]}</td> 
                                                <td data-title="Valor" style={{fontSize:'11px'}}>{content[1]}</td>
                                            </tr>            
                                })
                            }
                            </tbody>
                        </Table>
                    </div>                                   
                </div>);
  	}
}

export default WindowVerDatosLog