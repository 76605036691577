/**
* CLASS Users
*
* Contiene el contenedor principal de los usuarios
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../../data_grid/DataGrid';
import globalState from '../../configuration/GlobalState';
import {enviarMailActivacion,cargarFilas} from '../../api_calls/ApiCalls';
import Window from '../../window/Window';
import TBar from '../../tbar/TBar';
import alertify from 'alertifyjs';

class Users extends Component {
    constructor(props){
        super(props);
        this.state={
            botonCreacion : 'true',
            displayReact  : 'block',
            database : globalState.getState().companyData[0].bd
        }
    } 
    contarUsuarios(){
        let maxUsers  = globalState.getState().companyData[0].maximo_usuarios;
        let sqlParams = {
                            sqlCols : [
                                'COUNT(id) AS total', 
                                'activo',                                                                                                                                      
                            ], 
                            sqlWhere : [
                                " AND id_rol > 1"//no cuenta superusuarios
                            ],
                            sqlGroupBy : "activo",
                            sqlOrderBy : "activo"                                                                                                                                                         
                        } 
        cargarFilas('users','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
            } 
            else{
                if(res.length > 0 && res[0].total >= maxUsers){                    
                    this.setState({botonCreacion : 'false',displayReact : 'none'});
                }                
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    componentWillMount(){        
        this.contarUsuarios();
    }       
    componentDidUpdate(prevProps){                                 
        if (this.props !== prevProps) {           
           this.contarUsuarios(); 
        }       
    } 
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }  
    enviarMailActivacion(id){
        enviarMailActivacion(id,this.state.database)
        .then(response => {   
            response = response.data;         
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else if(response.msg === 'mail_error'){                
                alertify.alert('Error!', 'Ha ocurrido un error enviando el correo, verifique la conexion a internet y configuracion SMTP en la aplicacion!<br /><br />Detalles del Error: '+JSON.stringify(response.detail, null, 4));
            }
            else{
                let sqlParams = { 
                                    sqlCols : [
                                        "tiempo_token",                                                                            
                                    ],                             
                                }
                cargarFilas('politicas_seguridad','',1,0,'','',sqlParams,'rows',this.state.database)
                .then(response => {             
                    response = response.data;
                    if(response.msg === 'error'){
                        alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                    } 
                    else{
                        var titulo = ' 1 dia';
                        if(response.length > 0){
                            titulo = response[0].tiempo_token+' minutos';
                        }
                        alertify.alert('Aviso!', 'Se ha enviado un correo electronico al usuario para la activacion!<br />Vigencia del Correo: '+titulo);  
                    }
                })
                .catch(function (error) {
                    alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
                });       
            }  
        })
        .catch(function (error) {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    }
    ventanaReactivarUsuarios(){        
        globalState.dispatch({
            type   : "windowReactivarUsuarios",
            params : {
                          visible : true,
                          params  : {                                        
                                        idWin : "windowReactivarUsuarios",//identificador de la ventana
                                        tabla : "users",
                                        opcion: "Users",
                                        funcionClick : this.props.funcionClick,
                                        width : '400px', 
                                        height: '270px'
                                    }
                     }
        });
    }    
    colFuncion2(idRow){
        globalState.dispatch({
                    type   : "windowAdjuntosUsers",
                    params : {
                                  visible : true,
                                  params  : {
                                                idRow   : idRow, 
                                                table   : 'users',                                           
                                                idWin   : "windowAdjuntosUsers",
                                                width   : '300px', 
                                                height  : '250px',                                                
                                            }
                             }
                }); 
    }
    btnVisorLog(idRow){
        globalState.dispatch({
            type   : "windowVisorLog",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,
                                        table : 'users',
                                        idWin : "windowVisorLog",
                                        width : '400px', 
                                        height: '300px'//identificador de la ventana
                                    }
                     }
        });
    }  
    btnCapacitaciones(idRow){
        globalState.dispatch({
            type   : "windowUsuarioCapacitaciones",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,                                        
                                        idWin : "windowUsuarioCapacitaciones",
                                        width : '400px', 
                                        height: '300px'//identificador de la ventana
                                    }
                     }
        });
    }  
    render() {        
        var idRol = globalState.getState().idRol;        
        var sqlWhere = ' AND T1.id_rol > 1 ';
        if(idRol === 1){            
            sqlWhere = '';
        } 
        let sqlParams = {
                            sqlCols : [
                                'T1.id',
                                'T1.externo',
                                "IF(T1.externo='true','Si','No') AS nom_externo",
                                'T1.id_tipo_documento',
                                'DT.nombre AS tipo_documento',
                                "CONCAT(DT.nombre,' ',T1.documento) AS numero_documento",
                                'T1.documento',
                                'T1.nombre',
                                'T1.primer_nombre',
                                'T1.segundo_nombre',
                                'T1.primer_apellido',
                                'T1.segundo_apellido',
                                'T1.email',
                                'DATE_FORMAT(T1.fecha_nacimiento,"%Y-%m-%d") AS fecha_nacimiento',                                
                                'T1.direccion',
                                'A.nombre AS nombre_area',
                                'D.nombre AS nombre_departamento',
                                'T1.id_area', 
                                'T1.id_departamento',                                
                                'T1.telefono',
                                'T1.id_rol',
                                'RL.nombre AS rol',
                                'T1.imagen_usuario'
                            ],                                                      
                            sqlJoin : [
                                'INNER JOIN document_types AS DT ON (DT.id = T1.id_tipo_documento)', 
                                'INNER JOIN roles AS RL ON (RL.id = T1.id_rol)',
                                'INNER JOIN departamentos AS D ON (D.id = T1.id_departamento)',
                                'LEFT JOIN areas_servicio AS A ON (A.id = T1.id_area)' 
                            ],
                            sqlWhere : [ sqlWhere ],
                            fieldSearch : [
                                'T1.nombre',
                                'T1.documento',
                                'T1.email',
                                'T1.direccion',
                                'T1.telefono',                                
                            ],                                                                                                 
                        }  
        let windowVisorLog = <Window 
                                  id = "windowVisorLog"                      
                                  title='Ver Log'                                                
                                  tbar="false"
                                  componente="WindowVisorLog"
                                  params="" 
                              /> 

        let windowUsuarioCapacitaciones =  <Window 
                                                id = "windowUsuarioCapacitaciones"                      
                                                title='Ver Capacitaciones'                                                
                                                tbar="false"
                                                componente="WindowUsuarioCapacitaciones"
                                                params="" 
                                            />  

        let dataLogParams = {
            documento : "Documento",
            primer_nombre : "Primer Nombre",
            segundo_nombre  : "Segundo Nombre",
            primer_apellido : "Primer Apellido",
            segundo_apellido : "Segundo Apellido",
            direccion : "Direccion",
            telefono : "Telefono",
            email : "E-mail",            
        };

        return (//carga el componente que contiene la grilla de datos         
            <div>
                <TBar
                    items={[                              
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '80px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                              {
                                  type : 'boton',
                                  icon : 'redo',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Reactivaciones',
                                  display : this.state.displayReact,
                                  function : this.ventanaReactivarUsuarios.bind(this)
                              }                              
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>           
                    <DataGrid titulo='Usuarios' 
                              funcionClick={this.props.funcionClick}  
                              parametro={this.props.parametro}
                              tbarEdit="true"
                              tbarItems={[
                                              {
                                                    type : 'boton',
                                                    icon : 'description',
                                                    width : '80px',
                                                    height : '70px',
                                                    title : 'Ver Log',
                                                    display : 'block', 
                                                    windowComponent : windowVisorLog,
                                                    function : this.btnVisorLog.bind(this)                                       
                                              },
                                              {
                                                    type : 'boton',
                                                    icon : 'school',
                                                    width : '100px',
                                                    height : '70px',
                                                    title : 'Capacitaciones',
                                                    display : 'block', 
                                                    windowComponent : windowUsuarioCapacitaciones,
                                                    function : this.btnCapacitaciones.bind(this)                                       
                                              }
                                         ]}
                              dataFilter={[                                    
                                    {
                                        label : "A. Servicio",
                                        dinamic : 'true',
                                        field : 'T1.id_area',
                                        sqlParams : {
                                                        sqlCols : [
                                                            'id',
                                                            'nombre'                                
                                                        ],                                                                                                                                                                                                                                                                                   
                                                    },
                                        apiField : 'areas_servicio',
                                        valueName : 'nombre',
                                    },
                                    {
                                        label : "Depto",
                                        dinamic : 'true',
                                        field : 'T1.id_departamento',
                                        sqlParams : {
                                                        sqlCols : [
                                                            'id',
                                                            'nombre'                                
                                                        ],                                                                                                                                                                                                                                                                                   
                                                    },
                                        apiField : 'departamentos',
                                        valueName : 'nombre',
                                    },
                                    {
                                        label : "Rol",
                                        dinamic : 'true',
                                        field : 'T1.id_rol',
                                        sqlParams : {
                                                        sqlCols : [
                                                            'id',
                                                            'nombre'                                
                                                        ],
                                                        sqlWhere : [
                                                            " AND id > 1"
                                                        ]                                                                                                                                                                                                                                                                                   
                                                    },
                                        apiField : 'roles',
                                        valueName : 'nombre',
                                    }                                                 
                              ]}                    
                              colsData={[ 
                                              {
                                                type  : 'bd',
                                                label : 'Documento',
                                                field : 'numero_documento'
                                              },
                                              {
                                                  type  : 'bd',
                                                  label : 'Nombre',
                                                  field : 'nombre'
                                              },
                                              {
                                                  type  : 'bd',
                                                  label : 'Email',
                                                  field : 'email'
                                              },
                                              {
                                                  type  : 'bd',
                                                  label : 'Rol',
                                                  field : 'rol'
                                              },
                                              {
                                                  type  : 'bd',
                                                  label : 'Area',
                                                  field : 'nombre_area'
                                              },                                              
                                              {
                                                  type  : 'bd',
                                                  label : 'Externo',
                                                  field : 'nom_externo'
                                              },                                              
                                              {
                                                  type  : 'manual',
                                                  label : '',
                                                  icon  : 'attach_file',
                                                  colFuncion : this.colFuncion2.bind(this)
                                              }                                              
                                           ]}
                              sqlParams = { sqlParams } 
                              automatica="true"
                              botonNuevo={this.state.botonCreacion}
                              funcionAfterInsert={this.enviarMailActivacion.bind(this)}
                              botonesExportar="true"
                              dataLog={dataLogParams}
                              formFields={[
                                            {
                                                label : 'Tipo de Documento *',
                                                field : 'id_tipo_documento',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'true',
                                                apiField : 'document_types',
                                                valueName : 'nombre',
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],                                                                                                       
                                                            },
                                                tooltip : 'Seleccione el tipo de documento'
                                            },                                    
                                            {
                                                label : 'Documento *',
                                                field : 'documento',
                                                type  : 'text',
                                                validation : 'entero',
                                                required : 'true',
                                                tooltip : 'Ingrese el numero del documento'                                        
                                            },
                                            {
                                                label : 'Primer Nombre *',
                                                field : 'primer_nombre',
                                                type  : 'text',
                                                validation : 'mayusculas',
                                                required : 'true',
                                                tooltip : 'Ingrese el primer nombre del usuario'
                                            },
                                            {
                                                label : 'Segundo Nombre',
                                                field : 'segundo_nombre',
                                                type  : 'text',
                                                validation : 'mayusculas',
                                                required : 'false',
                                                tooltip : 'Ingrese el segundo nombre del usuario(opcional)'
                                            },
                                            {
                                                label : 'Primer Apellido *',
                                                field : 'primer_apellido',
                                                type  : 'text',
                                                validation : 'mayusculas',
                                                required : 'true',
                                                tooltip : 'Ingrese el primer apellido del usuario'
                                            },
                                            {
                                                label : 'Segundo Apellido',
                                                field : 'segundo_apellido',
                                                type  : 'text',
                                                validation : 'mayusculas',
                                                required : 'false',
                                                tooltip : 'Ingrese el segundo apellido del usuario(opcional)'
                                            },
                                            {
                                                label : 'Rol de Sistema *',
                                                field : 'id_rol',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'true',
                                                apiField : 'roles',
                                                valueName : 'nombre',
                                                sqlParams : {                                                                
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],
                                                                sqlWhere : [
                                                                    ' AND id > 1 '
                                                                ],                                                                                                                                                                                                                                 
                                                            },
                                                tooltip : 'Seleccione el rol de sistema para el usuario'                                            
                                            },
                                            {
                                                label : 'Externo *',
                                                field : 'externo',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: 'false',
                                                                nombre: 'No',
                                                            },
                                                            {
                                                                id: 'true',
                                                                nombre: 'Si', 
                                                            }],
                                                tooltip : 'Seleccione si el area es interna/externa'                                        
                                            }, 
                                            {
                                                label : 'Area de Servicio',
                                                field : 'id_area',
                                                type  : 'select',
                                                validation : '',
                                                required : 'false',
                                                dinamic : 'true',
                                                apiField : 'areas_servicio',
                                                valueName : 'nombre',
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],                                                                                                                                                                                                                               
                                                            },
                                                tooltip : 'Seleccione el area de servicio'                                            
                                            },
                                            {
                                                label : 'Departamento *',
                                                field : 'id_departamento',
                                                type  : 'select',
                                                validation : '',
                                                required : 'false',
                                                dinamic : 'true',
                                                apiField : 'departamentos',
                                                valueName : 'nombre',
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],                                                                                                                                                                                                                               
                                                            },
                                                tooltip : 'Seleccione el departamento'                                            
                                            },
                                            {
                                                label : 'Correo Electronico *',
                                                field : 'email',
                                                type  : 'text',
                                                validation : 'email',
                                                required : 'true',
                                                tooltip : 'Ingrese el correo electronico del usuario'
                                            },
                                            {
                                                label : 'Direccion *',
                                                field : 'direccion',
                                                type  : 'text',
                                                validation : 'direccion',
                                                required : 'true',
                                                tooltip : 'Ingrese la direccion de residencia del usuario'
                                            },
                                            {
                                                label : 'Telefono',
                                                field : 'telefono',
                                                type  : 'text',
                                                validation : 'telefono',
                                                required : 'false',
                                                tooltip : 'Ingrese el numero telefonico del usuario'
                                            },
                                            {
                                                label : 'Fecha de Nacimiento *',
                                                field : 'fecha_nacimiento',
                                                type  : 'date',
                                                validation : '',
                                                required : 'true',
                                                tooltip : 'Ingrese la fecha de nacimiento'                                        
                                            },
                                        ]}                     
                              apiField = {'users'}
                              mainContainer='Users'/>
                 </div>                
                  <Window 
                      id = "windowReactivarUsuarios"                      
                      title='Reactivar Usuarios'                      
                      tbar="false"
                      componente="WindowReactivarInformacion"
                      params="" 
                  />                  
                  <Window 
                      id = "windowAdjuntosUsers"                     
                      title='Ver Documentos'                   
                      tbar="false"
                      componente="WindowArchivosAdjuntos"
                      params="" 
                  /> 
                  <Window 
                      id = "windowUploadArchivo"                      
                      title='Subir Adjunto'                    
                      tbar="false"
                      componente="WindowUploadArchivo"
                      params="" 
                  />
            </div>
        )
    } 
}

export default Users
