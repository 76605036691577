/**
* CLASS NameUser
*
* Contiene el contenedor del nombre del usuario
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import Dropdown from 'react-bootstrap/Dropdown';
import CustomToggle from './CustomToggleDropdown';
import globalState from '../configuration/GlobalState';
import configJson,{ diccionario } from '../configuration/configuration.json';
import ImagenUsuario from './ImagenUsuario';
import {divMouseOver,divMouseOut} from '../configuration/GlobalFunctions';
import Window from '../window/Window';
import {logout} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import './desktop.css';
import '../../css/alertify.css';

const { LabelsNameUser } = diccionario;

class NameUser extends Component {
	  constructor(props) {
        super(props);    
        this.btnLogoutSession = this.btnLogoutSession.bind(this);
        this.state = {            
            username    : '',
            companyName : '',
            imagen_usuario  : 'default.png',
            database : globalState.getState().companyData[0].bd, 
            rol : ''           
        };        
    }    
    componentDidMount(){//traer los datos del store  
        this.unsubscribe1 = globalState.subscribe( ()=>{             
            if(globalState.getState().type==="nameUser"){
                this.setState({username   : globalState.getState().nameUser}) 
                this.setState({rol   : globalState.getState().userData[0].rol}) 
            }
        });
        this.unsubscribe2 = globalState.subscribe( ()=>{ 
            if(globalState.getState().type==="nameCompany"){ 
                this.setState({companyName  : globalState.getState().nameCompany})                
            }
        }); 
        this.unsubscribe3 = globalState.subscribe( ()=>{             
            if(globalState.getState().type==="imagen_usuario"){
                if(globalState.getState().imagen_usuario !== undefined && globalState.getState().imagen_usuario !== ""){
                    this.setState({imagen_usuario : globalState.getState().imagen_usuario});
                }
            }
        });       
    }
    componentWillUnmount(){         
        this.unsubscribe1();
        this.unsubscribe2();
        this.unsubscribe3();             
    }
    //evento cerrar sesion
	  btnLogoutSession(){//boton de cerrar sesion
        let idioma = globalState.getState().idioma;
	  	  alertify.confirm(LabelsNameUser.ConfirmLogoutTitle[idioma],LabelsNameUser.ConfirmLogoutText[idioma], this.logoutSession.bind(this), function(){});
	  } 
    //abrir la modal
    handleOpenModal(){//boton de abrir modal        
        globalState.dispatch({
                type   : "windowFormDataUser",
                params : {
                            visible : true,
                            params  : {
                                width   :'315px', 
                                height  :'80%'
                            }                           
                         }
            });
    }          
    //metodo cerrar sesion
	  logoutSession(){//llamar a cerrar sesion en la API
	 	    logout()
        .then(res => {
            var response = res.data; 
          	if (response.msg === "error") {      	  	  	  	
          		alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+response.detail);  	  	  	  	
          	} else if (response.msg === "success"){
          	  	this.props.history.push('/'+configJson.mainPath);//me devuelve al login                    	  	  	  	
          	}
        })
        .catch(err => {
          	alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);      	  	  
        });
	  } 
    render() {
          let idioma = globalState.getState().idioma;   
          var date = new Date();
          var randomDate = date.getTime(); 
          var path =  window.location.protocol+"//"+window.location.hostname+configJson.filesRoute; 
          var folder = globalState.getState().companyData[0].bd;          

    	  	return (//carga el menu de opciones del usuario  	  		
        			<Dropdown  id="ContenidoDataUser" className="ContenidoUser" onMouseOut={divMouseOut.bind(this,'ContenidoDataUser',configJson.fondoMenu)} onMouseOver={divMouseOver.bind(this,'ContenidoDataUser',configJson.fondoMenu)}>	
        				  <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">  	  					 		  
      				    	  <div className="FotoUsuario">
                          <ImagenUsuario src={path+folder+'/'+configJson.folderAvatarUser+this.state.imagen_usuario+'?'+randomDate} /> 
                      </div>
                      <div className="NombreUsuario">
                          <div style={{width:'100%',fontSize:'11px'}}>
                              {this.state.username}
                          </div>
                          <div style={{width:'100%',fontSize:'11px'}}>
                              {this.state.rol}
                          </div>
                          <div style={{width:'100%',fontSize:'11px'}}>
                              {this.state.companyName}
                          </div>	
                      </div>
      				    	  <div className="OptionUsuario">
      				    	  	<MaterialIcon size={24} icon="keyboard_arrow_down" invert />
      				    	  </div>					
      				    </Dropdown.Toggle>
      				    <Dropdown.Menu  style={{ marginTop: '54px',marginLeft: '5px',width:'300px'}}>
      				      	<Dropdown.Item eventKey="1" onClick={this.handleOpenModal.bind(this)}>
                          <div id="dropdownUserData" style={{height:'25px'}} onMouseOut={divMouseOut.bind(this,'dropdownUserData','#ffffff')} onMouseOver={divMouseOver.bind(this,'dropdownUserData','#c6c6c6')}>
                              <div style={{width:'30px',float:'left'}}>
                                  <MaterialIcon size={24} icon="account_circle" />
                              </div>
                              <div style={{height:'25px',float:'left',verticalAlign:'middle',paddingTop:'5px'}}>
                                  {LabelsNameUser.Option1[idioma]}
                              </div>
                          </div>
                      </Dropdown.Item>
      				      	<Dropdown.Item eventKey="1" onClick={this.btnLogoutSession.bind(this)}>
                          <div id="dropdownLogout" style={{height:'25px'}} onMouseOut={divMouseOut.bind(this,'dropdownLogout','#ffffff')} onMouseOver={divMouseOver.bind(this,'dropdownLogout','#c6c6c6')}>
                              <div style={{width:'30px',float:'left'}}>
                                  <MaterialIcon size={24} icon="close" />
                              </div>
                              <div style={{height:'25px',float:'left',verticalAlign:'middle',paddingTop:'5px'}}>
                                  {LabelsNameUser.Option2[idioma]}
                               </div>
                          </div>
                      </Dropdown.Item>				  	
      				    </Dropdown.Menu>   
                  <Window 
                      id = "windowFormDataUser"                      
                      title={LabelsNameUser.TitleWinFormUser[idioma]}
                      tbar='false'
                      componente="FormDataUser"
                      params="" 
                  />                                  
      			  </Dropdown>	              	    		
	     );
    }
}

export default NameUser