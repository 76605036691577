/**
* CLASS Companies
*
* Contiene el contenedor principal de empresas
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../../data_grid/DataGrid';
import globalState from '../../configuration/GlobalState';
import {crearDirectorios} from '../../api_calls/ApiCalls';
import configJson from '../../configuration/configuration.json';
import Window from '../../window/Window';
import TBar from '../../tbar/TBar';
import alertify from 'alertifyjs';

class Companies extends Component {
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }
    colFuncion(idRow){
        globalState.dispatch({
                type   : "windowEmpresasModulos",
                params : {
                              visible : true,
                              params  : {
                                            idRow : idRow,
                                            idWin  : "windowEmpresasModulos",//identificador de la ventana
                                            width  : '315px', 
                                            height : '80%'
                                        }
                         }
            }); 
    }
    colFuncion2(idRow){        
        globalState.dispatch({
                type   : "windowAdjuntosActivos",
                params : {
                              visible : true,
                              params  : {
                                            idRow  : idRow, 
                                            table  : 'ikarosof_management_acceso.companies',                                           
                                            idWin  : "windowAdjuntosActivos",
                                            width  : '300px', 
                                            height : '250px'//identificador de la ventana
                                        }
                         }
            }); 
    }     
    crearDirectorios(id){ 
        crearDirectorios(id).then(response => { 
            response = response.data;         
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un creando los directorios!<br>'+response.detail);
            }
            else{
                alertify.alert('Aviso!', 'Se han creado los directorios!');
            }
        })
        .catch(function (error) {           
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    }
  	render() { 
        let where = "";
        let database = globalState.getState().companyData[0].bd;
        let permiso = 19;        
        let modulos  =  {
                            type  : 'manual',
                            label : '',
                            icon  : 'settings',
                            colFuncion : this.colFuncion.bind(this)
                        };
        if(configJson.instalacion === 'local'){
            where = " AND bd='"+database+"'";            
            permiso = 777;
            modulos  =  {
                            type  : 'manual',
                            label : '',
                            icon  : 'folder',
                            colFuncion : this.crearDirectorios.bind(this)
                        };
        }     
        let sqlParams = {
                            sqlCols : [
                                'T1.id',
                                'T1.id_tipo_documento', 
                                'DT.nombre AS tipo_documento',
                                "CONCAT(DT.nombre,' ',T1.documento) AS numero_documento",
                                'T1.documento',
                                'T1.razon_social',
                                'T1.bd',
                                'T1.bd_ubicacion',
                                'T1.cuota_almacenamiento',
                                'T1.nombre_comercial',
                                'T1.tipo_licencia',
                                'T1.idioma',  
                                "IF(T1.idioma=1,'Español',IF(T1.idioma=2,'Ingles','')) AS nombre_idioma",  
                                'T1.maximo_usuarios',                              
                                "DATE_FORMAT(T1.fecha_vencimiento_licencia,'%Y-%m-%d') AS fecha_vencimiento_licencia",                                
                            ],                                                      
                            sqlJoin : [
                                'INNER JOIN document_types AS DT ON (DT.id = T1.id_tipo_documento)'
                            ],
                            sqlWhere : [ where ],
                            fieldSearch : [
                                'T1.documento',
                                'T1.razon_social',
                                'T1.nombre_comercial'                                
                            ],                                                                                                       
                        }                 
        return (//carga el componente que contiene la grilla de datos
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>            
                    <DataGrid titulo='Empresas' 
                              funcionClick={this.props.funcionClick} 
                              parametro={this.props.parametro}
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Documento',
                                                field : 'numero_documento'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Razon Social',
                                                field : 'razon_social'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Nombre Comercial',
                                                field : 'nombre_comercial'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Base de Datos',
                                                field : 'bd'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Idioma',
                                                field : 'nombre_idioma'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Licencia',
                                                field : 'tipo_licencia'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Vencimiento',
                                                field : 'fecha_vencimiento_licencia'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Max. Usuarios',
                                                field : 'maximo_usuarios'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Almacenamiento',
                                                field : 'cuota_almacenamiento'
                                            },
                                            modulos,
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'insert_photo',
                                                colFuncion : this.colFuncion2.bind(this)
                                            }
                                        ]} 
                              sqlParams = { sqlParams } 
                              automatica="true"
                              botonNuevo="true"
                              permisoInsertUpdate={permiso}                              
                              permisoDelete={permiso}
                              funcionAfterInsert={this.crearDirectorios.bind(this)}
                              funcionAfterUpdate={this.crearDirectorios.bind(this)}
                              formFields={[
                                            {
                                                label : 'Tipo de Documento *',
                                                field : 'id_tipo_documento',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'true',
                                                apiField : 'ikarosof_management_acceso.document_types',
                                                apiRoute : configJson.cloudRoute,
                                                valueName : 'nombre',
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],                                                                                                       
                                                            },
                                                tooltip : 'Seleccione el tipo de documento de la empresa'
                                            },                                    
                                            {
                                                label : 'Documento *',
                                                field : 'documento',
                                                type  : 'text',
                                                validation : 'numero_texto',
                                                required : 'true',
                                                tooltip : 'Ingrese el numero de documento de la empresa'                                        
                                            },
                                            {
                                                label : 'Razon Social *',
                                                field : 'razon_social',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                longitud : 55,
                                                required : 'true',
                                                tooltip : 'Ingrese la razon social de la empresa' 
                                            },
                                            {
                                                label : 'Nombre Comercial *',
                                                field : 'nombre_comercial',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                longitud : 55,
                                                required : 'true',
                                                tooltip : 'Ingrese el nombre comercial de la empresa' 
                                            },
                                            {
                                                label : 'Base de Datos *',
                                                field : 'bd',
                                                type  : 'text',
                                                validation : '',
                                                required : 'true',
                                                tooltip : 'Ingrese el nombre de la base de datos' 
                                            },
                                            {
                                                label : 'Instalacion *',
                                                field : 'bd_ubicacion',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: 'nube',
                                                                nombre: 'Nube',
                                                            },
                                                            {
                                                                id: 'local',
                                                                nombre: 'Local', 
                                                            },],
                                                tooltip : 'Ingrese el tipo de instalacion'                                         
                                            },
                                            {
                                                label : 'Idioma *',
                                                field : 'idioma',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: '1',
                                                                nombre: 'Español',
                                                            },
                                                            {
                                                                id: '2',
                                                                nombre: 'Ingles', 
                                                            },],
                                                tooltip : 'Ingrese el idioma del aplicativo'                                         
                                            },
                                            {
                                                label : 'Tipo de Licencia *',
                                                field : 'tipo_licencia',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: 'anual',
                                                                nombre: 'Anual',
                                                            },
                                                            {
                                                                id: 'mensual',
                                                                nombre: 'Mensual', 
                                                            },],
                                                tooltip : 'Ingrese el tipo de Licencia para la empresa'                                         
                                            },
                                            {
                                                label : 'Fecha de Vencimiento *',
                                                field : 'fecha_vencimiento_licencia',
                                                type  : 'date',
                                                validation : '',
                                                required : 'true',
                                                tooltip : 'Ingrese la fecha de vencimiento de la licencia' 
                                            },
                                            {
                                                label : 'Maximo de Usuarios *',
                                                field : 'maximo_usuarios',
                                                type  : 'text',
                                                validation : 'entero',
                                                required : 'true',
                                                tooltip : 'Ingrese el maximo de usuarios'                                        
                                            },
                                            {
                                                label : 'Cuota Almacenamiento(MB) *',
                                                field : 'cuota_almacenamiento',
                                                type  : 'text',
                                                validation : 'entero',
                                                required : 'true',
                                                tooltip : 'Ingrese la cuota de almacenamiento'                                        
                                            },
                                            {
                                                label  : 'Adjunto',                                                    
                                                field  : 'nombre_archivo',                                                                                                        
                                                type   : 'multi_file',
                                                accept : ".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png",
                                                allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],
                                                validation : 'file',
                                                required : '',
                                                tooltip : 'Seleccione el logo'
                                            },                                                                         
                                        ]}                     
                              apiField={'ikarosof_management_acceso.companies'}                                                           
                              mainContainer='Companies'/> 
                </div>
                <Window 
                    id = "windowEmpresasModulos"                      
                    title='Configurar Modulos'                      
                    tbar="false"
                    componente="WindowEmpresasModulos"
                    params="" 
                />
                <Window 
                    id = "windowAdjuntosActivos"                     
                    title='Logos'                   
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                /> 
                <Window 
                    id = "windowUploadArchivo"                      
                    title='Subir Adjunto'                    
                    tbar="false"
                    componente="WindowUploadArchivo"
                    params="" 
                />
            </div>             
        )
    } 
}

export default Companies