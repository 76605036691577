/**
* CLASS Desktop
*
* Contiene el escritorio de la aplicacion
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import NameUser from './NameUser';
import OptionMenu from './OptionMenu';
import MaterialIcon from 'material-icons-react';
import Modal from 'react-bootstrap/Modal';
import configJson from '../configuration/configuration.json';
import {validarPermiso,validarModulo} from '../configuration/GlobalFunctions';
import ImagenUsuario from './ImagenUsuario';
//import React, { useEffect } from 'react';
import globalState from '../configuration/GlobalState';
import BotonNotificaciones from './BotonNotificaciones';
import {checkSession,cargarFilas,logout,sincronizarEmpresa} from '../api_calls/ApiCalls';
import loadingImg from '../../images/loading.gif?v1.0';
import Container from './Container';
import alertify from 'alertifyjs';
import '../../css/alertify.css';
import './desktop.css?v1.5';

let timer = null; 

class Desktop extends Component {    
	constructor(props) {//al cargarse trae los datos del usuario 		
      	super(props);          

      	this.state = { 
      		loading: true,
          	redirect: false,
            vencimiento: false,
            empresaVacia: false,	 		                
      	 	componente: "WelcomePage",
      	 	parametro : "",
            checkMenu : false,
            showLoading : false,
            solicitudesPendientes : 0,
            solicitudesPorVencer : 0,            
            iconAlert : 'notifications',
            logoEmpresa : '',
            colorAlert : '',
            displayAlert : '', 
            passwordVencido : false                       
	    };        
	    this.actualizarContainer = this.actualizarContainer.bind(this);
	}

    componentWillMount(){
        this.validarSesion();
    } 

    async validarSesion(){
        try{
            var usuario = '';
            var empresa = '';
            if(this.props.location.state !== undefined){
                usuario = this.props.location.state.usuario; 
                empresa = this.props.location.state.empresa;
            }
            //validar la sesion
            let res = await checkSession();        
            var response = res.data; 
            if (response.session === "true") {
                //cargar la informacion de la empresa
                let sqlParams = {
                                    sqlCols : [
                                        "*",
                                        "DATEDIFF(fecha_vencimiento_licencia,NOW()) AS dias"
                                    ],                        
                                    sqlWhere : [ " AND documento = '"+empresa+"'" ],                                
                                }                
                let res = await cargarFilas('ikarosof_management_acceso.companies','',1,0,'','',sqlParams,'rows','ikarosof_management_acceso',configJson.cloudRoute);
                var response1 = res.data;                       
                if(response1.msg === 'error'){//aqui no me dejara continuar si hay un error
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la informacion de la empresa!<br />Codigo de Error: '+response1.detail);
                    this.setState({ loading: false, empresaVacia: true });
                }
                else if(response1.length === 0){//aqui no me dejara continuar si la empresa noe xiste
                    alert("La empresa ingresada no se encuentra registrada o activa!");
                    this.setState({ loading: false, empresaVacia: true });                        
                }                     
                else{
                    let res = await sincronizarEmpresa(empresa,response1[0]);
                    if (res.msg === "error") {
                        alertify.alert('Error!', 'Ha ocurrido un error sincronizando la informacion de la empresa!<br />Codigo de Error: '+response.detail);
                        this.setState({ loading: false, empresaVacia: true });
                    }
                    else if(res.msg === "not_exists"){
                        alertify.alert('Error!', 'No se encuentra empresa registrada o activa para sincronizar');
                        this.setState({ loading: false, empresaVacia: true });
                    }
                    //mostrar aviso del vencimiento de la licencia
                    if(response1[0].tipo_licencia === 'mensual' && response1[0].dias < 8){//si es mensual  
                        if(response1[0].dias < 1){
                            this.setState({ loading: false, vencimiento: true });                               
                        }
                        else{
                            alertify.alert('Aviso!', 'Su licencia va a expirar!<br />Le Restan '+response1[0].dias+' dias');
                        }                          
                    }
                    else if(response1[0].tipo_licencia === 'anual' && response1[0].dias < 31){//si es anual   
                        if(response1[0].dias < 1){
                            this.setState({ loading: false, vencimiento: true });                               
                        }
                        else{                         
                            alertify.alert('Aviso!', 'Su licencia va a expirar!<br />Le Restan '+response1[0].dias+' dias');
                        }
                    }
                    globalState.dispatch({
                        type   : "companyData",
                        params : response1
                    });
                    //cargar los modulos de la empresa
                    let sqlParams = {   
                                        sqlCols  : [ "id_modulo" ], 
                                        sqlWhere : [ " AND id_empresa="+response1[0].id ],
                                        mode : 'rows',                                                                                                
                                    }

                    let result = await cargarFilas('ikarosof_management_acceso.companies_modulos','',100,0,'','',sqlParams,'rows','ikarosof_management_acceso',configJson.cloudRoute);
                    result = result.data; 
                    if (result.msg === "error") {
                        alertify.alert('Error!', 'Ha ocurrido un error accesando a los modulos!<br />Codigo de Error: '+response.detail);
                        this.setState({ loading: false, empresaVacia: true });  
                    } 
                    else{ 
                        //cargar los accesos a los modulos
                        var arrayModulos = [];  
                        for(let i in result){ 
                            arrayModulos[result[i].id_modulo] = 'true';
                        }                          
                        if(arrayModulos.length > 0){                            
                            globalState.dispatch({
                                type   : "modulosAplicacion",
                                params : arrayModulos
                            });
                        }                                      
                        else{
                            alert("No se ha configurado el acceso a los modulos, envienos la solicitud a traves de ikarosofttechnology.com/management!");
                            this.setState({ loading: false, empresaVacia: true });                                    
                        }
                    }
                    //cargar el logo de la empresa
                    sqlParams = {   
                                    sqlCols  : [ "nombre_archivo" ], 
                                    sqlWhere : [ " AND id_maestro="+response1[0].id_local ],
                                    sqlOrderBy : 'id DESC',
                                    mode : 'rows',                                                                                                
                                }
                    result = await cargarFilas('ikarosof_management_acceso.companies_adjuntos','',100,0,'','',sqlParams,'rows','ikarosof_management_acceso');
                    result = result.data; 
                    if (result.msg === "error") {
                        alertify.alert('Error!', 'Ha ocurrido un error consultando el logo corporativo!<br />Codigo de Error: '+response.detail);
                        this.setState({ loading: false, empresaVacia: true });  
                    } 
                    else{
                        if(result.length > 0){
                            this.setState({logoEmpresa : result[0].nombre_archivo});
                            globalState.dispatch({
                                type   : "logoEmpresa",
                                params : result[0].nombre_archivo
                            });                                
                        }
                    }                    
                    //cargar la informacion del usuario
                    sqlParams = {
                        sqlCols : [
                            'T1.id',
                            'T1.id_tipo_documento',
                            'DT.nombre AS tipo_documento',
                            'T1.documento',
                            'T1.nombre',
                            'T1.primer_nombre',
                            'T1.segundo_nombre',
                            'T1.primer_apellido',
                            'T1.segundo_apellido',
                            'T1.email',
                            'T1.externo',
                            'T1.direccion',
                            'T1.telefono',
                            'T1.id_area',                                
                            'DATEDIFF(NOW(),T1.fecha_cambio_password) AS dias_cambio',
                            'T1.id_rol',
                            'RL.nombre AS rol',
                            'T1.imagen_usuario'
                        ],
                        sqlWhere : [ ' AND T1.email = \''+usuario+'\'' ],                            
                        sqlJoin : [
                            'INNER JOIN document_types AS DT ON (DT.id = T1.id_tipo_documento)', 
                            'INNER JOIN roles AS RL ON (RL.id = T1.id_rol)' 
                        ],                                                                             
                    }
                    res = await cargarFilas('users',usuario,1,0,'','',sqlParams,'rows',response1[0].bd);
                    response = res.data; 
                    if (response.msg === "error") {
                        alertify.alert('Error!', 'Ha ocurrido un error accesando a la informacion del usuario!<br />Codigo de Error: '+response.detail);
                        this.setState({ loading: false, empresaVacia: true });  
                    } 
                    else{                                  
                        this.setState({ loading: false },()=>{
                            globalState.dispatch({
                                type   : "userData",
                                params : response
                            });                                      
                            globalState.dispatch({
                                type   : "nameUser",
                                params : response[0].primer_nombre.toUpperCase()+' '+response[0].primer_apellido.toUpperCase()
                            });
                            globalState.dispatch({
                                type   : "nameCompany",
                                params : response1[0].razon_social.toUpperCase()
                            });
                            globalState.dispatch({
                                type   : "idioma",
                                params : response1[0].idioma
                            });
                            globalState.dispatch({
                                type   : "imagen_usuario",
                                params : response[0].imagen_usuario
                            }); 
                            globalState.dispatch({
                                type   : "idRol",
                                params : response[0].id_rol
                            });
                        });

                        var idRol = response[0].id_rol;

                        sqlParams = {   
                                        sqlCols  : [ "id_permiso" ], 
                                        sqlWhere : [ " AND id_rol="+idRol ],
                                        mode : 'rows',                                                                                                
                                    }

                        let result = await cargarFilas('roles_permisos','',100,0,'','',sqlParams,'rows',response1[0].bd);
                        result = result.data; 
                        if (result.msg === "error") {
                            alertify.alert('Error!', 'Ha ocurrido un error accesando a los permisos del usuario!<br />Codigo de Error: '+response.detail);
                            this.setState({ loading: false, empresaVacia: true });  
                        } 
                        else{ 
                            //cargar los permisos
                            var arrayPermisos = [];                                         
                            for(let i in result){                                             
                                arrayPermisos[result[i].id_permiso] = 'true';
                            }                          
                            globalState.dispatch({
                                type   : "permisosAplicacion",
                                params : arrayPermisos
                            }); 
                            //pantalla inicial                                                 
                            if(validarPermiso(1) && validarModulo(1)){
                                this.setState({ componente: "Dashboard" });                                     
                                this.setState({displayAlert : "true"});         
                            }
                            else if(validarModulo(5)){ 
                                this.setState({ componente: "SeleccionConocimiento" });                                         
                                this.setState({displayAlert : "false"});
                            } 
                        }
                        //vencimiento de contraseña
                        var dias_cambio = response[0].dias_cambio;
                        sqlParams = {   
                                        sqlCols : [ "password_vencimiento","tiempo_sesion" ],
                                        mode : 'rows'                                                 
                                    }

                        let res = await cargarFilas('politicas_seguridad','',1,0,'','',sqlParams,'rows',response1[0].bd);
                        response = res.data; 
                        if (response.msg === "error") {
                            alertify.alert('Error!', 'Ha ocurrido un error consultando las politicas de seguridad!<br />Codigo de Error: '+response.detail);
                            this.setState({ loading: false, empresaVacia: true });  
                        } 
                        else{                                        
                            //verifica si tiene vencimiento o no
                            var tiempo_sesion = 1440;
                            if(response.length > 0){
                                var dias_vencimiento = response[0].password_vencimiento;
                                if(dias_cambio < dias_vencimiento || dias_vencimiento === 0 || idRol === 1)
                                {
                                    var restantes = dias_vencimiento-dias_cambio;
                                    if(restantes < 7 && dias_vencimiento > 0 && idRol !== 1){
                                        alertify.alert('Aviso!', 'Su clave va a expirar, recuerde hacer el cambio de contraseña!<br />Le Restan '+restantes+' dias');
                                    }
                                }
                                else{                                                
                                    this.setState({ loading: false, passwordVencido: true });                                                 
                                }
                                tiempo_sesion = response[0].tiempo_sesion;
                            }
                            globalState.dispatch({
                                type   : "tiempoSesion",
                                params : tiempo_sesion
                            });
                        }                         
                    }                               
                }                          
            } else {
                this.setState({ loading: false, redirect: true });
            }
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
            this.setState({ loading: false, empresaVacia: true });  
        }
    }
    
	componentDidMount() {//cada que se monte el escritorio debe alistar la ventana del loading      
        //... 
        this.unsubscribe1 = globalState.subscribe( ()=>{ 
            if(globalState.getState().type==="menuNavegacion"){                             
                this.setState({checkMenu  : globalState.getState().menuNavegacion});                               
            }
        });
        this.unsubscribe2 = globalState.subscribe( ()=>{ 
            if(globalState.getState().type==="modalLoading"){
                this.setState({showLoading  : globalState.getState().modalLoading});                                                      
            }
        });   
        //muerte de la session por inactividad
        (function() {
            var minutes = true; // change to false if you'd rather use seconds 
            var interval = minutes ? 60000 : 1000; 
            var IDLE_TIMEOUT = 5; // 3 minutes in this example
            var idleCounter = 0; 
            document.onmousemove = document.onkeypress = document.ontouchmove = function () { 
                idleCounter = 0; 
            }; 
            window.setInterval(function () { 
                IDLE_TIMEOUT = globalState.getState().tiempoSesion;
                if (++idleCounter >= IDLE_TIMEOUT) {
                    //cierra la sesion                    
                    logout()
                    .then(res => {
                        var response = res.data; 
                        if (response.msg === "error") {                     
                            console.log('No se ha logrado la conexion con el servidor!<br />'+response.detail);                
                        } else if (response.msg === "success"){
                            window.location.reload();                                
                        }
                    })
                    .catch(err => {
                        console.log('No se ha logrado la conexion con el servidor!<br />'+err);              
                    });                     
                } 
            }, interval); 
        }());

        //sistema de notificaciones informativas para solicitudes pendientes
        timer = setInterval(function(){
            let idUsuario = globalState.getState().userData[0].id;
            let sqlPendientes = '';  
            let sqlPendientes1 = '';          

            if(validarPermiso(49)){
                let idArea = globalState.getState().userData[0].id_area;
                let andArea = "";
                if(idArea > 0){
                    andArea = " AND id_area="+idArea;
                }
                sqlPendientes = `(
                                    SELECT
                                        COUNT(id)
                                    FROM
                                        incidencias
                                    WHERE
                                        activo = 1
                                        AND estado = 2 
                                        `+andArea+`                                                                              
                                ) + (
                                    SELECT
                                        COUNT(id)
                                    FROM
                                        servicios
                                    WHERE
                                        activo = 1
                                        AND estado = 2
                                        `+andArea+`                                                                              
                                ) AS suma`;
                sqlPendientes1 = `(
                                    SELECT
                                        COUNT(id)
                                    FROM
                                        incidencias
                                    WHERE
                                        activo = 1
                                    AND estado = 2 
                                    `+andArea+`                                
                                    AND problema = 'false'                                    
                                    AND DATE_SUB(
                                        fecha_vencimiento_incidencia,
                                        INTERVAL (
                                            SELECT
                                                tiempo_incidencia
                                            FROM
                                                solicitudes_notificaciones                                            
                                        )
                                     HOUR
                                    ) <= NOW()
                                   ) + (
                                    SELECT
                                        COUNT(id)
                                    FROM
                                        incidencias
                                    WHERE
                                        activo = 1
                                    AND estado = 2 
                                    `+andArea+`                                   
                                    AND problema = 'true'                                    
                                    AND DATE_SUB(
                                        fecha_vencimiento_problema,
                                        INTERVAL (
                                            SELECT
                                                tiempo_problema
                                            FROM
                                                solicitudes_notificaciones                                            
                                        )
                                     HOUR
                                    ) <= NOW()
                                   ) + (
                                    SELECT
                                        COUNT(id)
                                    FROM
                                        servicios
                                    WHERE
                                        activo = 1
                                    AND estado = 2 
                                    `+andArea+`                                   
                                    AND DATE_SUB(
                                        fecha_vencimiento_servicio,
                                        INTERVAL (
                                            SELECT
                                                tiempo_servicio
                                            FROM
                                                solicitudes_notificaciones                                            
                                        )
                                     HOUR
                                    ) <= NOW()
                                   ) AS suma`;
            }
            else{
                let sqlIncidencias='';
                let sqlProblemas='';
                let sqlServicios='';
                let sqlIncidencias1='';
                let sqlProblemas1='';
                let sqlServicios1='';
                let count = 0;
                if(validarPermiso(37)){                    
                    sqlIncidencias = `(
                                        SELECT
                                            COUNT(id)
                                        FROM
                                            incidencias
                                        WHERE
                                            activo = 1
                                            AND estado = 2                                                                                       
                                            AND problema = 'false'
                                            AND id_tecnico_incidencia = `+idUsuario+`
                                      )+`;                                    

                    sqlIncidencias1 = `(
                                        SELECT
                                            COUNT(id)
                                        FROM
                                            incidencias
                                        WHERE
                                            activo = 1
                                        AND estado = 2                                       
                                        AND problema = 'false'
                                        AND id_tecnico_incidencia = `+idUsuario+`                                        
                                        AND DATE_SUB(
                                            fecha_vencimiento_incidencia,
                                            INTERVAL (
                                                SELECT
                                                    tiempo_incidencia
                                                FROM
                                                    solicitudes_notificaciones                                                
                                            )
                                         HOUR
                                        ) <= NOW()
                                       )+`;
                    count++;
                }
                if(validarPermiso(38)){
                    sqlProblemas = `(
                                        SELECT
                                            COUNT(id)
                                        FROM
                                            incidencias
                                        WHERE
                                            activo = 1
                                            AND estado = 2                                                                                      
                                            AND problema = 'true'
                                            AND id_tecnico_problema = `+idUsuario+`
                                    )+`;                                    

                    sqlProblemas1 = `(                                    
                                        SELECT
                                            COUNT(id)
                                        FROM
                                            incidencias
                                        WHERE
                                            activo = 1
                                        AND estado = 2                                        
                                        AND problema = 'true'
                                        AND id_tecnico_problema = `+idUsuario+`                                        
                                        AND DATE_SUB(
                                            fecha_vencimiento_problema,
                                            INTERVAL (
                                                SELECT
                                                    tiempo_problema
                                                FROM
                                                    solicitudes_notificaciones                                                
                                            )
                                         HOUR
                                        ) <= NOW()
                                     )+`;
                    count++;                  
                }
                if(validarPermiso(39)){
                    sqlServicios = `(
                                        SELECT
                                            COUNT(id)
                                        FROM
                                            servicios
                                        WHERE
                                            activo = 1
                                            AND estado = 2                                                                                       
                                            AND id_tecnico_servicio = `+idUsuario+`
                                    )+`;

                    sqlServicios1 =  `(
                                        SELECT
                                            COUNT(id)
                                        FROM
                                            servicios
                                        WHERE
                                            activo = 1
                                        AND estado = 2 
                                        AND id_tecnico_servicio = `+idUsuario+`                                       
                                        AND DATE_SUB(
                                            fecha_vencimiento_servicio,
                                            INTERVAL (
                                                SELECT
                                                    tiempo_servicio
                                                FROM
                                                    solicitudes_notificaciones                                               
                                            )
                                         HOUR
                                        ) <= NOW()
                                       )+`;
                    count++;                   
                }
                if(count > 0){
                    sqlPendientes = sqlIncidencias+sqlProblemas+sqlServicios;
                    sqlPendientes = sqlPendientes.slice(0,-1);
                    sqlPendientes = sqlPendientes+' AS suma';
                    sqlPendientes1 = sqlIncidencias1+sqlProblemas1+sqlServicios1;
                    sqlPendientes1 = sqlPendientes1.slice(0,-1);
                    sqlPendientes1 = sqlPendientes1+' AS suma';
                }                
            }            
            if(sqlPendientes !== ''){
                let sqlParams = {
                    sqlCols  : [ sqlPendientes ],                                                                                                                                       
                }
                cargarFilas('','',1,0,'','',sqlParams,'rows',globalState.getState().companyData[0].bd)
                .then(response => {
                    response = response.data;   
                    if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                        alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                    }
                    else{ 
                        let sqlParams = {
                            sqlCols : [ sqlPendientes1 ],                                                                                                       
                        }
                        cargarFilas('','',1,0,'','',sqlParams,'rows',globalState.getState().companyData[0].bd)
                        .then(response1 => {
                            response1 = response1.data;   
                            if(response1.msg === 'error'){//aqui no me dejara continuar si hay un error
                                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response1.detail);
                            }
                            else{ 
                                if(response[0].suma !== this.state.solicitudesPendientes){
                                    this.setState({solicitudesPendientes : response[0].suma}); 
                                    this.setState({iconAlert:'notifications_active'});
                                    this.setState({colorAlert:'red'});  
                                }
                                else if(response1[0].suma !== this.state.solicitudesPorVencer){
                                    this.setState({solicitudesPorVencer : response1[0].suma}); 
                                    this.setState({iconAlert:'notifications_active'});
                                    this.setState({colorAlert:'red'});  
                                }
                                else {
                                    this.setState({iconAlert:'notifications'});  
                                    this.setState({colorAlert:'white'}); 
                                } 
                            }
                        })
                        .catch(function (error) {
                            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
                        })                        
                    }     
                })
                .catch(function (error) {
                    alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
                })          
            }
        }.bind(this), 300000);         
    }    
    componentWillUnmount(){         
        this.unsubscribe1();
        this.unsubscribe2();
        clearInterval(timer);
    }	

    actualizarContainer(val,param){//carga dinamica del lado derecho	
    	this.setState({ componente: val });
    	this.setState({ parametro : param });		
    }	

    handleButtonMenu(e){//el boton que muestra esconde la barra de navegacion   
        var opcionCheck = true;
        if(this.state.checkMenu === true){
            opcionCheck = false;
        } 
        globalState.dispatch({
                            type   : "menuNavegacion",
                            params : opcionCheck
                        });
    }

    changeNavigationTrigger(e){
        //........
    }

  	render() {        
  		const { loading, redirect, vencimiento, passwordVencido,empresaVacia } = this.state;        
  		if (loading) {
        	return null;
      	}
      	if (redirect) {//sesion inexistente, cargar login
            alert('Su sesion ha finalizado, debe iniciar sesión de nuevo!');
        	return <Redirect to={'/'+configJson.mainPath} />;
      	}
        if(empresaVacia){//no hay empresa         
            return <Redirect to={'/'+configJson.mainPath} />;            
        }  
        if(vencimiento){//se vencio la licencia
            alert('Su licencia ha expirado! debe realizar su renovacion para continuar usando el software!');
            return <Redirect to={'/'+configJson.mainPath} />;            
        }
        if(passwordVencido){
            alert('Su password ha expirado! debe realizar el cambio para continuar usando el software!');
            return <Redirect to={'/'+configJson.mainPath} />; 
        }
        var date = new Date();
        var randomDate = date.getTime(); 
        var path =  window.location.protocol+"//"+window.location.hostname+configJson.filesRoute; 
        var folder = globalState.getState().companyData[0].bd+'/ikarosof_management_acceso.companies_adjuntos'; 
        let objImage = {maxHeight:'60px'};
        if(this.state.logoEmpresa){
            objImage = {backgroundImage:'none'};
        }	        
  	  	return (//carga el entorno del escritorio, barra de menu, barra superior y contenedor 	  		  
  	  		<div className="App">
                <input type="checkbox" id="navigationTrigger" className="navigationTrigger" checked={this.state.checkMenu} onChange={this.changeNavigationTrigger.bind(this)} />     
                <label id="labelnavigationTrigger" htmlFor="navigationTrigger" onClick={this.handleButtonMenu.bind(this)}>
                    <MaterialIcon size={24} icon="menu" invert/>                    
                </label>            
 		        <div id="pestanas" className="menuNavegacion" style={{backgroundColor:configJson.fondoMenu}}>
 		          	<div className="LogoCabecera" style={objImage}>
                    {
                        this.state.logoEmpresa !== '' ? 
                            <ImagenUsuario src={path+folder+'/'+this.state.logoEmpresa+'?'+randomDate} alt="Logo" />
                        : ''
                    }                        
                    </div>
 		            <OptionMenu funcionClick = {this.actualizarContainer} />                    
 		        </div>
                <div id="ContenedorDerechoEscritorio" className="ContenedorDerechoEscritorio">
 		            <div id="cabeceraEscritorio" className="cabeceraEscritorio" style={{backgroundColor:configJson.fondoCabecera}}>
 		                <NameUser className="ContentUser" history={this.props.history}/>
                        <div style={{float: 'right',paddingTop:'11px'}}>
                        {
                            this.state.displayAlert === "true" ?
                                <BotonNotificaciones icon={this.state.iconAlert} color={this.state.colorAlert}/> 
                            : ''
                        } 	  
                        </div>  		
 		            </div>
    		        <div id="contenidopestanas" className="contenidoEscritorio">
    		 	        <Container componente={this.state.componente} funcionClick = {this.actualizarContainer} parametro={this.state.parametro}/>
    		        </div>
                    <div className="divCopyright">© Ikaros Management Desarrollado por<br/><a href="https://ikarosofttechnology.com" target="_blank" className="linkPage" rel="noopener noreferrer">Ikarosoft Technology S.A.S</a><br/> Todos los derechos reservados</div>
                </div>                 
                <Modal show={this.state.showLoading} centered size="Auto">                    
                   <Modal.Body style={{textAlign:'center'}}><img src={loadingImg}  alt="Loading"/></Modal.Body>
               </Modal>                               		     
	       </div>   	
		);
  	}
}

export default Desktop