/**
* CLASS WindowUsuarioCapacitaciones
*
* Contiene el contenedor principal de las capacitaciones de un usuario
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Window from '../../window/Window';
import globalState from '../../configuration/GlobalState';
import DataGrid from '../../data_grid/DataGrid';

class WindowUsuarioCapacitaciones extends Component { 
    handleEdit(){

    } 
    colFuncion(idRow){        
        globalState.dispatch({
                type   : "windowAdjuntosCapacitaciones",
                params : {
                              visible : true,
                              params  : {
                                            idRow   : idRow, 
                                            table   : 'capacitaciones_usuarios',
                                            maxSize : 2048576,
                                            allowedExt : [".pdf",".jpg",".jpeg",".png"],                                           
                                            idWin   : "windowAdjuntosCapacitaciones",
                                            width   : '300px', 
                                            height  : '250px'//identificador de la ventana
                                        }
                         }
            }); 
    } 
  	render() {     
        let sqlParams = { 
                            sqlCols : [
                                'T1.id',
                                'C.nombre',
                                "DATE_FORMAT(CONCAT(C.fecha_inicio,' ',C.hora_inicio),'%Y-%m-%d %H:%i:%s') AS fecha_inicial", 
                                "DATE_FORMAT(CONCAT(C.fecha_final,' ',C.hora_final),'%Y-%m-%d %H:%i:%s') AS fecha_finalizacion",                                
                            ], 
                            fieldSearch : [
                                "C.nombre"
                            ],   
                            sqlJoin : [
                                " INNER JOIN capacitaciones AS C ON(C.id = T1.id_capacitacion)"
                            ],
                            sqlWhere : [
                                " AND id_usuario="+this.props.params.idRow
                            ],                                                    
                            filtroFechas : "false",                                                 
                        };                 
        return (//carga el componente que contiene la grilla de datos                           
            <div style={{position:"relative",height:'calc(100% - 30px)',overflow: 'auto',}}>            
                <DataGrid titulo='Capacitaciones' 
                          funcionClick={this.props.funcionClick} 
                          parametro={this.props.parametro}
                          colsData={[ 
                                        {
                                            type  : 'bd',
                                            label : 'Nombre',
                                            field : 'nombre'
                                        },
                                        {
                                            type  : 'bd',
                                            label : 'Fecha Inicial',
                                            field : 'fecha_inicial'
                                        },
                                        {
                                            type  : 'bd',
                                            label : 'Finalizacion',
                                            field : 'fecha_finalizacion'
                                        },
                                        {
                                            type  : 'manual',
                                            label : '',
                                            icon  : 'attach_file',
                                            colFuncion : this.colFuncion.bind(this)
                                        },
                                    ]} 
                          sqlParams = { sqlParams }
                          automatica="false"
                          botonNuevo="false"
                          funcionEdit={this.handleEdit.bind(this)}  
                          formFields={[
                                        {
                                            label : 'Nombre *',
                                            field : 'nombre',
                                            type  : 'text',
                                            validation : 'mayusculas',
                                            required : 'true',
                                            tooltip : 'Ingrese el nombre del tipo de documento',                                               
                                        }                                    
                                    ]}                     
                          apiField={'capacitaciones_usuarios'}
                          mainContainer='WindowUsuarioCapacitaciones'/> 
                <Window 
                    id = "windowAdjuntosCapacitaciones"                     
                    title='Ver Documentos'                   
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                /> 
            </div>     
        )
    } 
}

export default WindowUsuarioCapacitaciones