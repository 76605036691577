/**
* CLASS WindowVerEncuesta
*
* Contiene el componente que visualiza el detalle de la encuesta
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import { diccionario } from '../configuration/configuration.json';
import Button from 'react-bootstrap/Button';
import alertify from 'alertifyjs';

const { LabelsWindowVerEncuesta } = diccionario;

class WindowVerEncuesta extends Component {
    constructor(props) {        
        super(props);        
        this.state = {
            resultRows : [],
            idioma : globalState.getState().idioma,
            database : globalState.getState().companyData[0].bd 
        };      
    }
    componentWillMount(prevProps,prevState){  //refrescar el listado        
        let sqlParams = { 
                            sqlCols : [
                                "T1.id_pregunta",
                                "IF(T1.nombre_pregunta IS NOT NULL,T1.nombre_pregunta,E.nombre) AS pregunta",
                                "T1.puntaje",
                                "T1.comentarios"                                              
                            ], 
                            sqlJoin : [
                                "LEFT JOIN encuesta_satisfaccion_preguntas AS E ON (E.id = T1.id_pregunta)",                                 
                            ],
                            sqlInactive : 'true',                             
                            sqlOrderBy: 'T1.id_pregunta DESC',                                          
                            sqlWhere : [ " T1.id_solicitud = "+this.props.params.idRow+" AND T1.tabla = '"+this.props.params.table+"'" ],                                
                        }
        cargarFilas('encuesta_satisfaccion_respuestas','',100,0,'','',sqlParams,'rows',this.state.database)      
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowVerEncuesta.error_alert1[this.state.idioma]+': '+response.detail);
            }
            else{               
                this.setState({resultRows : response});
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowVerEncuesta.error_alert1[this.state.idioma]+': '+error);
        })     
    }
    handleBotonAtras(){
        this.props.params.parametro.funcionClick('WindowTrazabilidad'); 
    }
  	render() {                    
  	  	return (<div id="contenedorTrazabilidad" style={{paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'270px',overflowY : 'auto' }}>   
                    <div>
                    {
                        this.state.resultRows.map((content,i) => { 
                            content.puntaje  = LabelsWindowVerEncuesta.puntaje[this.state.idioma]+' '+content.puntaje;
                            if(content.id_pregunta === 0){
                                content.pregunta = LabelsWindowVerEncuesta.observaciones[this.state.idioma];
                                content.puntaje  = content.comentarios;
                            }                            
                            return <div key = {i} style={{paddingBottom:'5px'}}>
                                        <div style={{width:'100%'}}>
                                            <div style={{fontWeight:'Bold',fontSize:'11px'}}>{content.pregunta}</div>
                                            <div style={{fontSize:'11px'}}>{content.puntaje}</div>
                                        </div>                                                                               
                                   </div>                            
                        })
                    }
                    </div>
                    <div style={{paddingBottom:'5px'}}>
                        <div style={{width:'100%'}}>
                            <div style={{fontWeight:'Bold',fontSize:'11px'}}>{LabelsWindowVerEncuesta.promedio[this.state.idioma]} {this.props.parametro.puntaje}</div>                            
                        </div>                                                                               
                    </div>
                    <Button style={{margin: '5px 0'}} className="float-left mr-3" variant="secondary" onClick={this.handleBotonAtras.bind(this)}>
                        {LabelsWindowVerEncuesta.atras[this.state.idioma]}
                    </Button>                                          
                </div>);
  	}
}

export default WindowVerEncuesta