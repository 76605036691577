/**
* CLASS Dashboard
*
* Contiene el contenedor principal de la dashboard
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DatePicker from 'react-date-picker';
import globalState from '../configuration/GlobalState';
import { diccionario } from '../configuration/configuration.json';
import MaterialIcon from 'material-icons-react';
import Window from '../window/Window';
import TabPanel from '../tab_panel/TabPanel';
import alertify from 'alertifyjs';
import {cargarFilas} from '../api_calls/ApiCalls';
import {validarPermiso} from '../configuration/GlobalFunctions';

let timer = null; 
const { LabelsDashboard } = diccionario;

class Dashboard extends Component {
    constructor(props){
        super(props);
        let idArea = globalState.getState().userData[0].id_area;
        let filtroArea = idArea;
        if(!idArea > 0){
            filtroArea = '';
        }
        let date1 = new Date();
        date1.setDate(date1.getDate() - 30);
        let date2 = new Date();  
        this.state = {
            date1 : date1,
            date2 : date2,
            dateH : date2,
            id_usuario : 0,
            usuario : '',
            rol_usuario : 0,            
            idArea : idArea,
            filtroArea : filtroArea,            
            arrayAreas : [],
            idioma : globalState.getState().idioma,
            permisos : {
                permisoVerTecnicos : validarPermiso(49),
                indicadoresIncidencias : validarPermiso(46),
                indicadoresProblemas : validarPermiso(47),
                indicadoresServicios : validarPermiso(48),
                indicadoresClientes : validarPermiso(75),
                indicadoresProveedores : validarPermiso(76),
                indicadoresCapacitaciones : validarPermiso(77),
            },
            refreshDashboard : 0,
            database : globalState.getState().companyData[0].bd
        }        
        if(!idArea > 0){
            let sqlParams = {
                                sqlCols : [
                                    'id',
                                    'nombre'                                
                                ],                                                                                                                                                           
                            };                                                                
            cargarFilas('areas_servicio','',100,0,'','',sqlParams,'rows',this.state.database)
            .then(response => {
                response = response.data;
                if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                }
                else{
                    this.setState({arrayAreas : response});
                }
            })
            .catch(function (error) {
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            })
        }        
    }
    //manejadores de los datepicker
    changeDate1(val){
        if(val <= this.state.date2){        
            this.setState({ date1 : val });
        }        
    }
    changeDate2(val){        
        this.setState({ date2 : val });        
    }
    componentDidMount() {//cada que se monte el escritorio debe alistar la ventana del loading      
        //... 
        this.unsubscribe1 = globalState.subscribe( ()=>{ 
            if(globalState.getState().type==="dashboardIdFuncionario"){                             
                this.setState({id_usuario  : globalState.getState().dashboardIdFuncionario.id_usuario}); 
                this.setState({usuario  : globalState.getState().dashboardIdFuncionario.nombre_usuario});  
                this.setState({rol_usuario  : globalState.getState().dashboardIdFuncionario.rol_usuario});                                            
            }
        });                        
    }    
    componentWillUnmount(){         
        this.unsubscribe1();
        clearInterval(timer);        
    }
    handleFuncionario(){        
        globalState.dispatch({
            type   : "windowSeleccionFuncionario",
            params : {
                          visible : true,
                          params  : {
                                        idField : "dashboardIdFuncionario",
                                        where   : ' AND id_area > 0 AND id_area=\''+this.state.filtroArea+'\'',  
                                        idWin   : "windowSeleccionFuncionario",//identificador de la ventana
                                        width   : '500px', 
                                        height  : '270px',                                        
                                    }
                     }
        });
    }
    resetSelectFuncionario(){
        globalState.dispatch({//cargamos los datos de los permisos
            type   : "dashboardIdFuncionario",
            params : { id_usuario:0,nombre_usuario:'',rol_usuario:0 }
        });
        setTimeout(function(){
            this.setState({ refreshDashboard : this.state.refreshDashboard+1 });
        }.bind(this),2000);        
    }

    refreshDashboard(){
        this.setState({ refreshDashboard : this.state.refreshDashboard+1 });    
    }
    timer = setInterval(function(){
        this.setState({ refreshDashboard : this.state.refreshDashboard+1 });
    }.bind(this),60000);      

    handleChangeArea(e){
        var idArea = e.target.value;
        this.setState({ filtroArea : idArea });
    }
  	render() {      
        //LOS TIPOS DE GRAFICO SON PieChart y ColumnChart                    
        return ( 
            <div>
                <div id="DashboardFiltros">                    
                    <div id="form_content_field_PanelCampos_Reports_Infotipri_desde" materialdesign="false" className="formContentField" style={{width:'270px', minHeight:'30px', display:'block'}}>
                        <div id="form_label_PanelCampos_Reports_Infotipri_desde" className="formLabelReport" style={{width:'90px', paddingTop: '8px',float:'left'}}>{LabelsDashboard.FechaInicioDashboard[this.state.idioma]}</div>
                        <div id="form_field_PanelCampos_Reports_Infotipri_desde" className="formDateReport" style={{width:'180px',left:'100px',paddingTop: '3px'}}>                                                    
                            <DatePicker onChange={ this.changeDate1.bind(this) } format={"y-MM-dd"} value={this.state.date1} style={{  }} clearIcon={null} maxDate={this.state.date2}/>
                         </div>
                    </div>
                    <div data-role="div-empty"></div>
                    <div id="form_content_field_PanelCampos_Reports_Infotipri_hasta" materialdesign="false" className="formContentField" style={{width:'270px',minHeight:'30px', display:'block'}}>
                        <div id="form_label_PanelCampos_Reports_Infotipri_hasta" className="formLabelReport" style={{width:'90px', paddingTop: '8px',float:'left'}}>{LabelsDashboard.FechaFinalDashboard[this.state.idioma]}</div>
                        <div id="form_field_PanelCampos_Reports_Infotipri_hasta" className="formDateReport" style={{width:'auto',left:'100px',paddingTop: '3px'}}>                                                    
                            <DatePicker onChange={ this.changeDate2.bind(this) } format={"y-MM-dd"} value={this.state.date2} style={{  }} clearIcon={null} maxDate={this.state.dateH} minDate={this.state.date1}/>
                        </div>                          
                    </div>
                    <div data-role="div-empty"></div>
                    {
                        !this.state.idArea > 0 ?
                            <div id="form_content_field_PanelCampos_Reports_Infotipri_area" materialdesign="false" className="formContentField" style={{width:'270px',minHeight:'30px', display:'block'}}>
                                <div id="form_label_PanelCampos_Reports_Infotipri_area" className="formLabelReport" style={{width:'90px', paddingTop: '8px',float:'left'}}>{LabelsDashboard.AreaFilterDashboard[this.state.idioma]}</div>
                                <div id="form_field_PanelCampos_Reports_Infotipri_area" className="formDateReport" style={{width:'auto',left:'100px',paddingTop: '3px'}}>                                                    
                                    <select style={{border:'thin solid gray',width:'129px',float:'left',height:'27px'}} defaultValue="" onChange={this.handleChangeArea.bind(this)} >
                                        <option value="">Seleccione...</option>
                                        {
                                            this.state.arrayAreas.map((options,i) => {
                                                return <option value={options.id}  key= {i}>{options.nombre}</option>
                                            })
                                        }
                                    </select>                            
                                </div>                          
                            </div>
                        : ''
                    }                    
                    {
                        this.state.permisos.permisoVerTecnicos === true && this.state.filtroArea > 0 ?
                            <div id="form_content_field_PanelCampos_Reports_Infotipri_hasta" materialdesign="false" className="formContentField" style={{width:'250px',minHeight:'30px', display:'block'}}>
                                <div id="form_label_PanelCampos_Reports_Infotipri_hasta" className="formLabelReport" style={{width:'90px', paddingTop: '8px',float:'left'}}>{LabelsDashboard.FuncionarioFilterDashboard[this.state.idioma]}</div>
                                <div id="form_field_PanelCampos_Reports_Infotipri_hasta" className="formDateReport" style={{width:'175px',left:'100px',paddingTop: '3px'}}>                                                    
                                    <input value={this.state.usuario} type="text" style={{border:'thin solid gray',width:'129px',float:'left',height:'27px'}} onClick={this.handleFuncionario.bind(this)} readOnly/>                                    
                                    <div style={{float:'left',cursor:'pointer'}} onClick={this.resetSelectFuncionario.bind(this)} title="Eliminar seleccion de funcionario">    
                                        <MaterialIcon id="iconColumna" color="red" size={26} icon="remove_circle"/>
                                    </div>
                                </div>                          
                            </div>
                        : ''                              
                    }
                    <div style={{float:'left',cursor:'pointer',paddingTop:'2px'}} onClick={this.refreshDashboard.bind(this)} title="Actualizar Dashboard">
                        <MaterialIcon id="iconColumna" size={30} icon="refresh" color="blue"/>
                    </div>              
                </div> 
                <TabPanel 
                    id = "tabPanelDashboard"
                    defaultActive = "tab0"
                    defaultComponente = "DashboardContainer"
                    items={[
                                {
                                    id         : "tab0",
                                    title      : LabelsDashboard.TitleTab0FilterDashboard[this.state.idioma],
                                    icon       : "pie_chart",                                   
                                    componente : "DashboardContainer",
                                    params     : {
                                        date1 : this.state.date1,
                                        date2 : this.state.date2,
                                        usuario : this.state.id_usuario,
                                        rol_usuario : this.state.rol_usuario,
                                        refreshDashboard : this.state.refreshDashboard,
                                        filtroArea : this.state.filtroArea,
                                        permisos : this.state.permisos
                                    }
                                },                                
                                {
                                    id         : "tab1",                                    
                                    title      : LabelsDashboard.TitleTab1FilterDashboard[this.state.idioma],
                                    icon       : "timeline",                                    
                                    componente : "TrendContainer",
                                    params     : {
                                        date1 : this.state.date1,
                                        date2 : this.state.date2,
                                        usuario : this.state.id_usuario,
                                        rol_usuario : this.state.rol_usuario,
                                        refreshDashboard : this.state.refreshDashboard,
                                        filtroArea : this.state.filtroArea,                                        
                                        permisos : this.state.permisos
                                    }
                                },
                                {
                                    id         : "tab2",                                    
                                    title      : LabelsDashboard.TitleTab2FilterDashboard[this.state.idioma],
                                    icon       : "description",                                    
                                    componente : "ReportContainer",
                                    params     : {
                                        date1 : this.state.date1,
                                        date2 : this.state.date2,
                                        usuario : this.state.id_usuario,
                                        rol_usuario : this.state.rol_usuario,
                                        refreshDashboard : this.state.refreshDashboard,
                                        filtroArea : this.state.filtroArea,                                        
                                        permisos : this.state.permisos
                                    }
                                },
                                {
                                    id         : "tab3",
                                    title      : LabelsDashboard.TitleTab3FilterDashboard[this.state.idioma],
                                    icon       : "pie_chart",                                   
                                    componente : "GeneralContainer",
                                    params     : {
                                        date1 : this.state.date1,
                                        date2 : this.state.date2,
                                        usuario : this.state.id_usuario,
                                        rol_usuario : this.state.rol_usuario,
                                        refreshDashboard : this.state.refreshDashboard,
                                        filtroArea : this.state.filtroArea,
                                        permisos : this.state.permisos
                                    }
                                }
                            ]}
                />          
                <Window 
                    id = "windowSeleccionFuncionario"                      
                    title={LabelsDashboard.TitleWindowSeleccionFuncionario[this.state.idioma]}                    
                    tbar="false"
                    componente="WindowSeleccionFuncionario"
                    params="" 
                />   
            </div>
        );
    } 
}

export default Dashboard