/**
* CLASS WindowResetPassword
*
* Contiene el componente para resetear el password
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import configJson,{ diccionario } from '../configuration/configuration.json';
import {divMouseOver,divMouseOut,validarEmail,modalLoadingRstPwd,vacio} from '../configuration/GlobalFunctions';
import {sendEmailPassword,cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';
import './windowResetPassword.css';

const { LabelsWindowResetPassword } = diccionario;

class WindowResetPassword extends Component {
    constructor(props) {        
        super(props);   
        this.state = {
            email : this.props.params.email,
            idioma : globalState.getState().idioma,
        };            
    }  
    componentWillMount(){
        //en caso de no tener idioma        
        if(this.state.idioma === undefined){
            let sqlParams = { 
                                sqlCols : [
                                    "idioma",                                                                            
                                ],                                         
                                sqlWhere : [
                                    " AND bd='"+this.props.params.bd+"'"
                                ]                            
                            }
            cargarFilas('ikarosof_management_acceso.companies','',1,0,'','',sqlParams,'rows',this.props.params.bd)
            .then(response => {             
                response = response.data;
                if(response.msg === 'error'){
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                } 
                else{
                    this.setState({idioma : response[0].idioma});
                }
            })
            .catch(function (error) {
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            });
        }        
    }  
    handleContinuarBtn(e){
        var email = this.state.email; 
        if(!validarEmail(email) || vacio(email) === false){
            alertify.alert('Error!', 'No es una cuenta de Email Valida');
        }
        else{
            var modalLoading = "modalLoading";//con esto se que modal abrir
            var opcion = 'reset';
            if(this.props.params.idWin === 'windowResetPassword1'){
                modalLoading = "modalLoading1";
                opcion = 'restablecer';
            }
            modalLoadingRstPwd(true,modalLoading);
            //enviar al correo la recuperacion de la contraseña        
            sendEmailPassword(email,opcion,this.props.params.bd).then(response => { 
                modalLoadingRstPwd(false,modalLoading);            
                response = response.data;
                if(response.msg === 'error'){
                    alertify.alert('Error!', 'Ha ocurrido un error enviando el correo a '+email+'!<br />Codigo de Error: '+response.detail); 
                }
                else if(response.msg === 'not_found'){
                    alertify.alert('Error!', 'El usuario '+email+' No se encuentra en la base de datos!'); 
                }
                else if(response.msg === 'mail_error'){                
                    alertify.alert('Error!', 'Ha ocurrido un error enviando el correo, verifique la conexion a internet y configuracion SMTP en la aplicacion!<br /><br />Detalles del Error: '+JSON.stringify(response.detail, null, 4));
                }
                else {
                    let sqlParams = { 
                                        sqlCols : [
                                            "T1.tiempo_token",                                                                            
                                        ],                                         

                                        sqlJoin : [ "INNER JOIN users AS U ON(U.email = '"+email+"')" ],
                                    }
                    cargarFilas('politicas_seguridad','',1,0,'','',sqlParams,'rows',this.props.params.bd)
                    .then(response => {             
                        response = response.data;
                        if(response.msg === 'error'){
                            alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                        } 
                        else{
                            var titulo = ' 1 '+LabelsWindowResetPassword.LabelSuccess1TitleResetPassword[this.state.idioma];
                            if(response.length > 0){
                                titulo = response[0].tiempo_token+' '+LabelsWindowResetPassword.LabelSuccess2TitleResetPassword[this.state.idioma];
                            }
                            alertify.alert(LabelsWindowResetPassword.LabelSuccessMsg1ResetPassword[this.state.idioma],LabelsWindowResetPassword.LabelSuccessMsg2ResetPassword[this.state.idioma]+email+'!<br />'+LabelsWindowResetPassword.LabelSuccessMsg3ResetPassword[this.state.idioma]+titulo);  
                        }
                    })
                    .catch(function (error) {
                        alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
                    });
                    
                    globalState.dispatch({
                        type   : this.props.params.idWin,
                        params : {
                            visible : false,                    
                        }
                    });               
                }
            })
            .catch(function (error) {
                modalLoadingRstPwd(false,modalLoading); 
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            });
        }        
    }
    handleChangeEmail(e){
        this.setState({email : e.target.value});
    }        
  	render() { 
        var disabled = 'disabled';
        if(this.props.params.idWin === 'windowResetPassword1'){
            disabled = '';
        }         
  	  	return (
                <div id="WindowContentResetPassword">                   
                    <div id="ContentResetPassword">
                        <div className="text">{LabelsWindowResetPassword.LabelBodyWindowResetPassword[this.state.idioma]}</div>
                        <div className="formContentField" style={{width:'calc(100% - 20px)',minHeight:'30px', display:'block', maxWidth : '100%'}}> 
                            <input type="text" style={{width:'100%'}} className="withLabel" name="username" value={this.state.email} disabled={disabled} onChange={this.handleChangeEmail.bind(this)} /> 
                         </div>
                        <div id="divBtnReset" className="divBtnReset" style={{width:'50px'}}> 
                            <button 
                                id="resetPsswdBtn" 
                                style={{backgroundColor:configJson.windowColor}} 
                                className="save" 
                                onClick={this.handleContinuarBtn.bind(this)} 
                                onMouseOut={divMouseOut.bind(this,"resetPsswdBtn",configJson.windowColor)} 
                                onMouseOver={divMouseOver.bind(this,"resetPsswdBtn",configJson.windowColor)}
                            >
                                {LabelsWindowResetPassword.LabelBtnWindowResetPassword[this.state.idioma]}
                            </button> 
                        </div>                        
                    </div>
                </div>  				
			  );
  	}
}

export default WindowResetPassword