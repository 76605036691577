/**
* CLASS ActivosTiposCamposFicha
*
* Contiene el componente que contiene la configuracion de la ficha tecnica
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Window from '../../window/Window';
import globalState from '../../configuration/GlobalState';
import DataGrid from '../../data_grid/DataGrid';
import {cargarFilas} from '../../api_calls/ApiCalls';
import TBar from '../../tbar/TBar';
import alertify from 'alertifyjs';

class ActivosTiposCamposFicha extends Component {    
    retrocederPanel(){
        this.props.funcionClick('ActivosTipos');
    }
    async colFuncion(idCampo){
        try{
            globalState.dispatch({
                type   : "idActivoCampoFicha",
                params : idCampo
            });                    
            let database = globalState.getState().companyData[0].bd;
            let sqlParams = {
                                sqlCols : [
                                    'tipo',
                                ],
                                sqlWhere : [ " AND id="+idCampo ],                   
                            }
            let res = await cargarFilas('activos_tipos_campos_ficha','',1,0,'','',sqlParams,'rows',database);
            let response = res.data;
            if(response[0].tipo === 'select'){
                globalState.dispatch({
                    type   : "windowConfigurarCampo",
                    params : {
                                  visible : true,
                                  params  : {
                                                idCampo : idCampo,                                            
                                                idWin   : "windowConfigurarCampo",                                            
                                                width   : '350px', 
                                                height  : '70%'//identificador de la ventana
                                            }
                             }
                }); 
            } 
            else if(response[0].tipo !== 'date'){
                globalState.dispatch({
                    type   : "windowConfigurarCampo2",
                    params : {
                                  visible : true,
                                  params  : {
                                                idCampo : idCampo,
                                                funcionClick : this.props.funcionClick,                                            
                                                idWin   : "windowConfigurarCampo2",                                            
                                                width   : '350px', 
                                                height  : '220px'//identificador de la ventana
                                            }
                             }
                });
            }           
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message); 
        }
    }
  	render() {         
        let sqlParams = {                             
                            sqlCols : [
                                "id",
                                "id_tipo",
                                "nombre",
                                "tipo",
                                "validacion",
                                "longitud",                                
                                `CASE
                                   WHEN tipo = 'text' THEN 'TEXTO DE UNA SOLA LINEA' 
                                   WHEN tipo = 'textarea' THEN 'TEXTO DE VARIAS LINEAS'
                                   WHEN tipo = 'select' THEN 'LISTA DESPLEGABLE'
                                   WHEN tipo = 'date' THEN 'FECHA'
                                   ELSE ''
                                END AS nom_tipo`, 
                                `CASE
                                   WHEN validacion = 'sin_validacion' THEN 'SIN VALIDACION' 
                                   WHEN validacion = 'mayusculas' THEN 'LETRAS MAYUSCULAS'
                                   WHEN validacion = 'texto_mayusculas' THEN 'NUMEROS Y MAYUSCULAS'
                                   WHEN validacion = 'entero' THEN 'ENTERO'
                                   WHEN validacion = 'numero_real' THEN 'NUMERO REAL'
                                   ELSE ''
                                END AS nom_validacion`,                                
                                "IF(tipo='date','','settings') AS icono_tipo"
                            ],
                            fieldSearch : [
                                'nombre',                                
                            ],                            
                            sqlWhere : [
                                ' AND id_tipo='+globalState.getState().idTipoActivo.idRow
                            ]                                                                       
                        };              
  	  	return (
                <div>
                    <TBar
                        items={[
                                  {
                                      type : 'boton',
                                      icon : 'arrow_back',
                                      width : '100px',
                                      height : '60px',
                                      title : 'Atras',
                                      display : true,
                                      function : this.retrocederPanel.bind(this)
                                  },
                              ]}
                        length = '1'
                    />
                    <div style={{top: "60px",position:"relative"}}>  
                        <DataGrid titulo={'Ficha Tecnica '+globalState.getState().idTipoActivo.nombre} 
                                  funcionClick={this.props.funcionClick}   
                                  parametro={this.props.parametro} 
                                  colsData={[ 
                                                {
                                                    type  : 'bd',
                                                    label : 'Nombre',
                                                    field : 'nombre'
                                                },  
                                                {
                                                    type  : 'bd',
                                                    label : 'Nombre',
                                                    field : 'nom_tipo'
                                                },
                                                {
                                                    type  : 'bd',
                                                    label : 'Validacion',
                                                    field : 'nom_validacion'
                                                },
                                                {
                                                    type  : 'bd',
                                                    label : 'Longitud',
                                                    field : 'longitud'
                                                },
                                                {
                                                    type  : 'manual',
                                                    label : '',
                                                    icon  : '',
                                                    field : 'icono_tipo',                                                    
                                                    colFuncion : this.colFuncion.bind(this)
                                                },                                                                                               
                                            ]}
                                  sqlParams = { sqlParams }                       
                                  automatica="true"
                                  botonNuevo="true"
                                  formFields={[
                                                {
                                                    label : 'Nombre *',
                                                    field : 'nombre',
                                                    type  : 'text',
                                                    validation : 'texto_mayusculas',
                                                    required : 'true',
                                                    longitud : 30,
                                                    tooltip : 'Ingrese el nombre del campo'
                                                },                                                 
                                                {
                                                    label : 'Tipo *',
                                                    field : 'tipo',
                                                    type  : 'select',
                                                    validation : '',
                                                    required : 'true',
                                                    dinamic : 'false',
                                                    valueName : 'nombre',
                                                    options :  [{
                                                                    id: 'text',
                                                                    nombre: 'TEXTO DE UNA SOLA LINEA',
                                                                },
                                                                {
                                                                    id: 'textarea',
                                                                    nombre: 'TEXTO DE VARIAS LINEAS',
                                                                },
                                                                {
                                                                    id: 'date',
                                                                    nombre: 'FECHA',
                                                                },
                                                                {
                                                                    id: 'select',
                                                                    nombre: 'LISTA DESPLEGABLE',
                                                                }],
                                                    tooltip : 'Seleccione el tipo de campo',                                                                                            
                                                },                                                 
                                                {
                                                    label : '',
                                                    field : 'id_tipo',
                                                    type  : 'hidden',
                                                    validation : '',
                                                    value : globalState.getState().idTipoActivo.idRow,
                                                    required : 'true'                                        
                                                },                                                                              
                                            ]}                     
                                  apiField={'activos_tipos_campos_ficha'}
                                  mainContainer='ActivosTiposCamposFicha'/> 
                    </div>
                    <Window 
                        id = "windowConfigurarCampo"                      
                        title='Configurar Campo'                    
                        tbar="false"
                        componente="WindowConfigurarCampo"
                        params="" 
                    /> 
                    <Window 
                        id = "windowConfigurarCampo2"                      
                        title='Configurar Campo'                    
                        tbar="false"
                        componente="WindowConfigurarCampo2"
                        params="" 
                    />                                  
                </div>  				
			  );
  	}
}

export default ActivosTiposCamposFicha