/**
* CLASS WindowRolesPermisos
*
* Contiene el componente que lista los permisos del rol
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas} from '../../api_calls/ApiCalls';
import {guardaPermisos} from '../../api_calls/ApiCalls';
import {modalLoading} from '../../configuration/GlobalFunctions';
import TBar from '../../tbar/TBar';
import globalState from '../../configuration/GlobalState';
import alertify from 'alertifyjs';

class WindowRolesPermisos extends Component {
    constructor(props) {        
        super(props);   
        this.state = {
            objPermisos : '',
            database : globalState.getState().companyData[0].bd
        };        
    } 
    componentWillMount(){
        var idRol = this.props.params.idRol; 
        let rolUser = globalState.getState().idRol;       
        let arrayModulos = globalState.getState().modulosAplicacion;
        let strModulos = '';
        for(let i in arrayModulos){
            strModulos += 'id_modulo='+i+' OR ';
        }
        strModulos = '('+strModulos.slice(0,-4)+')';

        let joinSql = "";
        if(rolUser > 1){
            joinSql = "INNER JOIN roles_permisos AS R1 ON(R1.id_permiso = T1.id AND R1.id_rol = "+rolUser+")";
        }

        let sqlParams = { 
                            sqlCols : [ 
                                        "T1.id",
                                        "T1.nombre",
                                        "T1.nivel",
                                        "R.id AS checked"
                            ],                            
                            sqlJoin : [ 
                                        "LEFT JOIN roles_permisos AS R ON(R.id_permiso = T1.id AND R.id_rol = "+idRol+")",
                                        joinSql
                                      ], 
                            sqlWhere : [ "AND "+strModulos ],                               
                            sqlOrderBy : "T1.id_modulo,T1.orden"
                        }
        cargarFilas('permisos','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => { 
            response = response.data;            
            if(response.msg === 'error'){//aqui no me dejara continuar si la empresa noe xiste
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail); 
            }
            else{
                this.setState({objPermisos:response},()=>{                    
                    response.forEach((objPermisos,i) => {//chequear los que tengan el permiso activido en el rol
                        var checked = false;                        
                        if(objPermisos.checked > 0){
                            checked = true;
                        }                        
                        this.setState({ ['permiso_'+objPermisos.id]: checked });  
                    });                    
                });
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    } 
    handleCheckBox(e){//control del check de los checkbox
        var checkBox = e.target.name;
        var checked  = e.target.checked;        
        this.setState({ [checkBox]: checked });          
    }
    guardaPermisos(){//almacena los permisos en un array para enviarlos        
        var arrayPermisos = [];
        var id = 0;
        for(var i in this.state.objPermisos){
            id = this.state.objPermisos[i].id;
            if(this.state['permiso_'+id] === true){
                arrayPermisos.push(id);                
            }
        }
        modalLoading(true);
        guardaPermisos(this.props.params.idRol,arrayPermisos,this.state.database).then(response => { 
            modalLoading(false);
            globalState.dispatch({
                type   : "windowRolesPermisos",
                params : {
                              visible : false,                              
                         }
            });
        })
        .catch(function (error) {
            modalLoading(false);
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    }    
  	render() {             
  	  	return (<div style={{height:'calc(100% - 30px)'}}>
                    <TBar
                        items={[
                                 {
                                      type : 'boton',
                                      icon : 'save',
                                      width : '100px',
                                      height : '60px',
                                      title : 'Guardar',
                                      function : this.guardaPermisos.bind(this)
                                  },                              
                              ]}
                        length = '1'
                    />
                    <div id="contenedorPermisos" style={{position:"relative",top:'60px',paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'calc(100% - 60px)',overflowY : 'auto' }}>   
                    {
                        (this.state.objPermisos !== '') ?
                            this.state.objPermisos.map((objPermisos,i) => {
                                var padding = 5;
                                var fontWeight = 'bold';
                                if(objPermisos.nivel > 1){
                                    padding = objPermisos.nivel*10;
                                    fontWeight = 'normal';
                                }                                                         
                                return <div key={i} style={{width:'100%',height:'24px'}}>
                                            <div style={{float:'left'}}>
                                                <input name={'permiso_'+objPermisos.id} type="checkbox" onChange={this.handleCheckBox.bind(this)} checked={this.state['permiso_'+objPermisos.id] || false } />
                                            </div>
                                            <div style={{paddingLeft:padding+'px',float:'left',fontWeight:fontWeight,fontSize:'11px' }}>
                                                {objPermisos.nombre} 
                                            </div>
                                        </div>
                            })
                        : ''
                    }  
                    </div>
                </div>  				
			  );
  	}
}

export default WindowRolesPermisos