/**
* CLASS Servicios
*
* Contiene el contenedor principal de los Servicios
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import globalState from '../configuration/GlobalState';
import {asignarSolicitud} from '../api_calls/ApiCalls';
import { diccionario } from '../configuration/configuration.json';
import Window from '../window/Window';
import alertify from 'alertifyjs';
import {validarPermiso,fechaMysql,validarModulo} from '../configuration/GlobalFunctions';

const { LabelsServicios } = diccionario;

class Servicios extends Component {
    constructor(props){
        super(props);
        let permisoGestion = 'none';
        if(validarPermiso(25) === true){ 
            permisoGestion = 'block';
        }
        this.state = {            
            btnGestionar    : permisoGestion,
            database : globalState.getState().companyData[0].bd,
            idioma : globalState.getState().idioma,
        }       
    }
    btnGestionServicio(idRow){
        globalState.dispatch({
            type   : "windowGestionServicio",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,
                                        funcionClick : this.props.funcionClick,
                                        table : 'servicios',
                                        tipo  : 'servicio',
                                        componente : 'Servicios',
                                        idWin : "windowGestionServicio",
                                        width : '340px', 
                                        height: '390px'//identificador de la ventana
                                    }
                     }
        });            
    }
    btnTrazabilidadServicio(idRow){
        globalState.dispatch({
            type   : "windowTrazabilidadServicio",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,
                                        table : 'servicios',
                                        idWin : "windowTrazabilidadServicio",
                                        width : '320px',  
                                        height: '400px'//identificador de la ventana
                                    }
                     }
        });            
    }
    colFuncion(idRow){        
        globalState.dispatch({
                type   : "windowTrazabilidadServicio1",
                params : {
                              visible : true,
                              params  : {
                                            idRow  : idRow,
                                            table  : 'servicios',
                                            idWin  : "windowTrazabilidadServicio1",
                                            width  : '320px', 
                                            height : '400px'//identificador de la ventana
                                        }
                         }
            }); 
    } 
    colFuncion2(idRow){        
        globalState.dispatch({
                type   : "windowArchivosAdjuntosServicios",
                params : {
                              visible : true,
                              params  : {
                                            idRow  : idRow,
                                            table  : 'servicios',
                                            idWin  : "windowArchivosAdjuntosServicios",
                                            width  : '300px', 
                                            height : '250px'//identificador de la ventana
                                        }
                         }
            }); 
    }    
    //una vez se inserta el servicio se procede a asignar el tecnico
    asignarServicio(id){ 
        let idioma = this.state.idioma;
        asignarSolicitud(id,'servicio',this.state.database).then(response => {   
            response = response.data;         
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsServicios.error_alert1[idioma]+': '+response.detail);
            }
            else if(response.msg === 'no_capacidad'){
                alertify.alert('Error!', LabelsServicios.error_alert2[idioma], this.showAlertSuccess(response.consec));
            }
            else if(response.msg === 'no_sla'){                
                alertify.alert('Error!', LabelsServicios.error_alert3[idioma], this.showAlertSuccess(response.consec));
            }
            else if(response.msg === 'jefe_no_encontrado'){
                alertify.alert('Error!', LabelsServicios.error_alert5[idioma], this.showAlertSuccess(response.consec));
            }
            else if(response.msg === 'no_roles'){ 
                alertify.alert('Error!',LabelsServicios.error_alert4[idioma], this.showAlertSuccess(response.consec));
            }
            else if(response.msg === 'mail_error'){                
                alertify.alert('Error!', LabelsServicios.error_alert6[idioma]+': '+JSON.stringify(response.detail, null, 4), this.showAlertSuccess(response.consec));
            } 
            else{
                alertify.alert('Aviso!', LabelsServicios.success_alert[idioma]+' Ticket No. '+response.consec+'!')
            }
            this.props.funcionClick('Servicios');            
        })
        .catch(function (error) {            
            alertify.alert('Error!', LabelsServicios.error_alert1[idioma]+': '+error);
        });
    }
    showAlertSuccess(consec,e){//alert con el ticket       
        alert(LabelsServicios.success_alert[this.state.idioma]+' Ticket No. '+consec+'!');
    }          
  	render() { 
        //privacidad de la visualizacion
        let idUser  = globalState.getState().userData[0].id;
        let externo = globalState.getState().userData[0].externo;
        let idioma  = this.state.idioma;
        let whereUser = '';
        if(validarPermiso(49)){
            whereUser = '';
        } 
        else if(validarPermiso(39)){
            whereUser = ' AND (id_usuario = '+idUser+' OR id_tecnico_servicio = '+idUser+')';
        }
        else{
            whereUser = ' AND id_usuario = '+idUser;
        }
        //validar las areas de servicio habilitadas
        let whereArea = " AND (tipo='todas' OR tipo='interna')";
        if(externo === 'true'){
            whereArea = " AND (tipo='todas' OR tipo='externa')";
        } 
        //si tiene el permiso podrá ver todas las areas de servicio
        if(validarPermiso(34)){
            whereArea = "";
        }    

        // Id del area, si está vinculado a un area de servicio, solo podrá ver lo de su area
        let idArea = globalState.getState().userData[0].id_area;        
        let whereArea2 = '';
        if(idArea > 0){
            whereArea2 += " AND id = "+idArea;
        }

        let sqlParams = {
                            sqlCols : [
                                'T1.id',                                
                                'T1.id_activo',
                                'A.nombre AS nombre_activo',
                                'T1.id_activo',
                                'T1.prioridad',
                                'T1.impacto',
                                'T1.id_categoria',
                                'T1.id_subcategoria',
                                'T1.urgencia',
                                'T1.descripcion',
                                'SP.html AS nom_prioridad',
                                'AR.nombre AS nombre_area',
                                'T1.id_area', 
                                'T1.asunto', 
                                "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i') AS fecha", 
                                "IF(T1.estado = 0,'Sin Asignar','') AS estado", 
                                "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",                            
                                "SE.nombre AS estado",
                                "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",
                                "DATE_FORMAT(T1.fecha_asignacion_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_asignacion", 
                                "DATE_FORMAT(T1.fecha_solucion_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_solucion",  
                            ],
                            sqlJoin : [
                                'INNER JOIN users AS U ON (U.id = T1.id_usuario)',
                                'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_servicio)',
                                'INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)', 
                                'INNER JOIN solicitudes_prioridades AS SP ON (SP.id = T1.prioridad)',
                                'INNER JOIN areas_servicio AS AR ON (AR.id = T1.id_area)', 
                                'LEFT JOIN activos AS A ON (A.id = T1.id_activo)'
                            ],
                            sqlWhere : [
                                whereUser
                            ],
                            fieldSearch : [
                                "T1.asunto",
                                "T1.id",
                                'AR.nombre'                                
                            ],                            
                            fieldFechas : "T1.fecha",
                            fecha_completa : "true", 
                            sqlOrderBy : "T1.id DESC"                    
                        };  

        let windowGestionServicio = <Window 
                                            id = "windowGestionServicio"                      
                                            title={LabelsServicios.window2_title[idioma]}                                            
                                            tbar="false"
                                            componente="WindowProcesoGestion"
                                            params= ""
                                        /> 
          

        let windowTrazabilidadServicio =  <Window 
                                                id = "windowTrazabilidadServicio"                      
                                                title={LabelsServicios.window3_title[idioma]}                                                
                                                tbar="false"
                                                componente="WindowTrazabilidad"
                                                params="" 
                                           />

        let fieldActivos = null;        
        if(validarModulo(6)){            
            fieldActivos   =    {
                                    label : LabelsServicios.form_field9[idioma],                                        
                                    type  : 'data_select',
                                    field : 'id_activo',
                                    dataParams : { 
                                                     fetchData : {
                                                          fieldFetch  : 'nombre',
                                                          idField     : 'id_activo',
                                                          valueField  : 'nombre_activo',
                                                     },
                                                     apiField    : 'activos',
                                                     colsData    : [ 
                                                                        {
                                                                            type  : 'bd',
                                                                            label : 'Codigo',
                                                                            field : 'codigo'
                                                                        },
                                                                        {
                                                                            type  : 'bd',
                                                                            label : 'Nombre',
                                                                            field : 'nombre'
                                                                        },
                                                                    ],
                                                     sqlParams : {
                                                                    sqlCols : [ 
                                                                        'id',                                 
                                                                        'codigo',
                                                                        'nombre'                                
                                                                    ],                            
                                                                    fieldSearch : [                                                                            
                                                                        'codigo',
                                                                        'nombre',                                                                            
                                                                    ],  
                                                                    sqlWhere : [ " AND estado < 7" ]                                         
                                                                 }                                                                                           
                                                 },
                                    validation : '',
                                    required : 'false',
                                    tooltip : LabelsServicios.form_field9_tooltip[idioma],                                                                                            
                                };
        }

        return (
            <div>
                <DataGrid titulo={LabelsServicios.Titulo[idioma]} 
                          funcionClick={this.props.funcionClick}  
                          parametro={this.props.parametro} 
                          tbarEdit="true"
                          dataFilter={[
                                {
                                    label   : LabelsServicios.dataFilter1[idioma],
                                    dinamic : 'false',
                                    field   : 'T1.prioridad',
                                    options :  [{
                                                    id: '4',
                                                    nombre: LabelsServicios.dataFilter1_op1[idioma],
                                                },
                                                {
                                                    id: '3',
                                                    nombre: LabelsServicios.dataFilter1_op2[idioma],
                                                },
                                                {
                                                    id: '2',
                                                    nombre: LabelsServicios.dataFilter1_op3[idioma], 
                                                },
                                                {
                                                    id: '1',
                                                    nombre: LabelsServicios.dataFilter1_op4[idioma], 
                                                }]
                                },
                                {
                                    label   : LabelsServicios.dataFilter2[idioma],
                                    dinamic : 'false',
                                    field   : 'T1.estado',
                                    options :  [{
                                                    id: '0',
                                                    nombre: LabelsServicios.dataFilter2_op1[idioma],
                                                },
                                                {
                                                    id: '1',
                                                    nombre: LabelsServicios.dataFilter2_op2[idioma], 
                                                },
                                                {
                                                    id: '2',
                                                    nombre: LabelsServicios.dataFilter2_op3[idioma], 
                                                },
                                                {
                                                    id: '3',
                                                    nombre: LabelsServicios.dataFilter2_op4[idioma], 
                                                },
                                                {
                                                    id: '4',
                                                    nombre: LabelsServicios.dataFilter2_op5[idioma], 
                                                },
                                                {
                                                    id: '5',
                                                    nombre: LabelsServicios.dataFilter2_op6[idioma], 
                                                },
                                                {
                                                    id: '6',
                                                    nombre: LabelsServicios.dataFilter2_op7[idioma], 
                                                }]
                                },
                                {
                                    label : LabelsServicios.dataFilter3[idioma],
                                    dinamic : 'true',
                                    field : 'T1.id_area',
                                    sqlParams : {
                                                    sqlCols : [
                                                        'id',
                                                        'nombre'                                
                                                    ],
                                                    sqlWhere : [ " AND atencion_servicios='true'"+whereArea+whereArea2 ],                                                                                                                                                                                                                   
                                                },
                                    apiField : 'areas_servicio',
                                    valueName : 'nombre',
                                }                                         
                          ]}
                          tbarItems={[                                    
                                        {
                                              type : 'boton',
                                              icon : 'assignment_turned_in',
                                              width : '80px',
                                              height : '70px',
                                              title : LabelsServicios.edit_btn2[idioma],
                                              display : this.state.btnGestionar, 
                                              windowComponent : windowGestionServicio,
                                              function : this.btnGestionServicio.bind(this)                                       
                                        },                                                                                               
                                        {
                                              type : 'boton',
                                              icon : 'description',
                                              width : '80px',
                                              height : '70px',
                                              title : LabelsServicios.edit_btn3[idioma],
                                              display : true, 
                                              windowComponent : windowTrazabilidadServicio,
                                              function : this.btnTrazabilidadServicio.bind(this)                                       
                                        },                                                
                                    ]} 
                          colsData={[
                                        {
                                            type  : 'bd',
                                            label : LabelsServicios.ColLabel1[idioma],
                                            field : 'id'
                                        },
                                        {
                                            type  : 'bd_html',
                                            label : LabelsServicios.ColLabel2[idioma],
                                            field : 'nom_prioridad'
                                        },
                                        {
                                            type    : 'bd',
                                            label   : LabelsServicios.ColLabel2[idioma],
                                            field   : 'prioridad',
                                            onlyXLS : 'true'
                                        },
                                        {
                                            type    : 'bd',
                                            label   : LabelsServicios.ColLabel2_1[idioma],
                                            field   : 'impacto',
                                            onlyXLS : 'true'
                                        },
                                        {
                                            type    : 'bd',
                                            label   : LabelsServicios.ColLabel2_2[idioma],
                                            field   : 'urgencia',
                                            onlyXLS : 'true'
                                        },
                                        {
                                            type  : 'bd',
                                            label : LabelsServicios.ColLabel3[idioma],
                                            field : 'estado'
                                        },
                                        {
                                            type  : 'bd',
                                            label : LabelsServicios.ColLabel4[idioma],
                                            field : 'fecha'
                                        },
                                        {
                                            type  : 'bd',
                                            label : LabelsServicios.ColLabel5[idioma],
                                            field : 'nombre_area'
                                        },
                                        {
                                            type  : 'bd',
                                            label : LabelsServicios.ColLabel6[idioma],
                                            field : 'asunto'
                                        }, 
                                        {
                                            type    : 'bd',
                                            label   : LabelsServicios.ColLabel8[idioma],
                                            field   : 'descripcion',
                                            onlyXLS : 'true'
                                        },
                                        {
                                            type  : 'bd',
                                            label : LabelsServicios.ColLabel7[idioma],
                                            field : 'usuario'
                                        }, 
                                        {
                                            type    : 'bd',
                                            label   : LabelsServicios.ColLabel9[idioma],
                                            field   : 'tecnico',
                                            onlyXLS : 'true'
                                        },       
                                        {
                                            type    : 'bd',
                                            label   : LabelsServicios.ColLabel10[idioma],
                                            field   : 'fecha_asignacion',
                                            onlyXLS : 'true'
                                        },  
                                        {
                                            type    : 'bd',
                                            label   : LabelsServicios.ColLabel11[idioma],
                                            field   : 'fecha_solucion',
                                            onlyXLS : 'true'
                                        },
                                        {
                                            type  : 'manual',
                                            label : '',
                                            icon  : 'description',
                                            colFuncion : this.colFuncion.bind(this)
                                        },
                                        {
                                            type  : 'manual',
                                            label : '',
                                            icon  : 'attach_file',
                                            colFuncion : this.colFuncion2.bind(this)
                                        }                                                                         
                                    ]}
                          sqlParams = { sqlParams }
                          automatica="true"
                          botonNuevo="true"
                          funcionAfterInsert={this.asignarServicio.bind(this)}
                          botonesExportar="true"
                          botonExportarXls="true"
                          botonExportarPDF="false"
                          filtroFechas="true"  
                          formFields={[
                                        {
                                            label : LabelsServicios.ColLabel2_2[idioma]+' *',
                                            field : 'urgencia',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'false',
                                            valueName : 'nombre',
                                            options :  [{
                                                            id: '4',
                                                            nombre: LabelsServicios.dataFilter1_op1[idioma],
                                                        },
                                                        {
                                                            id: '3',
                                                            nombre: LabelsServicios.dataFilter1_op2[idioma],
                                                        },
                                                        {
                                                            id: '2',
                                                            nombre: LabelsServicios.dataFilter1_op3[idioma], 
                                                        },
                                                        {
                                                            id: '1',
                                                            nombre: LabelsServicios.dataFilter1_op4[idioma], 
                                                        }],
                                            tooltip : LabelsServicios.ColLabel2_2_tooltip[idioma],                                                                                            
                                        }, 
                                        {
                                            label : LabelsServicios.ColLabel2_1[idioma]+' *',
                                            field : 'impacto',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'false',
                                            valueName : 'nombre',
                                            options :  [{
                                                            id: '4',
                                                            nombre: LabelsServicios.ColLabel2_1_op1[idioma],
                                                        },
                                                        {
                                                            id: '3',
                                                            nombre: LabelsServicios.ColLabel2_1_op2[idioma],
                                                        },
                                                        {
                                                            id: '2',
                                                            nombre: LabelsServicios.ColLabel2_1_op3[idioma], 
                                                        },
                                                        {
                                                            id: '1',
                                                            nombre: LabelsServicios.ColLabel2_1_op4[idioma], 
                                                        }],
                                            tooltip : LabelsServicios.ColLabel2_1_tooltip[idioma],                                                                                            
                                        },
                                        {
                                            label : LabelsServicios.form_field3[idioma]+' *',
                                            field : 'id_area',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'true',
                                            apiField : 'areas_servicio',
                                            valueName : 'nombre',
                                            updateTo : 'id_categoria',
                                            sqlParams : {
                                                            sqlCols : [
                                                                'id',
                                                                'nombre'                                
                                                            ],
                                                            sqlWhere : [ " AND atencion_servicios='true'"+whereArea ],                                                                                                                                                                                                                          
                                                        },
                                            tooltip : LabelsServicios.form_field3_tooltip[idioma],                                          
                                        },
                                        {
                                            label : LabelsServicios.form_field4[idioma]+' *',
                                            field : 'id_categoria',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'true',
                                            apiField : 'areas_servicio_categorias',
                                            valueName : 'nombre',                                       
                                            updateTo : 'id_subcategoria',
                                            fieldUpdate : 'id_area', 
                                            sqlParams : {
                                                            sqlCols : [
                                                                'id',
                                                                'nombre'                                
                                                            ],
                                                            sqlWhere : [ " AND atencion_servicios='true'" ],                                                   
                                                        },
                                            tooltip : LabelsServicios.form_field4_tooltip[idioma]
                                        }, 
                                        {
                                            label :  LabelsServicios.form_field5[idioma]+' *',
                                            field : 'id_subcategoria',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'true',
                                            apiField : 'areas_servicio_subcategorias',
                                            valueName : 'nombre',
                                            fieldUpdate : 'id_categoria',                                        
                                            sqlParams : {
                                                            sqlCols : [
                                                                'id',
                                                                'nombre'                                
                                                            ],                                                                                                                                                            
                                                        },
                                            tooltip : LabelsServicios.form_field5_tooltip[idioma]
                                        },                                        
                                        fieldActivos,
                                        {
                                            label : LabelsServicios.form_field6[idioma]+' *',
                                            field : 'asunto',
                                            type  : 'text',
                                            validation : '',
                                            required : 'true',
                                            longitud : 60,
                                            tooltip : LabelsServicios.form_field6_tooltip[idioma],
                                        },                                       
                                        {
                                            label : LabelsServicios.form_field7[idioma]+' *',
                                            field : 'descripcion',
                                            type  : 'textarea-rich',
                                            rows  : 3,
                                            validation : '',
                                            required : 'true',
                                            tooltip : LabelsServicios.form_field7_tooltip[idioma],
                                        }, 
                                        {
                                            label  : LabelsServicios.form_field8[idioma],                                                    
                                            field  : 'nombre_archivo',                                                                                                        
                                            type   : 'multi_file',
                                            accept : ".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png",
                                            allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],
                                            validation : 'file',
                                            required : '',
                                            tooltip : LabelsServicios.form_field8_tooltip[idioma] 
                                        },                                         
                                        {
                                            label : '',
                                            field : 'id_usuario',
                                            type  : 'hidden',
                                            validation : '',
                                            value : globalState.getState().userData[0].id,
                                            required : 'true'                                        
                                        }, 
                                        {
                                            label : '',
                                            field : 'fecha',
                                            type  : 'hidden',
                                            validation : '',
                                            value : fechaMysql(),
                                            required : 'true'                                        
                                        },  
                                    ]}                     
                          apiField = {'servicios'}
                          permisoInsertUpdate="8"
                          permisoBtnUpdate="19"
                          permisoDelete="19"
                          mainContainer='Servicios'/> 
                <Window 
                    id = "windowTrazabilidadServicio1"                      
                    title={LabelsServicios.window3_title[idioma]}                     
                    tbar="false"
                    componente="WindowTrazabilidad"
                    params="" 
                />
                <Window 
                    id = "windowArchivosAdjuntosServicios"                      
                    title={LabelsServicios.window4_title[idioma]}                   
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                />                            
            </div>
        )
    } 
}

export default Servicios