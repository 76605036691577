/**
* CLASS ComboBoxFilter
*
* Contiene el contenedor del combobox para el filtro del data grid
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';

class ComboBoxFilter extends Component {
	constructor(props){
		super(props);		
		this.state = {
			options  : [],
			database : globalState.getState().companyData[0].bd
		}		
	}
	componentWillMount(){
		let listado = this.props.listado;
		if(listado.dinamic === 'true'){
			cargarFilas(listado.apiField,'',100,0,'','',listado.sqlParams,'rows',this.state.database).then(res => {
	            var response = res.data;
	            if (response.msg !== "error") {
	                this.setState({ options : response });
	            } 
	        })
	        .catch( error => {            
	            //...
	        }) 
		}
		else{
			this.setState({ options : listado.options });
		}
	}
	render(){
		return <select style={this.props.style} defaultValue="" onChange={this.props.functionChange} >
					<option value="">Seleccione...</option>
					{
						this.state.options.map((options,i) => {
                            return <option value={options.id}  key= {i}>{options.nombre}</option>
                        })
					}					
	       	   </select>
	}	
}

export default ComboBoxFilter