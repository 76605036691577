/**
/**
* CLASS WindowConfigurarCampo2
*
* Contiene el contenedor principal de las configuraciones de los campos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import FormDataGrid from '../../data_grid/FormDataGrid';
import {cargarFilas} from '../../api_calls/ApiCalls';
import globalState from '../../configuration/GlobalState';
import alertify from 'alertifyjs';

class WindowConfigurarRegla extends Component { 
    constructor(props){
        super(props);
        this.state = {
            idRow : 0,            
            database : globalState.getState().companyData[0].bd  
        };
    } 
    componentWillMount(){    
        //CARGAR LOS DATOS DEL FORMULARIO
        let sqlParams = { 
                            sqlWhere : [
                                " AND id="+globalState.getState().idActivoCampoFicha
                            ],                                                     
                            mode : 'rows'                                                 
                        }

        cargarFilas('activos_tipos_campos_ficha','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{
                let idRow = 0;               
                if(response.length > 0){
                    idRow = response[0];                    
                }
                this.setState({ idRow: idRow });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        }); 
    }
    cerrarVentana(){
        this.props.params.funcionClick('WelcomePage');
        this.props.params.funcionClick('ActivosTiposCamposFicha');
        globalState.dispatch({
                type   : "WindowConfigurarCampo2",
                params : {
                              visible : false,
                         }
        });
    } 
    render() {       
        return (//carga el componente que contiene la grilla de datos                            
            <div style={{top:'-10px',position:'relative'}}>             
                <FormDataGrid 
                    funcionClick = {this.props.funcionClick} 
                    parametro = {
                                    {
                                        idRow : this.state.idRow,
                                        mainContainer : 'WindowConfigurarCampo2',
                                        formulario : true,
                                        titulo : 'Validaciones',
                                        apiField : 'activos_tipos_campos_ficha', 
                                        enableBtnEdit : true,
                                        functionCancel : this.cerrarVentana.bind(this),
                                        funcionAfterInsert : this.cerrarVentana.bind(this),
                                        funcionAfterUpdate : this.cerrarVentana.bind(this),                                                                        
                                        formFields :  [
                                                        {
                                                            label : 'Validacion *',
                                                            field : 'validacion',
                                                            type  : 'select',
                                                            validation : '',
                                                            required : 'true',
                                                            dinamic : 'false',
                                                            valueName : 'nombre',
                                                            options :  [
                                                                        {
                                                                            id: 'sin_validacion',
                                                                            nombre: 'SIN VALIDACION',
                                                                        },
                                                                        {
                                                                            id: 'mayusculas',
                                                                            nombre: 'LETRAS MAYUSCULAS',
                                                                        },
                                                                        {
                                                                            id: 'texto_mayusculas',
                                                                            nombre: 'NUMEROS Y MAYUSCULAS',
                                                                        },
                                                                        {
                                                                            id: 'entero',
                                                                            nombre: 'ENTERO',
                                                                        },
                                                                        {
                                                                            id: 'numero_real',
                                                                            nombre: 'NUMERO REAL',
                                                                        }
                                                                       ],
                                                            tooltip : 'Seleccione la validacion',                                                                                            
                                                        },
                                                        {
                                                            label : 'Longitud *',
                                                            field : 'longitud',
                                                            type  : 'text',
                                                            validation : 'entero',
                                                            required : 'true',
                                                            longitud : 10,
                                                            tooltip : 'Ingrese la longitud de los campos'
                                                        },                                                                                                                                                                       
                                                        {
                                                            label : '',
                                                            field : 'id',
                                                            type  : 'hidden',
                                                            validation : '',
                                                            value : globalState.getState().idActivoCampoFicha,
                                                            required : 'true'                                        
                                                        },
                                                      ] 
                                    }
                                } 
                            
                /> 
            </div>                         
        )
    } 
}

export default WindowConfigurarRegla