/**
* CLASS WindowArchivosAdjuntos
*
* Contiene el componente que muestra los archivos adjuntos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import configJson from '../configuration/configuration.json';
import TablaAdjuntosContainer from './TablaAdjuntosContainer';
import Window from '../window/Window';
import {divMouseOver,divMouseOut} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import Button from 'react-bootstrap/Button';
import MaterialIcon from 'material-icons-react';
import {cargarFilas} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';

class WindowArchivosAdjuntos extends Component {
    constructor(props) {        
        super(props);
        this.state = {
            updateTable : 0,
            bloqueo : 'false',
            database : globalState.getState().companyData[0].bd
        };   
    }
    componentWillMount(){
        if(this.props.params.bloqueo === 'usuario'){
            let idUsuario = globalState.getState().userData[0].id;
            let sqlParams = {
                                sqlCols : [
                                    'id_tecnico',                                                                                                                                       
                                ],
                                sqlWhere : [ ' AND id='+this.props.params.idRow ]                                                                                                                                                          
                            } 
            cargarFilas('conocimiento','',1,0,'','',sqlParams,'rows',this.state.database)
            .then(res => {
                res = res.data;
                if (res.msg === "error") {
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
                } 
                else{
                    let bloqueo = 'true';
                    if(idUsuario === res[0].id_tecnico){
                        bloqueo = 'false';
                    }
                    this.setState({bloqueo : bloqueo});                                    
                }
            })
            .catch( err => {            
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
            });
        }
        
    }   
    componentDidMount() {//actualizar el listado de adjuntos 
        //... 
        this.unsubscribe1 = globalState.subscribe( ()=>{ 
            if(globalState.getState().type==="updateUploadArchivo"){ 
                this.setState({updateTable  : this.state.updateTable+1});                                               
            }
        });              
    } 
    componentWillUnmount(){         
        this.unsubscribe1();        
    }
    handleNewButton(){
        this.props.params['width']  = '200px';
        this.props.params['height'] = '170px';
        globalState.dispatch({
            type   : "windowUploadArchivo",
            params : {
                          visible : true,
                          params  : this.props.params,
                     }
        }); 
    } 
    verEspacioCloud(){
        this.props.funcionClick('WindowVerAlmacenamiento');
    }   
  	render() {
        let idRol = globalState.getState().idRol;                      
  	  	return (<div>
                    <div id="contenedorArchivosAdjuntos" style={{paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'220px',overflowY : 'auto' }}>   
                        {
                            this.state.bloqueo === 'false' || idRol === 1 ? 
                                <Button id="addAttachment" variant="primary" onClick={this.handleNewButton.bind(this)} style={{backgroundColor:configJson.fondoBotonGrilla}} onMouseOut={divMouseOut.bind(this,'addAttachment',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'addAttachment',configJson.fondoBotonGrilla)}>AGREGAR</Button>   
                            : ''
                        }
                        <div style={{float:'right',cursor:'pointer',paddingRight:'5px',top:'-5px',position:'relative'}} onClick={this.verEspacioCloud.bind(this)} title="Ver Almacenamiento">    
                            <MaterialIcon id="iconColumna" color={configJson.fondoBotonGrilla} size={40} icon="cloud"/>
                        </div>                        
                        <div className="table-responsive">
                            <TablaAdjuntosContainer 
                                updateTable = {this.state.updateTable}
                                params = {this.props.params}
                                bloqueo= {this.state.bloqueo}                                
                            />
                        </div>
                    </div>
                    <Window 
                        id = "windowUploadArchivo"                      
                        title='Subir Adjunto'                    
                        tbar="false"
                        componente="WindowUploadArchivo"
                        params="" 
                    />
                </div>);
  	}
}

export default WindowArchivosAdjuntos