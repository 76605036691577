/**
* CLASS ImapReglas
*
* Contiene el contenedor principal de las reglas de Imap
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../../data_grid/DataGrid';
import Window from '../../window/Window';
import globalState from '../../configuration/GlobalState';
import TBar from '../../tbar/TBar';

class ImapReglas extends Component {
    retrocederPanel(){
        this.props.funcionClick('Imap');
    }
    colFuncion(idRegla){        
        globalState.dispatch({
                type   : "windowConfigurarRegla",
                params : {
                              visible : true,
                              params  : {
                                            idRegla : idRegla,
                                            funcionClick : this.props.funcionClick,
                                            idWin  : "windowConfigurarRegla",//identificador de la ventana,
                                            width  : '500px', 
                                            height : '300px'
                                        }
                         }
            }); 
    }
  	render() {            
        let sqlParams = { 
                            sqlCols : [
                                "*",
                                'T1.id AS id',
                                'AR.nombre AS nombre_area',                                          
                                'IC.nombre AS categoria',
                                'ISC.nombre AS subcategoria',
                                "IF(impacto='4','CRITICO',IF(impacto='3','ALTO',IF(impacto='2','MEDIO','BAJO'))) AS nom_impacto",
                                "IF(urgencia='4','CRITICA',IF(urgencia='3','ALTA',IF(urgencia='2','MEDIA','BAJA'))) AS nom_urgencia",                                
                            ],
                            fieldSearch : [
                                'palabra_clave',                                
                            ],
                            sqlJoin : [
                                'LEFT JOIN areas_servicio AS AR ON (AR.id = T1.id_area)', 
                                'LEFT JOIN areas_servicio_categorias AS IC ON (IC.id = T1.id_categoria)', 
                                'LEFT JOIN areas_servicio_subcategorias ISC ON (ISC.id = T1.id_subcategoria)',
                            ],                            
                            sqlWhere : [
                                " AND id_imap="+globalState.getState().idImap
                            ],                                                                                   
                            filtroFechas : "false",                                                 
                        };                 
        return (//carga el componente que contiene la grilla de datos 
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>            
                    <DataGrid titulo='Reglas Imap' 
                              funcionClick={this.props.funcionClick} 
                              parametro={this.props.parametro}
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Palabra Clave',
                                                field : 'palabra_clave'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Tipo Solicitud',
                                                field : 'tipo'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Urgencia',
                                                field : 'nom_urgencia'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Impacto',
                                                field : 'nom_impacto'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Area Servicio',
                                                field : 'nombre_area'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Categoria',
                                                field : 'categoria'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Subcategoria',
                                                field : 'subcategoria'
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'settings',
                                                colFuncion : this.colFuncion.bind(this)
                                            },
                                        ]} 
                              sqlParams = { sqlParams }
                              automatica="true"
                              botonNuevo="true"
                              formFields={[
                                            {
                                                label : 'Palabra Clave *',
                                                field : 'palabra_clave',
                                                type  : 'text',
                                                validation : '',
                                                longitud : 30,
                                                required : 'true',
                                                tooltip : 'Ingrese la palabra clave',                                               
                                            },
                                            {
                                                label : 'Tipo Solicitud *',
                                                field : 'tipo',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: 'incidencia',
                                                                nombre: 'Incidencia',
                                                            },
                                                            {
                                                                id: 'problema',
                                                                nombre: 'Problema',
                                                            },
                                                            {
                                                                id: 'servicio',
                                                                nombre: 'Servicio', 
                                                            }],
                                                tooltip : 'Especifique el tipo de Solicitud'                                        
                                            },
                                            {
                                                label : 'Urgencia *',
                                                field : 'urgencia',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: '4',
                                                                nombre: 'Crítica',
                                                            },
                                                            {
                                                                id: '3',
                                                                nombre: 'Alta',
                                                            },
                                                            {
                                                                id: '2',
                                                                nombre: 'Media', 
                                                            },
                                                            {
                                                                id: '1',
                                                                nombre: 'Baja', 
                                                            }],
                                                tooltip : 'Seleccione el nivel de urgencia',                                                                                            
                                            },
                                            {
                                                label : 'Impacto *',
                                                field : 'impacto',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: '4',
                                                                nombre: 'Crítico',
                                                            },
                                                            {
                                                                id: '3',
                                                                nombre: 'Alto',
                                                            },
                                                            {
                                                                id: '2',
                                                                nombre: 'Medio', 
                                                            },
                                                            {
                                                                id: '1',
                                                                nombre: 'Bajo', 
                                                            }],
                                                tooltip : 'Seleccione el nivel de impacto',                                                                                            
                                            },
                                            {
                                                label : 'Asunto por Defecto *',
                                                field : 'asunto_default',
                                                type  : 'text',
                                                validation : '',
                                                longitud : 100,
                                                required : 'true',
                                                tooltip : 'Ingrese el asunto por defecto',                                               
                                            }, 
                                            {
                                                label : '',
                                                field : 'id_imap',
                                                type  : 'hidden',
                                                validation : '',
                                                value : globalState.getState().idImap,
                                                required : 'true'                                        
                                            },                                                                                 
                                        ]}                     
                              apiField={'imap_reglas'}
                              mainContainer='ImapReglas'/> 
                </div>
                <Window 
                    id = "windowConfigurarRegla"                      
                    title='Configurar Regla'                        
                    tbar="false"
                    componente="WindowConfigurarRegla"
                    params="" 
                />
            </div>             
        )
    } 
}

export default ImapReglas