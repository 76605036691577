/**
* CLASS DashboardContainer
*
* Contiene el contenedor con los indicadores
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DashboardOption from './DashboardOption';
import {cargarFilas,validarPermisoRol} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import { diccionario } from '../configuration/configuration.json';
import Table from 'react-bootstrap/Table';
import alertify from 'alertifyjs';
import '../../css/alertify.css';
import './dashboard.css';

const { LabelsDashboardContainer } = diccionario;

class DashboardContainer extends Component {
    constructor(props){
        super(props);          
        this.state={
            indicadores : {                
                indicadorIncidencias : 0,
                indicadorProblemas : 0,
                indicadorServicios : 0, 
                indicadorPromedioIncidencias : '', 
                indicadorPromedioProblemas : '',   
                indicadorPromedioServicios : '',                 
                indicadorIncidenciasPunt : 0,
                indicadorProblemasPunt : 0,
                indicadorServiciosPunt : 0,
                graficoTortaIncidencias : [],
                graficoTortaProblemas : [],
                graficoTortaServicios : [],
                graficoTortaIncidenciasTecnico : [],
                graficoTortaProblemasTecnico : [],
                graficoTortaServiciosTecnico : [],
                graficoTortaIncidenciasCump : [],
                graficoTortaProblemasCump : [],
                graficoTortaServiciosCump : [],
                graficoTortaIncidenciasPunt : [],
                graficoTortaProblemasPunt : [],
                graficoTortaServiciosPunt : [],
                graficoApiladoIncidencias : [],
                graficoApiladoServicios : [],
                graficoApiladoProblemas : [],
                stateYear1 : '',
            },
            permisos : {                
                displayPromI : 'none',
                displayPromP : 'none',
                displayPromS : 'none',            
                displayIncidencias : 'none',
                displayProblemas : 'none',
                displayServicios : 'none',
                whereIncidencia : '',
                whereProblema : '',
                whereServicio : '',                
            },
            idioma : globalState.getState().idioma,
            database : globalState.getState().companyData[0].bd           
        };        
    }
    componentWillMount(){
        this.cargarIndicadores(this.props.params.date1,this.props.params.date2,this.props.params.usuario,this.props.params.rol_usuario,this.props.params.filtroArea,this.props.params.permisos);         
    } 
    componentDidUpdate(prevProps){        
        if (JSON.stringify(this.props.params) !== JSON.stringify(prevProps.params)) {                        
            this.cargarIndicadores(this.props.params.date1,this.props.params.date2,this.props.params.usuario,this.props.params.rol_usuario,this.props.params.filtroArea,this.props.params.permisos); 
        }
    }
    async cargarIndicadores(date1,date2,idUser,rolUser,filtroArea,permisos){  //cargar los indicadores                             
        try{
            var verOtros = "true";        
            if(idUser === 0){
                idUser = globalState.getState().userData[0].id;
                verOtros = "false";
            }
            let arrayPermisos = {}; 
            //indicadores de servicio              
            if(permisos.permisoVerTecnicos === true && verOtros==="false"){
                //visualizaciones 
                arrayPermisos = { 
                    displayIncidencias : 'block',
                    displayProblemas   : 'block',
                    displayServicios   : 'block',
                    displayPromI : 'block',
                    displayPromP : 'block',
                    displayPromS : 'block',
                    whereIncidencia : '',
                    whereProblema : '',                 
                    whereServicio : '', 
                };
            } 
            else if(permisos.permisoVerTecnicos === false || verOtros==="true"){           
                let res = await validarPermisoRol(rolUser,46);
                let response = res.data;
                let displayPromI = 'none';
                let displayIncidencias = 'none';
                let whereIncidencia = '';
                if((response.msg === true && verOtros === "true") || (verOtros==="false" && permisos.indicadoresIncidencias === true)){
                    if(permisos.permisoVerTecnicos === true){
                        displayPromI = 'block';                     
                    }
                    displayIncidencias = 'block';
                    whereIncidencia    = ' AND id_tecnico_incidencia = '+idUser;                
                }            
                
                res = await validarPermisoRol(rolUser,47);
                response = res.data;  
                let displayPromP = 'none'; 
                let displayProblemas = 'none';
                let whereProblema = '';                                             
                if((response.msg === true && verOtros === "true") || (verOtros==="false" && permisos.indicadoresProblemas === true)){
                    if(permisos.permisoVerTecnicos === true){
                        displayPromP = 'block';                    
                    }
                    displayProblemas = 'block';
                    whereProblema = ' AND id_tecnico_problema = '+idUser;                    
                }
                        
                res = await validarPermisoRol(rolUser,48);
                response = res.data;            
                let displayPromS = 'none'; 
                let displayServicios = 'none';  
                let whereServicio = '';        
                if((response.msg === true && verOtros === "true") || (verOtros==="false" && permisos.indicadoresServicios === true)){
                    if(permisos.permisoVerTecnicos === true){
                        displayPromS = 'block';                   
                    }
                    displayServicios = 'block';                
                    whereServicio = ' AND id_tecnico_servicio = '+idUser;                   
                }                                  
                arrayPermisos = { 
                    displayIncidencias : displayIncidencias,
                    displayProblemas   : displayProblemas,
                    displayServicios   : displayServicios,
                    displayPromI : displayPromI,
                    displayPromP : displayPromP,
                    displayPromS : displayPromS,
                    whereIncidencia : whereIncidencia,
                    whereProblema : whereProblema,                 
                    whereServicio : whereServicio,                 
                };                
            }             
            //cargar los estados        
            this.setState({permisos : arrayPermisos},()=>{
                this.ejecutarQuerysDashboard(date1,date2,filtroArea,verOtros);
            });
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message);
        }
    }

    async ejecutarQuerysDashboard(date1,date2,filtroArea,verOtros){
        try{ 
            let permisos = this.state.permisos;                     
            //formato de la fecha
            date1 = new Date(date1.getTime() - (date1.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            date2 = new Date(date2.getTime() - (date2.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            //cargar los indicadores
            //indicador 1 de incidencias 
            var year1 = date1.substring(0,4);  
            var year2 = date2.substring(0,4);      
            var arrayMeses = [
                                '',
                                LabelsDashboardContainer.TableMes1DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes2DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes3DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes4DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes5DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes6DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes7DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes8DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes9DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes10DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes11DashboardContainer[this.state.idioma],
                                LabelsDashboardContainer.TableMes12DashboardContainer[this.state.idioma]
                             ];

            var whereArea  = '';
            var whereArea1 = '';

            if(filtroArea > 0 && verOtros==='false'){
                whereArea  = ' AND id_area='+filtroArea;
                whereArea1 = ' AND T1.id_area='+filtroArea;
            }
            //inicializar las variables            
            let indicadorIncidencias = [];
            let indicadorProblemas = [];
            let indicadorServicios = [];            
            let indicadorPromedioIncidencias = '';
            let indicadorPromedioProblemas = '';
            let indicadorPromedioServicios = '';
            let indicadorIncidenciasPunt = 0;
            let indicadorProblemasPunt = 0;
            let indicadorServiciosPunt = 0;
            let graficoTortaIncidencias = [];
            let graficoTortaProblemas = [];
            let graficoTortaServicios = [];
            let graficoTortaIncidenciasTecnicos = [];
            let graficoTortaProblemasTecnicos = [];        
            let graficoTortaServiciosTecnicos = [];
            let graficoTortaIncidenciasCump = [];
            let graficoTortaProblemasCump = [];
            let graficoTortaServiciosCump  = [];
            let graficoTortaIncidenciasPunt = [];
            let graficoTortaProblemasPunt = [];
            let graficoTortaServiciosPunt = [];
            let graficoApiladoIncidencias = [];
            let graficoApiladoProblemas = [];
            let graficoApiladoServicios = [];
            let stateYear1 = year1;
            let stateYear2 = year2;            
            let i = 0;
            let arrayIncidencias = []; 
            let arrayProblemas = [];
            let arrayServicios = [];
            
            let sqlParams = '';            
            //indicador 1 de incidencias            
            if(permisos.displayIncidencias === 'block'){            
                sqlParams = {
                                sqlCols : [
                                    'COUNT(id) AS total'
                                ],
                                sqlWhere : [ " AND problema='false' "+permisos.whereIncidencia+whereArea ],
                                fieldFechas : "T1.fecha",
                                sqlGroupBy : "activo", 
                                sqlOrderBy : "activo",                               
                                fecha_completa : "true"                                                     
                            }
                let res = await cargarFilas('incidencias','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                let total = 0;
                if(response.length > 0){
                    total = response[0].total;
                }  
                indicadorIncidencias = total;                
            }        
             //indicador 1 de problemas 
            if(permisos.displayProblemas === 'block'){   
                sqlParams = {
                                sqlCols : [
                                    'COUNT(id) AS total'
                                ], 
                                sqlWhere : [ " AND problema='true' "+permisos.whereProblema+whereArea ],
                                fieldFechas : "T1.fecha_problema",
                                sqlGroupBy : "activo",
                                sqlOrderBy : "activo", 
                                fecha_completa : "true"                                                      
                            }
                let res = await cargarFilas('incidencias','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;
                let total = 0;
                if(response.length > 0){
                    total = response[0].total;
                }                                             
                indicadorProblemas = total;
            }
             //indicador 1 de servicios  
            if(permisos.displayServicios === 'block'){ 
                sqlParams = {
                                sqlCols : [
                                    'COUNT(id) AS total'
                                ],
                                sqlWhere : [ ' '+permisos.whereServicio+whereArea ],
                                fieldFechas : "T1.fecha",
                                sqlGroupBy : "activo",
                                sqlOrderBy : "activo", 
                                fecha_completa : "true"                                                     
                            }
                let res = await cargarFilas('servicios','',1,0,date1,date2,sqlParams,'rows',this.state.database);           
                let response = res.data;
                let total = 0;
                if(response.length > 0){
                    total = response[0].total;
                } 
                indicadorServicios = total;
            }
            //indicador de horas promedio para resolucion de incidencias   
            if(permisos.displayPromI === 'block'){       
                sqlParams = {
                                sqlCols : [
                                   `CONCAT(
                                        FLOOR(
                                            TIME_FORMAT(
                                                SEC_TO_TIME(
                                                    AVG(
                                                        TIME_TO_SEC(
                                                            TIMEDIFF(
                                                                fecha_solucion_incidencia,
                                                                fecha_proceso_incidencia
                                                            )
                                                        )
                                                    )
                                                ),
                                                '%H'
                                            ) / 24
                                        ),
                                        ' dias ',
                                        MOD (
                                            TIME_FORMAT(
                                                SEC_TO_TIME(
                                                    AVG(
                                                        TIME_TO_SEC(
                                                            TIMEDIFF(
                                                                fecha_solucion_incidencia,
                                                                fecha_proceso_incidencia
                                                            )
                                                        )
                                                    )
                                                ),
                                                '%H'
                                            ),
                                            24
                                        ),
                                        'h:',
                                        TIME_FORMAT(
                                            SEC_TO_TIME(
                                                AVG(
                                                    TIME_TO_SEC(
                                                        TIMEDIFF(
                                                            fecha_solucion_incidencia,
                                                            fecha_proceso_incidencia
                                                        )
                                                    )
                                                )
                                            ),
                                            '%im:%ss'
                                        )
                                    ) AS promedio`
                                ],
                                sqlWhere : [ " AND problema='false' "+permisos.whereIncidencia+whereArea ],
                                fieldFechas : "T1.fecha_solucion_incidencia",
                                sqlGroupBy : "T1.activo",
                                fecha_completa : "true"                                                     
                            }
                let res = await cargarFilas('incidencias','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;
                let arrayPromedio = null;
                if(response.length > 0){
                    arrayPromedio = response[0].promedio;
                }                                
                if(arrayPromedio !== null){
                    arrayPromedio = arrayPromedio.split(' dias '); 
                    if(arrayPromedio[0] > 0){
                        arrayPromedio = response[0].promedio;
                    }   
                    else{
                        arrayPromedio = arrayPromedio[1];
                    }  
                } 
                else{
                    arrayPromedio = "00:00:00";
                }                                 
                indicadorPromedioIncidencias = arrayPromedio;
            }   
            //indicador de horas promedio para resolucion de problema   
            if(permisos.displayPromP === 'block'){          
                sqlParams = {
                                sqlCols : [
                                   `CONCAT(
                                        FLOOR(
                                            TIME_FORMAT(
                                                SEC_TO_TIME(
                                                    AVG(
                                                        TIME_TO_SEC(
                                                            TIMEDIFF(
                                                                fecha_solucion_problema,
                                                                fecha_proceso_problema
                                                            )
                                                        )
                                                    )
                                                ),
                                                '%H'
                                            ) / 24
                                        ),
                                        ' dias ',
                                        MOD (
                                            TIME_FORMAT(
                                                SEC_TO_TIME(
                                                    AVG(
                                                        TIME_TO_SEC(
                                                            TIMEDIFF(
                                                                fecha_solucion_problema,
                                                                fecha_proceso_problema
                                                            )
                                                        )
                                                    )
                                                ),
                                                '%H'
                                            ),
                                            24
                                        ),
                                        'h:',
                                        TIME_FORMAT(
                                            SEC_TO_TIME(
                                                AVG(
                                                    TIME_TO_SEC(
                                                        TIMEDIFF(
                                                            fecha_solucion_problema,
                                                            fecha_proceso_problema
                                                        )
                                                    )
                                                )
                                            ),
                                            '%im:%ss'
                                        )
                                    ) AS promedio`
                                ],
                                sqlWhere : [ " AND problema='true' "+permisos.whereProblema+whereArea ],
                                fieldFechas : "T1.fecha_solucion_problema",
                                sqlGroupBy : "T1.activo",
                                fecha_completa : "true"                                                     
                            }
                let res = await cargarFilas('incidencias','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;
                let arrayPromedio = null;
                if(response.length > 0){
                    arrayPromedio = response[0].promedio;
                } 
                if(arrayPromedio !== null){
                    arrayPromedio = arrayPromedio.split(' dias '); 
                    if(arrayPromedio[0] > 0){
                        arrayPromedio = response[0].promedio;
                    }   
                    else{
                        arrayPromedio = arrayPromedio[1];
                    }  
                }
                else{
                    arrayPromedio = "00:00:00";
                }                                   
                indicadorPromedioProblemas = arrayPromedio;
            }
            //indicador de horas promedio para resolucion de soluciones
            if(permisos.displayPromS === 'block'){            
                sqlParams = {
                                sqlCols : [
                                   `CONCAT(
                                        FLOOR(
                                            TIME_FORMAT(
                                                SEC_TO_TIME(
                                                    AVG(
                                                        TIME_TO_SEC(
                                                            TIMEDIFF(
                                                                fecha_solucion_servicio,
                                                                fecha_proceso_servicio
                                                            )
                                                        )
                                                    )
                                                ),
                                                '%H'
                                            ) / 24
                                        ),
                                        ' dias ',
                                        MOD (
                                            TIME_FORMAT(
                                                SEC_TO_TIME(
                                                    AVG(
                                                        TIME_TO_SEC(
                                                            TIMEDIFF(
                                                                fecha_solucion_servicio,
                                                                fecha_proceso_servicio
                                                            )
                                                        )
                                                    )
                                                ),
                                                '%H'
                                            ),
                                            24
                                        ),
                                        'h:',
                                        TIME_FORMAT(
                                            SEC_TO_TIME(
                                                AVG(
                                                    TIME_TO_SEC(
                                                        TIMEDIFF(
                                                            fecha_solucion_servicio,
                                                            fecha_proceso_servicio
                                                        )
                                                    )
                                                )
                                            ),
                                            '%im:%ss'
                                        )
                                    ) AS promedio`
                                ],
                                sqlWhere : [ " "+permisos.whereServicio+whereArea ],
                                fieldFechas : "T1.fecha_solucion_servicio",
                                sqlGroupBy : "T1.activo",
                                fecha_completa : "true"                                                     
                            }
                let res = await cargarFilas('servicios','',1,0,date1,date2,sqlParams,'rows',this.state.database);            
                let response = res.data;                 
                let arrayPromedio = null;
                if(response.length > 0){
                    arrayPromedio = response[0].promedio;
                } 
                if(arrayPromedio !== null){
                    arrayPromedio = arrayPromedio.split(' dias '); 
                    if(arrayPromedio[0] > 0){
                        arrayPromedio = response[0].promedio;
                    }   
                    else{
                        arrayPromedio = arrayPromedio[1];
                    }  
                } 
                else{
                    arrayPromedio = "00:00:00";
                }               
                indicadorPromedioServicios = arrayPromedio;
            }        
            //indicador 1 de promedio de puntaje de incidencias
            if(permisos.displayPromI === 'block'){  
                sqlParams = {
                                sqlCols : [
                                    'ROUND(AVG(puntaje_encuesta)) AS promedio'
                                ],
                                sqlWhere : [ " AND problema='false' AND estado = 5 AND puntaje_encuesta > 0 "+permisos.whereIncidencia+whereArea ],
                                fieldFechas : "T1.fecha_finalizacion",
                                sqlGroupBy : "T1.activo",
                                fecha_completa : "true"                                                     
                            }
                let res = await cargarFilas('incidencias','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;     
                let promedio = 0;       
                if(response.length > 0){
                    promedio = response[0].promedio;
                }
                indicadorIncidenciasPunt = promedio;
            }
             //indicador 1 de promedio de puntaje de problemas 
            if(permisos.displayPromP === 'block'){  
                sqlParams = {
                                sqlCols : [
                                    'ROUND(AVG(puntaje_encuesta)) AS promedio'
                                ], 
                                sqlWhere : [ " AND problema='true' AND estado = 5 AND puntaje_encuesta > 0 "+permisos.whereProblema+whereArea ],    
                                fieldFechas : "T1.fecha_finalizacion",
                                sqlGroupBy : "T1.activo",
                                fecha_completa : "true"                                                      
                            }
                let res = await cargarFilas('incidencias','',1,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;
                let promedio = 0;       
                if(response.length > 0){
                    promedio = response[0].promedio;
                }
                indicadorProblemasPunt = promedio;
            }
             //indicador 1 de promedio de puntaje de servicios 
            if(permisos.displayPromS === 'block'){     
                sqlParams = {
                                sqlCols : [
                                    'ROUND(AVG(puntaje_encuesta)) AS promedio'
                                ],
                                sqlWhere : [ ' AND estado = 5 AND puntaje_encuesta > 0 '+permisos.whereServicio+whereArea ],    
                                fieldFechas : "T1.fecha_finalizacion",
                                sqlGroupBy : "T1.activo",
                                fecha_completa : "true"                                                     
                            }
                let res = await cargarFilas('servicios','',1,0,date1,date2,sqlParams,'rows',this.state.database);            
                let response = res.data; 
                let promedio = 0;       
                if(response.length > 0){
                    promedio = response[0].promedio;
                } 
                indicadorServiciosPunt = promedio;
            }
            //indicador grafico de incidencias
            if(permisos.displayIncidencias === 'block'){  
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total',
                                    'UPPER(SE.nombre) AS estado',                                
                                ],
                                sqlJoin : [
                                    'INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)',                                 
                                ],
                                sqlWhere : [ 
                                             " AND problema='false' "+permisos.whereIncidencia+whereArea,
                                             " AND ((T1.fecha BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_asignacion_incidencia BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_proceso_incidencia BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_solucion_incidencia BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_finalizacion BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59'))" 
                                ],                       
                                sqlGroupBy : "T1.estado,SE.nombre", 
                                sqlOrderBy : "T1.estado,SE.nombre",                  
                            }
                let res = await cargarFilas('incidencias','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;
                arrayIncidencias = [['Estado', 'Total']];                
                for(i in response){
                    arrayIncidencias.push([response[i].estado,response[i].total]);                    
                }                                
                graficoTortaIncidencias = arrayIncidencias;               
            }
            //indicador grafico de problemas
            if(permisos.displayProblemas === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total',
                                    'UPPER(SE.nombre) AS estado',                                
                                ],
                                sqlJoin : [
                                    'INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)',                                 
                                ],
                                sqlWhere : [ 
                                    " AND T1.problema = 'true'"+permisos.whereProblema+whereArea,
                                    " AND ((T1.fecha BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_asignacion_problema BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_proceso_problema BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_solucion_problema BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_finalizacion BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59'))"  
                                ],                    
                                sqlGroupBy : "T1.estado,SE.nombre",
                                sqlOrderBy : "T1.estado,SE.nombre",                                                                
                            }

                let res = await cargarFilas('incidencias','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                arrayProblemas = [['Estado', 'Total']];                
                for(i in response){
                    arrayProblemas.push([response[i].estado,response[i].total]);                    
                }                                
                graficoTortaProblemas = arrayProblemas;            
            }
            //indicador grafico de servicios
            if(permisos.displayServicios === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total',
                                    'UPPER(SE.nombre) AS estado',                                
                                ],
                                sqlJoin : [
                                    'INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)',                                 
                                ],
                                sqlWhere : [ 
                                    " "+permisos.whereServicio+whereArea,
                                     " AND ((T1.fecha BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_asignacion_servicio BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_proceso_servicio BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_solucion_servicio BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59')OR(T1.fecha_finalizacion BETWEEN '"+date1+":00:00:00' AND '"+date2+":23:59:59'))"  
                                ],  
                                sqlGroupBy : "T1.estado,SE.nombre", 
                                sqlOrderBy : "T1.estado,SE.nombre",                
                            }

                let res = await cargarFilas('servicios','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;
                arrayServicios = [['Estado', 'Total']];                
                for(i in response){
                    arrayServicios.push([response[i].estado,response[i].total]);                    
                }                       
                graficoTortaServicios = arrayServicios;            
            } 
            //indicador grafico de incidencias asignadas por tecnico
            if(permisos.displayPromI === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total',
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS tecnico",                                
                                ],
                                sqlJoin : [
                                    'INNER JOIN users AS U ON (U.id = T1.id_tecnico_incidencia)',                                 
                                ],
                                sqlWhere : [ 
                                             " AND estado < 3 AND problema='false' "+permisos.whereIncidencia+whereArea1,                                     
                                ],                      
                                sqlGroupBy : "T1.id_tecnico_incidencia,U.primer_nombre,U.primer_apellido",                  
                                sqlOrderBy : "T1.id_tecnico_incidencia,U.primer_nombre,U.primer_apellido",
                            }
                let res = await cargarFilas('incidencias','',20,0,date1,date2,sqlParams,'rows',this.state.database)
                let response = res.data;
                arrayIncidencias = [['Estado', 'Total']];                
                for(i in response){
                    arrayIncidencias.push([response[i].tecnico,response[i].total]);                    
                }                                
                graficoTortaIncidenciasTecnicos = arrayIncidencias;                
            }
            //indicador grafico de problemas asignados por tecnico
            if(permisos.displayPromP === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total',
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS tecnico",                                
                                ],
                                sqlJoin : [
                                    'INNER JOIN users AS U ON (U.id = T1.id_tecnico_problema)',                                 
                                ],
                                sqlWhere : [ 
                                    " AND estado < 3 AND T1.problema = 'true'"+permisos.whereProblema+whereArea1,                            
                                ],
                                sqlGroupBy : "T1.id_tecnico_problema,U.primer_nombre,U.primer_apellido",                                                                
                                sqlOrderBy : "T1.id_tecnico_problema,U.primer_nombre,U.primer_apellido",
                            }

                let res = await cargarFilas('incidencias','',20,0,date1,date2,sqlParams,'rows',this.state.database)
                let response = res.data; 
                arrayProblemas = [['Estado', 'Total']];                
                for(i in response){
                    arrayProblemas.push([response[i].tecnico,response[i].total]);                    
                }                                
                graficoTortaProblemasTecnicos = arrayProblemas;                
            }
            //indicador grafico de servicios asignados por tecnico
            if(permisos.displayPromS === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total',
                                    "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS tecnico",                                
                                ],
                                sqlJoin : [
                                    'INNER JOIN users AS U ON (U.id = T1.id_tecnico_servicio)',                                 
                                ],
                                sqlWhere : [ 
                                    " "+permisos.whereServicio+whereArea1,
                                    " AND estado < 3 "  
                                ],                 
                                sqlGroupBy : "T1.id_tecnico_servicio,U.primer_nombre,U.primer_apellido", 
                                sqlOrderBy : "T1.id_tecnico_servicio,U.primer_nombre,U.primer_apellido",                
                            }

                let res = await cargarFilas('servicios','',20,0,date1,date2,sqlParams,'rows',this.state.database)
                let response = res.data;                                 
                arrayServicios = [['Estado', 'Total']];                
                for(i in response){
                    arrayServicios.push([response[i].tecnico,response[i].total]);                    
                }                                
                graficoTortaServiciosTecnicos = arrayServicios;               
            }
            //indicador grafico de incidencias por cumplimiento        
            if(permisos.displayIncidencias === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total',
                                    "IF(T1.incidencia_cumplimiento = 'true','CUMPLE','NO CUMPLE') AS cumplimiento",                                
                                ],                        
                                sqlWhere : [ " AND problema='false' AND (T1.incidencia_cumplimiento = 'true' OR T1.incidencia_cumplimiento = 'false') AND T1.fecha_solucion_incidencia IS NOT NULL "+permisos.whereIncidencia+whereArea ],    
                                fieldFechas : "T1.fecha_solucion_incidencia",
                                sqlGroupBy : "cumplimiento",
                                sqlOrderBy : "cumplimiento",
                                fecha_completa : "true"                                            
                            }
                let res = await cargarFilas('incidencias','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;                             
                arrayIncidencias = [['Cumple', 'Total']];                
                for(i in response){
                    arrayIncidencias.push([response[i].cumplimiento,response[i].total]);                    
                }                                
                graficoTortaIncidenciasCump = arrayIncidencias;             
            }
            //indicador grafico de problemas por cumplimiento
            if(permisos.displayProblemas === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total',
                                    "IF(T1.problema_cumplimiento = 'true','CUMPLE','NO CUMPLE') AS cumplimiento",                            
                                ],                       
                                sqlWhere : [ " AND T1.problema = 'true' AND (T1.problema_cumplimiento = 'true' OR T1.problema_cumplimiento = 'false') AND T1.fecha_solucion_problema IS NOT NULL "+permisos.whereProblema+whereArea ],    
                                fieldFechas : "T1.fecha_solucion_problema",
                                sqlGroupBy : "cumplimiento",
                                sqlOrderBy : "cumplimiento",
                                fecha_completa : "true"                                            
                            }

                let res = await cargarFilas('incidencias','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data; 
                arrayProblemas = [['Cumple', 'Total']];                
                for(i in response){
                    arrayProblemas.push([response[i].cumplimiento,response[i].total]);                    
                }                                
                graficoTortaProblemasCump = arrayProblemas;                
            }
            //indicador grafico de servicios por cumplimiento
            if(permisos.displayServicios === 'block'){
                sqlParams = {
                                sqlCols : [
                                    'COUNT(T1.id) AS total',
                                    "IF(T1.servicio_cumplimiento = 'true','CUMPLE','NO CUMPLE') AS cumplimiento",   
                                ],                        
                                sqlWhere : [ " AND (T1.servicio_cumplimiento = 'true' OR T1.servicio_cumplimiento = 'false') AND T1.fecha_solucion_servicio IS NOT NULL "+permisos.whereServicio+whereArea ],    
                                fieldFechas : "T1.fecha_solucion_servicio",
                                sqlGroupBy : "cumplimiento",
                                sqlOrderBy : "cumplimiento",
                                fecha_completa : "true"                                            
                            }

                let res = await cargarFilas('servicios','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;
                arrayServicios = [['Cumple', 'Total']];                
                for(i in response){
                    arrayServicios.push([response[i].cumplimiento,response[i].total]);                    
                }                                
                graficoTortaServiciosCump = arrayServicios;                
            } 
             //indicador grafico de incidencias por puntaje
            if(permisos.displayPromI === 'block'){ 
                sqlParams = {
                                sqlCols : [                                
                                    'ROUND(puntaje_encuesta) AS puntaje',
                                    'COUNT(id) AS total'                                
                                ],                        
                                sqlWhere : [ " AND problema='false' AND puntaje_encuesta > 0 "+permisos.whereIncidencia+whereArea ],    
                                fieldFechas : "T1.fecha_finalizacion",
                                sqlGroupBy : "puntaje",
                                sqlOrderBy : "puntaje",                         
                                fecha_completa : "true"                                            
                            }
                let res = await cargarFilas('incidencias','',20,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;                                
                arrayIncidencias = [['Puntaje', 'Total']];                
                for(i in response){
                    arrayIncidencias.push(['PUNTAJE '+response[i].puntaje,response[i].total]);                    
                }                         
                graficoTortaIncidenciasPunt = arrayIncidencias;                
            }
            //indicador grafico de problemas por puntaje
            if(permisos.displayPromP === 'block'){
                sqlParams = {
                                sqlCols : [                                
                                    'ROUND(puntaje_encuesta) AS puntaje',
                                    'COUNT(id) AS total'                            
                                ],                       
                                sqlWhere : [ " AND T1.problema = 'true' AND puntaje_encuesta > 0 "+permisos.whereProblema+whereArea ],    
                                fieldFechas : "T1.fecha_finalizacion",
                                sqlGroupBy : "puntaje",
                                sqlOrderBy : "puntaje",
                                fecha_completa : "true"                                            
                            }

                let res = await cargarFilas('incidencias','',20,0,date1,date2,sqlParams,'rows',this.state.database);            
                let response = res.data;                               
                arrayProblemas = [['Puntaje', 'Total']];                
                for(i in response){
                    arrayProblemas.push(['PUNTAJE '+response[i].puntaje,response[i].total]);                    
                }                                
                graficoTortaProblemasPunt = arrayProblemas;           
            }
            //indicador grafico de servicios por puntaje
            if(permisos.displayPromS === 'block'){
                sqlParams = {
                                sqlCols : [                                
                                    'ROUND(puntaje_encuesta) AS puntaje',
                                    'COUNT(id) AS total'
                                ],                        
                                sqlWhere : [ " AND puntaje_encuesta > 0 "+permisos.whereServicio+whereArea ],    
                                fieldFechas : "T1.fecha_finalizacion",
                                sqlGroupBy : "puntaje",
                                sqlOrderBy : "puntaje",
                                fecha_completa : "true"                                            
                            }

                let res = await cargarFilas('servicios','',20,0,date1,date2,sqlParams,'rows',this.state.database)
                let response = res.data;
                arrayServicios = [['Puntaje', 'Total']];                
                for(i in response){
                    arrayServicios.push(['PUNTAJE '+response[i].puntaje,response[i].total]);                    
                }                                
                graficoTortaServiciosPunt = arrayServicios;                
            }
            //indicador grafico de incidencias apilado 
            if(permisos.displayIncidencias === 'block'){       
                sqlParams = {
                                sqlCols : [
                                    'MONTH(fecha) AS mes',
                                    'YEAR(fecha) AS year',
                                    'SUM(sin_asignar) AS sin_asignar',
                                    'SUM(asignado) AS asignado',
                                    'SUM(en_proceso) AS en_proceso',
                                    'SUM(solucionado) AS solucionado',
                                    'SUM(rechazado) AS rechazado',
                                    'SUM(finalizado) AS finalizado',
                                    'SUM(anulado) AS anulado'                               
                                ],                        
                                sqlWhere : [ " AND YEAR(fecha) BETWEEN '"+year1+"' AND '"+year2+"' AND problema='false' "+permisos.whereIncidencia+whereArea ],                                   
                                sqlGroupBy : "year,mes",
                                sqlOrderBy : "year,mes"                                           
                            }

                let res = await cargarFilas('incidencias_estados_vista','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                let response = res.data;                           
                var arrayApiladoIncidencias = [[
                                                'ESTADO',
                                                LabelsDashboardContainer.TableCol2DashboardContainer[this.state.idioma],
                                                LabelsDashboardContainer.TableCol3DashboardContainer[this.state.idioma],
                                                LabelsDashboardContainer.TableCol4DashboardContainer[this.state.idioma],
                                                LabelsDashboardContainer.TableCol5DashboardContainer[this.state.idioma],
                                                LabelsDashboardContainer.TableCol6DashboardContainer[this.state.idioma],
                                                LabelsDashboardContainer.TableCol7DashboardContainer[this.state.idioma],
                                                LabelsDashboardContainer.TableCol8DashboardContainer[this.state.idioma]
                                              ]];                
                let x = 0;
                let label = '';
                for(i in response){
                    label = arrayMeses[response[i].mes];
                    if(year1 !== year2){
                        label = arrayMeses[response[i].mes]+' '+response[i].year;
                    }
                    x++;
                    arrayApiladoIncidencias.push([label,response[i].sin_asignar*1,response[i].asignado*1,response[i].en_proceso*1,response[i].solucionado*1,response[i].rechazado*1,response[i].finalizado*1,response[i].anulado*1]);                    
                } 
                if(x===0){
                    arrayApiladoIncidencias.push([arrayMeses[0],0,0,0,0,0,0,0]);                    
                }                                                         
                graficoApiladoIncidencias = arrayApiladoIncidencias;                        
            }
             //indicador grafico de problemas apilado      
            if(permisos.displayProblemas === 'block'){  
                sqlParams = {
                                sqlCols : [
                                    'MONTH(fecha) AS mes',
                                    'YEAR(fecha) AS year',
                                    'SUM(sin_asignar) AS sin_asignar',
                                    'SUM(asignado) AS asignado',
                                    'SUM(en_proceso) AS en_proceso',
                                    'SUM(solucionado) AS solucionado',
                                    'SUM(rechazado) AS rechazado',
                                    'SUM(finalizado) AS finalizado',
                                    'SUM(anulado) AS anulado'                               
                                ],                        
                                sqlWhere : [ " AND YEAR(fecha) BETWEEN '"+year1+"' AND '"+year2+"' AND problema='true' "+permisos.whereProblema+whereArea ],    
                                sqlGroupBy : "year,mes",
                                sqlOrderBy : "year,mes"                                           
                            }

                let res = await cargarFilas('incidencias_estados_vista','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response = res.data;                            
                var arrayApiladoProblemas = [['ESTADO', 'SIN ASIGNAR','ASIGNADO','EN PROCESO','SOLUCIONADO','RECHAZADO','FINALIZADO','ANULADO']];                
                let x = 0;
                let label = '';
                for(i in response){
                    label = arrayMeses[response[i].mes];
                    if(year1 !== year2){
                        label = arrayMeses[response[i].mes]+' '+response[i].year;
                    }
                    x++;
                    arrayApiladoProblemas.push([label,response[i].sin_asignar*1,response[i].asignado*1,response[i].en_proceso*1,response[i].solucionado*1,response[i].rechazado*1,response[i].finalizado*1,response[i].anulado*1]);                    
                } 
                if(x===0){
                    arrayApiladoProblemas.push([arrayMeses[0],0,0,0,0,0,0,0]);                    
                }                                 
                graficoApiladoProblemas = arrayApiladoProblemas;
            }    
            //indicador grafico de servicios apilado
            if(permisos.displayServicios === 'block'){        
                sqlParams = {
                                sqlCols : [
                                    'MONTH(fecha) AS mes',
                                    'YEAR(fecha) AS year',
                                    'SUM(sin_asignar) AS sin_asignar',
                                    'SUM(asignado) AS asignado',
                                    'SUM(en_proceso) AS en_proceso',
                                    'SUM(solucionado) AS solucionado',
                                    'SUM(rechazado) AS rechazado',
                                    'SUM(finalizado) AS finalizado',
                                    'SUM(anulado) AS anulado'                               
                                ],                        
                                sqlWhere : [ " AND YEAR(fecha) BETWEEN '"+year1+"' AND '"+year2+"' "+permisos.whereServicio+whereArea ],    
                                sqlGroupBy : "year,mes",
                                sqlOrderBy : "year,mes"                                           
                            }

                let res = await cargarFilas('servicios_estados_vista','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);           
                let response = res.data;                          
                var arrayApiladoServicios = [['ESTADO', 'SIN ASIGNAR','ASIGNADO','EN PROCESO','SOLUCIONADO','RECHAZADO','FINALIZADO','ANULADO']];                
                let x = 0;
                let label = '';
                for(i in response){
                    label = arrayMeses[response[i].mes];
                    if(year1 !== year2){
                        label = arrayMeses[response[i].mes]+' '+response[i].year;
                    }
                    x++;
                    arrayApiladoServicios.push([label,response[i].sin_asignar*1,response[i].asignado*1,response[i].en_proceso*1,response[i].solucionado*1,response[i].rechazado*1,response[i].finalizado*1,response[i].anulado*1]);                    
                }
                if(x===0){
                    arrayApiladoServicios.push([arrayMeses[0],0,0,0,0,0,0,0]);                    
                }                                 
                graficoApiladoServicios = arrayApiladoServicios; 
            }

            let arrayIndicadores = {                
                indicadorIncidencias : indicadorIncidencias,
                indicadorProblemas : indicadorProblemas,
                indicadorServicios : indicadorServicios,
                indicadorPromedioIncidencias : indicadorPromedioIncidencias,
                indicadorPromedioProblemas : indicadorPromedioProblemas,
                indicadorPromedioServicios : indicadorPromedioServicios,
                indicadorIncidenciasPunt : indicadorIncidenciasPunt,
                indicadorProblemasPunt : indicadorProblemasPunt,
                indicadorServiciosPunt : indicadorServiciosPunt,
                graficoTortaIncidencias : graficoTortaIncidencias,
                graficoTortaProblemas : graficoTortaProblemas,
                graficoTortaServicios : graficoTortaServicios,
                graficoTortaIncidenciasTecnicos : graficoTortaIncidenciasTecnicos,
                graficoTortaProblemasTecnicos       : graficoTortaProblemasTecnicos      ,
                graficoTortaServiciosTecnicos : graficoTortaServiciosTecnicos,
                graficoTortaIncidenciasCump : graficoTortaIncidenciasCump,
                graficoTortaProblemasCump : graficoTortaProblemasCump,
                graficoTortaServiciosCump : graficoTortaServiciosCump,
                graficoTortaIncidenciasPunt : graficoTortaIncidenciasPunt,
                graficoTortaProblemasPunt : graficoTortaProblemasPunt,
                graficoTortaServiciosPunt : graficoTortaServiciosPunt,
                graficoApiladoIncidencias : graficoApiladoIncidencias,
                graficoApiladoProblemas : graficoApiladoProblemas,
                graficoApiladoServicios : graficoApiladoServicios,
                stateYear1  : stateYear1,
                stateYear2  : stateYear2,                
            }            
            this.setState({indicadores : arrayIndicadores});
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message);
        }
    }     
  	render() {        
        let permisos = this.state.permisos;
        let indicadores = this.state.indicadores; 
        let titleApilados = ' '+indicadores.stateYear1;
        if(indicadores.stateYear1 !== indicadores.stateYear2){
            titleApilados = 's '+indicadores.stateYear1+' - '+indicadores.stateYear2;
        }
        //LOS TIPOS DE GRAFICO SON PieChart y ColumnChart                    
        return ( 
            <div id="DashboardContenedor">
                    <ul>                        
                        <div style={{display:permisos.displayIncidencias}}>
                            <DashboardOption                              
                                titulo={LabelsDashboardContainer.Ind1DashboardContainer[this.state.idioma]}                            
                                tipo="cifra" 
                                icono="contact_support"
                                colorIcono="orange"
                                valor={indicadores.indicadorIncidencias}
                            />
                        </div>
                        <div style={{display:permisos.displayProblemas}}> 
                            <DashboardOption                              
                                titulo={LabelsDashboardContainer.Ind2DashboardContainer[this.state.idioma]}                            
                                tipo="cifra" 
                                icono="warning"
                                colorIcono="red"
                                valor={indicadores.indicadorProblemas}
                            /> 
                        </div>
                        <div style={{display:permisos.displayServicios}}>
                            <DashboardOption                              
                                titulo={LabelsDashboardContainer.Ind3DashboardContainer[this.state.idioma]}                            
                                tipo="cifra"
                                icono="build"
                                colorIcono="blue" 
                                valor={indicadores.indicadorServicios}
                            /> 
                        </div>
                        <div style={{display:permisos.displayPromI}}>
                            <DashboardOption                              
                                titulo={LabelsDashboardContainer.Ind4DashboardContainer[this.state.idioma]}                            
                                tipo="cifra" 
                                icono="timer"
                                colorIcono="orange" 
                                valor={indicadores.indicadorPromedioIncidencias}
                            /> 
                        </div>  
                        <div style={{display:permisos.displayPromP}}>
                            <DashboardOption                              
                                titulo={LabelsDashboardContainer.Ind5DashboardContainer[this.state.idioma]}                           
                                tipo="cifra"
                                icono="timer"
                                colorIcono="red" 
                                valor={indicadores.indicadorPromedioProblemas}
                            /> 
                        </div> 
                        <div style={{display:permisos.displayPromS}}>
                            <DashboardOption                              
                                titulo={LabelsDashboardContainer.Ind6DashboardContainer[this.state.idioma]}                            
                                tipo="cifra"
                                icono="timer"
                                colorIcono="blue" 
                                valor={indicadores.indicadorPromedioServicios}
                            /> 
                        </div> 
                        <div style={{display:permisos.displayPromI}}>
                            <DashboardOption                              
                                titulo={LabelsDashboardContainer.Ind7DashboardContainer[this.state.idioma]}                            
                                tipo="cifra"
                                icono="verified"
                                colorIcono="orange" 
                                valor={indicadores.indicadorIncidenciasPunt}
                            /> 
                        </div>  
                        <div style={{display:permisos.displayPromP}}>
                            <DashboardOption                              
                                titulo={LabelsDashboardContainer.Ind8DashboardContainer[this.state.idioma]}                            
                                tipo="cifra"
                                icono="verified"
                                colorIcono="red" 
                                valor={indicadores.indicadorProblemasPunt}
                            /> 
                        </div> 
                        <div style={{display:permisos.displayPromS}}>
                            <DashboardOption                              
                                titulo={LabelsDashboardContainer.Ind9DashboardContainer[this.state.idioma]}                            
                                tipo="cifra" 
                                icono="verified"
                                colorIcono="blue"
                                valor={indicadores.indicadorServiciosPunt}
                            /> 
                        </div> 
                        <div style={{display:permisos.displayIncidencias}}>                     
                            <DashboardOption                             
                                titulo='Incidencias'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaIncidencias,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind10DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right: 5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <div style={{display:permisos.displayProblemas}}>
                            <DashboardOption                             
                                titulo='Problemas'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaProblemas,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind11DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        left: -10,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />   
                        </div>
                        <div style={{display:permisos.displayServicios}}>
                            <DashboardOption                             
                                titulo='Servicios'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaServicios,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind12DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right: 5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "200px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },  
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      sliceVisibilityThreshold: 0.004,                                                                                                                                        
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div> 
                        <div style={{display:permisos.displayPromI}}>                     
                            <DashboardOption                             
                                titulo='Incidencias'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaIncidenciasTecnicos,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind13DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right : 5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <div style={{display:permisos.displayPromP}}>
                            <DashboardOption                             
                                titulo='Problemas'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaProblemasTecnicos,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind14DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right: 5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />   
                        </div>
                        <div style={{display:permisos.displayPromS}}>
                            <DashboardOption                             
                                titulo='Servicios'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaServiciosTecnicos,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind15DashboardContainer[this.state.idioma],
                                                                     titleTextStyle : {
                                                                        fontSize : 12,
                                                                        color: '#005884'
                                                                     },
                                                                     chartArea: {
                                                                        right:5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},                                                                      
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <div style={{display:permisos.displayIncidencias}}>                     
                            <DashboardOption                             
                                titulo='Incidencias por Cumplimiento de SLA'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaIncidenciasCump,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind16DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right : 5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },                                                                      
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <div style={{display:permisos.displayProblemas}}>
                            <DashboardOption                             
                                titulo='Problemas'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaProblemasCump,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind17DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right:5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />   
                        </div>
                        <div style={{display:permisos.displayServicios}}>
                            <DashboardOption                             
                                titulo='Servicios'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaServiciosCump,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind18DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right:5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div> 
                        <div style={{display:permisos.displayPromI}}>                     
                            <DashboardOption                             
                                titulo='Incidencias por Puntaje'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaIncidenciasPunt,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind19DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right:5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <div style={{display:permisos.displayPromP}}>
                            <DashboardOption                             
                                titulo='Problemas'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaProblemasPunt,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind20DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right:5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />   
                        </div>
                        <div style={{display:permisos.displayPromS}}>
                            <DashboardOption                             
                                titulo='Servicios'                            
                                tipo="grafico"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "PieChart",
                                                    width     : '100%',
                                                    height    : '250px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTortaServiciosPunt,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind21DashboardContainer[this.state.idioma],
                                                                     chartArea: {
                                                                        right:5,
                                                                        top: 20,
                                                                        width: "100%",
                                                                        height: "100%"
                                                                     },
                                                                     legend: {
                                                                        position: "left",
                                                                        alignment: "start",
                                                                        width: "500px",
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 12
                                                                        }
                                                                      },
                                                                      pieSliceTextStyle : {
                                                                            fontSize : 8,
                                                                      },
                                                                      tooltip: {isHtml: true},
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <br />
                        <div style={{display:permisos.displayIncidencias}}>
                            <DashboardOption                             
                                titulo='Incidencias'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoIncidencias,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind22DashboardContainer[this.state.idioma]+titleApilados,
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            /> 
                        </div>
                        <div id="no-more-tables" style={{display:permisos.displayIncidencias,padding:'0 10px'}}>
                            <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}}>
                                 <thead className="cf">
                                    <tr>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol1DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol2DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol3DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol4DashboardContainer[this.state.idioma]}</th>                                  
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol5DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol6DashboardContainer[this.state.idioma]}</th>  
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol7DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol8DashboardContainer[this.state.idioma]}</th>                                      
                                    </tr>
                                </thead>    
                                <tbody>
                                {
                                    indicadores.graficoApiladoIncidencias.map((listado,i) => {
                                        if(i===0){
                                            return <tr key={ i } ></tr>;
                                        }
                                        else{
                                            return <tr key={ i } >
                                                        <td data-title={LabelsDashboardContainer.TableCol1DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[0]}</td> 
                                                        <td data-title={LabelsDashboardContainer.TableCol2DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[1]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol3DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[2]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol4DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[3]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol5DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[4]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol6DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[5]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol7DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[6]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol8DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[7]}&nbsp;</td>                                                                                           
                                                   </tr>
                                        }                                    
                                    })
                                }
                                </tbody>                                      
                            </Table>
                        </div>
                        <br />
                        <br />
                        <div style={{display:permisos.displayProblemas}}>
                            <DashboardOption                             
                                titulo='Problemas'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoProblemas,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind23DashboardContainer[this.state.idioma]+titleApilados,
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>
                        <div id="no-more-tables" style={{display:permisos.displayProblemas,padding:'0 10px'}}>
                            <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}}>
                                <thead className="cf">
                                    <tr>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol1DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol2DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol3DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol4DashboardContainer[this.state.idioma]}</th>                                  
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol5DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol6DashboardContainer[this.state.idioma]}</th>  
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol7DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol8DashboardContainer[this.state.idioma]}</th>                                      
                                    </tr>
                                </thead>    
                                <tbody>
                                {
                                    indicadores.graficoApiladoProblemas.map((listado,i) => {
                                        if(i===0){
                                            return <tr key={ i } ></tr>;
                                        }
                                        else{
                                            return <tr key={ i } >
                                                        <td data-title={LabelsDashboardContainer.TableCol1DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[0]}</td> 
                                                        <td data-title={LabelsDashboardContainer.TableCol2DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[1]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol3DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[2]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol4DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[3]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol5DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[4]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol6DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[5]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol7DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[6]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol8DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[7]}&nbsp;</td>                                                                                           
                                                   </tr>
                                        }                                    
                                    })
                                }
                                </tbody>                                      
                            </Table>
                        </div>                        
                        <br />
                        <div style={{display:permisos.displayServicios}}>
                            <DashboardOption                             
                                titulo='Servicios'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoServicios,
                                                    options    : {
                                                                     title: LabelsDashboardContainer.Ind24DashboardContainer[this.state.idioma]+titleApilados,
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            /> 
                        </div>
                        <div id="no-more-tables" style={{display:permisos.displayServicios,padding:'0 10px'}}>
                            <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}}>
                                 <thead className="cf">
                                    <tr>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol1DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol2DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol3DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol4DashboardContainer[this.state.idioma]}</th>                                  
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol5DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol6DashboardContainer[this.state.idioma]}</th>  
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol7DashboardContainer[this.state.idioma]}</th>
                                        <th style={{fontSize:'12px'}}>{LabelsDashboardContainer.TableCol8DashboardContainer[this.state.idioma]}</th>                                      
                                    </tr>
                                </thead>    
                                <tbody>
                                {
                                    indicadores.graficoApiladoServicios.map((listado,i) => {
                                        if(i===0){
                                            return <tr key={ i } ></tr>;
                                        }
                                        else{
                                            return <tr key={ i } >
                                                        <td data-title={LabelsDashboardContainer.TableCol1DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[0]}</td> 
                                                        <td data-title={LabelsDashboardContainer.TableCol2DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[1]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol3DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[2]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol4DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[3]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol5DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[4]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol6DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[5]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol7DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[6]}&nbsp;</td>
                                                        <td data-title={LabelsDashboardContainer.TableCol8DashboardContainer[this.state.idioma]} style={{fontSize:'10px'}}>{listado[7]}&nbsp;</td>                                                                                          
                                                   </tr>
                                        }                                    
                                    })
                                }
                                </tbody>                                      
                            </Table>
                        </div>
                        <br />
                        <br />                                                               
                    </ul>
                </div>
        );
    } 
}

export default DashboardContainer