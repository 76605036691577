/**
* CLASS WelcomePage
*
* Contiene la pantalla de ver solicitudes pendientes por finalizar
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/


import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import globalState from '../configuration/GlobalState';
import {validarPermiso} from '../configuration/GlobalFunctions';
import {cargarFilas} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import '../../css/alertify.css';
import './desktop.css';

class WelcomePage extends Component {
	constructor(props) {        
        super(props);   
        this.state = {           
            listadoPendientes : [],  
            database : globalState.getState().companyData[0].bd         
        };
        let idUsuario = globalState.getState().userData[0].id;
        let sqlPendientes = '';
        if(validarPermiso(49)){
        	let idArea = globalState.getState().userData[0].id_area;
            let andArea = "";
            if(idArea > 0){
                andArea = " AND id_area="+idArea;
            }
            sqlPendientes =    `tabla,
								id,
								usuario,
								DATE_FORMAT(fecha_vencimiento,'%Y-%m-%d %H:%i') AS fecha_vencimiento,
								DATE_FORMAT(tope,'%Y-%m-%d %H:%i') AS fecha_tope,
								IF(tope<=NOW(),'red','black') AS color
							FROM
								(
									SELECT
										'incidencia' AS tabla,
							      		T1.id,	
							      		CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario, 									
										T1.fecha_vencimiento_incidencia AS fecha_vencimiento,
										DATE_SUB(
	                                        fecha_vencimiento_incidencia,
	                                        INTERVAL (
		                                            SELECT
		                                                tiempo_incidencia
		                                            FROM
		                                                solicitudes_notificaciones		                                            
		                                    )
		                                     HOUR
		                                ) AS tope 	                                   
									FROM
										incidencias AS T1
									INNER JOIN users AS U ON(U.id = T1.id_tecnico_incidencia)
									WHERE
										T1.activo = 1
										AND T1.estado = 2
										`+andArea+`										
										AND T1.problema = 'false'										
									UNION
									SELECT
										'problema' AS tabla,
							      		T1.id,	
							      		CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario, 									
										T1.fecha_vencimiento_problema AS fecha_vencimiento,
										DATE_SUB(
	                                        fecha_vencimiento_problema,
	                                        INTERVAL (
		                                            SELECT
		                                                tiempo_problema
		                                            FROM
		                                                solicitudes_notificaciones		                                            
		                                    )
		                                     HOUR
		                                ) AS tope 
									FROM
										incidencias AS T1
									INNER JOIN users AS U ON(U.id = T1.id_tecnico_problema)	
									WHERE
										T1.activo = 1
										AND T1.estado = 2
										`+andArea+`
										AND T1.problema = 'true'									
									UNION
									SELECT
							        	'servicio' AS tabla,
							        	T2.id,	
							        	CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario, 									
										T2.fecha_vencimiento_servicio AS fecha_vencimiento,
										DATE_SUB(
	                                        fecha_vencimiento_servicio,
	                                        INTERVAL (
		                                            SELECT
		                                                tiempo_servicio
		                                            FROM
		                                                solicitudes_notificaciones		                                            
		                                    )
		                                     HOUR
		                                ) AS tope
									FROM
										servicios AS T2
									INNER JOIN users AS U ON(U.id = T2.id_tecnico_servicio)	
									WHERE
										T2.activo = 1
										AND T2.estado = 2
										`+andArea+`									
								) AS tabla ORDER BY fecha_vencimiento DESC`;
        }
        else {
        	var sqlIncidencias = '';
        	var sqlProblemas   = '';
        	var sqlServicios   = '';
        	if(validarPermiso(37)){
	            sqlIncidencias =   `SELECT
										'incidencia' AS tabla,
							      		T1.id,
							      		CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario, 										
										T1.fecha_vencimiento_incidencia AS fecha_vencimiento,
										DATE_SUB(
	                                        fecha_vencimiento_incidencia,
	                                        INTERVAL (
		                                            SELECT
		                                                tiempo_incidencia
		                                            FROM
		                                                solicitudes_notificaciones		                                            
		                                    )
		                                     HOUR
		                                ) AS tope 
									FROM
										incidencias AS T1
									INNER JOIN users AS U ON(U.id = T1.id_tecnico_incidencia)
									WHERE
										T1.activo = 1
										AND T1.estado = 2
										AND T1.problema = 'false'										
										AND id_tecnico_incidencia = `+idUsuario+`
									UNION `;
			}						
			if(validarPermiso(38)){		
				sqlProblemas = `SELECT
									'problema' AS tabla,
						            T1.id,	
						            CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario,								
									T1.fecha_vencimiento_problema AS fecha_vencimiento,
									DATE_SUB(
                                        fecha_vencimiento_problema,
                                        INTERVAL (
	                                            SELECT
	                                                tiempo_problema
	                                            FROM
	                                                solicitudes_notificaciones	                                            
	                                    )
	                                     HOUR
	                                ) AS tope 
								FROM
									incidencias AS T1
								INNER JOIN users AS U ON(U.id = T1.id_tecnico_problema)
								WHERE
									T1.activo = 1
									AND T1.estado = 2
									AND T1.problema = 'true'									
									AND id_tecnico_problema = `+idUsuario+`
								UNION `;
			}				  
			if(validarPermiso(39)){	
				sqlServicios = `SELECT
					        		'servicio' AS tabla,
					        		T2.id,	
					        		CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario,								
									T2.fecha_vencimiento_servicio AS fecha_vencimiento,
									DATE_SUB(
                                        fecha_vencimiento_servicio,
                                        INTERVAL (
	                                            SELECT
	                                                tiempo_servicio
	                                            FROM
	                                                solicitudes_notificaciones	                                            
	                                    )
	                                     HOUR
	                                ) AS tope
								FROM
									servicios AS T2
								INNER JOIN users AS U ON(U.id = T2.id_tecnico_servicio)
								WHERE
									T2.activo = 1
									AND T2.estado = 2									
									AND id_tecnico_servicio = `+idUsuario+`
								UNION `;
			}

			sqlPendientes = sqlIncidencias+sqlProblemas+sqlServicios;
			sqlPendientes = sqlPendientes.slice(0,-6); 

	        sqlPendientes =`    tabla,
								id,
								usuario,
								DATE_FORMAT(fecha_vencimiento,'%Y-%m-%d %H:%i') AS fecha_vencimiento,
								DATE_FORMAT(tope,'%Y-%m-%d %H:%i') AS fecha_tope,
								IF(tope<=NOW(),'red','black') AS color
							FROM
								(`+sqlPendientes+`) AS tabla ORDER BY fecha_vencimiento DESC`;	        
         	
        }

        let sqlParams = { 
                            sqlCols : [ sqlPendientes ],                                                                                                                  
                        }
        cargarFilas('','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{                
                this.setState({listadoPendientes : response});                
            }     
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })
    }

  	render() {
  	  	return (<div id="no-more-tables" style={{overflowY:'auto',height:'210px'}}>
	                <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}}>
	                     <thead className="cf">
	                        <tr>
	                            <th style={{fontSize:'12px'}}>No</th>
	                            <th style={{fontSize:'12px'}}>Tipo</th>
	                            <th style={{fontSize:'12px'}}>Vencimiento</th>
	                            <th style={{fontSize:'12px'}}>Asignado</th>                            
	                        </tr>
	                    </thead>    
	                    <tbody>
	                    {
	                        this.state.listadoPendientes.map((listado,i) => {
	                            return <tr key={ i } >
	                                        <td data-title="Consecutivo" style={{fontSize:'10px'}}>{listado.id}</td> 
	                                        <td data-title="Tipo" style={{fontSize:'10px'}}>{listado.tabla}&nbsp;</td>
	                                        <td data-title="Vencimiento" style={{fontSize:'10px',color:listado.color}}>{listado.fecha_vencimiento}&nbsp;</td> 
	                                        <td data-title="Asignado" style={{fontSize:'9px'}}>{listado.usuario}&nbsp;</td>                                      
	                                    </tr>
	                        })
	                    }
	                    </tbody>                                      
	                </Table>
	            </div>	       		    		
	    );
  	}
}

export default WelcomePage