/**
* CLASS TrendContainer
*
* Contiene el contenedor con las tendencias
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DashboardOption from './DashboardOption';
import {cargarFilas,validarPermisoRol} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import { diccionario } from '../configuration/configuration.json';
import alertify from 'alertifyjs';
import './dashboard.css';

const { LabelsTrendContainer } = diccionario;

class TrendContainer extends Component {
    constructor(props){
        super(props);          
        this.state={
            indicadores : {                
                graficoTendenciaIncidencias : [],
                graficoTendenciaProblemas : [],
                graficoTendenciaServicios : [], 
                graficoApiladoDepIncidencias : [], 
                graficoApiladoDepIncidencias1 : [], 
                graficoApiladoDepProblemas : [], 
                graficoApiladoDepProblemas1 : [], 
                graficoApiladoDepServicios : [], 
                graficoApiladoDepServicios1 : [],             
                stateYear1 : '',
            },
            permisos : {                            
                displayIncidencias : 'none',
                displayProblemas : 'none',
                displayServicios : 'none',
                whereIncidencia : '',
                whereProblema : '',
                whereServicio : '',                
            },
            idioma : globalState.getState().idioma,
            database : globalState.getState().companyData[0].bd            
        };        
    }
    componentWillMount(){
        this.cargarIndicadores(this.props.params.date1,this.props.params.date2,this.props.params.usuario,this.props.params.rol_usuario,this.props.params.filtroArea,this.props.params.permisos);         
    } 
    componentDidUpdate(prevProps){
        if (this.props !== prevProps) {              
            this.cargarIndicadores(this.props.params.date1,this.props.params.date2,this.props.params.usuario,this.props.params.rol_usuario,this.props.params.filtroArea,this.props.params.permisos); 
        }
    }
    async cargarIndicadores(date1,date2,idUser,rolUser,filtroArea,permisos){  //cargar los indicadores                             
        try{
            var verOtros = "true";        
            if(idUser === 0){
                idUser = globalState.getState().userData[0].id;
                verOtros = "false";
            }
            let arrayPermisos = {};                    
            if(permisos.permisoVerTecnicos === true && verOtros==="false"){
                //visualizaciones 
                arrayPermisos = { 
                    displayIncidencias : 'block',
                    displayProblemas   : 'block',
                    displayServicios   : 'block',                
                    whereIncidencia : '',
                    whereProblema : '',                 
                    whereServicio : '', 
                };
            } 
            else if(permisos.permisoVerTecnicos === false || verOtros==="true"){           
                let res = await validarPermisoRol(rolUser,46);
                let response = res.data;            
                let displayIncidencias = 'none';
                let whereIncidencia = '';
                if((response.msg === true && verOtros === "true") || (verOtros==="false" && permisos.indicadoresIncidencias === true)){
                    displayIncidencias = 'block';
                    whereIncidencia    = ' AND T1.id_tecnico_incidencia = '+idUser;                
                }            
                
                res = await validarPermisoRol(rolUser,47);
                response = res.data;              
                let displayProblemas = 'none';
                let whereProblema = '';                                             
                if((response.msg === true && verOtros === "true") || (verOtros==="false" && permisos.indicadoresProblemas === true)){
                    displayProblemas = 'block';
                    whereProblema = ' AND T1.id_tecnico_problema = '+idUser;                    
                }
                        
                res = await validarPermisoRol(rolUser,48);
                response = res.data;
                let displayServicios = 'none';  
                let whereServicio = '';        
                if((response.msg === true && verOtros === "true") || (verOtros==="false" && permisos.indicadoresServicios === true)){
                    displayServicios = 'block';                
                    whereServicio = ' AND T1.id_tecnico_servicio = '+idUser;                   
                }                                  
                arrayPermisos = { 
                    displayIncidencias : displayIncidencias,
                    displayProblemas   : displayProblemas,
                    displayServicios   : displayServicios,                
                    whereIncidencia : whereIncidencia,
                    whereProblema : whereProblema,                 
                    whereServicio : whereServicio,                 
                };
            }         
            this.setState({permisos : arrayPermisos},()=>{
                this.ejecutarQuerysDashboard(date1,date2,filtroArea,verOtros);
            });
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message);
        }
    }

    async ejecutarQuerysDashboard(date1,date2,filtroArea,verOtros){ 
        try{
            let permisos = this.state.permisos;              
            //formato de la fecha
            date1 = new Date(date1.getTime() - (date1.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            date2 = new Date(date2.getTime() - (date2.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
            //cargar los indicadores
            //indicador 1 de incidencias 
            var year1 = date1.substring(0,4);  
            var year2 = date2.substring(0,4); 
            if(year2 !== year1){
                year1 = year2;
            }   
            var arrayMeses = [
                                '',
                                LabelsTrendContainer.TableMes1TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes2TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes3TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes4TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes5TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes6TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes7TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes8TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes9TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes10TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes11TrendContainer[this.state.idioma],
                                LabelsTrendContainer.TableMes12TrendContainer[this.state.idioma]
                             ];

            var whereArea  = '';        

            if(filtroArea > 0 && verOtros==='false'){
                whereArea  = ' AND T1.id_area='+filtroArea;            
            }
            
            let graficoTendenciaIncidencias = [];
            let graficoTendenciaProblemas = [];
            let graficoTendenciaServicios = [];
            let graficoApiladoDepIncidencias = [];
            let graficoApiladoDepIncidencias1 = [];
            let graficoApiladoDepProblemas = [];
            let graficoApiladoDepProblemas1 = [];
            let graficoApiladoDepServicios = [];
            let graficoApiladoDepServicios1 = [];
            let stateYear1 = year1;            
            let i = 0; 
            let j = 0;
            let sqlParams = '';

            //grafico apilado por departamentos
            sqlParams = {};

            let res = await cargarFilas('departamentos','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
            let response = res.data;   

            let stringCols = "";
            for(i in response){            
                stringCols += "SUM(IF(U.id_departamento='"+response[i].id+"',1,0)) AS '"+response[i].nombre+"',";
            } 
            stringCols = stringCols.slice(0,-1);     
            
            //indicador grafico de incidencias Tendencia 
            let arrayTrend = {};
            
            if(permisos.displayIncidencias === 'block'){  
                //Inicializar el array de los meses
                for(i=1;i<=12;i++){
                    arrayTrend[i] = {
                        'mes' : i,
                        'total1' : 0,
                        'total2' : 0
                    }
                }
  
                //Año actual   
                sqlParams = {
                                sqlCols : [                                
                                    `MONTH(T1.fecha) AS mes`,
                                    'COUNT(DISTINCT(T1.id)) AS total'                             
                                ],           
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+year1+"' AND T1.problema='false' "+permisos.whereIncidencia+whereArea ],   
                                sqlGroupBy : "mes",    
                                sqlOrderBy : "mes"                                           
                            };

                let res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                let response1 = res.data;                 

                //llenar año actual
                for(i=1;i<=12;i++){
                    for(let j in response1){
                        if(response1[j]['mes'] === i)
                        {
                            arrayTrend[i].total1 = response1[j]['total'];
                        }
                    }
                }               

                //Año anterior   
                sqlParams = {
                                sqlCols : [                                
                                    `MONTH(T1.fecha) AS mes`,
                                    'COUNT(DISTINCT(T1.id)) AS total'                            
                                ],                                                       
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+(year1-1)+"' AND T1.problema='false' "+permisos.whereIncidencia+whereArea ],   
                                sqlGroupBy : "mes",    
                                sqlOrderBy : "mes"                                           
                            };

                res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                let response2 = res.data;

                //Llenar año anterior
                for(i=1;i<=12;i++){
                    for(let j in response2){
                        if(response2[j]['mes'] === i)
                        {
                            arrayTrend[i].total2 = response2[j]['total'];
                        }
                    }
                }                

                var arrayTendenciaIncidencias = [[{ type: 'string', label: 'Mes' }, 'Año '+year1, 'Año '+(year1-1)]];                
                let x = 0;
                for(i in arrayTrend){
                    x++;
                    arrayTendenciaIncidencias.push([arrayMeses[arrayTrend[i].mes].toString(),arrayTrend[i].total1,arrayTrend[i].total2]);                    
                } 
                if(x===0){
                    arrayTendenciaIncidencias.push([arrayMeses[0],0,0]);                    
                }                                                      
                graficoTendenciaIncidencias = arrayTendenciaIncidencias; 
                //grafico apilado por departamentos
                sqlParams = {
                                sqlCols : [
                                    'MONTH(T1.fecha) AS mes',
                                    stringCols                                                              
                                ],                        
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+year1+"' AND T1.problema='false' "+permisos.whereIncidencia+whereArea ],    
                                sqlJoin : [ " INNER JOIN users AS U ON(U.id = T1.id_usuario)" ],
                                sqlGroupBy : "mes",                                
                                sqlOrderBy : "mes"                                           
                            }

                res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                response = res.data;     

                let arrayApiladoDepIncidencias = [];  
                let arrayHead = [];
                let arrayResult = [];
                let cuenta = 0;
                //generar el array                
                for(i in response){  
                    x++;             
                    arrayResult = [];
                    cuenta = 0;
                    for(j in response[i]){                    
                        if(i === '0'){                        
                            arrayHead.push(j);
                        }
                        if(cuenta===0){
                            response[i][j] = arrayMeses[response[i][j]];
                        }  
                        else{
                            response[i][j] *= 1;
                        }                  
                        arrayResult.push(response[i][j]);
                        cuenta++;
                    }
                    if(i==='0'){
                        arrayApiladoDepIncidencias.push(arrayHead);
                    }               
                    arrayApiladoDepIncidencias.push(arrayResult);                    
                }
                if(cuenta===0){
                    arrayApiladoDepIncidencias = [['DEPARTAMENTO',''],['',0]];                    
                }   
                //enviar array al grafico                                                               
                graficoApiladoDepIncidencias = arrayApiladoDepIncidencias;
                //grafico apilado por departamentos año anterior
                sqlParams = {
                                sqlCols : [
                                    'MONTH(T1.fecha) AS mes',
                                    stringCols                                                              
                                ],                        
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+(year1-1)+"' AND T1.problema='false' "+permisos.whereIncidencia+whereArea ],    
                                sqlJoin : [ " INNER JOIN users AS U ON(U.id = T1.id_usuario)" ],
                                sqlGroupBy : "mes",                                
                                sqlOrderBy : "mes"                                           
                            }

                res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                response = res.data;                
                //generar el array para el grafico apilado
                arrayApiladoDepIncidencias = [];  
                arrayHead = [];
                arrayResult = [];
                cuenta = 0;                
                for(i in response){               
                    arrayResult = [];
                    cuenta = 0;
                    for(j in response[i]){                    
                        if(i === '0'){                        
                            arrayHead.push(j);
                        }
                        if(cuenta===0){
                            response[i][j] = arrayMeses[response[i][j]];
                        }
                        else{
                            response[i][j] *= 1;
                        }                    
                        arrayResult.push(response[i][j]);
                        cuenta++;                        
                    }
                    if(i==='0'){
                        arrayApiladoDepIncidencias.push(arrayHead);
                    }               
                    arrayApiladoDepIncidencias.push(arrayResult);                    
                }
                if(cuenta===0){
                    arrayApiladoDepIncidencias = [['DEPARTAMENTO',''],['',0]];                    
                }
                //enviar al grafico                                                                  
                graficoApiladoDepIncidencias1 = arrayApiladoDepIncidencias;
            }
             //indicador grafico de problemas Tendencia      
            if(permisos.displayProblemas === 'block'){  
                //Inicializar el array de los meses
                for(i=1;i<=12;i++){
                    arrayTrend[i] = {
                        'mes' : i,
                        'total1' : 0,
                        'total2' : 0
                    }
                }
                //Llenar año actual
                sqlParams = {
                                sqlCols : [
                                    `MONTH(T1.fecha) AS mes`,
                                    'COUNT(DISTINCT(T1.id)) AS total'                              
                                ],                                                        
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+year1+"' AND T1.problema='true' "+permisos.whereProblema+whereArea ],    
                                sqlGroupBy : "mes",
                                sqlOrderBy : "mes"                                           
                            }

                let res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response1 = res.data; 

                //llenar año actual
                for(i=1;i<=12;i++){
                    for(let j in response1){
                        if(response1[j]['mes'] === i)
                        {
                            arrayTrend[i].total1 = response1[j]['total'];
                        }
                    }
                } 

                //Llenar año anterior
                sqlParams = {
                                sqlCols : [
                                    `MONTH(T1.fecha) AS mes`,
                                    'COUNT(DISTINCT(T1.id)) AS total'                              
                                ],                                                        
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+(year1-1)+"' AND T1.problema='true' "+permisos.whereProblema+whereArea ],    
                                sqlGroupBy : "mes",
                                sqlOrderBy : "mes"                                           
                            }

                res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);
                let response2 = res.data; 

                //llenar año anterior
                for(i=1;i<=12;i++){
                    for(let j in response2){
                        if(response2[j]['mes'] === i)
                        {
                            arrayTrend[i].total2 = response2[j]['total'];
                        }
                    }
                }

                var arrayTendenciaProblemas = [[{ type: 'string', label: 'Mes' }, 'Año '+year1, 'Año '+(year1-1)]];                
                let x = 0;
                for(i in arrayTrend){
                    x++;
                    arrayTendenciaProblemas.push([arrayMeses[arrayTrend[i].mes].toString(),arrayTrend[i].total1,arrayTrend[i].total2]);                    
                } 
                if(x===0){
                    arrayTendenciaProblemas.push([arrayMeses[0],0,0]);                    
                }                                 
                graficoTendenciaProblemas = arrayTendenciaProblemas;
                //grafico apilado por departamentos
                sqlParams = {
                                sqlCols : [
                                    'MONTH(T1.fecha) AS mes',
                                    stringCols                                                              
                                ],                        
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+year1+"' AND T1.problema='true' "+permisos.whereProblema+whereArea ],    
                                sqlJoin : [ " INNER JOIN users AS U ON(U.id = T1.id_usuario)" ],
                                sqlGroupBy : "mes",
                                sqlOrderBy : "mes"                                           
                            }

                res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                response = res.data;     

                let arrayApiladoDepProblemas = [];  
                let arrayHead = [];
                let arrayResult = [];
                let cuenta = 0;
                //cargar el array apilado                
                for(i in response){  
                    x++;             
                    arrayResult = [];
                    cuenta = 0;
                    for(j in response[i]){                    
                        if(i === '0'){                        
                            arrayHead.push(j);
                        }
                        if(cuenta===0){
                            response[i][j] = arrayMeses[response[i][j]];
                        }  
                        else{
                            response[i][j] *= 1;
                        }                  
                        arrayResult.push(response[i][j]);
                        cuenta++;
                    }
                    if(i==='0'){
                        arrayApiladoDepProblemas.push(arrayHead);
                    }               
                    arrayApiladoDepProblemas.push(arrayResult);                    
                }
                if(cuenta===0){
                    arrayApiladoDepProblemas = [['DEPARTAMENTO',''],['',0]];                    
                } 
                //enviar array                                                               
                graficoApiladoDepProblemas = arrayApiladoDepProblemas;
                //grafico apilado por departamentos año anterior
                sqlParams = {
                                sqlCols : [
                                    'MONTH(T1.fecha) AS mes',
                                    stringCols                                                              
                                ],                        
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+(year1-1)+"' AND T1.problema='true' "+permisos.whereProblema+whereArea ],    
                                sqlJoin : [ " INNER JOIN users AS U ON(U.id = T1.id_usuario)" ],
                                sqlGroupBy : "mes",
                                sqlOrderBy : "mes"                                           
                            }

                res = await cargarFilas('incidencias','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                response = res.data;     

                arrayApiladoDepProblemas = [];  
                arrayHead = [];
                arrayResult = [];
                cuenta = 0;
                //cargar array
                for(i in response){               
                    arrayResult = [];
                    cuenta = 0;
                    for(j in response[i]){                    
                        if(i === '0'){                        
                            arrayHead.push(j);
                        }
                        if(cuenta===0){
                            response[i][j] = arrayMeses[response[i][j]];
                        } 
                        else{
                            response[i][j] *= 1;
                        }                   
                        arrayResult.push(response[i][j]);
                        cuenta++;
                    }
                    if(i==='0'){
                        arrayApiladoDepProblemas.push(arrayHead);
                    }               
                    arrayApiladoDepProblemas.push(arrayResult);                    
                }
                if(cuenta===0){
                    arrayApiladoDepProblemas = [['DEPARTAMENTO',''],['',0]];                    
                } 
                //enviar array                                                                 
                graficoApiladoDepProblemas1 = arrayApiladoDepProblemas;
            }    
            //indicador grafico de servicios Tendencia
            if(permisos.displayServicios === 'block'){  
                //Inicializar el array de los meses
                for(i=1;i<=12;i++){
                    arrayTrend[i] = {
                        'mes' : i,
                        'total1' : 0,
                        'total2' : 0
                    }
                }
                //año actual      
                sqlParams = {
                                sqlCols : [
                                    `MONTH(T1.fecha) AS mes`,
                                    'COUNT(DISTINCT(T1.id)) AS total'
                                ],                                            
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+year1+"' "+permisos.whereServicio+whereArea ],    
                                sqlGroupBy : "mes",                            
                                sqlOrderBy : "mes"                                           
                            }

                let res = await cargarFilas('servicios','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);           
                let response1 = res.data; 

                //llenar año actual
                for(i=1;i<=12;i++){
                    for(let j in response1){
                        if(response1[j]['mes'] === i)
                        {
                            arrayTrend[i].total1 = response1[j]['total'];
                        }
                    }
                }

                //año anterior      
                sqlParams = {
                                sqlCols : [
                                    `MONTH(T1.fecha) AS mes`,
                                    'COUNT(DISTINCT(T1.id)) AS total'
                                ],                                            
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+(year1-1)+"' "+permisos.whereServicio+whereArea ],    
                                sqlGroupBy : "mes",                            
                                sqlOrderBy : "mes"                                           
                            }

                res = await cargarFilas('servicios','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);           
                let response2 = res.data; 

                //llenar año anterior
                for(i=1;i<=12;i++){
                    for(let j in response2){
                        if(response2[j]['mes'] === i)
                        {
                            arrayTrend[i].total2 = response2[j]['total'];
                        }
                    }
                }

                var arrayTendenciaServicios = [[{ type: 'string', label: 'Mes' }, 'Año '+year1, 'Año '+(year1-1)]];                 
                let x = 0;
                for(i in arrayTrend){
                    x++;
                    arrayTendenciaServicios.push([arrayMeses[arrayTrend[i].mes].toString(),arrayTrend[i].total1,arrayTrend[i].total2]);                    
                }
                if(x===0){
                    arrayTendenciaServicios.push([arrayMeses[0],0,0]);                    
                }                                 
                graficoTendenciaServicios = arrayTendenciaServicios;
                //grafico apilado por departamentos
                sqlParams = {
                                sqlCols : [
                                    'MONTH(T1.fecha) AS mes',
                                    stringCols                                                              
                                ],                        
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+year1+"' "+permisos.whereServicio+whereArea ],    
                                sqlJoin : [ " INNER JOIN users AS U ON(U.id = T1.id_usuario)" ],
                                sqlGroupBy : "mes",
                                sqlOrderBy : "mes"                                           
                            }

                res = await cargarFilas('servicios','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                response = res.data;     

                let arrayApiladoDepServicios = [];  
                let arrayHead = [];
                let arrayResult = [];
                let cuenta = 0;
                //cargar array
                for(i in response){  
                    x++;             
                    arrayResult = [];
                    cuenta = 0;
                    for(j in response[i]){                    
                        if(i === '0'){                        
                            arrayHead.push(j);
                        }
                        if(cuenta===0){
                            response[i][j] = arrayMeses[response[i][j]];
                        }
                        else{
                            response[i][j] *= 1;
                        }                    
                        arrayResult.push(response[i][j]);
                        cuenta++;
                    }
                    if(i==='0'){
                        arrayApiladoDepServicios.push(arrayHead);
                    }               
                    arrayApiladoDepServicios.push(arrayResult);                    
                }
                if(cuenta===0){
                    arrayApiladoDepServicios = [['DEPARTAMENTO',''],['',0]];                    
                }  
                //enviar array                                              
                graficoApiladoDepServicios = arrayApiladoDepServicios;
                //grafico apilado por departamentos año anterior
                sqlParams = {
                                sqlCols : [
                                    'MONTH(T1.fecha) AS mes',
                                    stringCols                                                              
                                ],                        
                                sqlWhere : [ " AND YEAR(T1.fecha) = '"+(year1-1)+"' "+permisos.whereServicio+whereArea ],    
                                sqlJoin : [ " INNER JOIN users AS U ON(U.id = T1.id_usuario)" ],
                                sqlGroupBy : "mes",
                                sqlOrderBy : "mes"                                           
                            }

                res = await cargarFilas('servicios','',10000000,0,date1,date2,sqlParams,'rows',this.state.database);            
                response = res.data;     

                arrayApiladoDepServicios = [];  
                arrayHead = [];
                arrayResult = [];
                cuenta = 0;
                //cargar array
                for(i in response){               
                    arrayResult = [];
                    cuenta = 0;
                    for(j in response[i]){                    
                        if(i === '0'){                        
                            arrayHead.push(j);
                        }
                        if(cuenta===0){
                            response[i][j] = arrayMeses[response[i][j]];
                        } 
                        else{
                            response[i][j] *= 1;
                        }                   
                        arrayResult.push(response[i][j]);
                        cuenta++;
                    }
                    if(i==='0'){
                        arrayApiladoDepServicios.push(arrayHead);
                    }               
                    arrayApiladoDepServicios.push(arrayResult);                    
                }
                if(cuenta===0){
                    arrayApiladoDepServicios = [['DEPARTAMENTO',''],['',0]];                    
                }  
                //enviar array                                                                
                graficoApiladoDepServicios1 = arrayApiladoDepServicios; 
            }

            let arrayIndicadores = {            
                graficoTendenciaIncidencias : graficoTendenciaIncidencias,
                graficoApiladoDepIncidencias : graficoApiladoDepIncidencias,
                graficoApiladoDepIncidencias1 : graficoApiladoDepIncidencias1,            
                graficoTendenciaProblemas : graficoTendenciaProblemas,
                graficoApiladoDepProblemas : graficoApiladoDepProblemas,
                graficoApiladoDepProblemas1 : graficoApiladoDepProblemas1,
                graficoTendenciaServicios : graficoTendenciaServicios,
                graficoApiladoDepServicios : graficoApiladoDepServicios,
                graficoApiladoDepServicios1 : graficoApiladoDepServicios1,
                stateYear1  : stateYear1,
            }
            this.setState({indicadores : arrayIndicadores});
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message);
        }
    }     
  	render() {        
        let permisos = this.state.permisos;
        let indicadores = this.state.indicadores;    

        //LOS TIPOS DE GRAFICO SON PieChart y Line                    
        return ( 
            <div id="DashboardContenedor">
                    <ul>
                        <div style={{display:permisos.displayServicios}}>
                            <DashboardOption                             
                                titulo='Servicios'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "LineChart",
                                                    width     : '100%',
                                                    height    : '350px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTendenciaServicios,                                                    
                                                    options    : {                                                                     
                                                                     title: LabelsTrendContainer.Ind1TrendContainer[this.state.idioma]+(indicadores.stateYear1-1)+' - '+indicadores.stateYear1,                                                                     
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: 'MES',                                                                       
                                                                     },
                                                                     vAxis: {
                                                                        title: 'TOTAL',   
                                                                        format: '0'                                                                    
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     titleTextStyle: {
                                                                        color: '#005884'
                                                                     },                                                                     
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 5,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },                                                                      
                                                                      legendToggle : true,                                                                      
                                                                      annotation: {
                                                                          style:'string',
                                                                          textStyle: {
                                                                              fontName: 'Times-Roman',
                                                                              fontSize: 18,
                                                                              bold: true,
                                                                              italic: true,
                                                                              // The color of the text.
                                                                              color: '#871b47',
                                                                              // The color of the text outline.
                                                                              auraColor: '#d799ae',
                                                                              // The transparency of the text.
                                                                              opacity: 0.8
                                                                            }                                           
                                                                      },
                                                                      pointsVisible: true,
                                                                      visibleInLegend: false
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '3' 
                                                                 }
                                                }    
                                             ]}  
                            />
                            <br/>
                            <DashboardOption                             
                                titulo='Servicios'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoDepServicios,
                                                    options    : {
                                                                     title: LabelsTrendContainer.Ind2TrendContainer[this.state.idioma]+indicadores.stateYear1,
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                            <br />
                            <DashboardOption                             
                                titulo='Servicios'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoDepServicios1,
                                                    options    : {
                                                                     title: LabelsTrendContainer.Ind2TrendContainer[this.state.idioma]+(indicadores.stateYear1-1),
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            /> 
                        </div>
                        <br />          
                        <div style={{display:permisos.displayIncidencias}}>
                            <DashboardOption                             
                                titulo='Incidencias'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "LineChart",
                                                    width     : '100%',
                                                    height    : '350px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTendenciaIncidencias,
                                                    options    : { 
                                                                     title: LabelsTrendContainer.Ind3TrendContainer[this.state.idioma]+(indicadores.stateYear1-1)+' - '+indicadores.stateYear1,                                                                     
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: 'MES',                                                                       
                                                                     },
                                                                     vAxis: {
                                                                        title: 'TOTAL',
                                                                        format: '0'                                                                       
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     titleTextStyle: {
                                                                        color: '#005884'
                                                                     },                                                                     
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 5,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },                                                                      
                                                                      legendToggle : true,                                                                   
                                                                      pointsVisible: true,
                                                                      visibleInLegend: false
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                            <br />
                            <DashboardOption                             
                                titulo='Incidencias'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoDepIncidencias,
                                                    options    : {
                                                                     title: LabelsTrendContainer.Ind4TrendContainer[this.state.idioma]+indicadores.stateYear1,
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                            <br />
                            <DashboardOption                             
                                titulo='Incidencias'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoDepIncidencias1,
                                                    options    : {
                                                                     title: LabelsTrendContainer.Ind4TrendContainer[this.state.idioma]+(indicadores.stateYear1-1),
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            /> 
                        </div>
                        <br />
                        <div style={{display:permisos.displayProblemas}}>
                            <DashboardOption                             
                                titulo='Problemas'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "LineChart",
                                                    width     : '100%',
                                                    height    : '350px',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoTendenciaProblemas,
                                                    options    : {                                                                     
                                                                     title: LabelsTrendContainer.Ind5TrendContainer[this.state.idioma]+(indicadores.stateYear1-1)+' - '+indicadores.stateYear1,                                                                     
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: 'MES',                                                                       
                                                                     },
                                                                     vAxis: {
                                                                        title: 'TOTAL', 
                                                                        format: '0'                                                                      
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     titleTextStyle: {
                                                                        color: '#005884'
                                                                     },                                                                     
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 5,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },                                                                      
                                                                      legendToggle : true,                                                                      
                                                                      annotation: {
                                                                          style:'string',
                                                                          textStyle: {
                                                                              fontName: 'Times-Roman',
                                                                              fontSize: 18,
                                                                              bold: true,
                                                                              italic: true,
                                                                              // The color of the text.
                                                                              color: '#871b47',
                                                                              // The color of the text outline.
                                                                              auraColor: '#d799ae',
                                                                              // The transparency of the text.
                                                                              opacity: 0.8
                                                                            }                                           
                                                                      },
                                                                      pointsVisible: true,
                                                                      visibleInLegend: false
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '2' 
                                                                 }
                                                }    
                                             ]}  
                            />
                            <br />
                            <DashboardOption                             
                                titulo='Problemas'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoDepProblemas,
                                                    options    : {
                                                                     title: LabelsTrendContainer.Ind6TrendContainer[this.state.idioma]+indicadores.stateYear1,
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                            <br />
                            <DashboardOption                             
                                titulo='Problemas'                            
                                tipo="grafico_amplio"                                                 
                                chartProps= {[
                                                {
                                                    chartType : "ColumnChart",
                                                    width     : '100%',
                                                    height    : '100%',
                                                    loader    : 'Cargando...',
                                                    data      :  indicadores.graficoApiladoDepProblemas1,
                                                    options    : {
                                                                     title: LabelsTrendContainer.Ind6TrendContainer[this.state.idioma]+(indicadores.stateYear1-1),
                                                                     chartArea: {
                                                                        left: '10%',
                                                                        top: 30,
                                                                        width: "60%",
                                                                        height: "60%"
                                                                     },
                                                                     hAxis: {
                                                                        title: '',
                                                                        minValue: 0,
                                                                    },
                                                                     vAxis: {
                                                                        title: '',
                                                                        maxValue: 10, 
                                                                        format: '0'
                                                                     },
                                                                     tooltip: {
                                                                        isHtml: true,                                                                   
                                                                     },
                                                                     legend: {
                                                                        position: "right",
                                                                        alignment: "start",
                                                                        width: "600px",
                                                                        maxLines: 3,
                                                                        textStyle: {
                                                                          color: "000000",
                                                                          fontSize: 10
                                                                        }
                                                                      },
                                                                      isStacked: true,
                                                                      legendToggle : true,
                                                                      titleTextStyle: {
                                                                            color: '#005884'
                                                                      }
                                                                 },
                                                    rootProps  : { 
                                                                     'data-testid': '1' 
                                                                 }
                                                }    
                                             ]}  
                            />
                        </div>                                                   
                    </ul>
                </div>
        );
    } 
}

export default TrendContainer
