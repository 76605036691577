/**
* CLASS Roles
*
* Contiene el contenedor principal de los roles
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../../data_grid/DataGrid';
import Window from '../../window/Window';
import globalState from '../../configuration/GlobalState';
import {cargarFilas} from '../../api_calls/ApiCalls';
import TBar from '../../tbar/TBar';

class Roles extends Component {
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }
    colFuncion(idRow){        
        globalState.dispatch({
                type   : "windowRolesPermisos",
                params : {
                              visible : true,
                              params  : {
                                            idRol : idRow,
                                            idWin : "windowRolesPermisos",
                                            width : '315px', 
                                            height: '80%'//identificador de la ventana
                                        }
                         }
            }); 
    } 

    async validacionDeleteRol(id){
        try{
            // Validar que no hayan usuarios con el rol eliminar
            let database = globalState.getState().companyData[0].bd;
            let sqlParams = {
                                sqlCols : [
                                    "COUNT(id) AS total"                                             
                                ],                            
                                sqlWhere : [
                                    " AND id_rol = "+id//no cuenta superusuarios
                                ],      
                            }; 
            let res = await cargarFilas('users','',1000,0,'','',sqlParams,'rows',database);
            let response = res.data; 
            if(response.length > 0 && response[0].total > 0){
                return {  status : false, message : "El rol no se puede eliminar, tiene usuarios activos vinculados!" }; 
            }
            
            return {  status : true, message : "" }; 
               
        }           
        catch(err){
            return {  status : false, message : 'No se ha logrado la conexion con el servidor!<br />'+err.message }; 
        }     
    }

  	render() {  
        let idRol = globalState.getState().idRol;
        let where = '';
        if(idRol > 1){
            where = ' AND T1.id <> '+idRol;
        }  
        let sqlParams = {                                                                                 
                            sqlWhere : [
                                ' AND T1.id > 1 '+where
                            ],
                            fieldSearch : [
                                'T1.nombre'                                                              
                            ],                                                                                                                                                             
                        }                          
        return (//carga el componente que contiene la grilla de datos   
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}> 
                    <DataGrid titulo='Roles' 
                        funcionClick={this.props.funcionClick} 
                        parametro={this.props.parametro} 
                        funcionBeforeDelete={this.validacionDeleteRol.bind(this)}
                        tbarEdit="false"                              
                        colsData={[ 
                                    {
                                        type  : 'bd',
                                        label : 'Nombre',
                                        field : 'nombre'
                                    },                                         
                                    {
                                        type  : 'manual',
                                        label : '',
                                        icon  : 'settings',
                                        colFuncion : this.colFuncion.bind(this)
                                    }
                                 ]}
                        sqlParams = { sqlParams }  
                        automatica="true"                              
                        formFields={[
                                      {
                                          label : 'Nombre *',
                                          field : 'nombre',
                                          type  : 'text',
                                          validation : 'mayusculas',
                                          required : 'true',
                                          longitud : 40,
                                          tooltip : 'Ingrese el nombre del rol' 
                                      },                                                                                                              
                                  ]}                     
                        apiField={'roles'}
                        mainContainer='Roles'
                        botonNuevo="true"
                    />
                    </div>  
                    <Window 
                        id = "windowRolesPermisos"                      
                        title='Configurar Permisos'                            
                        tbar="false"
                        componente="WindowRolesPermisos"
                        params="" 
                    />
            </div>       
        )
    } 
}

export default Roles