/**
* CLASS App
*
* Contenedor principal de la aplicacion
*
* @author Hector Morales <warrior1987@gmail.com>
*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './components/login/Login';
import Desktop from './components/desktop/Desktop';
import configJson from './components/configuration/configuration.json';
import ResetPasswordForm from './components/reset_password/ResetPasswordForm';
import EncuestaSatisfaccion from './components/encuesta_satisfaccion/EncuestaSatisfaccion';
import EncuestaCliente from './components/terceros/FormEncuestaCliente';
import RechazoSolucion from './components/encuesta_satisfaccion/RechazoSolucion';

class App extends Component {
  render() {
    return (//router de la aplicacion
      <BrowserRouter>               
          <Switch>
              <Route exact path={'/'+configJson.mainPath} component={Login} /> 
              <Route exact path={'/'+configJson.mainPath+'resetPassword/:token/:user/:opcion/:bd'} component={ResetPasswordForm} />
              <Route exact path={'/'+configJson.mainPath+'encuestaSatisfaccion/:idSolicitud/:tabla/:bd'} component={EncuestaSatisfaccion} /> 
              <Route exact path={'/'+configJson.mainPath+'encuestaCliente/:lastId/:idCliente/:bd'} component={EncuestaCliente} />
              <Route exact path={'/'+configJson.mainPath+'rechazoSolucion/:idSolicitud/:tabla/:idUsuario/:bd'} component={RechazoSolucion} />    
              <Route exact path={'/'+configJson.mainPath+'desktop'} component={Desktop} />              
          </Switch>        
      </BrowserRouter>
    );
  }
}
export default App;
