/**
* CLASS Proveedores
*
* Contiene el contenedor principal de los Proveedores
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import TBar from '../tbar/TBar';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import Window from '../window/Window';
import alertify from 'alertifyjs';
import {validarPermiso} from '../configuration/GlobalFunctions';

class Proveedores extends Component {
    constructor(props){
        super(props);        
        let botonEncuestas = 'true';         
        let permisoInsertUpdate = 15;
        let permisoDelete = 16;
        let permisoReactivar = 'none';
        if(validarPermiso(53)){            
            botonEncuestas = 'true';
        }
        if(validarPermiso(16)){            
            permisoReactivar = 'block';
        }          
        this.state={
            botonEncuestas : botonEncuestas,
            permisoInsertUpdate : permisoInsertUpdate,
            permisoDelete : permisoDelete,
            permisoReactivar : permisoReactivar,
            database : globalState.getState().companyData[0].bd
        }       
    }
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }
    ventanaReactivarProveedores(){        
        globalState.dispatch({
            type   : "windowReactivarProveedores",
            params : {
                          visible : true,
                          params  : {                                        
                                        idWin : "windowReactivarProveedores",//identificador de la ventana
                                        tabla : "terceros",
                                        opcion: "Proveedores",
                                        funcionClick : this.props.funcionClick,
                                        width : '400px', 
                                        height: '270px'
                                    }
                     }
        });
    }
    colFuncion3(idRow){
        globalState.dispatch({
            type   : "windowEncuestasProveedor",
            params : {
                          visible : true,
                          params  : { 
                                        idRow : idRow,                                        
                                        idWin : "windowEncuestasProveedor",//identificador de la ventana
                                        opcion: 'proveedor',
                                        tabla : "terceros",
                                        width : '400px', 
                                        height: '400px'
                                    }
                     }
        });
    }
    colFuncion(idRow){
        globalState.dispatch({
                    type   : "windowAdjuntosProveedores",
                    params : {
                                  visible : true,
                                  params  : {
                                                idRow   : idRow, 
                                                table   : 'terceros',                                           
                                                idWin   : "windowAdjuntosProveedores",
                                                width   : '300px', 
                                                height  : '250px',                                                
                                            }
                             }
                }); 
    }
    async colFuncion2(idRow){
        try{
            let sqlParams = {
                                sqlCols : [
                                    'razon_social',
                                ],
                                sqlWhere : [ " AND id="+idRow ],                   
                            }
            let res = await cargarFilas('terceros','',1,0,'','',sqlParams,'rows',this.state.database);
            let response = res.data;
            globalState.dispatch({
                type   : "idTercero",
                params : {
                    idRow  : idRow,
                    nombre : response[0].razon_social,
                    tipo   : 'proveedor'
                }
            });
            this.props.funcionClick('TercerosContratos'); 
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message); 
        }                
    }
    btnVisorLog(idRow){
        globalState.dispatch({
            type   : "windowVisorLog",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,
                                        table : 'terceros',
                                        idWin : "windowVisorLog",
                                        width : '400px', 
                                        height: '300px'//identificador de la ventana
                                    }
                     }
        });
    } 
    async validacionDeleteProveedor(id){
        try{
            // Validar que no haya un funcionario vinculado al activo
            let database = globalState.getState().companyData[0].bd;
            let sqlParams = {
                                sqlCols : [
                                    "COUNT(id) AS total"                                    
                                ],                            
                                sqlWhere : [
                                    " AND id_proveedor = "+id
                                ],                              
                            }; 
            let res = await cargarFilas('activos','',1000,0,'','',sqlParams,'rows',database);
            let response = res.data; 
            if(response.length > 0 && response[0].total > 0){
                return {  status : false, message : "El proveedor no se puede eliminar, tiene activos vinculados!" }; 
            }

            return {  status : true, message : "" }; 
        }           
        catch(err){
            return {  status : false, message : 'No se ha logrado la conexion con el servidor!<br />'+err.message }; 
        }     
    }    	
  	render() {
        let sqlParams = {
                            sqlCols : [
                                'T1.id',
                                'T1.id_tipo_documento',
                                'DT.nombre AS tipo_documento',
                                "CONCAT(DT.nombre,' ',T1.documento) AS numero_documento",
                                'T1.documento',
                                'T1.razon_social',
                                'T1.nombre_comercial',
                                'T1.nombre_contacto',
                                'T1.direccion',
                                'T1.email',
                                'T1.telefono',  
                                'T1.cliente',
                                'T1.proveedor',                              
                                'R.html AS stars',
                                'ROUND(T1.puntaje_proveedor) AS puntaje'                                
                            ],
                            sqlJoin : [ 
                                'LEFT JOIN reputacion_estrellas AS R ON(R.puntaje = ROUND(T1.puntaje_proveedor))',                               
                                'INNER JOIN document_types AS DT ON (DT.id = T1.id_tipo_documento)'                                
                            ],
                            fieldSearch : [
                                'T1.documento',
                                'T1.razon_social',
                                'T1.nombre_comercial',
                                'T1.nombre_contacto',
                                'T1.direccion',
                                'T1.telefono'
                            ],
                            sqlWhere : [
                                " AND proveedor = 'true'"
                            ],
                            sqlOrderBy : 'T1.puntaje_proveedor DESC',                                               
                        }; 

        let botonContratos = '';
        if(validarPermiso(70)){            
            botonContratos = {
                                  type  : 'manual',
                                  label : '',
                                  icon  : 'business_center',
                                  colFuncion : this.colFuncion2.bind(this)
                              };
        }

        let botonEncuestas = '';        
        if(this.state.botonEncuestas === 'true'){
            botonEncuestas =  {
                                  type  : 'manual',
                                  label : '',
                                  icon  : 'star',
                                  colFuncion : this.colFuncion3.bind(this)
                              };            
        }        
        let windowVisorLog = <Window 
                                  id = "windowVisorLog"                      
                                  title='Ver Log'                                                
                                  tbar="false"
                                  componente="WindowVisorLog"
                                  params="" 
                              />

        let dataLogParams = {
            documento : "Documento",
            razon_social : "Razon Social",
            nombre_comercial  : "Nombre Comercial",
            nombre_contacto : "Contacto",
            direccion : "Direccion",
            telefono : "Telefono",
            email : "E-mail",            
        };

  	  	return (  	  		  
  	  		<div>
                <TBar
                    items={[  
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                              {
                                  type : 'boton',
                                  icon : 'redo',
                                  width : '100px',
                                  height : '80px',
                                  title : 'Reactivaciones',
                                  display : this.state.permisoReactivar,
                                  function : this.ventanaReactivarProveedores.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}> 
                    <DataGrid titulo='Proveedores' 
                              funcionClick={this.props.funcionClick}  
                              parametro={this.props.parametro}
                              funcionBeforeDelete={this.validacionDeleteProveedor.bind(this)}
                              tbarEdit="true"
                              tbarItems={[
                                              {
                                                    type : 'boton',
                                                    icon : 'description',
                                                    width : '80px',
                                                    height : '70px',
                                                    title : 'Ver Log',
                                                    display : 'block', 
                                                    windowComponent : windowVisorLog,
                                                    function : this.btnVisorLog.bind(this)                                       
                                              }
                                         ]}
                              dataFilter={[
                                  {
                                      label   : 'Puntaje',
                                      dinamic : 'false',
                                      field   : 'ROUND(T1.puntaje_proveedor)',
                                      options :  [{
                                                      id: '5',
                                                      nombre: '5',
                                                  },
                                                  {
                                                      id: '4',
                                                      nombre: '4',
                                                  },
                                                  {
                                                      id: '3',
                                                      nombre: '3',
                                                  },
                                                  {
                                                      id: '2',
                                                      nombre: '2', 
                                                  },
                                                  {
                                                      id: '1',
                                                      nombre: '1', 
                                                  }]
                                  }
                              ]}
                              colsData={[ 
                                            {
                                                type  : 'bd_html',
                                                label : 'Puntaje',
                                                field : 'stars'
                                            },                                            
                                            {
                                                type  : 'bd',
                                                label : 'Documento',
                                                field : 'numero_documento'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Razon Social',
                                                field : 'razon_social'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Nombre Comercial',
                                                field : 'nombre_comercial'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Email',
                                                field : 'email'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Direccion',
                                                field : 'direccion'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Telefono',
                                                field : 'telefono'
                                            },
                                            {
                                                type    : 'bd',
                                                label   : 'Contacto',
                                                field   : 'nombre_contacto',
                                                onlyXLS : 'true'
                                            },
                                            {
                                                type    : 'bd',
                                                label   : 'Puntaje',
                                                field   : 'puntaje',
                                                onlyXLS : 'true'
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'attach_file',
                                                colFuncion : this.colFuncion.bind(this)
                                            },
                                            botonContratos,
                                            botonEncuestas                                            
                                        ]}
                              sqlParams = { sqlParams } 
                              automatica="true"
                              botonNuevo="true" 
                              botonesExportar="true"
                              botonExportarXls="true"
                              dataLog={dataLogParams}
                              formFields={[
                                            {
                                                label : 'Tipo de Documento *',
                                                field : 'id_tipo_documento',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'true',
                                                apiField : 'document_types',
                                                valueName : 'nombre',
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],                                                                                                       
                                                            },
                                                tooltip : 'Seleccione el tipo de documento del proveedor'   
                                            },                                    
                                            {
                                                label : 'Documento *',
                                                field : 'documento',
                                                type  : 'text',
                                                validation : 'numero_texto',
                                                required : 'true',
                                                tooltip : 'Ingrese el numero de documento del proveedor'                                          
                                            },
                                            {
                                                label : 'Razon Social *',
                                                field : 'razon_social',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                longitud : 55,
                                                required : 'true',
                                                tooltip : 'Ingrese la razon social del proveedor'  
                                            },                                                                      
                                            {
                                                label : 'Nombre Comercial *',
                                                field : 'nombre_comercial',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                longitud : 55,
                                                required : 'true',
                                                tooltip : 'Ingrese el nombre comercial'  
                                            },
                                            {
                                                label : 'Nombre Contacto *',
                                                field : 'nombre_contacto',
                                                type  : 'text',
                                                validation : 'mayusculas',
                                                required : 'true',
                                                tooltip : 'Ingrese nombres y apellidos del funcionario contacto del proveedor'  
                                            },
                                            {
                                                label : 'Correo Electronico *',
                                                field : 'email',
                                                type  : 'text',
                                                validation : 'email',
                                                required : 'true',
                                                tooltip : 'Ingrese el correo electronico del proveedor'  
                                            },
                                            {
                                                label : 'Direccion *',
                                                field : 'direccion',
                                                type  : 'text',
                                                validation : 'direccion',
                                                required : 'true',
                                                longitud : 60,
                                                tooltip : 'Ingrese la direccion del proveedor'  
                                            },
                                            {
                                                label : 'Telefono *',
                                                field : 'telefono',
                                                type  : 'text',
                                                validation : 'telefono',
                                                required : 'true',
                                                tooltip : 'Ingrese el numero telefonico del proveedor'  
                                            },
                                            {
                                                label : 'Cliente *',
                                                field : 'cliente',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: 'false',
                                                                nombre: 'No',
                                                            },
                                                            {
                                                                id: 'true',
                                                                nombre: 'Si', 
                                                            }],
                                                tooltip : 'Especifique si es cliente'                                        
                                            },
                                            {
                                                label : '',
                                                field : 'proveedor',
                                                type  : 'hidden',
                                                validation : '',
                                                value : 'true',
                                                required : 'true'                                        
                                            },                                             
                                        ]}                     
                              apiField = {'terceros'}
                              permisoInsertUpdate={this.state.permisoInsertUpdate}
                              permisoDelete={this.state.permisoDelete}
                              mainContainer='Proveedores'/> 
                </div>
                <Window 
                      id = "windowReactivarProveedores"                      
                      title='Reactivar Proveedores'                      
                      tbar="false"
                      componente="WindowReactivarInformacion"
                      params="" 
                />
                <Window 
                      id = "windowEncuestasProveedor"                      
                      title='Encuestas Proveedor'                      
                      tbar="false"
                      componente="WindowEncuestasTerceros"
                      params="" 
                />                
                <Window 
                    id = "windowAdjuntosProveedores"                     
                    title='Ver Documentos'                   
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                /> 
                <Window 
                    id = "windowUploadArchivo"                      
                    title='Subir Adjunto'                    
                    tbar="false"
                    componente="WindowUploadArchivo"
                    params="" 
                />
            </div>                	
  	  	);
  	}
}

export default Proveedores
