/**
* CLASS AreasServicio
*
* Contiene el contenedor principal de los Areas de Servicio
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../../data_grid/DataGrid';
import Window from '../../window/Window';
import globalState from '../../configuration/GlobalState';
import {cargarFilas} from '../../api_calls/ApiCalls';
import TBar from '../../tbar/TBar';

class AreasServicio extends Component {
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }
    colFuncion(idArea){        
        globalState.dispatch({
                type   : "windowCategorias",
                params : {
                              visible : true,
                              params  : {
                                            idArea : idArea,
                                            idWin  : "windowCategorias",//identificador de la ventana,
                                            width  : '500px', 
                                            height : '300px'
                                        }
                         }
            }); 
    }
    colFuncion2(idArea){
        this.props.funcionClick('AreasSla',{idArea:idArea});         
    }
    ventanaDiasFestivos(){        
        globalState.dispatch({
            type   : "windowDiasFestivos",
            params : {
                          visible : true,
                          params  : {                                        
                                        idWin : "windowDiasFestivos",//identificador de la ventana
                                        tabla : "users",
                                        width : '300px', 
                                        height: '340px'
                                    }
                     }
        });
    } 

    async validacionDeleteArea(id){
        try{
            // Validar que no hayan usuarios en el area de servicio
            let database = globalState.getState().companyData[0].bd;
            let sqlParams = {
                                sqlCols : [
                                    "COUNT(id) AS total"                                             
                                ],                            
                                sqlWhere : [
                                    " AND id_area = "+id//no cuenta superusuarios
                                ],    
                            }; 
            let res = await cargarFilas('users','',1000,0,'','',sqlParams,'rows',database);
            let response = res.data; 
            if(response.length > 0 && response[0].total > 0){
                return {  status : false, message : "El area de servicio no se puede eliminar, tiene usuarios activos vinculados!" }; 
            }
            
            return {  status : true, message : "" }; 
               
        }           
        catch(err){
            return {  status : false, message : 'No se ha logrado la conexion con el servidor!<br />'+err.message }; 
        }     
    }

  	render() {  
        let sqlParams = {
                            sqlCols : [
                                '*',
                                "IF(atencion_incidencias='true','Si','No') AS incidencias",
                                "IF(atencion_problemas='true','Si','No') AS problemas",
                                "IF(atencion_servicios='true','Si','No') AS servicios",                                                                                                         
                            ], 
                            fieldSearch : [
                                'nombre'                                                              
                            ],                            
                            filtroFechas : "false",                                                                                                                                                              
                        }         
        return (//carga el componente que contiene la grilla de datos   
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },                              
                              {
                                  type : 'boton',
                                  icon : 'event',
                                  width : '90px',
                                  height : '60px',
                                  title : 'Dias Festivos',
                                  display : true,
                                  function : this.ventanaDiasFestivos.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}> 
                    <DataGrid titulo='Areas de Servicio' 
                        funcionClick={this.props.funcionClick} 
                        parametro={this.props.parametro} 
                        tbarEdit="false"                              
                        colsData={[ 
                                    {
                                        type  : 'bd',
                                        label : 'Nombre',
                                        field : 'nombre'
                                    },
                                    {
                                        type  : 'bd',
                                        label : 'Tipo',
                                        field : 'tipo'
                                    }, 
                                    {
                                        type  : 'bd',
                                        label : 'Atencion Incidencias',
                                        field : 'incidencias'
                                    }, 
                                    {
                                        type  : 'bd',
                                        label : 'Atencion Problemas',
                                        field : 'problemas'
                                    }, 
                                    {
                                        type  : 'bd',
                                        label : 'Atencion Servicios',
                                        field : 'servicios'
                                    },                                            
                                    {
                                        type  : 'bd',
                                        label : 'Capacidad',
                                        field : 'capacidad_atencion'
                                    },                                          
                                    {
                                        type  : 'manual',
                                        label : '',
                                        icon  : 'settings',
                                        colFuncion : this.colFuncion.bind(this)
                                    },
                                    {
                                        type  : 'manual',
                                        label : '',
                                        icon  : 'timer',
                                        colFuncion : this.colFuncion2.bind(this)
                                    }                                          
                                 ]}
                        sqlParams = { sqlParams }  
                        automatica="true"                              
                        formFields={[
                                      {
                                          label : 'Nombre *',
                                          field : 'nombre',
                                          type  : 'text',
                                          validation : 'texto_mayusculas',
                                          required : 'true',
                                          longitud : 45,
                                          tooltip : 'Ingrese el nombre del area de servicio'                                        
                                      },
                                      {
                                          label : 'Tipo *',
                                          field : 'tipo',
                                          type  : 'select',
                                          validation : '',
                                          required : 'true',
                                          dinamic : 'false',
                                          valueName : 'nombre',
                                          options :  [{
                                                          id: 'interna',
                                                          nombre: 'Interna',
                                                      },
                                                      {
                                                          id: 'externa',
                                                          nombre: 'Externa', 
                                                      },
                                                      {
                                                          id: 'todas',
                                                          nombre: 'Interna/Externa', 
                                                      }],
                                          tooltip : 'Seleccione si el area es interna/externa'                                        
                                      }, 
                                      {
                                          label : 'Atencion Incidencias *',
                                          field : 'atencion_incidencias',
                                          type  : 'select',
                                          validation : '',
                                          required : 'true',
                                          dinamic : 'false',
                                          valueName : 'nombre',
                                          options :  [{
                                                          id: 'true',
                                                          nombre: 'Si',
                                                      },
                                                      {
                                                          id: 'false',
                                                          nombre: 'No', 
                                                      }],
                                          tooltip : 'Seleccione si atiende incidencias'                                        
                                      },
                                      {
                                          label : 'Atencion Problemas *',
                                          field : 'atencion_problemas',
                                          type  : 'select',
                                          validation : '',
                                          required : 'true',
                                          dinamic : 'false',
                                          valueName : 'nombre',
                                          options :  [{
                                                          id: 'true',
                                                          nombre: 'Si',
                                                      },
                                                      {
                                                          id: 'false',
                                                          nombre: 'No', 
                                                      }],
                                          tooltip : 'Seleccione si atiende problemas'                                        
                                      },
                                      {
                                          label : 'Atencion Servicios *',
                                          field : 'atencion_servicios',
                                          type  : 'select',
                                          validation : '',
                                          required : 'true',
                                          dinamic : 'false',
                                          valueName : 'nombre',
                                          options :  [{
                                                          id: 'true',
                                                          nombre: 'Si',
                                                      },
                                                      {
                                                          id: 'false',
                                                          nombre: 'No', 
                                                      }],
                                          tooltip : 'Seleccione si atiende servicios'                                        
                                      },                                            
                                      {
                                          label : 'Capacidad de Atencion *',
                                          field : 'capacidad_atencion',
                                          type  : 'text',
                                          validation : 'entero',
                                          required : 'true',
                                          tooltip : 'Ingrese la capacidad de atencion de los tecnicos del area de servicio'                                        
                                      },                                                                                                                                                     
                                  ]}                     
                        apiField={'areas_servicio'}
                        mainContainer='AreasServicio'
                        funcionBeforeDelete={this.validacionDeleteArea.bind(this)}
                        botonNuevo="true"                              
                    />
                    </div>
                    <Window 
                        id = "windowCategorias"                      
                        title='Categorias'                        
                        tbar="false"
                        componente="WindowCategorias"
                        params="" 
                    />
                    <Window 
                        id = "windowDiasFestivos"                      
                        title='Dias Festivos'                      
                        tbar="false"
                        componente="WindowDiasFestivos"
                        params="" 
                    />                    
            </div>       
        )
    } 
}

export default AreasServicio