/**
* CLASS WindowEditarFichaTecnica
*
* Contiene el contenedor principal para el cambio de estados de la ficha tecnica
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,insertarActualizarFila} from '../api_calls/ApiCalls';
import Form from 'react-bootstrap/Form';
import {modalLoading} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import Window from '../window/Window';
import TBar from '../tbar/TBar';
import alertify from 'alertifyjs';

class WindowEditarFichaTecnica extends Component {
    constructor(props){
        super(props);
        this.state = {
            listadoEstados : [],
            estado     : '',
            id_usuario : 0,
            database   : globalState.getState().companyData[0].bd
        };
    }
    componentDidMount() {//cada que se monte el escritorio debe alistar la ventana del loading      
        //... 
        this.unsubscribe1 = globalState.subscribe( ()=>{ 
            if(globalState.getState().type==="estadosIdFuncionario"){                             
                this.setState({id_usuario  : globalState.getState().estadosIdFuncionario.id_usuario}); 
                this.setState({funcionario : globalState.getState().estadosIdFuncionario.nombre_usuario});                                            
            }
        });                        
    }    
    componentWillUnmount(){         
        this.unsubscribe1();              
    }
    componentWillMount(){
        let sqlParams = {
                            sqlCols : [
                                "T1.id",
                                "T1.nombre",                                                                                             
                            ],
                            sqlJoin : [
                                
                            ],                                                                         
                            sqlWhere : [ ]
                        };
        cargarFilas('activos_estados','',1000,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{ 
                this.setState({ listadoEstados: response },()=>{
                    this.cargarEstadoUsuario();
                });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    cargarEstadoUsuario(){
        let sqlParams = {
                            sqlCols : [
                                "estado",                                                                                                                            
                            ],                                                                                                    
                            sqlWhere : [ " AND id="+this.props.params.idRow ]
                        };
        cargarFilas('activos','',1000,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } 
            else{ 
                this.setState({ estado: response[0].estado });
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    guardarEstadoActivo(){ 
        let estado  = this.state.estado;
        let usuario = this.state.id_usuario;
        if(estado === ''){
            alertify.alert('Aviso!', 'Seleccione el estado!');
            return;
        }
        if(usuario === 0 && estado > 1 && estado < 7){
            alertify.alert('Aviso!', 'Seleccione el usuario!');
            return;
        }
        if(estado === 1 || estado === 7 || estado === 8){ 
            usuario = 0;
        }
        let objectEstado = {
            estado     : estado,
            id_asignado : usuario,
            id : this.props.params.idRow
        };
        let dataLog = {
            estado : "Estado",            
        }
        modalLoading(true);
        insertarActualizarFila('put','activos',objectEstado,dataLog,undefined,this.state.database).then(response => {               
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                modalLoading(false);
            }
            else{                
                modalLoading(false); 
                alertify.alert('Aviso!', 'Se ha guardado la informacion con exito!');  
                globalState.dispatch({
                    type   : "windowCambiarEstado",
                    params : {
                                  visible : false,
                             }
                });                
                this.props.params.funcionClick('Activos');            
            }     
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        }); 
    }
    handleChangeEstado(e){  
        let ingresado = e.target.value;             
        this.setState({ estado: ingresado });
        if(ingresado === 1 || ingresado === 7 || ingresado === 8){
            this.setState({id_usuario : 0,funcionario : ''},()=>{
                 globalState.dispatch({//cargamos los datos de los permisos
                    type   : "estadosIdFuncionario",
                    params : { id_usuario:0,nombre_usuario:'' }
                });
            });           
        }
    }
    handleFuncionario(e){        
        globalState.dispatch({
            type   : "windowSeleccionFuncionario",
            params : {
                          visible : true,
                          params  : { 
                                        idField : "estadosIdFuncionario",
                                        idWin   : "windowSeleccionFuncionario",//identificador de la ventana
                                        where   : '',
                                        width   : '500px', 
                                        height  : '270px',
                                        idArea  : this.state.filtroArea
                                    }
                     }
        });
    }
  	render() {  
        return (//carga el componente que contiene la grilla de datos
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'save',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Guardar',
                                  display : true,
                                  function : this.guardarEstadoActivo.bind(this)
                              },
                          ]}
                    length = '1'
                />           
                <div className="container" style={{position:"relative",top:'65px'}}>
                    <div className="content">
                        <div className="table-responsive mb-3"> 
                            <Form>
                                <Form.Group controlId="formFieldEstado">
                                    <Form.Label>Estado</Form.Label>                       
                                    <Form.Control as="select" name = "id_estado" onChange={this.handleChangeEstado.bind(this)} value={this.state.estado}>
                                        <option value="">Seleccione...</option>
                                        {
                                            this.state.listadoEstados.map((content,i) => { 
                                                return <option key={i} value={content.id} >{content.nombre}</option>
                                            })
                                        }                            
                                    </Form.Control>  
                                </Form.Group>
                                {
                                    this.state.estado > 1 && this.state.estado < 7 ?
                                        <Form.Group controlId="formFieldFuncionario">
                                            <Form.Label>Funcionario Asignado</Form.Label>
                                            <Form.Control name = "id_funcionario" type="text" onClick={this.handleFuncionario.bind(this)} value={this.state.funcionario} readOnly/>                               
                                        </Form.Group>
                                    : ''
                                }                                                 
                            </Form>
                        </div>
                    </div>
                </div>
                <Window 
                    id = "windowSeleccionFuncionario"                      
                    title='Seleccionar Funcionario'                    
                    tbar="false"
                    componente="WindowSeleccionFuncionario"
                    params="" 
                />                  
            </div>          
        )
    } 
}

export default WindowEditarFichaTecnica