/**
* CLASS Reports
*
* Contiene el contenedor principal del generador de informes
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import ReportOption from './ReportOption';
import globalState from '../configuration/GlobalState';
import ReportContainer from './ReportContainer';
import {validarPermiso} from '../configuration/GlobalFunctions';
import {validarModulo} from '../configuration/GlobalFunctions';
import configJson from '../configuration/configuration.json';
import './reports.css';

class Reports extends Component {
    constructor(props, context) {
        super(props, context);     
        this.state = { 
            optionMenu : ''            
        }
        this.actualizarReportContainer = this.actualizarReportContainer.bind(this);
    }  	
    actualizarReportContainer(optionMenu){        
        this.setState({ optionMenu: optionMenu });           
    }
  	render() { 
        // Id del area, si está vinculado a un area de servicio, solo podrá ver lo de su area
        let idArea = globalState.getState().userData[0].id_area;        
        let whereArea = '';
        if(idArea > 0){
            whereArea += " AND id = "+idArea;
        }

        var tabLog = -1;  
        var tabTitle = -1;       
        var logProveedores = {
                                label : 'Log de Proveedores',
                                reportTitle : 'Log de Proveedores',
                                table : 'log',  
                                colsHeaders : ['Fecha','Proveedor','Accion','Realizado por','Datos'],
                                colsData : ['fecha','nombre_proveedor','accion','nombre_funcionario','datos'],                                                                                      
                                dateFilter : 'true',
                                dataFilter : [
                                    {                                                    
                                        label   : 'Proveedor',                                        
                                        dinamic : 'data_select',
                                        field   : 'id_tabla',
                                        dataParams : { 
                                                         fetchData : {
                                                              fieldFetch  : 'razon_social',
                                                              idField     : 'id_tabla',
                                                              valueField  : 'nombre_proveedor',
                                                         },
                                                         apiField    : 'terceros',
                                                         colsData    : [ 
                                                                            {
                                                                                type  : 'bd',
                                                                                label : 'Documento',
                                                                                field : 'documento'
                                                                            },
                                                                            {
                                                                                type  : 'bd',
                                                                                label : 'Nombre',
                                                                                field : 'razon_social'
                                                                            },
                                                                        ],
                                                         sqlParams : {
                                                                        sqlCols : [ 
                                                                            'id',                                 
                                                                            'documento',
                                                                            'razon_social'                                
                                                                        ],
                                                                        sqlWhere : [
                                                                            "proveedor='true' AND id IN (SELECT id_tabla FROM log WHERE tabla='terceros')"
                                                                        ],                                                                                                                                                                        
                                                                        sqlInactive : 'true',                           
                                                                        fieldSearch : [                                                                            
                                                                            'documento',
                                                                            'razon_social',                                                                            
                                                                        ],                                      
                                                                     }                                                                                           
                                                     },
                                    },
                                ],  
                                btnExcel : 'true',
                                excelName : 'Proveedores_Log',
                                ////btnPDF : 'true',
                                sqlParams : {
                                    sqlCols : [   
                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                                                                
                                        'T1.accion',
                                        "P.razon_social AS nombre_proveedor", 
                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS nombre_funcionario", 
                                        "REPLACE(REPLACE (T1.datos, '{.}', ','),',',', ') AS datos",                                                                                                                                                            
                                    ],
                                    sqlJoin : [
                                        "INNER JOIN terceros AS P ON (P.id = T1.id_tabla AND P.proveedor='true')",                                                   
                                        'INNER JOIN users AS U1 ON (U1.id = T1.id_usuario)',                                                                                           
                                    ],
                                    fieldSearch : [
                                        
                                    ],                                                
                                    sqlWhere : [
                                        " AND tabla='terceros'"
                                    ],                                     
                                    sqlOrderBy : 'T1.fecha DESC',                                              
                                    fieldFechas : "T1.fecha",
                                    fecha_completa : "true",                                                                    
                                }
                            };

        var logClientes   = {
                                label : 'Log de Clientes',
                                reportTitle : 'Log de Clientes',
                                table : 'log',  
                                colsHeaders : ['Fecha','Cliente','Accion','Realizado por','Datos'],
                                colsData : ['fecha','nombre_cliente','accion','nombre_funcionario','datos'],                                                                                      
                                dateFilter : 'true',
                                dataFilter : [
                                    {                                                    
                                        label   : 'Cliente',                                        
                                        dinamic : 'data_select',
                                        field   : 'id_tabla',
                                        dataParams : { 
                                                         fetchData : {
                                                              fieldFetch  : 'razon_social',
                                                              idField     : 'id_tabla',
                                                              valueField  : 'nombre_cliente',
                                                         },
                                                         apiField    : 'terceros',
                                                         colsData    : [ 
                                                                            {
                                                                                type  : 'bd',
                                                                                label : 'Documento',
                                                                                field : 'documento'
                                                                            },
                                                                            {
                                                                                type  : 'bd',
                                                                                label : 'Nombre',
                                                                                field : 'razon_social'
                                                                            },
                                                                        ],
                                                         sqlParams : {
                                                                        sqlCols : [ 
                                                                            'id',                                 
                                                                            'documento',
                                                                            'razon_social'                                
                                                                        ],
                                                                        sqlWhere : [
                                                                            "cliente='true' AND id IN (SELECT id_tabla FROM log WHERE tabla='terceros')"
                                                                        ],                                                                                                                                                                        
                                                                        sqlInactive : 'true',                           
                                                                        fieldSearch : [                                                                            
                                                                            'documento',
                                                                            'razon_social',                                                                            
                                                                        ],                                      
                                                                     }                                                                                           
                                                     },
                                    },
                                ],  
                                btnExcel : 'true',
                                excelName : 'Clientes_Log',
                                ////btnPDF : 'true',
                                sqlParams : {
                                    sqlCols : [   
                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                                                                
                                        'T1.accion',
                                        "P.razon_social AS nombre_cliente", 
                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS nombre_funcionario", 
                                        "REPLACE(REPLACE (T1.datos, '{.}', ','),',',', ') AS datos",                                                                                                                                                            
                                    ],
                                    sqlJoin : [
                                        "INNER JOIN terceros AS P ON (P.id = T1.id_tabla AND P.cliente='true')",                                                   
                                        'INNER JOIN users AS U1 ON (U1.id = T1.id_usuario)',                                                                                           
                                    ],
                                    fieldSearch : [
                                        
                                    ],                                                
                                    sqlWhere : [
                                        " AND tabla='terceros'"
                                    ],                                     
                                    sqlOrderBy : 'T1.fecha DESC',                                              
                                    fieldFechas : "T1.fecha",
                                    fecha_completa : "true",                                                                    
                                }
                            };

        if(!validarModulo(7)){            
            logProveedores = null;
        }
        if(!validarModulo(12)){            
            logClientes = null;
        } 

        var colActivo  = 'Activo Fijo';
        var bodyActivo = 'nombre_activo';
        var logActivos = {
                            label : 'Log de Activos',
                            reportTitle : 'Log de Activos',
                            table : 'log',  
                            colsHeaders : ['Fecha','Activo','Accion','Realizado por','Datos'],
                            colsData : ['fecha','nombre_activo','accion','nombre_funcionario','datos'],                                                                                      
                            dateFilter : 'true',
                            dataFilter : [
                                {                                                    
                                    label   : 'Activo',                                        
                                    dinamic : 'data_select',
                                    field   : 'id_tabla',
                                    dataParams : { 
                                                     fetchData : {
                                                          fieldFetch  : 'nombre',
                                                          idField     : 'id_tabla',
                                                          valueField  : 'nombre_activo',
                                                     },
                                                     apiField    : 'activos',
                                                     colsData    : [ 
                                                                        {
                                                                            type  : 'bd',
                                                                            label : 'Serial',
                                                                            field : 'serial'
                                                                        },
                                                                        {
                                                                            type  : 'bd',
                                                                            label : 'Nombre',
                                                                            field : 'nombre'
                                                                        },
                                                                    ],
                                                     sqlParams : {
                                                                    sqlCols : [ 
                                                                        'id',                                 
                                                                        'serial',
                                                                        'nombre'                                
                                                                    ],
                                                                    sqlWhere : [
                                                                        "id IN (SELECT id_tabla FROM log WHERE tabla='activos')"
                                                                    ],                                                                                     
                                                                    sqlInactive : 'true',                           
                                                                    fieldSearch : [                                                                            
                                                                        'serial',
                                                                        'nombre',                                                                            
                                                                    ],                                      
                                                                 }                                                                                           
                                                 },
                                },
                            ],  
                            btnExcel : 'true',
                            excelName : 'activos_log',
                            ////btnPDF : 'true',
                            sqlParams : {
                                sqlCols : [   
                                    "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                                                                
                                    'T1.accion',
                                    "A.nombre AS nombre_activo", 
                                    "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS nombre_funcionario", 
                                    "REPLACE(REPLACE (T1.datos, '{.}', ','),',',', ') AS datos",                                                                                                                                                            
                                ],
                                sqlJoin : [
                                    'INNER JOIN activos AS A ON (A.id = T1.id_tabla)',
                                    'INNER JOIN users AS U1 ON (U1.id = T1.id_usuario)',                                                                                           
                                ],
                                fieldSearch : [
                                    
                                ],                                                
                                sqlWhere : [
                                    " AND tabla='activos'"
                                ], 
                                sqlOrderBy : 'T1.fecha DESC',                                              
                                fieldFechas : "T1.fecha",
                                fecha_completa : "true",                                                                    
                            }
                        }
        if(!validarModulo(6)){
            colActivo  = null;
            bodyActivo = null;
            logActivos = null;
        }              
  	  	return (
            <div style={{width:'100%',height:'100%'}}>
                <div id="reportTbar" className="reportTbar" data-role="reportTbar" os="windows" style={{backgroundColor : configJson.windowColor,overflow:'visible',display:'flex'}}>
                    <div style={{width:'100%',height:'100%',display:'flex',paddingBottom: '250px', marginBottom: '-250px',overflowX:'auto',overflowY:'hidden'}}>
                {
                    validarModulo(2) && validarPermiso(78) ?
                        <ReportOption 
                            tab={"ReportOption"+(++tabTitle)} 
                            title='Incidencias'                             
                            funcionClick = {this.actualizarReportContainer}                         
                            position={++tabLog}
                            optionWidth='240px'
                            optionMenu={[
                                            {
                                                label : 'Informe de Incidencias',
                                                reportTitle : 'Informe de Incidencias',
                                                table : 'incidencias',  
                                                colsHeaders : ['Fecha Incidencia','Ticket','Usuario',colActivo,'Departamento','Prioridad','Urgencia','Impacto','Area Servicio','Categoria','Subcategoria','Estado','Fecha Asignacion','Fecha Vencimiento','Fecha Solucion','Tecnico Asignado','Cumplimiento SLA','Finalizacion','Puntaje','Comentarios'],
                                                colsData : ['fecha','id','usuario',bodyActivo,'departamento','nom_prioridad','nom_urgencia','nom_impacto','nombre_area','categoria','subcategoria','estado','fecha_asignacion_incidencia','fecha_vencimiento_incidencia','fecha_solucion_incidencia','nombre_tecnico','cumplimiento','tipo_finalizacion','puntaje_encuesta','comentarios'],                                                                                      
                                                dateFilter : 'true',
                                                dataFilter : [
                                                    {
                                                        label   : 'Prioridad',
                                                        dinamic : 'false',
                                                        field   : 'T1.prioridad',
                                                        options :  [{
                                                                        id: '4',
                                                                        nombre: 'Crítica',
                                                                    },
                                                                    {
                                                                        id: '3',
                                                                        nombre: 'Alta',
                                                                    },
                                                                    {
                                                                        id: '2',
                                                                        nombre: 'Media', 
                                                                    },
                                                                    {
                                                                        id: '1',
                                                                        nombre: 'Baja', 
                                                                    }]
                                                    },
                                                    {
                                                        label   : 'Estado',
                                                        dinamic : 'false',
                                                        field   : 'T1.estado',
                                                        options :  [{
                                                                        id: '0',
                                                                        nombre: 'Sin Asignar',
                                                                    },
                                                                    {
                                                                        id: '1',
                                                                        nombre: 'Asignado', 
                                                                    },
                                                                    {
                                                                        id: '2',
                                                                        nombre: 'En Proceso', 
                                                                    },
                                                                    {
                                                                        id: '3',
                                                                        nombre: 'Solucionado', 
                                                                    },
                                                                    {
                                                                        id: '4',
                                                                        nombre: 'Rechazado', 
                                                                    },
                                                                    {
                                                                        id: '5',
                                                                        nombre: 'Finalizado', 
                                                                    },
                                                                    {
                                                                        id: '6',
                                                                        nombre: 'Anulado', 
                                                                    }]
                                                    },
                                                    {
                                                        label : "A. Servicio",
                                                        dinamic : 'true',
                                                        field : 'T1.id_area',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],
                                                                        sqlWhere : [ " AND atencion_incidencias='true'"+whereArea ],                                                                                                                                                                                                                            
                                                                    },
                                                        apiField : 'areas_servicio',
                                                        valueName : 'nombre',
                                                    }                                                         
                                                ], 
                                                btnExcel : 'true',
                                                excelName : 'incidencias',
                                                //btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',                                                    
                                                        'T1.id_activo',
                                                        'A.nombre AS nombre_activo',
                                                        'T1.id_activo',
                                                        'T1.prioridad',
                                                        'T1.impacto',
                                                        'D.nombre AS departamento',          
                                                        'AR.nombre AS nombre_area',                                          
                                                        'IC.nombre AS categoria',
                                                        'ISC.nombre AS subcategoria',
                                                        "DATE_FORMAT(T1.fecha_asignacion_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_asignacion_incidencia",
                                                        "DATE_FORMAT(T1.fecha_solucion_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_solucion_incidencia",
                                                        "DATE_FORMAT(T1.fecha_vencimiento_incidencia,'%Y-%m-%d %H:%i:%s') AS fecha_vencimiento_incidencia",
                                                        'T1.urgencia',
                                                        'T1.descripcion',                                                    
                                                        'T1.asunto', 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha", 
                                                        "SE.nombre AS estado", 
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS nombre_tecnico",                                                    
                                                        "ROUND(T1.puntaje_encuesta) AS puntaje_encuesta",
                                                        "E.comentarios",
                                                        `CASE
                                                           WHEN T1.incidencia_cumplimiento = 'true' THEN 'Cumple' 
                                                           WHEN T1.incidencia_cumplimiento = 'false' THEN 'No Cumple'
                                                           ELSE ''
                                                        END AS cumplimiento`,
                                                        `CASE
                                                           WHEN T1.prioridad = '1' THEN 'Baja' 
                                                           WHEN T1.prioridad = '2' THEN 'Media'
                                                           WHEN T1.prioridad = '3' THEN 'Alta'
                                                           WHEN T1.prioridad = '4' THEN 'Crítica'
                                                           ELSE ''
                                                        END AS nom_prioridad`,
                                                        `CASE
                                                           WHEN T1.urgencia = '1' THEN 'Baja' 
                                                           WHEN T1.urgencia = '2' THEN 'Media'
                                                           WHEN T1.urgencia = '3' THEN 'Alta'
                                                           WHEN T1.urgencia = '4' THEN 'Crítica'
                                                           ELSE ''
                                                        END AS nom_urgencia`,
                                                        `CASE
                                                           WHEN T1.impacto = '1' THEN 'Bajo' 
                                                           WHEN T1.impacto = '2' THEN 'Medio'
                                                           WHEN T1.impacto = '3' THEN 'Alto'
                                                           WHEN T1.impacto = '4' THEN 'Crítico'
                                                           ELSE ''
                                                        END AS nom_impacto`,
                                                        "T1.tipo_finalizacion",
                                                    ],
                                                    sqlJoin : [
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_incidencia)',
                                                        'INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)', 
                                                        'INNER JOIN areas_servicio AS AR ON (AR.id = T1.id_area)', 
                                                        'INNER JOIN areas_servicio_categorias AS IC ON (IC.id = T1.id_categoria)', 
                                                        'INNER JOIN areas_servicio_subcategorias ISC ON (ISC.id = T1.id_subcategoria)',
                                                        'INNER JOIN departamentos AS D ON (D.id = U.id_departamento)', 
                                                        'LEFT JOIN activos AS A ON (A.id = T1.id_activo)',
                                                        "LEFT JOIN encuesta_satisfaccion_respuestas AS E ON (E.id_solicitud = T1.id AND E.tabla = 'incidencias' AND E.id_pregunta = 0)",                                                    
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        ' AND T1.problema = "false"'
                                                    ],                                                
                                                    fieldFechas : "T1.fecha",                                                
                                                    fecha_completa : "true",
                                                    sqlOrderBy : "T1.id DESC"                                                                    
                                                }
                                            },
                                            {
                                                label : 'Informe de Encuestas',
                                                reportTitle : 'Informe de Encuestas de Incidencias',
                                                table : 'incidencias',  
                                                colsHeaders : ['Fecha Solicitud','Ticket','Asunto','Pregunta','Usuario','Tecnico','Puntaje'],
                                                colsData : ['fecha','id','asunto','pregunta','usuario','tecnico','puntaje'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'incidencias_encuestas',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',                                                    
                                                        'T1.asunto',
                                                        'E.puntaje',                                                   
                                                        "IF(E.nombre_pregunta IS NOT NULL,E.nombre_pregunta,EP.nombre) AS pregunta", 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",  
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",                                                                                                      
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_satisfaccion_respuestas AS E ON (T1.id = E.id_solicitud AND E.tabla='incidencias')",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_incidencia)',                                                    
                                                        "INNER JOIN encuesta_satisfaccion_preguntas AS EP ON (EP.id = E.id_pregunta)",                                       
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        ' AND T1.problema = "false"'
                                                    ],                                                
                                                    sqlOrderBy : " T1.id DESC,puntaje DESC",
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Tecnico',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tecnico_incidencia',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_tecnico_incidencia',
                                                                              valueField  : 'nombre_tecnico',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT id_tecnico_incidencia FROM incidencias WHERE problema = 'false' AND estado=5)"
                                                                                        ],                        
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },
                                            {
                                                label : 'Informe de Comentarios',
                                                reportTitle : 'Informe de Comentarios de Incidencias',
                                                table : 'incidencias',  
                                                colsHeaders : ['Fecha Solicitud','Ticket','Asunto','Usuario','Tecnico','Puntaje','Comentarios'],
                                                colsData : ['fecha','id','asunto','usuario','tecnico','puntaje','comentarios'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'incidencias_comentarios',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',                                                    
                                                        'ROUND(T1.puntaje_encuesta) AS puntaje',
                                                        'E.comentarios',                                                   
                                                        'T1.asunto', 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",                                                                                                      
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_satisfaccion_respuestas AS E ON (T1.id = E.id_solicitud AND E.tabla='incidencias' AND E.id_pregunta=0)",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_incidencia)', 
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        ' AND T1.problema = "false"'
                                                    ],                                                
                                                    sqlOrderBy : " T1.id DESC",
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Tecnico',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tecnico_incidencia',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_tecnico_incidencia',
                                                                              valueField  : 'nombre_tecnico',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT id_tecnico_incidencia FROM incidencias WHERE problema = 'false' AND estado=5)"
                                                                                        ],                         
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            }, 
                                            {
                                                label : 'Log de Seguimientos',
                                                reportTitle : 'Informe de Seguimientos de Incidencias',
                                                table : 'incidencias_seguimientos',  
                                                colsHeaders : ['Fecha','Ticket','Estado','Usuario','Observacion'],
                                                colsData : ['fecha','id_maestro','estado','usuario','observacion'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'incidencias_seguimientos',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id_maestro',
                                                        'T1.estado',                                                   
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario", 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "T1.observacion",                                                                                                                                                         
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN incidencias AS I ON (I.id = T1.id_maestro AND I.problema = 'false')",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',                                                    
                                                    ],
                                                    sqlWhere : [
                                                        
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],                                                                                                                                            
                                                    sqlOrderBy : " T1.id DESC",
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Tecnico',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'T1.id_usuario',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'T1.id_usuario',
                                                                              valueField  : 'nombre_tecnico',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],                                                                                    
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT S.id_usuario FROM incidencias_seguimientos AS S INNER JOIN incidencias AS I ON(I.id = S.id_maestro AND I.problema = 'false'))"
                                                                                        ],                         
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },                                        
                                        ]}                        
                        />
                    : ''
                }
                {
                    validarModulo(3) && validarPermiso(79) ?
                        <ReportOption 
                            tab={"ReportOption"+(++tabTitle)} 
                            title='Problemas'                             
                            funcionClick = {this.actualizarReportContainer}                         
                            position={++tabLog}
                            optionWidth='240px'
                            optionMenu={[
                                            {
                                                label : 'Informe de Problemas',
                                                reportTitle : 'Informe de Problemas',
                                                table : 'incidencias',  
                                                colsHeaders : ['Fecha Problema','Ticket','Usuario',colActivo,'Departamento','Prioridad','Urgencia','Impacto','Area Servicio','Categoria','Subcategoria','Estado','Fecha Asignacion','Fecha Vencimiento','Fecha Solucion','Tecnico Asignado','Cumplimiento SLA','Finalizacion','Puntaje','Comentarios'],
                                                colsData : ['fecha','id','usuario',bodyActivo,'departamento','nom_prioridad','nom_urgencia','nom_impacto','nombre_area','categoria','subcategoria','estado','fecha_asignacion_problema','fecha_vencimiento_problema','fecha_solucion_problema','nombre_tecnico','cumplimiento','tipo_finalizacion','puntaje_encuesta','comentarios'],                                                                                      
                                                dateFilter : 'true', 
                                                dataFilter : [
                                                    {
                                                        label   : 'Prioridad',
                                                        dinamic : 'false',
                                                        field   : 'T1.prioridad',
                                                        options :  [{
                                                                        id: '4',
                                                                        nombre: 'Crítica',
                                                                    },
                                                                    {
                                                                        id: '3',
                                                                        nombre: 'Alta',
                                                                    },
                                                                    {
                                                                        id: '2',
                                                                        nombre: 'Media', 
                                                                    },
                                                                    {
                                                                        id: '1',
                                                                        nombre: 'Baja', 
                                                                    }]
                                                    },
                                                    {
                                                        label   : 'Estado',
                                                        dinamic : 'false',
                                                        field   : 'T1.estado',
                                                        options :  [{
                                                                        id: '0',
                                                                        nombre: 'Sin Asignar',
                                                                    },
                                                                    {
                                                                        id: '1',
                                                                        nombre: 'Asignado', 
                                                                    },
                                                                    {
                                                                        id: '2',
                                                                        nombre: 'En Proceso', 
                                                                    },
                                                                    {
                                                                        id: '3',
                                                                        nombre: 'Solucionado', 
                                                                    },
                                                                    {
                                                                        id: '4',
                                                                        nombre: 'Rechazado', 
                                                                    },
                                                                    {
                                                                        id: '5',
                                                                        nombre: 'Finalizado', 
                                                                    },
                                                                    {
                                                                        id: '6',
                                                                        nombre: 'Anulado', 
                                                                    }]
                                                    },
                                                    {
                                                        label : "A. Servicio",
                                                        dinamic : 'true',
                                                        field : 'T1.id_area',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],
                                                                        sqlWhere : [ " AND atencion_problemas='true'"+whereArea ],                                                                                                                                                                                                                            
                                                                    },
                                                        apiField : 'areas_servicio',
                                                        valueName : 'nombre',
                                                    }         
                                                ], 
                                                btnExcel : 'true',
                                                excelName : 'problemas',
                                                //btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',                                                   
                                                        'T1.id_activo',
                                                        'A.nombre AS nombre_activo',
                                                        'T1.id_activo',
                                                        'T1.prioridad',
                                                        'T1.impacto',
                                                        'D.nombre AS departamento',
                                                        'AR.nombre AS nombre_area',
                                                        'IC.nombre AS categoria',
                                                        'ISC.nombre AS subcategoria',
                                                        "DATE_FORMAT(T1.fecha_asignacion_problema,'%Y-%m-%d %H:%i:%s') AS fecha_asignacion_problema",
                                                        "DATE_FORMAT(T1.fecha_solucion_problema,'%Y-%m-%d %H:%i:%s') AS fecha_solucion_problema",
                                                        "DATE_FORMAT(T1.fecha_vencimiento_problema,'%Y-%m-%d %H:%i:%s') AS fecha_vencimiento_problema",
                                                        'T1.urgencia',
                                                        'T1.descripcion',
                                                        `CASE
                                                           WHEN T1.prioridad = '1' THEN 'Baja' 
                                                           WHEN T1.prioridad = '2' THEN 'Media'
                                                           WHEN T1.prioridad = '3' THEN 'Alta'
                                                           WHEN T1.prioridad = '4' THEN 'Crítica'
                                                           ELSE ''
                                                        END AS nom_prioridad`,
                                                        `CASE
                                                           WHEN T1.urgencia = '1' THEN 'Baja' 
                                                           WHEN T1.urgencia = '2' THEN 'Media'
                                                           WHEN T1.urgencia = '3' THEN 'Alta'
                                                           WHEN T1.urgencia = '4' THEN 'Crítica'
                                                           ELSE ''
                                                        END AS nom_urgencia`,
                                                        `CASE
                                                           WHEN T1.impacto = '1' THEN 'Bajo' 
                                                           WHEN T1.impacto = '2' THEN 'Medio'
                                                           WHEN T1.impacto = '3' THEN 'Alto'
                                                           WHEN T1.impacto = '4' THEN 'Crítico'
                                                           ELSE ''
                                                        END AS nom_impacto`,
                                                        'T1.asunto', 
                                                        "DATE_FORMAT(T1.fecha_problema,'%Y-%m-%d %H:%i:%s') AS fecha", 
                                                        "SE.nombre AS estado", 
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS nombre_tecnico", 
                                                        "ROUND(T1.puntaje_encuesta) AS puntaje_encuesta",
                                                        "E.comentarios",
                                                        `CASE
                                                           WHEN T1.problema_cumplimiento = 'true' THEN 'Cumple' 
                                                           WHEN T1.problema_cumplimiento = 'false' THEN 'No Cumple'
                                                           ELSE ''
                                                        END AS cumplimiento`,
                                                        "T1.tipo_finalizacion",                                                                                                       
                                                    ],
                                                    sqlJoin : [
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_problema)',
                                                        'INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)',                                                      
                                                        'INNER JOIN areas_servicio AS AR ON (AR.id = T1.id_area)', 
                                                        'INNER JOIN areas_servicio_categorias AS IC ON (IC.id = T1.id_categoria)', 
                                                        'INNER JOIN areas_servicio_subcategorias ISC ON (ISC.id = T1.id_subcategoria)',
                                                        'INNER JOIN departamentos AS D ON (D.id = U.id_departamento)',                                                     
                                                        'LEFT JOIN activos AS A ON (A.id = T1.id_activo)',
                                                        "LEFT JOIN encuesta_satisfaccion_respuestas AS E ON (E.id_solicitud = T1.id AND E.tabla = 'incidencias' AND E.id_pregunta = 0)",                                       
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        ' AND T1.problema = "true"'
                                                    ],                                                
                                                    fieldFechas : "T1.fecha_problema",
                                                    fecha_completa : "true",
                                                    sqlOrderBy : "T1.id DESC"                                                                    
                                                }
                                            },
                                            {
                                                label : 'Informe de Encuestas',
                                                reportTitle : 'Informe de Encuestas de Problemas',
                                                table : 'incidencias',  
                                                colsHeaders : ['Fecha Solicitud','Ticket','Asunto','Pregunta','Usuario','Tecnico','Puntaje'],
                                                colsData : ['fecha','id','asunto','pregunta','usuario','tecnico','puntaje'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'problemas_encuestas',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',                                                    
                                                        'T1.asunto',
                                                        'E.puntaje',                                                   
                                                        "IF(E.nombre_pregunta IS NOT NULL,E.nombre_pregunta,EP.nombre) AS pregunta", 
                                                        "DATE_FORMAT(T1.fecha_problema,'%Y-%m-%d %H:%i:%s') AS fecha",
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",                                                                                                      
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_satisfaccion_respuestas AS E ON (T1.id = E.id_solicitud AND E.tabla='incidencias')",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_problema)',                                                    
                                                        "INNER JOIN encuesta_satisfaccion_preguntas AS EP ON (EP.id = E.id_pregunta)",                                       
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        ' AND T1.problema = "true"'
                                                    ],                                                
                                                    sqlOrderBy : " T1.id DESC,puntaje DESC",
                                                    fieldFechas : "T1.fecha_problema",
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Tecnico',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tecnico_problema',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_tecnico_problema',
                                                                              valueField  : 'nombre_tecnico',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT id_tecnico_problema FROM incidencias WHERE problema = 'true' AND estado=5)"
                                                                                        ],                         
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },
                                            {
                                                label : 'Informe de Comentarios',
                                                reportTitle : 'Informe de Comentarios de Problemas',
                                                table : 'incidencias',  
                                                colsHeaders : ['Fecha Solicitud','Ticket','Asunto','Usuario','Tecnico','Puntaje','Comentarios'],
                                                colsData : ['fecha','id','asunto','usuario','tecnico','puntaje','comentarios'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'problemas_comentarios',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',                                                    
                                                        'ROUND(T1.puntaje_encuesta) AS puntaje',
                                                        'E.comentarios',                                                   
                                                        'T1.asunto', 
                                                        "DATE_FORMAT(T1.fecha_problema,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",                                                                                                      
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_satisfaccion_respuestas AS E ON (T1.id = E.id_solicitud AND E.tabla='incidencias' AND E.id_pregunta=0)",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_problema)', 
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        ' AND T1.problema = "true"'
                                                    ],                                                
                                                    sqlOrderBy : " T1.id DESC",
                                                    fieldFechas : "T1.fecha_problema",
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Tecnico',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tecnico_problema',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_tecnico_problema',
                                                                              valueField  : 'nombre_tecnico',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT id_tecnico_problema FROM incidencias WHERE problema = 'true' AND estado=5)"
                                                                                        ],                         
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },
                                            {
                                                label : 'Log de Seguimientos',
                                                reportTitle : 'Informe de Seguimientos de Problemas',
                                                table : 'incidencias_seguimientos',  
                                                colsHeaders : ['Fecha','Ticket','Estado','Usuario','Observacion'],
                                                colsData : ['fecha','id_maestro','estado','usuario','observacion'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'problemas_seguimientos',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id_maestro',
                                                        'T1.estado',                                                   
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario", 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "T1.observacion",                                                                                                                                                         
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN incidencias AS I ON (I.id = T1.id_maestro AND I.problema = 'true')",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',                                                    
                                                    ],
                                                    sqlWhere : [
                                                        
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],                                                                                                                                             
                                                    sqlOrderBy : " T1.id DESC",
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Tecnico',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'T1.id_usuario',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'T1.id_usuario',
                                                                              valueField  : 'nombre_tecnico',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],                                                                                    
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT S.id_usuario FROM incidencias_seguimientos AS S INNER JOIN incidencias AS I ON(I.id = S.id_maestro AND I.problema = 'true'))"
                                                                                        ],                         
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },                                         
                                        ]}                        
                        />
                    : ''
                }
                {
                    validarModulo(4) && validarPermiso(80) ?    
                        <ReportOption 
                            tab={"ReportOption"+(++tabTitle)} 
                            title='Servicios'                            
                            funcionClick = {this.actualizarReportContainer}                         
                            position={++tabLog}
                            optionWidth='240px'
                            optionMenu={[
                                            {
                                                label : 'Informe de Servicios',
                                                reportTitle : 'Informe de Servicios',
                                                table : 'servicios',  
                                                colsHeaders : ['Fecha Problema','Ticket','Usuario',colActivo,'Departamento','Prioridad','Urgencia','Impacto','Area Servicio','Categoria','Subcategoria','Estado','Fecha Asignacion','Fecha Vencimiento','Fecha Solucion','Tecnico Asignado','Cumplimiento SLA','Finalizacion','Puntaje','Comentarios'],
                                                colsData : ['fecha','id','usuario',bodyActivo,'departamento','nom_prioridad','nom_urgencia','nom_impacto','nombre_area','categoria','subcategoria','estado','fecha_asignacion_servicio','fecha_vencimiento_servicio','fecha_solucion_servicio','nombre_tecnico','cumplimiento','tipo_finalizacion','puntaje_encuesta','comentarios'],                                                                                      
                                                dateFilter : 'true',
                                                dataFilter : [
                                                    {
                                                        label   : 'Prioridad',
                                                        dinamic : 'false',
                                                        field   : 'T1.prioridad',
                                                        options :  [{
                                                                        id: '4',
                                                                        nombre: 'Crítica',
                                                                    },
                                                                    {
                                                                        id: '3',
                                                                        nombre: 'Alta',
                                                                    },
                                                                    {
                                                                        id: '2',
                                                                        nombre: 'Media', 
                                                                    },
                                                                    {
                                                                        id: '1',
                                                                        nombre: 'Baja', 
                                                                    }]
                                                    },
                                                    {
                                                        label   : 'Estado',
                                                        dinamic : 'false',
                                                        field   : 'T1.estado',
                                                        options :  [{
                                                                        id: '0',
                                                                        nombre: 'Sin Asignar',
                                                                    },
                                                                    {
                                                                        id: '1',
                                                                        nombre: 'Asignado', 
                                                                    },
                                                                    {
                                                                        id: '2',
                                                                        nombre: 'En Proceso', 
                                                                    },
                                                                    {
                                                                        id: '3',
                                                                        nombre: 'Solucionado', 
                                                                    },
                                                                    {
                                                                        id: '4',
                                                                        nombre: 'Rechazado', 
                                                                    },
                                                                    {
                                                                        id: '5',
                                                                        nombre: 'Finalizado', 
                                                                    },
                                                                    {
                                                                        id: '6',
                                                                        nombre: 'Anulado', 
                                                                    }]
                                                    },
                                                    {
                                                        label : "A. Servicio",
                                                        dinamic : 'true',
                                                        field : 'T1.id_area',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],
                                                                        sqlWhere : [ " AND atencion_problemas='true'"+whereArea ],                                                                                                                                                                                                                            
                                                                    },
                                                        apiField : 'areas_servicio',
                                                        valueName : 'nombre',
                                                    }                                                         
                                                ],  
                                                btnExcel : 'true',
                                                excelName : 'servicios',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',                                                 
                                                        'T1.id_activo',
                                                        'A.nombre AS nombre_activo',
                                                        'T1.id_activo',
                                                        'T1.prioridad',
                                                        'T1.impacto',
                                                        'D.nombre AS departamento',
                                                        'AR.nombre AS nombre_area',
                                                        'IC.nombre AS categoria',
                                                        'ISC.nombre AS subcategoria',
                                                        "DATE_FORMAT(T1.fecha_asignacion_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_asignacion_servicio",
                                                        "DATE_FORMAT(T1.fecha_solucion_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_solucion_servicio",
                                                        "DATE_FORMAT(T1.fecha_vencimiento_servicio,'%Y-%m-%d %H:%i:%s') AS fecha_vencimiento_servicio",
                                                        'T1.urgencia',
                                                        'T1.descripcion',
                                                        `CASE
                                                           WHEN T1.prioridad = '1' THEN 'Baja' 
                                                           WHEN T1.prioridad = '2' THEN 'Media'
                                                           WHEN T1.prioridad = '3' THEN 'Alta'
                                                           WHEN T1.prioridad = '4' THEN 'Crítica'
                                                           ELSE ''
                                                        END AS nom_prioridad`,
                                                        `CASE
                                                           WHEN T1.urgencia = '1' THEN 'Baja' 
                                                           WHEN T1.urgencia = '2' THEN 'Media'
                                                           WHEN T1.urgencia = '3' THEN 'Alta'
                                                           WHEN T1.urgencia = '4' THEN 'Crítica'
                                                           ELSE ''
                                                        END AS nom_urgencia`,
                                                        `CASE
                                                           WHEN T1.impacto = '1' THEN 'Bajo' 
                                                           WHEN T1.impacto = '2' THEN 'Medio'
                                                           WHEN T1.impacto = '3' THEN 'Alto'
                                                           WHEN T1.impacto = '4' THEN 'Crítico'
                                                           ELSE ''
                                                        END AS nom_impacto`,
                                                        'T1.asunto', 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha", 
                                                        "SE.nombre AS estado", 
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS nombre_tecnico",
                                                        "ROUND(T1.puntaje_encuesta) AS puntaje_encuesta",
                                                        "E.comentarios",
                                                        `CASE
                                                           WHEN T1.servicio_cumplimiento = 'true' THEN 'Cumple' 
                                                           WHEN T1.servicio_cumplimiento = 'false' THEN 'No Cumple'
                                                           ELSE ''
                                                        END AS cumplimiento`,
                                                        "T1.tipo_finalizacion",                                                                                                        
                                                    ],
                                                    sqlJoin : [
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_servicio)',
                                                        'INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)',                                                     
                                                        'INNER JOIN areas_servicio AS AR ON (AR.id = T1.id_area)', 
                                                        'INNER JOIN areas_servicio_categorias AS IC ON (IC.id = T1.id_categoria)', 
                                                        'INNER JOIN areas_servicio_subcategorias ISC ON (ISC.id = T1.id_subcategoria)',
                                                        'INNER JOIN departamentos AS D ON (D.id = U.id_departamento)', 
                                                        'LEFT JOIN activos AS A ON (A.id = T1.id_activo)',
                                                        "LEFT JOIN encuesta_satisfaccion_respuestas AS E ON (E.id_solicitud = T1.id AND E.tabla = 'servicios' AND E.id_pregunta = 0)",                                       
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        
                                                    ],                                               
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",
                                                    sqlOrderBy : "T1.id DESC"                                                                     
                                                }
                                            },
                                            {
                                                label : 'Informe de Encuestas',
                                                reportTitle : 'Informe de Encuestas de Servicios',
                                                table : 'servicios',  
                                                colsHeaders : ['Fecha Solicitud','Ticket','Asunto','Pregunta','Usuario','Tecnico','Puntaje'],
                                                colsData : ['fecha','id','asunto','pregunta','usuario','tecnico','puntaje'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'servicios_encuestas',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',
                                                        'T1.asunto',                                                   
                                                        'E.puntaje',                                         
                                                        "IF(E.nombre_pregunta IS NOT NULL,E.nombre_pregunta,EP.nombre) AS pregunta", 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",  
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",                                                                                                      
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_satisfaccion_respuestas AS E ON (T1.id = E.id_solicitud AND E.tabla='servicios')",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_servicio)',                                                    
                                                        "INNER JOIN encuesta_satisfaccion_preguntas AS EP ON (EP.id = E.id_pregunta)",                                       
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                       
                                                    ],  
                                                    sqlOrderBy : " T1.id DESC,puntaje DESC",
                                                    fieldFechas : "T1.fecha",                                               
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Tecnico',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tecnico_servicio',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_tecnico_servicio',
                                                                              valueField  : 'nombre_tecnico',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                           " AND id IN (SELECT id_tecnico_servicio FROM servicios WHERE estado=5)"
                                                                                        ],                        
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },
                                            {
                                                label : 'Informe de Comentarios',
                                                reportTitle : 'Informe de Comentarios de Servicios',
                                                table : 'servicios',  
                                                colsHeaders : ['Fecha Solicitud','Ticket','Asunto','Usuario','Tecnico','Puntaje','Comentarios'],
                                                colsData : ['fecha','id','asunto','usuario','tecnico','puntaje','comentarios'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'servicios_comentarios',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',                                                    
                                                        'ROUND(T1.puntaje_encuesta) AS puntaje',
                                                        'E.comentarios',                                                   
                                                        'T1.asunto', 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS tecnico",                                                                                                      
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_satisfaccion_respuestas AS E ON (T1.id = E.id_solicitud AND E.tabla='servicios' AND E.id_pregunta=0)",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_tecnico_servicio)', 
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                       
                                                    ],                                                
                                                    sqlOrderBy : " T1.id DESC",
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Tecnico',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tecnico_servicio',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_tecnico_servicio',
                                                                              valueField  : 'nombre_tecnico',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT id_tecnico_servicio FROM servicios WHERE estado=5)"
                                                                                        ],                         
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },
                                            {
                                                label : 'Log de Seguimientos',
                                                reportTitle : 'Informe de Seguimientos de Servicios',
                                                table : 'servicios_seguimientos',  
                                                colsHeaders : ['Fecha','Ticket','Estado','Usuario','Observacion'],
                                                colsData : ['fecha','id_maestro','estado','usuario','observacion'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'servicios_seguimientos',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id_maestro',
                                                        'T1.estado',                                                   
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario", 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "T1.observacion",                                                                                                                                                         
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN servicios AS I ON (I.id = T1.id_maestro)",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',                                                    
                                                    ],
                                                    sqlWhere : [
                                                        
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],                                                                                                                                               
                                                    sqlOrderBy : " T1.id DESC",
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Tecnico',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'T1.id_usuario',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'T1.id_usuario',
                                                                              valueField  : 'nombre_tecnico',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],                                                                                    
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT S.id_usuario FROM servicios_seguimientos AS S INNER JOIN servicios AS I ON(I.id = S.id_maestro))"
                                                                                        ],                         
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },                                          
                                        ]}                        
                        />
                    : ''
                }
                {
                    validarModulo(6) && validarPermiso(81) ?
                        <ReportOption 
                            tab={"ReportOption"+(++tabTitle)} 
                            title='Activos'
                            funcionClick = {this.actualizarReportContainer}                         
                            position={++tabLog}
                            optionWidth='240px'
                            optionMenu={[
                                            {
                                                label : 'Informe de Activos',
                                                reportTitle : 'Informe de Activos',
                                                table : 'activos',  
                                                colsHeaders : ['Codigo','Tipo','Marca','Nombre','Departamento','Estado','Proveedor','Fecha Compra','Precio Compra','Asignado'],
                                                colsData : ['codigo','tipo_activo','marca','nombre','departamento','estado','proveedor','fecha_compra','precio_compra','asignado'],                                                                                      
                                                dataFilter : [
                                                    {
                                                        label : "Tipo",
                                                        dinamic : 'true',
                                                        field : 'T1.id_tipo',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],                                                                                                                                                                                                                                                                                               
                                                                    },
                                                        apiField : 'activos_tipos',
                                                        valueName : 'nombre',
                                                    },
                                                    {
                                                        label : "Estado",
                                                        dinamic : 'true',
                                                        field : 'T1.estado',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],                                                                                                                                                                                                                                                                                               
                                                                    },
                                                        apiField : 'activos_estados',
                                                        valueName : 'nombre',
                                                    },
                                                    {
                                                        label : "Depto",
                                                        dinamic : 'true',
                                                        field : 'T1.id_departamento',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],                                                                                                                                                                                                                                                                                               
                                                                    },
                                                        apiField : 'departamentos',
                                                        valueName : 'nombre',
                                                    },
                                                    {                                                    
                                                        label   : 'Asignado',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_asignado',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_asignado',
                                                                              valueField  : 'nombre_asignado',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT T1.id_asignado FROM activos AS T1 WHERE T1.activo = 1)"
                                                                                        ],                        
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                    {                                                    
                                                        label   : 'Proveedor',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_proveedor',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre_comercial',
                                                                              idField     : 'id_proveedor',
                                                                              valueField  : 'nombre_proveedor',
                                                                         },
                                                                         apiField    : 'terceros',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nit',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre_comercial'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre_comercial'                                
                                                                                        ],  
                                                                                        sqlWhere : [
                                                                                            " AND proveedor='true' AND id IN (SELECT T1.id_proveedor FROM activos AS T1 WHERE T1.activo = 1)"
                                                                                        ],                           
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre_comercial',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },                                                         
                                                ], 
                                                btnExcel : 'true',
                                                excelName : 'activos',
                                                //btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',
                                                        'T1.nombre',                                
                                                        'DATE_FORMAT(T1.fecha_compra,"%Y-%m-%d") AS fecha_compra',
                                                        'TA.nombre AS tipo_activo',
                                                        'P.nombre_comercial AS proveedor', 
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS asignado",                              
                                                        'T1.precio_compra',                                                        
                                                        'T1.codigo',                                                                                        
                                                        'T1.marca',
                                                        'AE.nombre AS estado',                                                        
                                                        'D.nombre AS departamento',                                                                
                                                    ],
                                                    sqlJoin : [
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_asignado)',
                                                        'INNER JOIN activos_estados AS AE ON (AE.id = T1.estado)', 
                                                        'INNER JOIN activos_tipos AS TA ON (TA.id = T1.id_tipo)', 
                                                        'INNER JOIN terceros AS P ON (P.id = T1.id_proveedor)',
                                                        'INNER JOIN departamentos AS D ON (D.id = T1.id_departamento)'
                                                    ],
                                                    sqlWhere : [ 

                                                    ],
                                                    fieldSearch : [
                                                        'T1.nombre',
                                                        'T1.fecha_compra',
                                                        'T1.precio_compra',
                                                        'TA.nombre',
                                                        'P.nombre_comercial'                               
                                                    ],                                                   
                                                    sqlOrderBy : "T1.id_tipo ASC,T1.nombre ASC"                                                             
                                                }
                                            },                                                                                    
                                        ]}                        
                        />
                    : ''
                }
                {
                    validarPermiso(82) ?
                        <ReportOption 
                            tab={"ReportOption"+(++tabTitle)} 
                            title='Usuarios'
                            funcionClick = {this.actualizarReportContainer}                         
                            position={++tabLog}
                            optionWidth='240px'
                            optionMenu={[
                                            {
                                                label : 'Informe de Usuarios',
                                                reportTitle : 'Informe de Usuarios',
                                                table : 'users',  
                                                colsHeaders : ['Tipo Documento','Documento','Nombre','Email','Rol','Area','Departamento','Direccion','Telefono','Fecha Nacimiento','Estado'],
                                                colsData : ['tipo_documento','documento','nombre','email','rol','nombre_area','nombre_departamento','direccion','telefono','fecha_nacimiento','estado'],                                                                                      
                                                dataFilter : [
                                                    {
                                                        label : "A. Servicio",
                                                        dinamic : 'true',
                                                        field : 'T1.id_area',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],
                                                                        sqlWhere : [ whereArea ]                                                                                                                                                                                                                                                                                   
                                                                    },
                                                        apiField : 'areas_servicio',
                                                        valueName : 'nombre',
                                                    },
                                                    {
                                                        label : "Depto",
                                                        dinamic : 'true',
                                                        field : 'T1.id_departamento',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],                                                                                                                                                                                                                                                                                   
                                                                    },
                                                        apiField : 'departamentos',
                                                        valueName : 'nombre',
                                                    },
                                                    {
                                                        label : "Rol",
                                                        dinamic : 'true',
                                                        field : 'T1.id_rol',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],
                                                                        sqlWhere : [
                                                                            " AND id > 1"
                                                                        ]                                                                                                                                                                                                                                                                                   
                                                                    },
                                                        apiField : 'roles',
                                                        valueName : 'nombre',
                                                    },
                                                    {
                                                        label   : 'Estado',
                                                        dinamic : 'false',
                                                        field   : 'T1.activo',
                                                        options :  [{
                                                                        id: '0',
                                                                        nombre: 'Eliminado',
                                                                    },
                                                                    {
                                                                        id: '1',
                                                                        nombre: 'Activo',
                                                                    }]
                                                    },                                                         
                                                ], 
                                                btnExcel : 'true',
                                                excelName : 'usuarios',
                                                //btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'T1.id',                                                
                                                        'DT.nombre AS tipo_documento',                                                
                                                        'T1.documento',
                                                        'T1.nombre',                                                
                                                        'T1.email',
                                                        'T1.fecha_nacimiento',
                                                        'T1.direccion',
                                                        'A.nombre AS nombre_area',
                                                        'D.nombre AS nombre_departamento',
                                                        'T1.telefono',                                                
                                                        'RL.nombre AS rol',
                                                        'T1.fecha_nacimiento',
                                                        "IF(T1.activo=1,'ACTIVO','ELIMINADO') AS estado",
                                                        'DATE_FORMAT(T1.fecha_nacimiento,"%Y-%m-%d") AS fecha_nacimiento',                                                
                                                    ],                                                      
                                                    sqlJoin : [
                                                        'INNER JOIN document_types AS DT ON (DT.id = T1.id_tipo_documento)', 
                                                        'INNER JOIN roles AS RL ON (RL.id = T1.id_rol)',
                                                        'INNER JOIN departamentos AS D ON (D.id = T1.id_departamento)',
                                                        'LEFT JOIN areas_servicio AS A ON (A.id = T1.id_area)' 
                                                    ],
                                                    sqlWhere : [ 
                                                        "T1.id > 0"
                                                    ],                                            
                                                    sqlInactive : 'true',
                                                    fieldSearch : [
                                                                                       
                                                    ],
                                                    sqlOrderBy : 'T1.nombre ASC'                                                             
                                                }
                                            },                                                                                    
                                        ]}                        
                        />
                    : ''
                }
                {
                    validarModulo(7) && validarPermiso(83) ?                        
                        <ReportOption 
                            tab={"ReportOption"+(++tabTitle)} 
                            title='Proveedores'                             
                            funcionClick = {this.actualizarReportContainer}                         
                            position={++tabLog}
                            optionWidth='240px'
                            optionMenu={[
                                            {
                                                label : 'Informe de Proveedores',
                                                reportTitle : 'Informe de Proveedores',
                                                table : 'terceros',  
                                                colsHeaders : ['Tipo Documento','Documento','Razon Social','Nombre Comercial','Direccion','Telefono','Puntaje'],
                                                colsData : ['tipo_documento','documento','razon_social','nombre_comercial','direccion','telefono','puntaje'],                                                                                      
                                                dateFilter : 'false',                                             
                                                btnExcel : 'true',
                                                excelName : 'terceros',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'DT.nombre AS tipo_documento',                                                    
                                                        'T1.documento',
                                                        'T1.razon_social',
                                                        'T1.nombre_comercial',
                                                        'T1.nombre_contacto',
                                                        'T1.direccion',
                                                        'T1.email',
                                                        'T1.telefono',
                                                        'ROUND(T1.puntaje_proveedor) AS puntaje',                                                                                                        
                                                    ],
                                                    sqlJoin : [
                                                        'INNER JOIN document_types AS DT ON (DT.id = T1.id_tipo_documento)'                        
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],                                                
                                                    sqlWhere : [
                                                        " AND proveedor='true'"
                                                    ],                                                                                                                                                                                                              
                                                },                                            
                                            },
                                            {
                                                label : 'Informe de Contratos',
                                                reportTitle : 'Informe de Contratos',
                                                table : 'terceros_contratos',  
                                                colsHeaders : ['Proveedor','Consecutivo','Nombre','Area Servicio','Tipo','Estado','Objeto Contrato','Moneda','Monto sin IVA','IVA','Responsable Ejecucion','Fecha Inicio','Fecha Vencimiento','Plan Pago','Numero de Pagos','Ultimo Pago Realizado','Responsable Pago','Venc. Ultimo Pago','Tasa Negociacion','Renovacion Automatica','Observaciones'],
                                                colsData : ['proveedor','id','nombre','nom_area','tipo_contrato','nom_estado','objeto_contrato','moneda','monto','iva','responsable_ejecucion','fecha_inicio','fecha_vencimiento','nom_plan_pago','numero_pagos','fecha_ultimo_pago','responsable_pago','vencimiento_ultimo_pago','tasa_negociacion','nom_renovacion','observaciones'],                                                                                      
                                                dateFilter : 'true',
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Proveedor',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tercero',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre_comercial',
                                                                              idField     : 'id_tercero',
                                                                              valueField  : 'nombre_tercero',
                                                                         },
                                                                         apiField    : 'terceros',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nit',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre_comercial'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre_comercial'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                            "  AND proveedor='true' AND id IN (SELECT id_tercero FROM terceros_contratos WHERE tipo='proveedor')"
                                                                                        ],                           
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre_comercial',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                    {                                                    
                                                        label   : 'R.Ejecucion',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_responsable_ejecucion',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_responsable_ejecucion',
                                                                              valueField  : 'nombre_responsable_ejecucion',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT id_responsable_ejecucion FROM terceros_contratos WHERE tipo='proveedor')"
                                                                                        ],                        
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },                                                    
                                                    {
                                                        label : "A. Servicio",
                                                        dinamic : 'true',
                                                        field : 'T1.id_area',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],
                                                                        sqlWhere : [ " AND atencion_incidencias='true'"+whereArea ],                                                                                                                                                                                                                            
                                                                    },
                                                        apiField : 'areas_servicio',
                                                        valueName : 'nombre',
                                                    },
                                                    {
                                                        label : "Estado",
                                                        dinamic : 'true',
                                                        field : 'T1.estado',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],                                                                                                                                                                                                                                                                                                    
                                                                    },
                                                        apiField : 'terceros_contratos_estados',
                                                        valueName : 'nombre',
                                                    }
                                                ],                                             
                                                btnExcel : 'true',
                                                excelName : 'terceros_contratos',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        "T1.id",
                                                        "P.razon_social AS proveedor",                                                        
                                                        "T1.nombre",
                                                        "M.nombre AS moneda",
                                                        "T1.tipo_contrato",
                                                        "T1.objeto_contrato",  
                                                        "T1.estado", 
                                                        "PC.nombre AS nom_estado",
                                                        "CP.nombre AS nom_plan_pago",
                                                        "AR.nombre AS nom_area",   
                                                        "T1.id_plan_pago",
                                                        "T1.numero_pagos",
                                                        "T1.nombre_responsable_pago",
                                                        "T1.email_responsable_pago", 
                                                        "T1.fecha_ultimo_pago",                               
                                                        "T1.iva", 
                                                        "T1.monto", 
                                                        "CONCAT(M.simbolo,CAST(FORMAT(T1.monto+(T1.monto*(T1.iva/100)),0) AS CHAR)) AS valor_monto", 
                                                        "T1.id_responsable_ejecucion", 
                                                        'U.nombre AS responsable_ejecucion',  
                                                        "T1.id_responsable_pago",
                                                        'U1.nombre AS responsable_pago',
                                                        "T1.tasa_negociacion",   
                                                        "T1.renovacion_automatica",
                                                        "IF(T1.renovacion_automatica='true','SI','NO') AS nom_renovacion",
                                                        "T1.observaciones",  
                                                        "DATE_FORMAT(T1.fecha_inicio,'%Y-%m-%d') AS fecha_inicio",                                                  
                                                        "DATE_FORMAT(T1.fecha_ultimo_pago,'%Y-%m-%d') AS fecha_ultimo_pago", 
                                                        "DATE_FORMAT(T1.fecha_vencimiento,'%Y-%m-%d') AS fecha_vencimiento", 
                                                        "DATE_FORMAT(T1.vencimiento_ultimo_pago,'%Y-%m-%d') AS vencimiento_ultimo_pago",
                                                    ],
                                                    fieldSearch : [
                                                        "T1.nombre",                                                                
                                                    ],                             
                                                    sqlJoin : [ 
                                                        "INNER JOIN terceros AS P ON (P.id = T1.id_tercero AND P.activo = 1)",
                                                        'INNER JOIN monedas AS M ON (M.id = T1.id_moneda)',
                                                        'INNER JOIN areas_servicio AS AR ON (AR.id = T1.id_area)', 
                                                        'INNER JOIN terceros_contratos_estados AS PC ON (PC.id = T1.estado)',
                                                        'INNER JOIN terceros_contratos_planes_pagos AS CP ON (CP.id = T1.id_plan_pago)',
                                                        'INNER JOIN users AS U ON (U.id = T1.id_responsable_ejecucion)',
                                                        'LEFT JOIN users AS U1 ON (U1.id = T1.id_responsable_pago)',
                                                    ],
                                                    sqlWhere : [
                                                        " AND T1.tipo='proveedor'",                                                        
                                                    ],  
                                                    sqlOrderBy : "P.razon_social ASC,T1.id DESC",                                                  
                                                    fieldFechas : "T1.fecha_inicio",
                                                    fecha_completa : "true",                                                                                                                                                                                                              
                                                },                                            
                                            },
                                            {
                                                label : 'Informe de Pagos',
                                                reportTitle : 'Informe de Pagos',
                                                table : 'terceros_contratos_pagos',  
                                                colsHeaders : ['Proveedor','Contrato','Consecutivo','Numero Factura','Fecha Factura','Valor'],
                                                colsData : ['razon_social','nombre_contrato','id_contrato','numero_factura','fecha_factura','valor'],                                                                                      
                                                dateFilter : 'true',
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Proveedor',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'C.id_tercero',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre_comercial',
                                                                              idField     : 'id_tercero',
                                                                              valueField  : 'nombre_tercero',
                                                                         },
                                                                         apiField    : 'terceros',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nit',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre_comercial'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre_comercial'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                            "  AND proveedor='true' AND id IN (SELECT id_tercero FROM terceros_contratos WHERE tipo='proveedor')"
                                                                                        ],                           
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre_comercial',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                    {                                                    
                                                        label   : 'Contrato',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_contrato',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_contrato',
                                                                              valueField  : 'nombre_contrato',
                                                                         },
                                                                         apiField    : 'terceros_contratos',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Consecutivo',
                                                                                                field : 'id'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'nombre',                                                                                                                         
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            ` AND id IN (
                                                                                                SELECT 
                                                                                                    P.id_contrato 
                                                                                                FROM terceros_contratos_pagos AS P
                                                                                                INNER JOIN terceros_contratos AS TC ON(TC.id = P.id_contrato AND TC.tipo='proveedor')                                                                                                
                                                                                            )`
                                                                                        ],                                                                                                                
                                                                                        fieldSearch : [
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    } 
                                                ],                                             
                                                btnExcel : 'true',
                                                excelName : 'terceros_contratos_pagos',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        "T1.id",
                                                        "T.razon_social",
                                                        "C.id AS id_contrato",
                                                        "C.nombre AS nombre_contrato",
                                                        "T1.numero_factura",
                                                        "DATE_FORMAT(T1.fecha_factura,'%Y-%m-%d') AS fecha_factura", 
                                                        "T1.valor"
                                                    ],
                                                    fieldSearch : [
                                                                                                                        
                                                    ],                             
                                                    sqlJoin : [                                                         
                                                        "INNER JOIN terceros_contratos AS C ON(C.id = T1.id_contrato AND C.tipo = 'proveedor')",
                                                        "INNER JOIN terceros AS T ON(T.id = C.id_tercero AND T.activo=1)",
                                                    ],
                                                    sqlWhere : [
                                                                                                                
                                                    ],                                                      
                                                    sqlOrderBy : "C.nombre ASC,T1.fecha_factura DESC",                                                  
                                                    fieldFechas : "T1.fecha_factura",
                                                    fecha_completa : "true",                                                                                                                                                                                                              
                                                },                                            
                                            },
                                            {
                                                label : 'Informe de Encuestas',
                                                reportTitle : 'Informe de Encuestas de Proveedores',
                                                table : 'encuesta_terceros',  
                                                colsHeaders : ['Fecha Encuesta','Proveedor','Pregunta','Usuario','Puntaje'],
                                                colsData : ['fecha','proveedor','pregunta','usuario','puntaje'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'terceros_encuestas',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [                                                    
                                                        'P.razon_social AS proveedor',
                                                        'ROUND(E.puntaje) AS puntaje',                                                    
                                                        "IF(E.nombre_pregunta IS NOT NULL,E.nombre_pregunta,EP.nombre) AS pregunta",  
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",                                                                                                                                                         
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_terceros_respuestas AS E ON (T1.id = E.id_encuesta)",
                                                        'INNER JOIN users AS U ON (U.id = T1.id_usuario)',   
                                                        'INNER JOIN terceros AS P ON (P.id = T1.id_tercero)',                                                                                                      
                                                        "INNER JOIN encuesta_terceros_preguntas AS EP ON (EP.id = E.id_pregunta)",                                       
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        " AND T1.tipo='proveedor'"
                                                    ],
                                                    sqlOrderBy : " P.razon_social ASC,E.puntaje DESC",
                                                    fieldFechas : "T1.fecha",                                               
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Proveedor',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tercero',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre_comercial',
                                                                              idField     : 'id_tercero',
                                                                              valueField  : 'nombre_tercero',
                                                                         },
                                                                         apiField    : 'terceros',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nit',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre_comercial'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre_comercial'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                            "  AND proveedor='true' AND id IN (SELECT id_tercero FROM encuesta_terceros WHERE tipo='proveedor')"
                                                                                        ],                           
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre_comercial',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },
                                            {
                                                label : 'Informe de Comentarios',
                                                reportTitle : 'Informe de Comentarios de Proveedores',
                                                table : 'encuesta_terceros',  
                                                colsHeaders : ['Fecha Encuesta','Proveedor','Comentarios','Usuario','Promedio Encuesta'],
                                                colsData : ['fecha','proveedor','comentarios','usuario','promedio'],                                                                                       
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'terceros_comentarios',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'P.razon_social AS proveedor',
                                                        'E.comentarios',                                                    
                                                        'ROUND(T1.promedio) AS promedio', 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",                                                                                                         
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_terceros_respuestas AS E ON (T1.id = E.id_encuesta AND E.id_pregunta=0)",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'INNER JOIN terceros AS P ON (P.id = T1.id_tercero)', 
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        " AND T1.tipo='proveedor'"
                                                    ],                                                                                     
                                                    sqlOrderBy : " P.razon_social ASC,E.puntaje DESC",                                                
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Proveedor',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tercero',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre_comercial',
                                                                              idField     : 'id_tercero',
                                                                              valueField  : 'nombre_tercero',
                                                                         },
                                                                         apiField    : 'terceros',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nit',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre_comercial'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre_comercial'                                
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            "  AND proveedor='true' AND id IN (SELECT id_tercero FROM encuesta_terceros WHERE tipo='proveedor')"
                                                                                        ],                            
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre_comercial',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },                                         
                                        ]}                        
                        />
                    : null
                }                
                {
                    validarModulo(12) && validarPermiso(84) ?                        
                        <ReportOption 
                            tab={"ReportOption"+(++tabTitle)} 
                            title='Clientes'                             
                            funcionClick = {this.actualizarReportContainer}                         
                            position={++tabLog}
                            optionWidth='240px'
                            optionMenu={[
                                            {
                                                label : 'Informe de Clientes',
                                                reportTitle : 'Informe de Clientes',
                                                table : 'terceros',  
                                                colsHeaders : ['Tipo Documento','Documento','Razon Social','Nombre Comercial','Direccion','Telefono','Puntaje'],
                                                colsData : ['tipo_documento','documento','razon_social','nombre_comercial','direccion','telefono','puntaje'],                                                                                      
                                                dateFilter : 'false',                                             
                                                btnExcel : 'true',
                                                excelName : 'terceros',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'DT.nombre AS tipo_documento',                                                    
                                                        'T1.documento',
                                                        'T1.razon_social',
                                                        'T1.nombre_comercial',
                                                        'T1.nombre_contacto',
                                                        'T1.direccion',
                                                        'T1.email',
                                                        'T1.telefono',
                                                        'ROUND(T1.puntaje_cliente) AS puntaje',                                                                                                        
                                                    ],
                                                    sqlJoin : [
                                                        'INNER JOIN document_types AS DT ON (DT.id = T1.id_tipo_documento)'                        
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],                                                
                                                    sqlWhere : [
                                                        " AND cliente='true'"
                                                    ],                                                                                                                                                                                                              
                                                },                                            
                                            },
                                            {
                                                label : 'Informe de Contratos',
                                                reportTitle : 'Informe de Contratos',
                                                table : 'terceros_contratos',  
                                                colsHeaders : ['Proveedor','Consecutivo','Nombre','Area Servicio','Tipo','Estado','Objeto Contrato','Moneda','Monto sin IVA','IVA','Responsable Ejecucion','Fecha Inicio','Fecha Vencimiento','Plan Pago','Numero de Pagos','Ultimo Pago Realizado','Responsable Pago','E-mail Responsable','Venc. Ultimo Pago','Tasa Negociacion','Renovacion Automatica','Observaciones'],
                                                colsData : ['proveedor','id','nombre','nom_area','tipo_contrato','nom_estado','objeto_contrato','moneda','monto','iva','responsable_ejecucion','fecha_inicio','fecha_vencimiento','nom_plan_pago','numero_pagos','fecha_ultimo_pago','responsable_pago','email_responsable_pago','vencimiento_ultimo_pago','tasa_negociacion','nom_renovacion','observaciones'],                                                                                      
                                                dateFilter : 'true',
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Cliente',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tercero',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre_comercial',
                                                                              idField     : 'id_tercero',
                                                                              valueField  : 'nombre_tercero',
                                                                         },
                                                                         apiField    : 'terceros',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nit',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre_comercial'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre_comercial'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                            "  AND cliente='true' AND id IN (SELECT id_tercero FROM terceros_contratos WHERE tipo='cliente')"
                                                                                        ],                           
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre_comercial',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                    {                                                    
                                                        label   : 'R.Ejecucion',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_responsable_ejecucion',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_responsable_ejecucion',
                                                                              valueField  : 'nombre_responsable_ejecucion',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            " AND id IN (SELECT id_responsable_ejecucion FROM terceros_contratos WHERE tipo='cliente')"
                                                                                        ],                        
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },                                                    
                                                    {
                                                        label : "A. Servicio",
                                                        dinamic : 'true',
                                                        field : 'T1.id_area',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],
                                                                        sqlWhere : [ " AND atencion_incidencias='true'"+whereArea ],                                                                                                                                                                                                                            
                                                                    },
                                                        apiField : 'areas_servicio',
                                                        valueName : 'nombre',
                                                    },
                                                    {
                                                        label : "Estado",
                                                        dinamic : 'true',
                                                        field : 'T1.estado',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ],                                                                                                                                                                                                                                                                                                    
                                                                    },
                                                        apiField : 'terceros_contratos_estados',
                                                        valueName : 'nombre',
                                                    }
                                                ],                                             
                                                btnExcel : 'true',
                                                excelName : 'terceros_contratos',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        "T1.id",
                                                        "P.razon_social AS proveedor",                                                        
                                                        "T1.nombre",
                                                        "M.nombre AS moneda",
                                                        "T1.tipo_contrato",
                                                        "T1.objeto_contrato",  
                                                        "T1.estado", 
                                                        "PC.nombre AS nom_estado",
                                                        "CP.nombre AS nom_plan_pago",
                                                        "AR.nombre AS nom_area",   
                                                        "T1.id_plan_pago",
                                                        "T1.numero_pagos",
                                                        "T1.nombre_responsable_pago AS responsable_pago",
                                                        "T1.email_responsable_pago", 
                                                        "T1.fecha_ultimo_pago",                               
                                                        "T1.iva", 
                                                        "T1.monto", 
                                                        "CONCAT(M.simbolo,CAST(FORMAT(T1.monto+(T1.monto*(T1.iva/100)),0) AS CHAR)) AS valor_monto", 
                                                        "T1.id_responsable_ejecucion", 
                                                        'U.nombre AS responsable_ejecucion',  
                                                        "T1.id_responsable_pago",                                                        
                                                        "T1.tasa_negociacion",   
                                                        "T1.renovacion_automatica",
                                                        "IF(T1.renovacion_automatica='true','SI','NO') AS nom_renovacion",
                                                        "T1.observaciones",  
                                                        "DATE_FORMAT(T1.fecha_inicio,'%Y-%m-%d') AS fecha_inicio",                                                  
                                                        "DATE_FORMAT(T1.fecha_ultimo_pago,'%Y-%m-%d') AS fecha_ultimo_pago", 
                                                        "DATE_FORMAT(T1.fecha_vencimiento,'%Y-%m-%d') AS fecha_vencimiento", 
                                                        "DATE_FORMAT(T1.vencimiento_ultimo_pago,'%Y-%m-%d') AS vencimiento_ultimo_pago",
                                                    ],
                                                    fieldSearch : [
                                                        "T1.nombre",                                                                
                                                    ],                             
                                                    sqlJoin : [ 
                                                        "INNER JOIN terceros AS P ON (P.id = T1.id_tercero AND P.activo = 1)",
                                                        'INNER JOIN monedas AS M ON (M.id = T1.id_moneda)',
                                                        'INNER JOIN areas_servicio AS AR ON (AR.id = T1.id_area)', 
                                                        'INNER JOIN terceros_contratos_estados AS PC ON (PC.id = T1.estado)',
                                                        'INNER JOIN terceros_contratos_planes_pagos AS CP ON (CP.id = T1.id_plan_pago)',
                                                        'INNER JOIN users AS U ON (U.id = T1.id_responsable_ejecucion)',                                                        
                                                    ],
                                                    sqlWhere : [
                                                        " AND T1.tipo='cliente'",                                                        
                                                    ],  
                                                    sqlOrderBy : "P.razon_social ASC,T1.id DESC",                                                  
                                                    fieldFechas : "T1.fecha_inicio",
                                                    fecha_completa : "true",                                                                                                                                                                                                              
                                                },                                            
                                            },
                                            {
                                                label : 'Informe de Pagos',
                                                reportTitle : 'Informe de Pagos',
                                                table : 'terceros_contratos_pagos',  
                                                colsHeaders : ['Cliente','Contrato','Consecutivo','Numero Factura','Fecha Factura','Valor'],
                                                colsData : ['razon_social','nombre_contrato','id_contrato','numero_factura','fecha_factura','valor'],                                                                                      
                                                dateFilter : 'true',
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Cliente',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'C.id_tercero',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre_comercial',
                                                                              idField     : 'id_tercero',
                                                                              valueField  : 'nombre_tercero',
                                                                         },
                                                                         apiField    : 'terceros',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nit',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre_comercial'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre_comercial'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                            "  AND cliente='true' AND id IN (SELECT id_tercero FROM terceros_contratos WHERE tipo='cliente')"
                                                                                        ],                           
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre_comercial',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                    {                                                    
                                                        label   : 'Contrato',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_contrato',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_contrato',
                                                                              valueField  : 'nombre_contrato',
                                                                         },
                                                                         apiField    : 'terceros_contratos',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Consecutivo',
                                                                                                field : 'id'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'nombre',                                                                                                                         
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            ` AND id IN (
                                                                                                SELECT 
                                                                                                    P.id_contrato 
                                                                                                FROM terceros_contratos_pagos AS P
                                                                                                INNER JOIN terceros_contratos AS TC ON(TC.id = P.id_contrato AND TC.tipo='cliente')                                                                                                
                                                                                            )`
                                                                                        ],                                                                                                                
                                                                                        fieldSearch : [
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    } 
                                                ],                                             
                                                btnExcel : 'true',
                                                excelName : 'terceros_contratos_pagos',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        "T1.id",
                                                        "T.razon_social",
                                                        "C.id AS id_contrato",
                                                        "C.nombre AS nombre_contrato",
                                                        "T1.numero_factura",
                                                        "DATE_FORMAT(T1.fecha_factura,'%Y-%m-%d') AS fecha_factura", 
                                                        "T1.valor"
                                                    ],
                                                    fieldSearch : [
                                                                                                                        
                                                    ],                             
                                                    sqlJoin : [                                                         
                                                        "INNER JOIN terceros_contratos AS C ON(C.id = T1.id_contrato AND C.tipo = 'cliente')",
                                                        "INNER JOIN terceros AS T ON(T.id = C.id_tercero AND T.activo=1)",
                                                    ],
                                                    sqlWhere : [
                                                                                                                
                                                    ],                                                     
                                                    sqlOrderBy : "C.nombre ASC,T1.fecha_factura DESC",                                                  
                                                    fieldFechas : "T1.fecha_factura",
                                                    fecha_completa : "true",                                                                                                                                                                                                              
                                                },                                            
                                            },
                                            {
                                                label : 'Informe de Encuestas',
                                                reportTitle : 'Informe de Encuestas de Clientes',
                                                table : 'encuesta_terceros',  
                                                colsHeaders : ['Fecha Encuesta','Cliente','Pregunta','Usuario','Puntaje'],
                                                colsData : ['fecha','cliente','pregunta','usuario','puntaje'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'terceros_encuestas',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [                                                    
                                                        'P.razon_social AS cliente',
                                                        'ROUND(E.puntaje) AS puntaje',                                                    
                                                        "IF(E.nombre_pregunta IS NOT NULL,E.nombre_pregunta,EP.nombre) AS pregunta", 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "IF(T1.tipo='cliente',P.nombre_contacto,CONCAT(U.primer_nombre,' ',U.primer_apellido)) AS usuario",                                                                                                                                                         
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_terceros_respuestas AS E ON (T1.id = E.id_encuesta)",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',   
                                                        'INNER JOIN terceros AS P ON (P.id = T1.id_tercero)',                                                                                                      
                                                        "INNER JOIN encuesta_terceros_preguntas AS EP ON (EP.id = E.id_pregunta)",                                       
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        " AND T1.tipo='cliente'"
                                                    ],
                                                    sqlOrderBy : " P.razon_social ASC,E.puntaje DESC",
                                                    fieldFechas : "T1.fecha",                                               
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Cliente',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tercero',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre_comercial',
                                                                              idField     : 'id_tercero',
                                                                              valueField  : 'nombre_tercero',
                                                                         },
                                                                         apiField    : 'terceros',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nit',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre_comercial'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre_comercial'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                            "  AND cliente='true' AND id IN (SELECT id_tercero FROM encuesta_terceros WHERE tipo='cliente')"
                                                                                        ],                           
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre_comercial',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },
                                            {
                                                label : 'Informe de Comentarios',
                                                reportTitle : 'Informe de Comentarios de Clientes',
                                                table : 'encuesta_terceros',  
                                                colsHeaders : ['Fecha Encuesta','Cliente','Comentarios','Usuario','Promedio Encuesta'],
                                                colsData : ['fecha','cliente','comentarios','usuario','promedio'],                                                                                       
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'terceros_comentarios',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        'P.razon_social AS cliente',
                                                        'E.comentarios',                                                    
                                                        'ROUND(T1.promedio) AS promedio', 
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                     
                                                        "IF(T1.tipo='cliente',P.nombre_contacto,CONCAT(U.primer_nombre,' ',U.primer_apellido)) AS usuario",                                                                                                         
                                                    ],
                                                    sqlJoin : [
                                                        "INNER JOIN encuesta_terceros_respuestas AS E ON (T1.id = E.id_encuesta AND E.id_pregunta=0)",
                                                        'LEFT JOIN users AS U ON (U.id = T1.id_usuario)',
                                                        'INNER JOIN terceros AS P ON (P.id = T1.id_tercero)', 
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        " AND T1.tipo='cliente'"
                                                    ],                                                                                     
                                                    sqlOrderBy : " P.razon_social ASC,E.puntaje DESC",                                                
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Cliente',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tercero',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre_comercial',
                                                                              idField     : 'id_tercero',
                                                                              valueField  : 'nombre_tercero',
                                                                         },
                                                                         apiField    : 'terceros',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nit',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre_comercial'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre_comercial'                                
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            "  AND cliente='true' AND id IN (SELECT id_tercero FROM encuesta_terceros WHERE tipo='cliente')"
                                                                                        ],                            
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre_comercial',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ]
                                            },                                         
                                        ]}                        
                        />
                    : null
                }
                {
                    validarModulo(13) && validarPermiso(85) ?                        
                        <ReportOption 
                            tab={"ReportOption"+(++tabTitle)} 
                            title='Capacitaciones' 
                            funcionClick = {this.actualizarReportContainer}        
                            position={++tabLog}
                            optionWidth='260px'
                            optionMenu={[
                                            {
                                                label : 'Informe de Capacitaciones',
                                                reportTitle : 'Informe de Capacitaciones',
                                                table : 'capacitaciones',  
                                                colsHeaders : ['Nombre','Instructor','Lugar','Horas','Fecha Inicio','Fecha Final','Observaciones'],
                                                colsData : ['nombre','instructor','lugar','intensidad','fecha_inicio','fecha_final','observaciones'],                                                                                      
                                                dateFilter : 'true',                                             
                                                btnExcel : 'true',
                                                excelName : 'capacitaciones',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        "*",
                                                        "DATE_FORMAT(CONCAT(fecha_inicio,' ',hora_inicio),'%Y-%m-%d %H:%i:%s') AS fecha_inicio", 
                                                        "DATE_FORMAT(CONCAT(fecha_final,' ',hora_final),'%Y-%m-%d %H:%i:%s') AS fecha_final",                                                    
                                                    ],                                                    
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    fieldFechas : "T1.fecha_inicio",                                               
                                                    fecha_completa : "true",                                                                                                                                                                                                             
                                                },                                            
                                            },
                                            {
                                                label : 'Informe de Asistencia',
                                                reportTitle : 'Informe de Asistencia',
                                                table : 'capacitaciones_usuarios',  
                                                colsHeaders : ['Usuario','Capacitacion','Fecha Inicio','Fecha Final','Asistencia'],
                                                colsData : ['usuario','capacitacion','fecha_inicial','fecha_fin','asitio'],                                                                                      
                                                dateFilter : 'true',                                            
                                                btnExcel : 'true',
                                                excelName : 'asistentes_capacitaciones',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [
                                                        "U.nombre AS usuario",
                                                        "DATE_FORMAT(CONCAT(C.fecha_inicio,' ',C.hora_inicio),'%Y-%m-%d %H:%i:%s') AS fecha_inicial", 
                                                        "DATE_FORMAT(CONCAT(C.fecha_final,' ',C.hora_final),'%Y-%m-%d %H:%i:%s') AS fecha_fin",                                                    
                                                        "C.nombre AS capacitacion",
                                                        "IF(T1.asistencia='true','SI','NO') AS asitio",                                                        
                                                    ],
                                                    sqlJoin : [                                                        
                                                        'INNER JOIN users AS U ON (U.id = T1.id_usuario)',   
                                                        'INNER JOIN capacitaciones AS C ON (C.id = T1.id_capacitacion)',
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],
                                                    sqlWhere : [
                                                        
                                                    ],
                                                    sqlOrderBy : " C.id DESC",
                                                    fieldFechas : "C.fecha_inicio",                                               
                                                    fecha_completa : "true",                                                                    
                                                },
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Funcionario',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_usuario',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_usuario',
                                                                              valueField  : 'nombre_usuario',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ], 
                                                                                        sqlWhere : [
                                                                                            "  AND id IN (SELECT id_usuario FROM capacitaciones_usuarios WHERE activo='1')"
                                                                                        ],                           
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                    {                                                    
                                                        label   : 'Capacitacion',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_capacitacion',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_capacitacion',
                                                                              valueField  : 'nombre_capacitacion',
                                                                         },
                                                                         apiField    : 'capacitaciones',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Fecha Inicio',
                                                                                                field : 'fecha_inicio'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'nombre',
                                                                                            "DATE_FORMAT(fecha_inicio,'%Y-%m-%d') AS fecha_inicio",                                                                                                                            
                                                                                        ],                                                                                                                    
                                                                                        fieldSearch : [                                                                            
                                                                                            'nombre',                                                                                                                                                                        
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                    {
                                                        label   : 'Asistencia',
                                                        dinamic : 'false',
                                                        field   : 'T1.asistencia',
                                                        options :  [{
                                                                        id: 'true',
                                                                        nombre: 'SI',
                                                                    },
                                                                    {
                                                                        id: 'false',
                                                                        nombre: 'NO',
                                                                    }]
                                                    },
                                                ]
                                            },                                                                                     
                                        ]}                        
                        />
                    : null
                }
                {
                    validarPermiso(86) ?
                        <ReportOption 
                            tab={"ReportOption"+(++tabTitle)} 
                            title='Logs' 
                            permiso='86'
                            funcionClick = {this.actualizarReportContainer}                         
                            position={++tabLog}
                            optionWidth='240px'
                            optionMenu={[
                                            {
                                                label : 'Log de Usuarios',
                                                reportTitle : 'Log de Usuarios',
                                                table : 'log',  
                                                colsHeaders : ['Fecha','Usuario','Accion','Realizado por','Datos'],
                                                colsData : ['fecha','nombre_usuario','accion','nombre_funcionario','datos'],                                                                                      
                                                dateFilter : 'true',
                                                dataFilter : [
                                                    {                                                    
                                                        label   : 'Usuario',                                        
                                                        dinamic : 'data_select',
                                                        field   : 'id_tabla',
                                                        dataParams : { 
                                                                         fetchData : {
                                                                              fieldFetch  : 'nombre',
                                                                              idField     : 'id_tabla',
                                                                              valueField  : 'nombre_usuario',
                                                                         },
                                                                         apiField    : 'users',
                                                                         colsData    : [ 
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Documento',
                                                                                                field : 'documento'
                                                                                            },
                                                                                            {
                                                                                                type  : 'bd',
                                                                                                label : 'Nombre',
                                                                                                field : 'nombre'
                                                                                            },
                                                                                        ],
                                                                         sqlParams : {
                                                                                        sqlCols : [ 
                                                                                            'id',                                 
                                                                                            'documento',
                                                                                            'nombre'                                
                                                                                        ],
                                                                                        sqlWhere : [
                                                                                            "id IN (SELECT id_tabla FROM log WHERE tabla='users')"
                                                                                        ],                                                                                     
                                                                                        sqlInactive : 'true',                           
                                                                                        fieldSearch : [                                                                            
                                                                                            'documento',
                                                                                            'nombre',                                                                            
                                                                                        ],                                      
                                                                                     }                                                                                           
                                                                     },
                                                    },
                                                ],  
                                                btnExcel : 'true',
                                                excelName : 'usuarios_log',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [   
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                                                                
                                                        'T1.accion',
                                                        "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS nombre_usuario", 
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS nombre_funcionario", 
                                                        "REPLACE(REPLACE (T1.datos, '{.}', ','),',',', ') AS datos",                                                                                                                                                            
                                                    ],
                                                    sqlJoin : [
                                                        'INNER JOIN users AS U ON (U.id = T1.id_tabla)',
                                                        'INNER JOIN users AS U1 ON (U1.id = T1.id_usuario)',                                                                                           
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],                                                
                                                    sqlWhere : [
                                                        " AND tabla='users'"
                                                    ], 
                                                    sqlOrderBy : 'T1.fecha DESC',                                              
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                    
                                                }
                                            }, 
                                            logActivos,
                                            {
                                                label : 'Log de SLA',
                                                reportTitle : 'Log de SLA',
                                                table : 'log',  
                                                colsHeaders : ['Fecha','Area de Servicio','Accion','Realizado por','Datos'],
                                                colsData : ['fecha','nombre_area','accion','nombre_funcionario','datos'],                                                                                      
                                                dateFilter : 'true',
                                                dataFilter : [
                                                    {
                                                        label : "A. Servicio",
                                                        dinamic : 'true',
                                                        field : 'S.id_area',
                                                        sqlParams : {
                                                                        sqlCols : [
                                                                            'id',
                                                                            'nombre'                                
                                                                        ], 
                                                                        sqlWhere : [ whereArea ]                                                                                                                                                                                                                                                                                           
                                                                    },
                                                        apiField : 'areas_servicio',
                                                        valueName : 'nombre',
                                                    }
                                                ],  
                                                btnExcel : 'true',
                                                excelName : 'sla_log',
                                                ////btnPDF : 'true',
                                                sqlParams : {
                                                    sqlCols : [   
                                                        "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                                                                                                
                                                        'T1.accion',
                                                        "A.nombre AS nombre_area", 
                                                        "CONCAT(U1.primer_nombre,' ',U1.primer_apellido) AS nombre_funcionario", 
                                                        "REPLACE(REPLACE (T1.datos, '{.}', ','),',',', ') AS datos",                                                                                                                                                            
                                                    ],
                                                    sqlJoin : [
                                                        'INNER JOIN areas_sla AS S ON (S.id = T1.id_tabla)',
                                                        'INNER JOIN areas_servicio AS A ON (A.id = S.id_area)',
                                                        'INNER JOIN users AS U1 ON (U1.id = T1.id_usuario)',                                                                                           
                                                    ],
                                                    fieldSearch : [
                                                        
                                                    ],                                                
                                                    sqlWhere : [
                                                        " AND tabla='areas_sla'"
                                                    ], 
                                                    sqlOrderBy : 'T1.fecha DESC',                                              
                                                    fieldFechas : "T1.fecha",
                                                    fecha_completa : "true",                                                                    
                                                }
                                            },  
                                            logProveedores,                                     
                                            logClientes
                                        ]}
                        /> 
                    : ''
                }
                    </div>                       
                </div>	
                <div id="reportContainer" className="reportContainer" data-role="reportTbar" os="windows" >
                    <ReportContainer optionMenu={this.state.optionMenu} />
                </div>
            </div>
  	  	);
  	}
}

export default Reports
