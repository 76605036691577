/**
* CLASS TablaAdjuntosContainer
*
* Contiene el componente que muestra los archivos adjuntos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import Table from 'react-bootstrap/Table';
import TablaAdjuntosList from './TablaAdjuntosList';
import alertify from 'alertifyjs';

class TablaAdjuntosContainer extends Component {
    constructor(props) {        
        super(props);   
        this.state = {
            arrayContent : [],
            database : globalState.getState().companyData[0].bd
        };    
        this.cargarFilas();    
    }
    componentDidUpdate(prevProps){  //refrescar el listado
        if (this.props.updateTable !== prevProps.updateTable) {           
           this.cargarFilas(); 
        }       
    } 
    cargarFilas(){
        let sqlParams = {
            sqlCols : [
                'T1.id',
                "LEFT(DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s'),10) AS fecha", 
                "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS nombre_usuario",
                'T1.nombre_archivo',
                'T1.id_usuario',                            
            ],
            sqlWhere : [ ' AND T1.id_maestro = \''+this.props.params.idRow+'\'' ],  
            sqlJoin : [                
                'INNER JOIN users AS U ON (U.id = T1.id_usuario)' 
            ],                                                                             
        }
        cargarFilas(this.props.params.table+'_adjuntos','',20,0,'','',sqlParams,'rows',this.state.database).then(response => { 
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{               
                this.setState({arrayContent : response});
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })
    }
  	render() {             
  	  	return (<div id="no-more-tables">
                    <Table className="tableDataGrid" id="table-to-xls" style={{marginBottom: '0px'}}>
                         <thead className="cf">
                            <tr>
                                <th style={{fontSize:'12px'}}>No</th>
                                <th style={{fontSize:'12px'}}>Fecha</th>
                                <th style={{fontSize:'12px'}}>Usuario</th>
                                <th style={{fontSize:'12px'}}>&nbsp;</th>                               
                            </tr>
                        </thead>    
                        <TablaAdjuntosList  
                            listado={this.state.arrayContent}
                            table={this.props.params.table}
                            bloqueo={this.props.bloqueo}
                        />                                       
                    </Table>
                </div>);
  	}
}

export default TablaAdjuntosContainer