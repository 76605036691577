/**
* CLASS TablaAdjuntosRow
*
* Contiene el componente de cada fila de los archivos adjuntos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import {deleteFile,visualizarArchivoServidor} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import globalState from '../configuration/GlobalState';
import configJson from '../configuration/configuration.json';

class TablaAdjuntosRow extends Component {
    verArchivoAdjunto(file){        
        var path   = window.location.protocol+"//"+window.location.hostname+configJson.filesRoute; 
        var folder = globalState.getState().companyData[0].bd;
        visualizarArchivoServidor(path+folder+'/'+this.props.table+'_adjuntos/'+file).then(response => {            
            //Create a Blob from the Stream
            const file = new Blob([response.data],{type: response.data.type});
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
        });        
    } 
    handleDeleteButton(data){//boton eliminar
        alertify.confirm('Confirmacion', 'Esta seguro(a) de eliminar este item?', this.handleConfirmAction.bind(this,data), function(){});
    }   
    handleConfirmAction(data) { 
        var database = globalState.getState().companyData[0].bd;          
        deleteFile(data.nombre_archivo,this.props.table+'_adjuntos',data.id,database).then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error eliminando el archivo!<br />Codigo de Error: '+response.detail);
            } 
            globalState.dispatch({//refrescar el listado
                type   : "updateUploadArchivo",
                params : { 
                            fileName : response.detail  
                        }
            });
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
        }); 
    } 
  	render() { 
        let idRol = globalState.getState().idRol;            
  	  	return (
             <tr>      
                <td style={{fontSize:'10px'}}>{this.props.numberRow+1}</td> 
                <td data-title="fecha" style={{fontSize:'10px'}}>{this.props.dataRow.fecha}&nbsp;</td>
                <td data-title="usuario" style={{fontSize:'10px'}}>{this.props.dataRow.nombre_usuario}&nbsp;</td>
                <td data-title="ver" style={{fontSize:'10px',cursor:'pointer'}} onClick={this.verArchivoAdjunto.bind(this,this.props.dataRow.nombre_archivo)}><MaterialIcon id="iconColumna" size={20} icon="visibility" /></td>
                {
                    this.props.bloqueo === 'false' || idRol === 1 ? 
                        <td data-title="delete" style={{fontSize:'10px',cursor:'pointer'}} onClick={this.handleDeleteButton.bind(this,this.props.dataRow)}><MaterialIcon id="iconColumna" size={20} icon="delete" /></td>
                    : '' 
                }                
            </tr>
        )
  	}
}

export default TablaAdjuntosRow