/**
* CLASS ReportContainer
*
* Contiene el contenedor del generador del reporte
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataReportContainer from './DataReportContainer';
import MaterialIcon from 'material-icons-react';
import ReactToPdf from 'react-to-pdf';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ComboBoxFilter from '../data_grid/ComboBoxFilter';
import globalState from '../configuration/GlobalState';
import DatePicker from 'react-date-picker';
import Window from '../window/Window';
import {cargarFilas} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';

import './reports.css';

class ReportContainer extends Component {
	constructor(props, context) { 
     	super(props, context);
        let date1 = new Date();
        date1.setDate(date1.getDate() - 30);  
        this.state = {
            date1 : date1,
            date2 : new Date(),
            dateH : new Date(),
            showPDF : false,
            showXLS : false,
            dataRow : '',
            arrayFilter  : [],
            windowDataSelectId : '',
            database : globalState.getState().companyData[0].bd 
        }
        this.funcionEditDataSelect = this.funcionEditDataSelect.bind(this); 
	}    
    componentDidUpdate(prevProps){  //refrescar el contenedor cuando se cambia de informe
        if (this.props.optionMenu !== prevProps.optionMenu) {           
            this.setState({dataRow : '',arrayFilter : []});  
            this.setState({ showXLS: false });
            //reiniciar los filtros          
            if(this.props.optionMenu.dataFilter){
                let dataFilter = JSON.parse(JSON.stringify(this.props.optionMenu.dataFilter));                  
                if(Object.keys(dataFilter).length > 0){ 
                    let fieldFetch = '';           
                    for(var i in dataFilter){                        
                        if(dataFilter[i].dinamic === 'data_select'){                            
                            fieldFetch = dataFilter[i].dataParams.fetchData.valueField;                            
                            this.setState({[fieldFetch] : ''});
                        }                        
                    }            
                }
            }            
        }       
    }
    //manejadores de los datepicker
    changeDate1(val){  
        if(val <= this.state.date2){
            this.setState({ date1 : val });
        }
    }
    changeDate2(val){        
        this.setState({ date2 : val });        
    }
    handleDataFilter(field,e){  
        //actualizar el filtro      
        let arrayFilter = this.state.arrayFilter;
        let filter = {...arrayFilter[field]};    
        filter = e.target.value;   
        arrayFilter[field] = filter;   
        this.setState({arrayFilter});            
    }
    generaReport(val){//generador del reporte
        let fecha1 = this.state.date1;
        let fecha2 = this.state.date2;        

        if(fecha1 === null){
            alertify.error("Favor ingrese fecha inicial!");             
            return;
        }
        if(fecha2 === null){            
            alertify.error("Favor ingrese fecha final!");  
            return;
        }
        //formato de la fecha
        fecha1 = new Date(fecha1.getTime() - (fecha1.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
        fecha2 = new Date(fecha2.getTime() - (fecha2.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];

        let sqlParams  = JSON.parse(JSON.stringify(this.props.optionMenu.sqlParams));
        let dataFilter = this.state.arrayFilter;        
        if(Object.keys(dataFilter).length > 0){            
            for(var i in dataFilter){                
                if(dataFilter[i] !== ""){
                    sqlParams.sqlWhere.push(" AND "+i+" = '"+dataFilter[i]+"'");
                }
            }            
        }

        //generacion del reporte  
        cargarFilas(this.props.optionMenu.table,'',100000,0,fecha1,fecha2,sqlParams,'rows',this.state.database)
        .then(res => {
            var response = res.data; 
            if (response.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            } else {                
                this.setState({ dataRow: response })
                //permite visualizar los botones de excel y pdf
                this.setState({ showXLS: true })
                this.setState({ showPDF: true })
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
        });
    }
    handleDataSelect(dataParams,field){//al dar clic en el campo de texto
        dataParams['funcionEdit'] = this.funcionEditDataSelect;      
        this.setState({windowDataSelectId : "windowReportDataSelect_"+field }, () => {
            globalState.dispatch({
                type   : "windowReportDataSelect_"+field,
                params : {
                    visible : true,
                    params  : dataParams,
                    width   : '400px', 
                    height  : '300px'
                }
            })
        });        
    }
    funcionEditDataSelect(data,params){//la funcion que carga los datos del DataSelect 
        let arrayFilter = this.state.arrayFilter;
        let filter = {...arrayFilter[params.idField]};    
        filter = data.id;
        arrayFilter[params.idField] = filter;   
        this.setState({arrayFilter});
        this.setState({[params.valueField] : data[params.fieldFetch]});
        this.setState({windowDataSelectId : "windowReportDataSelect_"+params.idField }, () => {
            globalState.dispatch({
                type   : "windowReportDataSelect_"+params.idField,
                params : {
                    visible : false,                    
                }
            })
        }); 
    }
    resetSelectFilter(field,nom_field){ 
        let arrayFilter = this.state.arrayFilter;       
        delete arrayFilter[field];          
        this.setState({arrayFilter},()=>{            
            this.setState({[nom_field] :''});             
        });
    }     		
  	render() {//cargar los controles dinamicos del generador del reporte
        let classEmpty = 'ReportContainer';
        if(this.state.dataRow.length === 0){
            classEmpty = 'ReportContainer ReportContainerEmpty';
        }
        const divPDF = React.createRef();     
        let field = '';  
        let cuenta = 1; 
        let field1 = '';
  	  	return ( 
            <div id="BodyReportContainer" style={{height: '100%'}}>
            {                
                this.props.optionMenu.label ? 
                    <div  style={{height: '100%'}}>
                         <div id="tbarReportContainer" className="tbarReportContainer">
                             <div id="PanelScrollY_ReportContainer" style={{width: '100%',display:'flex',paddingBottom:'275px',marginBottom:'-275px',overflowY:'auto'}}>
                                 {
                                    this.props.optionMenu.dateFilter === 'true' ?
                                        <div id="panelFieldReport" className="panelFieldReport" style={{width: '210px'}}>
                                            <div id="form_content_field_PanelCampos_Reports_Infotipri_desde" materialdesign="false" className="formContentField" style={{width:'200px', minHeight:'30px', display:'block'}}>
                                                <div id="form_label_PanelCampos_Reports_Infotipri_desde" className="formLabelReport" style={{width:'60px', paddingTop: '8px'}}>Fecha inicio</div>
                                                <div id="form_field_PanelCampos_Reports_Infotipri_desde" className="formDateReport" style={{width:'auto',left:'70px'}}>                                                    
                                                    <DatePicker onChange={ this.changeDate1.bind(this) } format={"y-MM-dd"} value={this.state.date1} style={{ position : 'absolute' }} clearIcon={null} maxDate={this.state.date2}/>
                                                 </div>
                                            </div>
                                            <div data-role="div-empty"></div>
                                            <div id="form_content_field_PanelCampos_Reports_Infotipri_hasta" materialdesign="false" className="formContentField" style={{width:'200px',minHeight:'30px', display:'block'}}>
                                                <div id="form_label_PanelCampos_Reports_Infotipri_hasta" className="formLabelReport" style={{width:'60px', paddingTop: '8px'}}>Fecha fin</div>
                                                <div id="form_field_PanelCampos_Reports_Infotipri_hasta" className="formDateReport" style={{width:'auto',left:'70px'}}>                                                    
                                                    <DatePicker onChange={ this.changeDate2.bind(this) } format={"y-MM-dd"} value={this.state.date2} style={{ position : 'absolute' }} clearIcon={null} maxDate={this.state.dateH} minDate={this.state.date1}/>
                                                </div>                          
                                            </div>
                                            <div data-role="div-empty"></div>
                                        </div>
                                    : ''
                                 } 
                                 {
                                    this.props.optionMenu.dataFilter !== undefined ?
                                        <>
                                        {
                                            this.props.optionMenu.dataFilter.map((listado,i) => {                                                 
                                                field = <>{field}
                                                            <div key={i} id="form_content_field_PanelCampos_Reports_Infotipri_desde" materialdesign="false" className="formContentField" style={{width:'200px', minHeight:'30px', display:'block'}}>                                                        
                                                                <div id="form_label_PanelCampos_Reports_Infotipri_desde" className="formLabelReport" style={{width:'auto', paddingTop: '8px'}}>{listado.label}:&nbsp;</div> 
                                                                <div id="form_field_PanelCampos_Reports_Infotipri_desde" className="formDateReport" style={{width:'auto',left:'80px'}}>
                                                                    {
                                                                        listado.dinamic === 'data_select' ?
                                                                            <div style={{position:'relative'}}>
                                                                                <input type="text"  style={{border:'thin solid gray',width:'120px',height:'29px'}} onClick={this.handleDataSelect.bind(this,listado.dataParams,listado.field)} value={this.state[listado.dataParams.fetchData.valueField] || 'Seleccione...'} readOnly/> 
                                                                                <div style={{float:'left',position:'absolute',cursor:'pointer',right:'0px',top:'2px'}} onClick={this.resetSelectFilter.bind(this,listado.field,listado.dataParams.fetchData.valueField)} title="Eliminar seleccion">    
                                                                                    <MaterialIcon id="iconColumna" color="red" size={20} icon="remove_circle"/>
                                                                                </div>
                                                                            </div>
                                                                        : <ComboBoxFilter style={{border:'thin solid gray',width:'120px',height:'29px'}} listado={listado} functionChange={this.handleDataFilter.bind(this,listado.field)} />
                                                                    }                                                                
                                                                </div> 
                                                            </div>                                              
                                                        </> 
                                                if(cuenta%2===0){
                                                    cuenta++;
                                                    field1 = field;
                                                    field = '';                                                    
                                                    return <div key={i} id="panelFieldReport" className="panelFieldReport" style={{width: '210px'}}>
                                                                {field1}
                                                           </div>
                                                } 
                                                else{
                                                    cuenta++;                                                                                
                                                } 
                                                return null;                                                                                                             
                                            }) 
                                        }                                        
                                        {
                                            (cuenta%2===0)? 
                                                 <div id="panelFieldReport" className="panelFieldReport" style={{width: '210px'}}>
                                                    {field}
                                                </div>
                                            : ''
                                        }
                                        </>                                                                                                           
                                    : ''
                                }                                   
                                 <div id="PanelBotones_Reports_Infotipri" className="PanelBotonesReport">
                                     <div id="reportButtonHTML" className="reportBtn btnReportContainer" style={{width:'65px',backgroundColor: '#FFF !important'}} data-role="reportButton" data-state="enable"> 
                                         <div style={{textAlign:'center'}}>
                                             <MaterialIcon size={24} icon="flash_on" />
                                         </div>                              
                                         <button className="save" os="windows" onClick={ this.generaReport.bind(this) }>Generar Informe</button>
                                     </div>
                                     <div data-role="div-empty"></div>
                                     {
                                        this.props.optionMenu.btnExcel === 'true' && this.state.showXLS === true ?
                                            <div id="reportButtonXLS" className="reportBtn btnReportContainer" style={{width:'65px',backgroundColor: '#FFF !important'}} data-role="reportButton" data-state="enable">                                 
                                                <div style={{textAlign:'center'}}>
                                                    <MaterialIcon size={24} icon="library_books" />
                                                </div>
                                                <ReactHTMLTableToExcel
                                                    id="test-table-xls-button"
                                                    className="download-table-xls-button"
                                                    table="table-to-xls"
                                                    filename={"informe_"+this.props.optionMenu.excelName}
                                                    sheet="tablexls"
                                                    buttonText="Generar Excel"/>                                                
                                            </div>
                                        : ''
                                     }
                                     <div data-role="div-empty"></div>
                                     {
                                        this.props.optionMenu.btnPDF === 'true' && this.state.showPDF === true ?
                                            <div id="reportButtonPDF" className="reportBtn btnReportContainer" style={{width:'65px',backgroundColor: '#FFF !important'}} data-role="reportButton" data-state="enable">                                 
                                                <div style={{textAlign:'center'}}>
                                                    <MaterialIcon size={24} icon="picture_as_pdf" />
                                                </div>
                                                <ReactToPdf targetRef={divPDF} filename={"informe_"+this.props.optionMenu.excelName+".pdf"}>
                                                    {
                                                        ({toPdf}) => (
                                                            <button onClick={toPdf} className="save" os="windows">Generar PDF</button>
                                                        )
                                                    }
                                                </ReactToPdf>
                                            </div>
                                        : ''
                                     }
                                     <div data-role="div-empty"></div>
                                 </div>
                             </div>
                         </div>
                         <div id="PanelInforme_Reports_Infotipri" className="PanelReport" style={{height: 'calc(100% - 104px)'}}>
                             <div id="InformeFile_Reports_Infotipri" className={classEmpty} tamano="letter" orientacion="V">
                                 <DataReportContainer title={this.props.optionMenu.reportTitle} divPDF = {divPDF} dataRow={this.state.dataRow} colsHeaders = {this.props.optionMenu.colsHeaders} colsData = {this.props.optionMenu.colsData} />
                             </div>
                         </div>
                         <Window   //ventana para el data select
                            id = {this.state.windowDataSelectId}                    
                            title='Seleccione ...'                                                     
                            tbar="false"
                            componente="DataGridSelect"
                            params="" 
                         /> 
                     </div>
                : ''                                                            
            }
            </div>
        );
  	}
}           

export default ReportContainer