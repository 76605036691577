/**
* CLASS WindowReactivarUsuario
*
* Contiene el componente que lista los usuarios eliminados para ser reactivados
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,insertarActualizarFila,enviarMailActivacion} from '../api_calls/ApiCalls';
import {modalLoading} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import Table from 'react-bootstrap/Table';
import MaterialIcon from 'material-icons-react';
import alertify from 'alertifyjs';
import '../data_grid/dataGrid.css'; 

class WindowReactivarUsuario extends Component {
    constructor(props) {        
        super(props);   
        this.state = {           
            listadoUsuarios : [],
            updateListado   : 0,
            database : globalState.getState().companyData[0].bd
        };
    }
    componentWillMount(){       
        this.cargarUsuarios();   
    }   
    componentDidUpdate(prevProps,prevState){
        if (this.state.updateListado !== prevState.updateListado) {           
           this.cargarUsuarios();            
        }       
    }     
    cargarUsuarios(){        
        let sqlParams = ''; 
        if(this.props.params.tabla === 'users'){
            sqlParams = { 
                            sqlCols : [
                                "T1.id",
                                "T1.nombre",
                                "RL.nombre AS rol",                                             
                            ],
                            sqlJoin : [                                
                                'INNER JOIN roles AS RL ON (RL.id = T1.id_rol)' 
                            ],    
                            sqlInactive : 'true',                                                                          
                            sqlWhere : [ " T1.activo = 0" ],                                
                        }
        }
        else{
            sqlParams = { 
                            sqlCols : [
                                "id",
                                "documento",
                                "nombre_comercial",                                             
                            ],                                
                            sqlInactive : 'true',                                                                          
                            sqlWhere : [ " activo = 0 " ],                                
                        }
        }       
        
        cargarFilas(this.props.params.tabla,'',1000,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{                
                this.setState({listadoUsuarios : response});          
            }     
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })
    }   
    handleConfirmReactivar(id){
        let title = 'usuario';
        if(this.props.params.tabla === 'terceros'){
            title = 'tercero';
            alertify.confirm('Confirmacion', 'Esta seguro(a) de reactivar el '+title+' seleccionado?', this.handleReactivarUsuario.bind(this,id), function(){});
        }
        else{
            let maxUsers  = globalState.getState().companyData[0].maximo_usuarios;
            let sqlParams = {
                                sqlCols : [
                                    'COUNT(id) AS total',
                                    'activo',                                                                                                                                       
                                ],                                 
                                sqlWhere : [
                                    " AND id_rol > 1"//no cuenta superusuarios
                                ],
                                sqlGroupBy : "activo",
                                sqlOrderBy : "activo",                                                                                                                                                         
                            } 
            cargarFilas('users','',1,0,'','',sqlParams,'rows',this.state.database)
            .then(res => {
                res = res.data;
                if (res.msg === "error") {
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
                } 
                else{
                    if(res[0].total >= maxUsers){
                        alertify.alert('Error!', 'No se puede reactivar el usuario,el maximo de usuarios activos es '+maxUsers+'!');
                    }
                    else{
                        alertify.confirm('Confirmacion', 'Esta seguro(a) de reactivar el '+title+' seleccionado?', this.handleReactivarUsuario.bind(this,id), function(){});
                    }                
                }
            })
            .catch( err => {            
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
            });
        }
    }
    handleReactivarUsuario(id){
        let objectData = {
            id : id,
            activo : 1,
        }
        let dataLog = {
            activo : "Activo",
        }                                      
        if(this.props.params.tabla === 'users'){
            objectData = {
                id : id,
                activo : 1,
                acceso_sistema : 'false'
            } 
            dataLog = {
                activo : "Activo",
                acceso_sistema : "Acceso al Sistema",
            }            
        }
        modalLoading(true);         
        insertarActualizarFila('put',this.props.params.tabla,objectData,dataLog,undefined,this.state.database).then(response => {         
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                modalLoading(false);
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{
                if(this.props.params.tabla === 'users'){                
                    this.enviarMailActivacion(id); 
                }
                else{
                    modalLoading(false);
                    alertify.alert('Aviso!', 'Se ha actualizado la informacion con exito!');
                    this.setState({updateListado : this.state.updateListado+1}); 
                    this.props.params.funcionClick('WelcomePage');
                    this.props.params.funcionClick(this.props.params.opcion);
                }               
            } 
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })
    }  
    enviarMailActivacion(id){
        enviarMailActivacion(id,this.state.database)
        .then(response => {   
            response = response.data;         
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                modalLoading(false);
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else if(response.msg === 'mail_error'){                
                modalLoading(false);
                alertify.alert('Error!', 'Ha ocurrido un error enviando el correo, verifique la conexion a internet y configuracion SMTP en la aplicacion!<br /><br />Detalles del Error: '+JSON.stringify(response.detail, null, 4));
            }
            else{  
                modalLoading(false);                            
                let sqlParams = { 
                                    sqlCols : [
                                        "tiempo_token",                                                                            
                                    ],                           
                                }
                cargarFilas('politicas_seguridad','',1,0,'','',sqlParams,'rows',this.state.database)
                .then(response => {             
                    response = response.data;
                    if(response.msg === 'error'){
                        alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                    } 
                    else{
                        var titulo = ' 1 dia';
                        if(response.length > 0){
                            titulo = response[0].tiempo_token+' minutos';
                        }
                        alertify.alert('Aviso!', 'Se ha enviado un correo electronico al usuario para la activacion!<br />Vigencia del Correo: '+titulo);  
                    }
                })
                .catch(function (error) {
                    alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
                });                
            }
            this.setState({updateListado : this.state.updateListado+1});
            this.props.params.funcionClick('WelcomePage');
            this.props.params.funcionClick(this.props.params.opcion);
        })
        .catch(function (error) {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    }    
    render() {             
        return (
                <div id="no-more-tables">
                    <Table className="tableDataGrid" responsive style={{marginBottom: '0px'}}>
                         <thead className="cf">
                            {
                                this.props.params.tabla === 'users' ?
                                    <tr>
                                        <th style={{fontSize:'12px'}}>No</th>
                                        <th style={{fontSize:'12px'}}>Nombre</th>
                                        <th style={{fontSize:'12px'}}>Rol</th>                                                                  
                                        <th style={{fontSize:'12px'}}>&nbsp;</th>                                   
                                    </tr>
                                :   <tr>
                                        <th style={{fontSize:'12px'}}>No</th>
                                        <th style={{fontSize:'12px'}}>Documento</th>
                                        <th style={{fontSize:'12px'}}>Nombre</th>                                                                  
                                        <th style={{fontSize:'12px'}}>&nbsp;</th>                                   
                                    </tr>
                            }                            
                        </thead>    
                        <tbody>
                        {
                            this.state.listadoUsuarios.map((listado,i) => {
                                if(this.props.params.tabla === 'users'){
                                    return <tr key={ i } >
                                                <td style={{fontSize:'10px'}}>{i+1}</td> 
                                                <td data-title="Categoria" style={{fontSize:'10px'}}>{listado.nombre}&nbsp;</td>
                                                <td data-title="Subcategoria" style={{fontSize:'10px'}}>{listado.rol}&nbsp;</td>                                            
                                                <td data-title="" style={{cursor:'pointer'}} onClick={this.handleConfirmReactivar.bind(this,listado.id)}><MaterialIcon id="iconColumna" size={20} icon="done" /></td>                                                               
                                           </tr>
                                }
                                else{
                                    return <tr key={ i } >
                                            <td style={{fontSize:'10px'}}>{i+1}</td> 
                                            <td data-title="Categoria" style={{fontSize:'10px'}}>{listado.documento}&nbsp;</td>
                                            <td data-title="Subcategoria" style={{fontSize:'10px'}}>{listado.nombre_comercial}&nbsp;</td>                                            
                                            <td data-title="" style={{cursor:'pointer'}} onClick={this.handleConfirmReactivar.bind(this,listado.id)}><MaterialIcon id="iconColumna" size={20} icon="done" /></td>                                                               
                                       </tr>
                                }
                                
                            })
                        }
                        </tbody>                                      
                    </Table>
                </div>                  
              );
    }
}

export default WindowReactivarUsuario