/**
/**
* CLASS TercerosContratosPagos
*
* Contiene el contenedor principal de los pagos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import TBar from '../tbar/TBar';
import Window from '../window/Window';
import {cargarFilas} from '../api_calls/ApiCalls';
import {number_format} from '../configuration/GlobalFunctions';
//import {validarPermiso} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';

class TercerosContratosPagos extends Component { 
    constructor(props){
        super(props);
        this.state={
            valorContrato : 0,
            totalPagado   : 0,
            botonNuevo    : 'true',
            saldoRestante : 0,
            database : globalState.getState().companyData[0].bd
        }
    } 
    retrocederPanel(){
        this.props.funcionClick('TercerosContratos');
    }
    componentWillMount(){
        let sqlParams = {
                            sqlCols : [
                                'monto+(monto*(iva/100)) AS monto',
                                'M.simbolo AS simbolo'                                                                                                                                       
                            ],
                            sqlJoin : [
                                " INNER JOIN monedas AS M ON (M.id = T1.id_moneda)"
                            ],
                            sqlWhere : [
                                " AND T1.id="+globalState.getState().idContrato.idRow
                            ]                                                                                                                                                          
                        } 
        cargarFilas('terceros_contratos','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
            } 
            else{
                this.setState({valorContrato : res[0].monto,simbolo : res[0].simbolo},()=>{
                    this.obtenerTotal();
                });                               
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    obtenerTotal(){
        let sqlParams = {
                            sqlCols : [
                                'SUM(valor) AS total',                                
                                'activo'                                                                                                                                       
                            ],
                            sqlWhere : [
                                " AND id_contrato="+globalState.getState().idContrato.idRow
                            ],
                            sqlGroupBy : "activo",
                            sqlOrderBy : "activo"                                                                                                                                             
                        } 
        cargarFilas('terceros_contratos_pagos','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
            } 
            else{
                if(res.length > 0){
                    // En el caso de tener pagos
                    let saldoRestante = this.state.valorContrato-res[0].total;
                    if(saldoRestante < 0){//SIN SALDO RESTANTE
                        saldoRestante = 0;
                    }                
                    this.setState({totalPagado : res[0].total,saldoRestante : saldoRestante});
                    if(saldoRestante === 0){
                        this.setState({botonNuevo : 'false'});
                    }             
                }
                else{
                    // Si aun no hay pagos el saldo es el total
                    this.setState({saldoRestante : this.state.valorContrato});
                }                                  
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    colFuncion(idRow){        
        globalState.dispatch({
                type   : "windowArchivosAdjuntosPagos",
                params : {
                              visible : true,
                              params  : {
                                            idRow  : idRow,
                                            table  : 'terceros_contratos_pagos',
                                            idWin  : "windowArchivosAdjuntosPagos",
                                            width  : '300px', 
                                            height : '250px'//identificador de la ventana
                                        }
                         }
            }); 
    }     
    render() {          
        let sqlParams = {                            
                            sqlCols : [                                
                                "T1.id",
                                "T1.valor",
                                "T1.numero_factura",
                                "CONCAT(M.simbolo,CAST(FORMAT(T1.valor,0) AS char)) AS nom_valor",
                                "DATE_FORMAT(T1.fecha_factura,'%Y-%m-%d') AS fecha_factura",
                                "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",  
                            ],
                            fieldSearch : [
                                "numero_factura",                                                                
                            ], 
                            sqlJoin : [
                                " INNER JOIN users AS U ON (U.id = T1.id_usuario)",
                                " INNER JOIN terceros_contratos AS PC ON (PC.id = T1.id_contrato)",
                                " INNER JOIN monedas AS M ON (M.id = PC.id_moneda)"
                            ],                      
                            sqlWhere : [" AND T1.id_contrato="+globalState.getState().idContrato.idRow ],
                            sqlOrderBy : "T1.fecha_factura DESC",                                                     
                            filtroFechas : "false",                                                 
                        };                 
        return (//carga el componente que contiene la grilla de datos                            
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>           
                    <DataGrid titulo={'Pagos Contrato # '+globalState.getState().idContrato.idRow+' '+globalState.getState().idContrato.tercero} 
                              funcionClick={this.props.funcionClick} 
                              parametro={this.props.parametro}
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Factura',
                                                field : 'numero_factura'
                                            },                                        
                                            {
                                                type  : 'bd',
                                                label : 'Fecha',
                                                field : 'fecha_factura'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Valor',
                                                field : 'nom_valor'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Usuario',
                                                field : 'usuario'
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'attach_file',
                                                colFuncion : this.colFuncion.bind(this)
                                            }
                                        ]} 
                              formFields ={[                                                   
                                                {
                                                    label : 'Numero de Factura *',
                                                    field : 'numero_factura',
                                                    type  : 'text',
                                                    validation : 'texto_mayusculas',
                                                    required : 'true',
                                                    longitud : 20,
                                                    tooltip : 'Ingrese el numero de Factura'                                        
                                                },
                                                {
                                                    label : 'Fecha de Factura *',
                                                    field : 'fecha_factura',
                                                    type  : 'date',
                                                    validation : '',
                                                    required : 'true',
                                                    tooltip : 'Ingrese la fecha de la Factura'                                        
                                                },
                                                {
                                                    label : 'Valor *',
                                                    field : 'valor',
                                                    type  : 'text',
                                                    validation : 'entero',
                                                    required : 'true',
                                                    tooltip : 'Ingrese el valor de la factura'  
                                                },
                                                {
                                                    label  : 'Factura',                                                    
                                                    field  : 'nombre_archivo',                                                                                                        
                                                    type   : 'multi_file',
                                                    accept : ".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png",
                                                    allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],
                                                    validation : 'file',
                                                    required : '',
                                                    tooltip : 'Seleccione la factura a adjuntar en el pago'
                                                },
                                                {
                                                    label : '',
                                                    field : 'id_usuario',
                                                    type  : 'hidden',
                                                    validation : '',
                                                    value : globalState.getState().userData[0].id,
                                                    required : 'true'                                        
                                                }, 
                                                {
                                                    label : '',
                                                    field : 'id_contrato',
                                                    type  : 'hidden',
                                                    validation : '',
                                                    value : globalState.getState().idContrato.idRow,
                                                    required : 'true'                                        
                                                },  
                                            ]}         
                              sqlParams = { sqlParams }
                              automatica="true"                          
                              botonNuevo={this.state.botonNuevo}
                              permisoInsertUpdate="68"
                              permisoBtnUpdate="68"
                              permisoDelete="68"                                                                      
                              apiField={'terceros_contratos_pagos'}
                              mainContainer='TercerosContratosPagos'/> 
                </div>
                <div style={{top:'7px',position:'relative',fontSize: '12px',fontWeight: 'bold',float: 'left',textAlign:'right',width: '100%'}}>
                    <div style={{float:'right',marginRight:'15px'}}>
                        <div style={{fontSize: '12px',float:'left',width:'120px'}}>Valor Contrato:</div>
                        <div style={{fontSize: '12px',float:'left',textAlign:'right',width:'75px',marginRight:'10px'}}>{number_format(this.state.valorContrato,this.state.simbolo)}</div>
                        <div style={{fontSize: '12px',float:'left',width:'100px'}}>Total Pagado:</div>
                        <div style={{fontSize: '12px',float:'left',textAlign:'right',width:'75px',marginRight:'10px'}}>{number_format(this.state.totalPagado,this.state.simbolo)}</div>
                        <div style={{fontSize: '12px',float:'left',width:'120px'}}>Saldo Restante:</div>
                        <div style={{fontSize: '12px',float:'left',textAlign:'right',width:'75px'}}>{number_format(this.state.saldoRestante,this.state.simbolo)}</div>
                    </div>
                </div>
                <Window 
                    id = "windowArchivosAdjuntosPagos"                     
                    title='Ver Adjuntos'                   
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                />
            </div>                        
        )
    } 
}

export default TercerosContratosPagos