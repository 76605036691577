/**
* CLASS TablaAdjuntosList
*
* Contiene el componente que lista las filas de archivos adjuntos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import TablaAdjuntosRow from './TablaAdjuntosRow'

class TablaAdjuntosList extends Component {   
  	render() {             
  	  	return (
            <tbody>
            {
                this.props.listado.map((listado,i) => {
                    return <TablaAdjuntosRow key={ i }
                                        numberRow = {i}
                                        dataRow = {listado} 
                                        table={this.props.table} 
                                        bloqueo={this.props.bloqueo}
                           />
                })
            }
            </tbody>
        )
  	}
}

export default TablaAdjuntosList