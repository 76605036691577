/**
* CLASS Option
*
* Contiene el contenedor de las opciones de panel de control
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import {divMouseOver,divMouseOut,validarPermiso} from '../configuration/GlobalFunctions';
import './controlpanel.css';

class Option extends Component {
    constructor(props){
        super(props);
        var permiso = this.props.permiso;
        this.state={ enabled : validarPermiso(permiso) };
    }     
    handleContainerChange(){//Un callback para cargar el datagrid        
        this.props.funcionClick(this.props.componente);        
    }
    render() {
        return (
            <div>
            {
                this.state.enabled === true ? 
                    <li id={this.props.tab} onClick={this.handleContainerChange.bind(this)} onMouseOut={divMouseOut.bind(this,this.props.tab,'#FFFFFF')} onMouseOver={divMouseOver.bind(this,this.props.tab,'#c6c6c6')} >
                        <div className="icono"><MaterialIcon size={50} icon={this.props.icono} /></div>
                        <div className="data">{this.props.titulo}</div>
                    </li> 
                : ''
            }
            </div>                  
        );
    }
}

export default Option
