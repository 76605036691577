/**
* CLASS WindowProcesoGestion
*
* Contiene el componente que gestiona el proceso de atencion por parte del personal tecnico 
* a incidencias, problemas y servicios.
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import globalState from '../configuration/GlobalState';
import { diccionario } from '../configuration/configuration.json';
import {cargarFilas,procesoGestion} from '../api_calls/ApiCalls';
import {validarPermiso,validarModulo,modalLoading} from '../configuration/GlobalFunctions';
import alertify from 'alertifyjs';
import TBar from '../tbar/TBar';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

const { LabelsWindowProcesoGestion } = diccionario;

class WindowProcesoGestion extends Component {
    constructor(props) {        
        super(props);   
        this.state = {   
            idioma : globalState.getState().idioma,         
            nomEstadoActual : '', 
            btnChangeArea : false,
            textareaGestion : '',   
            textareaCausaGestion : '',         
            selectSiguienteGestion : '',
            nameEstadoSiguiente : '',
            arrayEstadosGestion : [],
            arrayAreas         : [],
            arrayCategorias    : [],
            arraySubcategorias : [],            
            arrayTecnicos : [],
            idAsignado : 0,
            nomAsignado : '',
            tipo           : this.props.params.tipo,
            componente     : this.props.params.componente,
            idArea         : '',
            idCategoria    : '',
            idSubcategoria : '',
            selectGestionCambio  : '',
            database : globalState.getState().companyData[0].bd
        };        
        //validar permiso para anular
        let idUser  = globalState.getState().userData[0].id;
        let idRol   = globalState.getState().idRol;
        let permiso = 0; 
        let permiso_manual = 0;
        if(this.props.params.idWin === 'windowGestionIncidencia'){
            permiso = 4;
            permiso_manual = 40;
        } 
        else if(this.props.params.idWin === 'windowGestionProblema'){
            permiso = 26;  
            permiso_manual = 41;                  
        }  
        else if(this.props.params.idWin === 'windowGestionServicio'){
            permiso = 9; 
            permiso_manual = 42;
        }              
        var arrayEstadosGestion = [];
        let sqlParams = { 
                            sqlCols : [
                                "T1.id_tecnico_"+this.state.tipo+" AS id_tecnico",
                                "T1.estado",
                                "SE.nombre AS nom_estado",
                                "T1.estado+1 AS estado1",
                                "SE1.nombre AS nom_estado1",
                                "T1.id_area",
                                "T1.gestion_cambio"              
                            ], 
                            sqlJoin : [
                                "INNER JOIN solicitudes_estado AS SE ON (SE.valor = T1.estado)",
                                "LEFT JOIN solicitudes_estado AS SE1 ON (SE1.valor = T1.estado+1 AND T1.estado < 5)"  
                            ],                      
                            sqlWhere : [ " AND T1.id = "+this.props.params.idRow ],                                
                        }
        cargarFilas(this.props.params.table,'',1,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!',LabelsWindowProcesoGestion.error_alert1[this.state.idioma]+': '+response.detail);
            }
            else{                
                response = response[0];

                if(!validarPermiso(permiso_manual) && idUser !== response.id_tecnico && idRol > 1){
                    alertify.alert(LabelsWindowProcesoGestion.aviso[this.state.idioma], LabelsWindowProcesoGestion.error_alert2[this.state.idioma]);
                    globalState.dispatch({
                        type   : this.props.params.idWin,
                        params : {
                                      visible : false,                              
                                 }
                    });
                    return;
                }
                //cargar el combo del estado siguiente 
                let estadoActual = response.estado; 
                let nomEstadoActual = response.nom_estado;              
                let estadoSiguiente = response.estado1; 
                let nomEstadoSiguiente = response.nom_estado1; 
                
                this.setState({nomEstadoActual:response.nom_estado,nameEstadoSiguiente:nomEstadoActual,estadoActual:estadoActual});   
                if(!validarModulo(10)){                    
                    this.setState({selectGestionCambio:'false'});
                }
                else{
                    this.setState({selectGestionCambio:response.gestion_cambio});           
                }
                
                //arrayEstadosGestion = [{ estado : estadoActual, nom_estado :  nomEstadoActual}];    
                if(estadoActual < 3){//sin asignar
                    let sqlParams = {
                                        sqlCols : [
                                            'id',
                                            'nombre'                                
                                        ],
                                        sqlWhere : [ " AND atencion_"+this.state.tipo+"s='true'" ],                                                                                                               
                                    };                                                                
                    cargarFilas('areas_servicio','',100,0,'','',sqlParams,'rows',this.state.database)
                    .then(response => {
                        response = response.data;
                        if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                            alertify.alert('Error!', LabelsWindowProcesoGestion.error_alert3[this.state.idioma]+': '+response.detail);
                        }
                        else{
                            this.setState({arrayAreas : response});                            
                        }
                    })
                    .catch(function (error) {
                        alertify.alert('Error!', LabelsWindowProcesoGestion.error_alert3[this.state.idioma]+': '+error);
                    });
                    arrayEstadosGestion = [{ estado : estadoActual, nom_estado :  nomEstadoActual}];
                    arrayEstadosGestion.push({ estado : estadoSiguiente, nom_estado :  nomEstadoSiguiente});                             
                    if(estadoActual === 1){                   
                        this.setState({selectSiguienteGestion:'2',nameEstadoSiguiente:LabelsWindowProcesoGestion.en_proceso[this.state.idioma]});
                    }   
                    else{
                        this.setState({selectSiguienteGestion:estadoSiguiente,nameEstadoSiguiente:nomEstadoSiguiente});
                    }                         
                    if(validarPermiso(permiso) === true){ //tiene el permiso                                                       
                        arrayEstadosGestion.push({ estado : 6, nom_estado :  LabelsWindowProcesoGestion.anulado[this.state.idioma]});
                    }                            
                }
                else if(estadoActual < 4){
                    arrayEstadosGestion = [{ estado : estadoActual, nom_estado :  nomEstadoActual}];
                    this.setState({selectSiguienteGestion:estadoActual,nameEstadoSiguiente:nomEstadoActual});
                } 
                else if(estadoActual === 4){
                    arrayEstadosGestion = [{ estado : 2, nom_estado : LabelsWindowProcesoGestion.en_proceso[this.state.idioma]}]; 
                    this.setState({selectSiguienteGestion:'2', nameEstadoSiguiente : LabelsWindowProcesoGestion.en_proceso[this.state.idioma]});                           
                }
                else{
                    alertify.alert(LabelsWindowProcesoGestion.aviso[this.state.idioma], LabelsWindowProcesoGestion.aviso_alert1[this.state.idioma]+':'+nomEstadoActual);
                    globalState.dispatch({
                        type   : this.props.params.idWin,
                        params : {
                                      visible : false,                              
                                 }
                    });
                    return;
                }             
                this.setState({arrayEstadosGestion:arrayEstadosGestion});
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowProcesoGestion.error_alert1[this.state.idioma]+': '+error);
        })
    }          
    obsProcesoGestion(e){
        let value=e.target.value;
        value = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\n,:.@\-+¡!?¿ ]/g,'');         
        this.setState({textareaGestion:value});
    }
    obsCausaGestion(e){
        let value=e.target.value;
        value = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\n,:.@\-+¡!?¿ ]/g,'');         
        this.setState({textareaCausaGestion:value});
    }
    handleChangeEstado(e){        
        let value=e.target.value;
        let name = e.target.options[e.target.selectedIndex].text;
        this.setState({selectSiguienteGestion:value,nameEstadoSiguiente:name});  
        //en asignado se debe de esconder el checkbox            
        if(value !== '2'){
            this.setState({
                idAsignado     : 0,
                nomAsignado    : '',
                idArea         : '',
                idCategoria    : '',
                idSubcategoria : '',
                btnChangeArea  : false 
            });
        }         
    } 
    handleChangeTecnico(e){
        let value=e.target.value;
        this.setState({idAsignado:value}); 
        let nombre = e.target.options[e.target.selectedIndex].text;  
        this.setState({nomAsignado:nombre});            
    }
    handleGestionCambio(e){
        let value=e.target.value;
        this.setState({selectGestionCambio:value});         
    }
    handleChangeArea(e){
        let idArea = e.target.value;
        let sqlParams = {
                            sqlCols : [
                                'id',
                                'nombre'                                
                            ],
                            sqlWhere : [ " AND id_area='"+idArea+"' AND atencion_"+this.state.tipo+"s='true'" ],                                                                          
                        };                                                                
        cargarFilas('areas_servicio_categorias','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowProcesoGestion.error_alert3_1[this.state.idioma]+': '+response.detail);
            }
            else{
                this.setState({idArea : idArea});
                this.setState({arrayCategorias : response});
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowProcesoGestion.error_alert3_1[this.state.idioma]+': '+error);
        })
        //cargar el listado de usuarios
        let idUser  = globalState.getState().userData[0].id;
        let permiso = '';
        if(this.props.params.idWin === 'windowGestionIncidencia'){
            permiso = 37; 
        } 
        else if(this.props.params.idWin === 'windowGestionProblema'){
            permiso = 38;         
        }  
        else if(this.props.params.idWin === 'windowGestionServicio'){
            permiso = 39; 
        }                                                   
        sqlParams = { 
                        sqlCols : [
                            "id",
                            "nombre"              
                        ],                                               
                        sqlWhere :  [ 
                                        " AND id <> "+idUser+" AND id_area='"+idArea+"'",
                                        " AND id_rol IN (SELECT id_rol FROM roles_permisos WHERE id_permiso = "+permiso+")"
                                    ],                                
                    }
        cargarFilas('users','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {                                
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowProcesoGestion.error_alert4[this.state.idioma]+': '+response.detail);
            }
            else{  
                this.setState({'arrayTecnicos':response}); 
            }
        }) 
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowProcesoGestion.error_alert4[this.state.idioma]+': '+error);
        })
    }
    handleChangeCategoria(e){
        let idCategoria = e.target.value;
        let sqlParams = {
                            sqlCols : [
                                'id',
                                'nombre'                                
                            ],
                            sqlWhere : [ " AND id_categoria='"+idCategoria+"'" ],                                                                                                        
                        };                                                                
        cargarFilas('areas_servicio_subcategorias','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowProcesoGestion.error_alert5[this.state.idioma]+': '+response.detail);
            }
            else{
                this.setState({idCategoria : idCategoria});
                this.setState({arraySubcategorias : response});
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowProcesoGestion.error_alert5[this.state.idioma]+': '+error);
        })
    }
    handleChangeSubcategoria(e){
        let idSubcategoria = e.target.value;
        this.setState({idSubcategoria : idSubcategoria});
    }
    handleBtnGestion(e){
        var estadoActual        = this.state.estadoActual;
        var estadoSiguiente     = this.state.selectSiguienteGestion;        
        var observacion         = this.state.textareaGestion;  
        var nameEstadoSiguiente = this.state.nameEstadoSiguiente;
        var idArea              = this.state.idArea;      
        var idCategoria         = this.state.idCategoria;      
        var idSubcategoria      = this.state.idSubcategoria; 
        var idAsignado          = this.state.idAsignado;
        var gestionCambio       = this.state.selectGestionCambio;
        var causaRaiz           = this.state.textareaCausaGestion;
        
        if(estadoSiguiente === '1' 
        || (this.state.selectSiguienteGestion > 1 
        && this.state.selectSiguienteGestion < 3
        && this.state.btnChangeArea===true)){
             if(idArea.length === undefined || idArea.length < 1){
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert1[this.state.idioma]);
                return;
            }  
            if(idCategoria.length === undefined || idCategoria.length < 1){
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert2[this.state.idioma]);
                return;
            } 
            if(idSubcategoria.length === undefined || idSubcategoria.length < 1){
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert3[this.state.idioma]);
                return;
            } 
            if(idAsignado.length === undefined || idAsignado.length < 1){
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert4[this.state.idioma]);
                return;
            }
        }
        else if(this.state.btnChangeArea===false){//No se seleccionó Área
            idArea = '';
            idCategoria = '';
            idSubcategoria = '';
            idAsignado = '';
        }
        if(estadoSiguiente === '2'){
            if(gestionCambio.length === undefined || gestionCambio.length < 1){
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert5[this.state.idioma]);
                return;
            }
        }
        if(observacion.length === undefined || observacion.length < 1){
            alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert6[this.state.idioma]);
            return;
        } 
        if(estadoSiguiente === '3' && this.props.params.idWin === 'windowGestionProblema'){
            if(causaRaiz.length === undefined || causaRaiz.length < 1){
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert6_1[this.state.idioma]);
                return;
            }
        }        
        alertify.confirm('Confirmacion', LabelsWindowProcesoGestion.gestion_alert7[this.state.idioma]+this.state.tipo+LabelsWindowProcesoGestion.a[this.state.idioma]+nameEstadoSiguiente+'?',this.procesoGestion.bind(this,observacion,estadoActual,estadoSiguiente,nameEstadoSiguiente,idArea,idCategoria,idSubcategoria,idAsignado,gestionCambio,causaRaiz), function(){});    
    }
    procesoGestion(obs,estadoActual,estado,name_estado,idArea,idCategoria,idSubcategoria,idAsignado,gestionCambio,causaRaiz){        
        var id_usuario = globalState.getState().userData[0].id; 
        var nomAsignado = this.state.nomAsignado; 
        //realizar la gestion de la solicitud
        modalLoading(true);
        procesoGestion(obs,estadoActual,estado,name_estado,this.props.params.idRow,id_usuario,idArea,idCategoria,idSubcategoria,idAsignado,nomAsignado,gestionCambio,this.props.params.table,this.state.tipo,causaRaiz,this.state.database).then(response => { 
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_error1[this.state.idioma]+': '+response.detail);
                modalLoading(false);
            }
            else if(response.msg === 'no_mail'){ 
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert8[this.state.idioma]);                
            }
            else if(response.msg === 'mail_error'){ 
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert9[this.state.idioma]+': '+JSON.stringify(response.detail, null, 4));
            }
            else if(response.msg === 'no_sla'){ 
                modalLoading(false);
                alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_alert10[this.state.idioma]);
            }
            else if(response.msg === 'success'){
                alertify.alert(LabelsWindowProcesoGestion.aviso[this.state.idioma], LabelsWindowProcesoGestion.gestion_alert11[this.state.idioma]);
                modalLoading(false);
            }            
            globalState.dispatch({
                type   : "windowGestionIncidencia",
                params : {
                              visible : false,                              
                         }
            });                           
            this.props.params.funcionClick(this.state.componente);            
        })
        .catch(function (error) {
            modalLoading(false);
            alertify.alert('Error!', LabelsWindowProcesoGestion.gestion_error1[this.state.idioma]+': '+error);
            globalState.dispatch({
                type   : "windowGestionIncidencia",
                params : {
                              visible : false,                              
                         }
            });
        });
    }
    handleCheckArea(e){
        this.setState({btnChangeArea : e.target.checked}); 
        if(e.target.checked === false){
            this.setState({
                idAsignado     : 0,
                nomAsignado    : '',
                idArea         : '',
                idCategoria    : '',
                idSubcategoria : '', 
            });
        }       
    }
  	render() {  
        let idioma = this.state.idioma;
        let rowsObs = 11;
        if(this.state.selectSiguienteGestion.toString() === '1'){
            rowsObs = 5;
        }
        if(this.state.selectSiguienteGestion.toString() === '3' && this.props.params.idWin === 'windowGestionProblema'){
            rowsObs = 4;
        }
        if(this.state.selectSiguienteGestion.toString() === '2' && this.state.btnChangeArea === true){
            rowsObs = 4;
        }
        else if(this.state.selectSiguienteGestion.toString() === '2'){
            rowsObs = 9;
        }
        if(this.state.selectSiguienteGestion.toString() === '2' && validarModulo(10)){
            rowsObs -= 2;
        }                      
  	  	return (<div style={{height: 'calc(100% - 30px)'}}>    
                    <TBar
                        items={[
                                  {
                                      type : 'boton',
                                      icon : 'save',
                                      width : '80px',
                                      height : '60px',
                                      title : LabelsWindowProcesoGestion.btnGuardar[idioma],
                                      function : this.handleBtnGestion.bind(this)
                                  },                              
                              ]}
                        length = '1'
                    />
                    <div id="contenedorPermisos" style={{position:"relative",top:'60px'}}>
                        <Form style={{width:'98%'}}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formFieldGestion1" style={{marginBottom : '0px',marginTop : '0px'}}>
                                    <Form.Label style={{marginBottom : '0px'}}>{LabelsWindowProcesoGestion.estado_actual[idioma]}</Form.Label>
                                    <Form.Control name = "estado_actual" type="text" value={this.state.nomEstadoActual} disabled style={{height : '26px'}}/>  
                                </Form.Group>
                                <Form.Group as={Col} controlId="formFieldGestion2" style={{marginBottom : '0px'}}>
                                    <Form.Label style={{marginBottom : '0px'}}>{LabelsWindowProcesoGestion.estado_siguiente[idioma]}</Form.Label>                       
                                    <Form.Control as="select" name = "estado_siguiente" onChange={this.handleChangeEstado.bind(this)} value={this.state.selectSiguienteGestion} style={{height : '26px'}}>
                                    {
                                        this.state.arrayEstadosGestion.map((content,i) => { 
                                            return <option key={ i } value={content.estado} >{content.nom_estado}</option>
                                        })
                                    }                            
                                    </Form.Control>  
                                </Form.Group>
                            </Form.Row>
                            {
                                this.state.selectSiguienteGestion > 1 && this.state.selectSiguienteGestion < 3 ?
                                    <><Form.Row>
                                        <Form.Group controlId="formBasicCheckbox" className="ml-2 mt-2 mb-1">
                                            <Form.Check type="checkbox" label={LabelsWindowProcesoGestion.labelChangeArea[idioma]} checked={this.state.btnChangeArea} onChange={this.handleCheckArea.bind(this)}/>
                                        </Form.Group>
                                    </Form.Row></>
                                : ''
                            }
                            {
                                this.state.selectSiguienteGestion.toString() === '1'  
                                || (this.state.selectSiguienteGestion > 1 
                                && this.state.selectSiguienteGestion < 3 && this.state.btnChangeArea===true) ?                            
                                    <><Form.Row>
                                        <Form.Group as={Col} controlId="formFieldArea" style={{marginBottom : '0px'}}>
                                            <Form.Label>{LabelsWindowProcesoGestion.area[idioma]} *</Form.Label>                       
                                            <Form.Control as="select" name = "id_area" onChange={this.handleChangeArea.bind(this)} value={this.state.idArea} style={{height : '26px'}}>
                                                <option value="">{LabelsWindowProcesoGestion.seleccione[idioma]}</option>
                                                {
                                                    this.state.arrayAreas.map((content,i) => { 
                                                        return <option key={i} value={content.id} >{content.nombre}</option>
                                                    })
                                                }                            
                                            </Form.Control>  
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formFieldCategoria" style={{marginBottom : '0px'}}>
                                            <Form.Label>{LabelsWindowProcesoGestion.categoria[idioma]} *</Form.Label>                       
                                            <Form.Control as="select" name = "id_categoria" onChange={this.handleChangeCategoria.bind(this)} value={this.state.idCategoria} style={{height : '26px'}}>
                                                <option value="">{LabelsWindowProcesoGestion.seleccione[idioma]}</option>
                                                {
                                                    this.state.arrayCategorias.map((content,i) => { 
                                                        return <option key={i} value={content.id} >{content.nombre}</option>
                                                    })
                                                }                            
                                            </Form.Control>  
                                        </Form.Group>
                                     </Form.Row>
                                     <Form.Row>
                                        <Form.Group as={Col} controlId="formFieldSubcategoria" style={{marginBottom : '0px'}}>
                                            <Form.Label>{LabelsWindowProcesoGestion.subcategoria[idioma]} *</Form.Label>                       
                                            <Form.Control as="select" name = "id_subcategoria" onChange={this.handleChangeSubcategoria.bind(this)} value={this.state.idSubcategoria} style={{height : '26px'}}>
                                                <option value="">{LabelsWindowProcesoGestion.seleccione[idioma]}</option>
                                                {
                                                    this.state.arraySubcategorias.map((content,i) => { 
                                                        return <option key={i} value={content.id} >{content.nombre}</option>
                                                    })
                                                }                            
                                            </Form.Control>
                                        </Form.Group> 
                                        <Form.Group as={Col} controlId="formFieldGestion2_1" style={{marginBottom : '0px'}}>
                                            <Form.Label>{LabelsWindowProcesoGestion.tecnico[idioma]} *</Form.Label>                       
                                            <Form.Control as="select" name = "select_tecnico" onChange={this.handleChangeTecnico.bind(this)} value={this.state.selectTecnicoAsignado} style={{height : '26px'}}>
                                                <option value="">{LabelsWindowProcesoGestion.seleccione[idioma]}</option>
                                                {
                                                    this.state.arrayTecnicos.map((content,i) => { 
                                                        return <option key={ i } value={content.id} >{content.nombre}</option>
                                                    })
                                                }                            
                                            </Form.Control>  
                                        </Form.Group> 
                                    </Form.Row></>
                                : ''
                            } 
                            <Form.Group controlId="formFieldGestion3" style={{marginBottom : '0px'}}>
                                <Form.Label style={{marginBottom : '0px',marginTop : '0px'}}>{LabelsWindowProcesoGestion.observaciones[idioma]} *</Form.Label>                                                        
                                <Form.Control name = "observaciones" as="textarea" style={{resize:'none',overflow:'hidden'}} rows={rowsObs} onChange={this.obsProcesoGestion.bind(this)} value={this.state.textareaGestion} />
                            </Form.Group> 
                            {
                                this.state.selectSiguienteGestion.toString() === '3' && this.props.params.idWin === 'windowGestionProblema' ?
                                    <Form.Group controlId="formFieldGestion3_1" style={{marginBottom : '0px'}}>
                                        <Form.Label style={{marginBottom : '0px',marginTop : '0px'}}>{LabelsWindowProcesoGestion.causa_raiz[idioma]} *</Form.Label>                                                        
                                        <Form.Control name = "causa_problema" as="textarea" style={{resize:'none',overflow:'hidden'}} rows={rowsObs} onChange={this.obsCausaGestion.bind(this)} value={this.state.textareaCausaGestion} />
                                    </Form.Group> 
                                : ''
                            }
                            {
                                this.state.selectSiguienteGestion.toString() === '2' && validarModulo(10) ?
                                    <Form.Group controlId="formFieldGestion4" style={{marginBottom : '0px',marginTop : '0px'}}>
                                        <Form.Label style={{marginBottom : '0px'}}>{LabelsWindowProcesoGestion.gestion_cambio[idioma]} *</Form.Label>                                                        
                                        <Form.Control name = "gestion_cambio" as="select" style={{resize:'none',overflow:'hidden'}} onChange={this.handleGestionCambio.bind(this)} value={this.state.selectGestionCambio}>
                                            <option value="">{LabelsWindowProcesoGestion.seleccione[idioma]}</option>
                                            <option value="false">No</option>
                                            <option value="true">{LabelsWindowProcesoGestion.si[idioma]}</option>
                                        </Form.Control> 
                                    </Form.Group> 
                                : ''
                            }               
                        </Form>
                    </div>
                </div>  				
			  );
  	}
}

export default WindowProcesoGestion