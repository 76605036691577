/**
* CLASS Activos
*
* Contiene el contenedor principal de los activos
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import Window from '../window/Window';
import {cargarFilas} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import {generarCodigoActivo} from '../api_calls/ApiCalls';
import {validarPermiso} from '../configuration/GlobalFunctions';
import TBar from '../tbar/TBar';
import alertify from 'alertifyjs';

class Activos extends Component {
    constructor(props){
        super(props);
        let permisoAdministracion = 'none';
        if(validarPermiso(6) === true){
            permisoAdministracion = 'block';                    
        }
        this.state = {
            permisoAdministracion : permisoAdministracion
        };
    }
    retrocederPanel(){        
        if(globalState.getState().opcionDashboardActivos && globalState.getState().opcionDashboardActivos.idTipo > 0){
            this.props.funcionClick('DashboardActivos');
        }
        else{
            this.props.funcionClick('PanelMenu');            
        }
    }   
    colFuncion(idRow){        
        globalState.dispatch({
                type   : "windowNovedadesActivos",
                params : {
                              visible : true,
                              params  : {
                                            idRow : idRow,                                                                                         
                                            idWin : "windowNovedadesActivos",
                                            width : '500px', 
                                            height: '400px'//identificador de la ventana
                                        }
                         }
            }); 
    } 
    colFuncion2(idRow){        
        globalState.dispatch({
                type   : "windowAdjuntosActivos",
                params : {
                              visible : true,
                              params  : {
                                            idRow   : idRow, 
                                            table   : 'activos',
                                            maxSize : 10245760,
                                            allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],                                           
                                            idWin   : "windowAdjuntosActivos",
                                            width   : '300px', 
                                            height  : '250px'//identificador de la ventana
                                        }
                         }
            }); 
    }
    colFuncion3(idRow){
        this.props.funcionClick('ImprimirFichaTecnica',{ idActivo : idRow });   

    }
    btnVisorLog(idRow){
        globalState.dispatch({
            type   : "windowVisorLog",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,
                                        table : 'activos',
                                        idWin : "windowVisorLog",
                                        width : '400px', 
                                        height: '300px'//identificador de la ventana
                                    }
                     }
        });
    }
    btnEditarFicha(idRow){
        globalState.dispatch({
            type   : "windowEditarFichaTecnica",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,                                        
                                        idWin : "windowEditarFichaTecnica",
                                        width : '400px', 
                                        height: '70%'//identificador de la ventana
                                    }
                     }
        });
    }
    btnCambiarEstado(idRow){
        globalState.dispatch({
            type   : "windowCambiarEstado",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,  
                                        funcionClick : this.props.funcionClick,                                       
                                        idWin : "windowCambiarEstado",
                                        width : '400px', 
                                        height: '250px'//identificador de la ventana
                                    }
                     }
        });
    } 
    generarCodigo(id){
        let bd = globalState.getState().companyData[0].bd;
        generarCodigoActivo(id,bd).then(response => { 

        }).catch(function (error) {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    } 
    async validacionDeleteActivo(id){
        try{
            // Validar que no haya un funcionario vinculado al activo
            let database = globalState.getState().companyData[0].bd;
            let sqlParams = {
                                sqlCols : [
                                    "id_asignado"                                    
                                ],                            
                                sqlWhere : [
                                    " AND id = "+id
                                ],                              
                            }; 
            let res = await cargarFilas('activos','',1000,0,'','',sqlParams,'rows',database);
            let response = res.data; 
            if(response.length > 0 && response[0].id_asignado > 0){
                return {  status : false, message : "El activo no se puede eliminar, está asignado a un usuario!" }; 
            }

            // Validar que el activo no esté vinculado a incidencias sin finalizar             
            sqlParams = {
                                sqlCols : [
                                    "COUNT(id) AS total"                                   
                                ],                            
                                sqlWhere : [
                                    " AND problema='false' AND estado < 5 AND id_activo = "+id
                                ],                              
                            }; 
            res = await cargarFilas('incidencias','',1000,0,'','',sqlParams,'rows',database);
            response = res.data; 
            if(response.length > 0 && response[0].total > 0){
                return {  status : false, message : "El activo no se puede eliminar, está vinculado a incidencias en proceso!" }; 
            }

            // Validar que el activo no esté vinculado a problemas sin finalizar 
            sqlParams = {
                                sqlCols : [
                                    "COUNT(id) AS total"                                   
                                ],                            
                                sqlWhere : [
                                    " AND problema='true' AND estado < 5 AND id_activo = "+id
                                ],                              
                            }; 
            res = await cargarFilas('incidencias','',1000,0,'','',sqlParams,'rows',database);
            response = res.data; 
            if(response.length > 0 && response[0].total > 0){
                return {  status : false, message : "El activo no se puede eliminar, está vinculado a problemas en proceso!" }; 
            }

            // Validar que el activo no esté vinculado a servicios sin finalizar             
            sqlParams = {
                                sqlCols : [
                                    "COUNT(id) AS total"                                   
                                ],                            
                                sqlWhere : [
                                    " AND estado < 5 AND id_activo = "+id//no cuenta superusuarios
                                ],                              
                            }; 
            res = await cargarFilas('servicios','',1000,0,'','',sqlParams,'rows',database);
            response = res.data; 
            if(response.length > 0 && response[0].total > 0){
                return {  status : false, message : "El activo no se puede eliminar, está vinculado a servicios en proceso!" }; 
            }
            
            return {  status : true, message : "" }; 
               
        }           
        catch(err){
            return {  status : false, message : 'No se ha logrado la conexion con el servidor!<br />'+err.message }; 
        }     
    }
  	render() {           
        let whereCat = '';
        if(globalState.getState().opcionDashboardActivos.idTipo > 0){
            whereCat = ' AND T1.id_tipo='+globalState.getState().opcionDashboardActivos.idTipo;            
        }
        let sqlParams = {
                            sqlCols : [
                                'T1.id',
                                'T1.nombre',                                
                                'DATE_FORMAT(T1.fecha_compra,"%Y-%m-%d") AS fecha_compra',
                                'TA.nombre AS tipo_activo',
                                'P.nombre_comercial AS proveedor', 
                                "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS asignado",
                                'T1.precio_compra',
                                'T1.id_tipo',
                                'T1.codigo',
                                'T1.id_proveedor',                                
                                'T1.marca',                                
                                'T1.estado',
                                'AE.nombre AS nom_estado',
                                'T1.id_departamento',
                                'T1.id_ubicacion',
                                'D.nombre AS departamento',
                                'UB.nombre AS ubicacion',                                          
                            ],
                            sqlJoin : [
                                'LEFT JOIN users AS U ON (U.id = T1.id_asignado)',
                                'INNER JOIN activos_estados AS AE ON (AE.id = T1.estado)', 
                                'INNER JOIN activos_tipos AS TA ON (TA.id = T1.id_tipo)', 
                                'INNER JOIN terceros AS P ON (P.id = T1.id_proveedor)',
                                'INNER JOIN departamentos AS D ON (D.id = T1.id_departamento)',
                                'LEFT JOIN departamentos_ubicaciones AS UB ON (UB.id = T1.id_ubicacion)'
                            ],
                            sqlWhere : [ whereCat ],
                            fieldSearch : [
                                'T1.nombre',
                                'T1.fecha_compra',
                                'T1.precio_compra',
                                'TA.nombre',
                                'P.nombre_comercial'                               
                            ],                                                   
                            sqlOrderBy : "T1.id DESC"               
                        };
        let windowVisorLog = <Window 
                                  id = "windowVisorLog"                      
                                  title='Ver Log'                                                
                                  tbar="false"
                                  componente="WindowVisorLog"
                                  params="" 
                              /> 

        let windowEditarFichaTecnica = <Window 
                                            id = "windowEditarFichaTecnica"                      
                                            title='Ficha Tecnica'                                                
                                            tbar="false"
                                            componente="WindowEditarFichaTecnica"
                                            params="" 
                                        /> 

        let windowCambiarEstado = <Window 
                                            id = "windowCambiarEstado"                      
                                            title='Ficha Tecnica'                                                
                                            tbar="false"
                                            componente="WindowCambiarEstado"
                                            params="" 
                                        />  

        let dataLogParams = {
            codigo : "Codigo",
            nombre : "Nombre",
            marca  : "Marca",
            precio_compra : "Precio de Compra",
            fecha_compra : "Fecha de Compra"
        };

        return (
            <div>
                <TBar
                    items={[                              
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>
                    <DataGrid titulo={globalState.getState().opcionDashboardActivos.titulo} 
                              funcionClick={this.props.funcionClick}  
                              parametro={this.props.parametro}
                              funcionBeforeDelete={this.validacionDeleteActivo.bind(this)}
                              tbarEdit="true"
                              tbarItems={[
                                              {
                                                    type : 'boton',
                                                    icon : 'description',
                                                    width : '80px',
                                                    height : '70px',
                                                    title : 'Ver Log',
                                                    display : 'block', 
                                                    windowComponent : windowVisorLog,
                                                    function : this.btnVisorLog.bind(this)                                       
                                              },
                                              {
                                                    type : 'boton',
                                                    icon : 'assignment',
                                                    width : '80px',
                                                    height : '70px',
                                                    title : 'Ficha Tecnica',
                                                    display : this.state.permisoAdministracion, 
                                                    windowComponent : windowEditarFichaTecnica,
                                                    function : this.btnEditarFicha.bind(this)                                       
                                              },
                                              {
                                                    type : 'boton',
                                                    icon : 'settings',
                                                    width : '80px',
                                                    height : '70px',
                                                    title : 'Cambiar Estado',
                                                    display : this.state.permisoAdministracion, 
                                                    windowComponent : windowCambiarEstado,
                                                    function : this.btnCambiarEstado.bind(this)                                       
                                              }
                                         ]} 
                              dataFilter={[                                                
                                                {
                                                    label : "Estado",
                                                    dinamic : 'true',
                                                    field : 'T1.estado',
                                                    sqlParams : {
                                                                    sqlCols : [
                                                                        'id',
                                                                        'nombre'                                
                                                                    ],                                                                                                                                                                                                                                                                                               
                                                                },
                                                    apiField : 'activos_estados',
                                                    valueName : 'nombre',
                                                },
                                                {
                                                    label : "Depto",
                                                    dinamic : 'true',
                                                    field : 'T1.id_departamento',
                                                    sqlParams : {
                                                                    sqlCols : [
                                                                        'id',
                                                                        'nombre'                                
                                                                    ],                                                                                                                                                                                                                                                                                               
                                                                },
                                                    apiField : 'departamentos',
                                                    valueName : 'nombre',
                                                },
                                                {                                                    
                                                    label   : 'Asignado',                                        
                                                    dinamic : 'data_select',
                                                    field   : 'id_asignado',
                                                    dataParams : { 
                                                                     fetchData : {
                                                                          fieldFetch  : 'nombre',
                                                                          idField     : 'id_asignado',
                                                                          valueField  : 'nombre_asignado',
                                                                     },
                                                                     apiField    : 'users',
                                                                     colsData    : [ 
                                                                                        {
                                                                                            type  : 'bd',
                                                                                            label : 'Documento',
                                                                                            field : 'documento'
                                                                                        },
                                                                                        {
                                                                                            type  : 'bd',
                                                                                            label : 'Nombre',
                                                                                            field : 'nombre'
                                                                                        },
                                                                                    ],
                                                                     sqlParams : {
                                                                                    sqlCols : [ 
                                                                                        'id',                                 
                                                                                        'documento',
                                                                                        'nombre'                                
                                                                                    ], 
                                                                                    sqlWhere : [
                                                                                        " AND id IN (SELECT T1.id_asignado FROM activos AS T1 WHERE T1.activo = 1 "+whereCat+")"
                                                                                    ],                        
                                                                                    fieldSearch : [                                                                            
                                                                                        'documento',
                                                                                        'nombre',                                                                            
                                                                                    ],                                      
                                                                                 }                                                                                           
                                                                 },
                                                },
                                                {                                                    
                                                    label   : 'Proveedor',                                        
                                                    dinamic : 'data_select',
                                                    field   : 'id_proveedor',
                                                    dataParams : { 
                                                                     fetchData : {
                                                                          fieldFetch  : 'nombre_comercial',
                                                                          idField     : 'id_proveedor',
                                                                          valueField  : 'nombre_proveedor',
                                                                     },
                                                                     apiField    : 'terceros',
                                                                     colsData    : [ 
                                                                                        {
                                                                                            type  : 'bd',
                                                                                            label : 'Nit',
                                                                                            field : 'documento'
                                                                                        },
                                                                                        {
                                                                                            type  : 'bd',
                                                                                            label : 'Nombre',
                                                                                            field : 'nombre_comercial'
                                                                                        },
                                                                                    ],
                                                                     sqlParams : {
                                                                                    sqlCols : [ 
                                                                                        'id',                                 
                                                                                        'documento',
                                                                                        'nombre_comercial'                                
                                                                                    ],  
                                                                                    sqlWhere : [
                                                                                        " AND proveedor='true' AND id IN (SELECT T1.id_proveedor FROM activos AS T1 WHERE T1.activo = 1 "+whereCat+")"
                                                                                    ],                           
                                                                                    fieldSearch : [                                                                            
                                                                                        'documento',
                                                                                        'nombre_comercial',                                                                            
                                                                                    ],                                      
                                                                                 }                                                                                           
                                                                 },
                                                },
                                            ]}
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Fecha Compra',
                                                field : 'fecha_compra',
                                                onlyXLS : 'true'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Codigo',
                                                field : 'codigo'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Tipo',
                                                field : 'tipo_activo'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Marca',
                                                field : 'marca'
                                            },                                            
                                            {
                                                type  : 'bd',
                                                label : 'Nombre',
                                                field : 'nombre'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Departamento',
                                                field : 'departamento'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Estado',
                                                field : 'nom_estado'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Proveedor',
                                                field : 'proveedor'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Asignado',
                                                field : 'asignado'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Precio Compra',
                                                field : 'precio_compra',
                                                onlyXLS : 'true'
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'description',
                                                colFuncion : this.colFuncion.bind(this)
                                            },                                                
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'attach_file',
                                                colFuncion : this.colFuncion2.bind(this)
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'picture_as_pdf',
                                                colFuncion : this.colFuncion3.bind(this)
                                            }                                      
                                        ]}
                              sqlParams = { sqlParams }                              
                              automatica="true"
                              botonNuevo="true"
                              botonesExportar="true"  
                              botonExportarXls="true" 
                              funcionAfterInsert={this.generarCodigo.bind(this)}
                              funcionAfterUpdate={this.generarCodigo.bind(this)}
                              dataLog={dataLogParams}                                                
                              formFields={[
                                            {
                                                label : 'Fecha de Compra *',
                                                field : 'fecha_compra',
                                                type  : 'date',
                                                validation : '',
                                                required : 'true',
                                                tooltip : 'Ingrese la fecha de compra del activo'                                        
                                            },                                              
                                            {
                                                label : 'Departamento *',
                                                field : 'id_departamento',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'true',
                                                apiField : 'departamentos',
                                                valueName : 'nombre',
                                                updateTo : 'id_ubicacion',
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ], 
                                                            },
                                                tooltip : 'Seleccione el departamento'  
                                            }, 
                                            {
                                                label : 'Ubicacion *',
                                                field : 'id_ubicacion',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'true',
                                                apiField : 'departamentos_ubicaciones',
                                                valueName : 'nombre',
                                                fieldUpdate : 'id_departamento',                                        
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],  
                                                            },
                                                tooltip : 'Seleccione la ubicacion' 
                                            },                                
                                            {
                                                label : 'Proveedor *',                                        
                                                type  : 'data_select',
                                                field : 'id_proveedor',
                                                dataParams : { 
                                                                 fetchData : {
                                                                      fieldFetch  : 'nombre_comercial',
                                                                      idField     : 'id_proveedor',
                                                                      valueField  : 'proveedor',
                                                                 },
                                                                 apiField    : 'terceros',                                                         
                                                                 colsData    : [ 
                                                                                    {
                                                                                        type  : 'bd',
                                                                                        label : 'Documento',
                                                                                        field : 'documento'
                                                                                    },
                                                                                    {
                                                                                        type  : 'bd',
                                                                                        label : 'Nombre',
                                                                                        field : 'nombre_comercial'
                                                                                    },
                                                                                ],
                                                                 sqlParams : {
                                                                                sqlCols : [ 
                                                                                    'id',                               
                                                                                    'documento',
                                                                                    'nombre_comercial'                                
                                                                                ],                            
                                                                                fieldSearch : [
                                                                                    'documento',
                                                                                    'nombre_comercial',                                                                            
                                                                                ], 
                                                                                sqlWhere : [
                                                                                    " AND proveedor='true'"   
                                                                                ]                                                                                                                              
                                                                             } 
                                                              },
                                                tooltip : 'Seleccione el proveedor del activo',  
                                                validation : '',
                                                required : 'true'                                        
                                            },
                                            {
                                                label : 'Marca *',
                                                field : 'marca',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                required : 'true',
                                                longitud : 35,
                                                tooltip : 'Ingrese la marca del activo'  
                                            },                                            
                                            {
                                                label : 'Nombre *',
                                                field : 'nombre',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                required : 'true',
                                                longitud : 40,
                                                tooltip : 'Ingrese el nombre del activo'  
                                            },                                                                                        
                                            {
                                                label : 'Precio Compra *',
                                                field : 'precio_compra',
                                                type  : 'text',
                                                validation : 'entero',
                                                required : 'true',
                                                tooltip : 'Ingrese el precio de compra del activo'  
                                            },                                            
                                            {
                                                label  : 'Adjunto',                                                    
                                                field  : 'nombre_archivo',                                                                                                        
                                                type   : 'multi_file',
                                                accept : ".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png",
                                                allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],
                                                validation : 'file',
                                                maxSize : 10245760,
                                                required : '',
                                                tooltip : 'Seleccione los archivos a adjuntar en el activo'
                                            },
                                            {
                                                label : '',
                                                field : 'id_tipo',
                                                type  : 'hidden',
                                                validation : '',
                                                value : globalState.getState().opcionDashboardActivos.idTipo,
                                                required : 'true'                                        
                                            },                                         
                                        ]}                     
                              apiField = {'activos'}
                              permisoInsertUpdate="6"
                              permisoDelete="7"
                              mainContainer='Activos'/>  
                </div>
                 <Window 
                    id = "windowNovedadesActivos"                      
                    title='Ver Novedades'                    
                    tbar="false"
                    componente="WindowNovedadesActivos"
                    params="" 
                />
                <Window 
                    id = "windowAdjuntosActivos"                     
                    title='Ver Documentos'                   
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                /> 
                <Window 
                    id = "windowUploadArchivo"                      
                    title='Subir Adjunto'                    
                    tbar="false"
                    componente="WindowUploadArchivo"
                    params="" 
                />
                <Window 
                    id = "windowImprimirFicha"                      
                    title='Imprimir Ficha'                    
                    tbar="false"
                    componente="WindowImprimirFicha"
                    params="" 
                />                                
            </div>           
        )
    } 
}

export default Activos