/**
* CLASS WindowDiasFestivos
*
* Contiene el componente que lista los dias festivos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,insertarActualizarFila,eliminarFilas} from '../../api_calls/ApiCalls';
import {fechaMysql,modalLoading} from '../../configuration/GlobalFunctions';
import globalState from '../../configuration/GlobalState';
import alertify from 'alertifyjs';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import '../../data_grid/dataGrid.css'; 

class WindowDiasFestivos extends Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedDays: [],
            database : globalState.getState().companyData[0].bd 
        };
    }
    componentWillMount(){
        let sqlParams = {
                            sqlCols : [
                                "DISTINCT(DATE_FORMAT(fecha,'%Y-%m-%d')) AS fecha",                                                                                                                                       
                            ],
                            sqlWhere : [
                                " id > 0 "
                            ],      
                            sqlOrderBy  : 'fecha',                      
                            sqlInactive : 'true',                                                                                                                                                                                      
                        } 
        cargarFilas('dias_festivos','',100000,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
            } 
            else{
                let arrayDias = [];
                let fecha = '';
                let dia = '';
                let mes = '';
                let year = '';
                if(res.length > 0){
                    for(let i in res){
                        fecha = res[i].fecha;
                        year  = fecha.slice(0,4);
                        mes   = fecha.slice(5,7);
                        dia   = fecha.slice(8,10);                        
                        arrayDias.push(new Date(year,mes-1,dia)); 
                    }
                    this.setState({selectedDays:arrayDias});                    
                }                
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        let dia = fechaMysql(day).slice(0,10);
        dia = dia.slice(0,10);            
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );            
            let where = [ " fecha = '"+dia+"'" ];
            modalLoading(true);
            eliminarFilas('dias_festivos',0,'false','delete',where,this.state.database)
            .then(response => {            
                response = response.data;
                if(response.msg === 'error'){
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail); 
                    modalLoading(false);                    
                }
                else {
                    selectedDays.splice(selectedIndex, 1);
                    modalLoading(false); 
                }                
            })
            .catch(function (error) {
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            });
        } else {
            let objectFestivo = {
                fecha : dia
            };
            modalLoading(true);
            insertarActualizarFila('post','dias_festivos',objectFestivo,'false',undefined,this.state.database).then(response => {               
                response = response.data;   
                if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                    alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
                    modalLoading(false);
                }
                else{
                    selectedDays.push(day);
                    modalLoading(false);               
                }     
            })
            .catch(function (error) {
                alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
            })
        }
        this.setState({ selectedDays });
    }    
    render() {    
        return (<div style={{textAlign:'center'}}>
                    <DayPicker
                      selectedDays={this.state.selectedDays}
                      onDayClick={this.handleDayClick}
                    />
                </div>);
    }
}

export default WindowDiasFestivos