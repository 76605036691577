/**
* CLASS WindowEmpresasModulos
*
* Contiene el componente que lista los accesos a modulos de la empresa
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,guardaModulos} from '../../api_calls/ApiCalls';
import globalState from '../../configuration/GlobalState';
import {modalLoading} from '../../configuration/GlobalFunctions';
import TBar from '../../tbar/TBar';
import configJson from '../../configuration/configuration.json';
import alertify from 'alertifyjs';

class WindowEmpresasModulos extends Component {
    constructor(props) {        
        super(props);   
        this.state = {
            objModulos : '',
            database : globalState.getState().companyData[0].bd
        };        
    } 
    componentWillMount(){
        var idRow = this.props.params.idRow;
        let sqlParams = { 
                            sqlCols : [ 
                                        "T1.id",
                                        "T1.nombre",                                        
                                        "R.id AS checked"
                            ],                                                     
                            sqlJoin : [ "LEFT JOIN ikarosof_management_acceso.companies_modulos AS R ON(R.id_modulo = T1.id AND R.id_empresa = "+idRow+")" ],                       
                        }
        cargarFilas('ikarosof_management_acceso.modulos','',100,0,'','',sqlParams,'rows',this.state.database,configJson.cloudRoute)
        .then(response => { 
            response = response.data;            
            if(response.msg === 'error'){//aqui no me dejara continuar si la empresa noe xiste
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail); 
            }
            else{
                this.setState({objModulos:response},()=>{                    
                    response.forEach((objModulos,i) => {//chequear los que tengan el permiso activido en el rol
                        var checked = false;                        
                        if(objModulos.checked > 0){
                            checked = true;
                        }                        
                        this.setState({ ['modulo_'+objModulos.id]: checked });  
                    });                    
                });
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    } 
    handleCheckBox(e){//control del check de los checkbox
        var checkBox = e.target.name;
        var checked  = e.target.checked;        
        this.setState({ [checkBox]: checked });          
    }
    guardaEmpresasModulos(){        
        var arrayModulos = [];
        var id = 0;
        for(var i in this.state.objModulos){
            id = this.state.objModulos[i].id;
            if(this.state['modulo_'+id] === true){
                arrayModulos.push(id);                
            }
        }        
        modalLoading(true);
        guardaModulos(this.props.params.idRow,arrayModulos,'ikarosof_management_acceso').then(response => { 
            modalLoading(false);
            globalState.dispatch({
                type   : "windowEmpresasModulos",
                params : {
                              visible : false,                              
                         }
            });
        })
        .catch(function (error) {
            modalLoading(false);
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    }    
  	render() {             
  	  	return (
                <div style={{height:'calc(100% - 30px)'}}>
                    <TBar
                        items={[
                                 {
                                    type : 'boton',
                                    icon : 'save',
                                    width : '100px',
                                    height : '60px',
                                    title : 'Guardar',
                                    function : this.guardaEmpresasModulos.bind(this)
                                },                              
                              ]}
                        length = '1'
                    />
                    <div id="contenedorPermisos" style={{position:"relative",top:'60px',paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'calc(100% - 60px)',overflowY : 'auto' }}>   
                    {
                        (this.state.objModulos !== '') ?
                            this.state.objModulos.map((objModulos,i) => {
                                var padding = 5;
                                var fontWeight = 'bold';                                                                                    
                                return <div key={i} style={{width:'100%',height:'24px'}}>
                                            <div style={{float:'left'}}>
                                                <input name={'modulo_'+objModulos.id} type="checkbox" onChange={this.handleCheckBox.bind(this)} checked={this.state['modulo_'+objModulos.id] || false } />
                                            </div>
                                            <div style={{paddingLeft:padding+'px',float:'left',fontWeight:fontWeight,fontSize:'11px' }}>
                                                {objModulos.nombre} 
                                            </div>
                                        </div>
                            })
                        : ''
                    }  
                    </div> 
                </div> 				
			  );
  	}
}

export default WindowEmpresasModulos