/**
/**
* CLASS TercerosContratos
*
* Contiene el contenedor principal de los contratos de proveedor
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import Window from '../window/Window';
//import {validarPermiso} from '../configuration/GlobalFunctions';
import TBar from '../tbar/TBar';
import globalState from '../configuration/GlobalState';

class TercerosContratos extends Component { 
    retrocederPanel(){
        if(globalState.getState().idTercero.tipo === 'proveedor'){
            this.props.funcionClick('Proveedores');
        }
        else{
            this.props.funcionClick('Clientes');
        }        
    }
    colFuncion(idRow){ 
        globalState.dispatch({
                type   : "windowNotificacionesContratos",
                params : {
                              visible : true,
                              params  : {
                                            idRow  : idRow,
                                            opcion : globalState.getState().idTercero.tipo,                                            
                                            idWin  : "windowNotificacionesContratos",
                                            width  : '350px', 
                                            height : '70%'//identificador de la ventana
                                        }
                         }
            }); 
    }
    colFuncion1(idRow){
        globalState.dispatch({
            type   : "idContrato",
            params : {
                idRow   : idRow,                    
                tercero : globalState.getState().idTercero.nombre
            }
        });             
        this.props.funcionClick('TercerosContratosPagos');               
    }
    colFuncion2(idRow){        
        globalState.dispatch({
                type   : "windowArchivosAdjuntosContratos",
                params : {
                              visible : true,
                              params  : {
                                            idRow   : idRow,
                                            maxSize : 3145728,
                                            table   : 'terceros_contratos',
                                            idWin   : "windowArchivosAdjuntosContratos",
                                            width   : '300px', 
                                            height  : '250px'//identificador de la ventana
                                        }
                         }
            }); 
    }
    btnVisorLog(idRow){
        globalState.dispatch({
            type   : "windowVisorLog",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,
                                        table : 'terceros_contratos',
                                        idWin : "windowVisorLog",
                                        width : '400px', 
                                        height: '300px'//identificador de la ventana
                                    }
                     }
        });
    }  
    render() {  
        let permisoInsert = 66;
        let permisoUpdate = 67;        
        let fieldResponsable =  {
                                    label : 'Responsable Pago *',
                                    field : 'nombre_responsable_pago',
                                    type  : 'text',
                                    validation : 'mayusculas',
                                    required : 'true',
                                    longitud : 70,
                                    tooltip : 'Ingrese el responsable de pagos'                                        
                                };

        let fieldCorreo  =  {
                                label : 'Correo Responsable *',
                                field : 'email_responsable_pago',
                                type  : 'text',
                                validation : 'email',
                                required : 'true',
                                tooltip : 'Ingrese el correo electronico del responsable'  
                            };                        

        if(globalState.getState().idTercero.tipo === 'proveedor'){
            permisoInsert = 17;
            permisoUpdate = 58;
            fieldResponsable  = {
                                    label : 'Responsable Pago *',                                        
                                    type  : 'data_select',
                                    field : 'id_responsable_pago',
                                    dataParams : { 
                                                     fetchData : {
                                                          fieldFetch  : 'nombre',
                                                          idField     : 'id_responsable_pago',
                                                          valueField  : 'responsable_pago',
                                                     },
                                                     apiField    : 'users',                                                         
                                                     colsData    : [ 
                                                                        {
                                                                            type  : 'bd',
                                                                            label : 'Documento',
                                                                            field : 'documento'
                                                                        },
                                                                        {
                                                                            type  : 'bd',
                                                                            label : 'Nombre',
                                                                            field : 'nombre'
                                                                        },
                                                                    ],
                                                     sqlParams : {
                                                                    sqlCols : [ 
                                                                        'id',                               
                                                                        'documento',
                                                                        'nombre'                                
                                                                    ],                            
                                                                    fieldSearch : [
                                                                        'documento',
                                                                        'nombre',                                                                            
                                                                    ],                                                                                                                               
                                                                 } 
                                                  },
                                    tooltip : 'Seleccione el responsable de pago',  
                                    validation : '',
                                    required : 'true'                                        
                                };
            fieldCorreo = null;
        }
                       
        let sqlParams = { 
                            sqlCols : [
                                "T1.id",
                                "T1.id_tercero",
                                "T1.nombre", 
                                "T1.id_moneda", 
                                "T1.id_area", 
                                "T1.tipo_contrato",
                                "T1.objeto_contrato",  
                                "T1.estado", 
                                "PC.nombre AS nom_estado",
                                "CP.nombre AS nom_plan_pago",
                                "AR.nombre AS nom_area",   
                                "T1.id_plan_pago",
                                "T1.numero_pagos",
                                "T1.nombre_responsable_pago",
                                "T1.email_responsable_pago",                                
                                "T1.iva", 
                                "T1.monto", 
                                "CONCAT(M.simbolo,CAST(FORMAT(T1.monto+(T1.monto*(T1.iva/100)),0) AS CHAR)) AS valor_monto", 
                                "T1.id_responsable_ejecucion", 
                                'U.nombre AS responsable_ejecucion',  
                                "T1.id_responsable_pago",
                                'U1.nombre AS responsable_pago',
                                "T1.tasa_negociacion",   
                                "T1.renovacion_automatica",
                                "T1.observaciones",                                                    
                                "DATE_FORMAT(T1.fecha_inicio,'%Y-%m-%d') AS fecha_inicio", 
                                "DATE_FORMAT(T1.fecha_vencimiento,'%Y-%m-%d') AS fecha_vencimiento", 
                                "DATE_FORMAT(T1.vencimiento_ultimo_pago,'%Y-%m-%d') AS vencimiento_ultimo_pago",
                            ],
                            fieldSearch : [
                                "T1.nombre",                                                                
                            ],                             
                            sqlJoin : [ 
                                'INNER JOIN monedas AS M ON (M.id = T1.id_moneda)',
                                'INNER JOIN areas_servicio AS AR ON (AR.id = T1.id_area)', 
                                'INNER JOIN terceros_contratos_estados AS PC ON (PC.id = T1.estado)',
                                'INNER JOIN terceros_contratos_planes_pagos AS CP ON (CP.id = T1.id_plan_pago)',
                                'INNER JOIN users AS U ON (U.id = T1.id_responsable_ejecucion)',
                                'LEFT JOIN users AS U1 ON (U1.id = T1.id_responsable_pago)',
                            ],
                            sqlWhere : [
                                " AND T1.id_tercero="+globalState.getState().idTercero.idRow,
                                " AND T1.tipo='"+globalState.getState().idTercero.tipo+"'" 
                            ],
                            sqlOrderBy : "T1.fecha_vencimiento ASC",                                                     
                            filtroFechas : "false",                                                 
                        }; 
        let windowVisorLog = <Window 
                                  id = "windowVisorLog"                      
                                  title='Ver Log'                                                
                                  tbar="false"
                                  componente="WindowVisorLog"
                                  params="" 
                              />                
        return (//carga el componente que contiene la grilla de datos                            
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>           
                    <DataGrid titulo={'Contratos '+globalState.getState().idTercero.tipo+' '+globalState.getState().idTercero.nombre}
                        funcionClick={this.props.funcionClick} 
                        parametro={this.props.parametro}
                        tbarEdit="true"
                        tbarItems={[
                                        {
                                              type : 'boton',
                                              icon : 'description',
                                              width : '80px',
                                              height : '70px',
                                              title : 'Ver Log',
                                              display : 'block', 
                                              windowComponent : windowVisorLog,
                                              function : this.btnVisorLog.bind(this)                                       
                                        }
                                   ]}
                        colsData={[ 
                                      {
                                          type  : 'bd',
                                          label : 'Consec.',
                                          field : 'id'
                                      },
                                      {
                                          type  : 'bd',
                                          label : 'Nombre',
                                          field : 'nombre'
                                      },
                                      {
                                          type  : 'bd',
                                          label : 'Tipo',
                                          field : 'tipo_contrato'
                                      },
                                      {
                                          type  : 'bd',
                                          label : 'Area Servicio',
                                          field : 'nom_area'
                                      },
                                      {
                                          type  : 'bd',
                                          label : 'Estado',
                                          field : 'nom_estado'
                                      },                                        
                                      {
                                          type  : 'bd',
                                          label : 'Fecha Inicio',
                                          field : 'fecha_inicio'
                                      },
                                      {
                                          type  : 'bd',
                                          label : 'Vencimiento',
                                          field : 'fecha_vencimiento'
                                      },
                                      {
                                          type  : 'bd',
                                          label : 'Plan Pago',
                                          field : 'nom_plan_pago'
                                      },
                                      {
                                          type  : 'bd',
                                          label : 'Total',
                                          field : 'valor_monto'
                                      },
                                      {
                                          type  : 'bd',
                                          label : 'N. Pagos',
                                          field : 'numero_pagos'
                                      },
                                      {
                                          type  : 'bd',
                                          label : 'Venc. Ultimo Pago',
                                          field : 'vencimiento_ultimo_pago'
                                      },
                                      {
                                          type  : 'manual',
                                          label : '',
                                          icon  : 'notifications',
                                          colFuncion : this.colFuncion.bind(this)
                                      },
                                      {
                                          type  : 'manual',
                                          label : '',
                                          icon  : 'monetization_on',
                                          colFuncion : this.colFuncion1.bind(this)
                                      },                                                
                                      {
                                          type  : 'manual',
                                          label : '',
                                          icon  : 'attach_file',
                                          colFuncion : this.colFuncion2.bind(this)
                                      }
                                  ]} 
                        formFields ={[ 
                                        {
                                            label : 'Informacion General',
                                            field : '',
                                            type  : 'separator',
                                            validation : '',
                                            required : ''                                        
                                        },   
                                        {
                                            label : 'Nombre *',
                                            field : 'nombre',
                                            type  : 'text',
                                            validation : 'texto_mayusculas',
                                            required : 'true',
                                            longitud : 70,
                                            tooltip : 'Ingrese el nombre del contrato'                                        
                                        },
                                        {
                                            label : 'Tipo de Contrato *',
                                            field : 'tipo_contrato',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'false',
                                            valueName : 'nombre',
                                            options :  [{
                                                            id: 'administrativo',
                                                            nombre: 'Administrativo',
                                                        },
                                                        {
                                                            id: 'civil',
                                                            nombre: 'Civil',
                                                        },
                                                        {
                                                            id: 'comercial',
                                                            nombre: 'Comercial', 
                                                        }],
                                            tooltip : 'Seleccione el tipo de contrato',                                                                                            
                                        },
                                        {
                                            label : 'Area de Servicio *',
                                            field : 'id_area',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'true',
                                            apiField : 'areas_servicio',
                                            valueName : 'nombre',                                           
                                            sqlParams : {
                                                            sqlCols : [
                                                                'id',
                                                                'nombre'                                
                                                            ],                                                                                                                                                                                                                                                                                           
                                                        },
                                            tooltip : 'Seleccione el area de servicio',                                                                                       
                                        },                                                                                
                                        {
                                            label : 'Estado *',
                                            field : 'estado',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'true',
                                            apiField : 'terceros_contratos_estados',
                                            valueName : 'nombre',                                           
                                            sqlParams : {
                                                            sqlCols : [
                                                                'id',
                                                                'nombre'                                
                                                            ],                                                                                                                                                                                                                                                                                           
                                                        },
                                            tooltip : 'Seleccione el estado',                                                                                        
                                        },
                                        {
                                            label : 'Moneda *',
                                            field : 'id_moneda',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'true',
                                            apiField : 'monedas',
                                            valueName : 'nombre',                                                                                      
                                            sqlParams : {
                                                            sqlCols : [
                                                                'id',
                                                                'nombre'                                
                                                            ],                                                                                                                                                                                                                                                                                          
                                                        },
                                            tooltip : 'Seleccione la Moneda' 
                                        },
                                        {
                                            label : 'IVA(%) *',
                                            field : 'iva',
                                            type  : 'text',
                                            validation : 'entero',
                                            required : 'true',
                                            tooltip : 'Ingrese el porcentaje del IVA'  
                                        },
                                        {
                                            label : 'Monto *',
                                            field : 'monto',
                                            type  : 'text',
                                            validation : 'entero',
                                            required : 'true',
                                            tooltip : 'Ingrese el monto'  
                                        },                                        
                                        {
                                            label : 'Responsable Ejecucion *',                                        
                                            type  : 'data_select',
                                            field : 'id_responsable_ejecucion',
                                            dataParams : { 
                                                             fetchData : {
                                                                  fieldFetch  : 'nombre',
                                                                  idField     : 'id_responsable_ejecucion',
                                                                  valueField  : 'responsable_ejecucion',
                                                             },
                                                             apiField    : 'users',                                                         
                                                             colsData    : [ 
                                                                                {
                                                                                    type  : 'bd',
                                                                                    label : 'Documento',
                                                                                    field : 'documento'
                                                                                },
                                                                                {
                                                                                    type  : 'bd',
                                                                                    label : 'Nombre',
                                                                                    field : 'nombre'
                                                                                },
                                                                            ],
                                                             sqlParams : {
                                                                            sqlCols : [ 
                                                                                'id',                               
                                                                                'documento',
                                                                                'nombre'                                
                                                                            ],                            
                                                                            fieldSearch : [
                                                                                'documento',
                                                                                'nombre',                                                                            
                                                                            ],                                                                                                                               
                                                                         } 
                                                          },
                                            tooltip : 'Seleccione el responsable de ejecucion',  
                                            validation : '',
                                            required : 'true'                                        
                                        },
                                        {
                                            label : 'Fecha Inicio *',
                                            field : 'fecha_inicio',
                                            type  : 'date',
                                            validation : '',
                                            required : 'true',
                                            tooltip : 'Ingrese la fecha de inicio del contrato'                                        
                                        },
                                        {
                                            label : 'Fecha Vencimiento *',
                                            field : 'fecha_vencimiento',
                                            type  : 'date',
                                            validation : '',
                                            required : 'true',
                                            tooltip : 'Ingrese la fecha de vencimiento del contrato'                                        
                                        },
                                        {
                                            label : 'Objecto del Contrato *',
                                            field : 'objeto_contrato',
                                            type  : 'textarea',
                                            rows  : 4,
                                            validation : '',
                                            required : 'true',
                                            tooltip : 'Ingrese el objeto del contrato'
                                        },                                       
                                        {
                                            label : 'Informacion Pagos',
                                            field : '',
                                            type  : 'separator',
                                            validation : '',
                                            required : ''                                        
                                        },
                                        {
                                            label : 'Plan de Pagos *',
                                            field : 'id_plan_pago',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'true',
                                            apiField : 'terceros_contratos_planes_pagos',
                                            valueName : 'nombre',                                                                                      
                                            sqlParams : {
                                                            sqlCols : [
                                                                'id',
                                                                'nombre'                                
                                                            ],                                                                                                                                                                                                                                                                                         
                                                        },
                                            tooltip : 'Seleccione el plan de pago' 
                                        },
                                        {
                                            label : 'Numero de Pagos *',
                                            field : 'numero_pagos',
                                            type  : 'text',
                                            validation : 'entero',
                                            required : 'true',
                                            tooltip : 'Ingrese el numero de pagos'  
                                        },
                                        fieldResponsable,
                                        fieldCorreo,
                                        {
                                            label : 'Vencimiento Ultimo Pago *',
                                            field : 'vencimiento_ultimo_pago',
                                            type  : 'date',
                                            validation : '',
                                            required : 'true',
                                            tooltip : 'Ingrese la fecha de vencimiento del ultimo pago'                                        
                                        },
                                        {
                                            label : 'Negociacion',
                                            field : '',
                                            type  : 'separator',
                                            validation : '',
                                            required : ''                                        
                                        },
                                        {
                                            label : 'Tasa de Negociacion(%)',
                                            field : 'tasa_negociacion',
                                            type  : 'text',
                                            validation : 'entero',
                                            required : 'false',
                                            tooltip : 'Ingrese la tasa de negociacion'  
                                        },
                                        {
                                            label : 'Renovacion Automatica *',
                                            field : 'renovacion_automatica',
                                            type  : 'select',
                                            validation : '',
                                            required : 'true',
                                            dinamic : 'false',
                                            valueName : 'nombre',
                                            options :  [{
                                                            id: 'true',
                                                            nombre: 'Si',
                                                        },
                                                        {
                                                            id: 'false',
                                                            nombre: 'No', 
                                                        }],
                                            tooltip : 'Especifique si se renueva automaticamente'                                        
                                        },
                                        {
                                            label : 'Observaciones del Contrato *',
                                            field : 'observaciones',
                                            type  : 'textarea',
                                            rows  : 4,
                                            validation : '',
                                            required : 'true',
                                            tooltip : 'Ingrese las observaciones'
                                        },
                                        {
                                            label  : 'Polizas Asociadas',                                                    
                                            field  : 'nombre_archivo',                                                                                                        
                                            type   : 'multi_file',
                                            accept : ".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png",
                                            allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],
                                            maxSize : 3145728,
                                            validation : 'file',
                                            required : '',
                                            tooltip : 'Seleccione las polizas a adjuntar en el contrato'
                                        },
                                        {
                                            label : '',
                                            field : 'id_tercero',
                                            type  : 'hidden',
                                            validation : '',
                                            value : globalState.getState().idTercero.idRow,
                                            required : 'true'                                        
                                        }, 
                                        {
                                            label : '',
                                            field : 'tipo',
                                            type  : 'hidden',
                                            validation : '',
                                            value : globalState.getState().idTercero.tipo,
                                            required : 'true'                                        
                                        }, 
                                    ]}         
                        sqlParams = { sqlParams }
                        automatica="true"                          
                        botonNuevo="true"
                        permisoInsertUpdate={permisoInsert}
                        permisoBtnUpdate={permisoUpdate}
                        permisoDelete="19"                                                                         
                        apiField={'terceros_contratos'}
                        mainContainer='TercerosContratos'
                    /> 
                </div>                
                <Window 
                    id = "windowNotificacionesContratos"                     
                    title='Notificaciones'                   
                    tbar="false"
                    componente="WindowNotificacionesContratos"
                    params="" 
                /> 
                <Window 
                    id = "windowArchivosAdjuntosContratos"                     
                    title='Ver Adjuntos'                   
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                /> 
            </div>                        
        )
    } 
}

export default TercerosContratos