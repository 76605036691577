/**
* CLASS WindowVerAlmacenamiento
*
* Contiene el componente que muestra el espacio de almacenamiento
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Chart from 'react-google-charts';
import globalState from '../configuration/GlobalState';
import Button from 'react-bootstrap/Button';
import {obtenerConsumoAlmacenamiento} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';

class WindowVerAlmacenamiento extends Component {
    constructor(props) {        
        super(props);
        this.state = {            
            espacioTotal : globalState.getState().companyData[0].cuota_almacenamiento,
            espacioOcupado : 0,
            espacioLibre : 0,
            database : globalState.getState().companyData[0].bd
        };   
    }
    componentWillMount(){
        obtenerConsumoAlmacenamiento(this.state.database)
        .then(res => {
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando al sistema de archivos!<br />Codigo de Error: '+res.detail);
            } 
            else{
                let espacioOcupado = res; 
                let espacioDisponible = this.state.espacioTotal-espacioOcupado;    
                if(espacioDisponible < 0){
                    espacioDisponible = 0;
                }           
                this.setState({espacioOcupado : res,espacioLibre : espacioDisponible});                                                   
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    handleBtnAtras(){
        this.props.funcionClick('WindowArchivosAdjuntos');
    }      
  	render() {  
  	  	return (<div>
                    <div style={{paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'220px',overflowY : 'auto' }}>   
                        <Button id="addAttachment" variant="secondary" onClick={this.handleBtnAtras.bind(this)}>ATRAS</Button>   
                        <Chart
                            width={'280px'}
                            height={'170px'}
                            chartType="PieChart"
                            loader={<div>Loading Chart</div>}
                            data={[
                                ['Task', 'Hours per Day'],
                                ['Ocupado', this.state.espacioOcupado*1], 
                                ['Disponible', this.state.espacioLibre*1],                              
                            ]}
                            options={{
                                title: 'Almacenamiento(MB)',
                                chartArea: {
                                    right : 5,
                                    top: 30,
                                    width: "100%",
                                    height: "100%"
                                },
                                legend: {
                                    position: "left",
                                    alignment: "start",
                                    width: "500px",
                                    textStyle: {
                                        color: "000000",
                                        fontSize: 11
                                    }
                                },
                                // Just add this option
                                pieHole: 0.4,
                                is3D: true,
                            }}
                            rootProps={{ 'data-testid': '3' }}
                        />
                    </div>                    
                </div>);
  	}
}

export default WindowVerAlmacenamiento