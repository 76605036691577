/**
* CLASS WindowTrazabilidad
*
* Contiene el componente que guarda la observacion de la recategorizacion
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import configJson,{ diccionario } from '../configuration/configuration.json';
import {divMouseOver,divMouseOut,vacio,modalLoading,validarPermiso} from '../configuration/GlobalFunctions';
import {cargarFilas,rechazarSolucion} from '../api_calls/ApiCalls';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';

const { LabelsWindowTrazabilidad } = diccionario;
var base64 = require('base-64');

class WindowTrazabilidad extends Component {
    constructor(props) {        
        super(props);   
        this.state = {
            resultRows  : [],
            idioma      : globalState.getState().idioma,
            idUsuario   : globalState.getState().userData[0].id,
            updateList  : 0,
            verEncuesta : validarPermiso(27),
            database : globalState.getState().companyData[0].bd
        };  
        this.cargarTrazabilidad();
        //consultar el id del usuario y el estado de la solicitud

    }
    componentDidUpdate(prevProps,prevState){  //refrescar el listado        
        if (this.state.updateList !== prevState.updateList) {
            this.cargarTrazabilidad(); 
        }       
    }
    cargarTrazabilidad(){
        let field = null;
        if(this.props.params.table === 'incidencias'){
            field = "T1.causa";
        }
        let sqlParams = { 
                            sqlCols : [
                                "T1.estado",
                                "T1.observacion",
                                field,
                                "DATE_FORMAT(T1.fecha,'%Y-%m-%d %H:%i:%s') AS fecha",                        
                                "CONCAT(U.primer_nombre,' ',U.primer_apellido) AS usuario",
                                "I.id_usuario AS usuario_solicitud",
                                "I.estado AS estado_solicitud",
                                "I.puntaje_encuesta",
                                "I.tipo_finalizacion",
                                "DATE_FORMAT(I.fecha_finalizacion,'%Y-%m-%d %H:%i:%s') AS fecha_finalizacion"
                            ], 
                            sqlJoin : [
                                "LEFT JOIN users AS U ON (U.id = T1.id_usuario)",
                                "INNER JOIN "+this.props.params.table+" AS I ON(I.id = T1.id_maestro)"  
                            ],                 
                            sqlWhere : [ " AND T1.id_maestro = "+this.props.params.idRow ],
                            sqlOrderBy : "fecha ASC"                                
                        }
        cargarFilas(this.props.params.table+'_seguimientos','',100,0,'','',sqlParams,'rows',this.state.database)      
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowTrazabilidad.error_alert1[this.state.idioma]+': '+response.detail);
            }
            else{               
                this.setState({resultRows : response});
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowTrazabilidad.error_alert1[this.state.idioma]+': '+error);
        })
    }
    handleDiligenciarEncuesta(idRow,tabla){        
        var port = '';
        if(window.location.port > 0){
            port = ':'+window.location.port;
        }
        var path =  window.location.protocol+"//"+window.location.hostname+port;       
        window.open(path+'/'+configJson.mainPath+`encuestaSatisfaccion/`+base64.encode(idRow)+`/`+base64.encode(tabla)+`/`+base64.encode(this.state.database));
    }
    //rechazo de la solicitud  
    handleRechazarSolucion(idRow,tabla){
        let sqlParams = { 
                            sqlCols : [ "estado" ],
                                            
                            sqlWhere : [ " AND id = "+this.props.params.idRow ],                                
                        }
        let idioma = this.state.idioma;
        cargarFilas(this.props.params.table,'',1,0,'','',sqlParams,'rows',this.state.database)      
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', LabelsWindowTrazabilidad.error_alert2[idioma]+': '+response.detail);
            }
            else{               
                if(response[0].estado === 3){
                   alertify.prompt(LabelsWindowTrazabilidad.prompt_alert2[idioma],LabelsWindowTrazabilidad.prompt_alert1[idioma] , ''
                    ,function(evt, value) { 
                        if(vacio(value) === false){
                            alertify.error(LabelsWindowTrazabilidad.prompt_alert3[idioma]); 
                            return;
                        }                    
                        modalLoading(true);
                        rechazarSolucion(value,idRow,tabla,this.state.idUsuario,this.state.database)
                        .then(response => {
                            response = response.data;   
                            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                                modalLoading(false);
                                alertify.alert('Error!', LabelsWindowTrazabilidad.error_alert3[idioma]+': '+response.detail);
                            }
                            else if(response.msg === 'no_mail'){ 
                                modalLoading(false);
                                alertify.alert('Error!', LabelsWindowTrazabilidad.error_alert4[idioma]);                
                            }
                            else if(response.msg === 'mail_error'){ 
                                modalLoading(false);
                                alertify.alert('Error!', LabelsWindowTrazabilidad.error_alert5[idioma]+': '+response.detail);
                            }
                            else{               
                                modalLoading(false);
                                alertify.alert(LabelsWindowTrazabilidad.aviso[idioma],LabelsWindowTrazabilidad.success[idioma]);
                            }
                            this.setState({ updateList : this.state.updateList+1 });
                        })
                        .catch(function (error) {
                            alertify.alert('Error!', LabelsWindowTrazabilidad.error_alert3[idioma]+': '+error);
                        }) 
                    }.bind(this)
                    ,function() { 
                        //...
                    });  
                }
                else{                    
                    this.setState({ updateList : this.state.updateList+1 });
                }
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', LabelsWindowTrazabilidad.error_alert2[idioma]+': '+error);
        })               
    }
    handleVerEncuesta(idRow,table,puntaje){
        this.props.funcionClick('WindowVerEncuesta',{funcionClick:this.props.funcionClick,idRow:idRow,puntaje:puntaje});       
    } 
  	render() { 
        var count = 0; 
  	  	return (<div id="contenedorTrazabilidad" style={{paddingLeft: '7px',paddingTop: '7px',paddingBottom: '5px',height:'370px',overflowY : 'auto' }}>   
                    {
                        this.state.resultRows.map((content,i) => {
                            if(content.estado === 'Solucionado'){                                
                                count++;
                            }
                            return <div key = {i} style={{paddingBottom:'5px'}}>
                                        <div style={{width:'100%',height:'20px'}}>
                                            <div style={{fontWeight:'Bold'}}>{content.estado}</div>
                                        </div>
                                        <div style={{width:'100%',height:'16px'}}>
                                            <div style={{float:'left',fontSize:'11px',width:'50px'}}>{LabelsWindowTrazabilidad.fecha[this.state.idioma]}</div>
                                            <div style={{float:'left',fontSize:'11px'}}> {content.fecha}</div>
                                        </div>
                                        <div style={{width:'100%',height:'16px'}}>
                                            <div style={{float:'left',fontSize:'11px',width:'50px'}}>{LabelsWindowTrazabilidad.usuario[this.state.idioma]}</div>
                                            <div style={{float:'left',fontSize:'11px'}}> {content.usuario}</div>
                                        </div>                                        
                                        <div style={{fontSize:'11px',width:'100%',height:'16px'}}>{LabelsWindowTrazabilidad.observaciones[this.state.idioma]}</div>
                                        <div style={{fontSize:'11px',width:'100%'}}>{content.observacion}</div>
                                        {
                                            content.causa !== '' && content.causa !== null && content.causa !== undefined ?
                                                <div>                                                                                                       
                                                    <div style={{fontSize:'11px',width:'100%',height:'16px'}}>{LabelsWindowTrazabilidad.causa_raiz[this.state.idioma]}</div>
                                                    <div style={{fontSize:'11px',width:'100%'}}>{content.causa}</div>
                                                </div>
                                            :   ''
                                        }
                                        {
                                            this.state.idUsuario === content.usuario_solicitud 
                                            && content.estado_solicitud === 3 && content.estado === 'Solucionado' && count === 1 ?
                                                <div style={{width:'100%',height:'16px',textAlign:'center',marginTop:'5px'}}>                                                                                                       
                                                    <Button id="dataGridBtnNew" variant="primary" onClick={this.handleDiligenciarEncuesta.bind(this,this.props.params.idRow,this.props.params.table)} style={{backgroundColor:configJson.fondoBotonGrilla,height:'26px'}} onMouseOut={divMouseOut.bind(this,'dataGridBtnNew',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'dataGridBtnNew',configJson.fondoBotonGrilla)}>{LabelsWindowTrazabilidad.finalizar[this.state.idioma]}</Button>
                                                    <Button id="formGridBtnDelete" variant="danger" onClick={this.handleRechazarSolucion.bind(this,this.props.params.idRow,this.props.params.table)} style={{marginLeft:'50px',height:'26px'}} onMouseOut={divMouseOut.bind(this,'formGridBtnDelete',"#dc3545")} onMouseOver={divMouseOver.bind(this,'formGridBtnDelete',"#dc3545")}>{LabelsWindowTrazabilidad.rechazar[this.state.idioma]}</Button>
                                                </div>
                                            :   ''
                                        }
                                        {
                                            this.state.verEncuesta && content.estado_solicitud === 5 && content.estado === 'Solucionado' && count === 1 ?
                                                <div style={{width:'100%',height:'16px',textAlign:'center',marginTop:'5px'}}>                                                                                                       
                                                    <Button id="dataGridBtnNew" variant="primary" onClick={this.handleVerEncuesta.bind(this,this.props.params.idRow,this.props.params.table,content.puntaje_encuesta)} style={{backgroundColor:configJson.fondoBotonGrilla,height:'26px'}} onMouseOut={divMouseOut.bind(this,'dataGridBtnNew',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'dataGridBtnNew',configJson.fondoBotonGrilla)}>{LabelsWindowTrazabilidad.survey[this.state.idioma]}</Button>
                                                </div>
                                            :   ''
                                        }
                                   </div>                            
                        })
                    }
                    {
                        this.state.resultRows.length > 0 && this.state.resultRows[0].estado_solicitud === 5 && this.state.resultRows[0].tipo_finalizacion === 'sistema' ?
                            <>
                                <div style={{width:'100%',height:'20px'}}>
                                    <div style={{fontWeight:'Bold'}}>Finalizado</div>
                                </div>
                                <div style={{width:'100%',height:'16px'}}>
                                    <div style={{float:'left',fontSize:'11px',width:'50px'}}>{LabelsWindowTrazabilidad.fecha[this.state.idioma]}</div>
                                    <div style={{float:'left',fontSize:'11px'}}> {this.state.resultRows[0].fecha_finalizacion}</div>
                                </div>
                                <div style={{width:'100%',height:'16px'}}>
                                    <div style={{float:'left',fontSize:'11px',width:'50px'}}>{LabelsWindowTrazabilidad.usuario[this.state.idioma]}</div>
                                    <div style={{float:'left',fontSize:'11px'}}> Sistema</div>
                                </div>                                        
                                <div style={{fontSize:'11px',width:'100%',height:'16px'}}>{LabelsWindowTrazabilidad.observaciones[this.state.idioma]}</div>
                                <div style={{fontSize:'11px',width:'100%'}}>Finalización Automatica segun los SLAs
                                </div>
                            </>
                        : ''
                    }                                                          
                </div>);
  	}
}

export default WindowTrazabilidad