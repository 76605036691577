/**
* CLASS ResetPasswordForm
*
* Contiene el componente para resetear el password despues de dar link al correo
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import configJson from '../configuration/configuration.json';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LogoCorporativo from '../LogoCorporativo/LogoCorporativo';
import {cargarFilas,updatePassword} from '../api_calls/ApiCalls';
import {divMouseOver,divMouseOut,vacio,seguridad_clave,md5} from '../configuration/GlobalFunctions';
import alertify from 'alertifyjs';

var base64 = require('base-64');

class ResetPasswordForm extends Component {
	constructor(props){
		super(props);
		this.state = {
			tokenValid : 'false',
			token : this.props.match.params.token,
			opcion : base64.decode(this.props.match.params.opcion),
			email : base64.decode(this.props.match.params.user),
			database : base64.decode(this.props.match.params.bd),
			passwordActual : '',			
			nuevoPassword : '',
			repitaPassword : '',
			listadoPoliticas : [],
			titulo : '',
			minutosToken : 1440
		}
	}
	componentDidMount(){
		//chequea si el token es valido

		let sqlParams = { 
                            sqlCols : [
                                "COUNT(*) AS cuenta",                     
                            ],                            
                            sqlGroupBy : "activo",
                            sqlWhere : [ " AND email = '"+this.state.email+"' AND token = '"+this.state.token+"'" ],                                
                        }
        cargarFilas('users','',1,0,'','',sqlParams,'rows',this.state.database)
		.then(response => {             
            response = response.data;
            if(response.msg === 'error'){
            	this.setState({tokenValid : 'error'});               
            }
            else if (response.length > 0 && response[0].cuenta > 0){
            	this.setState({tokenValid : 'true' });
            }
            else {
            	this.setState({tokenValid : 'false' });
            }            

            let sqlParams = {  	                            
	                            mode : 'rows'                                                 
	                        }

	      	cargarFilas('politicas_seguridad','',1,0,'','',sqlParams,'rows',this.state.database) 
	      	.then(response => {            
	            response = response.data; 
	            if (response.msg === "error") {
	                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
	            } 
	            else{
	            	this.setState({listadoPoliticas : response });
	            	var titulo = '';
	            	if(response.length > 0){	            		
	            		if(response[0].password_minuscula === 'true'){
			        		titulo += ', minusculas';
			        	}
			        	if(response[0].password_mayuscula === 'true'){
			        		titulo += ', mayusculas';
			        	}
			        	if(response[0].password_numero === 'true'){
			        		titulo += ', numeros';
			        	}
			        	if(response[0].password_simbolo === 'true'){
			        		titulo += ', simbolos';
			        	}
	            		this.setState({minutosToken : response[0].tiempo_token });
			        	

			        	titulo = response[0].password_longitud+' caracteres'+titulo+'.';
	            	}
	            	else{
	            		titulo = '6 caracteres';
	            	}		        	

		        	this.setState({titulo : titulo});
	            }
	        })
	        .catch(function (error) {
	            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
	        });	
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        }); 
	}
	handlePasswordActual(e){
		this.setState({passwordActual : e.target.value }); 		
	}
	handleNuevoPassword(e){
		this.setState({nuevoPassword : e.target.value }); 
	}
	handleRepitaPassword(e){
		this.setState({repitaPassword : e.target.value }); 
	}
	handleResetPasswordButton(){//validacion del password
		if(vacio(this.state.passwordActual) === false && this.state.opcion === 'reset'){
		 	alertify.error('Ingrese el password actual!'); 
		}		
		else if(vacio(this.state.nuevoPassword) === false){
		 	alertify.error('Ingrese el nuevo password!'); 
		}
		else if(vacio(this.state.repitaPassword) === false){
		 	alertify.error('Confirme el nuevo password!'); 
		}
		else if(this.state.nuevoPassword !== this.state.repitaPassword){
			alertify.error('No coinciden los campos!'); 
		}
		else{			
			let listadoPoliticas = this.state.listadoPoliticas;
        	let objectParams = {};
        	if(Object.keys(listadoPoliticas).length > 0) {
        		objectParams.password_longitud  = listadoPoliticas[0].password_longitud;
        		objectParams.password_mayuscula = listadoPoliticas[0].password_mayuscula;
        		objectParams.password_minuscula = listadoPoliticas[0].password_minuscula;
        		objectParams.password_numero    = listadoPoliticas[0].password_numero;
        		objectParams.password_simbolo   = listadoPoliticas[0].password_simbolo; 
        	}
        	else{
        		objectParams.password_longitud  = 6;
        		objectParams.password_mayuscula = 'false';
        		objectParams.password_minuscula = 'false';
        		objectParams.password_numero    = 'false';
        		objectParams.password_simbolo   = 'false';
        	}   	
        	
        	let aprobacion_clave = seguridad_clave(this.state.nuevoPassword,objectParams);
        	if(aprobacion_clave === 'true'){	            		
        		updatePassword(this.state.email,md5(this.state.nuevoPassword),md5(this.state.passwordActual),this.state.opcion,this.state.database).then(response => {             
		            response = response.data;		            
		            if(response.msg === 'success'){
		            	this.setState({tokenValid : 'update_password' });
		            } 
		            else if(response.msg === 'no_coinciden'){
		            	alertify.alert('Error!', 'El password actual es erroneo!');
		            } 
		            else if(response.msg === 'mismo_password'){
		            	alertify.alert('Error!', 'Debe ingresar un password diferente al actual!');
		            }
		            else {
		            	this.setState({tokenValid : 'error_password'});               
		            }                     
		        })
		        .catch(function (error) {
		            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
		        });
        	}
        	else{
        		let titulo = '';
        		if(aprobacion_clave === 'longitud'){
        			titulo = 'minimo '+objectParams.password_longitud+' caracteres';
        		}
        		else{
        			titulo = aprobacion_clave;
        		}
        		alertify.error('La clave debe contener '+titulo); 
        	}        			
		}		
	}
	handleAbrirAplicativo(){
		var port = '';
        if(window.location.port > 0){
            port = ':'+window.location.port;
        }
		var path =  window.location.protocol+"//"+window.location.hostname+port; 
		window.location.href = path+'/'+configJson.mainPath; 
	}
	render() {  
		var token = this.state.token;
		var date  = base64.decode(token);
		//valida que ya venga numerico
		if(!isNaN(date)){			
			date = date*1;
		}			
		date = new Date(date);		
		date = date.getTime();
        //vigencia del token
        var currentDate = new Date().getTime();        
		var dateDiff    = currentDate-date;		
		var seconds     = dateDiff/1000;		

		//cuerpo del formulario
		return <div style={{background:'#efefef',width:'100%',height:'100%',overflow:'hidden'}}>
                    <div className="adM">
                    </div>
                    <LogoCorporativo database={this.state.database}/>
                    <div style={{width:'calc(100% - 60px)',maxWidth:'600px',height:'auto',padding:'20px',margin:'20px auto',backgroundColor:'#fff',borderRadius:'5px'}}>
                    {
                   		(this.state.tokenValid === 'error_password') ?
                   			<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>Ha ocurrido un error actualizando el password. <br /><br />Por favor intente de nuevo.</h1> 
                   		:(this.state.tokenValid === 'update_password') ?
                   			<div>
	                   			<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>
	                   				Se ha actualizado el password satisfactoriamente!.
	                   			</h1> 
	                   			<Form.Group controlId="botonAbrirPagina" style={{height:'50px',marginTop:'20px',marginBottom:'0px'}}>
	                                <Button id="formGridBtnAbrirPagina" style={{padding:'10px 20px',margin: '5px 0',backgroundColor:configJson.fondoBotonGrilla}} className="float-left mr-3" variant="primary" onClick={this.handleAbrirAplicativo.bind(this)} onMouseOut={divMouseOut.bind(this,'formGridBtnAbrirPagina',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'formGridBtnAbrirPagina',configJson.fondoBotonGrilla)}>
				  	      				 IR A LA APLICACION
				  	    			</Button>
				  	    		 </Form.Group> 
	                        </div>
                   		:(this.state.tokenValid === 'error') ?
                   			<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>Ha ocurrido un error accesando a la base de datos. <br /><br />Por favor intente de nuevo.</h1> 
               			:(this.state.tokenValid === 'false') ?
                   			<h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>EL token no coincide con la base de datos, debe realizar de nuevo el proceso!.</h1>
                   		:(seconds < (this.state.minutosToken*60)) ?
		                   	  <div>
		                   		  <h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>Restablecer su password</h1>
		                          <hr style={{margin:'0 0 20px 0'}} />
		                          <div style={{fontFamily:'Roboto,sans-serif',fontSize:'14px',color:'#666'}}>Por favor escriba el nuevo password para su cuenta {this.state.email}.<br /><br />Recuerda que debe tener minimo {this.state.titulo} </div>
		                          <div style={{marginTop:'40px'}}>
		                          	  <Form>
		                          	  	  {
		                          	  	  	  this.state.opcion === 'reset' ?
		                          	  	  	  	<Form.Group controlId="formBasicActualPwd">
		                                            <Form.Label title="Contraseña actual de su usuario">Password Actual *</Form.Label>
			                                            <Form.Control name = "passwordActual" type="password" onChange={this.handlePasswordActual.bind(this)} value={this.state.passwordActual} />                               
			                                        </Form.Group>
			                                  : ''
		                          	  	  }	
			                          	  <Form.Group controlId="formBasicResetPwd">
	                                            <Form.Label title="Nueva contraseña de su usuario">Nuevo Password *</Form.Label>
	                                            <Form.Control name = "nuevoPassword" type="password" onChange={this.handleNuevoPassword.bind(this)} value={this.state.nuevoPassword}/>                               
	                                      </Form.Group>
	                                      <Form.Group controlId="formBasicConfResetPwd">
	                                            <Form.Label title="Confirmacion de su contraseña">Repita Password *</Form.Label>
	                                            <Form.Control name = "repitaPassword" type="password" onChange={this.handleRepitaPassword.bind(this)} value={this.state.repitaPassword}/>                               
	                                      </Form.Group>
	                                      <Form.Group controlId="formBasicResetPwd" style={{height:'50px',marginTop:'20px',marginBottom:'0px'}}>
		                                      <Button id="formGridBtnSave" style={{padding:'10px 20px',margin: '5px 0',backgroundColor:configJson.fondoBotonGrilla}} className="float-left mr-3" variant="primary" onClick={this.handleResetPasswordButton.bind(this)} onMouseOut={divMouseOut.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)}>
	    				  	      					NUEVO PASSWORD
	    				  	    			  </Button>
	    				  	    		  </Form.Group>  
	                                  </Form>		                              
		                              <div className="yj6qo"></div>
		                              <div className="adL">
		                              </div>
		                          </div>
		                          <div className="adL">
		                          </div>
		                   	  </div>
	                    : <h1 style={{fontFamily:'Roboto,sans-serif',fontSize:'30px'}}>La solicitud para restablecer el password caduco. <br /><br />Por favor realice de nuevo el proceso.</h1>
                    } 
                    </div>
                    <div className="adL">
                    </div>
                </div>;  	  	
  	  	
    }
}   

export default ResetPasswordForm              