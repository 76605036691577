/**
* CLASS WindowConocimientoPorPublicar
*
* Contiene el componente que visualiza la solucion y permite al usuario puntuarla
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import {cargarFilas,guardarReputacionConocimiento} from '../api_calls/ApiCalls';
import Button from 'react-bootstrap/Button';
import configJson from '../configuration/configuration.json';
import {divMouseOver,divMouseOut} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import star_inactive from '../../images/star_inactive.png?v1.0';
import star_active from '../../images/star_active.png?v1.0';
import alertify from 'alertifyjs';
import './conocimiento.css'; 

const componentList = {
      star_inactive  : star_inactive,
      star_active : star_active,
}

class WindowConocimientoPorPublicar extends Component {
    constructor(props) {        
        super(props);   
        this.state = {           
            textoSolucion    : "",
            habilitarPuntaje : "true",
            star1 : 'star_inactive',
            star2 : 'star_inactive',
            star3 : 'star_inactive',
            star4 : 'star_inactive',
            star5 : 'star_inactive', 
            puntaje : 0,
            database : globalState.getState().companyData[0].bd           
        };

    }
    componentWillMount(){
        var idUsuario = globalState.getState().userData[0].id;                 
        let sqlParams = { 
                            sqlCols : [
                                "T1.solucion",
                                "T1.reputacion",
                                "T1.id_tecnico",
                                "R.id"              
                            ],
                            sqlJoin : [
                                'LEFT JOIN conocimiento_reputacion AS R ON (T1.id = R.id_conocimiento AND R.id_usuario = '+idUsuario+')',                                
                            ],                                                  
                            sqlWhere : [ " AND T1.id = "+this.props.params.idRow ],                                
                        }
        cargarFilas('conocimiento','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{                
                this.setState({textoSolucion : response[0].solucion});                
                if(response[0].id > 0 || response[0].id_tecnico === idUsuario){                    
                    this.setState({habilitarPuntaje : "false"});
                }
            }     
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })
    }        
    handlePuntaje(num,e){
        //activacion/desactivacion estrellas
        for(var i=num;i>0;i--){            
            this.setState({['star'+i] : 'star_active'});
        }
        for(i=num;i<5;i++){            
            this.setState({['star'+(i+1)] : 'star_inactive'});
        }
        this.setState({puntaje : num});        
    } 

    handleconfirmGuardar(){
        if(this.state.puntaje === 0){
            alertify.alert('Error!', 'Seleccione un puntaje!');
            return;
        }
        alertify.confirm('Confirmacion', 'Esta seguro(a) del puntaje indicado? Esta accion no se puede deshacer', this.handleGuardarBoton.bind(this), function(){});
    }
    handleGuardarBoton(){        
        var idUsuario = globalState.getState().userData[0].id;
        guardarReputacionConocimiento(this.state.puntaje,this.props.params.idRow,idUsuario,this.state.database).then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else if(response.msg === 'success'){ 
                this.props.params.funcionClick('WelcomePage');
                this.props.params.funcionClick('Conocimiento');
                alertify.alert('Aviso!', 'Puntaje Almacenado!');
            }  
            this.setState({habilitarPuntaje : 'false'});   
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        }) 
    }  
  	render() {                         
  	  	return (
                <div style={{padding:'5px'}}>
                    <div style={{padding:'5px',height:'120px',fontSize:'11px',overflowY:'auto'}}>
                        {this.state.textoSolucion}
                    </div>
                    {
                        this.state.habilitarPuntaje === 'true' ?
                        <div style={{padding:'5px'}}>   
                            <div style={{fontWeight:'bold'}}>Puntuacion de la Solucion:</div>
                            <div style={{textAlign:'center',height:'50px',width:'250px',marginLeft:'-6px'}}>
                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,1)}>
                                    <img src={componentList[this.state.star1]} alt={this.state.star1} />                                 
                                </div>
                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,2)}>
                                    <img src={componentList[this.state.star2]} alt={this.state.star2} />                                 
                                </div>
                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,3)}>
                                    <img src={componentList[this.state.star3]} alt={this.state.star3} />                                 
                                </div>
                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,4)}>
                                    <img src={componentList[this.state.star4]} alt={this.state.star4} />                                 
                                </div>
                                <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,5)}>
                                    <img src={componentList[this.state.star5]} alt={this.state.star5} />                                 
                                </div>                            
                            </div>
                            <Button id="botonGuardarPuntaje" variant="primary" onClick={this.handleconfirmGuardar.bind(this)} style={{backgroundColor:configJson.fondoBotonGrilla}} onMouseOut={divMouseOut.bind(this,'botonGuardarPuntaje',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'botonGuardarPuntaje',configJson.fondoBotonGrilla)}>GUARDAR</Button>
                        </div>
                        : ''
                    }
                </div>  				
			  );
  	}
}

export default WindowConocimientoPorPublicar