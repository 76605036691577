/**
* CLASS DashboardActivosOption
*
* Contiene el contenedor de las opciones de panel de control
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import {divMouseOver,divMouseOut} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import {cargarFilas} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import './activos.css';

class DashboardActivosOption extends Component {
    constructor(props){
        super(props);        
        this.state={ 
            result : {
                "total" : 0,
                "sin_asignar" : 0,
                "asignado" : 0,
                "prestamo" : 0,
                "trasladado" : 0,
                "reparacion" : 0,
                "garantia" : 0,
                "inactivo" : 0,
                "dado_baja" : 0
            },
            database : globalState.getState().companyData[0].bd  
        };
    }
    componentWillMount(){
        let sqlParams = {
                            sqlCols : [
                                'COUNT(id) AS total',
                                "SUM(IF(estado='1',1,0)) AS 'sin_asignar'", 
                                "SUM(IF(estado='2',1,0)) AS 'asignado'",
                                "SUM(IF(estado='3',1,0)) AS 'prestamo'",                                                                                                                                      
                                "SUM(IF(estado='4',1,0)) AS 'trasladado'",  
                                "SUM(IF(estado='5',1,0)) AS 'reparacion'",
                                "SUM(IF(estado='6',1,0)) AS 'garantia'",
                                "SUM(IF(estado='7',1,0)) AS 'inactivo'",
                                "SUM(IF(estado='8',1,0)) AS 'dado_baja'",                                
                                "id_tipo"
                            ],                            
                            sqlWhere : [
                                ' AND id_tipo='+this.props.idTipo
                            ],
                            sqlGroupBy : "id_tipo",
                            sqlOrderBy : "id_tipo"                                                                                                                                                          
                        } 
        cargarFilas('activos','',1,0,'','',sqlParams,'rows',this.state.database)
        .then(res => {
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+res.detail);
            } 
            else{
                if(res.length > 0){
                    this.setState({result:res[0]}); 
                }
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    handleContainerChange(){//Un callback para cargar el datagrid
        globalState.dispatch({
            type   : "opcionDashboardActivos",
            params : {
                idTipo : this.props.idTipo,
                titulo : this.props.titulo
            }
        })
        this.props.funcionClick('Activos');        
    }
    render() {        
        var icono = this.props.icono;
        if(!this.props.icono || this.props.icono === ''){
            icono = 'computer';
        }        
        return (
            <div>
            {                
                <li id={this.props.tab} onClick={this.handleContainerChange.bind(this)} onMouseOut={divMouseOut.bind(this,this.props.tab,'#FFFFFF')} onMouseOver={divMouseOver.bind(this,this.props.tab,'#c6c6c6')} >
                    <div className="tituloActivos">{this.props.titulo+'('+this.state.result.total+')'}</div>
                    <div className="icono">
                        <MaterialIcon id="dashIcon" size={50} icon={icono} />
                        <div className="divEstados">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="filaEstados">Sin Asignar</td><td className="filaEstados">({this.state.result.sin_asignar})</td>
                                    </tr>
                                    <tr>
                                        <td className="filaEstados">Asignado</td><td className="filaEstados">({this.state.result.asignado})</td>
                                    </tr>
                                    <tr>
                                        <td className="filaEstados">Prestamo</td><td className="filaEstados">({this.state.result.prestamo})</td>
                                    </tr>
                                    <tr>
                                        <td className="filaEstados">Trasladado&nbsp;</td><td className="filaEstados">({this.state.result.trasladado})</td>
                                    </tr>
                                    <tr>
                                        <td className="filaEstados">Reparacion</td><td className="filaEstados">({this.state.result.reparacion})</td>
                                    </tr>
                                    <tr>
                                        <td className="filaEstados">Garantia</td><td className="filaEstados">({this.state.result.garantia})</td>
                                    </tr>
                                    <tr>
                                        <td className="filaEstados">Inactivo</td><td className="filaEstados">({this.state.result.inactivo})</td>
                                    </tr>
                                    <tr>
                                        <td className="filaEstados">Dado de Baja</td><td className="filaEstados">({this.state.result.dado_baja})</td>
                                    </tr>
                                </tbody>
                            </table>                            
                        </div>
                    </div>                    
                </li>                 
            }
            </div>                  
        );
    }
}

export default DashboardActivosOption
