/**
* CLASS DocumentTypes
*
* Contiene el contenedor principal de tipos de documento
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import TBar from '../tbar/TBar';
import globalState from '../configuration/GlobalState';
import {cargarFilas} from '../api_calls/ApiCalls';

class DocumentTypes extends Component {
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }

    async validacionDeleteDocument(id){
        try{
            // Validar que no hayan usuarios con el tipo de documento
            let database = globalState.getState().companyData[0].bd;
            let sqlParams = {
                                sqlCols : [
                                    "COUNT(id) AS total"                                             
                                ],                            
                                sqlWhere : [
                                    " AND id_tipo_documento = "+id//no cuenta superusuarios
                                ],                                     
                            }; 
            let res = await cargarFilas('users','',1000,0,'','',sqlParams,'rows',database);
            let response = res.data; 
            if(response.length > 0 && response[0].total > 0){
                return {  status : false, message : "El tipo de documento no se puede eliminar, tiene usuarios activos vinculados!" }; 
            }
            
            return {  status : true, message : "" }; 
               
        }           
        catch(err){
            return {  status : false, message : 'No se ha logrado la conexion con el servidor!<br />'+err.message }; 
        }     
    }

  	render() {     
        let sqlParams = { 
                            fieldSearch : [
                                'nombre',                                
                            ],                                                        
                            filtroFechas : "false",                                                 
                        };                 
        return (//carga el componente que contiene la grilla de datos 
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>            
                    <DataGrid titulo='Típos de Documento' 
                              funcionClick={this.props.funcionClick}
                              funcionBeforeDelete={this.validacionDeleteDocument.bind(this)} 
                              parametro={this.props.parametro}
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Nombre',
                                                field : 'nombre'
                                            },
                                        ]} 
                              sqlParams = { sqlParams }
                              automatica="true"
                              botonNuevo="true"
                              formFields={[
                                            {
                                                label : 'Nombre *',
                                                field : 'nombre',
                                                type  : 'text',
                                                validation : 'mayusculas',
                                                required : 'true',
                                                tooltip : 'Ingrese el nombre del tipo de documento',                                               
                                            }                                    
                                        ]}                     
                              apiField={'document_types'}
                              mainContainer='DocumentTypes'/> 
                </div>
            </div>             
        )
    } 
}

export default DocumentTypes