/**
* CLASS DataGridRow
*
* Contiene una fila del datagrid
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React from 'react';
import {detectaDispostivo} from '../configuration/GlobalFunctions';
import MaterialIcon from 'material-icons-react';

class DataGridRow extends React.Component {    
    handleEditButton(param){//boton editar           
        if(this.props.enableRowClick === 'false'){
            //......no hace nada en el doble click
        }
        else if(this.props.automatica === 'true'){
            this.props.funcionClick('FormDataGrid',{ 
                                                        idRow:param,
                                                        mainContainer:this.props.mainContainer,
                                                        titulo:this.props.titulo,
                                                        apiField:this.props.apiField,
                                                        apiRoute:this.props.apiRoute,
                                                        formFields:this.props.formFields,
                                                        enableBtnEdit:this.props.enableBtnEdit,
                                                        enableBtnDel:this.props.enableBtnDel,
                                                        permisoBtnUpdate:this.props.permisoBtnUpdate,                                                        
                                                        tbarEdit : this.props.tbarEdit,
                                                        tbarItems : this.props.tbarItems,
                                                        funcionAfterUpdate : this.props.funcionAfterUpdate,
                                                        funcionBeforeDelete : this.props.funcionBeforeDelete,
                                                        dataLog : this.props.dataLog
                                                    });       
        } 
        else {     
            this.props.funcionEdit(param,this.props.funcionEditParams);            
        }
    }    
    handleNothing(){
        //...
    }
    render() {         
        var os = detectaDispostivo().os.toLowerCase();        
        var dataRow = this.props.dataRow;   
        var onClick = "";
        var field = "";
        var onDblClick = "";

        if(os === 'android' || os === 'ios'){           
            onClick = this.handleEditButton.bind(this,dataRow);
            onDblClick = this.handleNothing.bind(this);
        } 
        else{            
            onClick = this.handleNothing.bind(this);
            onDblClick = this.handleEditButton.bind(this,dataRow);
        }       
        let icono='';        
        let columnaIcon  = '';
        let componente = '';
        return(//carga las celdas con los datos y adiciona los botones de editar y eliminar
            <tr style={{ cursor:'pointer'}}>      
                <td style={{fontSize:'12px'}} onClick={ onClick } onDoubleClick={ onDblClick }>{this.props.numberRow+1}</td> 
                {
                    this.props.colsData.map((colsData,i) => {
                        field = null;
                        if(colsData.type === 'bd' && !colsData.onlyXLS){
                            field = <td data-title={colsData.label} key={ i } style={{fontSize:'12px'}} onClick={ onClick } onDoubleClick={ onDblClick }>{dataRow[colsData.field]}&nbsp;</td>
                        }
                        else if(colsData.type === 'bd_html'){
                            field = <td data-title={colsData.label} key={ i } style={{fontSize:'12px'}} onClick={ onClick } onDoubleClick={ onDblClick }><div dangerouslySetInnerHTML={{__html: dataRow[colsData.field]}}></div></td>
                        }
                        else if(colsData.type === 'manual'){
                            icono = colsData.icon;
                            if(icono === ''){
                                icono = dataRow[colsData.field];
                            }
                            
                            if(os === 'android' || os === 'ios'){
                                componente = <div style={{width:'30px'}}></div>
                            }                                                        
                            columnaIcon = <>{columnaIcon}<div onClick={colsData.colFuncion.bind(this,dataRow.id)} style={{cursor:'pointer'}} ><MaterialIcon id="iconColumna" size={19} icon={icono} /></div>{componente}</>
                        }
                        else{
                            return null;
                        }
                        if(i+1 === this.props.colsData.length && columnaIcon !== ''){
                            field = <td data-title={colsData.label} key={ i } style={{fontSize:'12px',display:'flex'}}>{columnaIcon}</td>
                        }                        
                        return field; 
                    })
                }    
            </tr>
        )
    }
}

export default DataGridRow