/**
* CLASS WindowConfigurarCampo
*
* Contiene el componente que configura los campos select de la ficha tecnica
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../../data_grid/DataGrid';
import globalState from '../../configuration/GlobalState';

class WindowConfigurarCampo extends Component {
    render() {
        let idCampo = globalState.getState().idActivoCampoFicha;
        let sqlParams = {                             
                            sqlCols : [
                                "id",
                                "id_campo",
                                "nombre",                                
                            ],
                            fieldSearch : [
                                'nombre',                                
                            ],                            
                            sqlWhere : [
                                ' AND id_campo='+idCampo
                            ]                                                                       
                        };                  
  	  	return (
                <div style={{top: "-12px",position:"relative"}}>  
                    <DataGrid titulo='Valores Campo' 
                              funcionClick={this.props.funcionClick}   
                              parametro={this.props.parametro} 
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Valor',
                                                field : 'nombre'
                                            },                                                                                                                                       
                                        ]}
                              sqlParams = { sqlParams }                       
                              automatica="true"
                              botonNuevo="true"
                              formFields={[
                                            {
                                                label : 'Nombre *',
                                                field : 'nombre',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                required : 'true',
                                                longitud : 30,
                                                tooltip : 'Ingrese el nombre del campo'
                                            },
                                            {
                                                label : '',
                                                field : 'id_campo',
                                                type  : 'hidden',
                                                validation : '',
                                                value : idCampo,
                                                required : 'true'                                        
                                            },                                                                              
                                        ]}                     
                              apiField={'activos_tipos_campos_ficha_valores'}
                              mainContainer='WindowConfigurarCampo'/> 
                </div>  				
			  );
  	}
}

export default WindowConfigurarCampo