/**
* CLASS Conocimiento
*
* Contiene el contenedor principal de tipos de documento
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../data_grid/DataGrid';
import Window from '../window/Window';
import TBar from '../tbar/TBar';
import globalState from '../configuration/GlobalState';
import { diccionario } from '../configuration/configuration.json';
import {validarPermiso} from '../configuration/GlobalFunctions';

const { LabelsConocimiento } = diccionario;

class Conocimiento extends Component {
    constructor(props){
        super(props);         
        let opcionConocimiento = globalState.getState().opcionConocimiento.opcion;
        let permisoPublicaciones = 'none';
        if(validarPermiso(30) === true && opcionConocimiento === 'incidencia'){
            permisoPublicaciones = 'block';                    
        }
        this.state = {
           permisoPublicaciones : permisoPublicaciones,
           opcionConocimiento : opcionConocimiento,              
           permisoInsertUpdate : globalState.getState().opcionConocimiento.permisoInsertUpdate,
           permisoDelete : globalState.getState().opcionConocimiento.permisoDelete        
        };            
    }
    ventanaPublicaciones(){       
        let idUsuario = globalState.getState().userData[0].id;        
        globalState.dispatch({
            type   : "windowConocimientoPorPublicar",
            params : {
                          visible : true,
                          params  : {                                       
                                        idUsuario : idUsuario,                                        
                                        idWin : "windowConocimientoPorPublicar",//identificador de la ventana
                                        width : '600px', 
                                        height: '270px'                                       
                                    }
                     }
        }); 
    }
    retrocederPanel(){
        this.props.funcionClick('SeleccionConocimiento');
    }
    colFuncion(idRow){
        globalState.dispatch({
            type   : "windowVerSolucion",
            params : {
                          visible : true,
                          params  : {
                                        idRow : idRow,
                                        funcionClick : this.props.funcionClick,
                                        idWin : "windowVerSolucion",//identificador de la ventana
                                        width:'300px', 
                                        height:'270px'
                                    }
                     }
        });
    }
    colFuncion2(idRow){
        globalState.dispatch({
                    type   : "windowAdjuntosConocimiento",
                    params : {
                                  visible : true,
                                  params  : {
                                                idRow   : idRow, 
                                                table   : 'conocimiento',
                                                maxSize : 10245760,
                                                allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],                                           
                                                idWin   : "windowAdjuntosConocimiento",
                                                width   : '300px', 
                                                height  : '250px',
                                                bloqueo : 'usuario'
                                            }
                             }
                }); 
    }
  	render() {
        let where  = '';        
        let externo = globalState.getState().userData[0].externo;
        let idioma = globalState.getState().idioma;
        let titulo = LabelsConocimiento.TitleDefault[idioma];
        let topGrilla = '0px';
        let enableRowClick = 'false';
        if(this.state.opcionConocimiento !== 'usuario'){
            where  = "AND T1.tipo_solicitud = '"+this.state.opcionConocimiento+"'";
            titulo = LabelsConocimiento.Title[idioma]+LabelsConocimiento[this.state.opcionConocimiento][idioma];
            topGrilla = '60px';
            enableRowClick = "true";
        }
        let idArea = globalState.getState().userData[0].id_area;
        let whereArea = " AND (T1.privado = 'false' OR T1.privado='true' AND T1.id_area = "+idArea+")";
        //validar las areas de servicio habilitadas
        let whereArea1 = " AND (A.tipo='todas' OR A.tipo='interna')";        
        if(externo === 'true'){
            whereArea1 = " AND (A.tipo='todas' OR A.tipo='externa')";
        }
        //si tiene el permiso podrá ver todas las areas de servicio
        if(validarPermiso(34)){
            whereArea  = "";
            whereArea1 = "";
        } 
        let sqlParams = { 
                            fieldSearch : [
                                'T1.tema', 
                                'AC.nombre',
                                'A.nombre',
                                'AC1.nombre'
                            ],
                            sqlCols : [ 
                                "IF(T1.privado='true','Si','No') AS nom_privado",
                                'T1.id',
                                'T1.tema',
                                'T1.id_area',
                                'T1.privado',
                                'A.nombre AS area',
                                'T1.id_categoria',
                                'AC.nombre AS categoria',
                                'T1.id_subcategoria', 
                                'AC1.nombre AS subcategoria',                              
                                'T1.tipo_solicitud', 
                                'T1.solucion', 
                                'T1.reputacion', 
                                'R.html AS stars'
                            ],                          
                            sqlJoin : [
                                'LEFT JOIN reputacion_estrellas AS R ON(R.puntaje = T1.reputacion)',
                                'INNER JOIN areas_servicio AS A ON (A.id = T1.id_area'+whereArea1+')',
                                'INNER JOIN areas_servicio_categorias AS AC ON (AC.id = T1.id_categoria)',
                                'INNER JOIN areas_servicio_subcategorias AS AC1 ON (AC1.id = T1.id_subcategoria)', 
                            ],
                            sqlWhere : [
                                " AND aprobacion = 'true' "+where+whereArea
                            ],                                                         
                            sqlOrderBy : 'reputacion DESC',                          
                            filtroFechas : "false",                                                                                                       
                        };
        //columna de icono
        let colIcon = '';
        if(this.state.opcionConocimiento !== 'usuario'){
            colIcon  =  {
                            type  : 'bd',
                            label : LabelsConocimiento.ColLabel6[idioma],
                            field : 'nom_privado'
                        };
        }
        return (//carga el componente que contiene la grilla de datos 
            <div>
                {
                    this.state.opcionConocimiento !== 'usuario' ?
                        <TBar
                            items={[
                                      {
                                          type : 'boton',
                                          icon : 'history',
                                          width : '100px',
                                          height : '80px',
                                          title : 'Publicaciones',
                                          display : this.state.permisoPublicaciones,
                                          function : this.ventanaPublicaciones.bind(this)
                                      },
                                      {
                                          type : 'boton',
                                          icon : 'arrow_back',
                                          width : '100px',
                                          height : '60px',
                                          title : LabelsConocimiento.LabelBtnAtras[idioma],
                                          display : true,
                                          function : this.retrocederPanel.bind(this)
                                      },
                                  ]}
                            length = '1'
                        />
                    : ''
                }                
                <div style={{top: topGrilla,position:"relative"}}>            
                    <DataGrid titulo={titulo} 
                              funcionClick={this.props.funcionClick} 
                              parametro={this.props.parametro}
                              colsData={[   
                                            {
                                                type  : 'bd',
                                                label : LabelsConocimiento.ColLabel1[idioma],
                                                field : 'area'
                                            }, 
                                            {
                                                type  : 'bd',
                                                label : LabelsConocimiento.ColLabel2[idioma],
                                                field : 'categoria'
                                            },
                                            {
                                                type  : 'bd',
                                                label : LabelsConocimiento.ColLabel3[idioma],
                                                field : 'subcategoria'
                                            },
                                            {
                                                type  : 'bd',
                                                label : LabelsConocimiento.ColLabel4[idioma],
                                                field : 'tema'
                                            },
                                            colIcon,
                                            {
                                                type  : 'bd_html',
                                                label : LabelsConocimiento.ColLabel5[idioma],
                                                field : 'stars'
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'find_in_page',
                                                colFuncion : this.colFuncion.bind(this)
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'attach_file',
                                                colFuncion : this.colFuncion2.bind(this)
                                            }
                                        ]} 
                              sqlParams = { sqlParams }
                              automatica="true"
                              enableRowClick = {enableRowClick}
                              botonNuevo="true"
                              formFields={[
                                            {
                                                label : 'Area de Servicio *',
                                                field : 'id_area',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'true',
                                                apiField : 'areas_servicio',
                                                valueName : 'nombre',
                                                updateTo : 'id_categoria',
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],
                                                                sqlWhere : [ " AND atencion_"+this.state.opcionConocimiento+"s='true'" ],                                                                                                                                                                                                                               
                                                            },
                                                tooltip : 'Seleccione el area de servicio',
                                                blockEdit : "true"                                            
                                            },
                                            {
                                                label : 'Categoria *',
                                                field : 'id_categoria',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'true',
                                                apiField : 'areas_servicio_categorias',
                                                valueName : 'nombre',                                       
                                                updateTo : 'id_subcategoria',
                                                fieldUpdate : 'id_area', 
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],
                                                                sqlWhere : [ " AND atencion_"+this.state.opcionConocimiento+"s='true'" ],                            
                                                            },
                                                tooltip : 'Seleccione la categoria de la incidencia' 
                                            },
                                            {
                                                label : 'Tema *',
                                                field : 'tema',
                                                type  : 'text',
                                                validation : '',
                                                required : 'true',
                                                longitud : 60,
                                                tooltip : 'Ingrese el tema del conocimiento'
                                            }, 
                                            {
                                                label : 'Subcategoria *',
                                                field : 'id_subcategoria',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'true',
                                                apiField : 'areas_servicio_subcategorias',
                                                valueName : 'nombre',
                                                fieldUpdate : 'id_categoria',                                        
                                                sqlParams : {
                                                                sqlCols : [
                                                                    'id',
                                                                    'nombre'                                
                                                                ],                                                                                                                                                                                                                               
                                                            },
                                                tooltip : 'Seleccione la Subcategoria' 
                                            },
                                            {
                                                label : 'Privado *',
                                                field : 'privado',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: 'false',
                                                                nombre: 'No',
                                                            },
                                                            {
                                                                id: 'true',
                                                                nombre: 'Si', 
                                                            }],
                                                tooltip : 'Especifique si es privado'                                        
                                            },                                            
                                            {
                                                label : 'Descripcion *',
                                                field : 'solucion',
                                                type  : 'textarea',
                                                rows  : 4,
                                                validation : '',
                                                required : 'true',
                                                tooltip : 'Ingrese la descripcion del conocimiento'
                                            }, 
                                            {
                                                label  : 'Adjunto',                                                    
                                                field  : 'nombre_archivo',                                                                                                        
                                                type   : 'multi_file',
                                                accept : ".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png",
                                                allowedExt : [".pdf",".doc",".docx",".xls",".xlsx",".jpg",".jpeg",".png"],
                                                maxSize : 10245760,
                                                validation : 'file',
                                                required : '',
                                                tooltip : 'Seleccione los archivos a adjuntar en el conocimiento'
                                            },                                            
                                            {
                                                label : '',
                                                field : 'id_tecnico',
                                                type  : 'hidden',
                                                validation : '',
                                                value : globalState.getState().userData[0].id,
                                                required : 'true'                                        
                                            }, 
                                            {
                                                label : '',
                                                field : 'tipo_solicitud',
                                                type  : 'hidden',
                                                validation : '',
                                                value : this.state.opcionConocimiento,
                                                required : 'true'                                        
                                            }, 
                                            {
                                                label : '',
                                                field : 'aprobacion',
                                                type  : 'hidden',
                                                validation : '',
                                                value : 'true',
                                                required : 'true'                                        
                                            },                                      
                                        ]}                     
                              apiField={'conocimiento'}
                              permisoInsertUpdate={this.state.permisoInsertUpdate}
                              permisoBtnUpdate={this.state.permisoInsertUpdate}
                              permisoDelete={this.state.permisoDelete}
                              mainContainer='Conocimiento'/> 
                </div>
                <Window 
                    id = "windowConocimientoPorPublicar"                      
                    title='Publicaciones Pendientes'                    
                    tbar="false"
                    componente="WindowConocimientoPorPublicar"
                    params="" 
                /> 
                <Window 
                    id = "windowVerSolucion"                      
                    title='Ver Solucion'                    
                    tbar="false"
                    componente="WindowVerSolucion"
                    params="" 
                />
                <Window 
                    id = "windowAdjuntosConocimiento"                     
                    title='Ver Documentos'                   
                    tbar="false"
                    componente="WindowArchivosAdjuntos"
                    params="" 
                /> 
                <Window 
                    id = "windowUploadArchivo"                      
                    title='Subir Adjunto'                    
                    tbar="false"
                    componente="WindowUploadArchivo"
                    params="" 
                />
            </div>             
        )
    } 
}

export default Conocimiento