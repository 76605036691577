/**
* CLASS Option
*
* Contiene el componente opcion de la barra de menu del escritorio
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import MaterialIcon from 'material-icons-react';
import {divMouseOver,divMouseOut,validarPermiso,validarModulo} from '../configuration/GlobalFunctions';
import configJson,{ diccionario } from '../configuration/configuration.json';
import globalState from '../configuration/GlobalState';
import '../../css/alertify.css';
import './desktop.css';

const { LabelsOptionMenu } = diccionario;

class Option extends Component {
    constructor(props){
        super(props);        
        var permiso = this.props.permiso;
        var modulo  = this.props.modulo;
        this.state={ enabled : 'false' };
        //validacion de los permisos
        this.unsubscribe1 = globalState.subscribe( ()=>{                        
            if(globalState.getState().type==="permisosAplicacion"){
                this.setState({ enabled : validarPermiso(permiso)&&validarModulo(modulo) });
            }                   
        });
    }    
    handleContainerChange(){//mostrar/esconder la barra de menu
        this.props.funcionClick(this.props.componente);
        var anchoPantalla = window.innerWidth; 
        if(anchoPantalla < 1115){            
            globalState.dispatch({
                            type   : "menuNavegacion",
                            params : false
                        });
        }     
    }
    componentWillUnmount(){         
        //this.unsubscribe1();        
    }
  	render() {
        let idioma = globalState.getState().idioma;       
  	  	return (
            <div>
            {
                this.state.enabled === true ? 
                    <li id={this.props.tab} status="enable">
                       <div id={'div'+this.props.tab} className="divOptionMenu" onClick={this.handleContainerChange.bind(this)} style={{height:'100%'}} onMouseOut={divMouseOut.bind(this,'div'+this.props.tab,configJson.fondoMenu)} onMouseOver={divMouseOver.bind(this,'div'+this.props.tab,configJson.fondoMenu)} >
                          <div className="LiIcon"><MaterialIcon size={30} icon={this.props.icono} invert /></div>
                          <div className="LiTitle">{LabelsOptionMenu[this.props.titulo][idioma]}</div>
                          <div className="LiDescrip">{LabelsOptionMenu[this.props.subtitulo][idioma]}</div>
                       </div>
                    </li>
                : ''
            }
            </div>
			  );
  	}
}

export default Option