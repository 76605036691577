/**
* CLASS WindowContainer
*
* Contiene el contenedor de contenido para la ventana
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import FormDataUser from '../desktop/FormDataUser';
import WelcomePage from '../desktop/WelcomePage';
import WindowResetPassword from '../reset_password/WindowResetPassword';
import WindowRolesPermisos from '../control_panel/roles/WindowRolesPermisos';
import WindowCategorias from '../control_panel/areas_servicio/WindowCategorias';
import WindowSubcategorias from '../control_panel/areas_servicio/WindowSubcategorias';
import WindowRecategorizarIncidencia from '../incidencias/WindowRecategorizarIncidencia';
import WindowConocimientoPorPublicar from '../conocimiento/WindowConocimientoPorPublicar';
import WindowVerSolucion from '../conocimiento/WindowVerSolucion';
import WindowProcesoGestion from '../gestion/WindowProcesoGestion';
import WindowTrazabilidad from '../gestion/WindowTrazabilidad';
import WindowSeleccionFuncionario from '../seleccion_funcionario/WindowSeleccionFuncionario';
import WindowEmpresasModulos from '../control_panel/companies/WindowEmpresasModulos';
import WindowVerEncuesta from '../gestion/WindowVerEncuesta';
import WindowArchivosAdjuntos from '../archivos_adjuntos/WindowArchivosAdjuntos';
import WindowUploadArchivo from '../archivos_adjuntos/WindowUploadArchivo';
import WindowVerPendientes from '../desktop/WindowVerPendientes';
import DataGridSelect from '../data_grid/DataGridSelect';
import WindowReactivarInformacion from '../reactivar_informacion/WindowReactivarInformacion';
import WindowEncuestasTerceros from '../terceros/WindowEncuestasTerceros';
import WindowNovedadesActivos from '../activos/WindowNovedadesActivos';
import FormDataGrid from '../data_grid/FormDataGrid';
import FormEncuestaProveedor from '../terceros/FormEncuestaProveedor';
import WindowVerEncuestaTerceros from '../terceros/WindowVerEncuestaTerceros';
import WindowHorasOperativas from '../control_panel/areas_servicio/WindowHorasOperativas';
import WindowAgregarIcono from '../control_panel/activos_tipos/WindowAgregarIcono';
import WindowVisorLog from '../log/WindowVisorLog';
import WindowVerDatosLog from '../log/WindowVerDatosLog';
import WindowDiasFestivos from '../control_panel/areas_servicio/WindowDiasFestivos';
import WindowEditarFichaTecnica from '../activos/WindowEditarFichaTecnica';
import WindowCambiarEstado from '../activos/WindowCambiarEstado';
import WindowConfigurarCampo from '../control_panel/activos_tipos/WindowConfigurarCampo';
import WindowConfigurarCampo2 from '../control_panel/activos_tipos/WindowConfigurarCampo2';
import WindowUbicaciones from '../control_panel/departamentos/WindowUbicaciones';
import WindowVerAlmacenamiento from '../archivos_adjuntos/WindowVerAlmacenamiento';
import WindowNotificacionesContratos from '../terceros/WindowNotificacionesContratos';
import WindowConfigurarRegla from '../control_panel/imap/WindowConfigurarRegla';
import WindowAgregarUsuariosCapacitacion from '../capacitaciones/WindowAgregarUsuariosCapacitacion';
import WindowUsuariosCapacitaciones from '../capacitaciones/WindowUsuariosCapacitaciones';
import WindowUsuarioCapacitaciones from '../control_panel/users/WindowUsuarioCapacitaciones';
import WindowVerCambiosLog from '../log/WindowVerCambiosLog';

class WindowContainer extends Component {    
    // listado de componentes  
    render() {               
        if(this.props.parametro){
            this.props.params.parametro = this.props.parametro;            
        }
        let componentList = {
            WelcomePage    : WelcomePage,
            FormDataUser : FormDataUser,  
            DataGridSelect : DataGridSelect, 
            WindowResetPassword : WindowResetPassword,   
            WindowRolesPermisos : WindowRolesPermisos, 
            WindowProcesoGestion : WindowProcesoGestion,
            WindowTrazabilidad : WindowTrazabilidad,
            FormDataGrid : FormDataGrid,
            WindowCategorias : WindowCategorias,
            WindowSubcategorias : WindowSubcategorias,
            WindowRecategorizarIncidencia : WindowRecategorizarIncidencia,
            WindowSeleccionFuncionario : WindowSeleccionFuncionario,            
            WindowArchivosAdjuntos : WindowArchivosAdjuntos,
            WindowUploadArchivo : WindowUploadArchivo,
            WindowConocimientoPorPublicar : WindowConocimientoPorPublicar,
            WindowVerPendientes : WindowVerPendientes,
            WindowVerSolucion : WindowVerSolucion,
            WindowNovedadesActivos : WindowNovedadesActivos,
            WindowReactivarInformacion : WindowReactivarInformacion,
            WindowEmpresasModulos : WindowEmpresasModulos,
            WindowVerEncuesta : WindowVerEncuesta,
            WindowEncuestasTerceros : WindowEncuestasTerceros,
            FormEncuestaProveedor : FormEncuestaProveedor,
            WindowVerEncuestaTerceros : WindowVerEncuestaTerceros,  
            WindowHorasOperativas : WindowHorasOperativas,
            WindowAgregarIcono : WindowAgregarIcono,
            WindowVisorLog : WindowVisorLog,
            WindowVerDatosLog : WindowVerDatosLog,
            WindowVerCambiosLog : WindowVerCambiosLog,
            WindowDiasFestivos : WindowDiasFestivos,  
            WindowEditarFichaTecnica : WindowEditarFichaTecnica,           
            WindowCambiarEstado : WindowCambiarEstado,
            WindowConfigurarCampo : WindowConfigurarCampo,
            WindowVerAlmacenamiento : WindowVerAlmacenamiento, 
            WindowNotificacionesContratos : WindowNotificacionesContratos,
            WindowConfigurarRegla : WindowConfigurarRegla,
            WindowConfigurarCampo2 : WindowConfigurarCampo2,
            WindowAgregarUsuariosCapacitacion : WindowAgregarUsuariosCapacitacion,
            WindowUsuariosCapacitaciones : WindowUsuariosCapacitaciones,
            WindowUsuarioCapacitaciones : WindowUsuarioCapacitaciones,
            WindowUbicaciones : WindowUbicaciones             
        }     
        let ChildComponent = componentList[this.props.componente];    
        return (        
            <ChildComponent params={this.props.params} funcionClick={this.props.funcionClick} parametro={this.props.parametro}/>              
        );  	
  	}
}

export default WindowContainer