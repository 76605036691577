/**
* CLASS TabPanel
*
* Contiene el contenedor principal del tabpanel
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import TabPanelOption from './TabPanelOption';
import TabPanelContainer from './TabPanelContainer';
import globalState from '../configuration/GlobalState';

class TabPanel extends Component {
	constructor(props){
		super(props);
		let idTab = null;
		let componente = null;
		let params = null;
		this.props.items.map((listado,i) => { 
			if(listado.id===this.props.defaultActive){
				idTab = listado.id; 
                componente = listado.componente; 
                params = listado.params;
			}
			return 0;
		})		
		this.state={
			tabActive  : idTab,
			componente : componente,
			params : params
		}		
	}
	componentDidUpdate(prevProps){		
		if (this.props.items !== prevProps.items) { 			    
			this.props.items.map((listado,i) => { 
				if(listado.id===this.state.tabActive){					
	                this.setState({params : listado.params});
				}
				return 0;
			})
		}
	}	
	componentDidMount(){  
        //... 
        this.unsubscribe1 = globalState.subscribe( ()=>{ 
            if(globalState.getState().type===this.props.id){                             
                this.setState({tabActive  : globalState.getState()[this.props.id].tabActive}); 
                this.setState({componente : globalState.getState()[this.props.id].componente}); 
                this.setState({params     : globalState.getState()[this.props.id].params});
            }
        });
	}
	componentWillUnmount(){         
        this.unsubscribe1();        
    }
	render() {
		return <div>
					<div id="ContentidTabSoporte" className="ContentTabs" style={{height: '37px', width: '100%'}}>
						{
							this.props.items.map((listado,i) => { 
								return (<TabPanelOption params={listado} key={i} tabPanelId={this.props.id} activeTab={this.state.tabActive}/>)	
							})
						}						
				   </div>
				   <TabPanelContainer componente={this.state.componente} params={this.state.params}/>
				</div>

	}

}

export default TabPanel