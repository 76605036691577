/**
* CLASS FormDataGrid
*
* Contiene el formulario dinamico del datagrid
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import ComboBoxFormDataGrid from './ComboBoxFormDataGrid';
import {ValidateExtension} from '../configuration/GlobalFunctions';
import configJson from '../configuration/configuration.json';
import globalState from '../configuration/GlobalState';
import {divMouseOver,divMouseOut,validarEmail,modalLoading} from '../configuration/GlobalFunctions';
import RichTextEditor from 'react-rte';
import Window from '../window/Window';
import TBar from '../tbar/TBar';
import MaterialIcon from 'material-icons-react';
import {insertarActualizarFila,eliminarFilas,uploaderFile,obtenerConsumoAlmacenamiento} from '../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import '../../css/alertify.css';
import './dataGrid.css'; 

class FormDataGrid extends Component {
  	
  	constructor(props) {
        super(props);
        //cargar dinamicamente los estados        
        this.state = {
            database : globalState.getState().companyData[0].bd,
            espacioTotal : globalState.getState().companyData[0].cuota_almacenamiento,
            espacioOcupado : 0
        };           
        //botones de cancelar y guardar      
        this.handleCancelButton = this.handleCancelButton.bind(this);
        this.handleSaveButton   = this.handleSaveButton.bind(this);
        this.handleConfirmAction = this.handleConfirmAction.bind(this);
        this.funcionEditDataSelect = this.funcionEditDataSelect.bind(this); 
                     
    }
    cargarCampos(){
        this.props.parametro.formFields.forEach((formFields,i) => {    
            if(formFields === null){
                return;
            }        
            if(this.props.parametro.idRow !== 0){                
                if(this.props.parametro.idRow[formFields.field] === '' || this.props.parametro.idRow[formFields.field] === undefined || this.props.parametro.idRow[formFields.field] === null){
                    if(formFields.type==='textarea-rich'){                    
                        this.setState({[formFields.field] : RichTextEditor.createEmptyValue()});                          
                    }
                    else if(formFields.type!=='separator' && formFields.type !== 'file' && formFields.type !== 'multi_file'){
                        this.setState({[formFields.field] : ''});
                    }                    
                }
                else{
                    if(formFields.type==='textarea-rich'){                    
                        this.setState({[formFields.field] : RichTextEditor.createValueFromString(this.props.parametro.idRow[formFields.field], 'html')});                          
                    }
                    else if(formFields.type!=='separator' && formFields.type !== 'file' && formFields.type !== 'multi_file'){
                        this.setState({[formFields.field] : this.props.parametro.idRow[formFields.field]});        
                    }         
                    if(formFields.type==='data_select'){//adicional pone el texto en el input del data select
                        this.setState({[formFields.dataParams.fetchData.valueField] : this.props.parametro.idRow[formFields.dataParams.fetchData.valueField]});
                    }
                }
            }
            else{
                if(formFields.type==='select'){                    
                    this.setState({[formFields.field] : ""});                    
                }                
                else if(formFields.type==='textarea-rich'){                    
                    this.setState({[formFields.field] : RichTextEditor.createEmptyValue()});                          
                }
                else if(formFields.type!=='separator'){                   
                    this.setState({[formFields.field] : ''});                          
                }                
            }
            if(formFields.type==='hidden'){
                this.setState({[formFields.field] : formFields.value}); 
            }
        });        
    } 
    componentWillMount(){        
        this.cargarCampos();
        obtenerConsumoAlmacenamiento(this.state.database)
        .then(res => {
            res = res.data;
            if (res.msg === "error") {
                alertify.alert('Error!', 'Ha ocurrido un error accesando al sistema de archivos!<br />Codigo de Error: '+res.detail);
            } 
            else{                                          
                this.setState({espacioOcupado : res});                                                   
            }
        })
        .catch( err => {            
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);                            
        });
    }
    componentDidUpdate(prevProps){  
        if (this.props.parametro !== prevProps.parametro) {           
            this.cargarCampos();            
        }          
    } 
    componentDidMount(){
        this.setState({windowDataSelectId : ''});
    } 
    handleCancelButton(){
        if(typeof this.props.parametro.functionCancel === 'function'){
            this.props.parametro.functionCancel();
        }
        this.props.funcionClick(this.props.parametro.mainContainer);        
    }
    handleSaveButton(id){
        //recorrido dinamico de los campos y cargar dinamicamente el arrayData
        var arrayData = {};
        var arrayFileFields = {};        
        var errors = 0;            

        this.props.parametro.formFields.forEach((formFields,i) => {
            if(formFields === null){
                return;
            }  
            if(formFields.type==='textarea-rich'){ 
                if(!this.state[formFields.field].getEditorState().getCurrentContent().hasText()){
                    alertify.error('Digite el campo '+formFields.label+'!'); 
                    errors++;
                    return; 
                }
                else{
                    arrayData[formFields.field] = this.state[formFields.field].toString('html');
                }                
            }
            else if((this.state[formFields.field] === undefined || this.state[formFields.field] === '') && formFields.required === 'true'){
                alertify.error('Digite el campo '+formFields.label+'!'); 
                errors++;
                return;
            }
            else if(formFields.type==='file' || formFields.type==='multi_file'){                
                if(this.state[formFields.field]){
                    arrayFileFields[formFields.field] = {
                                                            files : this.state[formFields.field],
                                                            allowedExt : formFields.allowedExt,  
                                                            maxSize : formFields.maxSize,                                                      
                                                            field  : formFields.field,                                                        
                                                        }
                }                
            } 
            else if(formFields.type!=='separator'){
                if(formFields.validation === 'email'){
                    if(!validarEmail(this.state[formFields.field])){
                        alertify.error('No es una cuenta de Email Valida en el campo '+formFields.label+'!'); 
                        errors++;
                        return;
                    }
                }                
                arrayData[formFields.field] = this.state[formFields.field];
            }
        });
        //hay errores?
        if(errors > 0){
            return;
        }            
      
        var method = '';

        var titulo = 'almacenado';
        if(id>0){//si es update o insert           
            method = 'put';
            arrayData['id'] = id;//mandar el ID
            titulo = 'actualizado';
        }
        else{            
            method = 'post';
        }   
        modalLoading(true);            
        //ajax que llama a la API para insertar o actualizar        
        insertarActualizarFila(method,this.props.parametro.apiField,arrayData,this.props.parametro.dataLog,undefined,this.state.database,this.props.parametro.apiRoute)
        .then(response => {
            modalLoading(false); 
            response = response.data;
            if(response.msg === 'error'){
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Detalle del Error: '+response.detail); 
            }
            else {                 
                //aqui es donde refresca el datagrid una vez se han hecho los cambios
                if(!id>0){                         
                    if(typeof this.props.parametro.funcionAfterInsert === 'function'){
                        this.props.parametro.funcionAfterInsert(response.insertId);
                    }
                    id = response.insertId;
                }
                else{
                    if(typeof this.props.parametro.funcionAfterUpdate === 'function'){
                        this.props.parametro.funcionAfterUpdate(id);
                    }
                } 
                //proceso para subir los archivos               
                if(Object.keys(arrayFileFields).length > 0){                    
                    for(let x in arrayFileFields){//recorrido por cada campo                        
                        var arrayFile  = arrayFileFields[x].files;
                        for(let i=0;i<arrayFile.length&&i<10;i++){                             
                            var file  = arrayFile[i]; 
                            if(!ValidateExtension(arrayFileFields[x].allowedExt,file.name)){                            
                                alert('Error: Solo se permiten imagenes con extension '+JSON.stringify(arrayFileFields[x].allowedExt)+'!');
                            }
                            else{
                                let size = configJson.maxDocumentsSize;
                                if(arrayFileFields[x].maxSize !== undefined){
                                    size = arrayFileFields[x].maxSize;
                                }                                
                                if(file.size > size){
                                    let maxSize = '';
                                    if(size/1048576 >= 1){
                                        maxSize = size/1048576;
                                        maxSize = maxSize.toFixed(1)+'M';
                                    }
                                    else{
                                        maxSize = size/1024;
                                        maxSize = maxSize.toFixed(1)+'K';
                                    }
                                    alert('Error: Solo se permiten archivos de maximo '+maxSize+'!');
                                }
                                else{
                                    var folder = globalState.getState().companyData[0].bd;  
                                    //verificar si hay espacio libre
                                    let espacioOcupado = (this.state.espacioOcupado*1048576)+file.size;
                                    let espacioTotal = this.state.espacioTotal*1048576;
                                    let espacioLibre = espacioTotal-espacioOcupado;
                                    if(espacioLibre < 0){
                                        espacioLibre = 0;
                                    }
                                    if(espacioOcupado > espacioTotal){
                                        alertify.alert('Error!', 'Se ha superado la cuota de almacenamiento!<br>Espacio Total:'+this.state.espacioTotal+' MB<br>Espacio Libre:'+espacioLibre+' MB');
                                        return;
                                    }
                                    uploaderFile(file,this.props.parametro.apiField+'_adjuntos',arrayFileFields[x].field,id+'_'+globalState.getState().userData[0].id,folder+'/'+this.props.parametro.apiField+'_adjuntos/',folder).then(res => {
                                        var response = res.data; 
                                        if (response.msg === "error") {
                                            alert('Error: Ha ocurrido un error subiendo el archivo!<br />Codigo de Error: '+response.detail);
                                        } else { 
                                            //alertify.alert('Aviso!', 'La informacion se ha '+titulo+' con exito!');                                                                 
                                        }
                                    })
                                    .catch( err => {            
                                        alert('Error: No se ha logrado la conexion con el servidor!<br />'+err);
                                    }); 
                                }
                            }
                        }                        
                    }
                } 
                alertify.alert('Aviso!', 'La informacion se ha '+titulo+' con exito!');
                this.props.funcionClick(this.props.parametro.mainContainer);  
            }
        })
        .catch(function (error) {
            modalLoading(false); 
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    }
    //manejo dinamico de los estados, con esto actualizo el valor de cualquier campo para enviarlos a la API
    handleStateChange(longitud,validation,funcionOnChange,e) { 
        
        var ingresado = null; //validaciones

        if(validation === 'textarea-rich'){
            ingresado = e;            
        }
        else if(validation === 'file'){                       
            this.setState({ [e.target.name]: e.target.files });
        }         
        else{
            ingresado = e.target.value;
            ingresado = ingresado.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\n,:.@\-+#?¿_$€& ]/g,'');
        }

        if(validation === 'mayusculas'){
            ingresado = ingresado.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ?¿ ]/g,'');
            ingresado = ingresado.toUpperCase();
        }
        if(validation === 'texto_mayusculas'){
            ingresado = ingresado.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9&?¿. ]/g,'');
            ingresado = ingresado.toUpperCase();
        }
        if(validation === 'telefono'){
            ingresado = ingresado.replace(/[^0-9+\-ext ]/g,'');            
        }
        if(validation === 'direccion'){
            ingresado = ingresado.replace(/[^\w.\-#ñÑ ]/g,'');            
        }
        if(validation === 'email'){
            ingresado = ingresado.replace(/[^a-zA-Z0-9._@-]/g,'');
            ingresado = ingresado.toLowerCase();
        }
        if(validation === 'entero'){
            ingresado = ingresado.replace(/[^\d]/g,'');
        }
        if(validation === 'numero_real'){
            ingresado = ingresado.replace(/[^\d.]/g,'');
        } 
        if(validation === 'numero_texto'){
            ingresado = ingresado.replace(/[^a-zA-Z0-9&]/g,'');
            ingresado = ingresado.toUpperCase();
        }        
        if(typeof funcionOnChange === 'function'){//la funcion onchange del combo  
            funcionOnChange(ingresado);           
        }

        if(validation === 'textarea-rich'){
            this.setState({ [funcionOnChange]: ingresado });
        }
        else{
            if(longitud!== undefined && longitud > 0){
                if(ingresado.length <= longitud){
                    this.setState({ [e.target.name]: ingresado });                    
                }
            }
            else{                
                if((validation === 'entero' || validation === 'numero_real') && ingresado.length <= 15){
                    this.setState({ [e.target.name]: ingresado }); 
                }
                else if((validation === 'numero_texto' || validation === 'mayusculas' || validation === 'telefono') && ingresado.length <= 20){
                    this.setState({ [e.target.name]: ingresado }); 
                }
                else if(validation === 'direccion' && ingresado.length <= 30){
                    this.setState({ [e.target.name]: ingresado }); 
                }
                else if(validation === 'email' && ingresado.length <= 50){
                    this.setState({ [e.target.name]: ingresado }); 
                }
                else if(validation === ""){
                    this.setState({ [e.target.name]: ingresado });
                }
            }
        }        
    }   
    async handleDeleteButton(id){//boton eliminar
        let enableDelete=true;
        // Verifica si hay una condición para que se pueda eliminar
        if(typeof this.props.parametro.funcionBeforeDelete === 'function'){
            let resultFuncion = await this.props.parametro.funcionBeforeDelete(id);
            if(!resultFuncion.status){
                alertify.alert('Error', resultFuncion.message);
                enableDelete=false;
            }
        }
        if(enableDelete){
            alertify.confirm('Confirmacion', 'Esta seguro(a) de eliminar este item?', this.handleConfirmAction.bind(this,id), function(){});
        }        
    }   
    handleConfirmAction(id) {        
        //CODIGO PARA ELIMINAR LA FILA        
        eliminarFilas(this.props.parametro.apiField,id,this.props.parametro.dataLog,this.props.parametro.actionDelete,this.props.parametro.whereDelete,this.state.database)
        .then(response => {            
            response = response.data;
            if(response.msg === 'error'){
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail); 
            }
            else if(response.msg === 'notExist'){
                alertify.alert('Error!', 'El dato a eliminar no existe!'); 
            }
            this.props.funcionClick('WelcomePage');
            this.props.funcionClick(this.props.parametro.mainContainer);
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });        
    }  
    handleDataSelect(dataParams){//al dar clic en el campo de texto
        dataParams['funcionEdit'] = this.funcionEditDataSelect;      
        this.setState({windowDataSelectId : "windowFormDataSelect_"+this.props.parametro.mainContainer+"_"+dataParams.idField }, () => {
            globalState.dispatch({
                type   : "windowFormDataSelect_"+this.props.parametro.mainContainer+"_"+dataParams.idField,
                params : {
                    visible : true,
                    params  : dataParams,
                    width   : '400px', 
                    height  : '300px'
                }
            })
        });        
    }
    funcionEditDataSelect(data,params){//la funcion que carga los datos del DataSelect        
        this.setState({[params.idField] : data.id });
        this.setState({[params.valueField] : data[params.fieldFetch]});
        this.setState({windowDataSelectId : "windowFormDataSelect_"+this.props.parametro.mainContainer+"_"+params.idField }, () => {
            globalState.dispatch({
                type   : "windowFormDataSelect_"+this.props.parametro.mainContainer+"_"+params.idField,
                params : {
                    visible : false,                    
                }
            })
        }); 
    } 
    handleHelpButton(text,e){
        alertify.alert("Aviso",text);
    } 
  	render() {
    	var titulo = 'Agregar';
        var id = 0;
        var field = '';
        var field1 = '';
        var count = 1;  
        var top = "0px";
        //var position = "relative";      
    	if(this.props.parametro.idRow !== 0){
    		titulo = 'Editar';
            id = this.props.parametro.idRow.id;
    	} 
        if(this.props.parametro.idRow !== 0 && this.props.parametro.tbarEdit === 'true'){
            top = "70px";             
            this.props.parametro.tbarItems.map((tbarItems,i) => {                
                tbarItems.function = tbarItems.function.bind(this,id);
                return 0;
            });
        }
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
              {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
              {label: 'Italic', style: 'ITALIC'},
              {label: 'Strikethrough', style: 'STRIKETHROUGH'},                        
              {label: 'Underline', style: 'UNDERLINE'}
            ],
            BLOCK_TYPE_DROPDOWN: [
              {label: 'Normal', style: 'unstyled'},
              {label: 'Heading Large', style: 'header-one'},
              {label: 'Heading Medium', style: 'header-two'},
              {label: 'Heading Small', style: 'header-three'}
            ],
            BLOCK_TYPE_BUTTONS: [
              {label: 'UL', style: 'unordered-list-item'},
              {label: 'OL', style: 'ordered-list-item'},
              {label: 'Blockquote', style: 'blockquote'}
            ]
        };                                       			
    	return (  //carga dinamica del formulario
            <div>
                {
                    this.props.parametro.idRow !== 0 && this.props.parametro.tbarEdit === 'true' ?
                        <TBar
                            items={this.props.parametro.tbarItems}                            
                        />
                    : ''
                }
                {
                    this.props.parametro.idRow !== 0 && this.props.parametro.tbarEdit === 'true' ?
                        this.props.parametro.tbarItems.map((tbarItems,i) => {    
                            return <div key={i}>{tbarItems.windowComponent}</div>
                        })  
                    : ''
                }                
    	 	    <div className="container" style={{top:top,position:"relative"}}>
                    <div className="content">
                       <div className="table-responsive mt-4">
                           <div className="titulo">{titulo} {this.props.parametro.titulo}</div>
                       </div>
                       <hr />
                       <div className="table-responsive mb-3">	
        			    	<Form>                            
                                {                                
                                    //cargar dinamicamente los campos, dependiendo si es input o select                                
                                    this.props.parametro.formFields.map((formFields,i) => {
                                        if(formFields === null){
                                            return '';
                                        }
                                        let readOnly = false;
                                        if(formFields.blockEdit === 'true' && id > 0){
                                            readOnly = true;
                                        }
                                        if(formFields.type === 'separator'){
                                            if(count%2===0){
                                                count+=3;
                                                field1 = field;
                                                field = '';                                                                                                                               
                                            }
                                            else{
                                                field1 = '';                                                
                                            }
                                            return <div key= {i}> 
                                                        <Form.Row style={{width:'99%'}}>
                                                            {field1}
                                                        </Form.Row>
                                                        <Form.Row style={{width:'99%'}}>
                                                            <Form.Group as={Col}  controlId={"formField_"+formFields.field}>
                                                                <Form.Label className="titulo">{formFields.label}</Form.Label>
                                                                <hr />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </div>
                                        }
                                        else if(formFields.type === 'text' || formFields.type === 'date' || formFields.type === 'time'){
                                            field = <>{field}<Form.Group as={Col}  controlId={"formField_"+formFields.field}>
                                                        <Form.Label title={formFields.tooltip}>
                                                            {formFields.label}
                                                            {   
                                                                formFields.help ?
                                                                    <div style={{float:'right',cursor:'pointer',marginTop:'-3px'}} onClick={this.handleHelpButton.bind(this,formFields.help)}>
                                                                        <MaterialIcon size={14} icon="help" color={configJson.iconosAyuda}/>
                                                                    </div>
                                                                : ''
                                                            }  
                                                        </Form.Label>
                                                        <Form.Control readOnly={readOnly} name = {formFields.field} type={formFields.type} onChange={this.handleStateChange.bind(this,formFields.longitud,formFields.validation,'')} value={this.state[formFields.field]}/>                               
                                                    </Form.Group></>
                                        }
                                        else if(formFields.type === 'file'){                                                                                     
                                            field = <>{field}<Form.Group as={Col}  controlId={"formField_"+formFields.field}>
                                                        <Form.Label title={formFields.tooltip}>
                                                            {formFields.label}                                                            
                                                            {   
                                                                formFields.help ?
                                                                    <div style={{float:'right',cursor:'pointer',marginTop:'-3px'}} onClick={this.handleHelpButton.bind(this,formFields.help)}>
                                                                        <MaterialIcon size={14} icon="help" color={configJson.iconosAyuda}/>
                                                                    </div>
                                                                : ''
                                                            }
                                                        </Form.Label>                                                         
                                                        <Form.Control
                                                            name={formFields.field}
                                                            type="file"                                                            
                                                            onChange={this.handleStateChange.bind(this,formFields.longitud,formFields.validation,'')}
                                                            accept={formFields.accept}                                                            
                                                        />                                                                                                                            
                                                    </Form.Group></>
                                        }
                                        else if(formFields.type === 'multi_file'){                                                                                     
                                            field = <>{field}<Form.Group as={Col}  controlId={"formField_"+formFields.field}>
                                                        <Form.Label title={formFields.tooltip}>
                                                            {formFields.label}
                                                            {   
                                                                formFields.help ?
                                                                    <div style={{float:'right',cursor:'pointer',marginTop:'-3px'}} onClick={this.handleHelpButton.bind(this,formFields.help)}>
                                                                        <MaterialIcon size={14} icon="help" color={configJson.iconosAyuda}/>
                                                                    </div>
                                                                : ''
                                                            }
                                                        </Form.Label>                                                         
                                                        <Form.Control
                                                            name={formFields.field}
                                                            type="file"                                                            
                                                            onChange={this.handleStateChange.bind(this,formFields.longitud,formFields.validation,'')}
                                                            accept={formFields.accept} 
                                                            multiple                                                            
                                                        />                                                                                                                            
                                                    </Form.Group></>
                                        }
                                        else if(formFields.type === 'textarea'){
                                            if(count%2===0){
                                                count+=3;
                                                field1 = field;
                                                field = '';                                                                                                                                                                           
                                            } 
                                            else{
                                                field1 = '';                                                
                                            } 
                                            return <div key= {i}> 
                                                    <Form.Row style={{width:'99%'}}>
                                                        {field1}
                                                    </Form.Row>
                                                    <Form.Row style={{width:'99%'}}>
                                                        <Form.Group as={Col} key= {i} controlId={"formField_"+formFields.field}>
                                                            <Form.Label title={formFields.tooltip}>
                                                                {formFields.label}
                                                                {   
                                                                    formFields.help ?
                                                                        <div style={{float:'right',cursor:'pointer',marginTop:'-3px'}} onClick={this.handleHelpButton.bind(this,formFields.help)}>
                                                                            <MaterialIcon size={14} icon="help" color={configJson.iconosAyuda}/>
                                                                        </div>
                                                                    : ''
                                                                }
                                                            </Form.Label>                                                        
                                                            <Form.Control readOnly={readOnly} name = {formFields.field} as="textarea" rows={formFields.rows} onChange={this.handleStateChange.bind(this,formFields.longitud,formFields.validation,'')} value={this.state[formFields.field]}/>
                                                        </Form.Group>
                                                    </Form.Row>
                                                   </div>
                                        }  
                                        else if(formFields.type === 'textarea-rich'){
                                            if(count%2===0){
                                                count+=3;
                                                field1 = field;
                                                field = '';                                                                                                                                                                              
                                            } 
                                            else{
                                                field1 = '';                                                
                                            }
                                            return <div key= {i}> 
                                                    <Form.Row style={{width:'99%'}}>
                                                        {field1}
                                                    </Form.Row>
                                                    <Form.Row style={{width:'99%'}}>
                                                        <Form.Group as={Col} key= {i} controlId={"formField_"+formFields.field}>
                                                            <Form.Label title={formFields.tooltip}>
                                                                {formFields.label}
                                                                {   
                                                                    formFields.help ?
                                                                        <div style={{float:'right',cursor:'pointer',marginTop:'-3px'}} onClick={this.handleHelpButton.bind(this,formFields.help)}>
                                                                            <MaterialIcon size={14} icon="help" color={configJson.iconosAyuda}/>
                                                                        </div>
                                                                    : ''
                                                                }
                                                            </Form.Label>
                                                            <RichTextEditor
                                                                name = {formFields.field}
                                                                onChange={this.handleStateChange.bind(this,'','textarea-rich',formFields.field)} 
                                                                value={this.state[formFields.field]} 
                                                                editorClassName="richTextEditor" 
                                                                readOnly={readOnly} 
                                                                toolbarConfig={toolbarConfig}                                                         
                                                            />                                                       
                                                        </Form.Group>
                                                    </Form.Row>
                                                   </div>
                                        }                                  
                                        else if(formFields.type === 'select'){
                                            field = <>{field}<Form.Group as={Col} controlId={"formField_"+formFields.field}>
                                                        <Form.Label title={formFields.tooltip}>
                                                            {formFields.label}
                                                            {   
                                                                formFields.help ?
                                                                    <div style={{float:'right',cursor:'pointer',marginTop:'-3px'}} onClick={this.handleHelpButton.bind(this,formFields.help)}>
                                                                        <MaterialIcon size={14} icon="help" color={configJson.iconosAyuda}/>
                                                                    </div>
                                                                : ''
                                                            }                                                             
                                                        </Form.Label>
                                                        <ComboBoxFormDataGrid 
                                                            valueName = {formFields.valueName} 
                                                            options = {formFields.options} 
                                                            apiField={formFields.apiField} 
                                                            dinamic={formFields.dinamic} 
                                                            name = {formFields.field} 
                                                            type={formFields.type}                                                                                                                     
                                                            fieldUpdate={formFields.fieldUpdate}
                                                            onChange={formFields.onChange}
                                                            valueUpdate={this.state[formFields.fieldUpdate]}
                                                            functionChange={this.handleStateChange.bind(this,'',formFields.validation,formFields.onChange)} 
                                                            value={this.state[formFields.field]} 
                                                            sqlParams={formFields.sqlParams}
                                                            readOnly={readOnly} />                               
                                                    </Form.Group></>
                                        }
                                        else if(formFields.type === 'data_select'){                                           
                                            field = <>{field}<Form.Group as={Col} controlId={"formField_"+formFields.field}>
                                                        <input type="hidden" name = {formFields.field} value={this.state[formFields.field]} />
                                                        <Form.Label title={formFields.tooltip}>
                                                            {formFields.label}
                                                            {   
                                                                formFields.help ?
                                                                    <div style={{float:'right',cursor:'pointer',marginTop:'-3px'}} onClick={this.handleHelpButton.bind(this,formFields.help)}>
                                                                        <MaterialIcon size={14} icon="help" color={configJson.iconosAyuda}/>
                                                                    </div>
                                                                : ''
                                                            }
                                                        </Form.Label>
                                                        <Form.Control disabled={readOnly} style={{backgroundColor:'#fff'}} name={formFields.dataParams.fetchData.valueField} type="text" onClick={this.handleDataSelect.bind(this,formFields.dataParams)} value={this.state[formFields.dataParams.fetchData.valueField] || 'Seleccione...'} readOnly/>                                
                                                   </Form.Group></>
                                        }                                        
                                        else if(formFields.type === 'hidden'){
                                            return <input key= {i} type="hidden" name = {formFields.field} value={this.state[formFields.field]} />
                                        }
                                        //organizar las columnas en filas
                                        if(count%2===0){
                                            count++;
                                            field1 = field;
                                            field = '';                                       
                                            return <Form.Row style={{width:'99%'}} key= {i}>
                                                        {field1}
                                                    </Form.Row>;   

                                        }                                    
                                        else{
                                            count++;                                                                                
                                        } 
                                        return null;                                     
                                    })                                
                                } 
                                {
                                    (count%2===0)?<Form.Row style={{width:'99%'}}>{field}</Form.Row>:''                                 
                                }
                                <Form.Row style={{width:'99%'}} className="espacioCopyright">                        
                                {
                                    this.props.parametro.enableBtnEdit === true || this.props.parametro.idRow === 0 ?
                                        <Button id="formGridBtnSave" className="float-left mr-3" variant="primary" onClick={this.handleSaveButton.bind(this,id)} style={{backgroundColor:configJson.fondoBotonGrilla}} onMouseOut={divMouseOut.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)}>
                                            GUARDAR
                                        </Button> 
                                    : ''
                                }
                                <Button variant="secondary" className="float-left mr-3" onClick={this.handleCancelButton.bind(this)}>
                                    CANCELAR
                                </Button>
                                {                                
                                    this.props.parametro.idRow !== 0 && this.props.parametro.enableBtnDel === true ?
                                        <Button id="formGridBtnDelete" className="float-left mr-3" variant="danger" onClick={this.handleDeleteButton.bind(this,id)} onMouseOut={divMouseOut.bind(this,"formGridBtnDelete","#dc3545")} onMouseOver={divMouseOver.bind(this,"formGridBtnDelete","#dc3545")}>
                                            ELIMINAR
                                        </Button>                                
                                    :  ""                                
                                } 
                                </Form.Row>                           
        				    </Form>
                            <Window   //ventana para el data select
                                id = {this.state.windowDataSelectId}                    
                                title='Seleccione ...'                                                     
                                tbar="false"
                                componente="DataGridSelect"
                                params="" 
                            /> 
        			    </div> 
        		    </div> 	  	 		       
        	    </div> 
            </div>
    	);
  	}
}

export default FormDataGrid