/**
* CLASS Window
*
* Contiene el componente principal de la ventana
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import Modal from 'react-modal';
import globalState from '../configuration/GlobalState';
import configJson from '../configuration/configuration.json';
import {divMouseOver,divMouseOut} from '../configuration/GlobalFunctions';
import WindowContainer from './WindowContainer';
import MaterialIcon from 'material-icons-react'; 
import './window.css';

Modal.setAppElement('#root');

const customStyles = {
  content : {
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    marginRight : '-50%',
    padding     : '0px',
    transform   : 'translate(-50%, -50%)',
  }
};

class Window extends Component {

    constructor(props) {        
        super(props); 
        this.state = {
            componente : this.props.componente,
            showModal: false,
            parametro: "",
            modalParams : '',
            width  : '',
            height : ''
        };               
        this.handleCloseModal = this.handleCloseModal.bind(this); 
        this.actualizarContainer = this.actualizarContainer.bind(this);        
    } 
    componentDidMount(){        
        this.unsubscribe1 = globalState.subscribe( ()=>{           
            if(globalState.getState().type===this.props.id){ 
                this.setState({ showModal: globalState.getState()[this.props.id].visible });
                if(globalState.getState()[this.props.id].params !== undefined) {//si existen le cargamos los parametros
                    this.setState({ modalParams: globalState.getState()[this.props.id].params }); 
                    this.setState({ width : globalState.getState()[this.props.id].params.width });
                    this.setState({ height : globalState.getState()[this.props.id].params.height });                    
                }              
            }
        });
    }
    componentWillUnmount(){
        this.unsubscribe1();
    }
    componentWillUpdate(){
        //actualiza las propiedades segun la ventana que se despliegue
        
    }
     
    handleCloseModal () {
        globalState.dispatch({
                type   : this.props.id,
                params : {
                            visible : false
                         }
            });
    }
    actualizarContainer(val,param){//carga dinamica del componente en la ventana   
        this.setState({ componente: val });
        this.setState({ parametro : param });       
    }  
    ejecutaFuncion(funcion,i,e){        
        e.persist();
        this.bloqueaBoton(e.currentTarget,i);
        funcion();        
    }  
    bloqueaBoton(e){    //bloquear el boton por dos segundos para evitar doble guardado    
        e.style.pointerEvents = "none"; 
        e.parentElement.style.pointerEvents = "none";         
        var DesBloqBtn = function(){ ExecDesBloqBtn(); };
        setTimeout(DesBloqBtn,2000);
        function ExecDesBloqBtn(){
            if(e){
                e.style.pointerEvents = "auto";  
                e.parentElement.style.pointerEvents = "auto";                   
            }
        }
    }
  	render() {
        var lengthTbar = this.props.tbar.length;
        var display = "table-cell";      
        if(lengthTbar === 1){
            display = "block";  
        }
        let w = window.innerWidth;
        if(w <= 570){
            customStyles.content.width  = '100%';   
            customStyles.content.height = '100%'; 
        }
        else if(this.state.showModal === true){
            customStyles.content.width  = this.state.width;   
            customStyles.content.height = this.state.height;                        
        }        
  	  	return (
  			<Modal 
               isOpen={this.state.showModal}
               contentLabel="Minimal Modal Example"
               style={customStyles}
            >
                <div style={{backgroundColor : configJson.windowColor }} className="windowTitle" os="windows" id="windowTitleFrame">
                    <div id="windowTitleTextFrame" className="windowTitleText" os="windows">{this.props.title}</div>
                    <div className="windowTitleBoton" style={{ top: '-4px',left:'-2px'}} os="windows" id="btnCloseVentanaFrame" onClick={this.handleCloseModal}>
                        <MaterialIcon size={24} icon="close" invert id="iconClose"/>
                    </div> 
                </div> 
                <div style={{backgroundColor : configJson.windowColor }} className="windowTbar" >
                {
                    this.props.tbar !== 'false' ?                                             
                        this.props.tbar.map((tbar,i) => {//cargar la tbar
                                    return (   
                                        <div id={"windowButton"+i} className="windowButton" key={i} style={{width:tbar.width,height:tbar.height,display:display}}  onMouseOut={divMouseOut.bind(this,"windowButton"+i,configJson.windowColor)} onMouseOver={divMouseOver.bind(this,"windowButton"+i,configJson.windowColor)}>                                    
                                            <div style={{width:'calc(100% - 5px)',float:'left',pointerEvents:'auto'}} onClick={this.ejecutaFuncion.bind(this,tbar.function,i)}> 
                                                <MaterialIcon size={24} icon={tbar.icon} invert/>
                                                <button className="save">{tbar.title}</button>                                             
                                            </div>
                                            {//la barra separadora
                                               i < this.props.tbar.length-1 ? 
                                                  <div className="windowSeparator" style={{height:tbar.height}}></div>
                                               : ''
                                            }
                                            
                                        </div>
                                    )
                        })                       
                    : ''
                }
                </div>                     
                <WindowContainer componente={this.state.componente} params={this.state.modalParams} funcionClick = {this.actualizarContainer} parametro={this.state.parametro}/>
            </Modal>  				
			  );
  	}    
}

export default Window