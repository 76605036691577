/**
* CLASS ActivosTipos
*
* Contiene el contenedor principal de los tipos de activos
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import globalState from '../../configuration/GlobalState';
import DataGrid from '../../data_grid/DataGrid';
import {cargarFilas} from '../../api_calls/ApiCalls';
import alertify from 'alertifyjs';
import Window from '../../window/Window';
import TBar from '../../tbar/TBar';

class ActivosTipos extends Component {
    retrocederPanel(){
        this.props.funcionClick('PanelMenu');
    }  
    colFuncion(idTipo){
        globalState.dispatch({
                type   : "windowAgregarIcono",
                params : {
                              visible : true,
                              params  : {
                                            idTipo : idTipo,                                            
                                            idWin  : "windowAgregarIcono",
                                            funcionClick : this.props.funcionClick,
                                            width  : '300px', 
                                            height : '110px'//identificador de la ventana
                                        }
                         }
            }); 
    }  
    async colFuncion2(idRow){
        try{            
            let sqlParams = {
                                sqlCols : [
                                    'nombre',
                                ],
                                sqlWhere : [ " AND id="+idRow ],                   
                            }
            let res = await cargarFilas('activos_tipos','',1,0,'','',sqlParams,'rows',globalState.getState().companyData[0].bd);
            let response = res.data;
            globalState.dispatch({
                type   : "idTipoActivo",                
                params : {
                    idRow  : idRow,
                    nombre : response[0].nombre
                }
            });
            this.props.funcionClick('ActivosTiposCamposFicha'); 
        }
        catch(err){
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err.message); 
        }
        
    }  

    async validacionDeleteActivoTipo(id){
        try{
            // Validar que no hayan activos con el tipo a eliminar
            let database = globalState.getState().companyData[0].bd;
            let sqlParams = {
                                sqlCols : [
                                    "COUNT(id) AS total"                                             
                                ],                            
                                sqlWhere : [
                                    " AND id_tipo = "+id//no cuenta superusuarios
                                ],                                    
                            }; 
            let res = await cargarFilas('activos','',1000,0,'','',sqlParams,'rows',database);
            let response = res.data; 
            if(response.length > 0 && response[0].total > 0){
                return {  status : false, message : "El tipo de activo no se puede eliminar, tiene activos vinculados!" }; 
            }
            
            return {  status : true, message : "" }; 
               
        }           
        catch(err){
            return {  status : false, message : 'No se ha logrado la conexion con el servidor!<br />'+err.message }; 
        }     
    }	  
  	render() { 
        let sqlParams = { 
                            sqlCols : [
                                "*",
                                "IF(categoria='it','IT','NO IT') AS nombre_categoria"
                            ],
                            fieldSearch : [
                                'nombre',                                
                            ],                                                       
                            filtroFechas : "false",                                                                             
                        };                        
        return (//carga el componente que contiene la grilla de datos 
            <div>
                <TBar
                    items={[
                              {
                                  type : 'boton',
                                  icon : 'arrow_back',
                                  width : '100px',
                                  height : '60px',
                                  title : 'Atras',
                                  display : true,
                                  function : this.retrocederPanel.bind(this)
                              },
                          ]}
                    length = '1'
                />
                <div style={{top: "60px",position:"relative"}}>  
                    <DataGrid titulo='Tipos de Activo' 
                              funcionClick={this.props.funcionClick} 
                              funcionBeforeDelete={this.validacionDeleteActivoTipo.bind(this)}  
                              parametro={this.props.parametro} 
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Nombre',
                                                field : 'nombre'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Categoria',
                                                field : 'nombre_categoria'
                                            },
                                            {
                                                type  : 'bd',
                                                label : 'Descripcion',
                                                field : 'descripcion'
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : '',
                                                field : 'icono',
                                                colFuncion : this.colFuncion.bind(this)
                                            },
                                            {
                                                type  : 'manual',
                                                label : '',
                                                icon  : 'assignment',
                                                field : '',
                                                colFuncion : this.colFuncion2.bind(this)
                                            }  
                                        ]}
                              sqlParams = { sqlParams }                       
                              automatica="true"
                              botonNuevo="true"
                              formFields={[
                                            {
                                                label : 'Nombre *',
                                                field : 'nombre',
                                                type  : 'text',
                                                validation : 'mayusculas',
                                                required : 'true',
                                                longitud : 40,
                                                tooltip : 'Ingrese el nombre del tipo de activo'
                                            },
                                            {
                                                label : 'Categoria *',
                                                field : 'categoria',
                                                type  : 'select',
                                                validation : '',
                                                required : 'true',
                                                dinamic : 'false',
                                                valueName : 'nombre',
                                                options :  [{
                                                                id: 'it',
                                                                nombre: 'IT',
                                                            },
                                                            {
                                                                id: 'no_it',
                                                                nombre: 'NO IT',
                                                            }],
                                                tooltip : 'Seleccione el tipo',                                                                                            
                                            }, 
                                            {
                                                label : 'Descripcion *',
                                                field : 'descripcion',
                                                type  : 'textarea',
                                                rows  : 4,
                                                validation : '',
                                                required : 'true',
                                                tooltip : 'Ingrese la descripcion del tipo de activo'
                                            },                               
                                        ]}                     
                              apiField={'activos_tipos'}
                              mainContainer='ActivosTipos'/> 
                </div>
                <Window 
                    id = "windowAgregarIcono"                      
                    title='Agregar Icono'                    
                    tbar="false"
                    componente="WindowAgregarIcono"
                    params="" 
                />                
            </div>            
        )
    } 
}

export default ActivosTipos