/**
* GlobalFunctions
*
* Contiene todas las funciones globales de la APP
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*
*/

import globalState from './GlobalState';

export function divMouseOver(field,color,e){
	//cambia el color al pasar por encima		
	if(e.currentTarget.id){ 
		if(e.currentTarget.id=== field){
			e.currentTarget.style.cursor = 'pointer';
			e.currentTarget.style.backgroundColor = LightenDarkenColor(color,20);
		}
	}
}

export function divMouseOut(field,color,e){	
	//cambia el color al salir del div
	if(e.currentTarget.id){ 
		if(e.currentTarget.id=== field){
			e.currentTarget.style.cursor = 'default';
			e.currentTarget.style.backgroundColor = color;
		}
	}	
}

function LightenDarkenColor(col,amt) { 
	var usePound = false; 
	if ( col[0] === "#" ) { 
		col = col.slice(1); 
		usePound = true; 
	} var num = parseInt(col,16); 
	var r = (num >> 16) + amt; 
	if ( r > 255 ) r = 255; 
	else if (r < 0) r = 0; 
	var b = ((num >> 8) & 0x00FF) + amt; 
	if ( b > 255 ) b = 255; 
	else if (b < 0) b = 0; 
	var g = (num & 0x0000FF) + amt; 
	if ( g > 255 ) g = 255; 
	else if ( g < 0 ) g = 0; 
	return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16); 
}

export function detectaDispostivo(){
	let nVer   = navigator.appVersion;
	let ua     = navigator.userAgent;
	let os     = '-';
	let cs     = '';
	let osType = 'desktop';

	let clientStrings = [
		{
		  s: 'Windows 10',
		  r: /(Windows 10.0|Windows NT 10.0)/
		},
		{
		  s: 'Windows 8.1',
		  r: /(Windows 8.1|Windows NT 6.3)/
		},
		{
		  s: 'Windows 8',
		  r: /(Windows 8|Windows NT 6.2)/
		},
		{
		  s: 'Windows 7',
		  r: /(Windows 7|Windows NT 6.1)/
		},
		{
		  s: 'Windows Vista',
		  r: /Windows NT 6.0/
		},
		{
		  s: 'Windows Server 2003',
		  r: /Windows NT 5.2/
		},
		{
		  s: 'Windows XP',
		  r: /(Windows NT 5.1|Windows XP)/
		},
		{
		  s: 'Windows 2000',
		  r: /(Windows NT 5.0|Windows 2000)/
		},
		{
		  s: 'Windows ME',
		  r: /(Win 9x 4.90|Windows ME)/
		},
		{
		  s: 'Windows 98',
		  r: /(Windows 98|Win98)/
		},
		{
		  s: 'Windows 95',
		  r: /(Windows 95|Win95|Windows_95)/
		},
		{
		  s: 'Windows NT 4.0',
		  r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
		},
		{
		  s: 'Windows CE',
		  r: /Windows CE/
		},
		{
		  s: 'Windows 3.11',
		  r: /Win16/
		},
		{
		  s: 'Android',
		  r: /Android/
		},
		{
		  s: 'Open BSD',
		  r: /OpenBSD/
		},
		{
		  s: 'Sun OS',
		  r: /SunOS/
		},
		{
		  s: 'Linux',
		  r: /(Linux|X11)/
		},
		{
		  s: 'iOS',
		  r: /(iPhone|iPad|iPod)/
		},
		{
		  s: 'Mac OS X',
		  r: /Mac OS X/
		},
		{
		  s: 'Mac OS',
		  r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
		},
		{
		  s: 'QNX',
		  r: /QNX/
		},
		{
		  s: 'UNIX',
		  r: /UNIX/
		},
		{
		  s: 'BeOS',
		  r: /BeOS/
		},
		{
		  s: 'OS/2',
		  r: /OS\/2/
		},
		{
		  s: 'Search Bot',
		  r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
		}
	];

	let id=0;	

	for(id in clientStrings){
		cs = clientStrings[id];		
		if(cs.r.test(ua)){
		 	os = cs.s;
		 	break;
		}				
	}

	let osVersion = '-'

	if (/Windows/.test(os)){
		osVersion = /Windows (.*)/.exec(os)[1];
		os        = 'Windows';
		osType    = 'desktop';
	}
		

	if(os === 'Mac OS X'){
		osVersion = /Mac OS X (10[._\d]+)/.exec(ua)[1];
		osType    = 'desktop';
	}
		

	if(os === 'Android'){
		osVersion = /Android ([._\d]+)/.exec(ua)[1];
		osType    = 'movil';
	}
		

	if(os === 'iOS'){
		// iOS 8+ UA changed
		if(/(iphone|ipod|ipad).* os 8_/.test(ua.toLowerCase())){
			osVersion = ua.toLowerCase().split('version/')[1].split(' ')[0]
			osType    = 'movil'
		}			
		else{
			osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
			osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
			osType    = 'movil';
		}			
	}
      	

	return {
		os        : os,
		osVersion : osVersion,
		osType    : osType,
		//ANDROID: 'Android'
		//IOS: 'iOS'
	}	

}

export function validarEmail(valor) {
	if(valor===''){ return true; }
	else if (/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(valor)){
		return (true)
	}
	else{ return (false); }
}

export function vacio(q) {
    for ( var i = 0; i < q.length; i++ ) {
        if ( q.charAt(i) !== " " && q.charAt(i) !== "\n") {
                return true;
        }
    }
    return false;
}

function tiene_numeros(texto){
	var numeros="0123456789";
    for(var i=0; i<texto.length; i++){
        if (numeros.indexOf(texto.charAt(i),0)!==-1){
         	return 1;
      	}
    }
    return 0;
}

function tiene_minusculas(texto){
	var letras="abcdefghijklmnñopqrstuvwxyz";
    for(var i=0; i<texto.length; i++){
      	if (letras.indexOf(texto.charAt(i),0)!==-1){
         	return 1;
      	}
    }
   return 0;
}

function tiene_mayusculas(texto){
   var letras_mayusculas="ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
   for(var i=0; i<texto.length; i++){
      if (letras_mayusculas.indexOf(texto.charAt(i),0)!==-1){
         return 1;
      }
   }
   return 0;
}

function tiene_simbolos(texto){
   var simbolos="!#$%&()*+,-./:;<=>¿?@[]^_{|}~";
   for(var i=0; i<texto.length; i++){
      if (simbolos.indexOf(texto.charAt(i),0)!==-1){
         return 1;
      }
   }
   return 0;
}

export function seguridad_clave(clave,options){
    if(clave.length < options.password_longitud){
   		return "longitud";
    }
    else if(options.password_mayuscula === 'true' && !tiene_mayusculas(clave)){
   		return "mayusculas";
    }
    else if(options.password_minuscula === 'true' && !tiene_minusculas(clave)){
   		return "minusculas";
    }
    else if(options.password_numero === 'true' && !tiene_numeros(clave)){
   		return "numeros";
    }
    else if(options.password_simbolo === 'true' && !tiene_simbolos(clave)){
   		return "simbolos";
    }
    else{
   		return 'true';
    }             
}  

export function modalLoadingRstPwd(opc,modal){
	globalState.dispatch({
                            type   : modal,
                            params : opc
                        });
} 

export function modalLoading(opc){	
	globalState.dispatch({
                            type   : "modalLoading",
                            params : opc
                        });
} 

export function ValidateExtension(allowedFiles,filename) {
    var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(" + allowedFiles.join('|') + ")$");
    if (!regex.test(filename.toLowerCase())) {           
        return false;
    }        
    return true;
}

export function md5(str) {
    // discuss at: http://phpjs.org/functions/md5/
    // original by: Webtoolkit.info (http://www.webtoolkit.info/)
    // improved by: Michael White (http://getsprink.com)
    // improved by: Jack
    // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // input by: Brett Zamir (http://brett-zamir.me)
    // bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // depends on: utf8_encode
    // example 1: md5('Kevin van Zonneveld');
    // returns 1: '6e658d4bfcb59cc13f96c14450ac40b9'
    var xl;
    var rotateLeft = function(lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits));
    };
    var addUnsigned = function(lX, lY) {
        var lX4, lY4, lX8, lY8, lResult;
        lX8 = (lX & 0x80000000);
        lY8 = (lY & 0x80000000);
        lX4 = (lX & 0x40000000);
        lY4 = (lY & 0x40000000);
        lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF);
        if (lX4 & lY4) {
            return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
        }
        if (lX4 | lY4) {
            if (lResult & 0x40000000) {
                return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
            } else {
                return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
            }
        } else {
            return (lResult ^ lX8 ^ lY8);
        }
    };
    var _F = function(x, y, z) {
        return (x & y) | ((~x) & z);
    };
    var _G = function(x, y, z) {
        return (x & z) | (y & (~z));
    };
    var _H = function(x, y, z) {
        return (x ^ y ^ z);
    };
    var _I = function(x, y, z) {
        return (y ^ (x | (~z)));
    };
    var _FF = function(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(_F(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
    };
    var _GG = function(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(_G(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
    };
    var _HH = function(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(_H(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
    };
    var _II = function(a, b, c, d, x, s, ac) {
        a = addUnsigned(a, addUnsigned(addUnsigned(_I(b, c, d), x), ac));
        return addUnsigned(rotateLeft(a, s), b);
    };
    var convertToWordArray = function(str) {
        var lWordCount;
        var lMessageLength = str.length;
        var lNumberOfWords_temp1 = lMessageLength + 8;
        var lNumberOfWords_temp2 = (lNumberOfWords_temp1 - (lNumberOfWords_temp1 % 64)) / 64;
        var lNumberOfWords = (lNumberOfWords_temp2 + 1) * 16;
        var lWordArray = new Array(lNumberOfWords - 1);
        var lBytePosition = 0;
        var lByteCount = 0;
        while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - (lByteCount % 4)) / 4;
            lBytePosition = (lByteCount % 4) * 8;
            lWordArray[lWordCount] = (lWordArray[lWordCount] | (str.charCodeAt(lByteCount) << lBytePosition));
            lByteCount++;
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4;
        lBytePosition = (lByteCount % 4) * 8;
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition);
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3;
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29;
        return lWordArray;
    };
    var wordToHex = function(lValue) {
        var wordToHexValue = '',
            wordToHexValue_temp = '',
            lByte, lCount;
        for (lCount = 0; lCount <= 3; lCount++) {
            lByte = (lValue >>> (lCount * 8)) & 255;
            wordToHexValue_temp = '0' + lByte.toString(16);
            wordToHexValue = wordToHexValue + wordToHexValue_temp.substr(wordToHexValue_temp.length - 2, 2);
        }
        return wordToHexValue;
    };
    var x = [],
        k, AA, BB, CC, DD, a, b, c, d, S11 = 7,
        S12 = 12,
        S13 = 17,
        S14 = 22,
        S21 = 5,
        S22 = 9,
        S23 = 14,
        S24 = 20,
        S31 = 4,
        S32 = 11,
        S33 = 16,
        S34 = 23,
        S41 = 6,
        S42 = 10,
        S43 = 15,
        S44 = 21;
    //str = this.utf8_encode(str);
    x = convertToWordArray(str);
    a = 0x67452301;
    b = 0xEFCDAB89;
    c = 0x98BADCFE;
    d = 0x10325476;
    xl = x.length;
    for (k = 0; k < xl; k += 16) {
        AA = a;
        BB = b;
        CC = c;
        DD = d;
        a = _FF(a, b, c, d, x[k + 0], S11, 0xD76AA478);
        d = _FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756);
        c = _FF(c, d, a, b, x[k + 2], S13, 0x242070DB);
        b = _FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE);
        a = _FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF);
        d = _FF(d, a, b, c, x[k + 5], S12, 0x4787C62A);
        c = _FF(c, d, a, b, x[k + 6], S13, 0xA8304613);
        b = _FF(b, c, d, a, x[k + 7], S14, 0xFD469501);
        a = _FF(a, b, c, d, x[k + 8], S11, 0x698098D8);
        d = _FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF);
        c = _FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1);
        b = _FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE);
        a = _FF(a, b, c, d, x[k + 12], S11, 0x6B901122);
        d = _FF(d, a, b, c, x[k + 13], S12, 0xFD987193);
        c = _FF(c, d, a, b, x[k + 14], S13, 0xA679438E);
        b = _FF(b, c, d, a, x[k + 15], S14, 0x49B40821);
        a = _GG(a, b, c, d, x[k + 1], S21, 0xF61E2562);
        d = _GG(d, a, b, c, x[k + 6], S22, 0xC040B340);
        c = _GG(c, d, a, b, x[k + 11], S23, 0x265E5A51);
        b = _GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA);
        a = _GG(a, b, c, d, x[k + 5], S21, 0xD62F105D);
        d = _GG(d, a, b, c, x[k + 10], S22, 0x2441453);
        c = _GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681);
        b = _GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8);
        a = _GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6);
        d = _GG(d, a, b, c, x[k + 14], S22, 0xC33707D6);
        c = _GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87);
        b = _GG(b, c, d, a, x[k + 8], S24, 0x455A14ED);
        a = _GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905);
        d = _GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8);
        c = _GG(c, d, a, b, x[k + 7], S23, 0x676F02D9);
        b = _GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A);
        a = _HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942);
        d = _HH(d, a, b, c, x[k + 8], S32, 0x8771F681);
        c = _HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122);
        b = _HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C);
        a = _HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44);
        d = _HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9);
        c = _HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60);
        b = _HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70);
        a = _HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6);
        d = _HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA);
        c = _HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085);
        b = _HH(b, c, d, a, x[k + 6], S34, 0x4881D05);
        a = _HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039);
        d = _HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5);
        c = _HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8);
        b = _HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665);
        a = _II(a, b, c, d, x[k + 0], S41, 0xF4292244);
        d = _II(d, a, b, c, x[k + 7], S42, 0x432AFF97);
        c = _II(c, d, a, b, x[k + 14], S43, 0xAB9423A7);
        b = _II(b, c, d, a, x[k + 5], S44, 0xFC93A039);
        a = _II(a, b, c, d, x[k + 12], S41, 0x655B59C3);
        d = _II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92);
        c = _II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D);
        b = _II(b, c, d, a, x[k + 1], S44, 0x85845DD1);
        a = _II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F);
        d = _II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0);
        c = _II(c, d, a, b, x[k + 6], S43, 0xA3014314);
        b = _II(b, c, d, a, x[k + 13], S44, 0x4E0811A1);
        a = _II(a, b, c, d, x[k + 4], S41, 0xF7537E82);
        d = _II(d, a, b, c, x[k + 11], S42, 0xBD3AF235);
        c = _II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB);
        b = _II(b, c, d, a, x[k + 9], S44, 0xEB86D391);
        a = addUnsigned(a, AA);
        b = addUnsigned(b, BB);
        c = addUnsigned(c, CC);
        d = addUnsigned(d, DD);
    }
    var temp = wordToHex(a) + wordToHex(b) + wordToHex(c) + wordToHex(d);
    return temp.toLowerCase();
}

function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line !== '') line += ','
            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
}

export function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function fechaMysql(date=new Date()){
    //var date = new Date(); // Or the date you'd like converted.
    var isoDateTime = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    return isoDateTime.slice(0, 19).replace('T', ' ');  
}

export function validarPermiso(idPermiso){
    //consulta si el rol tiene el permiso a validar
    let idRol = globalState.getState().idRol;
    let arrayPermisos = globalState.getState().permisosAplicacion;
    if(arrayPermisos[idPermiso] === 'true' || (idRol === 1 && idPermiso !== 777)){        
        return true;
    }
    else{
        return false;
    }
}

export function validarModulo(idModulo){
    //consulta si la empresa tiene habilitado un modulo en especifico   
    let arrayModulos = globalState.getState().modulosAplicacion;
    if(arrayModulos !== undefined && arrayModulos[idModulo] === 'true'){
        return true;
    }
    else{
        return false;
    }
}

export function number_format(amount,simbolo, decimals) {

    amount += ''; // por si pasan un numero en vez de un string
    amount = parseFloat(amount.replace(/[^0-9.]/g, '')); // elimino cualquier cosa que no sea numero o punto

    decimals = decimals || 0; // por si la variable no fue fue pasada
    simbolo = simbolo || ''; // por si la variable no fue fue pasada

    // si no es un numero o es igual a cero retorno el mismo cero
    if (isNaN(amount) || amount === 0) 
        return parseFloat(0).toFixed(decimals);

    // si es mayor o menor que cero retorno el valor formateado como numero
    amount = '' + amount.toFixed(decimals);

    var amount_parts = amount.split('.'),
        regexp = /(\d+)(\d{3})/;

    while (regexp.test(amount_parts[0]))
        amount_parts[0] = amount_parts[0].replace(regexp, '$1,$2');

    return simbolo+amount_parts.join('.');
}