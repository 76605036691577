/**
* CLASS WindowSubcategorias
*
* Contiene el componente que lista las subcategorias de una categoria de area de servicio
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import DataGrid from '../../data_grid/DataGrid';

class WindowSubcategorias extends Component {  
  	render() { 
        let sqlParams = { 
                            fieldSearch : [
                                'nombre',                                
                            ], 
                            sqlWhere : [
                                ' AND T1.id_categoria = '+this.props.params.idCat
                            ],                                                      
                            filtroFechas : "false",                                                                           
                        };                    
  	  	return (
            <div style={{height: 'calc(100% - 30px)'}}>                
                <div style={{position:"relative",height:"100%",overflow:"auto"}}>  
                    <DataGrid titulo='Subcategorias' 
                              funcionClick={this.props.funcionClick}   
                              parametro={this.props.parametro} 
                              colsData={[ 
                                            {
                                                type  : 'bd',
                                                label : 'Nombre',
                                                field : 'nombre'
                                            }                                        
                                        ]}
                              sqlParams = { sqlParams }                       
                              automatica="true"
                              botonNuevo="true"
                              formFields={[
                                            {
                                                label : 'Nombre *',
                                                field : 'nombre',
                                                type  : 'text',
                                                validation : 'texto_mayusculas',
                                                required : 'true',
                                                longitud : 45,
                                                tooltip : 'Ingrese el nombre de la subcategoria'
                                            },
                                            {
                                                label : '',
                                                field : 'id_categoria',
                                                type  : 'hidden',
                                                validation : '',
                                                value : this.props.params.idCat,
                                                required : 'true'                                        
                                            },                                                                          
                                        ]}                     
                              apiField={'areas_servicio_subcategorias'}
                              mainContainer='WindowSubcategorias'/> 
                </div>
            </div>                				
			  );
  	}
}

export default WindowSubcategorias