/**
* CLASS FormDataUser
*
* Contiene el componente formulario de datos de usuario
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import globalState from '../configuration/GlobalState';
import {uploaderFile,insertarActualizarFila,cargarFilas} from '../api_calls/ApiCalls';
import ImagenUsuario from './ImagenUsuario';
import TBar from '../tbar/TBar';
import Window from '../window/Window';
import {ValidateExtension} from '../configuration/GlobalFunctions';
import configJson,{ diccionario } from '../configuration/configuration.json';
import alertify from 'alertifyjs';
import '../../css/alertify.css';
import Form from 'react-bootstrap/Form';
import './desktop.css'; 

const { LabelsFormDataUser } = diccionario;

class FormDataUser extends Component {
    constructor(props) {        
        super(props);
        var userData = globalState.getState().userData;        
        this.state = {  
                        id : userData[0].id,
                        primer_nombre : '',
                        segundo_nombre : '',
                        primer_apellido : '',
                        segundo_apellido : '',            
                        direccion : '',
                        telefono : '',
                        idioma : globalState.getState().idioma,
                        imagen_usuario : 'default.png',
                        database : globalState.getState().companyData[0].bd
                     }
    }
    componentWillMount() {
        //....
        let sqlParams = {
                            sqlCols  : [                                
                                "primer_nombre",
                                "segundo_nombre",
                                "primer_apellido",
                                "segundo_apellido",
                                "imagen_usuario",
                                "direccion",
                                "telefono",
                            ],                           
                            sqlWhere : [ " AND T1.id = "+this.state.id ],                                
                        }
        cargarFilas('users','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;   
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{          
                this.setState({                    
                    primer_nombre : response[0].primer_nombre,
                    segundo_nombre : response[0].segundo_nombre,
                    primer_apellido : response[0].primer_apellido,
                    segundo_apellido : response[0].segundo_apellido,                        
                    direccion : response[0].direccion,
                    telefono : response[0].telefono,                    
                }); 
                if(response[0].imagen_usuario !== undefined && response[0].imagen_usuario !== ""){            
                    this.setState({imagen_usuario : response[0].imagen_usuario});
                }                       
            }     
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })
    }        
    handleStateChange(e) {     //CARGAR AL ESTADO GLOBAL LOS DATOS DEL FUNCIONARIO 
        let ingresado = e.target.value;
        if(e.target.name === 'telefono'){
            ingresado = ingresado.replace(/[^0-9+\-ext ]/g,''); 
        }
        else if(e.target.name === 'direccion'){
            ingresado = ingresado.replace(/[^\w.\-# ]/g,''); 
        }
        else{
            ingresado = ingresado.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ ]/g,'');
        }        
        ingresado = ingresado.toUpperCase();        
        this.setState({ [e.target.name]: ingresado });                     
    }
    handleImageUser(e) {
        //subir la imagen del usuario
        var file  = e.target.files[0];     
        var allowedFiles = [".jpg", ".jpeg", ".png"];
        if(!ValidateExtension(allowedFiles,file.name)){
            alertify.alert('Error!', 'Solo se permiten imagenes con extension jpeg, jpg o png !');
        }
        else{            
            if(file.size > configJson.maxAvatarSize){
                alertify.alert('Error!', 'Solo se permiten imagenes de maximo 500K!');
            }
            else{ 
                var folder = globalState.getState().companyData[0].bd;                  
                uploaderFile(file,'users','imagen_usuario',this.state.id,folder+'/'+configJson.folderAvatarUser,this.state.database).then(res => {
                    var response = res.data; 
                    if (response.msg === "error") {
                        alertify.alert('Error!', 'Ha ocurrido un error subiendo el archivo!<br />Codigo de Error: '+response.detail);
                    } else {                         
                        this.setState({imagen_usuario : response.detail});
                        globalState.dispatch({
                                        type   : "imagen_usuario",
                                        params : response.detail
                                    });                                  
                    }
                })
                .catch( err => {            
                    alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+err);
                });  
            }
        } 
    } 
    onError(){//controla error al ver imagen
        this.setState({
           imagen_usuario  : 'default.png'
        })
    } 
    functionUpdateUser(){
        //actualizacion de datos de usuario
        let objectUser = {
            id : this.state.id,            
            primer_nombre : this.state.primer_nombre,
            segundo_nombre : this.state.segundo_nombre,
            primer_apellido : this.state.primer_apellido,
            segundo_apellido : this.state.segundo_apellido,            
            direccion : this.state.direccion,
            telefono : this.state.telefono,
        } 

        let dataLogParams = {            
            primer_nombre : "Primer Nombre",
            segundo_nombre  : "Segundo Nombre",
            primer_apellido : "Primer Apellido",
            segundo_apellido : "Segundo Apellido",
            direccion : "Direccion",
            telefono : "Telefono",                       
        };

        insertarActualizarFila('put','users',objectUser,dataLogParams,undefined,this.state.database)
        .then(response => {
            response = response.data;
            if(response.msg === 'error'){
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail); 
            }
            else {                
                globalState.dispatch({
                    type   : "windowFormDataUser", 
                    params : {
                        visible : false
                    }
                });
                globalState.dispatch({
                    type   : "nameUser",
                    params : this.state.primer_nombre.toUpperCase()+' '+this.state.primer_apellido.toUpperCase()
                });
                //ACTUALIZAR EL GLOBAL STORE  
                globalState.getState().userData[0].primer_nombre = this.state.primer_nombre; 
                globalState.getState().userData[0].segundo_nombre = this.state.segundo_nombre;
                globalState.getState().userData[0].primer_apellido = this.state.primer_apellido;  
                globalState.getState().userData[0].segundo_apellido = this.state.segundo_apellido; 
                globalState.getState().userData[0].direccion = this.state.direccion;
                globalState.getState().userData[0].telefono = this.state.telefono;                                      
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });
    }  
    functionChangePassword(){
        var email = globalState.getState().userData[0].email;
        globalState.dispatch({
                type   : "windowResetPassword2",
                params : {
                              visible : true,
                              params  : {
                                            email  : email,                                            
                                            idWin  : "windowResetPassword2",
                                            bd     : globalState.getState().companyData[0].bd,
                                            width  :'300px', 
                                            height :'240px'
                                        }
                         }
            });
    }
  	render() {        
        var date = new Date();
        var randomDate = date.getTime();  
        var path =  window.location.protocol+"//"+window.location.hostname+configJson.filesRoute;    
        var folder = globalState.getState().companyData[0].bd;           

  	  	return (
  				<div style={{height:'calc(100% - 30px)'}}>
                    <TBar
                        items={[
                                 {
                                      type : 'boton',
                                      icon : 'save',
                                      width : '100px',
                                      height : '70px',
                                      title : LabelsFormDataUser.TBarBtn1WinFormUser[this.state.idioma],
                                      function : this.functionUpdateUser.bind(this)
                                  },
                                  {
                                      type : 'boton',
                                      icon : 'settings',
                                      width : '100px',
                                      height : '70px',
                                      title : LabelsFormDataUser.TBarBtn2WinFormUser[this.state.idioma],
                                      function : this.functionChangePassword.bind(this)
                                  }                              
                              ]}
                        length = '2'
                    />
                    <div className="container" style={{ position:"relative",top:'70px',height:'calc(100% - 70px)',overflowY : 'auto',overflowX:'hidden' }}>
                        <div className="content"> 
                            <br />
                            <div id="contentImageUser">
                                <div className="divImageUser">
                                    <ImagenUsuario id="ImageUser" src={path+folder+'/'+configJson.folderAvatarUser+this.state.imagen_usuario+'?'+randomDate} />
                                    <input id="uploadedImageUser" type="file" name="uploadedImageUser" onChange={this.handleImageUser.bind(this)}/>
                                </div>
                            </div>
                            <div className="table-responsive mb-3">
                                <Form>                                                            
                                    <Form.Group controlId="formBasicFirstname">
                                         <Form.Label title={LabelsFormDataUser.LabelPrimerNombreTitleFormUser[this.state.idioma]}>{LabelsFormDataUser.LabelPrimerNombreFormUser[this.state.idioma]} *</Form.Label>
                                         <Form.Control name = "primer_nombre" type="text" value={this.state.primer_nombre} onChange={this.handleStateChange.bind(this)}/>                               
                                    </Form.Group>                        
                                    <Form.Group controlId="formBasicSecondname">
                                         <Form.Label title={LabelsFormDataUser.LabelSegundoNombreTitleFormUser[this.state.idioma]}>{LabelsFormDataUser.LabelSegundoNombreFormUser[this.state.idioma]} *</Form.Label>
                                         <Form.Control name = "segundo_nombre" type="text" value={this.state.segundo_nombre} onChange={this.handleStateChange.bind(this)}/>                               
                                    </Form.Group>                        
                                    <Form.Group controlId="formBasicLastname1">
                                         <Form.Label title={LabelsFormDataUser.LabelPrimerApellidoTitleFormUser[this.state.idioma]}>{LabelsFormDataUser.LabelPrimerApellidoFormUser[this.state.idioma]} *</Form.Label>
                                         <Form.Control name = "primer_apellido" type="text" value={this.state.primer_apellido} onChange={this.handleStateChange.bind(this)}/>                               
                                    </Form.Group>                        
                                    <Form.Group controlId="formBasicLastname2">
                                         <Form.Label title={LabelsFormDataUser.LabelSegundoApellidoTitleFormUser[this.state.idioma]}>{LabelsFormDataUser.LabelSegundoApellidoFormUser[this.state.idioma]} *</Form.Label>
                                         <Form.Control name = "segundo_apellido" type="text" value={this.state.segundo_apellido} onChange={this.handleStateChange.bind(this)}/>                               
                                    </Form.Group>        
                                    <Form.Group controlId="formBasicAddress">
                                         <Form.Label title={LabelsFormDataUser.LabelDireccionTitleFormUser[this.state.idioma]}>{LabelsFormDataUser.LabelDireccionFormUser[this.state.idioma]} *</Form.Label>
                                         <Form.Control name = "direccion" type="text" value={this.state.direccion} onChange={this.handleStateChange.bind(this)}/>                               
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPhone">
                                         <Form.Label title={LabelsFormDataUser.LabelTelefonoTitleFormUser[this.state.idioma]}>{LabelsFormDataUser.LabelTelefonoFormUser[this.state.idioma]} *</Form.Label>
                                         <Form.Control name = "telefono" type="text" value={this.state.telefono} onChange={this.handleStateChange.bind(this)}/>                               
                                    </Form.Group>
                                </Form>
                            </div> 
                        </div>
                    </div>
                    <Window 
                        id = "windowResetPassword2"                      
                        title={LabelsFormDataUser.TBarBtn2WinFormUser[this.state.idioma]}                                           
                        tbar='false'
                        componente="WindowResetPassword"
                        params="" 
                    />   
                </div>  				
			  );
  	}
}

export default FormDataUser