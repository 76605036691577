/**
* CLASS FormEncuestaProveedor
*
* Contiene el componente para la encuesta del proveedor 
*
* @author Hector Morales <hector.morales@ikarosofttechnology.com>
*/

import React, { Component } from 'react';
import configJson from '../configuration/configuration.json';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import star_inactive from '../../images/star_inactive.png?v1.0';
import star_active from '../../images/star_active.png?v1.0';
import {cargarFilas,guardarEncuestaTercero} from '../api_calls/ApiCalls';
import {divMouseOver,divMouseOut,modalLoading,vacio} from '../configuration/GlobalFunctions';
import globalState from '../configuration/GlobalState';
import alertify from 'alertifyjs';

const componentList = {
    star_inactive  : star_inactive,
    star_active : star_active,
}

class FormEncuestaProveedor extends Component {
    constructor(props){
        super(props);        
        this.state = {
            resultPreguntas  : [],
            campoComentarios : '',
            database : globalState.getState().companyData[0].bd     
        }   
    }
    cargaEncuesta(){
        let sqlParams = {
                            sqlCols : [
                                'id',
                                'nombre'                                
                            ], 
                            sqlWhere : [
                                " AND tipo='proveedor'"
                            ]                                                                                                                                                           
                        };                                                                
        cargarFilas('encuesta_terceros_preguntas','',100,0,'','',sqlParams,'rows',this.state.database)
        .then(response => {
            response = response.data;
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else{
                this.setState({resultPreguntas : response});
                response.map((listado,i) => {               
                    this.setState({[listado.id] : ''});
                    this.setState({[listado.id+'_star1'] : 'star_inactive'});
                    this.setState({[listado.id+'_star2'] : 'star_inactive'});
                    this.setState({[listado.id+'_star3'] : 'star_inactive'});
                    this.setState({[listado.id+'_star4'] : 'star_inactive'});
                    this.setState({[listado.id+'_star5'] : 'star_inactive'});
                    return 0;
                })
            }
        })
        .catch(function (error) {
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        })
    }
    componentWillMount(){   
        this.cargaEncuesta(); 
    }  
    handleEnviarEncuesta(){
        //validar que todas esten diligenciadas
        let idUser = globalState.getState().userData[0].id;        
        var jsonRespuestas = {};
        var errors = 0;
        this.state.resultPreguntas.map((listado,i) => {     
            if(this.state[listado.id] === ''){
                alertify.error('Califique la pregunta: '+listado.nombre+'!'); 
                errors++;         
            }
            else{
              jsonRespuestas[listado.id] = this.state[listado.id];
            }
            return 0;
        })
        if(vacio(this.state.campoComentarios)  === false){
            alertify.error('Sus comentarios son muy importantes para nuestra mejora!');
            errors++; 
        }
        else{
            jsonRespuestas[0] = this.state.campoComentarios;
        }
        if(errors > 0){
            return;
        }
        //generar el JSON
        jsonRespuestas = JSON.stringify(jsonRespuestas);
        modalLoading(true);
        guardarEncuestaTercero(jsonRespuestas,this.props.params.idRow,idUser,'proveedor',this.state.database).then(response => {             
            response = response.data;  
            if(response.msg === 'error'){//aqui no me dejara continuar si hay un error
                modalLoading(false);
                alertify.alert('Error!', 'Ha ocurrido un error accesando a la base de datos!<br />Codigo de Error: '+response.detail);
            }
            else if(response.msg === 'no_mail'){ 
                modalLoading(false);
                alertify.alert('Error!', 'No hay destinatarios para enviar el correo!');                
            }
            else if(response.msg === 'mail_error'){ 
                modalLoading(false);
                alertify.alert('Error!', 'Ha ocurrido un error enviando el correo!<br />Codigo de Error: '+response.detail);
            }
            else{ 
                modalLoading(false);                                              
            }
            this.props.params.parametro.funcionClick('WindowEncuestasTerceros');
        })
        .catch(function (error) {
          modalLoading(false);
            alertify.alert('Error!', 'No se ha logrado la conexion con el servidor!<br />'+error);
        });   
    } 
    handleComentarios(e){
        let value=e.target.value;
        value = value.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\n,:.@\-+¡!?¿ ]/g,''); 
        this.setState({campoComentarios : value});
    }
    handlePuntaje(id,num,e){
        //activacion/desactivacion estrellas
        for(var i=num;i>0;i--){            
            this.setState({[id+'_star'+i] : 'star_active'});
        }
        for(i=num;i<5;i++){            
            this.setState({[id+'_star'+(i+1)] : 'star_inactive'});
        }
        this.setState({[id] : num});
    }
    handleBotonAtras(){
        this.props.params.parametro.funcionClick('WindowEncuestasTerceros');      
    } 
    render() {
        return <div style={{background:'#efefef',width:'100%',height:'calc(100% - 30px)',overflow:'auto'}}> 
                    <div style={{height:'auto',padding:'20px',margin:'20px 20px 20px 20px',backgroundColor:'#fff',borderRadius:'5px'}}>
                        <div> 
                            <Form>
                                {
                                    this.state.resultPreguntas.map((listado,i) => {
                                        return <Form.Group key={i} controlId={"encuestaPregunta_"+i}>
                                                 <Form.Label style={{fontSize:'11px',fontWeight:'bold'}}>{listado.nombre} *</Form.Label>
                                                 <Col sm={10} style={{paddingLeft:'0px'}}>                                      
                                                      <div style={{textAlign:'center',height:'30px',width:'250px',marginLeft:'0px'}}>
                                                          <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,1)}>
                                                              <img style={{width:'60%',height:'60%'}} src={componentList[this.state[listado.id+'_star1']]} alt={this.state[listado.id+'_star1']} />                                 
                                                          </div>
                                                          <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,2)}>
                                                              <img style={{width:'60%',height:'60%'}} src={componentList[this.state[listado.id+'_star2']]} alt={this.state[listado.id+'_star2']} />                                 
                                                          </div>
                                                          <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,3)}>
                                                              <img style={{width:'60%',height:'60%'}} src={componentList[this.state[listado.id+'_star3']]} alt={this.state[listado.id+'_star3']} />                                 
                                                          </div>
                                                          <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,4)}>
                                                              <img style={{width:'60%',height:'60%'}} src={componentList[this.state[listado.id+'_star4']]} alt={this.state[listado.id+'_star4']} />                                 
                                                          </div>
                                                          <div className="iconoPuntaje" onClick={this.handlePuntaje.bind(this,listado.id,5)}>
                                                              <img style={{width:'60%',height:'60%'}} src={componentList[this.state[listado.id+'_star5']]} alt={this.state[listado.id+'_star5']} />                                 
                                                          </div>                            
                                                      </div>
                                                  </Col>                                                      
                                               </Form.Group>                                    
                                    })
                                }
                                <Form.Row style={{width:'99%'}}>
                                    <Form.Group as={Col} controlId="encuestaComentarios">
                                        <Form.Label>Escriba sus Comentarios *</Form.Label>                                                        
                                        <Form.Control name = "encuestaComentarios" as="textarea" rows="3" onChange={this.handleComentarios.bind(this)} value={this.state.campoComentarios}/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group controlId="formBasicResetPwd" style={{height:'50px',marginBottom:'0px'}}>
                                    <Button id="formGridBtnSave" style={{margin: '5px 0',backgroundColor:configJson.fondoBotonGrilla}} className="float-left mr-3" variant="primary" onClick={this.handleEnviarEncuesta.bind(this)} onMouseOut={divMouseOut.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)} onMouseOver={divMouseOver.bind(this,'formGridBtnSave',configJson.fondoBotonGrilla)}>
                                        GUARDAR
                                    </Button>
                                    <Button style={{margin: '5px 0'}} className="float-left mr-3" variant="secondary" onClick={this.handleBotonAtras.bind(this)}>
                                        CANCELAR
                                    </Button>
                                </Form.Group>  
                            </Form> 
                        </div>
                    </div>
                </div> 
    }
}   

export default FormEncuestaProveedor              